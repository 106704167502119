import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Table.module.scss';

const Cell = (props) => {
  return (
    <div
      className={classNames(Styles.cell, props.className, props.sticky && Styles.stickyCell)}
      style={{ width: props.width, ...props.style }}>
      {props.children}
    </div>
  );
};

Cell.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  sticky: PropTypes.bool,
};

export default Cell;
