import { authRequest } from './utils';

export const getAssignments = async (config = undefined) => {
  const url = `admin/assignments/`;
  return await authRequest.get(url, config);
};

export const getAssignment = async (uuid, config = undefined) => {
  const url = `admin/assignments/${uuid}/`;
  return await authRequest.get(url, config);
};

export const putAssignment = async (uuid, data, config = undefined) => {
  const url = `admin/assignments/${uuid}/`;
  return authRequest.put(url, data, config);
};

export const postAssignment = async (data, config = undefined) => {
  const url = `admin/assignments/`;
  return authRequest.post(url, data, config);
};

export const deleteAssignment = async (uuid, config = undefined) => {
  const url = `admin/assignments/${uuid}/`;
  return await authRequest.delete(url, config);
};
