import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SelectMenu from '@hiredigital/ui/SelectMenu';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Toggle from 'react-toggle';
import convert from 'htmr';
import { postBriefAcceptAdminReview, postBriefAcceptCallReview, putBrief } from '@apis/briefs';
import { postEngagement } from '@apis/engagements';

import { AdminBriefStage, BriefStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import { isBriefAdmin, isAdmin } from '@helpers/permissions';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';

import Table from '@components/Table/Table';

import EngagementListItem from './EngagementListItem';

import Styles from '../list.module.scss';
import ListStyles from '@styles/PageList.module.scss';

class BriefListItem extends Component {
  static propTypes = {
    brief: PropTypes.object,
    onDelete: PropTypes.func,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.brief,
    };
  }

  handleAcceptCallReview = () => {
    postBriefAcceptCallReview(this.props.brief.uuid).then(
      (response) => {
        this.setState({ stage: response.data.stage });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleAcceptAdminReview = (brief, briefIndex) => {
    postBriefAcceptAdminReview(this.props.brief.uuid).then(
      (response) => {
        this.setState({ stage: response.data.stage });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };
  handleBriefUpdate = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(
      {
        [name]: type === 'checkbox' ? checked : value,
      },
      () => {
        this.saveBrief(this.state);
      }
    );
  };

  handleSelectChange = (value, name) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.saveBrief(this.state);
      }
    );
  };

  saveBrief = (brief) => {
    this.setState({ submitted: true, loading: true });

    const data = {
      stage: brief.stage,
      status: brief.status,
      disableNotifications: brief.disableNotifications,
    };

    putBrief(this.props.brief.uuid, data).then(
      (response) => {
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.brief.uuid !== this.props.brief.uuid ||
      nextProps.brief.status !== this.props.brief.status ||
      nextProps.brief.stage !== this.props.brief.stage ||
      nextProps.brief.disableNotifications !== this.props.brief.disableNotifications
    ) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  handleAddEngagement = () => {
    const data = {
      brief: this.props.brief?.uuid,
      organization: this.props.brief?.organization?.uuid,
    };

    postEngagement(data).then((response) => {
      this.props.history.push('/briefs/' + this.props.brief.uuid + '#eng-' + response.data.uuid);
    });
  };

  render() {
    const {
      title,
      organization,
      status,
      stage,
      availableStages,
      created,
      disableNotifications,
      internalNotes,
    } = this.state;
    return (
      <Fragment>
        <div className={classNames(Styles.briefCard)}>
          <Table.Cell className={classNames(ListStyles.cellRow, Styles.cell)} width='300px'>
            <Link name='title' className={Styles.name} to={`/briefs/${this.props.brief.uuid}`}>
              {title || 'Untitled Brief'}
            </Link>
            <a
              className={Styles.profileLink}
              style={{ marginLeft: 'auto' }}
              href={`${process.env.APP_ENDPOINT}/briefs/${this.props.brief.uuid}`}
              rel='nofollow noopener'
              target='_blank'>
              <IconExternal className={Styles.externalIcon} />
            </a>
          </Table.Cell>

          <Table.Cell className={classNames(ListStyles.cell, Styles.cell)} width='225px'>
            <div className={Styles.organization}>
              <img
                className={classNames(ListStyles.picture, ListStyles.orgPicture)}
                src={organization && organization.picture}
                alt={organization && organization.name}
              />
              <div>
                <p className={Styles.orgName}>{organization && organization.name}</p>
              </div>
            </div>
          </Table.Cell>

          <Table.Cell className={classNames(Styles.hoverParent, Styles.cell)} width='300px'>
            {internalNotes ? (
              <div className={Styles.hoverSection}>
                <div className={Styles.innerSection}>
                  <div style={{ display: 'flex' }}>
                    <p className={Styles.title} style={{ marginRight: '10px' }}>
                      {`Notes: `}
                    </p>
                    <div className={Styles.content}>{convert(internalNotes)}</div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </Table.Cell>

          <Table.Cell className={classNames(ListStyles.cell, Styles.cell)} width='180px'>
            {/* <Dropdown size='sm'>
              <Dropdown.Toggle className={ListStyles.selectDropdown}>
                <p>{AdminBriefStage.getEnum(stage).label}</p>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {availableStages.map((stage, stageIndex) => (
                  <Dropdown.Item
                    onClick={() => this.handleSelectChange(stage, 'stage')}
                    key={stageIndex}>
                    {AdminBriefStage.getEnum(stage).label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <SelectMenu
              onChange={(v) => this.handleSelectChange(v?.id, 'stage')}
              options={availableStages.map((id) => ({
                id,
                label: AdminBriefStage.getEnum(id).label,
              }))}
              defaultValue={AdminBriefStage.getEnum(stage)}
            />
          </Table.Cell>

          <Table.Cell className={classNames(ListStyles.cell, Styles.cell)} width='220px'>
            {/* <Dropdown size='sm'>
              <Dropdown.Toggle className={ListStyles.selectDropdown}>
                <p>{BriefStatus.getEnum(status).label}</p>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {BriefStatus.values.map((status, statusIndex) => (
                  <Dropdown.Item
                    onClick={() => this.handleSelectChange(status.id, 'status')}
                    key={statusIndex}>
                    {status.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <SelectMenu
              onChange={(v) => this.handleSelectChange(v?.id, 'status')}
              options={BriefStatus.values}
              defaultValue={BriefStatus.getEnum(status)}
            />
          </Table.Cell>

          <Table.Cell className={classNames(ListStyles.cell, Styles.cell)} width='130px'>
            <p>{toReadableDate(created)}</p>
          </Table.Cell>

          <Table.Cell width='250px' className={classNames(ListStyles.cell, Styles.cell)}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <label
                htmlFor={`disableNotifications-${this.props.brief.uuid}`}
                className={Styles.toggleLabel}>{`Disable Emails`}</label>
              <Toggle
                id={`disableNotifications-${this.props.brief.uuid}`}
                name='disableNotifications'
                defaultChecked={disableNotifications}
                onChange={this.handleBriefUpdate}
              />
            </div>
          </Table.Cell>
          <div className={Styles.floatingAction}>
            {/* <Dropdown drop='left' size='sm'>
              <Dropdown.Toggle className={ListStyles.moreDropdown}>
                <IconMoreVertical className={ListStyles.moreIcon} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  target='_blank'
                  href={`${process.env.APP_ENDPOINT}/briefs/${this.props.brief.uuid}`}>
                  {`View brief on platform`}
                </Dropdown.Item>

                {this.props.currentUser && isBriefAdmin(this.props.currentUser) && (
                  <Fragment>
                    <Dropdown.Item onClick={this.handleAcceptCallReview}>
                      {`Accept call review`}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleAcceptAdminReview}>
                      {`Accept admin review`}
                    </Dropdown.Item>
                  </Fragment>
                )}

                <Dropdown.Item onClick={this.handleAddEngagement}>{`Add Engagement`}</Dropdown.Item>
                {this.props.currentUser && isAdmin(this.props.currentUser) ? (
                  <Dropdown.Item onClick={(e) => this.props.onDelete(this.state.uuid)}>
                    {`Delete Brief`}
                  </Dropdown.Item>
                ) : (
                  ''
                )}
              </Dropdown.Menu>
            </Dropdown> */}
            <SplitButton
              moreButtonClass={Styles.moreButtonClass}
              more={[
                {
                  text: 'View brief on platform',
                  as: 'a',
                  taget: '_blank',
                  href: `${process.env.APP_ENDPOINT}/briefs/${this.props.brief.uuid}`,
                },
                ...(this.props.currentUser && isBriefAdmin(this.props.currentUser)
                  ? [
                      {
                        text: 'Accept call review',
                        onClick: this.handleAcceptCallReview,
                      },
                      {
                        text: 'Accept admin review',
                        onClick: this.handleAcceptAdminReview,
                      },
                    ]
                  : []),
                {
                  text: 'Add Engagement',
                  onClick: this.handleAddEngagement,
                },
                {
                  ...(this.props.currentUser && isAdmin(this.props.currentUser)
                    ? {
                        text: 'Delete Brief',
                        onClick: () => this.props.onDelete(this.state.uuid),
                      }
                    : {}),
                },
              ]}
            />
          </div>
        </div>
        <div className={Styles.engagementsContainer}>
          {this.state.engagements.map((engagement, index) => (
            <EngagementListItem
              key={engagement.uuid}
              engagement={engagement}
              briefUuid={this.props.brief.uuid}
              currentUser={this.props.currentUser}
            />
          ))}
        </div>
      </Fragment>
    );
  }
}

export default BriefListItem;
