import { useMemo } from 'react';

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ total, perPage, siblings = 2, currentPage }) => {
  const ellipsis = '...';

  const paginationRange = useMemo(() => {
    const totalPageNo = Math.ceil(total / perPage);

    // the number of pages is calculated as follows: siblings + start page + last page + current page + 2 * ellipsis.
    const maxPageCount = siblings + 5;

    /*
      in the event that there are less pages than the page numbers we wanted to display
      just return [1, n->, totalPageNo] for the paginator
    */
    if (maxPageCount >= totalPageNo) {
      return range(1, totalPageNo);
    }

    const leftSiblingIndex = Math.max(currentPage - siblings, 1);
    const rightSiblingIndex = Math.min(currentPage + siblings, totalPageNo);

    /*
      do not show ellipsis if there is only one position left
      after/before the left/right page count
    */
    const shouldShowLeftEllipsis = leftSiblingIndex > 2;
    const shouldShowRightEllipsis = rightSiblingIndex < totalPageNo - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageNo;

    if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const leftItemCount = 3 + 2 * siblings;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, ellipsis, totalPageNo];
    }

    if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) {
      const rightItemCount = 3 + 2 * siblings;
      const rightRange = range(totalPageNo - rightItemCount + 1, totalPageNo);
      return [firstPageIndex, ellipsis, ...rightRange];
    }

    if (shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, ellipsis, ...middleRange, ellipsis, lastPageIndex];
    }
  }, [total, perPage, siblings, currentPage]);

  return paginationRange;
};
