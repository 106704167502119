import { useEffect, useId, useState } from 'react';
import Toggle from 'react-toggle';
import { Tooltip, Position } from 'evergreen-ui';
import _isEqual from 'lodash/isEqual';

import {
  Currency,
  ServiceRateType,
  NeededHoursType,
  DurationUnits,
} from '@hiredigital/lib/helpers/enum';
import { generateRateDisplayText } from '@hiredigital/lib/helpers/rates';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import DualInput from '@hiredigital/ui/Input/DualInput';
import TextInput from '@hiredigital/ui/Input/TextInput';
import IconMore from '@hiredigital/ui/Icon/icons/more.inline.svg';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import Loader from '@hiredigital/ui/Loader';

import { postRates, putRates } from '@apis/rates';

import Styles from '@styles/BriefDetail.module.scss';

const TIMEOUT_MS = 1000;

const RateInputGroup = ({
  isClient,
  rate: [rate, setRate],
  resource,
  resourceUuid,
  hideBgColorArea = false,
}) => {
  const primaryResource = isClient ? 'client-rate' : 'talent-rate';

  const formId = useId();

  const [data, setData] = useState();
  const [rateUuid, setRateUuid] = useState(rate?.uuid);

  const [maxRate, setMaxRate] = useState(rate?.maxRate);
  const [currencyCode, setCurrencyCode] = useState(rate?.currencyCode);
  const [rateType, setRateType] = useState(rate?.rateType);

  const [minHours, setMinHours] = useState(rate?.minHours);
  const [maxHours, setMaxHours] = useState(rate?.maxHours);
  const [hoursType, setHoursType] = useState(rate?.hoursPeriodType);

  const [duration, setDuration] = useState(rate?.duration);
  const [durationType, setDurationType] = useState(rate?.durationType);

  const validatedFields = [
    'maxRate',
    'currencyCode',
    'rateType',
    'minHours',
    'maxHours',
    'hoursPeriodType',
    'duration',
    'durationType',
  ];

  const [showSettings, setShowSettings] = useState(rate ? !rate.autoGenerateDisplayText : false);
  const [rateDisplayText, setRateDisplayText] = useState(rate?.rateDisplayText || '');
  const [isAuto, setIsAuto] = useState(rate ? !!rate.autoGenerateDisplayText : true);
  const [showHours, setShowHours] = useState(!!rate?.showHoursPeriod);
  const [showDuration, setShowDuration] = useState(!!rate?.showDuration);

  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const getData = () => ({
    ...rate,
    maxRate: maxRate || null,
    currencyCode: currencyCode || null,
    rateType: rateType || null,
    minHours: minHours || 0.0,
    maxHours: maxHours || 0.0,
    hoursPeriodType: hoursType || null,
    duration: duration || null,
    durationType: durationType || null,
    rateDisplayText: rateDisplayText || null,
    autoGenerateDisplayText: isAuto,
    showHoursPeriod: showHours,
    showDuration: showDuration,
  });

  useEffect(() => {
    setData(getData());
  }, []);

  const submitRates = () => {
    // if true, the component is not mounted yet so a submission is unnecessary
    if (!data) return setIsSaving(false);

    const newData = getData();
    // if true, there is no change from the original data or the last submitted data
    if (_isEqual(data, newData)) return setIsSaving(false);

    // don't submit null values in mandatory fields for creating a new rate
    // this check is for POST request only
    if (!rateUuid) {
      // return without submitting to API if all fields are blank
      if (validatedFields.every((field) => !newData[field])) return setIsSaving(false);
    }

    (rateUuid
      ? putRates(rateUuid, newData)
      : postRates(newData, primaryResource, resource, resourceUuid)
    )
      .then((response) => {
        setRateUuid(response.data.uuid);
        setRate(response.data);
        setData(newData);
      })
      .catch((error) => {
        setErrors(error?.response?.data || {});
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    setIsSaving(true);
    setErrors({});

    if (isAuto) {
      const rateObject = {
        maxRate,
        currencyCode,
        rateType,
        minHours,
        maxHours,
        hoursType,
        duration,
        durationType,
        showHours,
        showDuration,
      };
      setRateDisplayText(generateRateDisplayText(rateObject));
    }

    const submitTimeout = setTimeout(submitRates, TIMEOUT_MS);
    return () => {
      clearTimeout(submitTimeout);
      setIsSaving(false);
    };
  }, [
    maxRate,
    currencyCode,
    rateType,
    minHours,
    maxHours,
    hoursType,
    duration,
    durationType,
    rateDisplayText,
    isAuto,
    showHours,
    showDuration,
  ]);

  const toggleSettings = () => setShowSettings(!showSettings);

  const containerFlex = ['1.5 1 0', '1.2 1 0', '0.8 1 0', '0 1 0'];
  const IconSettings = showSettings ? IconClose : IconMore;
  return (
    <>
      <InputGroup>
        <InputContainer style={{ flex: containerFlex[0] }} rateInput>
          <DualInput
            input={(Input) => (
              <Input
                name={`estimatedRate${formId}`}
                label={`${isClient ? 'Client' : 'Talent'} Rate`}
                style={{ textAlign: 'center' }}
                type='text'
                defaultValue={maxRate}
                onChange={(e) => setMaxRate(e.target.value)}
                error={!!errors.maxRate}
              />
            )}
            select={(Select) => (
              <Select
                id='currencyCodeID'
                name='currencyCode'
                label='Currency'
                style={{ flexBasis: '115px' }}
                defaultValue={Currency.getEnum(currencyCode)}
                // getOptionLabel={({ code, label }) => `${code} - ${label}`}
                formatOptionLabel={({ code, label }, { context }) =>
                  context === 'menu' ? label : code
                }
                getOptionValue={({ id }) => id}
                options={Currency.values}
                onChange={(e) => setCurrencyCode(e.target.value?.id)}
                error={!!errors.currencyCode}
              />
            )}
            secondSelect={(Select) => (
              <Select
                id='estimatedRateTypeID'
                name='estimatedRateType'
                label='Period'
                style={{ flexBasis: '115px' }}
                defaultValue={ServiceRateType.getEnum(rateType)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                options={ServiceRateType.values}
                onChange={(e) => setRateType(e.target.value?.id)}
                error={!!errors.rateType}
              />
            )}
          />
        </InputContainer>
        <InputContainer style={{ flex: containerFlex[1] }}>
          <DualInput
            input={(Input) => (
              <Input
                name={`hours${formId}`}
                label='Hours (Fixed or Min-Max)'
                style={{ textAlign: 'center' }}
                type='text'
                defaultValue={`${minHours ? `${minHours}-` : ''}${maxHours || ''}`}
                onChange={(e) => {
                  if (/^.+-.*$/.test(e.target.value)) {
                    const [minHours, maxHours] = e.target.value.split('-');
                    setMinHours(minHours?.trim());
                    setMaxHours(maxHours?.trim());
                  } else {
                    setMinHours(0);
                    setMaxHours(e.target.value);
                  }
                }}
                error={errors.maxHours || errors.minHours}
              />
            )}
            select={(Select) => (
              <Select
                id='minPerID'
                name='minPer'
                label='Per'
                style={{ flexBasis: '130px' }}
                defaultValue={NeededHoursType.getEnum(hoursType)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                options={NeededHoursType.values}
                onChange={(e) => setHoursType(e.target.value?.id)}
                isClearable
              />
            )}
          />
        </InputContainer>
        <InputContainer style={{ flex: containerFlex[2] }}>
          <DualInput
            input={(Input) => (
              <Input
                name={`duration${formId}`}
                label='Duration'
                style={{ textAlign: 'center' }}
                type='text'
                defaultValue={duration}
                onChange={(e) => setDuration(e.target.value)}
                error={errors.duration}
              />
            )}
            select={(Select) => (
              <Select
                id='maxPerID'
                name='maxPer'
                label='Unit'
                style={{ flexBasis: '110px' }}
                defaultValue={DurationUnits.getEnum(durationType)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                options={DurationUnits.values}
                onChange={(e) => setDurationType(e.target.value?.id)}
                isClearable
              />
            )}
          />
        </InputContainer>
        <InputContainer
          style={{ flex: containerFlex[3], justifyContent: 'center', position: 'relative' }}>
          <Tooltip
            isShown={showSettings ? false : undefined}
            position={Position.TOP_RIGHT}
            statelessProps={{ className: Styles.rateDisplayTooltip }}
            showDelay={200}
            content={
              <div style={{ color: 'white', minWidth: '150px' }}>
                <div style={{ fontWeight: '600', color: 'lightgray', fontSize: '0.7em' }}>
                  Shown As
                </div>
                <div style={{ marginBottom: '5px' }}>{rateDisplayText}</div>
                <div style={{ color: 'lightgray', fontSize: '0.7em', textAlign: 'right' }}>
                  Click to customize
                </div>
                <div
                  // triangle shape
                  style={{
                    position: 'absolute',
                    bottom: '-8px',
                    right: '14px',
                    width: 0,
                    height: 0,
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderTop: '8px solid #474d66',
                  }}
                />
              </div>
            }>
            <div>
              {isSaving ? (
                <Loader
                  className={Styles.spinner1}
                  size={Loader.Size.SMALL}
                  onClick={toggleSettings}
                />
              ) : (
                <IconSettings className={Styles.icon1} onClick={toggleSettings} />
              )}
            </div>
          </Tooltip>
        </InputContainer>
      </InputGroup>
      {showSettings && (
        <InputGroup>
          <InputContainer style={{ flex: containerFlex[0], flexDirection: 'row' }}>
            <TextInput
              label={`Shown As`}
              name={`rateDisplayText${formId}`}
              type={`Text`}
              value={rateDisplayText}
              onChange={(e) => setRateDisplayText(e.target.value)}
              disabled={isAuto}
              style={isAuto && !hideBgColorArea ? { backgroundColor: '#fce4e3' } : {}}
            />
            <div
              style={{
                padding: '3px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '50px',
                marginLeft: '10px',
              }}>
              <Toggle
                id={`toggleAuto${formId}`}
                name='toggleAuto'
                defaultChecked={isAuto}
                onChange={({ target }) => setIsAuto(target.checked)}
              />
              <label htmlFor={`toggleAuto${formId}`} className={Styles.toggleLabel}>
                Auto
              </label>
            </div>
          </InputContainer>
          <InputContainer style={{ flex: containerFlex[1], flexDirection: 'row' }}>
            {isAuto && maxHours && hoursType ? (
              <ShowToggle id='showHours' showToggle={[showHours, setShowHours]} />
            ) : undefined}
          </InputContainer>
          <InputContainer style={{ flex: containerFlex[2], flexDirection: 'row' }}>
            {isAuto && duration && durationType ? (
              <ShowToggle id='showDuration' showToggle={[showDuration, setShowDuration]} />
            ) : undefined}
          </InputContainer>
          <InputContainer style={{ flex: containerFlex[3], justifyContent: 'center' }}>
            {/* hidden element for spacing only */}
            <IconSettings className={Styles.icon1} style={{ visibility: 'hidden' }} />
          </InputContainer>
        </InputGroup>
      )}
    </>
  );
};

const ShowToggle = ({ id, showToggle: [showToggle, setShowToggle] }) => {
  const formId = useId();

  const boxStyle = {
    width: '100%',
    height: '50%',
    margin: '0 12px',
    border: '1px solid #c4cfd9',
    borderTop: 'none',
  };

  return (
    <>
      <div style={{ ...boxStyle, borderRight: 'none' }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
        }}>
        <Toggle
          id={`${id}${formId}`}
          defaultChecked={showToggle}
          onChange={({ target }) => setShowToggle(target.checked)}
        />
        <label htmlFor={`${id}${formId}`} className={Styles.toggleLabel}>
          Show
        </label>
      </div>
      <div style={{ ...boxStyle, borderLeft: 'none' }} />
    </>
  );
};

export default RateInputGroup;
