import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  postTeamInvitation,
  putTeamInvitation,
  postTeamUser,
  deleteTeamUser,
  deleteTeamInvitation,
} from '@apis/teams';

import Styles from './Members.module.scss';
import { validateEmail } from '@helpers/forms';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import MemberItem from './MemberItem';
import MemberInvitationItem from './MemberInvitationItem';

class Members extends Component {
  static propTypes = {
    team: PropTypes.object,
    members: PropTypes.array,
    invitations: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      team: {},
      members: [],
      invitations: [],
      email: '',
      name: '',
      role: '',
      showInviteForm: false,
      showMemberForm: false,
      emailValid: true,
      submitted: false,
      errors: {},
    };
  }

  componentDidMount = () => {
    const { team, members, invitations } = this.props;

    this.setState({
      team,
      members,
      invitations: invitations.filter((invitation) => {
        return !members.some(({ user }) => user.email === invitation.email);
      }),
      // showInviteForm: !this.props.invitations.length,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  updateInvitation = (invitation, status) => {
    const index = this.state.invitations.indexOf(invitation);
    const data = { status: status.id };
    putTeamInvitation(this.state.team.uuid, invitation.id, data).then((response) => {
      const invitations = this.state.invitations;
      invitations[index].status = status.id;
      this.setState({ invitations: invitations });
    });
  };

  sendInvitation = () => {
    this.setState({ submitted: true, errors: {} });
    const { email, name, role } = this.state;

    if (email) {
      this.setState({ emailValid: validateEmail(email) });
    }

    if (email && this.state.emailValid) {
      this.setState({ loading: true });

      const data = {
        email,
        name,
        role,
      };

      postTeamInvitation(this.state.team.uuid, data).then(
        (response) => {
          const invitations = this.state.invitations;
          invitations.push(response.data);
          this.setState({
            email: '',
            name: '',
            role: '',
            loading: false,
            showInviteForm: false,
            showMemberForm: false,
            submitted: false,
            invitations,
          });
        },
        ({ response: { data } }) => {
          Object.keys(data).forEach((key) => {
            if (data[key] && !!data[key].length) {
              this.setState({ errors: { [key]: data[key][0] } });
            }
          });
          this.setState({ loading: false });
        }
      );
    }
  };

  handleToggleInvitationForm = () => {
    this.setState({
      invitations: [{ isEdit: true }, ...this.state.invitations],
      showInviteForm: !this.state.showInviteForm,
    });
  };

  handleToggleMemberForm = () => {
    this.setState({
      members: [{ isEdit: true }, ...this.state.members],
      showMemberForm: !this.state.showMemberForm,
    });
  };

  handleCreateNewMember = () => {
    if (this.validate()) {
      const members = this.state.members;
      postTeamUser(this.props.team.uuid, {
        user: this.state.user.uuid,
        role: this.state.role,
      }).then(
        (response) => {
          members.push(response.data);
          const invitations = this.state.invitations.filter((invitation) => {
            return !members.some(({ user }) => user.email === invitation.email);
          });

          this.setState({ members, invitations, showMemberForm: false });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    }
  };

  isMemberExist = ({ uuid }) => {
    const { members } = this.state;
    const found = members.find((member) => member.user.uuid === uuid);
    return !!found;
  };

  deleteMember = (member) => {
    // const index = this.state.members.indexOf(member);
    this.setState({ loading: true });
    if (member.id) {
      deleteTeamUser(this.props.team.uuid, member.id).then(
        (response) => {
          // this.state.members.splice(index, 1);
          // this.setState({ members: this.state.members });
          const members = this.state.members?.filter((v) => v?.id !== member?.id) || [];
          this.setState({ members, loading: false });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    } else {
      // this.state.members.splice(index, 1);
      // this.setState({ members: this.state.members });
      const members = this.state.members?.filter((v) => v?.id !== member?.id) || [];
      this.setState({ members, loading: false });
    }
  };

  deleteInvitation = (invitation) => {
    // const index = this.state.invitations.indexOf(invitation);
    this.setState({ loading: true });
    if (invitation.id) {
      deleteTeamInvitation(this.props.team.uuid, invitation.id).then(
        (response) => {
          // this.state.invitations.splice(index, 1);
          const invitations = this.state.invitations?.filter((v) => v?.id !== invitation?.id);
          // this.setState({ invitations: this.state.invitations });
          this.setState({ invitations, loading: false });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    } else {
      // this.state.invitations.splice(index, 1);
      const invitations = this.state.invitations?.filter((v) => v?.id !== invitation?.id);
      // this.setState({ invitations: this.state.invitations });
      this.setState({ invitations, loading: false });
    }
  };

  handleOwnerChange = (team) => {
    this.setState({ team });
  };

  validate = () => {
    const errors = { user: 'This field cannot be blank.' };
    const fields = Object.keys(errors);

    let valid = true,
      stateErrors = {};
    fields.forEach((key) => {
      if (
        !this.state[key] ||
        (typeof this.state[key] === 'object' && !Object.keys(this.state[key]).length)
      ) {
        valid = false;
        stateErrors = {
          ...stateErrors,
          [key]: errors[key] || '',
        };
      }
    });

    if (this.state.user && this.isMemberExist(this.state.user)) {
      valid = false;
      stateErrors = {
        ...stateErrors,
        user: 'Member already exists.',
      };
    }

    this.setState({ errors: stateErrors });
    return valid;
  };

  handleMemberCancel = () => {
    this.setState({
      members: this.state.members.filter((v) => !!v?.id),
      showMemberForm: false,
    });
  };

  handleInvitationCancel = () => {
    this.setState({ showInviteForm: false });
  };

  handleMemberCreate = (data) => {
    this.setState({
      members: [...this.state.members, data]?.filter((v) => !!v?.id),
      showMemberForm: false,
    });
  };

  handleInvitationCreate = (data) => {
    this.setState({
      invitations: [...this.state.invitations, data]?.filter((v) => !!v?.id),
      showInviteForm: false,
    });
  };

  render() {
    const validateEmail = () => {
      if (this.state.submitted) {
        if (!this.state.email) {
          return 'Your must include an e-mail address.';
        } else if (!this.state.emailValid) {
          return 'Enter a valid Email';
        }
      }
    };

    return (
      <Card>
        <Card.Header>
          <span>{`Members`}</span>
          <div className={Styles.action}>
            {!this.state.showMemberForm && (
              <Button name='add new' type={Button.Type.BLUE} onClick={this.handleToggleMemberForm}>
                {`Add Existing User`}
              </Button>
            )}
            {!this.state.showInviteForm && (
              <Button
                name='add new'
                type={Button.Type.BLUE}
                onClick={this.handleToggleInvitationForm}>
                {`Invite New User`}
              </Button>
            )}
          </div>
        </Card.Header>
        <Fragment>
          {this.state.members?.map((member, index) => (
            <MemberItem
              member={member}
              team={this.state.team}
              isEdit={member.isEdit}
              deleteMember={this.deleteMember}
              key={member.id || index}
              isOwner={
                !!(
                  member &&
                  member.user &&
                  this.state.team &&
                  this.state.team.owner &&
                  member.user.uuid === this.state.team.owner.uuid
                )
              }
              onOwnerChange={this.handleOwnerChange}
              onCancel={this.handleMemberCancel}
              onCreate={this.handleMemberCreate}
            />
          ))}

          {this.state.invitations?.map((invitation, index) => (
            <MemberInvitationItem
              invitation={invitation}
              team={this.state.team}
              isEdit={invitation.isEdit}
              deleteInvitation={this.deleteInvitation}
              key={invitation.id || index}
              onCancel={this.handleInvitationCancel}
              onCreate={this.handleInvitationCreate}
            />
          ))}
        </Fragment>
      </Card>
    );
  }
}

export default Members;
