import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import { BaseCountry } from '@hiredigital/lib/helpers/enum';
import IconXero from '@hiredigital/ui/Icon/icons/xero.inline.svg';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import { getInvoices, refreshInvoices, postClearInvoices } from '@apis/payments';
import { InvoiceStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { formatter } from '@helpers/formats';

import Styles from './Styles.module.scss';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

class InvoiceList extends Component {
  static propTypes = {
    resourceUuid: PropTypes.string,
    resource: PropTypes.string,
  };

  static defaultProps = {
    resource: `orgs`,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        Header: 'Invoice Number',
        id: 'invoiceNumber',
        Cell: ({ row }) => {
          return (
            <div className={Styles.row}>
              <a className={Styles.link} href={row.original.attachment} target='_blank'>
                {`${row.original.invoiceNumber}`}
              </a>
              <a
                className={Styles.xeroLink}
                href={
                  this.props.resource === `users`
                    ? `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${row.original.invoiceId}`
                    : `https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${row.original.invoiceId}`
                }
                target='_blank'>
                <IconXero className={Styles.xeroIcon} />
              </a>
            </div>
          );
        },
      },

      {
        Header: 'Invoice Date',
        accessor: (d) => toReadableDate(d.date),
      },
      {
        Header: 'Country',
        accessor: (d) => BaseCountry.getLabel(d.baseCountry),
      },

      // {
      //   Header: 'Due Date',
      //   accessor: (d) => toReadableDate(d.dueDate),
      // },
      {
        Header: 'Status',
        accessor: (d) => InvoiceStatus.getLabel(d.status),
      },
      {
        Header: 'Amount Due',
        id: 'amountDue',
        Cell: ({ row }) => {
          return <p className={Styles.currency}>{formatter.format(row.original.amountDue)}</p>;
        },
      },
      {
        Header: 'Cur',
        width: 50,
        accessor: 'currencyCode',
      },

      {
        Header: 'Total Amount',
        id: 'totalAmount',
        Cell: ({ row }) => {
          return <p className={Styles.currency}>{formatter.format(row.original.totalAmount)}</p>;
        },
      },
    ];

    this.state = {
      search: '',
    };
  }

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.search(value);
  };

  search = debounce((value) => {
    this.setState({
      search: value,
    });
  }, 250);

  refreshXero = () => {
    const { resource, resourceUuid } = this.props;
    return refreshInvoices(resource, resourceUuid).then(() => {
      this.forceRefresh();
    });
  };

  forceRefresh = () => {
    this.setState({ search: ' ' }, () => this.setState({ search: '' }));
  };

  clearXero = () => {
    const { resource, resourceUuid } = this.props;
    return postClearInvoices(resource, resourceUuid).then(() => {
      this.forceRefresh();
    });
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <div className={Styles.sectionTitle}>{`Invoices`}</div>
          <input
            className={Styles.searchFieldFrame}
            name='search'
            type='text'
            placeholder='Search Invoices'
            defaultValue={this.state.search}
            onChange={this.handleSearchChange}
          />
          {/* <Button
            className={Styles.leftButton}
            name='refresh'
            type={Button.Type.WHITE_BLUE_OUTLINE}
            onClick={this.refreshXero}>
            {`Refresh Invoices`}
          </Button>
          <Dropdown name='MoreMenu' drop='down' size='sm' alignRight>
            <Dropdown.Toggle className={Styles.rightButton}>
              <IconMoreVertical className={Styles.moreIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.clearXero}>{`Clear Invoices`}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <SplitButton
            name='refresh'
            type={Button.Type.BLUE_OUTLINE}
            onClick={this.refreshXero}
            more={[{ text: 'Clear Invoices', onClick: this.clearXero }]}>
            {`Refresh Invoices`}
          </SplitButton>
        </Card.Header>

        <Card.Item noPadding>
          <PaginatedTable
            columns={this.columns}
            search={this.state.search}
            rowAccessor='uuid'
            getListData={(config) =>
              getInvoices(this.props.resource, this.props.resourceUuid, config)
            }
            defaultLimit={10}
            defaultPaginationOptions={[5, 20, 50, 100]}
            showParams={false}
            emptyContainerClass={Styles.emptyContainer}
            paginationClass={Styles.paginationContainer}
          />
        </Card.Item>
      </Card>
    );
  }
}

export default InvoiceList;
