import Cookies from 'universal-cookie';

const cookies = new Cookies();
export const COOKIE_DOMAIN = {
  domain: process.env.cookieDomain ? process.env.cookieDomain : '',
  path: '/',
};

export const isLoggedIn = () => {
  if (cookies.get('admin_token')) {
    return true;
  }
  return false;
};
