import { useMemo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import { format, parseISO } from 'date-fns';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import { getOrgBriefs } from '@apis/orgs';
import { BriefStatus, AdminBriefStage } from '@hiredigital/lib/helpers/enum';
import { copyTextToClipboard } from '@hiredigital/lib/helpers/utils';
import Styles from './UserAssignments.module.scss';

import SplitButton from '@hiredigital/ui/Button/SplitButton';

const BriefColumn = ({ row, onEdit, onView, onViewAssignment, onCopyTalent, onCopyClient }) => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <button className={Styles.link} onClick={() => onViewAssignment?.(row)}>
        {row?.original?.title || 'Untitled Brief'}
      </button>
      <div style={{ marginLeft: 'auto' }}>
        <SplitButton
          more={[{ text: 'View Brief in Admin', onClick: () => onViewAssignment?.(row) }]}
        />
      </div>
    </div>
  );
};

const TextRight = ({ value }) => <div style={{ textAlign: 'right', width: '100%' }}>{value}</div>;

const List = ({ orgUuid, currentUser }) => {
  const [status, setStatus] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [search, setSearch] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [updateRow, setUpdateRow] = useState(undefined);
  const [errors, setErrors] = useState({});
  let timeout = null;

  const handleEdit = (item) => {
    setSelected(item.original);
    setIsShown(true);
  };

  // const handleCopyClient = (a) => {
  //   copyTextToClipboard(
  //     `${process.env.APP_ENDPOINT}/briefs/${a?.original?.engagement?.brief?.uuid}`
  //   );
  // };

  const handleCopyTalent = (a) => {
    copyTextToClipboard(`${process.env.APP_ENDPOINT}/engagements/${a?.original?.engagement?.uuid}`);
  };

  // const handleViewBrief = (a) =>
  //   window.open(`/briefs/${a?.original?.engagement?.brief?.uuid}`, '_blank');

  const handleViewAssignment = (a) => {
    window.open(`/briefs/${a?.original?.uuid}`, '_blank');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        canSort: true,
        minWidth: 300,
        id: 'title',
        truncate: false,
        Cell: ({ row }) => (
          <BriefColumn
            // onEdit={handleEdit}
            // onView={handleViewBrief}
            onViewAssignment={handleViewAssignment}
            // onCopyTalent={handleCopyTalent}
            // onCopyClient={handleCopyClient}
            row={row}
          />
        ),
      },
      {
        Header: 'Notes',
        canSort: true,
        minWidth: 200,
        id: 'notes',
        Cell: ({ row }) => (
          <div dangerouslySetInnerHTML={{ __html: row?.original?.internal_notes }} />
        ),
      },

      {
        Header: 'Stage',
        id: 'stage',
        canSort: true,
        width: 100,
        accessor: (a) => AdminBriefStage.getEnum(a?.stage).label,
      },
      {
        Header: 'Status',
        id: 'status',
        canSort: true,
        width: 100,
        accessor: (a) => BriefStatus.getEnum(a?.status).label,
      },
      {
        Header: 'Created',
        canSort: true,
        id: 'created',
        width: 100,
        accessor: (n) => <div>{format(parseISO(n.created.toString()), 'dd MMM yyyy')}</div>,
      },
    ],
    []
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleStatusSelect = (v) => setStatus(v);

  const handleStatusDeselect = (v) => setStatus({});

  const filterMemo = useMemo(
    () => ({
      status: status?.value,
    }),
    [status]
  );

  const handleFormChange = ({ name, value }) => {
    const data = { ...selected, [name]: value };
    setSelected(data);
    setErrors({});
  };

  // const handleSave = () => {
  //   if (!validateFields()) return;

  //   const {
  //     uuid,
  //     user,
  //     team,
  //     brandRate,
  //     startDate,
  //     endDate,
  //     brandRateType,
  //     talentRate,
  //     talentRateType,
  //     status,
  //     title,
  //     engagement,
  //     application,
  //   } = selected;

  //   const data = {
  //     uuid,
  //     user: user?.uuid,
  //     team: team?.uuid,
  //     startDate,
  //     endDate,
  //     brandRate,
  //     brandRateType,
  //     talentRate,
  //     talentRateType,
  //     status,
  //     title,
  //     engagement: engagement?.uuid,
  //     application: application?.uuid,
  //   };

  //   setIsSaving(true);
  //   putOrgAssignments(orgUuid, uuid, data)
  //     .then(({ data }) => {
  //       setIsSaving(false);
  //       setUpdateRow(data);
  //       setIsShown(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsSaving(false);
  //     });
  // };

  // const validateFields = () => {
  //   let errors = {};
  //   const { startDate, endDate } = selected;
  //   console.log('startDate', startDate);
  //   console.log('endDate', endDate);

  //   if (startDate && endDate && isAfter(new Date(startDate), new Date(endDate))) {
  //     errors = { ...errors, startDate: 'Start Date should not be later than End Date.' };
  //   }

  //   if (Object.keys(errors).length > 0) {
  //     setErrors(errors);
  //     return false;
  //   }

  //   return true;
  // };

  return (
    <Fragment>
      <Card>
        <Card.Header>
          <div className={Styles.sectionTitle}>{`Briefs`}</div>
          <input
            className={Styles.searchField}
            name='search'
            type='text'
            placeholder='Search Briefs'
            onChange={handleSearchChange}
          />
          <div>
            {/* <MemoSelectMenu
              title='Filter by Status'
              hasFilter={true}
              height={175}
              position={Position.BOTTOM_RIGHT}
              options={[...BriefStatus.values].map((s) => ({
                label: s.label,
                value: String(s.id),
              }))}
              isMultiSelect={true}
              selected={String(status?.value)}
              onSelect={handleStatusSelect}
              onDeselect={handleStatusDeselect}>
              <button className={Styles.filter}>
                {status?.label || `Filter by Status`}
                <div className={Styles.arrowDown} />
              </button>
            </MemoSelectMenu> */}
          </div>
        </Card.Header>
        <Card.Item noPadding>
          <PaginatedTable
            columns={columns}
            updateRow={updateRow}
            rowAccessor='uuid'
            getListData={(config) => getOrgBriefs(orgUuid, config)}
            search={search}
            filters={filterMemo}
            tableClass={Styles.table}
            headerCellClass={Styles.headerCell}
            cellClass={Styles.cell}
            emptyContainerClass={Styles.emptyContainer}
            paginationClass={Styles.pagination}
            defaultLimit={5}
            defaultPaginationOptions={[5, 20, 50, 100]}
            showParams={false}
          />
        </Card.Item>
      </Card>
      {/* <Dialog
        isShown={isShown}
        title={`Edit Assignment`}
        onCloseComplete={() => setIsShown(false)}
        confirmLabel={`Save`}
        width={1000}
        preventBodyScrolling
        onConfirm={handleSave}
        isConfirmLoading={isSaving}
        contentContainerProps={{ overflow: 'auto' }}
        containerProps={{ minHeight: '220px' }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}>
        <AssignmentForm
          data={selected}
          orgUuid={orgUuid}
          onFormChange={handleFormChange}
          currentUser={currentUser}
          errors={errors}
          hideBgColorArea
        />
      </Dialog> */}
    </Fragment>
  );
};

List.propTypes = {
  orgUuid: PropTypes.string,
};

export default List;
