import { Component } from 'react';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import { putResource, putResourceCall } from '@apis/common';
import { CallStatus, PreferredPlatform } from '@hiredigital/lib/helpers/enum';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';
import { timezoneList } from '@data/timezoneList';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';

import { countryList } from '@data/countryList';

class CallSchedule extends Component {
  static propTypes = {
    data: PropTypes.object,
    briefUuid: PropTypes.string,
    orgUuid: PropTypes.string,
    // resource: PropTypes.string,
    // isBrief: PropTypes.bool,
    callResource: PropTypes.string,
    phoneResource: PropTypes.string,
    uuid: PropTypes.string,
  };

  static defaultProps = {
    // resource: `brief-registrations`,
    orgUuid: '',
    // isBrief: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...props.data,
      isSaving: false,
      isPhoneValid: true,
    };
  }

  handleChange = (event, valueKey) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: valueKey ? value[valueKey] : value,
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  handleEnumChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value.id,
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  handleAutoSave = (timeout = 3000) => {
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.handleSubmit();
    }, timeout);
  };

  handleSubmit = async () => {
    this.setState({ isSaving: true });

    try {
      if (this.state.isPhoneValid) {
        const {
          startTime,
          endTime,
          preferred,
          timezone,
          status,
          conferenceUrl,
          skype,
          phoneCountry,
          phoneNumber,
          organizationName,
        } = this.state;

        let payload = {};
        const phone = {
          phoneNumber,
          phoneCountry: phoneCountry?.id,
        };

        if (this.props.phoneResource === 'orgs') {
          payload = { contact: { ...phone } };
        } else if (this.props.phoneResource === 'brief-registrations') {
          payload = { ...phone, organizationName };
        } else if (this.props.phoneResource === 'prospects') {
          payload = { ...phone };
        }
        await this.savePhone(payload);

        const callData = {
          ...(startTime && { startTime }),
          ...(endTime && { endTime }),
          timezone: timezone.id,
          preferred,
          status,
          conferenceUrl,
          skype,
          phoneCountry: (phoneCountry && phoneCountry.id) || null,
          phoneNumber,
        };
        await this.saveCall(callData);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => this.setState({ isSaving: false }), 250);
    }
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date }, this.handleAutoSave);
  };

  handlePhoneCountryChange = (value) => {
    this.setState({ phoneCountry: value }, this.handleAutoSave);
  };

  handlePhoneNumberChange = (value) => {
    this.setState({ phoneNumber: value }, this.handleAutoSave);
  };

  saveCall = (data) => {
    const { callResource, uuid } = this.props;
    // const resource = isBrief ? 'briefs' : 'brief-registrations';
    return putResourceCall(callResource, uuid, this.state.id, data);
  };

  savePhone = (data) => {
    const { phoneResource } = this.props;
    // const resource = isBrief ? 'orgs' : 'brief-registrations';
    // const uuid = isBrief ? orgUuid : briefUuid;
    const uuid = phoneResource === 'orgs' ? this.props.orgUuid : this.props.uuid;
    return putResource(phoneResource, uuid, data);
  };

  render() {
    return (
      <Card>
        <Card.Header>
          {`Call Details`}
          {this.state.conferenceUrl && (
            <a className={Styles.link} href={this.state.conferenceUrl} target='_blank'>
              Open Meeting Link
            </a>
          )}
          {this.state.phoneNumber && (
            <a
              className={Styles.link}
              href={`tel:${this.state.phoneCountry?.dialCode || ''}${this.state.phoneNumber}`}>
              {`Call ${this.state.phoneCountry?.dialCode || ''}${this.state.phoneNumber}`}
            </a>
          )}
        </Card.Header>
        <Card.Item>
          <InputGroup>
            <InputContainer>
              <PhoneNumber
                phoneCountry={this.state.phoneCountry}
                phoneNumber={this.state.phoneNumber}
                onPhoneCountryChange={this.handlePhoneCountryChange}
                onPhoneNumberChange={this.handlePhoneNumberChange}
                getCountries={async () => ({ data: countryList })}
                onValidate={(v) => this.setState({ isPhoneValid: v })}
              />
            </InputContainer>

            <InputContainer>
              <Select
                data-test-id='timezone'
                defaultValue={this.state.timezone}
                name={`timezone`}
                label={`Timezone`}
                classNamePrefix='s-contact'
                getOptionLabel={({ name, label, offset }) => {
                  return `GMT ${offset} ${name || label}`;
                }}
                getOptionValue={({ id }) => id}
                options={timezoneList}
                onChange={this.handleChange}
                required
              />
            </InputContainer>
          </InputGroup>

          <InputGroup>
            <InputContainer>
              <Select
                data-test-id='preferred'
                className={Styles.select}
                defaultValue={PreferredPlatform.getEnum(this.state.preferred)}
                classNamePrefix='s-contact'
                name='preferred'
                label='Call Preference'
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                options={PreferredPlatform.values}
                onChange={(e) => this.handleChange(e, 'id')}
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name='skype'
                label='Skype'
                defaultValue={this.state.skype}
                onChange={this.handleChange}
              />
            </InputContainer>
          </InputGroup>

          <InputGroup>
            <InputContainer>
              <TextInput
                name='conferenceUrl'
                label='Conference Url'
                defaultValue={this.state.conferenceUrl}
                onChange={this.handleChange}
              />
            </InputContainer>
            <InputContainer>
              <Select
                data-test-id='status2'
                defaultValue={CallStatus.getEnum(this.state.status)}
                name={`status`}
                label={`Status`}
                classNamePrefix='s-contact'
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                options={CallStatus.values}
                onChange={(e) => this.handleChange(e, 'id')}
                required
              />
            </InputContainer>
          </InputGroup>

          <InputGroup>
            <InputContainer>
              {/* <DateSelector
                name='startTime'
                label='Start Time'
                value={this.state.startTime}
                timeFormat={true}
                onChange={(date) => this.handleDateChange(date, 'startTime')}
                viewMode={`time`}
                isMasked={false}
              /> */}
              <DatePicker
                id='schedStartTime'
                name='startTime'
                label='Start Time'
                value={this.state.startTime}
                dateFormat='MM/dd/yyyy hh:mm aa'
                onChange={(date) => this.handleDateChange(date, 'startTime')}
                showTimeInput
              />
            </InputContainer>
            <InputContainer>
              {/* <DateSelector
                name='endTime'
                label='End Time'
                value={this.state.endTime}
                timeFormat={true}
                onChange={(date) => this.handleDateChange(date, 'endTime')}
                viewMode={`time`}
                isMasked={false}
              /> */}
              <DatePicker
                id='schedEndTime'
                name='endTime'
                label='End Time'
                value={this.state.endTime}
                dateFormat='MM/dd/yyyy hh:mm aa'
                onChange={(date) => this.handleDateChange(date, 'endTime')}
                showTimeInput
              />
            </InputContainer>
          </InputGroup>
        </Card.Item>
        <Card.Footer right>
          <Button
            onClick={() => this.handleAutoSave(0)}
            name='submit'
            type={Button.Type.BLUE}
            isLoading={this.state.isSaving}>
            {`Save`}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default CallSchedule;
