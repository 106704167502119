import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import Card from '@hiredigital/ui/Card';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

import toast from 'react-hot-toast';
import withEmailLabel from '@hoc/withEmailLabel';
import {
  getTalentRegistration,
  saveTalentRegistration,
  convertTalentRegistration,
  messageTalentRegistration,
  patchTalentRegistration,
} from '@apis/talents';

import { isAnyTalentAdmin, isAdmin } from '@helpers/permissions';
import { withUser } from '@context/user';

import { setPageTitle, addHttpPrefix } from '@hiredigital/lib/helpers/utils';
import { onUsersLoadOptions, onEngagementLoadOptions } from '@apis/dropdown';
import { UserRegistrationStatus, Site } from '@hiredigital/lib/helpers/enum';
import { RatesApiManager } from '@hiredigital/lib/apis/class/rates';
import { authRequest } from '@apis/utils';

import { countryList } from '@data/countryList';

import AppLayout from '@components/Layout/AppLayout';

import Quill from '@hiredigital/ui/Quill/Editor';
import SingleUpload from '@hiredigital/ui/Form/File/NewSingle';
import References from '@hiredigital/ui/Form/References';
import ServiceRateList from '@components/ServiceRate/ServiceRateList';
import TalentRateList from '@hiredigital/ui/TalentRateList';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import FileViewer from '@hiredigital/ui/FileViewer/FileViewer';

import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';
import {
  deleteUserRegistrationFile,
  deleteUserRegistrationLink,
  postUserRegistrationFile,
  postUserRegistrationLink,
} from '@apis/users';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);
const UserSelect = withEmailLabel(Select);

class TalentRegistrationDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      userUuid: this.props.match.params.userUuid,
      autoSaveTimeout: null,
      isSaving: false,
      hasInitLoad: false,
      teamName: null,
      phoneNumber: null,
      email: null,
      firstName: null,
      lastName: null,
      referral: null,
      notes: null,
      phoneCountry: null,
      errors: {},
      user: null,
      resume: null,
      attachments: null,
      links: null,
      type: null,
      site: null,
      uuid: null,
      created: null,
      resume: null,
      showResumes: JSON.parse(localStorage.getItem('showResumes')) || false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.visibility !== this.state.visibility ||
      nextState.isAddInProgress !== this.state.isAddInProgress ||
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.isSaving !== this.state.isSaving ||
      nextState.resume !== this.state.resume ||
      nextState.showResumes !== this.state.showResumes
    ) {
      return true;
    }

    return false;
  }

  componentDidMount = () => {
    getTalentRegistration(this.state.userUuid).then(({ data }) => {
      setPageTitle(
        `Talent Registrations | ${
          `${data.firstName} ${data.lastName}` || 'New Talent Registration'
        }`
      );
      this.populateFields(data, () => {
        this.setState({ hasInitLoad: true });
      });
    });
  };

  populateFields = (data, completionCallback) => {
    this.setState({ ...data }, () => {
      if (completionCallback) {
        completionCallback();
      }
    });
  };

  handleChange = (event, valueKey, completionCallback) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: valueKey ? value[valueKey] : value,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
    this.handleAutoSave();
  };

  handlePhoneCountryChange = (value, completionCallback) => {
    this.setState(
      {
        phoneCountry: value,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };

  handlePhoneNumberChange = (value, completionCallback) => {
    this.setState(
      {
        phoneNumber: value,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };

  handleConvertUser = () => {
    this.setState({ isConverting: true });
    convertTalentRegistration(this.state.uuid)
      .then(({ data }) => {
        if (data?.user?.uuid) {
          this.setState({ isConverting: false });

          toast.success('Talent has been converted, redirecting to profile...', {
            id: 'Talent Converted',
          });

          setTimeout(() => {
            this.props.history.push('/profiles/' + data.user.uuid);
          }, 500);
        }
      })
      .catch((error) => {
        toast.error('There was an issue with converting this registration', {
          id: 'Talent Not Converted',
        });

        this.setState({ isConverting: false });
      });
  };

  handleMessageTalent = () => {
    this.setState({ isMessaging: true });
    messageTalentRegistration(this.state.uuid)
      .then(({ data }) => {
        toast.success('Message has been queued', { id: 'Talent Message Queued' });
        if (data?.user?.uuid) {
          this.setState({ isMessaging: false });
        }
      })
      .catch((error) => {
        toast.error('There was an issue with queueing this message', {
          id: 'Talent Message Not Queued',
        });
        this.setState({ isMessaging: false });
      });
  };

  handleImmediateSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 0),
    });
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleToggleChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
    this.handleImmediateSave();
  };

  handleSubmit = (storeResponse = true) => {
    const {
      name,
      phoneNumber,
      email,
      firstName,
      lastName,
      teamName,
      isTeam,
      referral,
      type,
      phoneCountry,
      notes,
      user,
      status,
      created,
      engagement,
    } = this.state;

    const data = {
      name,
      phoneNumber,
      email,
      firstName,
      lastName,
      referral,
      type,
      teamName,
      isTeam,
      phoneCountry: (phoneCountry && phoneCountry.id) || null,
      user: (user && user.uuid) || null,
      notes,
      status,
      created,
      engagement: engagement?.uuid,
    };

    this.setState({ errors: {}, isSaving: true });
    saveTalentRegistration(this.state.userUuid, data).then(
      (response) => {
        if (storeResponse) {
          this.populateFields(response);
        }
        this.setState({ isSaving: false, autoSaveTimeout: null });
      },
      (error) => {
        const errors = error.response.data;
        let stateErrors;
        Object.keys(errors).forEach((key) => {
          stateErrors = { ...stateErrors, [key]: errors[key][0] };
        });
        this.setState({
          isSaving: false,
          autoSaveTimeout: null,
          errors: stateErrors,
        });
      }
    );
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleSocialChange = (event, prefix, removeBaseUrl = true) => {
    const { name, value } = event.target;
    const link =
      (removeBaseUrl
        ? value && value.replace(prefix, '')
        : value && addHttpPrefix(value, prefix)) || '';
    this.setState({ [name]: link });
    this.handleAutoSave();
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleResumeViewChange = (event) => {
    const { name, checked } = event.target;
    localStorage.setItem('showResumes', checked);
    this.setState({ showResumes: checked });
  };

  render() {
    const adminPermission = this.props.currentUser?.adminPermission;

    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/talent-registrations'>
              {`Talent Registrations`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            {this.state.hasInitLoad && (
              <div className={PageStyles.headerActive}>
                {`${this.state.firstName} ${this.state.lastName}` || 'Untitled'}
              </div>
            )}
            <div className={PageStyles.headerAction}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Toggle
                  id='show-resumes'
                  name='showResumes'
                  defaultChecked={this.state.showResumes}
                  onChange={this.handleResumeViewChange}
                />
                <label htmlFor='show-resumes' className={Styles.toggleLabel}>{`Show Resume`}</label>
              </div>
            </div>
          </div>
        }>
        {this.state.hasInitLoad && (
          <Fragment>
            <div style={{ display: 'flex' }}>
              {this.state.showResumes && this.state.resume && (
                <div className={PageStyles.sideContainer}>
                  <FileViewer
                    className={Styles.resumeImage}
                    title={this.state.resume}
                    url={this.state.resume}
                  />
                </div>
              )}
              <div className={PageStyles.container}>
                <div className={classNames(PageStyles.formContainer, PageStyles.adminContainer)}>
                  <div className={classNames(Styles.row, Styles.topItem)}>
                    <h3 className={PageStyles.headline}>{`Settings`}</h3>
                    <Button
                      name='submit'
                      className={Styles.saveButton}
                      type={Button.Type.BLUE}
                      onClick={this.handleImmediateSave}
                      isLoading={this.state.isSaving}>
                      {`Save`}
                    </Button>
                  </div>
                  <div style={{ maxWidth: '100%', marginTop: '20px' }}>
                    <div className={Styles.notes}>
                      <Quill
                        name='notes'
                        label='Notes'
                        placeholder='Review Notes'
                        defaultValue={this.state.notes}
                        onChange={this.handleChange}
                        legacyCompat
                      />
                    </div>
                  </div>
                </div>

                <Card>
                  <Card.Header>
                    {`Talent Registrations`}

                    <div className={Styles.sideLinks}>
                      {this.state.user && this.state.user.name && (
                        <Link className={Styles.otherLink} to={`/profiles/${this.state.user.uuid}`}>
                          {`View ${this.state.user.name} `}
                        </Link>
                      )}
                      {` | `}
                      {this.state.resume ? (
                        <a
                          className={Styles.sideLinkItem}
                          href={this.state.resume}
                          style={{ marginLeft: '10px' }}
                          target='_blank'>
                          {`Download Resume`}
                        </a>
                      ) : (
                        <span
                          className={Styles.sideLinkItem}
                          style={{
                            marginLeft: '10px',
                          }}>{`No Resume on File`}</span>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Item>
                    <div className={PageStyles.leftRightContainer}>
                      <div className={PageStyles.leftFields}>
                        <InputGroup>
                          <InputContainer>
                            <TextInput
                              name='firstName'
                              label='First Name'
                              defaultValue={this.state.firstName}
                              placeholder='First Name'
                              onChange={this.handleChange}
                              error={this.state.errors.firstName}
                            />
                          </InputContainer>
                          <InputContainer>
                            <TextInput
                              name='lastName'
                              label='Last Name'
                              defaultValue={this.state.lastName}
                              placeholder='Last Name'
                              onChange={this.handleChange}
                              error={this.state.errors.lastName}
                            />
                          </InputContainer>
                        </InputGroup>
                        <InputGroup className={Styles.inputGroup}>
                          <InputContainer>
                            <PhoneNumber
                              phoneCountry={this.state.phoneCountry}
                              phoneNumber={this.state.phoneNumber}
                              onPhoneCountryChange={this.handlePhoneCountryChange}
                              onPhoneNumberChange={this.handlePhoneNumberChange}
                              getCountries={async () => ({ data: countryList })}
                              error={this.state.errors.phoneNumber}
                              shouldValidate={false}
                            />
                          </InputContainer>
                          {/* <div className={Styles.phoneGroup}>
                        <div className={Styles.country}>
                          <Select
                            data-test-id='phoneCountry'
                            className={Styles.select}
                            defaultValue={this.state.phoneCountry}
                            classNamePrefix='s-contact'
                            name='phoneCountry'
                            label='Code'
                            error={this.state.errors.phoneCountry}
                            getOptionLabel={({ dialCode }) => dialCode}
                            getOptionValue={({ id }) => id}
                            options={countryList}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className={Styles.phoneNumber}>
                          <TextInput
                            name='phoneNumber'
                            label='Phone Number'
                            defaultValue={this.state.phoneNumber}
                            onChange={this.handleChange}
                            error={this.state.errors.phoneNumber}
                          />
                        </div>
                      </div> */}
                          <InputContainer className={Styles.inputContainerRight}>
                            <TextInput
                              name='email'
                              label='Email'
                              defaultValue={this.state.email}
                              placeholder='Email'
                              onChange={this.handleChange}
                              error={this.state.errors.email}
                            />
                          </InputContainer>
                        </InputGroup>

                        <InputGroup>
                          <InputContainer>
                            <UserSelect
                              id='userSelect'
                              className={Styles.select}
                              defaultValue={this.state.user}
                              classNamePrefix='s-contact'
                              name='user'
                              label='User'
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ uuid }) => uuid}
                              options={this.state.users}
                              onChange={this.handleChange}
                              isPaginated
                              loadOptions={onUsersLoadOptions}
                            />
                          </InputContainer>

                          <InputContainer>
                            <TextInput
                              name='teamName'
                              label='Team Name'
                              defaultValue={this.state.teamName}
                              placeholder='Team Name'
                              onChange={this.handleChange}
                              error={this.state.errors.teamName}
                            />
                          </InputContainer>
                        </InputGroup>

                        <InputGroup>
                          <InputContainer>
                            <Select
                              data-test-id='engagement'
                              className={Styles.select}
                              defaultValue={this.state.engagement}
                              classNamePrefix='s-contact'
                              name='engagement'
                              label='Engagement'
                              getOptionLabel={({ title }) => title || 'No title'}
                              getOptionValue={({ uuid }) => uuid}
                              //options={Site.values}
                              isPaginated
                              loadOptions={onEngagementLoadOptions}
                              onChange={this.handleChange}
                            />
                          </InputContainer>
                          <InputContainer>
                            <Select
                              data-test-id='site'
                              className={Styles.select}
                              defaultValue={Site.getEnum(this.state.site)}
                              classNamePrefix='s-contact'
                              name='site'
                              label='Site'
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={Site.values}
                              onChange={(e) => this.handleChange(e, 'id')}
                            />
                          </InputContainer>
                        </InputGroup>

                        <InputContainer>
                          <SingleUpload
                            name='resume'
                            label='Resume'
                            fileUploadRequest={(data, conf) =>
                              patchTalentRegistration(this.state.userUuid, data, conf)
                            }
                            fileRemoveRequest={() =>
                              patchTalentRegistration(this.state.userUuid, { resume: null })
                            }
                            // apiFileUpload={`admin/user-registrations/${this.state.userUuid}/`}
                            // onFileRemovePayloadGenerate={(key) => {
                            //   const formData = new FormData();
                            //   formData.append(key, '');
                            //   return formData;
                            // }}
                            idleLabel='Upload'
                            // method='patch'
                            value={this.state.resume}
                            onResponse={this.populateFields}
                            hideRemove={false}
                          />
                        </InputContainer>
                        <div>
                          <References
                            attachments={this.state.attachments}
                            links={this.state.links}
                            postLinkRequest={(payload, config) =>
                              postUserRegistrationLink(this.state.userUuid, payload, config)
                            }
                            postFileRequest={(payload, config) =>
                              postUserRegistrationFile(this.state.userUuid, payload, config)
                            }
                            deleteLinkRequest={(id) =>
                              deleteUserRegistrationLink(this.state.userUuid, id)
                            }
                            deleteFileRequest={(id) =>
                              deleteUserRegistrationFile(this.state.userUuid, id)
                            }
                            // server={`admin/user-registrations/${this.state.userUuid}`}
                          />
                        </div>
                      </div>
                      <div className={PageStyles.rightFields}>
                        <InputContainer>
                          <Select
                            data-test-id='status'
                            className={Styles.select}
                            defaultValue={UserRegistrationStatus.getEnum(this.state.status)}
                            classNamePrefix='s-contact'
                            name='status'
                            label='Status'
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={UserRegistrationStatus.values}
                            onChange={(e) => this.handleChange(e, 'id')}
                          />
                        </InputContainer>
                        <InputContainer>
                          <TextInput
                            name='uuid'
                            label='Uuid'
                            defaultValue={this.state.uuid}
                            placeholder='Uuid'
                            onChange={this.handleChange}
                            error={this.state.errors.uuid}
                            locked
                          />
                        </InputContainer>
                        <InputContainer
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}>
                          <Toggle
                            id='is-team'
                            name='isTeam'
                            defaultChecked={this.state.isTeam}
                            onChange={this.handleToggleChange}
                          />
                          <label
                            htmlFor='request-private'
                            className={Styles.toggleLabel}>{`Team`}</label>
                        </InputContainer>
                        <InputContainer>
                          <TextInput
                            name='referral'
                            label='Referral'
                            defaultValue={this.state.referral}
                            placeholder='Referral'
                            onChange={this.handleChange}
                            error={this.state.errors.referral}
                          />
                        </InputContainer>
                        <InputContainer>
                          <TextInput
                            name='type'
                            label='Type'
                            defaultValue={this.state.type}
                            error={this.state.errors.type}
                            onChange={this.handleChange}
                          />
                        </InputContainer>
                        <InputContainer>
                          <DatePicker
                            id='talent-regCreated'
                            name='created'
                            label='Created'
                            value={this.state.created}
                            onChange={(date) => this.handleDateChange(date, 'created')}
                          />
                        </InputContainer>
                      </div>
                    </div>
                  </Card.Item>
                  <Card.Footer right sticky>
                    <>
                      <Button
                        name='convert'
                        className={Styles.messageButton}
                        type={Button.Type.BLUE_OUTLINE}
                        onClick={this.handleMessageTalent}
                        isLoading={this.state.isMessaging}>
                        {`Message To Complete`}
                      </Button>
                      <Button
                        name='convert'
                        className={Styles.convertButton}
                        type={Button.Type.BLUE_OUTLINE}
                        onClick={this.handleConvertUser}
                        isLoading={this.state.isConverting}>
                        {`Convert to User`}
                      </Button>
                    </>

                    <Button
                      name='submit'
                      className={Styles.saveButton}
                      type={Button.Type.BLUE}
                      onClick={this.handleImmediateSave}
                      isLoading={this.state.isSaving}>
                      {`Save`}
                    </Button>
                  </Card.Footer>
                </Card>

                {isNewRates
                  ? ((this.props.currentUser && isAnyTalentAdmin(this.props.currentUser)) ||
                      adminPermission?.userRegistrationTalentRate) && (
                      <div>
                        <TalentRateList
                          type={TalentRateList.Type.ADMIN}
                          apiManager={new RatesApiManager(authRequest)}
                          resourceUuid={this.state.userUuid}
                          resource={`user-registrations`}
                          talentPermission={adminPermission?.userRegistrationTalentRate}
                          showCopySkillsOption={false}
                        />
                      </div>
                    )
                  : ((this.props.currentUser && isAnyTalentAdmin(this.props.currentUser)) ||
                      adminPermission?.userRegistrationClientRate ||
                      adminPermission?.userRegistrationTalentRate) && (
                      <div>
                        <ServiceRateList
                          talentPermission={adminPermission?.userRegistrationTalentRate}
                          clientPermission={adminPermission?.userRegistrationClientRate}
                          resourceUuid={this.state.userUuid}
                          resource={`user-registrations`}
                          showCopySkillsOption={false}
                          hideClient
                        />
                      </div>
                    )}
              </div>
            </div>
          </Fragment>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default withUser(TalentRegistrationDetail);
