import { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip, Position } from 'evergreen-ui';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import Select from '@hiredigital/ui/Input/Select';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import Refresh from '@hiredigital/ui/Icon/icons/refresh.inline.svg';

import debounce from 'lodash/debounce';
import isAfter from 'date-fns/isAfter';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';
import { toISOExactDateTime } from '@hiredigital/lib/helpers/date';

import AppLayout from '@components/Layout/AppLayout';

import { CancelToken } from '@apis/utils';
import { getOrg } from '@apis/orgs';
import { getUser } from '@apis/users';
import { getFinanceTopLevel } from '@apis/tracking';
import { onTimetrackerUsersLoadOptions, onAllOrgsLoadOptions } from '@apis/dropdown';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import EntryTreeItem from './components/EntryTreeItem';

import withEmailLabel from '@hoc/withEmailLabel';
import { withUser } from '@context/user';

import ListStyles from '@styles/PageList.module.scss';
import Styles from './list.module.scss';

const UserSelect = withEmailLabel(Select);

let cancel;

class EventList extends Component {
  static propTypes = {
    location: PropTypes.object,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const query = queryString.parse(props.location.search);
    this.state = {
      isLoaded: false,
      isLoadingMore: false,
      nextPage: 1,
      results: [],
      hasMore: true,
      organization: null,
      start: query?.start ? new Date(query.start) : startOfMonth(subMonths(new Date(), 1)),
      end: query?.end ? new Date(query.end) : endOfMonth(subMonths(new Date(), 1)),
      user: null,
      isStale: false,
      isDuration: true,
      topLevelOrganization: query?.topLevelOrganization || false,
    };
    this.performDebouncedSearch = debounce(this.searchevents, 750);
  }

  componentDidMount = () => {
    setPageTitle('Timetracker Tree');
    const promises = [];
    if (this.props.location.search) {
      const search = queryString.parse(this.props.location.search);
      promises.push(
        search.user ? getUser(search.user).then((response) => response.data) : undefined,
        search.organization
          ? getOrg(search.organization).then((response) => response.data)
          : undefined
      );
    }

    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    Promise.all(promises).then(([user, organization]) => {
      this.setState({ user, organization });
      this.getEvents({
        start, // start of day
        end, // end of day
        user: user ? user.uuid : null,
        organization: organization ? organization.uuid : null,
        page: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    });
  };

  componentDidUpdate = () => {
    const query = queryString.parse(this.props.location.search);
    const newTopLevelOrganization = query?.topLevelOrganization || false;
    if (this.state.topLevelOrganization === newTopLevelOrganization) return;
    this.setState(
      {
        topLevelOrganization: newTopLevelOrganization,
      },
      () => this.searchevents()
    );
  };

  getEvents = (params, clearResults = false) => {
    if (cancel) {
      cancel();
    }
    const payload = {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };

    this.setState({ isLoadingMore: true, isLoaded: false, ...(clearResults && { results: [] }) });
    getFinanceTopLevel(this.state.topLevelOrganization, payload).then(
      ({ data }) => {
        const list = data.results.filter((v) => 1.0 * v?.totalDuration > 0.0);
        const results = clearResults ? list : [...this.state.results, ...list];
        this.setState({
          results,
          nextPage: data.meta.nextPage,
          isLoaded: true,
          isLoadingMore: false,
          hasMore: !!data.meta.nextPage,
        });
      },
      (error) => {
        console.error('Error occured during page request.');
        this.setState({
          isLoaded: false,
          hasMore: false,
          isLoadingMore: false,
        });
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
        isLoaded: false,
      },
      () => {
        this.performDebouncedSearch();
      }
    );
  };

  handleStartDateChange = (date) => {
    this.setState(
      {
        start: date,
        isLoaded: false,
      },
      () => {
        this.performDebouncedSearch();
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        end: date,
        isLoaded: false,
      },
      () => {
        this.adjustDateStart(date);
        this.performDebouncedSearch();
      }
    );
  };

  adjustDateStart = (endDate) => {
    if (!!this.state.start && isAfter(new Date(this.state.start), endDate)) {
      this.setState({ start: endDate });
    }
  };

  searchevents = () => {
    this.setState({ isStale: false });

    let searchQuery = {};

    if (this.state.topLevelOrganization) {
      searchQuery.topLevelOrganization = this.state.topLevelOrganization;
    }

    if (this.state.user) {
      searchQuery.user = this.state.user.uuid;
    }

    if (this.state.organization) {
      searchQuery.organization = this.state.organization.uuid;
    }

    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);
    if (this.state.start) {
      searchQuery.start = start;
      searchQuery.end = end;
    }

    this.getEvents(
      {
        start,
        end,
        user: this.state.user ? this.state.user.uuid : null,
        organization: this.state.organization ? this.state.organization.uuid : null,
        page: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      true
    );

    const searchQueryString = queryString.stringify(searchQuery, { sort: false });
    this.props.history.push(
      '/timetracker/tree' + (searchQueryString ? '?' + searchQueryString : '')
    );
  };

  loadMoreEvents = (page) => {
    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    this.getEvents({
      start,
      end,
      user: this.state.user ? this.state.user.uuid : null,
      organization: this.state.organization ? this.state.organization.uuid : null,
      page: this.state.nextPage,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  toggleDurationFormat = () => {
    this.setState({ isDuration: !this.state.isDuration });
  };

  setStale = () => {
    this.setState({ isStale: true });
  };

  render() {
    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={ListStyles.headerRow}>
            <div className={ListStyles.headerActive}>{`Timetracker Tree`}</div>
            <div className={Styles.filterContainer}>
              <span className={Styles.filterText}>{`Filter`}</span>
              <div className={Styles.filterFields}>
                <Fragment>
                  <UserSelect
                    id='userID'
                    className={Styles.select}
                    value={this.state.user}
                    classNamePrefix='s-contact'
                    name='user'
                    label='Talent'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    options={this.state.users}
                    onChange={this.handleChange}
                    isPaginated
                    loadOptions={onTimetrackerUsersLoadOptions}
                    isClearable
                  />
                  <Select
                    data-test-id='organization'
                    className={Styles.select}
                    value={this.state.organization}
                    classNamePrefix='s-contact'
                    name='organization'
                    label='Organization'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    onChange={this.handleChange}
                    loadOptions={onAllOrgsLoadOptions}
                    isPaginated
                    isClearable
                  />
                  <div className={Styles.dateInput}>
                    <DatePicker
                      id='eventsStart'
                      name='start'
                      label='Start Date'
                      value={this.state.start}
                      onChange={this.handleStartDateChange}
                    />
                  </div>
                  <div className={Styles.dateInput}>
                    <DatePicker
                      id='eventsEnd'
                      name='end'
                      label='End Date'
                      value={this.state.end}
                      onChange={this.handleEndDateChange}
                      minDate={this.state.start ? new Date(this.state.start) : undefined}
                    />
                  </div>
                  {this.state.isStale && (
                    <Tooltip
                      position={Position.RIGHT}
                      content={
                        <span style={{ color: 'white', fontSize: '14px' }}>
                          Results may be stale.
                          <br />
                          Click to reload.
                        </span>
                      }>
                      <span>
                        <Button
                          className={Styles.refreshIcon}
                          onClick={this.searchevents}
                          type={Button.Type.LIGHT_GRAY}
                          size={Button.Size.ICON}>
                          <Refresh />
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        }>
        {this.state.results && (
          <InfiniteScroll
            initialLoad={true}
            className={Styles.container}
            pageStart={1}
            loadMore={this.loadMoreEvents}
            hasMore={this.state.hasMore && !this.state.isLoadingMore}
            threshold={500}
            useWindow={false}
            loader={
              <div className={classNames(Styles.loaderArea)} key={0}>
                {this.state.isLoadingMore && <Loader />}
              </div>
            }>
            <div className={classNames(Styles.card, Styles.sticky)}>
              <div className={Styles.list}>
                <div className={Styles.action}>
                  <div className={classNames(Styles.listSection, Styles.center, Styles.header)}>
                    {`Max Allocation (Hours)`}
                  </div>
                  <div
                    className={classNames(
                      Styles.listSectionSmall,
                      Styles.center,
                      Styles.header,
                      Styles.headerAction
                    )}
                    onClick={this.toggleDurationFormat}>
                    <Tooltip
                      position={Position.LEFT}
                      content={`Show as ${this.state.isDuration ? 'Timestamp' : 'Hours'}`}>
                      <span>Duration</span>
                    </Tooltip>
                  </div>
                  <div className={classNames(Styles.listSection, Styles.center, Styles.header)}>
                    {`Paid`}
                  </div>
                  <div
                    className={classNames(
                      Styles.listSectionSmall,
                      Styles.center,
                      Styles.header,
                      Styles.headerAction
                    )}
                    onClick={this.toggleDurationFormat}>
                    <Tooltip
                      position={Position.LEFT}
                      content={`Show as ${this.state.isDuration ? 'Timestamp' : 'Hours'}`}>
                      <span>Unpaid Duration</span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

            {this.state.results.map((talent, index) => (
              <div className={classNames(Styles.card)} key={`${talent.uuid}-${index}`}>
                <EntryTreeItem
                  key={talent.uuid}
                  data={{
                    search: {
                      start,
                      end,
                    },
                    type: this.state.topLevelOrganization ? 'client' : 'talent',
                    name: talent.name,
                    uuid: talent.uuid,
                    picture: talent.picture,
                    maxAllocation: talent.maxAllocation,
                    unlockedDuration: 1.0 * talent.unlockedDuration,
                    totalDuration: 1.0 * talent.totalDuration,
                    childType: 'relationship',
                    children: talent.relationships?.filter((v) => 1.0 * v?.totalDuration > 0.0),
                    // only on top level:
                    unlinkedTasks: talent.unlinkedTasks,
                    unlinkedEvents: talent.unlinkedEvents,
                    topLevelOrganization: this.state.topLevelOrganization,
                  }}
                  isDuration={this.state.isDuration}
                  updateUnlocked={this.setStale}
                />
              </div>
            ))}
          </InfiniteScroll>
        )}

        {!this.state.isLoaded && (
          <div style={{ margin: '' }}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default withUser(EventList);
