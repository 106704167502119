import { useMemo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button/Button';

import SelectMenu from '@hiredigital/ui/SelectMenu';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import ApplicationForm from './ApplicationForm';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import { getUserApplications, putUserApplication } from '@apis/users';
import { AdminApplicationStatus, RateType } from '@hiredigital/lib/helpers/enum';
import { copyTextToClipboard } from '@hiredigital/lib/helpers/utils';
import Styles from './UserApplications.module.scss';

import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

const EngagementColumn = ({ row, onEdit, onView, onCopyTalent, onCopyClient }) => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <button className={Styles.link} onClick={() => onEdit?.(row?.original)}>
        <MultiLineEllipsis elem={'span'}>
          {row?.original?.engagement?.title ||
            row?.original?.engagement?.talentType?.label ||
            row?.original?.engagement?.otherTalentType}
        </MultiLineEllipsis>
      </button>
      <div style={{ marginLeft: 'auto' }}>
        <SplitButton
          more={[
            { text: 'Edit', onClick: () => onEdit?.(row?.original) },
            { text: 'View Brief in Admin', onClick: () => onView?.(row?.original) },
            { text: 'Copy Talent Link', onClick: () => onCopyTalent?.(row?.original) },
            { text: 'Copy Client Link', onClick: () => onCopyClient?.(row?.original) },
          ]}
        />
      </div>
    </div>
  );
};

const TextRight = ({ value }) => <div style={{ textAlign: 'right', width: '100%' }}>{value}</div>;

const List = ({ userUuid, currentUser, talentPermission, clientPermission }) => {
  const [status, setStatus] = useState(null);
  //const [activeKey, setActiveKey] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [search, setSearch] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [updateRow, setUpdateRow] = useState(undefined);
  const [talentEngagementRate, setTalentEngagementRate] = useState();
  const [clientEngagementRate, setClientEngagementRate] = useState();
  let timeout = null;

  const handleEdit = (item) => {
    setSelected(item);
    setTalentEngagementRate(item?.talentEngagementRate);
    setClientEngagementRate(item?.clientEngagementRate);
    setIsShown(true);
  };

  const handleCopyClient = (a) => {
    copyTextToClipboard(`${process.env.APP_ENDPOINT}/briefs/${a?.engagement?.brief?.uuid}`);
  };

  const handleCopyTalent = (a) => {
    copyTextToClipboard(`${process.env.APP_ENDPOINT}/engagements/${a?.engagement?.uuid}`);
  };

  const handleViewBrief = (a) => window.open(`/briefs/${a?.engagement?.brief?.uuid}`, '_blank');

  const columns = useMemo(
    () => [
      {
        Header: 'Engagement',
        canSort: true,
        minWidth: 300,
        id: 'engagement__title',
        truncate: false,
        Cell: ({ row }) => (
          <EngagementColumn
            onEdit={handleEdit}
            onView={handleViewBrief}
            onCopyTalent={handleCopyTalent}
            onCopyClient={handleCopyClient}
            row={row}
          />
        ),
      },
      {
        Header: 'Client',
        canSort: true,
        minWidth: 200,
        id: 'engagement__brief__organization__name',
        truncate: false,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', overflow: 'hidden' }}>
            <img
              className={Styles.picture}
              src={row?.original?.engagement?.brief?.organization?.picture}
              alt=''
            />
            <MultiLineEllipsis elem={'span'}>
              {row?.original?.engagement?.brief?.organization?.name}
            </MultiLineEllipsis>
          </div>
        ),
      },

      {
        Header: () => <TextRight value={`Talent Rate`} />,
        id: 'talent_rate',
        canSort: true,
        width: 100,
        Cell: ({ row }) =>
          talentPermission &&
          (isNewRates ? (
            <TextRight value={`${row?.original?.talentEngagementRate?.rateDisplayText || ''}`} />
          ) : (
            <TextRight
              value={
                row?.original?.talentRate
                  ? `${row?.original?.talentRate} (${RateType.getLabel(
                      row?.original?.talentRateType
                    )})`
                  : ''
              }
            />
          )),
      },
      {
        Header: () => <TextRight value={`Client Rate`} />,
        id: 'brand_rate',
        canSort: true,
        width: 100,
        Cell: ({ row }) =>
          clientPermission &&
          (isNewRates ? (
            <TextRight value={`${row?.original?.clientEngagementRate?.rateDisplayText || ''}`} />
          ) : (
            <TextRight
              value={
                row?.original?.brandRate
                  ? `${row?.original?.brandRate} (${RateType.getLabel(
                      row?.original?.brandRateType
                    )})`
                  : ''
              }
            />
          )),
      },
      {
        Header: 'Status',
        canSort: true,
        id: 'status',
        width: 100,
        accessor: (a) => AdminApplicationStatus.getEnum(a?.status).label,
      },
    ],
    []
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleStatusSelect = (e) => setStatus(e.target.value);

  // const handleStatusDeselect = (v) => setStatus({});

  const filterMemo = useMemo(
    () => ({
      status: status?.id,
    }),
    [status]
  );

  const handleFormChange = ({ name, value }) => {
    const data = { ...selected, [name]: value };
    setSelected(data);
  };

  const handleSave = () => {
    const {
      uuid,
      user,
      team,
      brandRate,
      content,
      clientTerms,
      talentTerms,
      brandRateType,
      brandMinPer,
      brandMinHours,
      brandMaxPer,
      brandMaxHours,
      talentRate,
      talentRateType,
      talentMinPer,
      talentMinHours,
      talentMaxPer,
      talentMaxHours,
      status,
    } = selected;

    const data = {
      uuid,
      user: user?.uuid,
      team: team?.uuid,
      content,
      clientTerms,
      talentTerms,
      brandRate,
      brandRateType,
      brandMinPer,
      brandMinHours,
      brandMaxPer,
      brandMaxHours,
      talentRate,
      talentRateType,
      talentMinPer,
      talentMinHours,
      talentMaxPer,
      talentMaxHours,
      status,
      talentEngagementRate: talentEngagementRate?.uuid || null,
      clientEngagementRate: clientEngagementRate?.uuid || null,
    };

    setIsSaving(true);
    putUserApplication(userUuid, uuid, data)
      .then(({ data }) => {
        setIsSaving(false);
        setUpdateRow(data);
        setIsShown(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  return (
    <Fragment>
      <Card>
        <Card.Header>
          <div className={Styles.sectionTitle}>{`Engagement Applications`}</div>
          <input
            className={Styles.searchField}
            name='search'
            type='text'
            placeholder='Search Applications'
            onChange={handleSearchChange}
          />
          <div>
            {/* <MemoSelectMenu
              title='Filter by Status'
              hasFilter={true}
              height={175}
              position={Position.BOTTOM_RIGHT}
              options={[...AdminApplicationStatus.values].map((s) => ({
                label: s.label,
                value: String(s.id),
              }))}
              isMultiSelect={true}
              selected={String(status?.value)}
              onSelect={handleStatusSelect}
              onDeselect={handleStatusDeselect}>
              <button className={Styles.filter}>
                {status?.label || `Filter by Status`}
                <div className={Styles.arrowDown} />
              </button>
            </MemoSelectMenu> */}
            <SelectMenu
              placeholder='Filter by Status'
              onChange={handleStatusSelect}
              options={AdminApplicationStatus.values}
              isClearable
            />
          </div>
        </Card.Header>
        <Card.Item noPadding>
          <PaginatedTable
            columns={columns}
            updateRow={updateRow}
            rowAccessor='uuid'
            getListData={(config) => getUserApplications(userUuid, config)}
            search={search}
            filters={filterMemo}
            tableClass={Styles.table}
            headerCellClass={Styles.headerCell}
            cellClass={Styles.cell}
            emptyContainerClass={Styles.emptyContainer}
            paginationClass={Styles.pagination}
            defaultLimit={5}
            defaultPaginationOptions={[5, 20, 50, 100]}
            showParams={false}
          />
        </Card.Item>
      </Card>
      <Dialog
        isOpen={isShown}
        title={`Edit Application`}
        onClose={() => setIsShown(false)}
        width={1080}>
        <ApplicationForm
          data={selected}
          onFormChange={handleFormChange}
          currentUser={currentUser}
          hideBgColorArea
          talentEngagementRate={[talentEngagementRate, setTalentEngagementRate]}
          clientEngagementRate={[clientEngagementRate, setClientEngagementRate]}
          resourceUuid={selected?.uuid}
        />
        <div style={{ marginTop: '30px' }}>
          <Button onClick={handleSave} type={Button.Type.BLUE} isLoading={isSaving}>
            {`Save`}
          </Button>
          <Button onClick={() => setIsShown(false)} type={Button.Type.LIGHT_GRAY}>
            {`Cancel`}
          </Button>
        </div>
      </Dialog>
    </Fragment>
  );
};

List.propTypes = {
  userUuid: PropTypes.string,
  currentUser: PropTypes.object,
};

export default List;
