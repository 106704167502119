import { Fragment, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import qs from 'qs';
import convert from 'htmr';
import {
  InstantSearch,
  Configure,
  connectHits,
  createInfiniteHitsSessionStorageCache,
  Pagination,
  Stats,
} from 'react-instantsearch-dom';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import { UserStatus, ServiceRateType } from '@hiredigital/lib/helpers/enum';
import {
  isSearchElevatedAdmin,
  isTalentSearchBrandRatesAdmin,
  isTalentSearchTalentRatesAdmin,
} from '@helpers/permissions';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { useQuery } from '@hiredigital/lib/hooks/useQuery';

import AppLayout from '@components/Layout/AppLayout';
import Table from '@components/Table/Table';
import RefinementSelect from '@components/InstantSearch/RefinementSelect';
import SearchBox from '@components/InstantSearch/SearchBox';
import Highlight from '@components/InstantSearch/Highlight';

import withDataSource from '@hoc/withNewDataSource';
import { useUser } from '@context/user';

import Styles from '@styles/PageList.module.scss';
import TalentStyles from './talents.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

// const searchClient = algoliasearch(`${process.env.ALGOLIA_APP}`, `${process.env.ALGOLIA_KEY}`);
const searchClient = instantMeiliSearch(`${process.env.MSEARCH_API}`, `${process.env.MSEARCH_KEY}`);

const Hits = ({ hits, insights, hasPrevious, refinePrevious, hasMore, refineNext }) => {
  const currentUser = useUser();

  return (
    <>
      {hits.map((user, index) => {
        const userRates = isNewRates
          ? user?.talent_rates || user?.talentRates
          : user?.service_rates;

        return (
          <div className={classNames(Styles.talentCard)} key={index}>
            <Table.Cell sticky={true} width='330px'>
              <div style={{ display: 'flex' }}>
                <img
                  className={classNames(Styles.picture, Styles.smallPicture)}
                  src={user?.picture}
                  alt=''
                />
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <a href={`/profiles/${user.objectID}`}>
                      <p className={TalentStyles.name}>
                        <Highlight hit={user} attribute='name' />
                      </p>
                    </a>

                    <Dropdown drop='right' size='sm' style={{ margin: '-5px 0' }}>
                      <Dropdown.Toggle className={Styles.moreDropdown}>
                        <IconMoreVertical className={Styles.moreIcon} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/resumes/${
                            user.portfolio?.access_key || ''
                          }`}>
                          {`View Resume`}
                        </Dropdown.Item>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/resumes/${user.username}`}>
                          {`View Public Resume`}
                        </Dropdown.Item>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/portfolios/${
                            user.portfolio?.access_key || ''
                          }`}>
                          {`View Portfolio`}
                        </Dropdown.Item>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/portfolios/${user.username}`}>
                          {`View Public Portfolio`}
                        </Dropdown.Item>
                        {currentUser && isSearchElevatedAdmin(currentUser) && (
                          <Dropdown.Item
                            href={`/profiles/${user.objectID}`}>{`Edit Profile`}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <p className={TalentStyles.primarySkill}>
                    {(user?.portfolio?.primary_skill?.noun && (
                      <Highlight hit={user} attribute='portfolio.primary_skill.noun' />
                    )) ||
                      (user?.portfolio?.primary_skill?.label && (
                        <Highlight hit={user} attribute='portfolio.primary_skill.label' />
                      )) || <span className={Styles.inactive}>{'No Primary Skill'}</span>}
                  </p>

                  <p className={TalentStyles.headline}>
                    {(user?.profile?.headline && (
                      <Highlight hit={user} attribute='profile.headline' />
                    )) || <span className={Styles.inactive}>{'No Headline'}</span>}
                  </p>
                </div>
              </div>
            </Table.Cell>

            <Table.Cell width='250px'>
              {userRates &&
                userRates.map((rate, i) => {
                  const itemRate = isNewRates ? rate.max_rate || rate.maxRate : rate.rate;
                  const itemCurrency = isNewRates
                    ? rate.currency_code || rate.currencyCode
                    : rate.currency;

                  const isTalentSearchRatesAdmin =
                    !!currentUser &&
                    (currentUser.adminPermission?.talentClientRate ||
                      isTalentSearchBrandRatesAdmin(currentUser) ||
                      currentUser.adminPermission?.talentTalentRate ||
                      isTalentSearchTalentRatesAdmin(currentUser));

                  return (
                    <div key={i} className={Styles.subRow}>
                      {(currentUser.adminPermission?.talentClientRate ||
                        isTalentSearchBrandRatesAdmin(currentUser)) &&
                        !!rate.client_rate && (
                          <div className={TalentStyles.content}>
                            <span className={TalentStyles.mainRate} data-tip='Client Rate'>
                              {`${itemCurrency} ${rate.client_rate} ${ServiceRateType.getLabel(
                                rate.rate_type
                              )}`}
                              <ReactTooltip place='top' type='dark' effect='solid' />
                            </span>
                          </div>
                        )}

                      {(currentUser.adminPermission?.talentTalentRate ||
                        isTalentSearchTalentRatesAdmin(currentUser)) &&
                        !!itemRate && (
                          <div className={TalentStyles.content}>
                            <span className={TalentStyles.secondaryRate} data-tip='Talent Rate'>
                              {`${itemCurrency} ${itemRate} ${ServiceRateType.getLabel(
                                rate.rate_type
                              )}`}
                              <ReactTooltip place='top' type='dark' effect='solid' />
                            </span>
                          </div>
                        )}

                      {isTalentSearchRatesAdmin && (rate.name || rate.description) && (
                        <Fragment>
                          <p className={TalentStyles.title}>{rate.name || ''}</p>
                          <div className={TalentStyles.content}>
                            {rate.description?.replace(/<\/?[^>]+(>|$)/g, '') || ''}
                          </div>
                        </Fragment>
                      )}

                      {currentUser && isSearchElevatedAdmin(currentUser) && rate.internalNotes && (
                        <Fragment>
                          <p className={TalentStyles.title}>{`Internal Notes:`}</p>
                          <div className={TalentStyles.content}>
                            {rate.internalNotes.replace(/<\/?[^>]+(>|$)/g, '')}
                          </div>
                        </Fragment>
                      )}
                    </div>
                  );
                })}
            </Table.Cell>

            <Table.Cell width='300px'>
              <div className={TalentStyles.skill}>
                <Highlight hit={user} attribute={`profile.description`} />
              </div>
            </Table.Cell>
            <Table.Cell width='300px'>
              {user.portfolio_items?.map((item, i) => {
                return (
                  <div key={i} className={TalentStyles.skill}>
                    <Highlight hit={user} attribute={`portfolio_items[${i}].title`} />
                  </div>
                );
              })}
            </Table.Cell>
            <Table.Cell width='300px'>
              {user.portfolio?.ordered_skills?.map((skill, i) => {
                return (
                  <div key={i} className={TalentStyles.skill}>
                    <Highlight hit={user} attribute={`portfolio.ordered_skills[${i}].noun`} />{' '}
                  </div>
                );
              })}
            </Table.Cell>

            <Table.Cell width='300px'>
              {user.employment?.map((emp, i) => {
                return (
                  <div key={i}>
                    <p className={TalentStyles.title}>
                      <Highlight hit={user} attribute={`employment[${i}].job_title`} />
                    </p>
                    <p className={TalentStyles.content}>
                      <Highlight hit={user} attribute={`employment[${i}].company`} />
                    </p>
                  </div>
                );
              })}
            </Table.Cell>
            <Table.Cell width='300px'>
              {user.education?.map((emp, i) => {
                return (
                  <div key={i}>
                    <p className={TalentStyles.title}>
                      <Highlight hit={user} attribute={`education[${i}].qualification`} />
                      {emp.major && `, `}
                      <Highlight hit={user} attribute={`education[${i}].major`} />
                    </p>
                    <p className={TalentStyles.content}>
                      <Highlight hit={user} attribute={`education[${i}].institution`} />
                    </p>
                  </div>
                );
              })}
            </Table.Cell>

            <Table.Cell width='250px'>
              {user.ratings &&
                user.ratings.map((rating, idx) => {
                  return (
                    <div key={idx} className={Styles.subRow}>
                      <p className={TalentStyles.title}>
                        {`Review:`} {(rating.overall_rating && rating.overall_rating) || ''}
                      </p>
                      <div className={TalentStyles.content}>
                        {rating.message && convert(rating.message)}
                      </div>
                    </div>
                  );
                })}
            </Table.Cell>

            <Table.Cell width='250px'>
              {user.talent_scores &&
                user.talent_scores.map((score, idx) => {
                  return (
                    <div key={idx} className={Styles.subRow}>
                      <div className={TalentStyles.scoreList}>
                        <div className={TalentStyles.scoreItem}>
                          <p className={TalentStyles.scoreTitle}>{`Skills`}</p>
                          <p className={TalentStyles.score}>{score?.skills_score || 'N/A'}</p>
                        </div>
                        <div className={TalentStyles.scoreItem}>
                          <p className={TalentStyles.scoreTitle}>{`Availability`}</p>
                          <p className={TalentStyles.score}>{score?.availability_score || 'N/A'}</p>
                        </div>
                        <div className={TalentStyles.scoreItem}>
                          <p className={TalentStyles.scoreTitle}>{`Quality of Work`}</p>
                          <p className={TalentStyles.score}>
                            {score?.quality_of_work_score || 'N/A'}
                          </p>
                        </div>
                        <div className={TalentStyles.scoreItem}>
                          <p className={TalentStyles.scoreTitle}>{`Timeliness`}</p>
                          <p className={TalentStyles.score}>{score?.timeliness_score || 'N/A'}</p>
                        </div>
                        <div className={TalentStyles.scoreItem}>
                          <p className={TalentStyles.scoreTitle}>{`Communication`}</p>
                          <p className={TalentStyles.score}>
                            {score?.communication_score || 'N/A'}
                          </p>
                        </div>
                        <div className={TalentStyles.scoreItem}>
                          <p className={TalentStyles.title}>{`Overall Score`}</p>
                          <p className={TalentStyles.title}>
                            {score?.overall_score?.toFixed(2) || 'N/A'}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Table.Cell>

            <Table.Cell width='180px'>
              <p>{user.profile && UserStatus.getEnum(user.profile.status).label}</p>
            </Table.Cell>

            <Table.Cell width='100px'>
              {user.portfolio?.is_hidden ? (
                `Private`
              ) : user.portfolio?.request_hidden && !user.portfolio.is_hidden ? (
                <span
                  style={{
                    color: '#ec392f',
                  }}>{`Private Pending`}</span>
              ) : (
                ''
              )}
            </Table.Cell>
            <Table.Cell width='100px'>{user.agreement_signed ? `Signed` : ''}</Table.Cell>

            <Table.Cell width='150px'>
              <div className={TalentStyles.content}>
                {user.portfolio?.current_location?.description && (
                  <Highlight hit={user} attribute={`portfolio.current_location.description`} />
                )}
                {user.address?.country && user.portfolio?.current_location && <br />}
                {user.address?.city && <Highlight hit={user} attribute={`address.city`} />}
                {`${
                  (user.address?.city && <Highlight hit={user} attribute={`address.city`} />) || ''
                } ${
                  (user.address?.region && <Highlight hit={user} attribute={`address.region`} />) ||
                  ''
                } ${
                  (user.address?.country && (
                    <Highlight hit={user} attribute={`address.country`} />
                  )) ||
                  ''
                }`}
              </div>
            </Table.Cell>
            <Table.Cell width='300px'>
              {user.priority ? (
                <Fragment>
                  <p className={TalentStyles.title}>{`Priority:`}</p>
                  <div className={TalentStyles.content}>{user.priority}</div>
                </Fragment>
              ) : (
                <div />
              )}
              {user.notes ? (
                <Fragment>
                  <p className={TalentStyles.title}>{`Profile Review Notes:`}</p>
                  <div className={TalentStyles.content}> {convert(user.notes)}</div>
                </Fragment>
              ) : (
                <div />
              )}
              {user.adminNotes ? (
                <Fragment>
                  <p className={TalentStyles.title}>{`Admin Notes:`}</p>
                  <div className={TalentStyles.content}> {user.admin_notes}</div>
                </Fragment>
              ) : (
                <div />
              )}
            </Table.Cell>
          </div>
        );
      })}
    </>
  );
};

const CustomHits = connectHits(Hits);
const sessionStorageCache = createInfiniteHitsSessionStorageCache();

const TalentList = ({ loadData, hasMore, sort, sortData, ...props }) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  /* To prevent unnecessary re-assignments of string values during renders triggered by
  subsequent changes to the query parameters, state variables (search and country)
  were used instead of regular variables. This prevents duplicate requests being sent
  by the InstantSearch component */
  const [search] = useState(query.get('search'));
  const [country] = useState(query.getAll('country')?.filter((v) => !!v));

  const [isMounted, setIsMounted] = useState(false);

  const tableHeader = [
    {
      title: 'Talent',
      field: 'first_name',
      width: '330px',
      sortable: true,
      isSticky: true,
    },

    {
      title: 'Rates',
      field: 'rates',
      width: '250px',
      sortable: false,
    },
    {
      title: 'About',
      field: 'description',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Portfolio',
      field: 'portfolio_items',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Skills',
      field: 'skills',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Work Experience',
      field: 'experience',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Education',
      field: 'education',
      width: '300px',
      sortable: false,
    },

    {
      title: 'Reviews',
      field: 'reviews',
      width: '250px',
      sortable: false,
    },
    {
      title: 'Score',
      field: 'talent_scores',
      width: '250px',
      sortable: false,
    },
    {
      title: 'Status',
      field: 'profile__status',
      width: '180px',
      sortable: true,
    },
    {
      title: 'Privacy',
      field: 'privacy',
      width: '100px',
      sortable: false,
    },
    {
      title: 'Agreement',
      field: 'agreement',
      width: '100px',
      sortable: false,
    },
    {
      title: 'Location',
      field: 'country',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Notes',
      field: 'notes',
      width: '300px',
      sortable: false,
    },
  ];

  useEffect(() => {
    setPageTitle('Talents');
    setIsMounted(true);
  }, []);

  const createQueryString = (params) => {
    return `?${qs.stringify(params, { arrayFormat: 'repeat' })}`;
  };

  const onSearchStateChange = ({ query = '', refinementList = {} } = {}) => {
    const key = Object.keys(refinementList)[0];
    history.push(createQueryString({ search: query, country: refinementList[key] }));
  };

  if (!isMounted) return null;

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.ALGOLIA_USER_INDEX}
      onSearchStateChange={onSearchStateChange}>
      <Configure advancedSyntax={true} />
      <AppLayout
        location={location}
        bodyClass={Styles.wideBody}
        header={
          <div className={Styles.headerRow}>
            <div className={Styles.headerActive}>{`Talent Search`}</div>
            <SearchBox className={Styles.headerSearch} defaultRefinement={search} />
            <RefinementSelect
              label={'Location'}
              className={Styles.headerSelect}
              attribute='portfolio.current_location.description'
              defaultRefinement={country}
            />
          </div>
        }>
        <div className={Styles.talentContainer}>
          <div className={Styles.talentHeader}>
            {tableHeader &&
              tableHeader.length &&
              tableHeader.map((header, index) => {
                const column = header.field;
                return (
                  <Table.Header
                    width={header.width}
                    key={index}
                    sticky={header.isSticky}
                    className={classNames(
                      { [Styles.sort]: header.sortable },
                      header.sortable &&
                        (sort[column] === false
                          ? Styles.sortInactive
                          : sort[column]
                          ? Styles.sortActive
                          : '')
                    )}>
                    {header.title}
                  </Table.Header>
                );
              })}
          </div>

          <CustomHits cache={sessionStorageCache} />
        </div>
        <div className={TalentStyles.pagination}>
          <Pagination />
          <Stats />
        </div>
      </AppLayout>
    </InstantSearch>
  );
};

TalentList.propTypes = {
  location: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withDataSource(TalentList, 'talent-search');
