import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { UserProvider } from '@context/user';

import ProfileList from './pages/users/list';
import ProfileDetail from './pages/users/detail';
import FinanceTalentList from './pages/financeTalents/list';
import FinanceTalentDetail from './pages/financeTalents/detail';
import FinanceClientList from './pages/financeClients/list';
import FinanceClientDetail from './pages/financeClients/detail';
import WiseRecipientList from './pages/wiseRecipients/list';
import TeamProfileList from './pages/teams/list';
import TeamProfileDetail from './pages/teams/detail';
import OrganizationList from './pages/organizations/list';
import OrganizationDetail from './pages/organizations/detail';
import EmailNotificationList from './pages/emails/list';
import EmailDetail from './pages/emails/detail';
import TimesheetList from './pages/timesheets/list';
import PrepaymentList from './pages/prepayments/list';
// import PendingPrepaymentList from './pages/pendingPrepayments/list';
import ProjectList from './pages/projects/list';
import ProjectDetail from './pages/projects/detail';

import EmailTemplateList from './pages/emailTemplates/list';
import EmailTemplateDetail from './pages/emailTemplates/detail';

import SupportList from './pages/support/list';
import SupportDetail from './pages/support/detail';

import TalentList from './pages/talents/list';
import NewTalentList from './pages/talents/list2';
import NewResumeList from './pages/talents/resumes';
import JobList from './pages/jazzhr-jobs/list';
import JobDetail from './pages/jazzhr-jobs/detail';
// import NewTalentList from './pages/talents-v2/list';
import PortfolioItemList from './pages/portfolioItems/list';
import PortfolioItemScreenshotList from './pages/portfolioItemScreenshots/list';

import BriefList from './pages/briefs/list';
//import BriefSalesList from './pages/briefs/list';
//import BriefPlacementList from './pages/briefs/list';

import BriefDetail from './pages/briefs/detail';
// import BriefListv2 from './pages/briefs2/list';
// import BriefDetailv2 from './pages/briefs2/detail';

import PrivateRoute from '@helpers/PrivateRoute';

import LoginPage from './pages/auth/login';
import LogoutPage from './pages/auth/logout';

import BillList from './pages/bills/list';
import MessageList from './pages/messages/list';
import AssignmentList from './pages/assignments/list';
import AssignmentDetail from './pages/assignments/detail';
import TimetrackerSummary from './pages/timetracker/summary';
import TimetrackerList from './pages/timetracker/list';
import TimetrackerDetail from './pages/timetracker/detail';
import TimetrackerTree from './pages/timetracker/tree';

import BriefRegistrationList from './pages/brief-registrations/list';
import BriefRegistrationDetail from './pages/brief-registrations/detail';

import OrgRegistrationList from './pages/org-registrations/list';
import OrgRegistrationDetail from './pages/org-registrations/detail';

import TalentRegistrationList from './pages/talent-registrations/list';
import TalentRegistrationDetail from './pages/talent-registrations/detail';

import NotificationList from './pages/notifications/List';
import ProspectList from './pages/prospect/List';
import ProspectDetail from './pages/prospect/detail';
import Dashboard from './pages/dashboard';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { Toaster } from 'react-hot-toast';
// import defaultTheme from './theme';
import { ThemeProvider } from 'evergreen-ui/esm/theme';
import theme from '@hiredigital/ui/theme';
import PageNotFound from './pages/404';
import InterceptorProvider from '@hiredigital/ui/InterceptorProvider';

import { authRequest } from '@apis/utils';

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

// const theme = {
//   ...defaultTheme,
//   avatar: {
//     size: {
//       medium: '30px',
//     },
//   },
// };

const App = () => {
  return (
    <ThemeProvider value={theme}>
      <InterceptorProvider requests={[authRequest]}>
        <UserProvider initial={{}}>
          {/*<DevTools />*/}
          <ErrorBoundary>
            <Router>
              <Switch>
                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/logout' component={LogoutPage} />
                <PrivateRoute path='/profiles/:userUuid' component={ProfileDetail} />
                <PrivateRoute exact path='/profiles' component={ProfileList} />

                <PrivateRoute path='/finance-talents/:userUuid' component={FinanceTalentDetail} />
                <PrivateRoute exact path='/finance-talents' component={FinanceTalentList} />
                <PrivateRoute path='/finance-clients/:orgUuid' component={FinanceClientDetail} />
                <PrivateRoute exact path='/finance-clients' component={FinanceClientList} />
                <PrivateRoute exact path='/wise-recipients' component={WiseRecipientList} />

                <PrivateRoute exact path='/teams' component={TeamProfileList} />
                <PrivateRoute path='/teams/:teamUuid' component={TeamProfileDetail} />
                <PrivateRoute exact path='/briefs' component={BriefList} />
                <PrivateRoute exact path='/briefs-sales' component={BriefList} />
                <PrivateRoute exact path='/briefs-placement' component={BriefList} />
                <PrivateRoute path='/briefs/:briefUuid' component={BriefDetail} />
                {/*<PrivateRoute exact path='/briefs' component={BriefListv2} />*/}
                {/*<PrivateRoute path='/briefs/:briefUuid' component={BriefDetailv2} />*/}
                <PrivateRoute exact path='/portfolio-items' component={PortfolioItemList} />
                <PrivateRoute
                  path='/portfolio-item-screenshots'
                  component={PortfolioItemScreenshotList}
                />

                <PrivateRoute exact path='/talents' component={TalentList} />
                <PrivateRoute exact path='/talent-search' component={NewTalentList} />
                <PrivateRoute exact path='/resume-search' component={NewResumeList} />
                <PrivateRoute exact path='/jazzhr-jobs' component={JobList} />
                <PrivateRoute exact path='/jazzhr-jobs/:jobId' component={JobDetail} />

                <PrivateRoute exact path='/clients' component={OrganizationList} />
                <PrivateRoute exact path='/timesheets' component={TimesheetList} />
                {/*<Redirect from='/events' to='/timetracker/summary' noThrow />*/}
                <PrivateRoute exact path='/events' component={TimetrackerSummary} />
                <PrivateRoute exact path='/timetracker/events' component={TimetrackerList} />
                <PrivateRoute
                  exact
                  path='/timetracker/events/:uuid?'
                  component={TimetrackerDetail}
                />
                <PrivateRoute exact path='/timetracker/tree' component={TimetrackerTree} />
                <PrivateRoute exact path='/prepayments' component={PrepaymentList} />
                {/*<PrivateRoute exact path='/pending-prepayments' component={PendingPrepaymentList} />*/}
                <PrivateRoute exact path='/projects' component={ProjectList} />
                <PrivateRoute path='/projects/:projectUuid' component={ProjectDetail} />
                <PrivateRoute exact path='/messages' component={MessageList} />

                <PrivateRoute exact path='/support' component={SupportList} />
                <PrivateRoute path='/support/:userUuid' component={SupportDetail} />

                <PrivateRoute exact path={['/bills', '/bills/:id']} component={BillList} />

                <PrivateRoute path='/clients/:orgUuid' component={OrganizationDetail} />
                <PrivateRoute exact path='/emails' component={EmailNotificationList} />
                <PrivateRoute exact path='/emails/:emailId' component={EmailDetail} />
                <PrivateRoute exact path='/email-templates' component={EmailTemplateList} />
                <PrivateRoute
                  exact
                  path='/email-templates/:templateId'
                  component={EmailTemplateDetail}
                />
                <PrivateRoute exact path='/assignments' component={AssignmentList} />
                <PrivateRoute
                  exact
                  path='/assignments/:assignmentUuid'
                  component={AssignmentDetail}
                />

                <PrivateRoute exact path='/brief-registrations' component={BriefRegistrationList} />

                <PrivateRoute
                  exact
                  path='/brief-registrations/:briefUuid'
                  component={BriefRegistrationDetail}
                />

                <PrivateRoute exact path='/org-registrations' component={OrgRegistrationList} />

                <PrivateRoute
                  exact
                  path='/org-registrations/:orgUuid'
                  component={OrgRegistrationDetail}
                />

                <PrivateRoute
                  exact
                  path='/talent-registrations'
                  component={TalentRegistrationList}
                />
                <PrivateRoute exact path='/prospects' component={ProspectList} />
                <PrivateRoute exact path='/prospects/:prospectuuid' component={ProspectDetail} />
                <PrivateRoute
                  exact
                  path='/talent-registrations/:userUuid'
                  component={TalentRegistrationDetail}
                />

                <PrivateRoute exact path='/notifications' component={NotificationList} />

                {/*

                <PrivateRoute path="briefs" component={BriefList} />
                <PrivateRoute path="briefs-sales" component={OptBriefList} />
                <PrivateRoute path="briefs/:briefUuid" component={BriefDetail} />
    */}
                <PrivateRoute exact path='/' component={Dashboard} />
                <Route exact path='/404' component={PageNotFound} />
                <Redirect to='/404' />
              </Switch>

              {/*<Redirect from="users" to="profiles" noThrow />
            <Redirect from="users/" to="profiles" noThrow />
            <Redirect from="users/:userUuid" to="profiles/:userUuid" noThrow />
            <PrivateRoute path="/" component={Dashboard} />
            <PrivateRoute path="profiles" component={ProfileList} />
            <PrivateRoute path="profiles/:userUuid" component={ProfileDetail} />
            <PrivateRoute path="talents" component={TalentList} />
            <PrivateRoute path="briefs" component={BriefList} />
            <PrivateRoute path="briefs-sales" component={OptBriefList} />
            <PrivateRoute path="briefs/:briefUuid" component={BriefDetail} />
            <PublicRoute path="login" component={LoginPage} />
            <PublicRoute path="logout" component={LogoutPage} />
            <PublicRoute path="*" component={PageNotFound} />*/}
            </Router>
            <Toaster />
          </ErrorBoundary>
        </UserProvider>
      </InterceptorProvider>
    </ThemeProvider>
  );
};
export default App;

// window.store = BaseModel;
