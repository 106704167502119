import { connectHighlight } from 'react-instantsearch-dom';

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });
  return parsedHit?.map((part, index) =>
    part.isHighlighted ? <mark key={index}>{part.value}</mark> : part.value
  );
};

export default connectHighlight(Highlight);
