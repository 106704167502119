import { authRequest } from './utils';
const serializeProspect = (data) => {
  const serializedData = {
    ...data,
    phoneCountry: data?.phoneCountry?.id,
    organization: data?.organization?.uuid,
    brief: data?.brief?.uuid,
    user: data?.user?.uuid,
    skills: data?.skills?.map((v) => v.id),
  };
  return serializedData;
};

export const getProspects = async (config = undefined) => {
  const url = `admin/prospects/`;
  return await authRequest.get(url, config);
};

export const updateProspectRequirement = async (
  uuid,
  data,
  serializer = serializeProspect,
  config = undefined
) => {
  const url = `admin/prospects/${uuid}/`;
  return await authRequest.put(url, serializer(data), config);
};

export const getProspect = async (uuid, config = undefined) => {
  const url = `admin/prospects/${uuid}/`;
  return await authRequest.get(url, config);
};

export const postProspectRequirementFile = async (uuid, data, config = undefined) => {
  const url = `admin/prospects/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteProspectRequirementFile = async (uuid, fileId, config = undefined) => {
  const url = `admin/prospects/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};

export const convertProspectToUser = async (uuid, data, config = undefined) => {
  const url = `admin/prospects/${uuid}/convert-to-user/`;
  return await authRequest.post(url, data, config);
};

export const inviteUserToJoin = async (uuid, data, config = undefined) => {
  const url = `admin/prospects/${uuid}/invite-user-to-join/`;
  return await authRequest.post(url, data, config);
};
