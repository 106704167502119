import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
// import Modal from 'react-modal';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';

//import DateSelector from '@components/Form/Input/DateSelector';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { getTaxRates, getBillingAccounts, getBill, saveBill } from '@apis/payments';
import { onContactsLoadOptions } from '@apis/dropdown';

import Styles from './detail.module.scss';

const BillModal = ({ onInvoiceUpdate, billId, history, ...props }) => {
  let saveTimeout;
  const [bill, setBill] = useState();
  const [date, setDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [payment, setPayment] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [hasInitLoad, setHasInitLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [taxRates, setTaxRates] = useState([]);

  useEffect(() => {
    Promise.all([getBill(billId), getBillingAccounts(), getTaxRates()])
      .then(
        ([
          { data: billData },
          {
            data: { results: accounts },
          },
          {
            data: { results: taxRates },
          },
        ]) => {
          setPageTitle(billData.invoiceNumber || 'Untitled Bill');
          setAccounts(accounts);
          setTaxRates(taxRates);
          setDate(billData.date);
          setDueDate(billData.dueDate);
          setInvoiceNumber(billData.invoiceNumber);
          setPayment(billData.payment);

          setLineItems(!Object.keys(billData?.lineItems || {}).length ? [] : billData?.lineItems);

          setBill(billData);
          setHasInitLoad(true);
        }
      )
      .catch((error) => {
        console.log(error);
      });

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, []);

  useEffect(() => {}, []);

  const handleClose = () => {
    setPageTitle('Bills');
    history.push('/bills');
  };

  const handleSaveAndClose = async () => {
    const success = await handleSave();
    console.log('success', success);
    if (success) {
      await handleClose();
    }
    return;
  };

  const handleSave = async () => {
    setIsSaving(true);

    try {
      const data = await saveBill(billId, {
        date,
        dueDate,
        invoiceNumber,
        lineItems,
        ...(payment &&
          payment.id && {
            payment: payment.id,
          }),
      });
      setIsSaving(false);

      onInvoiceUpdate?.(data);
      return true;
    } catch (error) {
      setIsSaving(false);
      return false;
    }
  };

  const handleAddNewLine = () => {
    setLineItems([...lineItems, {}]);
    // handleSaveWithDelay();
  };

  const handleChange = (e, setHandler) => {
    const { name, value } = e.target;
    setHandler(value);
    // handleSaveWithDelay();
  };

  const handleLineItemChange = (e, idx, valueKey) => {
    const { name, value } = e.target;
    const updatedLineItems = [...lineItems];
    updatedLineItems[idx][name] = valueKey ? value[valueKey] : value;
    setLineItems(updatedLineItems);
    calculateAmount(idx);
  };

  const handleLineItemTaxRate = (e, idx) => {
    const { name, value } = e.target;
    const updatedLineItems = [...lineItems];
    // updatedLineItems[name] = valueKey ? value[valueKey] : value;
    updatedLineItems[idx]['taxRate'] = value['effectiveRate'];
    updatedLineItems[idx]['taxType'] = value['taxType'];

    setLineItems(updatedLineItems);
    calculateAmount(idx);
    // handleSaveWithDelay();
  };

  const calculateAmount = (idx) => {
    const updatedLineItems = [...lineItems];
    const item = updatedLineItems[idx];
    const subTotal = item.quantity * item.unitAmount;
    const tax = subTotal * item.taxRate * 0.01;
    const amount = subTotal + tax;
    console.log('recalculating', tax, amount);
    updatedLineItems[idx]['taxAmount'] = tax;
    updatedLineItems[idx]['lineAmount'] = amount;
    setLineItems(updatedLineItems);
  };

  const handleRowRemove = (idx) => {
    setLineItems(lineItems.filter((item, itemKey) => idx !== itemKey));
    handleSaveWithDelay();
  };

  const handleDateChange = (value, setHandler) => {
    setHandler(value);
  };

  const handleSaveWithDelay = (timeout = 3000) => {
    if (saveTimeout) clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      handleSave();
    }, timeout);
  };

  return (
    <Dialog isOpen={true} onClose={handleClose} padding={0}>
      <div className={Styles.modalContent}>
        <Card style={{ marginBottom: 0 }}>
          <Card.Header>
            <p className={Styles.modalTitle}>{`Invoice`}</p>
            {/* <a className={Styles.close} onClick={handleClose}>
              <IconClose className={Styles.closeIcon} />
            </a> */}
          </Card.Header>

          {hasInitLoad ? (
            <Fragment>
              <Card.Item>
                <InputGroup>
                  <InputContainer style={{ marginBottom: 0 }}>
                    <Select
                      data-test-id='payment'
                      value={payment}
                      classNamePrefix='s-contact'
                      name='payment'
                      label='Payment Contact'
                      getOptionLabel={({ user }) => user?.name || ''}
                      getOptionValue={({ id }) => id}
                      loadOptions={onContactsLoadOptions}
                      onChange={(e) => handleChange(e, setPayment)}
                      isPaginated
                    />
                  </InputContainer>
                  <InputContainer style={{ marginBottom: 0 }}>
                    <TextInput
                      name='invoiceNumber'
                      label='Invoice Number'
                      value={invoiceNumber}
                      onChange={(e) => handleChange(e, setInvoiceNumber)}
                    />
                  </InputContainer>
                  <InputContainer style={{ marginBottom: 0 }}>
                    {/* <DateSelector
                      name='date'
                      label='Date'
                      value={date}
                      onChange={(date) => handleDateChange(date, setDate)}
                    /> */}
                    <DatePicker
                      id='editBillsDate'
                      name='date'
                      label='Date'
                      value={date}
                      onChange={(date) => handleDateChange(date, setDate)}
                    />
                  </InputContainer>
                  <InputContainer style={{ marginBottom: 0 }}>
                    {/* <DateSelector
                      name='dueDate'
                      label='Due Date'
                      value={dueDate}
                      onChange={(date) => handleDateChange(date, setDueDate)}
                    /> */}
                    <DatePicker
                      id='editBillsDueDate'
                      name='dueDate'
                      label='Due Date'
                      value={dueDate}
                      onChange={(date) => handleDateChange(date, setDueDate)}
                    />
                  </InputContainer>
                </InputGroup>
              </Card.Item>
              <Card.Item noPadding>
                <div className={Styles.lineItemsContainer}>
                  <div className={Styles.tableLineItems}>
                    <div className={Styles.tableHeader}>
                      <div className={Styles.tableHeaderCell}>
                        <span className={Styles.headerText}>{`Decription`}</span>
                      </div>
                      <div
                        className={classNames(Styles.tableHeaderCell, Styles.cellNumber)}
                        style={{ flex: '0 1 100px' }}>
                        <span className={Styles.headerText}>{`Qty`}</span>
                      </div>
                      <div
                        className={classNames(Styles.tableHeaderCell, Styles.cellNumber)}
                        style={{ flex: '0 1 120px' }}>
                        <span className={Styles.headerText}>{`Price`}</span>
                      </div>
                      <div className={Styles.tableHeaderCell}>
                        <span className={Styles.headerText}>{`Account`}</span>
                      </div>
                      <div
                        className={classNames(Styles.tableHeaderCell)}
                        style={{ flex: '0 1 200px' }}>
                        <span className={Styles.headerText}>{`Tax`}</span>
                      </div>
                      <div
                        className={classNames(Styles.tableHeaderCell, Styles.cellNumber)}
                        style={{ flex: '0 1 120px' }}>
                        <span className={Styles.headerText}>{`Tax Amount`}</span>
                      </div>
                      <div
                        className={classNames(Styles.tableHeaderCell, Styles.cellNumber)}
                        style={{ flex: '0 1 130px' }}>
                        <span className={Styles.headerText}>{`Amount`}</span>
                      </div>
                      <div className={Styles.tableHeaderCell} style={{ flex: '0 1 50px' }}>
                        {``}
                      </div>
                    </div>
                    {lineItems?.map((item, idx) => {
                      return (
                        <div key={idx} className={Styles.tableRow}>
                          <div className={Styles.tableCell}>
                            <input
                              name='description'
                              className={Styles.tableInput}
                              defaultValue={lineItems[idx].description}
                              onChange={(e) => handleLineItemChange(e, idx)}
                            />
                          </div>
                          <div className={Styles.tableCell} style={{ flex: '0 1 100px' }}>
                            <input
                              name='quantity'
                              type='number'
                              className={classNames(Styles.tableInput, Styles.alignRight)}
                              defaultValue={lineItems[idx].quantity}
                              onChange={(e) => handleLineItemChange(e, idx)}
                            />
                          </div>
                          <div className={Styles.tableCell} style={{ flex: '0 1 120px' }}>
                            <input
                              name='unitAmount'
                              type='number'
                              className={classNames(Styles.tableInput, Styles.alignRight)}
                              defaultValue={lineItems[idx].unitAmount}
                              onChange={(e) => handleLineItemChange(e, idx)}
                            />
                          </div>
                          <div className={Styles.tableCell}>
                            <Select
                              className={Styles.cellDropdownSelect}
                              defaultValue={
                                accounts.find(({ code }) => code === lineItems[idx].accountCode) ||
                                ''
                              }
                              classNamePrefix='s-contact'
                              name={`accountCode`}
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ name }) => name}
                              options={accounts}
                              onChange={(e) => handleLineItemChange(e, idx, 'code')}
                            />
                          </div>
                          <div
                            className={Styles.tableCell}
                            style={{
                              flex: '0 1 200px',
                              maxWidth: '200px',
                            }}>
                            <Select
                              className={Styles.cellDropdownSelect}
                              defaultValue={
                                taxRates.find(
                                  ({ taxType }) => taxType === lineItems[idx].taxType
                                ) || ''
                              }
                              classNamePrefix='s-contact'
                              name={`taxRate`}
                              getOptionLabel={({ name, effectiveRate }) =>
                                `${name} (${effectiveRate}%)`
                              }
                              getOptionValue={({ effectiveRate }) => effectiveRate}
                              options={taxRates}
                              onChange={(e) => handleLineItemTaxRate(e, idx, 'effectiveRate')}
                            />
                          </div>
                          <div className={Styles.tableCell} style={{ flex: '0 1 120px' }}>
                            <input
                              name='taxAmount'
                              type='number'
                              disabled
                              className={classNames(Styles.tableInput, Styles.alignRight)}
                              value={lineItems[idx].taxAmount}
                              onChange={(e) => handleLineItemChange(e, idx)}
                            />
                          </div>
                          <div className={Styles.tableCell} style={{ flex: '0 1 130px' }}>
                            <input
                              name='lineAmount'
                              type='number'
                              disabled
                              className={classNames(Styles.tableInput, Styles.alignRight)}
                              value={lineItems[idx].lineAmount}
                              onChange={(e) => handleLineItemChange(e, idx)}
                            />
                          </div>
                          <div
                            className={classNames(Styles.tableCell, Styles.tableCellAction)}
                            style={{ flex: '0 1 50px' }}>
                            <div onClick={() => handleRowRemove(idx)} className={Styles.btnBin}>
                              <IconClose />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className={Styles.tableAddNew}>
                      <Button
                        name='cancel'
                        type={Button.Type.LIGHT_GRAY}
                        onClick={handleAddNewLine}>
                        {`+ Add New Line`}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Item>
            </Fragment>
          ) : (
            <Card.Item>
              <Loader />
            </Card.Item>
          )}

          <Card.Footer>
            <Button
              name='save'
              type={Button.Type.BLUE}
              onClick={handleSaveAndClose}
              isLoading={isSaving}>
              {`Save`}
            </Button>
            <Button name='cancel' type={Button.Type.GRAY} onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </Dialog>
  );
};

export default BillModal;
