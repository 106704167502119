import { authRequest, request } from './utils';

export const getLogos = async (config = undefined) => {
  // const url = `https://cdn.hiredigital.com/logos/list.json`;
  const url = `https://s3.ap-southeast-1.amazonaws.com/cdn.content.co/logos/list.json`;
  return await request.get(url, config);
};

export const getStartDates = async (config = undefined) => {
  const url = `start-dates/`;
  return await authRequest.get(url, config);
};

export const saveAttachment = async (file) => {
  const formData = new FormData();
  formData.append('attachment', file, file.fileName || file.name);

  const url = `attachment/`;
  return await authRequest.post(url, formData);
};

export const createAdminSkill = async (value) => {
  const data = { label: value, adminGenerated: true };
  const url = `admin/skills/`;
  return await authRequest.post(url, data);
};

export const postResource = async (resource, data, config = undefined) => {
  const url = `admin/${resource}/`;
  return await authRequest.post(url, data, config);
};

export const putResource = async (resource, resouceUuid, data, config = undefined) => {
  const url = `admin/${resource}/${resouceUuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchResource = async (resource, resouceUuid, data, config = undefined) => {
  const url = `admin/${resource}/${resouceUuid}/`;
  return await authRequest.patch(url, data, config);
};

export const getResourceDropdownUsers = async (resource, resouceUuid, config = undefined) => {
  const url = `admin/${resource}/${resouceUuid}/users/`;
  return await authRequest.get(url, config);
};

export const getResourceBankAccounts = async (resource, resouceUuid, config = undefined) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/`;
  return await authRequest.get(url, config);
};

export const getResourceBankAccountsBanks = async (
  resource,
  resouceUuid,
  countryCode,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/banks/?country=${countryCode}`;
  return await authRequest.get(url, config);
};

export const getResourceBankAccountsBankBranches = async (
  resource,
  resouceUuid,
  countryCode,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/bank-branches/?country=${countryCode}`;
  return await authRequest.get(url, config);
};

export const getResourceBankAccountsSwiftCodes = async (
  resource,
  resouceUuid,
  countryCode,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/swift-codes/?country=${countryCode}`;
  return await authRequest.get(url, config);
};

export const deleteResourceBankAccount = async (
  resource,
  resouceUuid,
  bankId,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/${bankId}/`;
  return await authRequest.delete(url, config);
};

export const putResourceBankAccount = async (
  resource,
  resouceUuid,
  bankId,
  data,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/${bankId}/`;
  return await authRequest.put(url, data, config);
};

export const patchResourceBankAccount = async (
  resource,
  resouceUuid,
  bankId,
  data,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/${bankId}/`;
  return await authRequest.patch(url, data, config);
};

export const postResourceBankAccount = async (resource, resouceUuid, data, config = undefined) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/`;
  return await authRequest.post(url, data, config);
};

export const putResourceCall = async (resource, resouceUuid, callId, data, config = undefined) => {
  const url = `admin/${resource}/${resouceUuid}/call/${callId}/`;
  return await authRequest.put(url, data, config);
};

export const putResourceBankAccountTransferwiseAcccount = async (
  resource,
  resouceUuid,
  accountId,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/bank-accounts/${accountId}/update-transferwise/`;
  return await authRequest.post(url, config);
};

export const patchResourceCall = async (
  resource,
  resouceUuid,
  callId,
  data,
  config = undefined
) => {
  const url = `admin/${resource}/${resouceUuid}/call/${callId}/`;
  return await authRequest.patch(url, data, config);
};

export const postLocations = async (data, config = undefined) => {
  const url = `locations/`;
  return await authRequest.post(url, data, config);
};

export const getCities = async (search, config = undefined) => {
  const url = `gcities/?search=${search}`;
  return await authRequest.get(url, config);
};

export const getDropdownAssignments = async (config = undefined) => {
  const url = `admin/dropdown-assignments`;
  return await authRequest.get(url, config);
};

export const getRegions = async (search, config = undefined) => {
  const url = `gregions/?search=${search}`;
  return await authRequest.get(url, config);
};
