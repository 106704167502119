import { useMemo, useState } from 'react';
import Card from '@hiredigital/ui/Card';

import { TransferStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import { getTransferwiseTransfers } from '@apis/payments';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from './Transfers.module.scss';

const TransferList = ({ userUuid }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Reference',
        id: 'details',
        canSort: true,
        Cell: ({ row }) => {
          return <div className={Styles.row}>{`${row.original.details?.reference}`}</div>;
        },
      },
      // TODO - format the dates
      // {
      //   Header: 'Date',
      //   id: 'created_date',
      //   canSort: true,
      //   accessor: (d) => (d.createdDate ? toReadableDate(d.createdDate) : ''),
      // },
      {
        Header: 'Expected Date',
        id: 'estimated_delivery_date',
        canSort: true,
        accessor: (d) => (d.estimatedDeliveryDate ? toReadableDate(d.estimatedDeliveryDate) : ''),
      },
      {
        Header: 'Source',
        id: 'source_value',
        accessor: (d) => (
          <p className={Styles.currency}>
            {d.sourceValue} {d.sourceCurrency}
          </p>
        ),
      },
      {
        Header: 'Target',
        id: 'target_value',
        accessor: (d) => (
          <p className={Styles.currency}>
            {d.targetValue} {d.targetCurrency}
          </p>
        ),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: (d) => TransferStatus.getLabel(d.status),
      },
      // {
      //   Header: 'Total Amount',
      //   id: 'total',
      //   Cell: ({ row }) => {
      //     return <p className={Styles.currency}>{formatter.format(row.original.total)}</p>;
      //   },
      // },
    ],
    []
  );

  const [search, setSearch] = useState(null);
  let timeout = null;

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <Card>
      <Card.Header>
        <span>{`Transferwise Transfers`}</span>
        <input
          className={Styles.searchField}
          name='search'
          type='text'
          placeholder='Search Transfers'
          onChange={handleSearchChange}
        />
      </Card.Header>
      <Card.Item noPadding>
        <PaginatedTable
          columns={columns}
          search={search}
          rowAccessor='uuid'
          getListData={(config) => getTransferwiseTransfers(userUuid, config)}
          tableClass={Styles.table}
          headerCellClass={Styles.headerCell}
          cellClass={Styles.cell}
          emptyContainerClass={Styles.emptyContainer}
          paginationClass={Styles.pagination}
          defaultLimit={10}
          defaultPaginationOptions={[5, 20, 50, 100]}
          showParams={false}
        />
      </Card.Item>
    </Card>
  );
};

export default TransferList;
