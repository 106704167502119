import { useState, useEffect } from 'react';
import { toReadableShortDateTime } from '@hiredigital/lib/helpers/date';

const Timer = ({ deadline }) => {
  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState(0);
  let setTimeoutId;

  const updateRemainMinutesAndSeconds = (secondsLeft) => {
    setMins(Math.floor(secondsLeft / 60));
    setSecs(Math.floor(secondsLeft % 60));
  };

  const countDown = () => {
    const secondsLeft = (new Date(deadline) - new Date()) / 1000;
    if (secondsLeft <= 0) {
      stopTimer();
    }

    if (secondsLeft > 0) {
      updateRemainMinutesAndSeconds(secondsLeft);
    }
  };

  const startTimer = () => {
    countDown();
    setTimeoutId = setInterval(() => countDown(), 1000);
  };

  const stopTimer = () => {
    updateRemainMinutesAndSeconds(0);
    clearTimeout(setTimeoutId);
  };

  const hasPassed = (deadline) => {
    return new Date(deadline) - new Date() <= 0;
  };

  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
  }, [deadline]);

  return hasPassed(deadline)
    ? toReadableShortDateTime(deadline)
    : `${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`;
};

export { Timer };
