import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { postSkill } from '@apis/skills';
import toast from 'react-hot-toast';
import { useDebouncedCallback } from 'use-debounce';

import Card from '@hiredigital/ui/Card';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';
import AppLayout from '@components/Layout/AppLayout';
import { ProspectStatus, Role } from '@hiredigital/lib/helpers/enum';
import SortableSelect from '@hiredigital/ui/Input/SortableSelect';
import References from '@hiredigital/ui/Form/References';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import {
  onSkillsLoadOptions,
  onOrgsLoadOptions,
  onBriefLoadOptions,
  onUsersLoadOptions,
} from '@apis/dropdown';
import { postOrg } from '@apis/orgs';
import { postBrief } from '@apis/briefs';
import CallSchedule from '@components/Call/CallSchedule';
import { useUser } from '@context/user';
import withEmailLabel from '@hoc/withEmailLabel';

import {
  updateProspectRequirement,
  getProspect,
  postProspectRequirementFile,
  deleteProspectRequirementFile,
  convertProspectToUser,
  inviteUserToJoin,
} from '@apis/prospects';
import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

const UserSelect = withEmailLabel(Select);

let saveTimeout = undefined;
const ProspectDetail = (props) => {
  const currentUser = useUser();
  const [loading, setLoading] = useState(false);
  const [isConverting, setIsConverting] = useState(false);

  // const [prospect, setProspect] = useState();
  const [skills, setSkills] = useState();
  const [uuid, setUuid] = useState();
  const [status, setStatus] = useState();
  const [user, setUser] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [requirements, setRequirements] = useState();
  const [attachments, setAttachments] = useState();
  const [organization, setOrganization] = useState();
  const [brief, setBrief] = useState();
  const [call, setCall] = useState();
  const [phoneCountry, setPhoneCountry] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [showNextSteps, setShowNextSteps] = useState(false);

  useEffect(() => {
    getProspect(props.match.params.prospectuuid)
      .then(({ data }) => {
        setUuid(data.uuid);
        setSkills(data.skills);
        setStatus(data.status);
        setUser(data.user);
        setName(data.name);
        setEmail(data.email);
        setRequirements(data.requirements);
        setAttachments(data.attachments);
        setOrganization(data.organization);
        setBrief(data.brief);
        setCall(data.call);
        setPhoneCountry(data.phoneCountry);
        setPhoneNumber(data.phoneNumber);
        // setProspect(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (uuid) {
      debouncedSave({
        skills,
        uuid,
        status,
        user,
        name,
        email,
        requirements,
        attachments,
        organization,
        brief,
        call,
        phoneCountry,
        phoneNumber,
      });
    }
  }, [status, user, name, email, requirements, call, phoneCountry, phoneNumber]);

  useEffect(() => {
    if (uuid) {
      handleSubmit({
        skills,
        uuid,
        status,
        user,
        name,
        email,
        requirements,
        attachments,
        organization,
        brief,
        call,
        phoneCountry,
        phoneNumber,
      });
    }
  }, [skills, organization, brief]);

  const handleSkillChange = ({ target }) => {
    const { value } = target;
    if (value === null) {
      setSkills([]);
    } else {
      setSkills(value);
    }
  };
  const handleStatusChange = ({ target }) => {
    const { value } = target;
    setStatus(value.id);
    debouncedSave();
  };

  const debouncedSave = useDebouncedCallback((data) => {
    handleSubmit(data);
  }, 15000);

  const handleSubmit = (data) => {
    setLoading(true);

    updateProspectRequirement(uuid, data)
      .then((response) => {
        // setProspect((prevState) => ({ ...prevState, ...response.data }));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleCreateSkill = async (value) => {
    const { data } = await postSkill({ label: value, adminGenerated: true });
    setSkills((skills || []).concat([data]));
    // handleSubmit();
  };

  const handleCreateBrief = (value) => {
    const data = { title: value };
    postBrief(data)
      .then(({ data }) => {
        setBrief(data);
      })
      .catch((err) => console.log(err));
  };
  const handleCreateOrg = (value) => {
    const data = { name: value };
    postOrg(data)
      .then(({ data }) => {
        setOrganization(data);
      })
      .catch((err) => console.log(err));
  };

  const handleInviteUserToJoin = async () => {
    setIsConverting(true);
    try {
      const { data } = await inviteUserToJoin(uuid);
      setUser(data.user);
      setOrganization(data.organization);
      setBrief(data.brief);
      setIsConverting(false);
      setShowNextSteps(true);
      toast.success('Prospect was sent an invitation', {
        id: 'convert',
      });
    } catch (e) {
      console.log(e);
      setIsConverting(false);
    }
  };

  const handleConvertProspect = async () => {
    setIsConverting(true);
    try {
      const { data } = await convertProspectToUser(uuid);

      setUser(data.user);
      setOrganization(data.organization);
      setBrief(data.brief);
      setIsConverting(false);
      setShowNextSteps(true);

      toast.success('Prospect was converted to user and sent an invitation', {
        id: 'convert',
      });
    } catch (e) {
      console.log(e);
      setIsConverting(false);
    }
  };

  const closeNextSteps = () => {
    setShowNextSteps(false);
  };

  const openNextSteps = () => {
    setShowNextSteps(true);
  };

  const handleSkillLoad = (e, f, g) => {
    return onSkillsLoadOptions(e, f, g);
  };

  return (
    <AppLayout
      location={props.location}
      roles={[Role.ADMIN.id]}
      permissions={currentUser.adminPermission?.isSuperuser}
      header={
        <div className={PageStyles.header}>
          <Link className={PageStyles.headerParent} to='/prospects'>
            {`Prospects Review`}
          </Link>
          <p className={PageStyles.headerCaret}>&rsaquo;</p>
          <div className={PageStyles.headerActive}>{name || ''}</div>
        </div>
      }>
      <Dialog
        isOpen={showNextSteps}
        title={`Next Steps`}
        onClose={() => closeNextSteps()}
        className={Styles.dialog}
        containerProps={{ overflow: 'auto' }}>
        <h5>{`Organization: ${organization?.name}`}</h5>
        <p>
          The organization has been created based on the details provided earlier. You may wish to
          edit them before the prospect creates their user account.
        </p>
        <Link className={Styles.dialogLink} to={`/clients/${organization?.uuid}`} target='_blank'>
          {`Open Organization Admin in a New Tab`}
        </Link>
        <br />
        <br />
        <h5>{`User: ${user?.name}`}</h5>
        <p>{`The user will be invited to join ${organization?.name} and will recieve an email with a link to join. If you need to change any details about the user you can do so here.`}</p>
        <Link className={Styles.dialogLink} to={`/profiles/${user?.uuid}`} target='_blank'>
          {`Open User Admin in a New Tab`}
        </Link>
        <br />
        <br />
        <h5>{`Brief: ${brief?.title}`}</h5>
        <p>{`A brief in ${organization?.name} will be created and information from the prospect will be copied there. You should update any engagements and have the client continue to confirm the brief before moving onto the payment or agreement.`}</p>
        <Link className={Styles.dialogLink} to={`/briefs/${brief?.uuid}`} target='_blank'>
          {`Open Brief Admin in a New Tab`}
        </Link>
        <br />
        <br />

        <Button onClick={closeNextSteps} type={Button.Type.BLUE}>
          {`Close`}
        </Button>
      </Dialog>

      {uuid && (
        <div className={PageStyles.container}>
          <Card>
            <Card.Header>{`Prospect`}</Card.Header>
            <Card.Item>
              <div className={PageStyles.leftRightContainer}>
                <div className={PageStyles.leftFields}>
                  <InputGroup>
                    <InputContainer>
                      <TextInput
                        name='name'
                        label='Name'
                        defaultValue={name || ''}
                        onChange={({ target }) => setName(target.value)}
                      />
                    </InputContainer>
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <TextInput
                        name='email'
                        label='Email Address'
                        defaultValue={email || ''}
                        onChange={({ target }) => setEmail(target.value)}
                      />
                    </InputContainer>
                    <InputContainer>
                      <TextInput
                        name='phoneNumber'
                        label='Phone'
                        defaultValue={phoneNumber || ''}
                        onChange={({ target }) => setPhoneNumber(target.value)}
                      />
                    </InputContainer>
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <SortableSelect
                        className={PageStyles.select}
                        value={skills || []}
                        name='skills'
                        label='Skills'
                        isClearable={false}
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        isMulti
                        onChange={handleSkillChange}
                        isCreatable
                        isPaginated
                        onCreateOption={handleCreateSkill}
                        loadOptions={handleSkillLoad}
                      />
                    </InputContainer>
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <TextArea
                        name='requirements'
                        label='Requirements'
                        placeholder='Add requirements.'
                        defaultValue={requirements || ''}
                        onChange={({ target }) => setRequirements(target.value)}
                      />
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <References
                        attachments={attachments || []}
                        disableAddLink={true}
                        postFileRequest={(data, config) =>
                          postProspectRequirementFile(uuid, data, config)
                        }
                        deleteFileRequest={(id) => deleteProspectRequirementFile(uuid, id)}
                      />
                    </InputContainer>
                  </InputGroup>
                </div>
                <div className={PageStyles.rightFields}>
                  <InputGroup>
                    <InputContainer>
                      <Select
                        data-test-id='status'
                        value={ProspectStatus.getEnum(status)}
                        name={`status`}
                        label={`Status`}
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={ProspectStatus.values}
                        onChange={handleStatusChange}
                        required
                      />
                    </InputContainer>
                  </InputGroup>

                  <InputGroup>
                    <InputContainer style={{ maxWidth: '100%' }}>
                      <UserSelect
                        data-test-id='user'
                        defaultValue={user}
                        name='user'
                        label='User'
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        onChange={({ target }) => setUser(target.value)}
                        isPaginated
                        loadOptions={onUsersLoadOptions}
                      />
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Select
                        data-test-id='organization'
                        value={organization || ''}
                        name='organization'
                        label='Organization (Type to Create)'
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        getNewOptionData={(value, label) => ({
                          uuid: value,
                          name: label,
                          __isNew__: true,
                        })}
                        isCreatable
                        isPaginated
                        cacheOptions
                        formatCreateLabel={(e) => `Create organization "${e}"`}
                        allowCreateWhileLoading={false}
                        onCreateOption={handleCreateOrg}
                        loadOptions={onOrgsLoadOptions}
                        onChange={({ target }) => setOrganization(target.value)}
                      />
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Select
                        data-test-id='brief'
                        value={brief || ''}
                        name='brief'
                        label='Brief (Type to Create)'
                        getOptionLabel={({ title }) => title}
                        getOptionValue={({ uuid }) => uuid}
                        getNewOptionData={(value, label) => ({
                          uuid: value,
                          title: label,
                          __isNew__: true,
                        })}
                        isCreatable
                        isPaginated
                        formatCreateLabel={(e) => `Create brief "${e}"`}
                        allowCreateWhileLoading={false}
                        onCreateOption={handleCreateBrief}
                        loadOptions={onBriefLoadOptions}
                        onChange={({ target }) => setBrief(target.value)}
                      />
                    </InputContainer>
                  </InputGroup>
                </div>
              </div>
            </Card.Item>
            <Card.Footer right>
              {user ? (
                <Button name='submit' type={Button.Type.BLUE} onClick={handleInviteUserToJoin}>
                  {`Resend Invitation Email`}
                </Button>
              ) : (
                <Button
                  name='submit'
                  type={Button.Type.BLUE}
                  onClick={handleConvertProspect}
                  isLoading={isConverting}>
                  {`Convert to User`}
                </Button>
              )}

              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={() =>
                  handleSubmit({
                    skills,
                    uuid,
                    status,
                    user,
                    name,
                    email,
                    requirements,
                    attachments,
                    organization,
                    brief,
                    call,
                    phoneCountry,
                    phoneNumber,
                  })
                }
                isLoading={loading}>
                {`Save Prospect`}
              </Button>
            </Card.Footer>
          </Card>

          {uuid && (
            <CallSchedule
              callResource={'prospects'}
              phoneResource={'prospects'}
              uuid={uuid}
              data={{
                ...call,
                phoneCountry,
                phoneNumber,
              }}
            />
          )}
        </div>
      )}
    </AppLayout>
  );
};

export default ProspectDetail;
