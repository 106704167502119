import { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import {
  onTeamsLoadOptions,
  onOrgsLoadOptions,
  onSkillsLoadOptions,
  onTalentTypesLoadOptions,
  onUsersLoadOptions,
  onAssignmentUsersLoadOptions,
} from '@apis/dropdown';
import {
  AssignmentStatus,
  RateType,
  TimeRate,
  VisibilityOption,
  EngagementStatus,
} from '@hiredigital/lib/helpers/enum';
import { isSalesAdmin, isPlacementAdmin } from '@helpers/permissions';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { withUser } from '@context/user';
import withEmailLabel from '@hoc/withEmailLabel';
import { languageList } from '@data/languageList';
import { countryList } from '@data/countryList';

import AppLayout from '@components/Layout/AppLayout';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import DualInput from '@hiredigital/ui/Input/DualInput';
import Quill from '@hiredigital/ui/Quill/Editor';

import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';
import { putAssignment, deleteAssignment, getAssignment } from '@apis/assignments';
import { postSkill } from '@apis/skills';
import isAfter from 'date-fns/isAfter';
import RateInputGroup from '@components/RateInputGroup';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);
const UserSelect = withEmailLabel(Select);

class AssignmentDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    currentUser: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      assignmentUuid: this.props.match.params.assignmentUuid,
      autoSaveTimeout: null,
      isSaving: false,
      title: null,
      user: null,
      team: null,
      status: null,
      brandRateType: null,
      brandRate: null,
      talentRateType: null,
      talentRate: null,
      brandMinHours: null,
      brandMaxHours: null,
      brandMinPer: null,
      brandMaxPer: null,
      talentMinPer: null,
      talentMaxPer: null,
      talentMinHours: null,
      talentMaxHours: null,
      startDate: null,
      endDate: null,
      organization: null,
      hasInitLoad: false,
      users: [],
      team: [],
      engagement: {},
      isDeleting: false,
      forceUpdate: false,
      errors: {},
      talentEngagementRate: null,
      clientEngagementRate: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.location.hash !== this.props.location.hash ||
      nextProps.currentUser !== this.props.currentUser
    ) {
      return true;
    }

    if (
      nextState.engagement.publishDate !== this.state.engagement.publishDate ||
      nextState.engagement.skills !== this.state.engagement.skills ||
      nextState.user !== this.state.user ||
      nextState.team !== this.state.team ||
      nextState.status !== this.state.status ||
      nextState.isSaving !== this.state.isSaving ||
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.forceUpdate !== this.state.forceUpdate ||
      nextState.errors !== this.state.errors
    ) {
      return true;
    }

    return false;
  }

  componentDidMount = () => {
    this.loadAssignmentDetail().then(({ data }) => {
      setPageTitle(`Assignments | ${data.title || 'New Assignment'}`);
      this.populateFields(data, () => {
        this.setState({ hasInitLoad: true });
      });
    });
  };

  loadAssignmentDetail = () => {
    return getAssignment(this.state.assignmentUuid);
  };

  populateFields = (data, completionCallback) => {
    if (!data.engagement) {
      data.engagement = {};
    }

    if (!data.engagement.publishDate) {
      data.engagement.publishDate = new Date();
    }

    this.setState(
      {
        ...data,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };

  handleCreateSkill = (value) => {
    const data = { label: value, adminGenerated: true };
    postSkill(data).then((response) => {
      this.setState({
        engagement: {
          ...this.state.engagement,
          skills: (this.state.engagement.skills || []).concat([response.data]),
        },
      });
    });
  };

  handleChange = (event, setId, completionCallback) => {
    const { name, value, type, checked } = event.target;
    this.setState(
      {
        [name]: type === 'checkbox' ? checked : setId ? value.id : value,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
    this.handleAutoSave();
  };

  handleOrganizationChange = (event) => {
    this.handleChange(event, false, () => {
      const { organization, engagement } = this.state;
      if (organization && organization.name) {
        this.setState({
          engagement: {
            ...engagement,
            clientName: organization.name,
          },
        });
      }
    });
  };

  handleImmediateSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 0),
    });
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleDelete = () => {
    this.setState({ isDeleting: true });
    deleteAssignment(this.state.assignmentUuid).then((response) => {
      setTimeout(() => {
        this.setState({ isDeleting: false });
        this.props.history.push('/assignments');
      }, 500);
    });
  };

  validateFields = () => {
    const { startDate, endDate } = this.state;
    let errors = {};

    if (startDate && endDate && isAfter(new Date(startDate), new Date(endDate))) {
      errors = { ...errors, startDate: 'Start Date should not be later than End Date.' };
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  handleSubmit = (storeResponse = true) => {
    const {
      title,
      user,
      team,
      status,
      brandRateType,
      brandRate,
      talentRateType,
      talentRate,
      brandMinHours,
      brandMaxHours,
      brandMinPer,
      brandMaxPer,
      talentMinPer,
      talentMaxPer,
      talentMinHours,
      talentMaxHours,
      startDate,
      endDate,
      organization,
      engagement: {
        title: engagementTitle,
        slug,
        responsibilities,
        visibility,
        status: engagementStatus,
        requirements,
        languages,
        countries,
        location,
        skills,
        talentTerms,
        clientTerms,
        clientName,
        publishDate,
        talentType,
      },
    } = this.state;

    if (this.validateFields()) {
      const data = {
        title,
        user: user ? user.uuid : null,
        team: team ? team.uuid : null,
        status,
        brandRateType,
        brandRate: brandRate || null,
        talentRateType,
        talentRate: talentRate || null,
        brandMinHours,
        brandMaxHours,
        brandMinPer,
        brandMaxPer,
        talentMinPer,
        talentMaxPer,
        talentMinHours,
        talentMaxHours,
        startDate,
        endDate,
        organization: organization ? organization.uuid : null,
        engagement: {
          title: engagementTitle,
          slug,
          responsibilities,
          requirements,
          location,
          status: engagementStatus,
          visibility,
          talentTerms,
          clientTerms,
          clientName,
          publishDate,
          talentType: talentType ? talentType.id : null,
        },
      };

      data.engagement.countries = countries?.map((o) => o.id) || [];
      data.engagement.languages = languages?.map((o) => o.id) || [];
      data.engagement.skills = skills?.map((o) => o.id) || [];

      this.setState({ isSaving: true });
      //this.saveAssignmentDetail(data).then(
      putAssignment(this.state.assignmentUuid, data).then(
        (response) => {
          if (storeResponse) {
            this.populateFields(response);
          }
          this.setState({ isSaving: false, autoSaveTimeout: null });
        },
        (error) => {
          this.setState({ isSaving: false, autoSaveTimeout: null });
        }
      );
    }
  };

  handleStartDateChange = (value) => {
    this.setState({ startDate: value, errors: {} });
    this.handleAutoSave();
  };

  handleEndDateChange = (value) => {
    this.setState({ endDate: value, errors: {} });
    this.handleAutoSave();
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleEngagementChange = (event, valueKey) => {
    const { name, value } = event.target;
    this.setState(
      {
        engagement: {
          ...this.state.engagement,
          [name]: valueKey ? value[valueKey] : value,
        },
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  handleEngagementDateChange = (value, name) => {
    this.setState({
      engagement: {
        ...this.state.engagement,
        [name]: value,
      },
    });
  };

  getDatePickers = () => (
    <>
      <InputContainer>
        <DatePicker
          id='detailStartDate'
          name='startDate'
          label='Start Date'
          value={this.state.startDate}
          onChange={this.handleStartDateChange}
          error={this.state.errors?.startDate}
        />
      </InputContainer>
      <InputContainer>
        <DatePicker
          id='detailEndDate'
          name='endDate'
          label='End Date'
          value={this.state.endDate}
          onChange={this.handleEndDateChange}
          // minDate={this.state.startDate ? new Date(this.state.startDate) : undefined}
        />
      </InputContainer>
    </>
  );

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/assignments'>
              {`Assignments`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            {this.state.hasInitLoad && (
              <div className={PageStyles.headerActive}>
                {this.state.title || 'Untitled Assignment'}
              </div>
            )}
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <h3 className={classNames(PageStyles.headline, PageStyles.headlineRow)}>
              {`Assignment`}
            </h3>
            <div className={PageStyles.leftRightContainer}>
              <div className={PageStyles.leftFields}>
                <InputContainer>
                  <TextInput
                    name='title'
                    label='Title'
                    defaultValue={this.state.title}
                    placeholder='Title'
                    onChange={this.handleChange}
                  />
                </InputContainer>
                <InputGroup>
                  <InputContainer>
                    <UserSelect
                      id='userID'
                      className={PageStyles.select}
                      defaultValue={this.state.user}
                      classNamePrefix='s-contact'
                      name='user'
                      label='User'
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      options={this.state.users}
                      onChange={this.handleChange}
                      loadOptions={onUsersLoadOptions}
                      isPaginated
                      isClearable
                    />
                  </InputContainer>

                  <InputContainer>
                    <Select
                      data-test-id='team'
                      className={PageStyles.select}
                      defaultValue={this.state.team}
                      classNamePrefix='s-contact'
                      name='team'
                      label='Team'
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      options={this.state.teams}
                      onChange={this.handleChange}
                      isPaginated
                      loadOptions={onTeamsLoadOptions}
                      isClearable
                    />
                  </InputContainer>
                </InputGroup>
              </div>
              <div className={PageStyles.rightFields}>
                <InputContainer>
                  <Select
                    data-test-id='status'
                    className={PageStyles.select}
                    defaultValue={AssignmentStatus.getEnum(this.state.status)}
                    classNamePrefix='s-contact'
                    name='status'
                    label='Status'
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={AssignmentStatus.values}
                    onChange={(e) => this.handleChange(e, true)}
                  />
                </InputContainer>
                <InputContainer>
                  <Select
                    data-test-id='organization'
                    className={PageStyles.select}
                    defaultValue={this.state.organization}
                    classNamePrefix='s-contact'
                    name='organization'
                    label='Client'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    options={this.state.orgs}
                    onChange={this.handleOrganizationChange}
                    isPaginated
                    loadOptions={onOrgsLoadOptions}
                    isClearable
                  />
                </InputContainer>
              </div>
            </div>
            {this.props.currentUser &&
              isNewRates &&
              (isPlacementAdmin(this.props.currentUser) ||
                this.props.currentUser.adminPermission?.assignmentTalentRate) && (
                <RateInputGroup
                  rate={[
                    this.state.talentEngagementRate,
                    (r) => this.setState({ talentEngagementRate: r }),
                  ]}
                  resource={'assignment'}
                  resourceUuid={this.state.uuid}
                  hideBgColorArea
                />
              )}
            {this.props.currentUser &&
              isNewRates &&
              (isSalesAdmin(this.props.currentUser) ||
                this.props.currentUser.adminPermission?.assignmentClientRate) && (
                <RateInputGroup
                  isClient
                  rate={[
                    this.state.clientEngagementRate,
                    (r) => this.setState({ clientEngagementRate: r }),
                  ]}
                  resource={'assignment'}
                  resourceUuid={this.state.uuid}
                  hideBgColorArea
                />
              )}
            <div className={PageStyles.leftRightContainer}>
              <div className={PageStyles.leftFields}>
                {this.props.currentUser &&
                  !isNewRates &&
                  (isPlacementAdmin(this.props.currentUser) ||
                    this.props.currentUser.adminPermission?.assignmentTalentRate) && (
                    <InputGroup>
                      <InputContainer>
                        <DualInput
                          input={(Input) => (
                            <Input
                              name='talentRate'
                              label='Talent Rate (SGD)'
                              type='number'
                              step='.01'
                              defaultValue={this.state.talentRate}
                              onChange={this.handleChange}></Input>
                          )}
                          select={(Select) => (
                            <Select
                              id='talentRateTypeID'
                              name='talentRateType'
                              label='Rate Type'
                              style={{ flexBasis: '115px' }}
                              defaultValue={RateType.getEnum(this.state.talentRateType)}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={RateType.values}
                              onChange={(e) => this.handleChange(e, true)}></Select>
                          )}></DualInput>
                      </InputContainer>
                      <InputContainer>
                        <DualInput
                          input={(Input) => (
                            <Input
                              name='talentMinHours'
                              label='Min Talent Hours'
                              type='number'
                              step='.01'
                              defaultValue={this.state.talentMinHours}
                              onChange={this.handleChange}></Input>
                          )}
                          select={(Select) => (
                            <Select
                              id='talentMinPerID'
                              name='talentMinPer'
                              label='Per'
                              style={{ flexBasis: '130px' }}
                              defaultValue={TimeRate.getEnum(this.state.talentMinPer)}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={TimeRate.values}
                              onChange={(e) => this.handleChange(e, true)}></Select>
                          )}></DualInput>
                      </InputContainer>
                      <InputContainer>
                        <DualInput
                          input={(Input) => (
                            <Input
                              name='talentMaxHours'
                              label='Max Talent Hours'
                              type='number'
                              step='.01'
                              defaultValue={this.state.talentMaxHours}
                              onChange={this.handleChange}></Input>
                          )}
                          select={(Select) => (
                            <Select
                              id='talentMaxPerID'
                              name='talentMaxPer'
                              label='Per'
                              style={{ flexBasis: '130px' }}
                              defaultValue={TimeRate.getEnum(this.state.talentMaxPer)}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={TimeRate.values}
                              onChange={(e) => this.handleChange(e, true)}></Select>
                          )}></DualInput>
                      </InputContainer>
                    </InputGroup>
                  )}
                {this.props.currentUser &&
                  !isNewRates &&
                  (isSalesAdmin(this.props.currentUser) ||
                    this.props.currentUser.adminPermission?.assignmentClientRate) && (
                    <InputGroup>
                      <InputContainer>
                        <DualInput
                          input={(Input) => (
                            <Input
                              name='brandRate'
                              label='Client Rate (SGD)'
                              type='number'
                              step='.01'
                              defaultValue={this.state.brandRate}
                              onChange={this.handleChange}></Input>
                          )}
                          select={(Select) => (
                            <Select
                              id='brandRateTypeID'
                              name='brandRateType'
                              label='Rate Type'
                              style={{ flexBasis: '115px' }}
                              defaultValue={RateType.getEnum(this.state.brandRateType)}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={RateType.values}
                              onChange={(e) => this.handleChange(e, true)}></Select>
                          )}></DualInput>
                      </InputContainer>
                      <InputContainer>
                        <DualInput
                          input={(Input) => (
                            <Input
                              name='brandMinHours'
                              label='Min Client Hours'
                              type='number'
                              step='.01'
                              defaultValue={this.state.brandMinHours}
                              onChange={this.handleChange}></Input>
                          )}
                          select={(Select) => (
                            <Select
                              id='brandMinPerID'
                              name='brandMinPer'
                              label='Per'
                              style={{ flexBasis: '130px' }}
                              defaultValue={TimeRate.getEnum(this.state.brandMinPer)}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={TimeRate.values}
                              onChange={(e) => this.handleChange(e, true)}></Select>
                          )}></DualInput>
                      </InputContainer>
                      <InputContainer>
                        <DualInput
                          input={(Input) => (
                            <Input
                              name='brandMaxHours'
                              label='Max Client Hours'
                              type='number'
                              step='.01'
                              defaultValue={this.state.brandMaxHours}
                              onChange={this.handleChange}></Input>
                          )}
                          select={(Select) => (
                            <Select
                              id='brandMaxPerID'
                              name='brandMaxPer'
                              label='Per'
                              style={{ flexBasis: '130px' }}
                              defaultValue={TimeRate.getEnum(this.state.brandMaxPer)}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={TimeRate.values}
                              onChange={(e) => this.handleChange(e, true)}></Select>
                          )}></DualInput>
                      </InputContainer>
                    </InputGroup>
                  )}
                {isNewRates && <InputGroup>{this.getDatePickers()}</InputGroup>}
              </div>
              {!isNewRates && <div className={PageStyles.rightFields}>{this.getDatePickers()}</div>}
            </div>
            <h3
              className={classNames(PageStyles.headline, PageStyles.headlineRow)}
              style={{ marginTop: '40px' }}>
              {`Engagement`}
            </h3>

            <div className={Styles.note}>
              {`In order to ensure the engagement shows up properly for the talent,
                   make sure the title, client name, talent type, and publish date are
                   filled out.`}
            </div>

            <div className={PageStyles.leftRightContainer}>
              <div className={PageStyles.leftFields}>
                <InputContainer>
                  <TextInput
                    name='title'
                    label='Title'
                    defaultValue={this.state.engagement.title}
                    onChange={this.handleEngagementChange}
                  />
                </InputContainer>

                <InputContainer style={{ zIndex: 0 }}>
                  <Quill
                    name='responsibilities'
                    label='Responsibilities'
                    placeholder='Responsibilities'
                    defaultValue={this.state.engagement.responsibilities || ''}
                    onChange={this.handleEngagementChange}
                    style={{ minHeight: '126px' }}
                    legacyCompat
                  />
                </InputContainer>

                <InputContainer style={{ zIndex: 0 }}>
                  <Quill
                    name='requirements'
                    label='Requirements'
                    placeholder='Requirements'
                    defaultValue={this.state.engagement.requirements || ''}
                    onChange={this.handleEngagementChange}
                    style={{ minHeight: '126px' }}
                    legacyCompat
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    data-test-id='skills'
                    className={PageStyles.select}
                    value={this.state.engagement.skills}
                    classNamePrefix='s-contact'
                    name='skills'
                    label='Skills'
                    isClearable={false}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    isMulti
                    onChange={this.handleEngagementChange}
                    isPaginated
                    isCreatable
                    onCreateOption={this.handleCreateSkill}
                    loadOptions={onSkillsLoadOptions}
                  />
                </InputContainer>

                <InputGroup>
                  {this.props.currentUser &&
                    (isPlacementAdmin(this.props.currentUser) ||
                      this.props.currentUser.adminPermission?.assignmentTalentRate) && (
                      <InputContainer style={{ zIndex: 0 }}>
                        <Quill
                          name='talentTerms'
                          label='Talent Terms'
                          placeholder='Talent Terms'
                          defaultValue={this.state.engagement.talentTerms || ''}
                          onChange={this.handleEngagementChange}
                          style={{ minHeight: '252px' }}
                          legacyCompat
                        />
                      </InputContainer>
                    )}

                  {this.props.currentUser &&
                    (isSalesAdmin(this.props.currentUser) ||
                      this.props.currentUser.adminPermission?.assignmentClientRate) && (
                      <InputContainer style={{ zIndex: 0 }}>
                        <Quill
                          name='clientTerms'
                          label='Client Terms'
                          placeholder='Client Terms'
                          defaultValue={this.state.engagement.clientTerms || ''}
                          onChange={this.handleEngagementChange}
                          style={{ minHeight: '252px' }}
                          legacyCompat
                        />
                      </InputContainer>
                    )}
                </InputGroup>
              </div>

              <div className={PageStyles.rightFields}>
                <InputContainer>
                  <TextInput
                    name='slug'
                    label='Slug'
                    defaultValue={this.state.engagement.slug}
                    onChange={this.handleEngagementChange}
                  />
                </InputContainer>

                <InputContainer>
                  <TextInput
                    name='clientName'
                    label='Client Name'
                    defaultValue={this.state.engagement.clientName}
                    onChange={this.handleEngagementChange}
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    data-test-id='talentType'
                    className={PageStyles.select}
                    defaultValue={this.state.engagement.talentType}
                    classNamePrefix='s-contact'
                    name='talentType'
                    label='Talent Type'
                    isClearable
                    isPaginated
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    onChange={this.handleEngagementChange}
                    loadOptions={onTalentTypesLoadOptions}
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    data-test-id='visibility'
                    className={PageStyles.select}
                    defaultValue={VisibilityOption.getEnum(this.state.engagement.visibility)}
                    classNamePrefix='s-contact'
                    name='visibility'
                    label='Visibility'
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={VisibilityOption.values}
                    onChange={(e) => this.handleEngagementChange(e, 'id')}
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    data-test-id='status2'
                    className={PageStyles.select}
                    defaultValue={EngagementStatus.getEnum(this.state.engagement.status)}
                    classNamePrefix='s-contact'
                    name='status'
                    label='Status'
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={EngagementStatus.values}
                    onChange={(e) => this.handleEngagementChange(e, 'id')}
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    data-test-id='languages'
                    className={PageStyles.select}
                    defaultValue={this.state.engagement.languages}
                    classNamePrefix='s-contact'
                    name='languages'
                    label='Language'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    options={languageList}
                    isMulti
                    onChange={this.handleEngagementChange}
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    data-test-id='countries'
                    className={PageStyles.select}
                    defaultValue={this.state.engagement.countries}
                    classNamePrefix='s-contact'
                    name='countries'
                    label='Country'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    options={countryList}
                    isMulti
                    onChange={this.handleEngagementChange}
                  />
                </InputContainer>

                <InputContainer>
                  <TextInput
                    name='location'
                    label='Location (if on-site)'
                    defaultValue={this.state.engagement.location}
                    onChange={this.handleEngagementChange}
                  />
                </InputContainer>

                <InputContainer>
                  <DatePicker
                    id='detailPublishDate'
                    name='publishDate'
                    label='Publish Date'
                    value={this.state.engagement.publishDate}
                    onChange={(date) => this.handleEngagementDateChange(date, 'publishDate')}
                  />
                </InputContainer>
              </div>
            </div>

            <div
              className={classNames(PageStyles.formContainer, PageStyles.stickyRow)}
              style={{ marginBottom: '0' }}>
              <div className={Styles.saveRow}>
                <Button
                  name='submit'
                  className={Styles.deleteButton}
                  type={Button.Type.RED_OUTLINE}
                  onClick={this.handleDelete}
                  isLoading={this.state.isDeleting}>
                  {`Delete`}
                </Button>
                <Button
                  name='submit'
                  className={Styles.saveButton}
                  type={Button.Type.BLUE}
                  onClick={this.handleImmediateSave}
                  isLoading={this.state.isSaving}>
                  {`Save`}
                </Button>
              </div>
            </div>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default withUser(AssignmentDetail);
