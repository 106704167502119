import { useState } from 'react';
import PropTypes from 'prop-types';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import { putTeamUser, postTeamUser, postTeamOwnerChange } from '@apis/teams';
import { onUsersLoadOptions } from '@apis/dropdown';
import withEmailLabel from '@hoc/withEmailLabel';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import Styles from './Members.module.scss';
const UserSelect = withEmailLabel(Select);

const MemberItem = ({
  team,
  member: isInitialMember,
  deleteMember,
  isOwner,
  onOwnerChange,
  isEdit: isInitialEdit,
  onCancel,
  onCreate,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(isInitialEdit || false);
  const [member, setMember] = useState(isInitialMember || {});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMember({ ...member, [name]: value });
  };

  const handleCancel = () => {
    setMember(isInitialMember || {});
    setIsEdit(false);
    // eslint-disable-next-line no-unused-expressions
    onCancel?.();
  };

  const validateFields = () => {
    if (!member?.user?.uuid) {
      setErrors({ ...errors, user: 'User is required.' });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const data = {
        role: member?.role,
        user: member?.user?.uuid,
      };
      setLoading(true);
      if (member.id) {
        putTeamUser(team.uuid, member.id, data).then(
          (response) => {
            setLoading(false);
            setIsEdit(false);
          },
          (error) => {
            setLoading(false);
          }
        );
      } else {
        postTeamUser(team.uuid, data).then(
          (response) => {
            setMember(response.data);
            setLoading(false);
            setIsEdit(false);
            // eslint-disable-next-line no-unused-expressions
            onCreate?.(response.data);
          },
          (error) => {
            setLoading(false);
          }
        );
      }
    }
  };

  const handleMakeTeamOwner = (teamUser) => {
    postTeamOwnerChange(team.uuid, { teamUser }).then(({ data }) => {
      // eslint-disable-next-line no-unused-expressions
      onOwnerChange?.(data);
    });
  };

  return (
    <Card.Item style={{ padding: '10px 20px' }}>
      {isEdit && (
        <div className={Styles.form}>
          <InputGroup>
            <InputContainer>
              <UserSelect
                className={Styles.select}
                value={member?.user}
                classNamePrefix='s-contact'
                name='user'
                label='User'
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                onChange={handleChange}
                isPaginated
                loadOptions={onUsersLoadOptions}
                disabled={team?.owner?.uuid && member?.user?.uuid === team?.owner?.uuid}
                error={errors?.user}
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name='role'
                label='Team Role'
                type='text'
                value={member?.role}
                onChange={handleChange}
                error={errors?.role}
              />
            </InputContainer>
          </InputGroup>

          <div className={Styles.row}>
            <Button
              name='delete'
              type={Button.Type.WHITE}
              onClick={(e) => deleteMember(member)}
              disabled={team?.owner?.uuid && member?.user?.uuid === team?.owner?.uuid}>
              {`Delete`}
            </Button>

            <div className={Styles.action}>
              <Button name='cancel' type={Button.Type.WHITE_BLUE_OUTLINE} onClick={handleCancel}>
                {`Cancel`}
              </Button>

              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={handleSubmit}
                isLoading={loading}>
                {`Save`}
              </Button>
            </div>
          </div>
        </div>
      )}

      {!isEdit && (
        <div className={Styles.row}>
          <img className={Styles.picture} src={member?.user?.picture} alt='' />
          <div style={{ overflow: 'hidden' }}>
            <a href={`/profiles/${member?.user?.uuid}`} className={Styles.name}>
              {member?.user?.name}
              {team?.owner?.uuid && member?.user?.uuid === team?.owner?.uuid && (
                <span style={{ opacity: '.5' }}>{` (Team Creator)`}</span>
              )}
            </a>

            <p className={Styles.info}>
              {member?.role} {member?.role && member?.user?.email && <> &bull; </>}
              {member?.user?.email}
            </p>

            <p className={Styles.info}></p>
          </div>

          <div className={Styles.action}>
            {/* <Button
              name='Edit'
              className={Styles.saveButton}
              type={Button.Type.BLUE_OUTLINE}
              onClick={() => setIsEdit(true)}>
              {`Edit`}
            </Button>

            <Dropdown size='sm' drop='down' alignRight>
              <Dropdown.Toggle name='Edit ellipsis' className={Styles.moreDropdown}>
                <IconMoreVertical className={Styles.moreIcon} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={`/profiles/${member?.user?.uuid}`}>
                  {`View Talent Profile`}
                </Dropdown.Item>
                <Dropdown.Item
                  href={`${process.env.MARKETING_ENDPOINT}/resumes/${member?.user?.username}`}
                  target='_blank'
                  onClick={() => setIsEdit(true)}>
                  {`View Public Resume`}
                </Dropdown.Item>
                {!isOwner && (
                  <Dropdown.Item onClick={() => handleMakeTeamOwner(member.id)}>
                    {`Mark as Team Owner`}
                  </Dropdown.Item>
                )}
                {!isOwner && (
                  <Dropdown.Item onClick={() => deleteMember(member)}>
                    {`Delete Member`}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown> */}
            <SplitButton
              type={Button.Type.BLUE_OUTLINE}
              onClick={() => setIsEdit(true)}
              more={[
                { text: 'View Talent Profile', as: 'a', href: `/profiles/${member?.user?.uuid}` },
                {
                  text: 'View Public Resume',
                  as: 'a',
                  href: `${process.env.MARKETING_ENDPOINT}/resumes/${member?.user?.username}`,
                },
                {
                  ...(!isOwner && {
                    text: 'Mark as Team Owner',
                    onClick: () => handleMakeTeamOwner(member.id),
                  }),
                },
                {
                  ...(!isOwner && {
                    text: 'Delete Member',
                    onClick: () => deleteMember(member),
                  }),
                },
              ]}>{`Edit`}</SplitButton>
          </div>
        </div>
      )}
    </Card.Item>
  );
};

MemberItem.propTypes = {
  team: PropTypes.object,
  member: PropTypes.object,
  deleteMember: PropTypes.func,
  isOwner: PropTypes.bool,
  onOwnerChange: PropTypes.func,
};

export default MemberItem;
