import { Component } from 'react';
import Styles from './Styles.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info);
    // You can also log the error to an error reporting service
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div className={Styles.container}>
        <h2 className={Styles.errorHeadline}>{`Sorry, Something went wrong...`}</h2>
        <p className={Styles.errorDescription}>{`Slack @tim to get this issue fixed.`}</p>
        <p className={Styles.errorDescription}>
          {`You can try to refresh the page or try again later.`}
        </p>
      </div>
    );
  }
}

export default ErrorBoundary;
