import { useState, Fragment } from 'react';
import Toggle from 'react-toggle';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Select from '@hiredigital/ui/Input/Select';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { authRequest } from '@apis/utils';
import { getDropdownAssignments } from '@apis/common';

import { putProjectMember, postProjectMember } from '@apis/projects';
import withEmailLabel from '@hoc/withEmailLabel';

import Styles from './Styles.module.scss';

const UserSelect = withEmailLabel(Select);

const Item = ({
  member,
  onDelete,
  projectUuid,
  orgUuid,
  onCancel,
  onCreate,
  onUpdate,
  isDeleting,
}) => {
  const [data, setData] = useState(member);
  const [isShown, setIsShown] = useState(member?.isAdd || false);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const handleCancel = () => {
    setData(member);
    setIsShown(false);
    onCancel?.();
  };

  const handleEdit = () => setIsShown(true);

  const onAssignmentsLoadOptions = async (search, loadedOptions, { page }) => {
    const payload = {
      params: {
        search,
        page,
        user: data?.user?.uuid,
        organization: orgUuid,
      },
    };
    const response = await getDropdownAssignments(payload);
    return {
      options: response.data.results,
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  const onOrgRelatedUsersLoadOptions = async (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20, showAll: true } };
    const url = `admin/dropdown-org-related-users/?organization=${orgUuid}`;
    const response = await authRequest.get(url, data);
    return {
      options: response.data.results,
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  const handleSubmit = () => {
    const { user, team, assignment, disableNotifications, status } = data;
    const payload = {
      user: user ? user.uuid : null,
      team: team ? team.uuid : null,
      assignment: assignment?.uuid || null,
      disableNotifications,
      status,
    };
    saveMember(payload);
  };

  const saveMember = (payload) => {
    setIsSaving(true);
    setErrors({});
    const id = member.id;

    Promise.all([
      id ? putProjectMember(projectUuid, id, payload) : postProjectMember(projectUuid, payload),
    ])
      .then(([{ data }]) => {
        setIsShown(false);
        setIsSaving(false);
        id ? onUpdate?.(data) : onCreate?.(data);
      })
      .catch(() => {
        setIsShown(false);
        setIsSaving(false);
        onCancel?.();
      });
  };

  const handleDeleteItem = () => onDelete?.(member);

  return (
    <Card.Item style={{ padding: '10px 20px' }}>
      {(isShown && (
        <Fragment>
          <div className={Styles.row}>
            <InputGroup>
              <InputContainer>
                <UserSelect
                  id='userID'
                  className={Styles.select}
                  defaultValue={data?.user}
                  classNamePrefix='s-contact'
                  name='user'
                  label='User'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ uuid }) => uuid}
                  onChange={(e) => setData({ ...data, user: e.target.value })}
                  isPaginated
                  loadOptions={onOrgRelatedUsersLoadOptions}
                  error={errors?.user}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  data-test-id='assignment'
                  key={`${data?.user?.uuid}-${data?.id}`}
                  className={Styles.select}
                  defaultValue={data?.assignment}
                  classNamePrefix='s-contact'
                  name='assignment'
                  label='Assignment'
                  getOptionLabel={({ title, organization, user, uuid }) =>
                    `${title || 'New Assignment'} | ${organization && organization.name} `
                  }
                  getOptionValue={(assigmment) => assigmment}
                  onChange={(e) => setData({ ...data, assignment: e.target.value })}
                  isPaginated
                  isClearable
                  loadOptions={onAssignmentsLoadOptions}
                  error={errors?.assignment}
                />
              </InputContainer>
            </InputGroup>
          </div>
          <div className={Styles.row}>
            <Button
              name='Delete'
              type={Button.Type.RED_OUTLINE}
              onClick={handleDeleteItem}
              isisSaving={isDeleting}>
              {`Delete`}
            </Button>

            <div className={Styles.action}>
              <Button name='cancel' type={Button.Type.WHITE_BLUE_OUTLINE} onClick={handleCancel}>
                {`Cancel`}
              </Button>

              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={handleSubmit}
                isSaving={isSaving}>
                {`Save`}
              </Button>
            </div>
          </div>
        </Fragment>
      )) || (
        <div className={Styles.row}>
          <img className={Styles.picture} src={data?.user?.picture} alt='' />
          <div style={{ overflow: 'hidden' }}>
            <p className={Styles.name}>{data?.user?.name}</p>
          </div>

          <div className={Styles.action}>
            <div className={Styles.toggleManager}>
              <Toggle
                id={`disable-notifications-${member.id}`}
                name='disableNotifications'
                defaultChecked={data?.disableNotifications}
                onChange={(e) => {
                  setData({ ...data, disableNotifications: e.target.checked });
                  saveMember({ disableNotifications: e.target.checked });
                }}
              />
              <label htmlFor={`disable-notifications-${member.id}`} className={Styles.toggleLabel}>
                {`Disable Notifications`}
              </label>
            </div>

            <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={handleEdit}>
              {`Edit`}
            </Button>
          </div>
        </div>
      )}
    </Card.Item>
  );
};

export default Item;
