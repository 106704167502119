import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import withEmailLabel from '@hoc/withEmailLabel';
import { onClientUsersLoadOptions } from '@apis/dropdown';
import {
  deleteOrgInvitation,
  putOrgInvitation,
  createOrgInvitation,
  createOrgUser,
  deleteOrgUser,
} from '@apis/orgs';
import { Site } from '@hiredigital/lib/helpers/enum';
import { validateEmail } from '@helpers/forms';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Action from '@hiredigital/ui/Action';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import Member from './Member';
import Invitation from './Invitation';

import Styles from './Styles.module.scss';

const UserSelect = withEmailLabel(Select);

class MemberList extends Component {
  static propTypes = {
    members: PropTypes.array,
    invitations: PropTypes.array,
    orgUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      members: [],
      invitations: [],
      email: '',
      name: '',
      site: Site.CONTENTCO.id,
      role: '',
      isManager: false,
      showInviteForm: false,
      showMemberForm: false,
      emailValid: true,
      submitted: false,
      errors: {},
    };
    this.addMemberDebounce = debounce(this.addMember, 500);
  }

  componentDidMount = () => {
    this.setState({
      members: this.props.members,
      invitations: this.props.invitations,
    });
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  handleUpdateInvitation = (invitation, status) => {
    const data = { status: status.id };
    putOrgInvitation(this.props.orgUuid, invitation.id, data).then((response) => {
      const invitations = this.state.invitations.map((item) => {
        if (item.id === ((invitation && invitation.id) || '')) {
          item.status = status.id;
        }
        return item;
      });
      this.setState({ invitations });
    });
  };

  handleDeleteInvitation = (invitation, index) => {
    deleteOrgInvitation(this.props.orgUuid, invitation.id).then(() => {
      const invitations = this.state.invitations.filter((v) => v?.id !== invitation?.id);
      // invitations.splice(index, 1);
      this.setState({ invitations });
    });
  };

  sendInvitation = () => {
    this.setState({ submitted: true });
    const { email, name, role, site } = this.state;

    if (email) {
      this.setState({ emailValid: validateEmail(email) });
    }

    if (email && this.state.emailValid) {
      this.setState({ loading: true });

      const data = {
        email,
        name,
        role,
        site: site?.id,
      };

      createOrgInvitation(this.props.orgUuid, data).then(
        (response) => {
          const invitations = this.state.invitations;
          invitations.push(response.data);
          this.setState({
            email: '',
            name: '',
            role: '',
            loading: false,
            showInviteForm: false,
            showMemberForm: false,
            submitted: false,
            invitations,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    }
  };

  handleToggleInvitationForm = () => {
    this.setState({
      showInviteForm: !this.state.showInviteForm,
      submitted: false,
    });

    // this.setState((prevState, props) => ({ showInviteForm: !prevState.showInviteForm, submitted: false }))
  };

  handleToggleMemberForm = () => {
    this.setState({
      showMemberForm: !this.state.showMemberForm,
      submitted: false,
    });

    // this.setState((prevState, props) => ({ showInviteForm: !prevState.showInviteForm, submitted: false }))
  };

  handleCreateNewMember = () => {
    if (!this.validate()) return;
    const { user, isManager, role } = this.state;
    this.addMemberDebounce({ user: user.uuid, role, isManager });
  };

  addMember = (data) => {
    const { members } = this.state;
    this.setState({ loading: true, errors: {} });
    createOrgUser(this.props.orgUuid, data).then(
      (response) => {
        //members.push(response.data);
        this.setState({
          loading: false,
          showMemberForm: false,
          members: [...members, response.data],
        });
      },
      (error) => {
        this.setState({ loading: false, showMemberForm: false });
      }
    );
  };

  validate = () => {
    const { user } = this.state;
    if (!user) {
      this.setState({ errors: { user: 'User is required.' } });
      return false;
    }
    if (this.isMemberExist(this.state.user)) {
      this.setState({ errors: { user: 'User already exist.' } });
      return false;
    }
    return true;
  };

  isMemberExist = ({ uuid }) => {
    const { members } = this.state;
    const found = members.find((member) => member.user.uuid === uuid);
    return !!found;
  };

  deleteMember = (member) => {
    const index = this.state.members.indexOf(member);
    this.setState({ loading: true });
    deleteOrgUser(this.props.orgUuid, member.id).then(
      (response) => {
        this.state.members.splice(index, 1);
        this.setState({ members: this.state.members, loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const validateEmail = () => {
      if (this.state.submitted) {
        if (!this.state.email) {
          return 'Your must include an e-mail address.';
        } else if (!this.state.emailValid) {
          return 'Enter a valid Email';
        }
      }
    };

    return (
      <Card>
        <Card.Header>
          <span>{`Members`}</span>
          <div className={Styles.action}>
            {!this.state.showMemberForm && (
              <Button name='add new' type={Button.Type.BLUE} onClick={this.handleToggleMemberForm}>
                {`Add Existing User`}
              </Button>
            )}
            {!this.state.showInviteForm && (
              <Button
                name='add new'
                type={Button.Type.BLUE}
                onClick={this.handleToggleInvitationForm}>
                {`Invite New User`}
              </Button>
            )}
          </div>
        </Card.Header>
        <Card.Note>
          {`Admins of an organization will have access to 
          timesheets (if enabled for the organization) and access to 
          billing, payment, and talent reviews.`}
        </Card.Note>

        <Fragment>
          {(this.state.members &&
            this.state.members.length &&
            this.state.members.map((member, index) => (
              <Member
                member={member}
                deleteMember={this.deleteMember}
                key={member.id}
                orgUuid={this.props.orgUuid}
              />
            ))) ||
            ''}

          {(this.state.invitations &&
            this.state.invitations.length &&
            this.state.invitations.map((invitation, index) => (
              <Invitation
                invitation={invitation}
                key={invitation.id}
                orgUuid={this.props.orgUuid}
                onUpdateInvitation={(invitation, status) => {
                  this.handleUpdateInvitation(invitation, status);
                }}
                onDeleteInvitation={(invitation) => {
                  this.handleDeleteInvitation(invitation, index);
                }}
              />
            ))) ||
            ''}

          {this.state.members &&
            this.state.members.length === 0 &&
            this.state.invitations &&
            this.state.invitations.length === 0 && (
              <Card.Item>
                <div className={Styles.empty}>No members added</div>
              </Card.Item>
            )}

          {this.state.showInviteForm && (
            <>
              <Card.Item>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      name='email'
                      label='Email'
                      error={validateEmail()}
                      defaultValue={this.state.email}
                      onChange={this.handleChange}
                    />
                  </InputContainer>

                  <InputContainer>
                    <TextInput
                      name='name'
                      label='Name'
                      defaultValue={this.state.name}
                      onChange={this.handleChange}
                    />
                  </InputContainer>

                  <InputContainer>
                    <Select
                      data-test-id='site'
                      className={Styles.select}
                      defaultValue={Site.getEnum(this.state.site)}
                      classNamePrefix='s-contact'
                      name='site'
                      label='Site'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      options={Site.values}
                      onChange={(e) => this.handleChange(e, 'id')}
                    />
                  </InputContainer>
                </InputGroup>
              </Card.Item>
              <Card.Footer>
                <Action>
                  <Button
                    name='cancel'
                    type={Button.Type.WHITE_BLUE_OUTLINE}
                    onClick={this.handleToggleInvitationForm}>
                    {`Cancel`}
                  </Button>
                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={this.sendInvitation}
                    isLoading={this.state.loading}>
                    {`Send Invitation`}
                  </Button>
                </Action>
              </Card.Footer>
            </>
          )}

          {this.state.showMemberForm && (
            <>
              <Card.Item>
                <InputGroup>
                  <InputContainer style={{ marginBottom: '0' }}>
                    <UserSelect
                      className={Styles.select}
                      defaultValue={this.state.user}
                      classNamePrefix='s-contact'
                      name='user'
                      label='User'
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      options={this.state.users}
                      onChange={this.handleChange}
                      isPaginated
                      loadOptions={onClientUsersLoadOptions}
                      error={this.state.errors.user}
                    />
                  </InputContainer>
                </InputGroup>
              </Card.Item>
              <Card.Footer>
                <Action>
                  <Button
                    name='cancel'
                    type={Button.Type.WHITE_BLUE_OUTLINE}
                    onClick={this.handleToggleMemberForm}>
                    {`Cancel`}
                  </Button>

                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={this.handleCreateNewMember}
                    isLoading={this.state.loading}>
                    {`Add Member`}
                  </Button>
                </Action>
              </Card.Footer>
            </>
          )}
        </Fragment>
      </Card>
    );
  }
}

export default MemberList;
