import { Component, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';

import Styles from './Styles.module.scss';

const Address = ({ address: addressObj, payment, onSave, title }) => {
  const [address, setAddress] = useState(addressObj.address);
  const [city, setCity] = useState(addressObj.city);
  const [region, setRegion] = useState(addressObj.region);
  const [country, setCountry] = useState(addressObj.country);
  const [postalCode, setPostalCode] = useState(addressObj.postalCode);
  const [loading, setLoading] = useState(false);

  const handleSave = async (event) => {
    setLoading(true);

    try {
      await onSave?.({
        address: {
          city,
          country,
          postalCode,
          address,
          region,
        },
      });
      setLoading(false);
    } catch (err) {
      return;
    }
    setLoading(false);
  };

  return (
    <Card>
      <Card.Header>
        <span className={Styles.headline}>{title}</span>
      </Card.Header>
      <Fragment>
        <Card.Item>
          <InputGroup>
            <InputContainer>
              <TextArea
                name='address'
                label='Address'
                placeholder='Address'
                value={address || ''}
                onChange={(e) => setAddress(e.target.value)}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='city'
                label='City'
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name='region'
                label='State'
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='country'
                label='Country'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name='postalCode'
                label='Postal Code'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </InputContainer>
          </InputGroup>
        </Card.Item>
        <Card.Footer right>
          <Button name='submit' type={Button.Type.BLUE} onClick={handleSave} isLoading={loading}>
            {`Update Address`}
          </Button>
        </Card.Footer>
      </Fragment>
    </Card>
  );
};

Address.propTypes = {
  address: PropTypes.object,
  payment: PropTypes.object,
  onSave: PropTypes.func,
  title: PropTypes.string,
};
Address.defaultProps = {
  title: 'Address',
};

export default Address;
