import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { postProjectDeadline, deleteProjectDeadline } from '@apis/projects';
import DatePicker from '@hiredigital/ui/Input/Date/Date';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import Deadline from './Deadline';
import Styles from './Styles.module.scss';

class DeadlineList extends Component {
  static propTypes = {
    deadlines: PropTypes.array,
    projectUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      deadlines: [],
      name: '',
      dueDate: null,
      showForm: false,
      emailValid: true,
      submitted: false,
      errors: {},
    };
    this.addDeadlineDebounce = debounce(this.addDeadline, 500);
  }

  componentDidMount = () => {
    this.setState({
      deadlines: this.props.deadlines,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  // handleDateChange = (date, name) => {
  //   this.setState({
  //     [name]: date ? date.format() : null,
  //   });
  // };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleToggleForm = () => {
    this.setState({
      showForm: !this.state.showForm,
      dueDate: new Date(),
      submitted: false,
    });
  };

  handleCreateNew = () => {
    const { name, dueDate } = this.state;
    this.addDeadlineDebounce({ name: name, dueDate: dueDate });
  };

  addDeadline = (data) => {
    const { deadlines } = this.state;
    this.setState({ loading: true, errors: {} });
    postProjectDeadline(this.props.projectUuid, data).then(
      (response) => {
        this.setState({
          loading: false,
          showForm: false,
          name: '',
          dueDate: null,
          deadlines: [...deadlines, response.data],
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  deleteDeadline = (deadline) => {
    // const index = this.state.deadlines.indexOf(deadline);
    this.setState({ loading: true });
    deleteProjectDeadline(this.props.projectUuid, deadline.id).then(
      (response) => {
        // this.state.deadlines.splice(index, 1);
        this.setState({
          deadlines: this.state.deadlines?.filter((v) => v?.id !== deadline?.id) || [],
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <span className={Styles.headline}>{`Deadlines`}</span>
          {!this.state.showForm && (
            <Button
              name='add new'
              type={Button.Type.BLUE}
              onClick={this.handleToggleForm}
              style={{ marginLeft: 'auto' }}>
              {`Add New Deadline`}
            </Button>
          )}
        </Card.Header>

        <div>
          {(this.state.deadlines &&
            this.state.deadlines.length &&
            this.state.deadlines.map((deadline, index) => (
              <Deadline
                deadline={deadline}
                deleteDeadline={this.deleteDeadline}
                key={deadline.id}
                projectUuid={this.props.projectUuid}
              />
            ))) ||
            ''}

          {!this.state.showForm && this.state.deadlines && this.state.deadlines.length === 0 && (
            <Card.Item>No deadlines added</Card.Item>
          )}

          {this.state.showForm && (
            <Card.Item>
              <InputGroup style={{ marginBottom: '15px' }}>
                <InputContainer style={{ marginBottom: '0' }}>
                  <TextInput
                    name='name'
                    label='Add a task or milestone'
                    type='text'
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </InputContainer>
                <InputContainer style={{ marginBottom: '0' }}>
                  {/* <DateSelector
                    className={Styles.dateInput}
                    name='dueDate'
                    label='Due Date'
                    value={this.state.dueDate}
                    onChange={(date) => this.handleDateChange(date, 'dueDate')}
                  /> */}
                  <DatePicker
                    id='listDeadlineDueDate'
                    name='dueDate'
                    label='Due Date'
                    value={this.state.dueDate}
                    onChange={(date) => this.handleDateChange(date, 'dueDate')}
                  />
                </InputContainer>
              </InputGroup>

              <div className={Styles.row}>
                <div className={Styles.action}>
                  <Button
                    name='cancel'
                    type={Button.Type.WHITE_BLUE_OUTLINE}
                    onClick={this.handleToggleForm}>
                    {`Cancel`}
                  </Button>

                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={this.handleCreateNew}
                    isLoading={this.state.loading}>
                    {`Add Deadline`}
                  </Button>
                </div>
              </div>
            </Card.Item>
          )}
        </div>
      </Card>
    );
  }
}

export default DeadlineList;
