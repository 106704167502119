import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getOrgUsers, getOrg, getOrgInvitations, putOrg } from '@apis/orgs';
import PageStyles from '@styles/PageDetail.module.scss';
import AppLayout from '@components/Layout/AppLayout';

import Loader from '@hiredigital/ui/Loader';

import Alert from 'react-bootstrap/Alert';
import { validateUrl } from '@helpers/forms';
import { MemberInvitationStatus, Role } from '@hiredigital/lib/helpers/enum';
import { setPageTitle, addHttpPrefix } from '@hiredigital/lib/helpers/utils';
import { withUser } from '@context/user';
import InvoiceList from '@components/Invoices/List';
import QuoteList from '@components/Quotes/List';
import PrepaymentList from '@components/Prepayments/List';
// import PendingPrepaymentList from '@components/PendingPrepayments/List';
import CreditNoteList from '@components/CreditNotes/List';
import Address from '@components/Address/Address';
import PaymentSettings from '@components/PaymentSettings/PaymentSettings';
import qs from 'qs';

class OrganizationDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    currentUser: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      orgUuid: this.props.match.params.orgUuid,
      name: '',
      picture: '',
      description: '',
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        type: null,
      },
      social: {
        website: '',
        linkedin: '',
        twitter: '',
        facebook: '',
        instagram: '',
      },
      city: '',
      status: null,
      adminSettings: {
        projectReview: false,
        depositRequired: false,
        depositePaid: false,
        depositAmount: null,
        agreementSigned: false,
        agreementVerified: false,
        agreementRequired: false,
        viewEditor: false,
        viewTimesheets: false,
      },
      address: {
        city: '',
      },
      members: [],
      invitations: [],
      websiteValid: true,
      hasInitLoad: false,
      submitted: false,
      currentTab: '',
      hubspotCompany: null,
    };
  }

  componentDidMount = () => {
    const hash = this.props.location && this.props.location.hash;
    this.setState({ currentTab: (hash && hash.substr(1)) || 'profile' });

    this.loadOrganizations().then(
      ([
        { data: profile },
        {
          data: { results: members },
        },
        {
          data: { results: invitations },
        },
      ]) => {
        this.populateFields(profile, () => {
          setPageTitle(`Clients | ${profile.name || 'New Organization'}`);
          this.setState({ members, invitations }, () => {
            this.setState({ hasInitLoad: true });
          });
        });
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.location.hash !== this.props.location.hash ||
      nextProps.currentUser !== this.props.currentUser
    ) {
      return true;
    }

    if (
      nextState.isSaving !== this.state.isSaving ||
      nextState.hasInitLoad !== this.state.hasInitLoad
    ) {
      return true;
    }

    if (
      nextState.contact !== this.state.contact ||
      nextState.status !== this.state.status ||
      nextState.visibility !== this.state.visibility ||
      nextState.type !== this.state.type ||
      // nextState.adminSettings !== this.state.adminSettings ||
      nextState.adminSettings.depositAmount !== this.state.adminSettings.depositAmount ||
      nextState.address !== this.state.address ||
      nextState.orgUuid !== this.state.orgUuid ||
      nextState.countries !== this.state.countries ||
      nextState.languages !== this.state.languages ||
      nextState.hubspotCompany !== this.state.hubspotCompany ||
      nextState.topics !== this.state.topics
    ) {
      return true;
    }
    return false;
  }

  loadOrganizations = () => {
    return Promise.all([
      this.loadOrganizationProfile(),
      this.loadOrganizationMembers(),
      this.loadOrganizationInvitations(),
    ]);
  };

  loadOrganizationProfile = () => getOrg(this.state.orgUuid);

  loadOrganizationMembers = () => getOrgUsers(this.state.orgUuid);

  loadOrganizationInvitations = () => {
    const params = {
      status: [
        MemberInvitationStatus.CREATED.id,
        MemberInvitationStatus.SENT.id,
        MemberInvitationStatus.CANCELLED.id,
        MemberInvitationStatus.RESEND.id,
        MemberInvitationStatus.REJECTED.id,
      ],
    };
    const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });
    return getOrgInvitations(this.state.orgUuid, { params, paramsSerializer });
  };

  populateFields = (data, completionCallback) => {
    this.setState(
      {
        ...data,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };

  hanldeImageUploadPayloadGenerate = (name, file) => {
    const formData = new FormData();
    formData.append(name, file, file.fileName || file.name);
    formData.append('name', this.state.name);
    return formData;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    // From https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
    this.setState({
      [name]: value,
    });
    // this.setState({ user: { ...this.state.user, [name]: value } });
    this.handleAutoSave();
  };

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.handleAutoSave();
  };

  handleEnumChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value.id,
    });
    this.handleImmediateSave();
  };

  handleSocialChange = (event, prefix, removeBaseUrl = true) => {
    const { name, value } = event.target;
    const link =
      (removeBaseUrl
        ? value && value.replace(prefix, '')
        : value && addHttpPrefix(value, prefix)) || '';
    // This only works with shouldUpdate
    this.setState((state) => {
      return {
        ...state,
        social: {
          ...state.social,
          [name]: link,
        },
      };
    });

    // this.setState((state) => ((state.social[name] = link), state));
    this.handleAutoSave();
  };

  handleImmediateSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 0);
  };

  handleImmediateNestedSave = (o) => {
    this.setState(o, this.handleImmediateSave);
  };

  handleAutoSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 2000);
  };

  handleSubmit = (storeResponse = true) => {
    this.setState({ submitted: true });
    const {
      name,
      description,
      contact,
      status,
      visibility,
      type,
      social,
      adminSettings,
      address,
      orgUuid,
      countries,
      languages,
      topics,
      confidentialDescription,
      confidentialName,
      hubspotCompany,
      payment,
      guidelines,
    } = this.state;

    if (social && social.website) {
      this.setState({ websiteValid: validateUrl(social.website) });
    }
    if (contact && contact.user) {
      contact.user = contact.user.uuid;
    }

    if (contact && contact.timezone) {
      contact.timezone = contact.timezone.id;
    }

    if (this.state.websiteValid) {
      this.setState({ isSaving: true });

      const data = {
        countries: countries?.map((obj) => obj.id),
        languages: languages?.map((obj) => obj.id),
        topics: topics.map((obj) => obj.id),
        name,
        description,
        status: status,
        visibility: visibility,
        type: type,
        contact,
        adminSettings,
        social,
        address,
        confidentialDescription,
        confidentialName,
        payment,
        guidelines,
      };

      if (hubspotCompany) data.hubspotCompany = hubspotCompany.id;

      putOrg(orgUuid, data).then(
        (response) => {
          if (storeResponse) {
            this.populateFields(response);
          }
          this.setState({ isSaving: false });
        },
        (error) => {
          this.setState({ isSaving: false });
        }
      );
    }
  };

  addSocialLink(name, value, prefix) {
    const link = value ? addHttpPrefix(value, prefix) : '';
    this.setState((state) => ((state.social[name] = link), state));
  }

  handleAdminPortfolioBoolean = (event) => {
    const { name, checked } = event.target;
    this.setState((state) => ((state.adminSettings[name] = checked), state));
    this.handleAutoSave();
  };

  handleStripeCurrencyChange = (event) => {
    const { value } = event.target;
    this.setState(
      {
        payment: { ...this.state.payment, stripeCurrency: value.id },
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  handleAdminChange = (event) => {
    const { name, value } = event.target;
    // Only this works with nested
    this.setState((state) => {
      return {
        ...state,
        adminSettings: {
          ...state.adminSettings,
          [name]: value,
        },
      };
    });

    // this.setState((state) => ((state.adminSettings[name] = value), state));
    this.handleAutoSave();
  };

  navigateTabLocation = (tab) => {
    this.setState({ currentTab: tab }, () => {
      const { history, location } = this.props;
      history.push(`${location.pathname}#${tab}`);
    });
  };

  render() {
    return (
      <AppLayout
        roles={[Role.FINANCE.id, Role.ADMIN.id]}
        permissions={this.props.currentUser.adminPermission?.finance}
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/clients'>
              {`Clients`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>{this.state.name}</div>
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <PaymentSettings
              orgUuid={this.state.orgUuid}
              data={{
                ...this.state.payment,
              }}
            />

            <Address
              title='Payment Details'
              address={this.state.address}
              payment={this.state.payment}
              onSave={(o) => this.handleImmediateNestedSave(o)}
            />

            {this.state.adminSettings.depositRequired && !this.state.adminSettings.depositPaid && (
              <Alert variant={'warning'}>
                Note that the client will not be able to see any prepayments, or credit notes until
                their deposit has been paid, or the deposit requirement has been removed.
              </Alert>
            )}

            {/* <PendingPrepaymentList orgUuid={this.state.orgUuid} /> */}
            <PrepaymentList orgUuid={this.state.orgUuid} />
            <CreditNoteList orgUuid={this.state.orgUuid} />
            <InvoiceList resourceUuid={this.state.orgUuid} />
            <QuoteList orgUuid={this.state.orgUuid} />
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}
//export default OrganizationDetail;
export default withUser(OrganizationDetail);
