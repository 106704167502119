import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { deleteProjectMember } from '@apis/projects';

import Item from './Item';
import Styles from './Styles.module.scss';

const MemberList = ({ projectUuid, orgUuid, members: initialMembers }) => {
  const [members, setMembers] = useState(initialMembers);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setMembers(initialMembers);
  }, [initialMembers]);

  const handleCreateNew = () => {
    const hasPending = members.some((v) => v?.isAdd);
    if (!hasPending) {
      setMembers([{ isAdd: true }, ...members]);
    }
  };

  const handleDelete = (member) => {
    setIsDeleting(true);
    deleteProjectMember(projectUuid, member.id).then(
      (response) => {
        const newMembers = members.filter((v) => v?.id !== member?.id);
        setMembers(newMembers);
        setIsDeleting(false);
      },
      (error) => {
        console.error(error);
        setIsDeleting(false);
      }
    );
  };

  const handleCancel = () => {
    setMembers(members.filter((v) => v?.id));
  };

  const handleCreate = (member) => {
    setMembers(members.map((v) => (v?.isAdd ? member : v)));
  };

  const handleUpdate = (member) => {
    setMembers(members.map((v) => (v?.id === member?.id ? member : v)));
  };

  return (
    <Card>
      <Card.Header>
        <span className={Styles.headline}>{`Project Members`}</span>

        {orgUuid && (
          <Button
            name='submit'
            type={Button.Type.BLUE}
            onClick={handleCreateNew}
            style={{ marginLeft: 'auto' }}>
            {`Add Member`}
          </Button>
        )}
      </Card.Header>
      <Fragment>
        {members?.map((member) => (
          <Item
            projectUuid={projectUuid}
            orgUuid={orgUuid}
            member={member}
            onDelete={handleDelete}
            onCancel={handleCancel}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            isDeleting={isDeleting}
            key={member?.id}
          />
        ))}

        {members?.length === 0 && (
          <Card.Item>
            <div className={Styles.empty}>{`No members added`}</div>
          </Card.Item>
        )}
      </Fragment>
    </Card>
  );
};

MemberList.propTypes = {
  members: PropTypes.array,
  projectUuid: PropTypes.string,
  orgUuid: PropTypes.string,
};
export default MemberList;
