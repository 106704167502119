import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Styles from './ScreenshotViewer.module.scss';

import Loader from '@hiredigital/ui/Loader';
// import Icon from '@hiredigital/ui/Icon/Icon';

import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import classNames from 'classnames';
import { getEventScreenshots } from '@apis/tracking';
import { Portal } from 'evergreen-ui';
import { toReadableDateTime } from '@hiredigital/lib/helpers/date';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

const ArrowNavLeft = (props) => (
  <div className={Styles.arrowNavContainer} {...props}>
    <IconDown className={classNames(Styles.arrowNav, Styles.arrowLeft)} />
  </div>
);

const ArrowNavRight = (props) => (
  <div className={Styles.arrowNavContainer} {...props}>
    <IconDown className={classNames(Styles.arrowNav, Styles.arrowRight)} />
  </div>
);

const ScreenshotViewer = ({
  defaultActiveIndex,
  className,
  screenshots: initialScreenshots,
  meta: initialMeta,
  eventUuid,
  user,
  ...props
}) => {
  const infiniteLoaderRef = useRef(null);

  const [screenshots, setScreenshots] = useState(initialScreenshots);
  const [meta, setMeta] = useState(initialMeta);
  const [screenshotIndex, setScreenshotIndex] = useState(defaultActiveIndex);
  const [activeScreenshot, setActiveScreenshot] = useState(screenshots?.[defaultActiveIndex]);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageRendering, setIsImageRendering] = useState(true);
  const isItemLoaded = () => false;

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const downHandler = (e) => {
    if (e.keyCode === 37 || e.code == 'ArrowLeft') {
      keyDownOffset(-1);
    } else if (e.keyCode === 39 || e.code == 'ArrowRight') {
      keyDownOffset(1);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [screenshotIndex]); // Empty array ensures that effect is only run on mount and unmount

  const loadMoreScreenshots = async (startIndex, stopIndex) => {
    if (stopIndex + 10 >= screenshots.length && !isLoading && meta.nextPage) {
      setIsLoading(true);
      const params = {
        page: meta.nextPage,
        ordering: 'time',
      };
      try {
        const { data } = await getEventScreenshots(eventUuid, { params });
        const list = [...screenshots, ...data.results];
        setScreenshots(list);
        setMeta(data.meta);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    }
  };

  const keyDownOffset = (offset) => {
    changeActive(screenshotIndex + offset);
  };

  const changeActive = (index) => {
    if (screenshots?.[index]) {
      setScreenshotIndex(index);
      setActiveScreenshot(screenshots?.[index]);
      setIsImageRendering(true);
      infiniteLoaderRef?.current?._listRef?.scrollToItem(index);
    }
  };

  const handleImageLoad = (e) => {
    setTimeout(() => setIsImageRendering(false), 500);
  };

  return (
    <Portal>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div onClick={props?.onClose} className={Styles.close}>
            <IconClose className={Styles.closeIcon} />
          </div>
        </div>
        <div className={Styles.body}>
          <div className={Styles.row}>
            <ArrowNavLeft onClick={() => changeActive(screenshotIndex - 1)} />
            <img
              className={Styles.activeImage}
              src={activeScreenshot.originalImage}
              alt='Original Screenshot'
              onLoad={handleImageLoad}
            />
            <div
              className={classNames(
                Styles.activeImageLoaderContainer,
                isImageRendering && Styles.isVisible
              )}>
              <div className={Styles.activeImageLoader}>
                <Loader />
              </div>
            </div>
            <ArrowNavRight onClick={() => changeActive(screenshotIndex + 1)} />
          </div>
          <div className={Styles.descriptionRow}>
            <div className={Styles.user}>
              <img className={Styles.picture} src={user.picture} />
              <span className={Styles.name}>{user.name}</span>
            </div>

            {(activeScreenshot.time && (
              <div className={Styles.time}>{toReadableDateTime(activeScreenshot.time)}</div>
            )) ||
              ''}
            {(activeScreenshot.totalSeconds && (
              <div className={Styles.time}>
                {`${(
                  (activeScreenshot.activeKeyboardMouseSeconds / activeScreenshot.totalSeconds) *
                  100
                ).toPrecision(3)}% Active `}
              </div>
            )) || <div className={Styles.activity}>No Activity</div>}
            {(activeScreenshot.isBlurred && <div className={Styles.activity}>{`Blurred`}</div>) ||
              ''}
            {(activeScreenshot.isDeleted && <div className={Styles.activity}>{`Deleted`}</div>) ||
              ''}
          </div>
          <div className={Styles.carousell}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isItemLoaded}
              itemCount={meta.count}
              loadMoreItems={loadMoreScreenshots}>
              {({ onItemsRendered, ref }) => (
                <List
                  className={Styles.previewList}
                  layout='horizontal'
                  height={120}
                  width={1600}
                  itemCount={meta.count}
                  itemSize={160}
                  onItemsRendered={onItemsRendered}
                  ref={ref}>
                  {({ index, style }) => {
                    return (
                      <div
                        className={classNames(
                          Styles.carousellImageContainer,
                          index === screenshotIndex && Styles.isActive
                        )}
                        style={{
                          ...style,
                        }}>
                        <img
                          className={Styles.carousellImage}
                          onClick={() => changeActive(index)}
                          src={screenshots?.[index]?.preview}
                          alt='test'
                        />
                      </div>
                    );
                  }}
                </List>
              )}
            </InfiniteLoader>
          </div>
        </div>
      </div>
    </Portal>
  );
};

ScreenshotViewer.propTypes = {
  className: PropTypes.string,
  defaultActiveIndex: PropTypes.number,
  screenshots: PropTypes.array,
  meta: PropTypes.object,
  eventUuid: PropTypes.string,
  user: PropTypes.object,
};

export default ScreenshotViewer;
