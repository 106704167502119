import { useMemo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button/Button';

// import { Position, SelectMenu } from 'evergreen-ui';
import SelectMenu from '@hiredigital/ui/SelectMenu';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import AssignmentForm from './AssignmentForm';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import { getOrgAssignments, putOrgAssignments } from '@apis/orgs';
import { AssignmentStatus, RateType } from '@hiredigital/lib/helpers/enum';
import { copyTextToClipboard } from '@hiredigital/lib/helpers/utils';
import Styles from './UserAssignments.module.scss';
import isAfter from 'date-fns/isAfter';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

const EngagementColumn = ({
  row,
  onEdit,
  onView,
  onViewAssignment,
  onCopyTalent,
  onCopyClient,
}) => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <button className={Styles.link} onClick={() => onEdit?.(row)}>
        {row?.original?.title ||
          row?.original?.engagement?.title ||
          row?.original?.engagement?.talentType?.label ||
          row?.original?.engagement?.otherTalentType ||
          'Assignment'}
      </button>
      <div style={{ marginLeft: 'auto' }}>
        <SplitButton
          more={[
            { text: 'Edit', onClick: () => onEdit?.(row) },
            { text: 'View Assignment in Admin', onClick: () => onViewAssignment?.(row) },
            { text: 'Copy Talent Link', onClick: () => onCopyTalent?.(row) },
          ]}
        />
      </div>
    </div>
  );
};

const TextRight = ({ value }) => <div style={{ textAlign: 'right', width: '100%' }}>{value}</div>;

const List = ({ orgUuid, currentUser }) => {
  const [status, setStatus] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [search, setSearch] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [updateRow, setUpdateRow] = useState(undefined);
  const [errors, setErrors] = useState({});
  const [talentEngagementRate, setTalentEngagementRate] = useState();
  const [clientEngagementRate, setClientEngagementRate] = useState();
  let timeout = null;

  const handleEdit = (item) => {
    setSelected(item.original);
    setTalentEngagementRate(item.original?.talentEngagementRate);
    setClientEngagementRate(item.original?.clientEngagementRate);
    setIsShown(true);
  };

  // const handleCopyClient = (a) => {
  //   copyTextToClipboard(
  //     `${process.env.APP_ENDPOINT}/briefs/${a?.original?.engagement?.brief?.uuid}`
  //   );
  // };

  const handleCopyTalent = (a) => {
    copyTextToClipboard(`${process.env.APP_ENDPOINT}/engagements/${a?.original?.engagement?.uuid}`);
  };

  // const handleViewBrief = (a) =>
  //   window.open(`/briefs/${a?.original?.engagement?.brief?.uuid}`, '_blank');

  const handleViewAssignment = (a) => {
    window.open(`/assignments/${a?.original?.uuid}`, '_blank');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Engagement',
        canSort: true,
        minWidth: 300,
        id: 'engagement__title',
        truncate: false,
        Cell: ({ row }) => (
          <EngagementColumn
            onEdit={handleEdit}
            // onView={handleViewBrief}
            onViewAssignment={handleViewAssignment}
            onCopyTalent={handleCopyTalent}
            // onCopyClient={handleCopyClient}
            row={row}
          />
        ),
      },
      {
        Header: 'Talent',
        canSort: true,
        minWidth: 200,
        id: 'talent__name',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', overflow: 'hidden' }}>
            {/* <img className={Styles.picture} src={row?.original?.organization?.picture} alt='' /> */}
            {row?.original?.user?.name}
          </div>
        ),
      },

      {
        Header: () => <TextRight value={`Talent Rate`} />,
        id: 'talent_rate',
        canSort: true,
        width: 100,
        Cell: ({ row }) =>
          isNewRates ? (
            <TextRight value={`${row?.original?.talentEngagementRate?.rateDisplayText || ''}`} />
          ) : (
            <TextRight
              value={
                row?.original?.talentRate
                  ? `${row?.original?.talentRate} (${RateType.getLabel(
                      row?.original?.talentRateType
                    )})`
                  : ''
              }
            />
          ),
      },
      {
        Header: () => <TextRight value={`Client Rate`} />,
        id: 'brand_rate',
        canSort: true,
        width: 100,
        Cell: ({ row }) =>
          isNewRates ? (
            <TextRight value={`${row?.original?.clientEngagementRate?.rateDisplayText || ''}`} />
          ) : (
            <TextRight
              value={
                row?.original?.brandRate
                  ? `${row?.original?.brandRate} (${RateType.getLabel(
                      row?.original?.brandRateType
                    )})`
                  : ''
              }
            />
          ),
      },
      {
        Header: 'Status',
        canSort: true,
        id: 'status',
        width: 100,
        accessor: (a) => AssignmentStatus.getEnum(a?.status).label,
      },
    ],
    []
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleStatusSelect = (e) => setStatus(e.target.value);

  // const handleStatusDeselect = (v) => setStatus({});

  const filterMemo = useMemo(
    () => ({
      status: status?.id,
    }),
    [status]
  );

  const handleFormChange = ({ name, value }) => {
    const data = { ...selected, [name]: value };
    setSelected(data);
    setErrors({});
  };

  const handleSave = () => {
    if (!validateFields()) return;

    const {
      uuid,
      user,
      team,
      brandRate,
      startDate,
      endDate,
      brandRateType,
      talentRate,
      talentRateType,
      status,
      title,
      engagement,
      application,
    } = selected;

    const data = {
      uuid,
      user: user?.uuid,
      team: team?.uuid,
      startDate,
      endDate,
      brandRate,
      brandRateType,
      talentRate,
      talentRateType,
      status,
      title,
      engagement: engagement?.uuid,
      application: application?.uuid,
      talentEngagementRate: talentEngagementRate?.uuid || null,
      clientEngagementRate: clientEngagementRate?.uuid || null,
    };

    setIsSaving(true);
    putOrgAssignments(orgUuid, uuid, data)
      .then(({ data }) => {
        setIsSaving(false);
        setUpdateRow(data);
        setIsShown(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  const validateFields = () => {
    let errors = {};
    const { startDate, endDate } = selected;
    console.log('startDate', startDate);
    console.log('endDate', endDate);

    if (startDate && endDate && isAfter(new Date(startDate), new Date(endDate))) {
      errors = { ...errors, startDate: 'Start Date should not be later than End Date.' };
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      <Card>
        <Card.Header>
          <div className={Styles.sectionTitle}>{`Engagement Assignments`}</div>
          <input
            className={Styles.searchField}
            name='search'
            type='text'
            placeholder='Search Assignments'
            onChange={handleSearchChange}
          />
          <div>
            {/* <MemoSelectMenu
              title='Filter by Status'
              hasFilter={true}
              height={175}
              position={Position.BOTTOM_RIGHT}
              options={[...AssignmentStatus.values].map((s) => ({
                label: s.label,
                value: String(s.id),
              }))}
              isMultiSelect={true}
              selected={String(status?.value)}
              onSelect={handleStatusSelect}
              onDeselect={handleStatusDeselect}>
              <button className={Styles.filter}>
                {status?.label || `Filter by Status`}
                <div className={Styles.arrowDown} />
              </button>
            </MemoSelectMenu> */}
            <SelectMenu
              placeholder='Filter by Status'
              onChange={handleStatusSelect}
              options={AssignmentStatus.values}
              isClearable
            />
          </div>
        </Card.Header>
        <Card.Item noPadding>
          <PaginatedTable
            columns={columns}
            updateRow={updateRow}
            rowAccessor='uuid'
            getListData={(config) => getOrgAssignments(orgUuid, config)}
            search={search}
            filters={filterMemo}
            tableClass={Styles.table}
            headerCellClass={Styles.headerCell}
            cellClass={Styles.cell}
            emptyContainerClass={Styles.emptyContainer}
            paginationClass={Styles.pagination}
            defaultLimit={5}
            defaultPaginationOptions={[5, 20, 50, 100]}
            showParams={false}
          />
        </Card.Item>
      </Card>
      <Dialog
        isOpen={isShown}
        title={`Edit Assignment`}
        onClose={() => setIsShown(false)}
        width={1080}
        className={Styles.dialogPanel}
        containerProps={{ overflow: 'auto' }}>
        <AssignmentForm
          data={selected}
          orgUuid={orgUuid}
          onFormChange={handleFormChange}
          currentUser={currentUser}
          errors={errors}
          hideBgColorArea
          talentEngagementRate={[talentEngagementRate, setTalentEngagementRate]}
          clientEngagementRate={[clientEngagementRate, setClientEngagementRate]}
          resourceUuid={selected?.uuid}
        />
        <Button onClick={handleSave} type={Button.Type.BLUE} isLoading={isSaving}>
          {`Save`}
        </Button>
        <Button onClick={() => setIsShown(false)} type={Button.Type.LIGHT_GRAY}>
          {`Cancel`}
        </Button>
      </Dialog>
    </Fragment>
  );
};

List.propTypes = {
  orgUuid: PropTypes.string,
};

export default List;
