import { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/webpack-resolver';

import 'brace/ext/language_tools';
import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

import Toggle from 'react-toggle';

import AppLayout from '@components/Layout/AppLayout';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';

import { EmailTemplateBase } from '@hiredigital/lib/helpers/enum';
import {
  getEmailTemplate,
  putEmailTemplate,
  postEmailTemplatePreview,
  postEmailTemplateRefresh,
} from '@apis/emails';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

class EmailTemplateDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      templateId: this.props.match.params.templateId,
      autoSaveTimeout: null,
      isSaving: false,
      refreshIsSaving: false,
      hasInitLoad: false,
      title: null,
      category: null,
      testData: '',
      isGeneratingPreview: false,
    };
  }

  componentDidMount = () => {
    Promise.all([this.loadTemplate()]).then(
      (values) => {
        this.setState({
          isLoaded: true,
        });
        this.handleResponse(values[0]);
        setPageTitle(
          `Email Templates | ${(values[0].data && values[0].data.name) || 'New Template'}`
        );
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      },
      (error) => {
        this.setState({ isLoaded: true });
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) {
      return true;
    }
    if (
      nextState.templateId !== this.state.templateId ||
      nextState.autoSaveTimeout !== this.state.autoSaveTimeout ||
      nextState.isSaving !== this.state.isSaving ||
      nextState.refreshIsSaving !== this.state.refreshIsSaving ||
      nextState.isGeneratingPreview !== this.state.isGeneratingPreview ||
      nextState.hasInitLoad !== this.state.hasInitLoad
    ) {
      return true;
    }
    return false;
  }

  loadTemplate = () => getEmailTemplate(this.state.templateId);

  handleChange = (event) => {
    const { name, value } = event.target;
    // From https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
    this.setState({
      [name]: value,
    });
    // this.handleAutoSave();
  };

  handleArchiveChange = (event) => {
    const { name, value, checked } = event.target;
    this.setState({
      [name]: checked,
    });
    this.handleAutoSave();
  };

  handleEnumChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value.id,
    });
    this.handleAutoSave();
  };

  handleSubjectChange = (value) => {
    this.setState({
      subject: value,
    });
    // this.handleAutoSave();
  };

  handlePreviewChange = (value) => {
    this.setState({
      preheader: value,
    });
    // this.handleAutoSave();
  };

  handleContentChange = (value) => {
    this.setState({
      content: value,
    });
    // this.handleAutoSave();
  };

  handleHTMLChange = (value) => {
    this.setState({
      htmlContent: value,
    });
    // this.handleAutoSave();
  };

  handleTestDataChange = (value) => {
    this.setState({
      testData: value,
    });
    // this.handleAutoSave();
  };

  handleResponse = (response) => {
    if (response) {
      this.setState(
        {
          ...response.data,
          testData: JSON.stringify(response.data?.testData),
        },
        () => {
          this.setState({ hasInitLoad: true });
        }
      );
    }
  };

  handleUploadResponse = ({ data }) => {
    const portfolio = {
      ...this.state.portfolio,
      resume: data.portfolio.resume,
    };
    this.setState({ portfolio });
  };

  handleImmediateSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit();
      }, 0),
    });
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 6000),
    });
  };

  handleSubmit = (storeResponse = true, completionCallback) => {
    // We do this to ignore this skills for now, when we move all to portoflio read/write then we can leave
    const data = {
      ...this.state,
      base: this.state.base,
      ...(this.state.testData && { testData: JSON.parse(this.state.testData) }),
    };

    putEmailTemplate(this.state.templateId, data).then(
      (response) => {
        completionCallback?.();
        if (storeResponse) {
          this.handleResponse(response);
        }
        this.setisSaving();
      },
      (error) => {
        this.setState({ isSaving: false, autoSaveTimeout: null });
      }
    );
  };

  setisSaving = () => {
    this.setState({ isSaving: true, autoSaveTimeout: null });
    setTimeout(() => {
      this.setState({ isSaving: false });
    }, 2000);
  };

  handleRefresh = () => {
    this.setState({ refreshIsSaving: true });
    postEmailTemplateRefresh(this.state.templateId, {}).then(
      (response) => {
        this.setState({ refreshIsSaving: false });
      },
      (error) => {
        this.setState({ refreshIsSaving: false });
      }
    );
  };

  handleGeneratePreview = () => {
    this.handleSubmit(true, this.generatePreview);
  };

  generatePreview = () => {
    this.setState({ isGeneratingPreview: true });
    postEmailTemplatePreview(this.state.templateId, {}).then(
      ({ data }) => {
        this.setState({
          isGeneratingPreview: false,
          generatedPreview: data.generatedPreview,
        });
      },
      (error) => {
        this.setState({ isGeneratingPreview: false });
      }
    );
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/email-templates'>
              {`Email Templates`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>{this.state.name}</div>
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <div className={PageStyles.leftRightContainer}>
              <div className={PageStyles.leftFields}>
                <InputContainer>
                  <TextInput
                    name='title'
                    label='Title'
                    defaultValue={this.state.title}
                    onChange={this.handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <TextArea
                    name='description'
                    label='Description'
                    placeholder=''
                    defaultValue={this.state.description}
                    onChange={this.handleChange}
                  />
                </InputContainer>
              </div>
              <div className={PageStyles.rightFields}>
                <InputContainer>
                  <TextInput
                    name='category'
                    label='Category'
                    defaultValue={this.state.category}
                    onChange={this.handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <TextInput
                    name='name'
                    label='Template Name'
                    defaultValue={this.state.name}
                    onChange={this.handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <Select
                    data-test-id='base'
                    className={PageStyles.select}
                    defaultValue={EmailTemplateBase.getEnum(this.state.base)}
                    classNamePrefix='s-contact'
                    name='base'
                    label='Base'
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={EmailTemplateBase.values}
                    onChange={this.handleEnumChange}
                  />
                </InputContainer>
              </div>
            </div>

            <div>
              <InputGroup></InputGroup>
              <InputContainer>
                <TextInput
                  name='subject'
                  label='Email Subject'
                  defaultValue={this.state.subject}
                  onChange={this.handleChange}
                />

                {/*<label className={Styles.label}>Subject</label>
                <AceEditor
                  placeholder='Subject'
                  mode='handlebars'
                  theme='monokai'
                  name='Subject'
                  wrapEnabled={true}
                  onLoad={this.onLoad}
                  onChange={this.handleSubjectChange}
                  fontSize={14}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  width='100%'
                  height='75px'
                  value={this.state.subject}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                />*/}
              </InputContainer>

              <InputContainer>
                <label className={Styles.label}>Preview</label>
                <AceEditor
                  placeholder='Preview'
                  mode='handlebars'
                  theme='monokai'
                  name='Preview'
                  wrapEnabled={true}
                  onLoad={this.onLoad}
                  onChange={this.handlePreviewChange}
                  fontSize={14}
                  showGutter={true}
                  width='100%'
                  height='70px'
                  value={this.state.preheader}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: false,
                    tabSize: 2,
                  }}
                />
              </InputContainer>

              <InputContainer>
                <label className={Styles.label}>Plaintext Content</label>

                <AceEditor
                  placeholder='Content'
                  mode='handlebars'
                  theme='monokai'
                  name='Template'
                  onLoad={this.onLoad}
                  onChange={this.handleContentChange}
                  fontSize={14}
                  wrapEnabled={true}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  width='100%'
                  height='250px'
                  value={this.state.content}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                />
              </InputContainer>

              <InputContainer>
                <label className={Styles.label}>HTML Content</label>
                <AceEditor
                  placeholder='HTML Content'
                  mode='html'
                  theme='monokai'
                  name='HTML Template'
                  onLoad={this.onLoad}
                  onChange={this.handleHTMLChange}
                  fontSize={14}
                  wrapEnabled={true}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  width='100%'
                  value={this.state.htmlContent}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                />
              </InputContainer>
              <InputGroup>
                <InputContainer style={{ flexBasis: '33%' }}>
                  <label className={Styles.label}>Test Data</label>
                  <AceEditor
                    placeholder='Test Data'
                    mode='json'
                    theme='monokai'
                    name='Test Data'
                    fontSize={14}
                    wrapEnabled={true}
                    showGutter={false}
                    width='100%'
                    onChange={this.handleTestDataChange}
                    value={this.state.testData}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <label className={Styles.label}>{`Generated Preview`}</label>
                  <div className={Styles.previewContainer}>
                    {this.state.generatedPreview && (
                      <iframe
                        src={`data:text/html, ${encodeURIComponent(this.state.generatedPreview)}`}
                        className={Styles.generatedPreview}
                        style={{ marginBottom: '-5px' }}
                      />
                    )}
                  </div>
                </InputContainer>
              </InputGroup>

              {/* <InputGroup>
                    <InputContainer>
                      <label className={Styles.label}>{`Message`}</label>
                      <div className={Styles.codeEditorContainer} style={{ minHeight: '300px' }}>
                        <AceEditor
                          placeholder=''
                          mode='handlebars'
                          theme='github'
                          name='message'
                          wrapEnabled={true}
                          onChange={(value) => {
                            this.handleEditorMessageChange('message', value);
                          }}
                          fontSize={14}
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          width='100%'
                          height='300px'
                          value={this.state.message}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                          }}
                        />
                      </InputContainer>
                    </div>
                  </InputGroup> */}

              <div
                className={classNames(PageStyles.formContainer, PageStyles.stickyRow)}
                style={{ zIndex: '100' }}>
                <div className={Styles.saveRow}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <label
                      htmlFor={`archived`}
                      className={Styles.toggleLabel}
                      style={{ marginRight: '10px' }}>{`Archived`}</label>
                    <Toggle
                      id={`archived`}
                      name='archived'
                      defaultChecked={this.state.archived}
                      onChange={this.handleArchiveChange}
                    />
                  </div>

                  <Button
                    name='submit'
                    className={Styles.saveButton}
                    type={Button.Type.BLUE_OUTLINE}
                    onClick={this.handleGeneratePreview}
                    isLoading={this.state.isGeneratingPreview}>
                    {`Generate Preview`}
                  </Button>

                  <Button
                    name='submit'
                    className={Styles.saveButton}
                    type={Button.Type.BLUE_OUTLINE}
                    onClick={this.handleRefresh}
                    isLoading={this.state.refreshIsSaving}>
                    {`Update Live Template`}
                  </Button>

                  <Button
                    name='submit'
                    className={Styles.saveButton}
                    type={Button.Type.BLUE}
                    onClick={this.handleImmediateSave}
                    isLoading={this.state.isSaving}>
                    {`Save Template`}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default EmailTemplateDetail;
