import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  putProjectDeadline,
  postProjectDeadlineActive,
  postProjectDeadlineComplete,
} from '@apis/projects';

import { ProjectDeadlineStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import TextInput from '@hiredigital/ui/Input/TextInput';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import DatePicker from '@hiredigital/ui/Input/Date/Date';
import Styles from './Styles.module.scss';

class Item extends Component {
  static propTypes = {
    deadline: PropTypes.object,
    deleteDeadline: PropTypes.func,
    projectUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.deadline,
      isEdit: false,
    };
  }

  handleEdit = () => {
    this.setState({ isEdit: true });
  };

  handleCancel = () => {
    this.setState({
      ...this.props.deadline,
      isEdit: false,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  // handleDateChange = (date, name) => {
  //   this.setState({
  //     [name]: date ? date.format() : null,
  //   });
  // };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleSubmit = () => {
    const { name, dueDate } = this.state;
    const data = {
      name,
      dueDate,
    };
    putProjectDeadline(this.props.projectUuid, this.props.deadline.id, data).then(
      (response) => {
        this.setState({ isEdit: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleComplete = () => {
    postProjectDeadlineComplete(this.props.projectUuid, this.props.deadline.id).then((response) => {
      this.setState({ status: ProjectDeadlineStatus.COMPLETE.id });
    });
  };

  handleActive = () => {
    postProjectDeadlineActive(this.props.projectUuid, this.props.deadline.id).then((response) => {
      this.setState({ status: ProjectDeadlineStatus.ACTIVE.id });
    });
  };

  render() {
    return (
      <Card.Item>
        {this.state.isEdit && (
          <Fragment>
            <InputGroup style={{ marginBottom: '15px' }}>
              <InputContainer style={{ marginBottom: '0' }}>
                <TextInput
                  name='name'
                  label='Add a task or milestone'
                  type='text'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </InputContainer>

              <InputContainer style={{ marginBottom: '0' }}>
                {/* <DateSelector
                  className={Styles.dateInput}
                  name='dueDate'
                  label='Due Date'
                  value={this.state.dueDate}
                  onChange={(date) => this.handleDateChange(date, 'dueDate')}
                /> */}
                <DatePicker
                  id='deadlineDueDate'
                  name='dueDate'
                  label='Due Date'
                  value={this.state.dueDate}
                  onChange={(date) => this.handleDateChange(date, 'dueDate')}
                />
              </InputContainer>
            </InputGroup>

            <div className={Styles.row}>
              <Button
                name='delete'
                type={Button.Type.WHITE}
                onClick={(e) => this.props.deleteDeadline(this.props.deadline)}>
                {`Delete`}
              </Button>

              <div className={Styles.action}>
                <Button
                  name='cancel'
                  type={Button.Type.WHITE_BLUE_OUTLINE}
                  onClick={this.handleCancel}>
                  {`Cancel`}
                </Button>

                <Button name='submit' type={Button.Type.BLUE} onClick={this.handleSubmit}>
                  {`Save`}
                </Button>
              </div>
            </div>
          </Fragment>
        )}

        {!this.state.isEdit && (
          <div className={Styles.row}>
            <div style={{ overflow: 'hidden' }}>
              <p className={Styles.name}>{this.state.name || 'Untitled Deadline'}</p>
            </div>

            <div className={Styles.action}>
              {this.state.dueDate && (
                <p className={Styles.info}>{toReadableDate(this.state.dueDate)}</p>
              )}

              {this.state.status === ProjectDeadlineStatus.ACTIVE.id && (
                <Button
                  name='Complete'
                  className={Styles.statusButton}
                  type={Button.Type.BLUE}
                  onClick={this.handleComplete}>
                  {`Complete`}
                </Button>
              )}

              {this.state.status === ProjectDeadlineStatus.COMPLETE.id && (
                <Button
                  name='Active'
                  className={Styles.statusButton}
                  type={Button.Type.BLUE}
                  onClick={this.handleActive}>
                  {`Active`}
                </Button>
              )}

              <Button
                name='Edit'
                className={Styles.editButton}
                type={Button.Type.BLUE_OUTLINE}
                onClick={this.handleEdit}>
                {`Edit`}
              </Button>
            </div>
          </div>
        )}
      </Card.Item>
    );
  }
}

export default Item;
