import { authRequest } from './utils';

export const onBriefLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-briefs/`;
  return loadOptions(url, data);
};

export const onTimesheetsLoadOptions = (userUuid, search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-timesheets/?user=${userUuid}`;
  return loadOptions(url, data);
};

export const onOrgsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/orgs/`;
  return loadOptions(url, data);
};

export const onAllOrgsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/orgs/`;
  return loadOptions(url, data);
};

export const onOrgLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-orgs/`;
  return loadOptions(url, data);
};

export const onAssignmentsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-assignments/`;
  return loadOptions(url, data);
};

export const onProjectsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-projects/`;
  return loadOptions(url, data);
};

export const onAllUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-all-users/`;
  return loadOptions(url, data);
};

export const onClientUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-client-users/`;
  return loadOptions(url, data);
};

export const onStartDatesLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `start-dates/`;
  return loadOptions(url, data);
};

export const onUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-talent-users/`;
  return loadOptions(url, data);
};

export const onTimetrackerUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-timetracker-users/`;
  return loadOptions(url, data);
};

export const onAssignmentUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-assignment-users/`;
  return loadOptions(url, data);
};

export const onOrgRelatedUsersLoadOptions = (orgUuid, search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-org-related-users/?organization=${orgUuid}`;
  return loadOptions(url, data);
};

export const onInvitationTalentUsersLoadOptions = (
  egagementUuid,
  search,
  loadedOptions,
  { page }
) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-talent-users/?type=invitation&engagement=${egagementUuid}`;
  return loadOptions(url, data);
};

export const onAssignmentTalentUsersLoadOptions = (
  egagementUuid,
  search,
  loadedOptions,
  { page }
) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-talent-users/?type=assignment&engagement=${egagementUuid}`;
  return loadOptions(url, data);
};

export const onApplicationTalentUsersLoadOptions = (
  egagementUuid,
  search,
  loadedOptions,
  { page }
) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-talent-users/?type=application&engagement=${egagementUuid}`;
  return loadOptions(url, data);
};

export const onApplicationLoadOptions = (search, loadedOptions, { page }, orgUuid) => {
  const data = { params: { search, page, limit: 20, organization: orgUuid } };
  const url = `admin/dropdown-applications/`;
  return loadOptions(url, data);
};

export const onTeamsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-teams/`;
  return loadOptions(url, data);
};

export const onSkillsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/skills/`;
  return loadOptions(url, data);
};

export const onPublicationsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `publications/`;
  return loadOptions(url, data);
};

export const onTalentTypesLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `talent-types/`;
  return loadOptions(url, data);
};

export const onFormatLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `formats/`;
  return loadOptions(url, data);
};

export const onEngagementLoadOptions = (search, loadedOptions, { page }, orgUuid) => {
  const data = { params: { search, page, limit: 20, organization: orgUuid } };
  const url = `admin/dropdown-engagements/`;
  return loadOptions(url, data);
};

export const onHubspotDealsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `hubspot-deals/`;
  return loadOptions(url, data);
};

export const onRatingTypesLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-rating-types/`;
  return loadOptions(url, data);
};

export const onStrongAttributesLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-strong-attributes/`;
  return loadOptions(url, data);
};

export const onWeakAttributesLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-weak-attributes/`;
  return loadOptions(url, data);
};

export const onPaymentLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-org-payments`;
  return loadOptions(url, data);
};

export const onOrganizationLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/orgs/`;
  return loadOptions(url, data);
};

export const onContactsLoadOptions = async (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-payment-bill-contacts/`;
  const filter = (item) => !!(item && item.user && item.user.name);
  return loadOptions(url, data, filter);
};

export const onTeamLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/teams/`;
  return loadOptions(url, data);
};

export const onHubspotCompaniesLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/hubspot-companies/`;
  return loadOptions(url, data);
};

const loadOptions = async (url, data, filter) => {
  const {
    data: { results, meta },
  } = await authRequest.get(url, data);
  return {
    options: filter ? results.filter(filter) : results,
    hasMore: meta.nextPage !== null,
    additional: {
      page: meta.nextPage,
    },
  };
};
