export const countryList = [
  {
    id: 2,
    name: 'Afghanistan',
    cca2: 'AF',
    dialCode: '+93',
  },
  {
    id: 5,
    name: 'Åland Islands',
    cca2: 'AX',
    dialCode: '+358',
  },
  {
    id: 6,
    name: 'Albania',
    cca2: 'AL',
    dialCode: '+355',
  },
  {
    id: 64,
    name: 'Algeria',
    cca2: 'DZ',
    dialCode: '+213',
  },
  {
    id: 11,
    name: 'American Samoa',
    cca2: 'AS',
    dialCode: '+1684',
  },
  {
    id: 7,
    name: 'Andorra',
    cca2: 'AD',
    dialCode: '+376',
  },
  {
    id: 3,
    name: 'Angola',
    cca2: 'AO',
    dialCode: '+244',
  },
  {
    id: 4,
    name: 'Anguilla',
    cca2: 'AI',
    dialCode: '+1264',
  },
  {
    id: 12,
    name: 'Antarctica',
    cca2: 'AQ',
    dialCode: '+672',
  },
  {
    id: 14,
    name: 'Antigua and Barbuda',
    cca2: 'AG',
    dialCode: '+1268',
  },
  {
    id: 9,
    name: 'Argentina',
    cca2: 'AR',
    dialCode: '+54',
  },
  {
    id: 10,
    name: 'Armenia',
    cca2: 'AM',
    dialCode: '+374',
  },
  {
    id: 1,
    name: 'Aruba',
    cca2: 'AW',
    dialCode: '+297',
  },
  {
    id: 15,
    name: 'Australia',
    cca2: 'AU',
    dialCode: '+61',
  },
  {
    id: 16,
    name: 'Austria',
    cca2: 'AT',
    dialCode: '+43',
  },
  {
    id: 17,
    name: 'Azerbaijan',
    cca2: 'AZ',
    dialCode: '+994',
  },
  {
    id: 25,
    name: 'Bahamas',
    cca2: 'BS',
    dialCode: '+1242',
  },
  {
    id: 24,
    name: 'Bahrain',
    cca2: 'BH',
    dialCode: '+973',
  },
  {
    id: 22,
    name: 'Bangladesh',
    cca2: 'BD',
    dialCode: '+880',
  },
  {
    id: 33,
    name: 'Barbados',
    cca2: 'BB',
    dialCode: '+1246',
  },
  {
    id: 28,
    name: 'Belarus',
    cca2: 'BY',
    dialCode: '+375',
  },
  {
    id: 19,
    name: 'Belgium',
    cca2: 'BE',
    dialCode: '+32',
  },
  {
    id: 29,
    name: 'Belize',
    cca2: 'BZ',
    dialCode: '+501',
  },
  {
    id: 20,
    name: 'Benin',
    cca2: 'BJ',
    dialCode: '+229',
  },
  {
    id: 30,
    name: 'Bermuda',
    cca2: 'BM',
    dialCode: '+1441',
  },
  {
    id: 35,
    name: 'Bhutan',
    cca2: 'BT',
    dialCode: '+975',
  },
  {
    id: 31,
    name: 'Bolivia',
    cca2: 'BO',
    dialCode: '+591',
  },
  {
    id: 26,
    name: 'Bosnia and Herzegovina',
    cca2: 'BA',
    dialCode: '+387',
  },
  {
    id: 37,
    name: 'Botswana',
    cca2: 'BW',
    dialCode: '+267',
  },
  {
    id: 36,
    name: 'Bouvet Island',
    cca2: 'BV',
    dialCode: '+47',
  },
  {
    id: 32,
    name: 'Brazil',
    cca2: 'BR',
    dialCode: '+55',
  },
  {
    id: 214,
    name: 'Syria',
    cca2: 'SY',
    dialCode: '+963',
  },
  {
    id: 105,
    name: 'British Indian Ocean Territory',
    cca2: 'IO',
    dialCode: '+246',
  },
  {
    id: 34,
    name: 'Brunei',
    cca2: 'BN',
    dialCode: '+673',
  },
  {
    id: 23,
    name: 'Bulgaria',
    cca2: 'BG',
    dialCode: '+359',
  },
  {
    id: 21,
    name: 'Burkina Faso',
    cca2: 'BF',
    dialCode: '+226',
  },
  {
    id: 18,
    name: 'Burundi',
    cca2: 'BI',
    dialCode: '+257',
  },
  {
    id: 119,
    name: 'Cambodia',
    cca2: 'KH',
    dialCode: '+855',
  },
  {
    id: 45,
    name: 'Cameroon',
    cca2: 'CM',
    dialCode: '+237',
  },
  {
    id: 39,
    name: 'Canada',
    cca2: 'CA',
    dialCode: '+1',
  },
  {
    id: 51,
    name: 'Cape Verde',
    cca2: 'CV',
    dialCode: '+238',
  },
  {
    id: 56,
    name: 'Cayman Islands',
    cca2: 'KY',
    dialCode: '+ 345',
  },
  {
    id: 38,
    name: 'Central African Republic',
    cca2: 'CF',
    dialCode: '+236',
  },
  {
    id: 216,
    name: 'Chad',
    cca2: 'TD',
    dialCode: '+235',
  },
  {
    id: 42,
    name: 'Chile',
    cca2: 'CL',
    dialCode: '+56',
  },
  {
    id: 43,
    name: 'China',
    cca2: 'CN',
    dialCode: '+86',
  },
  {
    id: 55,
    name: 'Christmas Island',
    cca2: 'CX',
    dialCode: '+61',
  },
  {
    id: 40,
    name: 'Cocos (Keeling) Islands',
    cca2: 'CC',
    dialCode: '+61',
  },
  {
    id: 49,
    name: 'Colombia',
    cca2: 'CO',
    dialCode: '+57',
  },
  {
    id: 50,
    name: 'Comoros',
    cca2: 'KM',
    dialCode: '+269',
  },
  {
    id: 47,
    name: 'Republic of the Congo',
    cca2: 'CG',
    dialCode: '+242',
  },
  {
    id: 46,
    name: 'DR Congo',
    cca2: 'CD',
    dialCode: '+243',
  },
  {
    id: 48,
    name: 'Cook Islands',
    cca2: 'CK',
    dialCode: '+682',
  },
  {
    id: 52,
    name: 'Costa Rica',
    cca2: 'CR',
    dialCode: '+506',
  },
  {
    id: 44,
    name: 'Ivory Coast',
    cca2: 'CI',
    dialCode: '+225',
  },
  {
    id: 99,
    name: 'Croatia',
    cca2: 'HR',
    dialCode: '+385',
  },
  {
    id: 53,
    name: 'Cuba',
    cca2: 'CU',
    dialCode: '+53',
  },
  {
    id: 57,
    name: 'Cyprus',
    cca2: 'CY',
    dialCode: '+357',
  },
  {
    id: 58,
    name: 'Czech Republic',
    cca2: 'CZ',
    dialCode: '+420',
  },
  {
    id: 62,
    name: 'Denmark',
    cca2: 'DK',
    dialCode: '+45',
  },
  {
    id: 60,
    name: 'Djibouti',
    cca2: 'DJ',
    dialCode: '+253',
  },
  {
    id: 61,
    name: 'Dominica',
    cca2: 'DM',
    dialCode: '+1767',
  },
  {
    id: 63,
    name: 'Dominican Republic',
    cca2: 'DO',
    dialCode: '+1849',
  },
  {
    id: 65,
    name: 'Ecuador',
    cca2: 'EC',
    dialCode: '+593',
  },
  {
    id: 66,
    name: 'Egypt',
    cca2: 'EG',
    dialCode: '+20',
  },
  {
    id: 200,
    name: 'El Salvador',
    cca2: 'SV',
    dialCode: '+503',
  },
  {
    id: 88,
    name: 'Equatorial Guinea',
    cca2: 'GQ',
    dialCode: '+240',
  },
  {
    id: 67,
    name: 'Eritrea',
    cca2: 'ER',
    dialCode: '+291',
  },
  {
    id: 70,
    name: 'Estonia',
    cca2: 'EE',
    dialCode: '+372',
  },
  {
    id: 71,
    name: 'Ethiopia',
    cca2: 'ET',
    dialCode: '+251',
  },
  {
    id: 74,
    name: 'Falkland Islands',
    cca2: 'FK',
    dialCode: '+500',
  },
  {
    id: 76,
    name: 'Faroe Islands',
    cca2: 'FO',
    dialCode: '+298',
  },
  {
    id: 73,
    name: 'Fiji',
    cca2: 'FJ',
    dialCode: '+679',
  },
  {
    id: 72,
    name: 'Finland',
    cca2: 'FI',
    dialCode: '+358',
  },
  {
    id: 75,
    name: 'France',
    cca2: 'FR',
    dialCode: '+33',
  },
  {
    id: 93,
    name: 'French Guiana',
    cca2: 'GF',
    dialCode: '+594',
  },
  {
    id: 186,
    name: 'French Polynesia',
    cca2: 'PF',
    dialCode: '+689',
  },
  {
    id: 13,
    name: 'French Southern and Antarctic Lands',
    cca2: 'TF',
    dialCode: '+262',
  },
  {
    id: 78,
    name: 'Gabon',
    cca2: 'GA',
    dialCode: '+241',
  },
  {
    id: 86,
    name: 'Gambia',
    cca2: 'GM',
    dialCode: '+220',
  },
  {
    id: 80,
    name: 'Georgia',
    cca2: 'GE',
    dialCode: '+995',
  },
  {
    id: 59,
    name: 'Germany',
    cca2: 'DE',
    dialCode: '+49',
  },
  {
    id: 82,
    name: 'Ghana',
    cca2: 'GH',
    dialCode: '+233',
  },
  {
    id: 83,
    name: 'Gibraltar',
    cca2: 'GI',
    dialCode: '+350',
  },
  {
    id: 89,
    name: 'Greece',
    cca2: 'GR',
    dialCode: '+30',
  },
  {
    id: 91,
    name: 'Greenland',
    cca2: 'GL',
    dialCode: '+299',
  },
  {
    id: 90,
    name: 'Grenada',
    cca2: 'GD',
    dialCode: '+1473',
  },
  {
    id: 85,
    name: 'Guadeloupe',
    cca2: 'GP',
    dialCode: '+590',
  },
  {
    id: 94,
    name: 'Guam',
    cca2: 'GU',
    dialCode: '+1671',
  },
  {
    id: 92,
    name: 'Guatemala',
    cca2: 'GT',
    dialCode: '+502',
  },
  {
    id: 81,
    name: 'Guernsey',
    cca2: 'GG',
    dialCode: '+44',
  },
  {
    id: 84,
    name: 'Guinea',
    cca2: 'GN',
    dialCode: '+224',
  },
  {
    id: 87,
    name: 'Guinea-Bissau',
    cca2: 'GW',
    dialCode: '+245',
  },
  {
    id: 95,
    name: 'Guyana',
    cca2: 'GY',
    dialCode: '+592',
  },
  {
    id: 100,
    name: 'Haiti',
    cca2: 'HT',
    dialCode: '+509',
  },
  {
    id: 236,
    name: 'Vatican City',
    cca2: 'VA',
    dialCode: '+379',
  },
  {
    id: 98,
    name: 'Honduras',
    cca2: 'HN',
    dialCode: '+504',
  },
  {
    id: 96,
    name: 'Hong Kong',
    cca2: 'HK',
    dialCode: '+852',
  },
  {
    id: 101,
    name: 'Hungary',
    cca2: 'HU',
    dialCode: '+36',
  },
  {
    id: 109,
    name: 'Iceland',
    cca2: 'IS',
    dialCode: '+354',
  },
  {
    id: 104,
    name: 'India',
    cca2: 'IN',
    dialCode: '+91',
  },
  {
    id: 102,
    name: 'Indonesia',
    cca2: 'ID',
    dialCode: '+62',
  },
  {
    id: 107,
    name: 'Iran',
    cca2: 'IR',
    dialCode: '+98',
  },
  {
    id: 108,
    name: 'Iraq',
    cca2: 'IQ',
    dialCode: '+964',
  },
  {
    id: 106,
    name: 'Ireland',
    cca2: 'IE',
    dialCode: '+353',
  },
  {
    id: 103,
    name: 'Isle of Man',
    cca2: 'IM',
    dialCode: '+44',
  },
  {
    id: 110,
    name: 'Israel',
    cca2: 'IL',
    dialCode: '+972',
  },
  {
    id: 111,
    name: 'Italy',
    cca2: 'IT',
    dialCode: '+39',
  },
  {
    id: 112,
    name: 'Jamaica',
    cca2: 'JM',
    dialCode: '+1876',
  },
  {
    id: 115,
    name: 'Japan',
    cca2: 'JP',
    dialCode: '+81',
  },
  {
    id: 113,
    name: 'Jersey',
    cca2: 'JE',
    dialCode: '+44',
  },
  {
    id: 114,
    name: 'Jordan',
    cca2: 'JO',
    dialCode: '+962',
  },
  {
    id: 116,
    name: 'Kazakhstan',
    cca2: 'KZ',
    dialCode: '+7',
  },
  {
    id: 117,
    name: 'Kenya',
    cca2: 'KE',
    dialCode: '+254',
  },
  {
    id: 120,
    name: 'Kiribati',
    cca2: 'KI',
    dialCode: '+686',
  },
  {
    id: 182,
    name: 'North Korea',
    cca2: 'KP',
    dialCode: '+850',
  },
  {
    id: 122,
    name: 'South Korea',
    cca2: 'KR',
    dialCode: '+82',
  },
  {
    id: 123,
    name: 'Kosovo',
    cca2: 'XK',
    dialCode: '+383',
  },
  {
    id: 124,
    name: 'Kuwait',
    cca2: 'KW',
    dialCode: '+965',
  },
  {
    id: 118,
    name: 'Kyrgyzstan',
    cca2: 'KG',
    dialCode: '+996',
  },
  {
    id: 125,
    name: 'Laos',
    cca2: 'LA',
    dialCode: '+856',
  },
  {
    id: 135,
    name: 'Latvia',
    cca2: 'LV',
    dialCode: '+371',
  },
  {
    id: 126,
    name: 'Lebanon',
    cca2: 'LB',
    dialCode: '+961',
  },
  {
    id: 132,
    name: 'Lesotho',
    cca2: 'LS',
    dialCode: '+266',
  },
  {
    id: 127,
    name: 'Liberia',
    cca2: 'LR',
    dialCode: '+231',
  },
  {
    id: 128,
    name: 'Libya',
    cca2: 'LY',
    dialCode: '+218',
  },
  {
    id: 130,
    name: 'Liechtenstein',
    cca2: 'LI',
    dialCode: '+423',
  },
  {
    id: 133,
    name: 'Lithuania',
    cca2: 'LT',
    dialCode: '+370',
  },
  {
    id: 134,
    name: 'Luxembourg',
    cca2: 'LU',
    dialCode: '+352',
  },
  {
    id: 136,
    name: 'Macau',
    cca2: 'MO',
    dialCode: '+853',
  },
  {
    id: 145,
    name: 'Macedonia',
    cca2: 'MK',
    dialCode: '+389',
  },
  {
    id: 141,
    name: 'Madagascar',
    cca2: 'MG',
    dialCode: '+261',
  },
  {
    id: 157,
    name: 'Malawi',
    cca2: 'MW',
    dialCode: '+265',
  },
  {
    id: 158,
    name: 'Malaysia',
    cca2: 'MY',
    dialCode: '+60',
  },
  {
    id: 142,
    name: 'Maldives',
    cca2: 'MV',
    dialCode: '+960',
  },
  {
    id: 146,
    name: 'Mali',
    cca2: 'ML',
    dialCode: '+223',
  },
  {
    id: 147,
    name: 'Malta',
    cca2: 'MT',
    dialCode: '+356',
  },
  {
    id: 144,
    name: 'Marshall Islands',
    cca2: 'MH',
    dialCode: '+692',
  },
  {
    id: 155,
    name: 'Martinique',
    cca2: 'MQ',
    dialCode: '+596',
  },
  {
    id: 153,
    name: 'Mauritania',
    cca2: 'MR',
    dialCode: '+222',
  },
  {
    id: 156,
    name: 'Mauritius',
    cca2: 'MU',
    dialCode: '+230',
  },
  {
    id: 159,
    name: 'Mayotte',
    cca2: 'YT',
    dialCode: '+262',
  },
  {
    id: 143,
    name: 'Mexico',
    cca2: 'MX',
    dialCode: '+52',
  },
  {
    id: 77,
    name: 'Micronesia',
    cca2: 'FM',
    dialCode: '+691',
  },
  {
    id: 140,
    name: 'Moldova',
    cca2: 'MD',
    dialCode: '+373',
  },
  {
    id: 139,
    name: 'Monaco',
    cca2: 'MC',
    dialCode: '+377',
  },
  {
    id: 150,
    name: 'Mongolia',
    cca2: 'MN',
    dialCode: '+976',
  },
  {
    id: 149,
    name: 'Montenegro',
    cca2: 'ME',
    dialCode: '+382',
  },
  {
    id: 154,
    name: 'Montserrat',
    cca2: 'MS',
    dialCode: '+1664',
  },
  {
    id: 138,
    name: 'Morocco',
    cca2: 'MA',
    dialCode: '+212',
  },
  {
    id: 152,
    name: 'Mozambique',
    cca2: 'MZ',
    dialCode: '+258',
  },
  {
    id: 148,
    name: 'Myanmar',
    cca2: 'MM',
    dialCode: '+95',
  },
  {
    id: 160,
    name: 'Namibia',
    cca2: 'NA',
    dialCode: '+264',
  },
  {
    id: 170,
    name: 'Nauru',
    cca2: 'NR',
    dialCode: '+674',
  },
  {
    id: 169,
    name: 'Nepal',
    cca2: 'NP',
    dialCode: '+977',
  },
  {
    id: 167,
    name: 'Netherlands',
    cca2: 'NL',
    dialCode: '+31',
  },
  {
    id: 161,
    name: 'New Caledonia',
    cca2: 'NC',
    dialCode: '+687',
  },
  {
    id: 171,
    name: 'New Zealand',
    cca2: 'NZ',
    dialCode: '+64',
  },
  {
    id: 165,
    name: 'Nicaragua',
    cca2: 'NI',
    dialCode: '+505',
  },
  {
    id: 162,
    name: 'Niger',
    cca2: 'NE',
    dialCode: '+227',
  },
  {
    id: 164,
    name: 'Nigeria',
    cca2: 'NG',
    dialCode: '+234',
  },
  {
    id: 166,
    name: 'Niue',
    cca2: 'NU',
    dialCode: '+683',
  },
  {
    id: 163,
    name: 'Norfolk Island',
    cca2: 'NF',
    dialCode: '+672',
  },
  {
    id: 151,
    name: 'Northern Mariana Islands',
    cca2: 'MP',
    dialCode: '+1670',
  },
  {
    id: 168,
    name: 'Norway',
    cca2: 'NO',
    dialCode: '+47',
  },
  {
    id: 172,
    name: 'Oman',
    cca2: 'OM',
    dialCode: '+968',
  },
  {
    id: 173,
    name: 'Pakistan',
    cca2: 'PK',
    dialCode: '+92',
  },
  {
    id: 178,
    name: 'Palau',
    cca2: 'PW',
    dialCode: '+680',
  },
  {
    id: 185,
    name: 'Palestine',
    cca2: 'PS',
    dialCode: '+970',
  },
  {
    id: 174,
    name: 'Panama',
    cca2: 'PA',
    dialCode: '+507',
  },
  {
    id: 179,
    name: 'Papua New Guinea',
    cca2: 'PG',
    dialCode: '+675',
  },
  {
    id: 184,
    name: 'Paraguay',
    cca2: 'PY',
    dialCode: '+595',
  },
  {
    id: 176,
    name: 'Peru',
    cca2: 'PE',
    dialCode: '+51',
  },
  {
    id: 177,
    name: 'Philippines',
    cca2: 'PH',
    dialCode: '+63',
  },
  {
    id: 175,
    name: 'Pitcairn Islands',
    cca2: 'PN',
    dialCode: '+64',
  },
  {
    id: 180,
    name: 'Poland',
    cca2: 'PL',
    dialCode: '+48',
  },
  {
    id: 183,
    name: 'Portugal',
    cca2: 'PT',
    dialCode: '+351',
  },
  {
    id: 181,
    name: 'Puerto Rico',
    cca2: 'PR',
    dialCode: '+1939',
  },
  {
    id: 187,
    name: 'Qatar',
    cca2: 'QA',
    dialCode: '+974',
  },
  {
    id: 189,
    name: 'Romania',
    cca2: 'RO',
    dialCode: '+40',
  },
  {
    id: 190,
    name: 'Russia',
    cca2: 'RU',
    dialCode: '+7',
  },
  {
    id: 191,
    name: 'Rwanda',
    cca2: 'RW',
    dialCode: '+250',
  },
  {
    id: 188,
    name: 'Réunion',
    cca2: 'RE',
    dialCode: '+262',
  },
  {
    id: 27,
    name: 'Saint Barthélemy',
    cca2: 'BL',
    dialCode: '+590',
  },
  {
    id: 121,
    name: 'Saint Kitts and Nevis',
    cca2: 'KN',
    dialCode: '+1869',
  },
  {
    id: 129,
    name: 'Saint Lucia',
    cca2: 'LC',
    dialCode: '+1758',
  },
  {
    id: 137,
    name: 'Saint Martin',
    cca2: 'MF',
    dialCode: '+590',
  },
  {
    id: 203,
    name: 'Saint Pierre and Miquelon',
    cca2: 'PM',
    dialCode: '+508',
  },
  {
    id: 237,
    name: 'Saint Vincent and the Grenadines',
    cca2: 'VC',
    dialCode: '+1784',
  },
  {
    id: 244,
    name: 'Samoa',
    cca2: 'WS',
    dialCode: '+685',
  },
  {
    id: 201,
    name: 'San Marino',
    cca2: 'SM',
    dialCode: '+378',
  },
  {
    id: 206,
    name: 'São Tomé and Príncipe',
    cca2: 'ST',
    dialCode: '+239',
  },
  {
    id: 192,
    name: 'Saudi Arabia',
    cca2: 'SA',
    dialCode: '+966',
  },
  {
    id: 194,
    name: 'Senegal',
    cca2: 'SN',
    dialCode: '+221',
  },
  {
    id: 204,
    name: 'Serbia',
    cca2: 'RS',
    dialCode: '+381',
  },
  {
    id: 213,
    name: 'Seychelles',
    cca2: 'SC',
    dialCode: '+248',
  },
  {
    id: 199,
    name: 'Sierra Leone',
    cca2: 'SL',
    dialCode: '+232',
  },
  {
    id: 195,
    name: 'Singapore',
    cca2: 'SG',
    dialCode: '+65',
  },
  {
    id: 208,
    name: 'Slovakia',
    cca2: 'SK',
    dialCode: '+421',
  },
  {
    id: 209,
    name: 'Slovenia',
    cca2: 'SI',
    dialCode: '+386',
  },
  {
    id: 198,
    name: 'Solomon Islands',
    cca2: 'SB',
    dialCode: '+677',
  },
  {
    id: 202,
    name: 'Somalia',
    cca2: 'SO',
    dialCode: '+252',
  },
  {
    id: 246,
    name: 'South Africa',
    cca2: 'ZA',
    dialCode: '+27',
  },
  {
    id: 205,
    name: 'South Sudan',
    cca2: 'SS',
    dialCode: '+211',
  },
  {
    id: 196,
    name: 'South Georgia',
    cca2: 'GS',
    dialCode: '+500',
  },
  {
    id: 69,
    name: 'Spain',
    cca2: 'ES',
    dialCode: '+34',
  },
  {
    id: 131,
    name: 'Sri Lanka',
    cca2: 'LK',
    dialCode: '+94',
  },
  {
    id: 193,
    name: 'Sudan',
    cca2: 'SD',
    dialCode: '+249',
  },
  {
    id: 207,
    name: 'Suriname',
    cca2: 'SR',
    dialCode: '+597',
  },
  {
    id: 197,
    name: 'Svalbard and Jan Mayen',
    cca2: 'SJ',
    dialCode: '+47',
  },
  {
    id: 211,
    name: 'Swaziland',
    cca2: 'SZ',
    dialCode: '+268',
  },
  {
    id: 210,
    name: 'Sweden',
    cca2: 'SE',
    dialCode: '+46',
  },
  {
    id: 41,
    name: 'Switzerland',
    cca2: 'CH',
    dialCode: '+41',
  },
  {
    id: 228,
    name: 'Taiwan',
    cca2: 'TW',
    dialCode: '+886',
  },
  {
    id: 219,
    name: 'Tajikistan',
    cca2: 'TJ',
    dialCode: '+992',
  },
  {
    id: 229,
    name: 'Tanzania',
    cca2: 'TZ',
    dialCode: '+255',
  },
  {
    id: 218,
    name: 'Thailand',
    cca2: 'TH',
    dialCode: '+66',
  },
  {
    id: 222,
    name: 'Timor-Leste',
    cca2: 'TL',
    dialCode: '+670',
  },
  {
    id: 217,
    name: 'Togo',
    cca2: 'TG',
    dialCode: '+228',
  },
  {
    id: 220,
    name: 'Tokelau',
    cca2: 'TK',
    dialCode: '+690',
  },
  {
    id: 223,
    name: 'Tonga',
    cca2: 'TO',
    dialCode: '+676',
  },
  {
    id: 224,
    name: 'Trinidad and Tobago',
    cca2: 'TT',
    dialCode: '+1868',
  },
  {
    id: 225,
    name: 'Tunisia',
    cca2: 'TN',
    dialCode: '+216',
  },
  {
    id: 226,
    name: 'Turkey',
    cca2: 'TR',
    dialCode: '+90',
  },
  {
    id: 221,
    name: 'Turkmenistan',
    cca2: 'TM',
    dialCode: '+993',
  },
  {
    id: 215,
    name: 'Turks and Caicos Islands',
    cca2: 'TC',
    dialCode: '+1649',
  },
  {
    id: 227,
    name: 'Tuvalu',
    cca2: 'TV',
    dialCode: '+688',
  },
  {
    id: 230,
    name: 'Uganda',
    cca2: 'UG',
    dialCode: '+256',
  },
  {
    id: 231,
    name: 'Ukraine',
    cca2: 'UA',
    dialCode: '+380',
  },
  {
    id: 8,
    name: 'United Arab Emirates',
    cca2: 'AE',
    dialCode: '+971',
  },
  {
    id: 79,
    name: 'United Kingdom',
    cca2: 'GB',
    dialCode: '+44',
  },
  {
    id: 234,
    name: 'United States',
    cca2: 'US',
    dialCode: '+1',
  },
  {
    id: 233,
    name: 'Uruguay',
    cca2: 'UY',
    dialCode: '+598',
  },
  {
    id: 235,
    name: 'Uzbekistan',
    cca2: 'UZ',
    dialCode: '+998',
  },
  {
    id: 242,
    name: 'Vanuatu',
    cca2: 'VU',
    dialCode: '+678',
  },
  {
    id: 238,
    name: 'Venezuela',
    cca2: 'VE',
    dialCode: '+58',
  },
  {
    id: 241,
    name: 'Vietnam',
    cca2: 'VN',
    dialCode: '+84',
  },
  {
    id: 239,
    name: 'British Virgin Islands',
    cca2: 'VG',
    dialCode: '+1284',
  },
  {
    id: 240,
    name: 'United States Virgin Islands',
    cca2: 'VI',
    dialCode: '+1340',
  },
  {
    id: 243,
    name: 'Wallis and Futuna',
    cca2: 'WF',
    dialCode: '+681',
  },
  {
    id: 245,
    name: 'Yemen',
    cca2: 'YE',
    dialCode: '+967',
  },
  {
    id: 247,
    name: 'Zambia',
    cca2: 'ZM',
    dialCode: '+260',
  },
  {
    id: 248,
    name: 'Zimbabwe',
    cca2: 'ZW',
    dialCode: '+263',
  },
  {
    id: 54,
    name: 'Curaçao',
    cca2: 'CW',
    dialCode: '+599',
  },
  {
    id: 68,
    name: 'Western Sahara',
    cca2: 'EH',
    dialCode: '+212',
  },
  {
    id: 212,
    name: 'Sint Maarten',
    cca2: 'SX',
    dialCode: '+1',
  },
  {
    id: 232,
    name: 'United States Minor Outlying Islands',
    cca2: 'US',
    dialCode: '+1',
  },
];
