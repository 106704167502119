import { useMemo, useState, useEffect, memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
import { Link } from 'react-router-dom';
import Button from '@hiredigital/ui/Button';
// import { SelectMenu, Position } from 'evergreen-ui';
import SelectMenu from '@hiredigital/ui/SelectMenu';
import { onOrgLoadOptions, onAssignmentUsersLoadOptions } from '@apis/dropdown';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { isBriefAdmin, isSalesAdmin, isPlacementAdmin } from '@helpers/permissions';
import { useUser } from '@context/user';
import { AssignmentStatus } from '@hiredigital/lib/helpers/enum';

import withParameters from '@hoc/withParameters';
import withEmailLabel from '@hoc/withEmailLabel';
import withParamsResolver from '@hoc/withParamsResolver';

import { getAssignments, putAssignment } from '@apis/assignments';
import { RateType } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';
import AppLayout from '@components/Layout/AppLayout';

import Styles from '@styles/PageList.module.scss';
import PageStyles from './list.module.scss';
import { postAssignment } from '@apis/assignments';

// const MemoSelectMenu = memo(SelectMenu);
const UserAsyncPaginate = withEmailLabel(AsyncPaginate);

const UserSelect = withParamsResolver(UserAsyncPaginate, 'admin/users');
const ClientSelect = withParamsResolver(UserAsyncPaginate, 'admin/orgs');

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

const Profile = ({ data }) => {
  if (!data) return '';
  const imgStyle = { marginRight: '10px' };
  return (
    <div className={Styles.profile}>
      {data && data.picture ? (
        <img className={Styles.picture} src={data.picture} alt={data.name} style={imgStyle} />
      ) : (
        <div className={classNames(Styles.picture, Styles.noPicture)} style={imgStyle} />
      )}
      <div style={{ overflow: 'hidden' }}>
        <MultiLineEllipsis className={Styles.userTeamName}>{data?.name || ''}</MultiLineEllipsis>
      </div>
    </div>
  );
};

const AssignmentList = ({ initialProps, location, history, ...props }) => {
  const currentUser = useUser();
  const [user, setUser] = useState(initialProps?.user);
  const [organization, setOrganization] = useState(initialProps?.organization);
  const [isAdding, setIsAdding] = useState(false);
  const [updateRow, setUpdateRow] = useState(undefined);
  const [search, setSearch] = useState(initialProps?.search);

  let timeout = null;

  const StatusColumn = ({ assignment, onStatusUpdate }) => {
    return (
      <div className={PageStyles.cell}>
        <SelectMenu
          onChange={(item) => onStatusUpdate(assignment, item.id)}
          options={AssignmentStatus.values.map((a) => ({ label: a.label, id: a.id }))}
          defaultValue={AssignmentStatus.getEnum(assignment.status) || ''}
        />
        {/* <MemoSelectMenu
          title='Select Status'
          hasFilter={false}
          height={175}
          position={Position.BOTTOM_RIGHT}
          options={AssignmentStatus.values.map((a) => ({ label: a.label, value: a.id }))}
          selected={assignment.status}
          onSelect={(item) => onStatusUpdate(assignment, item.value)}>
          <div className={Styles.toggleContainer}>
            {AssignmentStatus.getEnum(assignment.status).label || ''}
            <div className={Styles.toggle} />
          </div>
        </MemoSelectMenu> */}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        canSort: true,
        id: 'title',
        minWidth: 250,
        Cell: ({ row: { original: a } }) => {
          return <Link to={`/assignments/${a?.uuid}`}>{a?.title || 'Untitled Assignment'}</Link>;
        },
      },
      {
        Header: 'Status',
        canSort: true,
        id: 'status',
        truncate: false,
        accessor: (e) => <StatusColumn assignment={e} onStatusUpdate={handleStatusChange} />,
      },
      {
        Header: 'Engagement',
        canSort: true,
        id: 'engagement',
        accessor: (a) => a?.engagment?.title,
      },
      {
        Header: 'User',
        canSort: true,
        id: 'user',
        minWidth: 250,
        truncate: false,
        Cell: ({ row: { original: a } }) => {
          return <Profile data={a?.user} />;
        },
      },
      {
        Header: 'Team',
        canSort: true,
        id: 'team__name',
        minWidth: 250,
        truncate: false,
        Cell: ({ row: { original: a } }) => {
          return <Profile data={a?.team} />;
        },
      },
      {
        Header: 'Organization',
        canSort: true,
        id: 'organization__name',
        minWidth: 250,
        truncate: false,
        Cell: ({ row: { original: a } }) => {
          return <Profile data={a?.organization} />;
        },
      },
      ...(currentUser && isSalesAdmin(currentUser)
        ? [
            {
              Header: currentUser && isBriefAdmin(currentUser) ? 'Client Rate' : 'Rate',
              canSort: true,
              id: 'brandRate',
              minWidth: 165,
              Cell: ({ row: { original: a } }) => {
                return isNewRates ? (
                  <p className={Styles.content}>
                    {`${a?.clientEngagementRate?.rateDisplayText || ''}`}
                  </p>
                ) : (
                  <p className={Styles.content}>
                    {`${a?.brandRate || '0.00'} ${
                      a?.brandRateType ? RateType.getEnum(a?.brandRateType).label || '' : ''
                    }`}
                  </p>
                );
              },
            },
          ]
        : []),
      ...(currentUser && isPlacementAdmin(currentUser)
        ? [
            {
              Header: currentUser && isBriefAdmin(currentUser) ? 'Talent Rate' : 'Rate',
              canSort: true,
              id: 'talentRate',
              minWidth: 165,
              Cell: ({ row: { original: a } }) => {
                return isNewRates ? (
                  <p className={Styles.content}>
                    {`${a?.talentEngagementRate?.rateDisplayText || ''}`}
                  </p>
                ) : (
                  <p className={Styles.content}>
                    {`${a?.talentRate || '0.00'} ${
                      a?.talentRateType ? RateType.getEnum(a?.talentRateType).label || '' : ''
                    }`}
                  </p>
                );
              },
            },
          ]
        : []),
      {
        Header: 'Start Date',
        canSort: true,
        id: 'start',
        accessor: (a) => (a?.startDate ? toReadableDate(a.startDate) : ''),
      },
      {
        Header: 'End Date',
        canSort: true,
        id: 'end',
        accessor: (a) => (a?.endDate ? toReadableDate(a.endDate) : ''),
      },
    ],
    [currentUser]
  );

  const handleStatusChange = (assignment, statusId) => {
    handleStatusUpdate({ ...assignment, status: statusId });
    putAssignment(assignment.uuid, { status: statusId })
      .then(({ data }) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setPageTitle('Assignments');
  }, []);

  const handleCreateNewAssignment = () => {
    setIsAdding(true);
    const data = { status: AssignmentStatus.PROJECT.id };
    postAssignment(data)
      .then(({ data }) => {
        setIsAdding(false);
        history.push('/assignments/' + data.uuid);
      })
      .catch((error) => {
        setIsAdding(false);
      });
  };

  const handleUserFilterChange = (u) => setUser(u?.uuid);

  const handleOrgFilterChange = (o) => setOrganization(o?.uuid);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleStatusUpdate = (data) => refreshList(data);

  const refreshList = (data) => {
    setUpdateRow(data);
  };

  const filterMemo = useMemo(
    () => ({
      user,
      organization,
    }),
    [user, organization]
  );

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.wideBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Assignments`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />

          <UserSelect
            id='userID'
            className={Styles.headerSelect}
            selectValue={user}
            defaultValue={user}
            name='user'
            placeholder='User'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={handleUserFilterChange}
            isPaginated
            loadOptions={onAssignmentUsersLoadOptions}
            debounceTimeout={500}
            isClearable
            additional={{ page: 1 }}
            SelectComponent={Select}
            createOptionPosition={'first'}
          />

          <ClientSelect
            id='organizationID'
            className={Styles.headerSelect}
            selectValue={organization}
            defaultValue={organization}
            name='organization'
            placeholder='Client'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={handleOrgFilterChange}
            isPaginated
            loadOptions={onOrgLoadOptions}
            debounceTimeout={500}
            isClearable
            additional={{ page: 1 }}
            SelectComponent={Select}
            createOptionPosition={'first'}
          />

          <Button
            className={Styles.addButton}
            type={Button.Type.BLUE}
            onClick={handleCreateNewAssignment}
            isLoading={isAdding}>
            {`New Assignment`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getAssignments}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        updateRow={updateRow}
        rowHeight={`auto`}
        rowAccessor='uuid'
        filters={filterMemo}
      />
    </AppLayout>
  );
};

AssignmentList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(AssignmentList);
