import { authRequest } from './utils';

export const getEmails = async (config = undefined) => {
  const url = `admin/emails/`;
  return await authRequest.get(url, config);
};

export const getEmail = async (id, config = undefined) => {
  const url = `admin/emails/${id}/`;
  return authRequest.get(url, config);
};

export const putEmail = async (id, data, config = undefined) => {
  const url = `admin/emails/${id}/`;
  return authRequest.put(url, data, config);
};

export const patchEmail = async (id, data, config = undefined) => {
  const url = `admin/emails/${id}/`;
  return authRequest.patch(url, data, config);
};

export const postEmail = async (data, config = undefined) => {
  const url = `admin/emails/`;
  return authRequest.post(url, data, config);
};

export const getEmailTemplates = async (config = undefined) => {
  const url = `admin/email-templates/`;
  return await authRequest.get(url, config);
};

export const getEmailTemplate = async (templateId, config = undefined) => {
  const url = `admin/email-templates/${templateId}/`;
  return await authRequest.get(url, config);
};

export const putEmailTemplate = async (templateId, data, config = undefined) => {
  const url = `admin/email-templates/${templateId}/`;
  return await authRequest.put(url, data, config);
};

export const patchEmailTemplate = async (templateId, data, config = undefined) => {
  const url = `admin/email-templates/${templateId}/`;
  return await authRequest.patch(url, data, config);
};

export const postEmailTemplate = async (data, config = undefined) => {
  const url = `admin/email-templates/`;
  return await authRequest.post(url, data, config);
};

export const deleteEmailTemplate = async (templateId, config = undefined) => {
  const url = `admin/email-templates/${templateId}/`;
  return await authRequest.delete(url, config);
};

export const postEmailTemplatePreview = async (templateId, data, config = undefined) => {
  const url = `admin/email-templates/${templateId}/preview/`;
  return await authRequest.post(url, data, config);
};

export const postEmailTemplateRefresh = async (templateId, data, config = undefined) => {
  const url = `admin/email-templates/${templateId}/refresh/`;
  return await authRequest.post(url, data, config);
};
