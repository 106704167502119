import { authRequest } from './utils';

export const getMessages = async (config = undefined) => {
  const url = `admin/messages/`;
  return await authRequest.get(url, config);
};

export const putMessage = async (id, data, config = undefined) => {
  const url = `admin/messages/${id}/`;
  return authRequest.put(url, data, config);
};

export const deleteMessage = async (id, config = undefined) => {
  const url = `admin/messages/${id}/`;
  return authRequest.delete(url, config);
};
