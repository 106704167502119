/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, Suspense, lazy, memo, useCallback } from 'react';
import classNames from 'classnames';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { languageList } from '@data/languageList';
import { countryList } from '@data/countryList';
import remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import {
  EngagementStatus,
  Currency,
  RateType,
  TimeRate,
  VisibilityOption,
} from '@hiredigital/lib/helpers/enum';
import { postSkill } from '@apis/skills';
import {
  postEngagementInvitationGenerate,
  putEngagement,
  postEngagementInvitationClear,
  postEngagementInvitation,
  postEngagementApplication,
  postEngagementAssignment,
  deleteEngagement,
} from '@apis/engagements';
import { onSkillsLoadOptions, onTalentTypesLoadOptions, onFormatLoadOptions } from '@apis/dropdown';
import { isSalesAdmin, isPlacementAdmin, isAnyBriefAdmin } from '@helpers/permissions';

import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import Action from '@hiredigital/ui/Action';
import Quill from '@hiredigital/ui/Quill/Editor';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import DualInput from '@hiredigital/ui/Input/DualInput';

import Styles from '@styles/BriefDetail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';
import isAfter from 'date-fns/isAfter';
import { useUser } from '@context/user';
import RateInputGroup from '@components/RateInputGroup';

const InvitationDetailList = lazy(() => import('./InvitationDetailList'));
const AssignmentDetailList = lazy(() => import('./AssignmentDetailList'));
const ApplicationDetailList = lazy(() => import('./ApplicationDetailList'));

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);
let autoSaveTimeout;

const getFormatTitle = (eng) => {
  if (eng?.formatQty > 1) {
    return `${eng?.formatQty} ${eng?.format?.pluralLabel || eng?.otherFormat}`;
  }
  return eng?.format?.label || eng?.otherFormat;
};

const generateEngagementTitle = (eng) => {
  const talentTypeTitle = eng?.talentType?.label || eng?.otherTalentType;
  return talentTypeTitle || getFormatTitle(eng) || eng?.title || 'New Engagement';
};

const EngagementDetailList = memo(
  ({
    engagement,
    onEngagementChange,
    onEngagementDelete,
    index,
    location: propsLocation,
    briefUuid,
    history,
  }) => {
    const initialEngagement = engagement;
    const currentUser = useUser();

    const [uuid] = useState(initialEngagement?.uuid);
    const [countries, setCountries] = useState(initialEngagement?.countries);
    const [languages, setLanguages] = useState(initialEngagement?.languages);
    const [skills, setSkills] = useState(initialEngagement?.skills);
    const [talentType, setTalentType] = useState(initialEngagement?.talentType);
    const [format, setFormat] = useState(initialEngagement?.format);
    const [responsibilities, setResponsibilities] = useState(initialEngagement?.responsibilities);
    const [requirements, setRequirements] = useState(initialEngagement?.requirements);
    const [formatQty, setFormatQty] = useState(initialEngagement?.formatQty);
    const [title, setTitle] = useState(initialEngagement?.title);
    const [clientDetails, setClientDetails] = useState(initialEngagement?.clientDetails);
    const [clientName, setClientName] = useState(initialEngagement?.clientName);
    const [confidentialClientName, setConfidentialClientName] = useState(
      initialEngagement?.confidentialClientName
    );
    const [confidentialClientDetails, setConfidentialClientDetails] = useState(
      initialEngagement?.confidentialClientDetails
    );
    const [slug, setSlug] = useState(initialEngagement?.slug);
    const [otherTalentType, setOtherTalentType] = useState(initialEngagement?.otherTalentType);
    const [otherFormat, setOtherFormat] = useState(initialEngagement?.otherFormat);
    const [estimatedStartDate, setEstimatedStartDate] = useState(
      initialEngagement?.estimatedStartDate
    );
    const [estimatedEndDate, setEstimatedEndDate] = useState(initialEngagement?.estimatedEndDate);
    const [estimatedTalentRate, setEstimatedTalentRate] = useState(
      initialEngagement?.estimatedTalentRate || 0
    );
    const [estimatedTalentRateType, setEstimatedTalentRateType] = useState(
      initialEngagement?.estimatedTalentRateType
    );
    const [estimatedBrandRate, setEstimatedBrandRate] = useState(
      initialEngagement?.estimatedBrandRate || 0
    );
    const [estimatedBrandRateType, setEstimatedBrandRateType] = useState(
      initialEngagement?.estimatedBrandRateType
    );
    const [brandMinHours, setBrandMinHours] = useState(initialEngagement?.brandMinHours);
    const [brandMaxHours, setBrandMaxHours] = useState(initialEngagement?.brandMaxHours);
    const [brandMinPer, setBrandMinPer] = useState(initialEngagement?.brandMinPer);
    const [brandMaxPer, setBrandMaxPer] = useState(initialEngagement?.brandMaxPer);
    const [talentMinPer, setTalentMinPer] = useState(initialEngagement?.talentMinPer);
    const [talentMaxPer, setTalentMaxPer] = useState(initialEngagement?.talentMaxPer);
    const [talentMinHours, setTalentMinHours] = useState(initialEngagement?.talentMinHours);
    const [talentMaxHours, setTalentMaxHours] = useState(initialEngagement?.talentMaxHours);
    const [location, setLocation] = useState(initialEngagement?.location);
    const [currencyCode, setCurrencyCode] = useState(initialEngagement?.currencyCode);
    const [estimatedBudget, setEstimatedBudget] = useState(initialEngagement?.estimatedBudget);
    const [clientTerms, setClientTerms] = useState(initialEngagement?.clientTerms);
    const [talentTerms, setTalentTerms] = useState(initialEngagement?.talentTerms);
    const [status, setStatus] = useState(initialEngagement?.status);
    const [sgdBudget] = useState(initialEngagement?.sgdBudget);
    const [visibility, setVisibility] = useState(initialEngagement?.visibility);

    const [invitations, setInvitations] = useState(initialEngagement?.invitations);
    const [applications, setApplications] = useState(initialEngagement?.applications);
    const [assignments, setAssignments] = useState(initialEngagement?.assignments);

    const [talentEngagementRate, setTalentEngagementRate] = useState(
      initialEngagement?.talentEngagementRate
    );
    const [clientEngagementRate, setClientEngagementRate] = useState(
      initialEngagement?.clientEngagementRate
    );

    const [currentTab, setCurrentTab] = useState('details');
    const [isAddInProgress, setIsAddInProgress] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const [data, setData] = useState();
    useEffect(() => {
      setData(getData());
    }, []);

    useEffect(() => {
      if (!isMounted) return;
      handleAutoSave();
    }, [
      countries,
      languages,
      skills,
      talentType,
      format,
      responsibilities,
      requirements,
      formatQty,
      title,
      clientDetails,
      clientName,
      confidentialClientName,
      confidentialClientDetails,
      slug,
      otherTalentType,
      otherFormat,
      estimatedStartDate,
      estimatedEndDate,
      estimatedTalentRate,
      estimatedTalentRateType,
      estimatedBrandRate,
      estimatedBrandRateType,
      brandMinHours,
      brandMaxHours,
      brandMinPer,
      brandMaxPer,
      talentMinPer,
      talentMaxPer,
      talentMinHours,
      talentMaxHours,
      location,
      currencyCode,
      estimatedBudget,
      clientTerms,
      talentTerms,
      status,
      visibility,
      talentEngagementRate?.uuid,
      clientEngagementRate?.uuid,
    ]);

    useEffect(() => {
      if (!isMounted) return;
      onEngagementChange?.({ assignments }, uuid);
    }, [assignments]);

    useEffect(() => {
      if (!isMounted) return;
      onEngagementChange?.({ invitations }, uuid);
    }, [invitations]);

    useEffect(() => {
      if (!isMounted) return;
      onEngagementChange?.({ applications }, uuid);
    }, [applications]);

    useEffect(() => {
      setIsMounted(true);
    }, []);

    const validateFields = () => {
      let errors = {};

      if (
        estimatedStartDate &&
        estimatedEndDate &&
        isAfter(new Date(estimatedStartDate), new Date(estimatedEndDate))
      ) {
        errors = { ...errors, estimatedStartDate: 'Start Date should not be later than End Date.' };
      }

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return false;
      }

      return true;
    };

    const getData = () => ({
      countries: countries?.map((obj) => obj.id),
      languages: languages?.map((obj) => obj.id),
      skills: skills?.map((obj) => obj.id) || [],
      talentType: talentType ? talentType.id : null,
      format: format ? format.id : null,
      requirements,
      responsibilities,
      formatQty,
      title,
      clientDetails,
      clientName,
      confidentialClientName,
      confidentialClientDetails,
      slug,
      otherTalentType,
      otherFormat,
      estimatedStartDate,
      estimatedEndDate,
      estimatedTalentRate: estimatedTalentRate || 0,
      estimatedTalentRateType,
      estimatedBrandRate: estimatedBrandRate || 0,
      estimatedBrandRateType,
      brandMinHours,
      brandMaxHours,
      brandMinPer,
      brandMaxPer,
      talentMinPer,
      talentMaxPer,
      talentMinHours,
      talentMaxHours,
      location,
      currencyCode,
      estimatedBudget: estimatedBudget || 0,
      sgdBudget: sgdBudget || 0,
      clientTerms,
      talentTerms,
      status,
      visibility,
      talentEngagementRate: talentEngagementRate?.uuid || null,
      clientEngagementRate: clientEngagementRate?.uuid || null,
    });

    const submit = () => {
      // if true, the component is not mounted yet so a submission is unnecessary
      if (!data) return;

      if (validateFields()) {
        const newData = getData();

        // if true, there is no change from the original data or the last submitted data
        if (_isEqual(data, newData)) return;

        setIsSaving(true);

        putEngagement(engagement?.uuid, newData).then(
          (response) => {
            onEngagementChange?.(response.data, engagement?.uuid);
            setIsSaving(false);
            setData(newData);
          },
          (error) => {
            setIsSaving(false);
          }
        );
      }
    };

    const handleApplicationDelete = useCallback(
      (applicationUuid) => {
        setApplications(applications.filter((a) => a?.uuid !== applicationUuid));
      },
      [applications]
    );

    const handleAddRecommendedInvitations = () => {
      postEngagementInvitationGenerate(engagement?.uuid).then(
        (response) => {
          setInvitations([...invitations].concat(response.data.results));
          setIsSaving(false);
        },
        (error) => {
          setIsSaving(false);
        }
      );
    };

    const handleRemoveRecommendedInvitations = () => {
      postEngagementInvitationClear(engagement?.uuid).then(
        (response) => {
          let updatedInvitations = [...invitations];
          remove(updatedInvitations, (n) => response.data.indexOf(n.id) !== -1);
          setInvitations(updatedInvitations);
          setIsSaving(false);
        },
        (error) => {
          setIsSaving(false);
        }
      );
    };

    const getNewObjectWithDefaults = (response) => ({
      ...response?.data,
      new: true,
      talentMinHours,
      talentMaxHours,
      brandMinHours,
      brandMaxHours,
      talentMinPer,
      talentMaxPer,
      brandMinPer,
      brandMaxPer,
      talentEngagementRate: talentEngagementRate && {
        maxRate: talentEngagementRate.maxRate,
        currencyCode: talentEngagementRate.currencyCode,
        rateType: talentEngagementRate.rateType,

        minHours: talentEngagementRate.minHours,
        maxHours: talentEngagementRate.maxHours,
        hoursPeriodType: talentEngagementRate.hoursPeriodType,

        duration: talentEngagementRate.duration,
        durationType: talentEngagementRate.durationType,

        rateDisplayText: talentEngagementRate.rateDisplayText,
        autoGenerateDisplayText: talentEngagementRate.autoGenerateDisplayText,
        showDuration: talentEngagementRate.showDuration,
        showHoursPeriod: talentEngagementRate.showHoursPeriod,
      },
      clientEngagementRate: clientEngagementRate && {
        maxRate: clientEngagementRate.maxRate,
        currencyCode: clientEngagementRate.currencyCode,
        rateType: clientEngagementRate.rateType,

        minHours: clientEngagementRate.minHours,
        maxHours: clientEngagementRate.maxHours,
        hoursPeriodType: clientEngagementRate.hoursPeriodType,

        duration: clientEngagementRate.duration,
        durationType: clientEngagementRate.durationType,

        rateDisplayText: clientEngagementRate.rateDisplayText,
        autoGenerateDisplayText: clientEngagementRate.autoGenerateDisplayText,
        showDuration: clientEngagementRate.showDuration,
        showHoursPeriod: clientEngagementRate.showHoursPeriod,
      },
    });

    const handleAddInvitation = () => {
      setIsAddInProgress(true);
      postEngagementInvitation(engagement?.uuid).then(
        (response) => {
          const newInvitation = getNewObjectWithDefaults(response);
          setInvitations([...invitations, newInvitation]);
          setIsAddInProgress(false);
          setIsSaving(false);
        },
        (error) => {
          setIsSaving(false);
        }
      );
    };

    const handleAddApplication = () => {
      postEngagementApplication(engagement?.uuid).then(
        (response) => {
          const newApplication = getNewObjectWithDefaults(response);
          setApplications([...applications, newApplication]);
          setIsSaving(false);
        },
        (error) => {
          setIsSaving(false);
        }
      );
    };

    const handleAddAssignment = () => {
      postEngagementAssignment(engagement?.uuid).then(
        (response) => {
          const newAssignemnt = getNewObjectWithDefaults(response);
          setAssignments([...assignments, newAssignemnt]);
          setIsSaving(false);
        },
        (error) => {
          setIsSaving(false);
        }
      );
    };

    const handleInvitationDelete = useCallback(
      (invitationId) => {
        setInvitations(invitations.filter((invitation) => invitation.id !== invitationId));
      },
      [invitations]
    );

    const handleAssignmentDelete = useCallback(
      (assignmentUuid) => {
        setAssignments(assignments.filter((assignment) => assignment?.uuid !== assignmentUuid));
      },
      [assignments]
    );

    const handleAssignmentUpdate = useCallback(
      (data) => {
        setAssignments(
          assignments.map((item) =>
            (item.id || item.uuid) === (data.id || data.uuid) ? data : item
          )
        );
      },
      [assignments]
    );

    const handleApplicationUpdate = useCallback(
      (data) => {
        setApplications(
          applications.map((item) =>
            (item.id || item.uuid) === (data.id || data.uuid) ? data : item
          )
        );
      },
      [applications]
    );

    const handleInvitationUpdate = useCallback(
      (data) => {
        setInvitations(
          invitations.map((item) =>
            (item.id || item.uuid) === (data.id || data.uuid) ? data : item
          )
        );
      },
      [invitations]
    );

    const handleEngagementDelete = () => {
      deleteEngagement(engagement?.uuid).then(
        (response) => {
          if (onEngagementDelete) {
            onEngagementDelete(engagement?.uuid);
          }
        },
        (error) => {
          setIsSaving(false);
        }
      );
    };

    const handleCreateSkill = (value) => {
      const data = { label: value, adminGenerated: true };
      postSkill(data).then((response) => {
        setSkills([...skills, response.data]);
        handleAutoSave(data);
      });
    };

    const handleImmediateSave = () => {
      clearTimeout(autoSaveTimeout);
      autoSaveTimeout = setTimeout(() => submit(), 0);
    };

    const handleAutoSave = () => {
      clearTimeout(autoSaveTimeout);
      autoSaveTimeout = setTimeout(() => submit(), 2000);
    };

    const navigateTabLocation = (tab) => {
      setCurrentTab(tab);
    };

    return (
      <div>
        <div className={classNames(Styles.engagementItem)} style={{ zIndex: 1000 / (index + 1) }}>
          <div
            id={`eng-${uuid}`}
            className={classNames(propsLocation.hash === `#eng-${uuid}` && Styles.highlightItem)}>
            <div
              style={{ zIndex: 3 }}
              className={classNames(
                Styles.row,
                Styles.sectionHeader,
                propsLocation.hash === `#eng-${uuid}` && Styles.highlightItem
              )}>
              <h3
                className={classNames(PageStyles.headline)}>{`Engagement: ${generateEngagementTitle(
                engagement
              )}`}</h3>
              <div className={Styles.row} style={{ marginLeft: 'auto' }}>
                <Button
                  type={Button.Type.BLUE_OUTLINE}
                  onClick={handleAddInvitation}
                  isLoading={isAddInProgress}>
                  {`Add Invitation`}
                </Button>

                {/* <Button
                  className={Styles.saveButton}
                  type={Button.Type.BLUE}
                  onClick={handleImmediateSave}
                  isLoading={isSaving}>
                  {`Save Engagement`}
                </Button>

                <Dropdown size='sm' drop='down' alignRight>
                  <Dropdown.Toggle className={Styles.deleteDropdown}>
                    <IconMoreVertical className={Styles.moreIcon} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {currentUser && isPlacementAdmin(currentUser) && (
                      <Dropdown.Item onClick={handleAddRecommendedInvitations}>
                        {`Generate Invitations`}
                      </Dropdown.Item>
                    )}
                    {currentUser && isPlacementAdmin(currentUser) && (
                      <Dropdown.Item onClick={handleRemoveRecommendedInvitations}>
                        {`Remove Generated Invitations`}
                      </Dropdown.Item>
                    )}

                    {currentUser && isPlacementAdmin(currentUser) && (
                      <Dropdown.Item
                        onClick={handleAddInvitation}>{`Add Invitation`}</Dropdown.Item>
                    )}

                    {currentUser && isPlacementAdmin(currentUser) && (
                      <Dropdown.Item onClick={handleAddApplication}>
                        {`Add Application`}
                      </Dropdown.Item>
                    )}

                    {currentUser && isPlacementAdmin(currentUser) && (
                      <Dropdown.Item
                        onClick={handleAddAssignment}>{`Add Assignment`}</Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={handleEngagementDelete}>
                      {`Delete Engagement`}
                    </Dropdown.Item>

                    {currentUser &&
                      isPlacementAdmin(currentUser) &&
                      status >= EngagementStatus.MATCH.id && (
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.APP_ENDPOINT}/briefs/${briefUuid}/engagements/`}>
                          {`View Engagement in App`}
                        </Dropdown.Item>
                      )}

                    <Dropdown.Item
                      target='_blank'
                      href={`${process.env.MARKETING_ENDPOINT}/engagements/${engagement?.slug}/`}>
                      {`View Public Engagement`}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <SplitButton
                  type={Button.Type.BLUE}
                  onClick={handleImmediateSave}
                  isLoading={isSaving}
                  style={{ marginLeft: '10px' }}
                  more={[
                    ...(currentUser &&
                      isPlacementAdmin(currentUser) && [
                        { text: 'Generate Invitations', onClick: handleAddRecommendedInvitations },
                        {
                          text: 'Remove Generated Invitation',
                          onClick: handleRemoveRecommendedInvitations,
                        },
                        { text: 'Add Invitation', onClick: handleAddInvitation },
                        { text: 'Add Application', onClick: handleAddApplication },
                        { text: 'Add Assignment', onClick: handleAddAssignment },
                      ]),
                    { text: 'Delete Engagement', onClick: handleEngagementDelete },
                    {
                      ...(currentUser &&
                        isPlacementAdmin(currentUser) &&
                        status >= EngagementStatus.MATCH.id && {
                          text: 'View Engament in App',
                          as: 'a',
                          target: '_blank',
                          href: `${process.env.APP_ENDPOINT}/briefs/${briefUuid}/engagements/`,
                        }),
                    },
                    {
                      text: 'View Public Engagement',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/engagements/${engagement?.slug}/`,
                    },
                  ]}>
                  {`Save Engagement`}
                </SplitButton>
              </div>
            </div>
            {currentUser &&
              (isPlacementAdmin(currentUser) ||
                currentUser.adminPermission.engagementClientRate ||
                currentUser.adminPermission.engagementTalentRate) && (
                <div className={classNames(PageStyles.adminContainer, PageStyles.adminNested)}>
                  {currentUser.adminPermission.engagementClientRate && !isNewRates && (
                    <InputGroup>
                      <InputContainer>
                        <TextInput
                          name='estimatedBudget'
                          label='Estimated Engagement Budget'
                          type='number'
                          step='.01'
                          defaultValue={estimatedBudget}
                          onChange={(e) => setEstimatedBudget(e.target.value)}
                        />
                      </InputContainer>

                      <InputContainer>
                        <Select
                          data-test-id='currencyCode'
                          className={PageStyles.select}
                          defaultValue={Currency.getEnum(currencyCode)}
                          classNamePrefix='s-contact'
                          name='currencyCode'
                          label='Currency Code'
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={Currency.values}
                          onChange={(e) => setCurrencyCode(e.target.value?.id)}
                        />
                      </InputContainer>
                    </InputGroup>
                  )}

                  {/* visible for Brief Admin, Brief Placement Admin, Admin */}
                  {currentUser &&
                    !isNewRates &&
                    (isPlacementAdmin(currentUser) ||
                      currentUser.adminPermission.engagementTalentRate) && (
                      <InputGroup>
                        <InputContainer>
                          <DualInput
                            input={(Input) => (
                              <Input
                                name='estimatedTalentRate'
                                label='Talent Rate (SGD)'
                                type='number'
                                step='.01'
                                defaultValue={estimatedTalentRate}
                                onChange={(e) => setEstimatedTalentRate(e.target.value)}></Input>
                            )}
                            select={(Select) => (
                              <Select
                                id='estimatedTalentRateTypeID'
                                name='estimatedTalentRateType'
                                label='Talent Rate Type'
                                style={{ flexBasis: '115px' }}
                                defaultValue={RateType.getEnum(estimatedTalentRateType)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                options={RateType.values}
                                onChange={(e) =>
                                  setEstimatedTalentRateType(e.target.value?.id)
                                }></Select>
                            )}></DualInput>
                        </InputContainer>
                        <InputContainer>
                          <DualInput
                            input={(Input) => (
                              <Input
                                name='talentMinHours'
                                label='Min Talent Hours'
                                type='number'
                                step='.01'
                                defaultValue={talentMinHours}
                                onChange={(e) => setTalentMinHours(e.target.value)}></Input>
                            )}
                            select={(Select) => (
                              <Select
                                id='talentMinPerID'
                                name='talentMinPer'
                                label='Per'
                                style={{ flexBasis: '130px' }}
                                defaultValue={TimeRate.getEnum(talentMinPer)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                options={TimeRate.values}
                                onChange={(e) => setTalentMinPer(e.target.value?.id)}></Select>
                            )}></DualInput>
                        </InputContainer>
                        <InputContainer>
                          <DualInput
                            input={(Input) => (
                              <Input
                                name='talentMaxHours'
                                label='Max Talent Hours'
                                type='number'
                                step='.01'
                                defaultValue={talentMaxHours}
                                onChange={(e) => setTalentMaxHours(e.target.value)}></Input>
                            )}
                            select={(Select) => (
                              <Select
                                id='talentMaxPerID'
                                name='talentMaxPer'
                                label='Per'
                                style={{ flexBasis: '130px' }}
                                defaultValue={TimeRate.getEnum(talentMaxPer)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                options={TimeRate.values}
                                onChange={(e) => setTalentMaxPer(e.target.value?.id)}></Select>
                            )}></DualInput>
                        </InputContainer>
                      </InputGroup>
                    )}

                  {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles except Brief Placement Admin */}
                  {currentUser &&
                    !isNewRates &&
                    (isSalesAdmin(currentUser) ||
                      currentUser.adminPermission.engagementClientRate) && (
                      <InputGroup>
                        <InputContainer>
                          <DualInput
                            input={(Input) => (
                              <Input
                                name='estimatedBrandRate'
                                label='Client Rate (SGD)'
                                type='number'
                                step='.01'
                                defaultValue={estimatedBrandRate}
                                onChange={(e) => setEstimatedBrandRate(e.target.value)}></Input>
                            )}
                            select={(Select) => (
                              <Select
                                name='estimatedBrandRateType'
                                label='Client Rate Type'
                                style={{ flexBasis: '115px' }}
                                defaultValue={RateType.getEnum(estimatedBrandRateType)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                options={RateType.values}
                                onChange={(e) =>
                                  setEstimatedBrandRateType(e.target.value?.id)
                                }></Select>
                            )}></DualInput>
                        </InputContainer>
                        <InputContainer>
                          <DualInput
                            input={(Input) => (
                              <Input
                                name='brandMinHours'
                                label='Min Client Hours'
                                type='number'
                                step='.01'
                                defaultValue={brandMinHours}
                                onChange={(e) => setBrandMinHours(e.target.value)}></Input>
                            )}
                            select={(Select) => (
                              <Select
                                id='brandMinPerID'
                                name='brandMinPer'
                                label='Per'
                                style={{ flexBasis: '130px' }}
                                defaultValue={TimeRate.getEnum(brandMinPer)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                options={TimeRate.values}
                                onChange={(e) => setBrandMinPer(e.target.value?.id)}></Select>
                            )}></DualInput>
                        </InputContainer>
                        <InputContainer>
                          <DualInput
                            input={(Input) => (
                              <Input
                                name='brandMaxHours'
                                label='Max Client Hours'
                                type='number'
                                step='.01'
                                defaultValue={brandMaxHours}
                                onChange={(e) => setBrandMaxHours(e.target.value)}></Input>
                            )}
                            select={(Select) => (
                              <Select
                                id='brandMaxPerID'
                                name='brandMaxPer'
                                label='Per'
                                style={{ flexBasis: '130px' }}
                                defaultValue={TimeRate.getEnum(brandMaxPer)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                options={TimeRate.values}
                                onChange={(e) => setBrandMaxPer(e.target.value?.id)}></Select>
                            )}></DualInput>
                        </InputContainer>
                      </InputGroup>
                    )}

                  {/* visible for Brief Admin, Brief Placement Admin, Admin */}
                  {currentUser &&
                    isNewRates &&
                    (isPlacementAdmin(currentUser) ||
                      currentUser.adminPermission.engagementTalentRate) && (
                      <RateInputGroup
                        rate={[talentEngagementRate, setTalentEngagementRate]}
                        resource={'engagement'}
                        resourceUuid={engagement.uuid}
                      />
                    )}

                  {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles except Brief Placement Admin */}
                  {currentUser &&
                    isNewRates &&
                    (isSalesAdmin(currentUser) ||
                      currentUser.adminPermission.engagementClientRate) && (
                      <RateInputGroup
                        isClient
                        rate={[clientEngagementRate, setClientEngagementRate]}
                        resource={'engagement'}
                        resourceUuid={engagement.uuid}
                      />
                    )}
                </div>
              )}
            <div className={Styles.sectionBody}>
              <Tab.Container defaultActiveKey='details' activeKey={currentTab} mountOnEnter={true}>
                <Nav variant='tabs' className={classNames(Styles.row, Styles.headlineRow)}>
                  <Nav.Item>
                    <Nav.Link
                      eventKey='details'
                      onClick={() => {
                        navigateTabLocation('details');
                      }}>
                      <h3 className={classNames(PageStyles.headline)}>{`Engagement`}</h3>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey='client'
                      onClick={() => {
                        navigateTabLocation('client');
                      }}>
                      <h3 className={classNames(PageStyles.headline)}>{`Client Details`}</h3>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey='type'
                      onClick={() => {
                        navigateTabLocation('type');
                      }}>
                      <h3 className={classNames(PageStyles.headline)}>{`Talent Type`}</h3>
                    </Nav.Link>
                  </Nav.Item>
                  <Action>
                    <a
                      href={`${process.env.MARKETING_ENDPOINT}/engagements/${engagement.slug}`}
                      style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
                      target='_blank'>
                      {`View Public Engagement`}
                    </a>
                  </Action>
                </Nav>
                <Tab.Content className={PageStyles.tabContent}>
                  <Tab.Pane eventKey='details'>
                    <div className={Styles.leftRightContainer}>
                      <div className={Styles.leftFields}>
                        <InputContainer>
                          <TextInput
                            name='title'
                            label='Title'
                            defaultValue={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer style={{ zIndex: 0 }}>
                          <Quill
                            name='responsibilities'
                            label='Responsibilities'
                            placeholder='Responsibilities'
                            defaultValue={responsibilities}
                            onChange={(e) => setResponsibilities(e.target.value)}
                            style={{ minHeight: '126px' }}
                            legacyCompat
                          />
                        </InputContainer>

                        <InputContainer>
                          <Quill
                            name='requirements'
                            label='Requirements'
                            placeholder='Requirements'
                            defaultValue={requirements}
                            onChange={(e) => setRequirements(e.target.value)}
                            style={{ minHeight: '126px' }}
                            legacyCompat
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            value={skills}
                            classNamePrefix='s-contact'
                            name='skills'
                            label='Skills'
                            isClearable={false}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={skills}
                            isMulti
                            onChange={(e) => setSkills(e.target.value)}
                            isPaginated
                            isCreatable
                            onCreateOption={handleCreateSkill}
                            loadOptions={onSkillsLoadOptions}
                          />
                        </InputContainer>

                        <InputGroup>
                          {currentUser && isPlacementAdmin(currentUser) && (
                            <InputContainer style={{ zIndex: 0 }}>
                              <Quill
                                name='talentTerms'
                                label='Talent Terms'
                                placeholder='Talent Terms'
                                defaultValue={talentTerms}
                                onChange={(e) => setTalentTerms(e.target.value)}
                                style={{ minHeight: '252px' }}
                                legacyCompat
                              />
                            </InputContainer>
                          )}

                          {currentUser && isSalesAdmin(currentUser) && (
                            <InputContainer style={{ zIndex: 0 }}>
                              <Quill
                                name='clientTerms'
                                label='Client Terms'
                                placeholder='Client Terms'
                                defaultValue={clientTerms}
                                onChange={(e) => setClientTerms(e.target.value)}
                                style={{ minHeight: '252px' }}
                                legacyCompat
                              />
                            </InputContainer>
                          )}
                        </InputGroup>
                      </div>

                      <div className={Styles.rightFields}>
                        <InputContainer>
                          <TextInput
                            name='slug'
                            label='Slug'
                            defaultValue={slug}
                            onChange={(e) => setSlug(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            value={VisibilityOption.getEnum(visibility)}
                            classNamePrefix='s-contact'
                            name='visibility'
                            label='Visibility'
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={VisibilityOption.values}
                            onChange={(e) => setVisibility(e.target.value?.id)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            defaultValue={EngagementStatus.getEnum(status)}
                            classNamePrefix='s-contact'
                            name='status'
                            label='Status'
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={EngagementStatus.values}
                            onChange={(e) => setStatus(e.target.value?.id)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            defaultValue={languages}
                            classNamePrefix='s-contact'
                            name='languages'
                            label='Language'
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            options={languageList}
                            isMulti
                            onChange={(e) => setLanguages(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            defaultValue={countries}
                            classNamePrefix='s-contact'
                            name='countries'
                            label='Country'
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            options={countryList}
                            isMulti
                            onChange={(e) => setCountries(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <TextInput
                            name='location'
                            label='Location (if on-site)'
                            defaultValue={location}
                            onChange={(e) => setLocation(e.target.value)}
                          />
                        </InputContainer>
                        <InputGroup>
                          <InputContainer>
                            <DatePicker
                              id='engagementEstimatedStartDate'
                              name='estimatedStartDate'
                              label='Start Date'
                              value={estimatedStartDate}
                              onChange={setEstimatedStartDate}
                              error={errors?.estimatedStartDate}
                            />
                          </InputContainer>

                          <InputContainer>
                            <DatePicker
                              id='engagementEstimatedEndDate'
                              name='estimatedEndDate'
                              label='End Date'
                              value={estimatedEndDate}
                              onChange={setEstimatedEndDate}
                              // minDate={
                              //   this.state.estimatedStartDate
                              //     ? new Date(this.state.estimatedStartDate)
                              //     : undefined
                              // }
                            />
                          </InputContainer>
                        </InputGroup>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey='client'>
                    <div className={Styles.leftRightContainer}>
                      <div className={Styles.leftFields}>
                        <InputContainer>
                          <TextInput
                            name='clientName'
                            label='Client Name'
                            defaultValue={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer style={{ zIndex: 0 }}>
                          <Quill
                            name='clientDetails'
                            label='Client Details'
                            placeholder='Client Details'
                            defaultValue={clientDetails}
                            onChange={(e) => setClientDetails(e.target.value)}
                            style={{ minHeight: '126px' }}
                            legacyCompat
                          />
                        </InputContainer>

                        <InputContainer>
                          <TextInput
                            name='confidentialClientName'
                            label='Confidential Client Name'
                            defaultValue={confidentialClientName}
                            onChange={(e) => setConfidentialClientName(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer style={{ zIndex: 0 }}>
                          <Quill
                            name='confidentialClientDetails'
                            label='Confidential Client Details'
                            placeholder='Confidential Client Details'
                            defaultValue={confidentialClientDetails}
                            onChange={(e) => setConfidentialClientDetails(e.target.value)}
                            style={{ minHeight: '126px' }}
                            legacyCompat
                          />
                        </InputContainer>
                      </div>

                      <div className={Styles.rightFields}>
                        <InputContainer>
                          <TextInput
                            name='slug'
                            label='Slug'
                            defaultValue={slug}
                            onChange={(e) => setSlug(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            data-test-id='visibility'
                            className={PageStyles.select}
                            value={VisibilityOption.getEnum(visibility)}
                            classNamePrefix='s-contact'
                            name='visibility'
                            label='Visibility'
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={VisibilityOption.values}
                            onChange={(e) => setVisibility(e.target.value?.id)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            value={EngagementStatus.getEnum(status)}
                            classNamePrefix='s-contact'
                            name='status'
                            label='Status'
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={EngagementStatus.values}
                            onChange={(e) => setStatus(e.target.value?.id)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            defaultValue={languages}
                            classNamePrefix='s-contact'
                            name='languages'
                            label='Language'
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            options={languageList}
                            isMulti
                            onChange={(e) => setLanguages(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Select
                            className={PageStyles.select}
                            defaultValue={countries}
                            classNamePrefix='s-contact'
                            name='countries'
                            label='Country'
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            options={countryList}
                            isMulti
                            onChange={(e) => setCountries(e.target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <TextInput
                            name='location'
                            label='Location (if on-site)'
                            defaultValue={location}
                            onChange={(e) => setLocation(e.target.value)}
                          />
                        </InputContainer>
                        <InputGroup>
                          <InputContainer>
                            <DatePicker
                              id='engagementEstimatedStartDate2'
                              name='estimatedStartDate'
                              label='Start Date'
                              value={estimatedStartDate}
                              onChange={(e) => setEstimatedStartDate(e.target.value)}
                              error={errors?.estimatedStartDate}
                            />
                          </InputContainer>

                          <InputContainer>
                            <DatePicker
                              id='engagementEstimatedEndDate2'
                              name='estimatedEndDate'
                              label='End Date'
                              value={estimatedEndDate}
                              onChange={(e) => setEstimatedEndDate(e.target.value)}
                            />
                          </InputContainer>
                        </InputGroup>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey='type'>
                    <InputGroup>
                      <InputContainer>
                        <Select
                          data-test-id='talentType'
                          className={PageStyles.select}
                          defaultValue={talentType}
                          classNamePrefix='s-contact'
                          name='talentType'
                          label='Talent Type'
                          isClearable
                          isPaginated
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          onChange={(e) => setTalentType(e.target.value)}
                          loadOptions={onTalentTypesLoadOptions}
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextInput
                          name='otherTalentType'
                          label='Other Talent Type'
                          type='text'
                          defaultValue={otherTalentType}
                          onChange={(e) => setOtherTalentType(e.target.value)}
                        />
                      </InputContainer>
                    </InputGroup>
                    <InputGroup>
                      <InputContainer>
                        <Select
                          className={PageStyles.select}
                          defaultValue={format}
                          classNamePrefix='s-contact'
                          name='format'
                          label='Format'
                          isClearable
                          isPaginated
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          onChange={(e) => setFormat(e.target.value)}
                          loadOptions={onFormatLoadOptions}
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextInput
                          name='otherFormat'
                          label='Other Format'
                          type='text'
                          defaultValue={otherFormat}
                          onChange={(e) => setOtherFormat(e.target.value)}
                        />
                      </InputContainer>
                    </InputGroup>

                    <InputContainer>
                      <TextInput
                        name='formatQty'
                        label='Format Qty'
                        type='number'
                        defaultValue={formatQty}
                        onChange={(e) => setFormatQty(e.target.value)}
                      />
                    </InputContainer>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            {/*<hr />*/}
          </div>

          {isMounted &&
            (isAnyBriefAdmin(currentUser) || currentUser.adminPermission.invitation) &&
            invitations?.map((invitation, index) => (
              <Suspense key={invitation.id} fallback={<p style={{ height: 200 }}>loading...</p>}>
                <InvitationDetailList
                  currentUser={currentUser}
                  engagementUuid={engagement?.uuid}
                  invitation={invitation}
                  onInvitationDelete={handleInvitationDelete}
                  onInvitationSave={handleInvitationUpdate}
                  index={1000 / (index + 1) - 30 - index}
                  location={propsLocation}
                  history={history}
                />
              </Suspense>
            ))}

          {isMounted &&
            (isAnyBriefAdmin(currentUser) || currentUser.adminPermission.application) &&
            applications?.map((application, index) => (
              <Suspense
                key={application?.uuid}
                fallback={<p style={{ height: 200 }}>loading...</p>}>
                <ApplicationDetailList
                  currentUser={currentUser}
                  engagementUuid={engagement?.uuid}
                  application={application}
                  onApplicationDelete={handleApplicationDelete}
                  onApplicationSave={handleApplicationUpdate}
                  index={1000 / (index + 1) - 20 - index}
                  location={propsLocation}
                  history={history}
                />
              </Suspense>
            ))}

          {isMounted &&
            (isAnyBriefAdmin(currentUser) || currentUser.adminPermission.assignment) &&
            assignments?.map((assignment, index) => (
              <Suspense key={assignment?.uuid} fallback={<p style={{ height: 200 }}>loading...</p>}>
                <AssignmentDetailList
                  currentUser={currentUser}
                  engagementUuid={engagement?.uuid}
                  assignment={assignment}
                  onAssignmentDelete={handleAssignmentDelete}
                  onAssignmentSave={handleAssignmentUpdate}
                  index={1000 / (index + 1) - 40 - index}
                  location={propsLocation}
                  history={history}
                />
              </Suspense>
            ))}
        </div>
      </div>
    );
  }
);

export default EngagementDetailList;
