import { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import Card from '@hiredigital/ui/Card';
import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';
import Select from '@hiredigital/ui/Input/Select';

import AppLayout from '@components/Layout/AppLayout';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import { setPageTitle, addHttpPrefix } from '@hiredigital/lib/helpers/utils';
import { OrganizationType, Site } from '@hiredigital/lib/helpers/enum';
import withEmailLabel from '@hoc/withEmailLabel';
import { onUsersLoadOptions, onOrganizationLoadOptions } from '@apis/dropdown';
import { getOrgRegistration, putOrgRegistration, convertClientRegistration } from '@apis/orgs';

import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

import { countryList } from '@data/countryList';

const UserSelect = withEmailLabel(Select);

class OrgRegistrationDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      orgUuid: this.props.match.params.orgUuid,
      userFirstName: null,
      userLastName: null,
      phoneNumber: null,
      userEmail: null,
      requirements: null,
      name: null,
      promotion: null,
      referral: null,
      type: null,
      site: null,
      uuid: null,
      created: null,
      phoneCountry: null,
      errors: {},
      autoSaveTimeout: null,
      isSaving: false,
      hasInitLoad: false,
      isPhoneValid: false,
    };
  }

  componentDidMount = () => {
    getOrgRegistration(this.state.orgUuid).then(({ data }) => {
      setPageTitle(`Client Registrations | ${data.name || 'New Client Registration'}`);
      this.populateFields(data, () => {
        this.setState({ hasInitLoad: true });
      });
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.isSaving !== this.state.isSaving
    ) {
      return true;
    }

    return false;
  }

  populateFields = (data, completionCallback) => {
    this.setState(
      {
        ...data,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };
  handlePhoneNumberChange = (value) => {
    this.setState({ phoneNumber: value }, this.handleAutoSave);
  };
  handlephoneCountryChange = (value) => {
    this.setState({ phoneCountry: value }, this.handleAutoSave);
  };
  handleChange = (event, valueKey, completionCallback) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: valueKey ? value[valueKey] : value,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
    this.handleAutoSave();
  };

  handleImmediateSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 0),
    });
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleSubmit = async (storeResponse = true) => {
    this.setState({ errors: {}, isSaving: true });

    try {
      const {
        name,
        phoneNumber,
        userEmail,
        requirements,
        userFirstName,
        userLastName,
        promotion,
        website,
        phoneCountry,
        organization,
        user,
        type,
        site,
        created,
        referral,
      } = this.state;

      const data = {
        name,
        phoneNumber,
        userEmail,
        requirements,
        userFirstName,
        userLastName,
        promotion,
        website,
        phoneCountry: (phoneCountry && phoneCountry.id) || null,
        organization: (organization && organization.uuid) || null,
        user: (user && user.uuid) || null,
        type,
        site,
        created,
        referral,
      };

      const response = await putOrgRegistration(this.state.orgUuid, data);
      this.setState({ autoSaveTimeout: null });

      if (storeResponse) {
        this.populateFields(response);
      }
    } catch (error) {
      const errors = error.response.data;
      let stateErrors;
      Object.keys(errors).forEach((key) => {
        stateErrors = { ...stateErrors, [key]: errors[key][0] };
      });
      this.setState({
        autoSaveTimeout: null,
        errors: stateErrors,
      });
    } finally {
      setTimeout(() => this.setState({ isSaving: false }), 250);
    }
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleSocialChange = (event, prefix, removeBaseUrl = true) => {
    const { name, value } = event.target;
    const link =
      (removeBaseUrl
        ? value && value.replace(prefix, '')
        : value && addHttpPrefix(value, prefix)) || '';
    this.setState({ [name]: link });
    this.handleAutoSave();
  };

  handleConvertOrganization = () => {
    this.setState({ isConverting: true });
    convertClientRegistration(this.state.uuid)
      .then(({ data }) => {
        if (data?.organization?.uuid) {
          this.setState({ isConverting: false });
          setTimeout(() => {
            this.props.history.push('/clients/' + data.organization.uuid);
          }, 500);
        }
      })
      .catch((error) => {
        this.setState({ isConverting: false });
      });
  };

  // handleDateChange = (date, name) => {
  //   this.setState({
  //     [name]: date ? date.format() : null,
  //   });
  // };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/org-registrations'>
              {`Client Registrations`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            {this.state.hasInitLoad && (
              <div className={PageStyles.headerActive}>{this.state.name || 'Untitled'}</div>
            )}
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <Card>
              <Card.Header>
                Client Registration
                <div className={Styles.otherLinks}>
                  {this.state.user && this.state.user.name && (
                    <Link className={Styles.otherLink} to={`/profiles/${this.state.user.uuid}`}>
                      {`View ${this.state.user.name}`}
                    </Link>
                  )}
                  {this.state.organization && this.state.organization.name && (
                    <Link
                      className={Styles.otherLink}
                      to={`/clients/${this.state.organization.uuid}`}>
                      {`View ${this.state.organization.name}`}
                    </Link>
                  )}
                </div>
              </Card.Header>
              <Card.Item>
                <div className={PageStyles.leftRightContainer}>
                  <div className={PageStyles.leftFields}>
                    <InputGroup>
                      <InputContainer>
                        <TextInput
                          name='name'
                          label='Client Name'
                          defaultValue={this.state.name}
                          placeholder='Client Name'
                          onChange={this.handleChange}
                          error={this.state.errors.name}
                        />
                      </InputContainer>
                      <InputContainer>
                        <Select
                          className={PageStyles.select}
                          defaultValue={this.state.organization}
                          classNamePrefix='s-contact'
                          data-test-id='organization'
                          name='organization'
                          label='Linked Organization'
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ uuid }) => uuid}
                          onChange={this.handleChange}
                          loadOptions={onOrganizationLoadOptions}
                          isPaginated
                        />
                      </InputContainer>
                    </InputGroup>
                    <InputGroup>
                      <InputContainer>
                        <TextInput
                          name='userFirstName'
                          label='First Name'
                          defaultValue={this.state.userFirstName}
                          placeholder='First Name'
                          onChange={this.handleChange}
                          error={this.state.errors.userFirstName}
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextInput
                          name='userLastName'
                          label='Last Name'
                          defaultValue={this.state.userLastName}
                          placeholder='Last Name'
                          onChange={this.handleChange}
                          error={this.state.errors.userLastName}
                        />
                      </InputContainer>
                      <InputContainer>
                        <UserSelect
                          id='userSelect'
                          style={{ width: '50%' }}
                          className={Styles.select}
                          defaultValue={this.state.user}
                          name='user'
                          label='Linked User'
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ uuid }) => uuid}
                          options={this.state.users}
                          onChange={this.handleChange}
                          isPaginated
                          loadOptions={onUsersLoadOptions}
                        />
                      </InputContainer>
                    </InputGroup>
                    <InputGroup>
                      <InputContainer className={classNames(Styles.phoneGroup)}>
                        <PhoneNumber
                          phoneCountry={this.state.phoneCountry}
                          phoneNumber={this.state.phoneNumber}
                          onPhoneCountryChange={this.handlephoneCountryChange}
                          onPhoneNumberChange={this.handlePhoneNumberChange}
                          getCountries={async () => ({ data: countryList })}
                          onValidate={(v) => this.setState({ isPhoneValid: v })}
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextInput
                          name='userEmail'
                          label='Email'
                          defaultValue={this.state.userEmail}
                          placeholder='Email'
                          onChange={this.handleChange}
                          error={this.state.errors.userEmail}
                        />
                      </InputContainer>
                    </InputGroup>

                    <InputGroup>
                      <InputContainer>
                        <TextArea
                          name='requirements'
                          label='Requirements'
                          defaultValue={this.state.requirements}
                          placeholder='Requirements'
                          onChange={this.handleChange}
                        />
                      </InputContainer>
                    </InputGroup>
                  </div>
                  <div className={PageStyles.rightFields}>
                    {/* <InputContainer>
                      <Select
                        className={Styles.select}
                        value={this.state.status}
                        classNamePrefix="s-contact"
                        name="status"
                        label="Status"
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={[]}
                        onChange={this.handleChange}
                      />
                    </div> */}
                    <InputContainer>
                      <TextInput
                        name='uuid'
                        label='Registration Uuid'
                        defaultValue={this.state.uuid}
                        onChange={this.handleChange}
                        error={this.state.errors.uuid}
                        disabled
                      />
                    </InputContainer>
                    <InputContainer>
                      <Select
                        data-test-id='site'
                        className={Styles.select}
                        defaultValue={Site.getEnum(this.state.site)}
                        classNamePrefix='s-contact'
                        name='site'
                        label='Site'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={Site.values}
                        onChange={(e) => this.handleChange(e, 'id')}
                      />
                    </InputContainer>
                    <InputContainer>
                      <TextInput
                        name='promotion'
                        label='Promotion'
                        defaultValue={this.state.promotion}
                        placeholder='Promotion'
                        onChange={this.handleChange}
                        error={this.state.errors.promotion}
                      />
                    </InputContainer>
                    {/*<InputContainer>
                  <TextInput
                    name='referral'
                    label='Referral'
                    defaultValue={this.state.referral}
                    error={this.state.errors.referral}
                    onChange={this.handleChange}
                  />
                </InputContainer>*/}
                    <InputContainer>
                      <Select
                        data-test-id='type'
                        className={Styles.select}
                        defaultValue={OrganizationType.getEnum(this.state.type)}
                        classNamePrefix='s-contact'
                        name='type'
                        label='Type'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={OrganizationType.values}
                        onChange={(e) => this.handleChange(e, 'id')}
                      />
                    </InputContainer>
                    <InputContainer>
                      {/* <DateSelector
                    className={Styles.dateInput}
                    name='created'
                    label='Created'
                    value={this.state.created}
                    onChange={(date) => this.handleDateChange(date, 'created')}
                    locked
                  /> */}
                      <DatePicker
                        id='org-regCreated'
                        name='created'
                        label='Created'
                        value={this.state.created}
                        onChange={(date) => this.handleDateChange(date, 'created')}
                      />
                    </InputContainer>
                  </div>
                </div>
              </Card.Item>

              <Card.Footer right sticky>
                <Button
                  name='convert'
                  className={Styles.convertButton}
                  type={Button.Type.BLUE_OUTLINE}
                  onClick={this.handleConvertOrganization}
                  isLoading={this.state.isConverting}>
                  {`Convert to Client`}
                </Button>

                <Button
                  name='submit'
                  className={Styles.saveButton}
                  type={Button.Type.BLUE}
                  onClick={this.handleImmediateSave}
                  isLoading={this.state.isSaving}>
                  {`Save`}
                </Button>
              </Card.Footer>
            </Card>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default OrgRegistrationDetail;
