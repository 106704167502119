import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import SplitButton from '@hiredigital/ui/Button/SplitButton';

import Card from '@hiredigital/ui/Card';
import Loader from '@hiredigital/ui/Loader';
import { copyTextToClipboard } from '@hiredigital/lib/helpers/utils';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { AgreementStatus } from '@hiredigital/lib/helpers/enum';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from './Styles.module.scss';

import {
  getAgreements,
  generateAgreement,
  resetAgreement,
  deleteAgreement,
  viewAgreement,
  getAgreementLink,
  resendAgreement,
  getBoldAgreements,
  getBoldAgreement,
  generateBoldAgreement,
  getBoldAgreementLink,
  downloadBoldAgreement,
  getBoldDownloadLink,
} from '@apis/agreements';

const columns = [
  {
    Header: 'Agreement',
    id: 'messageTitle',
    minWidth: 300,
    Cell: ({ row }) => {
      return row.original.messageTitle;
    },
  },
  // {
  //   Header: 'Date Received',
  //   accessor: (d) => {
  //     if (!d.created) {
  //       return '';
  //     }
  //     return toReadableDate(d.created);
  //   },
  // },
  // {
  //   Header: 'Step',
  //   id: 'step',
  //   width: 75,
  //   Cell: ({ row }) => {
  //     return row.original.activityAction;
  //   },
  // },
  // {
  //   Header: 'Status',
  //   id: 'status',
  //   width: 150,
  //   Cell: ({ row }) => {
  //     return row.original.status;
  //   },
  // },
  // {
  //   Header: 'Actions',
  //   id: 'actions',
  //   width: 75,
  //   truncate: false,
  //   Cell: ({ row }) => {
  //     return (
  //       <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
  //         <SplitButton
  //           direction='left'
  //           more={[
  //             {
  //               text: 'Send Email Reminder',
  //               onClick: () => this.handleResendAgreement(row.original.documentId),
  //             },
  //             {
  //               text: 'Get Agreement Link',
  //               onClick: () => this.handleCopyAgreementLink(row.original),
  //             },
  //             {
  //               text: 'Delete Agreement',
  //               onClick: () => this.handleDeleteAgreement(row.original.documentId),
  //             },
  //           ]}
  //         />
  //       </div>
  //     );
  //   },
  // },
];

const AgreementList = ({
  note,
  resource = 'orgs',
  resourceUuId,
  children,
  docusignLink,
  currentUser = {},
  action,
}) => {
  const [agreements, setAgreements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasInitLoad, setHasInitLoad] = useState(false);

  useEffect(() => {
    getBoldAgreements(resource, resourceUuId).then(({ data }) => {
      setAgreements(data.results);
      setHasInitLoad(true);
      setIsDataLoaded(true);
    });
  }, []);

  const handleRefresh = () => {
    setIsLoading(true);
    setIsDataLoaded(false);
    getBoldAgreements(resource, resourceUuId).then(({ data }) => {
      setAgreements(data.results);
      setIsDataLoaded(true);
      setIsLoading(false);
      setHasInitLoad(true);
    });
  };

  const updateListData = async (config) => {
    const { data } = await getBoldAgreements(resource, resourceUuId, config);
    setAgreements(data.results);
    setIsDataLoaded(true);
    setIsLoading(false);
    setHasInitLoad(true);
    return data;
  };

  const handleGenerateAgreement = () => {
    setIsSaving(true);
    generateBoldAgreement(resource, resourceUuId)
      .then(({ data }) => {
        setAgreements([...agreements, data]);
        setIsSaving(false);
        setIsDataLoaded(false);
      })
      .catch((error) => {
        setIsSaving(false);
      })
      .finally(() => {
        handleRefresh();
        setIsDataLoaded(true);
      });
  };

  const handleResetAgreement = () => {
    resetAgreement(resource, resourceUuId).then(({ data }) => {
      setAgreements([]);
    });
  };

  const handleResendAgreement = async (agreementId) => {
    try {
      await resendAgreement(resource, resourceUuId, agreementId);
      toast.success('Email sent to pending signers.', { id: 'Resend' });
    } catch (e) {}
  };

  const handleCopyAgreementLink = async (agreement) => {
    try {
      setIsLoading(true);
      const { data } = await getBoldAgreementLink(resource, resourceUuId, agreement.documentId);
      console.log(data);
      if (data.signLink) {
        copyTextToClipboard(data.signLink);
        toast.success('Copied to clipboard.', { id: 'Copied' });
      }
    } catch (e) {
      toast.error('Link not found.', { id: 'Copied' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadAgreement = async (agreement) => {
    try {
      setIsLoading(true);
      const { data } = await downloadBoldAgreement(resource, resourceUuId, agreement.documentId);
      if (data.signLink) {
        copyTextToClipboard(data.signLink);
        toast.success('Copied to clipboard.', { id: 'Copied' });
      }
    } catch (e) {
      toast.error('Link not found.', { id: 'Copied' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAgreement = (agreementId) => {
    deleteAgreement(resource, resourceUuId, agreementId)
      .then(({ data }) => {
        setIsDataLoaded(false);
        setAgreements(agreements.filter(({ id }) => id !== agreementId) || []);
      })
      .finally(() => {
        setIsDataLoaded(true);
      });
  };

  const handleAgreementView = async (agreementId) => {
    await viewAgreement(resource, resourceUuId, agreementId);
  };

  const hasAgreements = agreements && !!agreements.length;
  return (
    <Card>
      <Card.Header>
        <>{`New Talent Agreements`}</>
        {isLoading && (
          <Loader style={{ margin: '-3px', marginLeft: '10px' }} size={Loader.Size.SMALL} />
        )}
        <Card.Action>
          <>
            {action}
            <SplitButton
              type={SplitButton.Type.WHITE}
              moreButtonClass={Styles.moreButton}
              more={[
                { text: 'Refresh List', onClick: handleRefresh },
                { text: 'Generate Agreement', onClick: handleGenerateAgreement },
              ]}
            />
          </>
        </Card.Action>
      </Card.Header>
      {note && <Card.Note>{note}</Card.Note>}
      {children}
      {agreements.map((a, i) => (
        <Card.Item
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          key={a.documentId}>
          <div>{a.messageTitle}</div>
          <div style={{ marginLeft: 'auto', marginRight: '10px' }}>{a.status}</div>
          <div style={{ margin: '0 10px' }}>{a?.created && toReadableDate(a.created)}</div>

          <div>
            <SplitButton
              direction='left'
              more={[
                {
                  text: 'Get Agreement Link',
                  onClick: () => handleCopyAgreementLink(a),
                },
                {
                  as: 'a',
                  text: 'Download Agreement',
                  href: getBoldDownloadLink(resource, resourceUuId, a.documentId),
                },
              ]}
            />
          </div>
        </Card.Item>
      ))}
    </Card>
  );
};

export default AgreementList;
