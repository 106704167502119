import { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip, Position } from 'evergreen-ui';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '@hiredigital/ui/Loader';
import Select from '@hiredigital/ui/Input/Select';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import debounce from 'lodash/debounce';
import isAfter from 'date-fns/isAfter';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';

import AppLayout from '@components/Layout/AppLayout';
import Button from '@hiredigital/ui/Button';

import { CancelToken } from '@apis/utils';
import { getOrg } from '@apis/orgs';
import { getUser } from '@apis/users';
import { getUserEvents, deleteEvent } from '@apis/tracking';
import { onTimetrackerUsersLoadOptions, onAllOrgsLoadOptions } from '@apis/dropdown';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { convertNumericToDuration, toISOExactDateTime } from '@hiredigital/lib/helpers/date';

import EntryListItem from './components/EntryListItem';
import TimesheetEntry from '@components/TimesheetEntry';

import withEmailLabel from '@hoc/withEmailLabel';
import { withUser } from '@context/user';

import ListStyles from '@styles/PageList.module.scss';
import Styles from './list.module.scss';

const UserSelect = withEmailLabel(Select);

let cancel;

class EventList extends Component {
  static propTypes = {
    location: PropTypes.object,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const query = queryString.parse(props.location.search);
    this.state = {
      isLoaded: false,
      isLoadingMore: false,
      nextPage: 1,
      events: [],
      hasMore: true,
      organization: null,
      search: query?.search || '',
      start: query?.start ? new Date(query.start) : startOfMonth(subMonths(new Date(), 1)),
      end: query?.end ? new Date(query.end) : endOfMonth(subMonths(new Date(), 1)),
      user: null,
      isDuration: true,
      isShownEntry: false,
      isShownConfirm: false,
      isDeleting: false,
    };
    this.performDebouncedSearch = debounce(this.searchevents, 750);
  }

  componentDidMount = () => {
    setPageTitle('Events');
    const promises = [];
    if (this.props.location.search) {
      const search = queryString.parse(this.props.location.search);
      promises.push(
        search.user ? getUser(search.user).then((response) => response.data) : undefined,
        search.organization
          ? getOrg(search.organization).then((response) => response.data)
          : undefined
      );
    }

    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    Promise.all(promises).then(([user, organization]) => {
      this.setState({ user, organization });
      this.getEvents({
        search: this.state.search,
        start, // start of day
        end, // end of day
        user: user ? user.uuid : null,
        organization: organization ? organization.uuid : null,
        page: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    });
  };

  getEvents = (params, clearResults = false) => {
    if (cancel) {
      cancel();
    }
    const payload = {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };

    this.setState(
      {
        isLoadingMore: true,
        isLoaded: false,
        ...(clearResults && { events: [] }),
      },
      () => {
        getUserEvents(payload).then(
          ({ data } = {}) => {
            const list = data?.results.filter((v) => !!v.timetrackerEvents?.length) || [];
            const events = clearResults ? list : [...this.state.events, ...list];
            this.setState({
              events,
              nextPage: data?.meta.nextPage,
              isLoaded: true,
              isLoadingMore: false,
              hasMore: !!data?.meta.nextPage,
            });
          },
          (error) => {
            console.log('Error occured during page request.');
            this.setState({
              isLoaded: false,
              hasMore: false,
              isLoadingMore: false,
            });
          }
        );
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
        isLoaded: false,
      },
      () => {
        this.performDebouncedSearch();
      }
    );
  };

  handleStartDateChange = (date) => {
    this.setState(
      {
        start: date,
        isLoaded: false,
      },
      () => {
        this.performDebouncedSearch();
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        end: date,
        isLoaded: false,
      },
      () => {
        this.adjustDateStart(date);
        this.performDebouncedSearch();
      }
    );
  };

  adjustDateStart = (endDate) => {
    if (!!this.state.start && isAfter(new Date(this.state.start), endDate)) {
      this.setState({ start: endDate });
    }
  };

  searchevents = () => {
    let searchQuery = {};
    if (this.state.search) {
      searchQuery.search = this.state.search;
    }

    if (this.state.user) {
      searchQuery.user = this.state.user.uuid;
    }

    if (this.state.organization) {
      searchQuery.organization = this.state.organization.uuid;
    }

    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);
    if (this.state.start) {
      searchQuery.start = start;
      searchQuery.end = end;
    }

    this.getEvents(
      {
        search: this.state.search,
        start,
        end,
        user: this.state.user ? this.state.user.uuid : null,
        organization: this.state.organization ? this.state.organization.uuid : null,
        page: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      true
    );

    const searchQueryString = queryString.stringify(searchQuery, { sort: false });
    this.props.history.push('/events' + (searchQueryString ? '?' + searchQueryString : ''));
  };

  loadMoreEvents = (page) => {
    if (this.state.isLoadingMore || !this.state.isLoaded) return;

    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    this.getEvents({
      search: this.state.search,
      start,
      end,
      user: this.state.user ? this.state.user.uuid : null,
      organization: this.state.organization ? this.state.organization.uuid : null,
      page: this.state.nextPage,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  refreshEvents = () => {
    this.getEvents(
      {
        search: this.state.search,
        start: toISOExactDateTime(this.state.start, true),
        end: toISOExactDateTime(this.state.end, false),
        user: this.state.user?.uuid,
        organization: this.state.organization?.uuid,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      true
    );
  };

  toggleDurationFormat = () => {
    this.setState({ isDuration: !this.state.isDuration });
  };

  handleAddActivity = () => {
    this.setState({ isShownEntry: true, timeEntry: { manual: true } });
  };

  handleEditActivity = (data) => {
    this.setState({ isShownEntry: true, timeEntry: { ...data } });
  };

  handleDeleteActivity = (data) => {
    this.setState({ isShownConfirm: true, timeEntry: { ...data } });
  };

  handleCloseConfirm = () => {
    this.setState({ isShownConfirm: false });
  };

  handleEditActivityCompletion = (data) => {
    this.setState((prev) => ({
      events: prev.events.map((v) => {
        if (v.uuid === data?.user?.uuid) {
          const timetrackerEvents = v?.timetrackerEvents?.map((evt) => {
            return evt?.uuid === data?.uuid ? data : evt;
          });
          return { ...v, timetrackerEvents };
        }
        return v;
      }),
    }));
  };

  handleDeleteActivityCompletion = async () => {
    this.setState({ isDeleting: true });
    try {
      await deleteEvent(this.state.timeEntry?.uuid);
      this.setState((prev) => ({
        isShownConfirm: false,
        events: prev.events.map((v) => {
          if (v.uuid === this.state.timeEntry?.user?.uuid) {
            const timetrackerEvents = v?.timetrackerEvents?.filter(
              (evt) => evt?.uuid !== this.state.timeEntry?.uuid
            );
            return { ...v, timetrackerEvents };
          }
          return v;
        }),
      }));
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isDeleting: false });
    }
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={ListStyles.headerRow}>
            <div className={ListStyles.headerActive}>{`Timetracker Summary`}</div>
            <input
              className={ListStyles.headerSearch}
              name='search'
              type='text'
              placeholder='Search'
              value={this.state.search}
              onChange={this.handleChange}
            />
            <div className={Styles.filterContainer}>
              <span className={Styles.filterText}>{`Filter`}</span>
              <div className={Styles.filterFields}>
                <Fragment>
                  <UserSelect
                    id='userID'
                    className={Styles.select}
                    value={this.state.user}
                    classNamePrefix='s-contact'
                    name='user'
                    label='Talent'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    options={this.state.users}
                    onChange={this.handleChange}
                    isPaginated
                    loadOptions={onTimetrackerUsersLoadOptions}
                    isClearable
                  />
                  <Select
                    data-test-id='organization'
                    className={Styles.select}
                    value={this.state.organization}
                    classNamePrefix='s-contact'
                    name='organization'
                    label='Organization'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    onChange={this.handleChange}
                    loadOptions={onAllOrgsLoadOptions}
                    isPaginated
                    isClearable
                  />
                  <div className={Styles.dateInput}>
                    <DatePicker
                      id='eventsStart'
                      name='start'
                      label='Start Date'
                      value={this.state.start}
                      onChange={this.handleStartDateChange}
                    />
                  </div>
                  <div className={Styles.dateInput}>
                    <DatePicker
                      id='eventsEnd'
                      name='end'
                      label='End Date'
                      value={this.state.end}
                      onChange={this.handleEndDateChange}
                      minDate={this.state.start ? new Date(this.state.start) : undefined}
                    />
                  </div>
                </Fragment>
              </div>
            </div>
            <Button
              type={Button.Type.BLUE}
              className={Styles.btnAdd}
              onClick={this.handleAddActivity}>
              {`Add Activity`}
            </Button>
          </div>
        }>
        {this.state.events && (
          <InfiniteScroll
            initialLoad={true}
            className={Styles.container}
            pageStart={1}
            loadMore={this.loadMoreEvents}
            hasMore={this.state.hasMore && !this.state.isLoadingMore}
            threshold={500}
            useWindow={false}
            loader={
              <div className={classNames(Styles.loaderArea)} key={0}>
                {this.state.isLoadingMore && <Loader />}
              </div>
            }>
            {this.state.events.map((event, index) => (
              <div className={classNames(Styles.card)} key={`${event.uuid}-${index}`}>
                <div className={classNames(Styles.list, Styles.sticky)}>
                  <img className={Styles.picture} src={event.picture} alt={event.name} />
                  <div>
                    <p className={ListStyles.name}>{event.name}</p>
                  </div>

                  <div className={Styles.action}>
                    <div className={classNames(Styles.header)}>{`Paid`}</div>
                    <div className={classNames(Styles.listSection, Styles.header)}>
                      {`Screenshots`}
                    </div>
                    <div className={classNames(Styles.listSection, Styles.header)}>
                      {`Start Time`}
                    </div>
                    <div
                      className={classNames(Styles.listSection, Styles.header, Styles.headerAction)}
                      style={{ width: '100px', minWidth: '100px' }}
                      onClick={this.toggleDurationFormat}>
                      <Tooltip
                        position={Position.TOP}
                        content={this.state.isDuration ? 'Show As Timestamp' : 'Show As Hours'}>
                        <span>Duration</span>
                      </Tooltip>
                    </div>
                    <div className={Styles.listOption}></div>
                  </div>
                </div>

                {event.timetrackerEvents.map((entry, entryIndex) => (
                  <EntryListItem
                    key={entry.uuid}
                    onEdit={this.handleEditActivity}
                    onDelete={this.handleDeleteActivity}
                    event={{
                      ...entry,
                      user: { uuid: event.uuid, name: event.name, picture: event.picture },
                    }}
                    isDuration={this.state.isDuration}
                  />
                ))}

                <div className={Styles.list}>
                  <div className={Styles.action}>
                    <div
                      className={classNames(Styles.listSection, Styles.header)}
                      style={{ width: '100px', minWidth: '100px' }}>
                      {`Total`}
                    </div>
                    <div className={Styles.listSectionSmall}>
                      {this.state.isDuration
                        ? (event.totalDuration / 3600).toFixed(5)
                        : convertNumericToDuration(event.totalDuration)}
                    </div>
                    <div className={Styles.listOption}></div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}

        {!this.state.isLoaded && (
          <div style={{ margin: '' }}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}

        {this.state.isShownEntry && (
          <TimesheetEntry
            isShown={true}
            onClose={() => this.setState({ isShownEntry: false })}
            data={this.state.timeEntry}
            onAdd={this.refreshEvents}
            onUpdate={this.handleEditActivityCompletion}
            OverlapRowComponent={EntryListItem}
          />
        )}

        <Dialog
          title={`Delete Activity`}
          description={
            <>
              <p>Are you sure you want to delete this activity?</p>
              <EntryListItem
                event={this.state.timeEntry}
                showActionItems={false}
                className={ListStyles.deleteDialogEntry}
                isDuration={this.state.isDuration}
              />
            </>
          }
          minWidth='450px'
          isOpen={this.state.isShownConfirm}
          onClose={this.handleCloseConfirm}>
          <Button
            onClick={this.handleDeleteActivityCompletion}
            type={Button.Type.BLUE}
            isLoading={this.state.isDeleting}>
            {'Delete'}
          </Button>
          <Button onClick={this.handleCloseConfirm}>{'Cancel'}</Button>
        </Dialog>
      </AppLayout>
    );
  }
}

export default withUser(EventList);
