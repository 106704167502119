import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './ScreenshotViewer.module.scss';

import Loader from '@hiredigital/ui/Loader';

import classNames from 'classnames';
import ScreenshotItem from './ScreenshotItem';
import InfiniteScroll from 'react-infinite-scroller';
import { getEventScreenshots } from '@apis/tracking';
import ScreenshotViewer from './ScreenshotViewer';

const ListLoader = () => (
  <div style={{ width: '100%' }}>
    <Loader color={Loader.Color.WHITE} size={Loader.Size.LARGE} />
  </div>
);

const ScreenshotList = (props) => {
  const [hasMore, setHasMore] = useState(true);
  const [showScreenshots, setShowScreenshots] = useState(false);
  const [screenshots, setScreenshots] = useState([]);
  const [meta, setMeta] = useState({});
  const [defaultActiveIndex, setDefaultActiveIndex] = useState(null);

  useEffect(() => {
    handleLoadMore(1, false);
  }, []);

  const handleScreenshotClick = (idx, uuid) => {
    setDefaultActiveIndex(idx);
    setShowScreenshots(true);
  };

  const handleLoadMore = (page, append = true) => {
    if (!hasMore) return;
    const params = { page, ordering: 'time' };
    getEventScreenshots(props.eventUuid, { params })
      .then(({ data }) => {
        const list = append ? [...screenshots, ...data.results] : [...data.results];
        setScreenshots(list);
        setMeta(data.meta);
        if (!data.meta.nextPage) {
          setHasMore(false);
        }
      })
      .catch((error) => {
        setHasMore(false);
      });
  };

  return (
    <Fragment>
      <div className={classNames(Styles.screenshotList)}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={handleLoadMore}
          hasMore={hasMore}
          threshold={500}
          useWindow={false}
          className={Styles.screenshotsContainer}
          loader={<ListLoader key={1} />}>
          {screenshots.map((item, idx) => (
            <ScreenshotItem
              key={idx}
              src={item.preview}
              onClick={() => handleScreenshotClick(idx, item.uuid)}
            />
          ))}
        </InfiniteScroll>
      </div>
      {showScreenshots && (
        <ScreenshotViewer
          user={props.user}
          eventUuid={props.eventUuid}
          screenshots={screenshots}
          meta={meta}
          onClose={() => setShowScreenshots(false)}
          defaultActiveIndex={defaultActiveIndex}
        />
      )}
    </Fragment>
  );
};

ScreenshotList.propTypes = {
  className: PropTypes.string,
  eventUuid: PropTypes.string,
  user: PropTypes.object,
};

export default ScreenshotList;
