import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';

import { deleteUserOrg, getUserOrgs } from '@apis/users';
import Item from './Item';
import Styles from './Styles.module.scss';

class OrgList extends Component {
  static propTypes = {
    userUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      orgs: [],
    };
  }

  componentDidMount = () => {
    getUserOrgs(this.props.userUuid)
      .then(({ data: { results } }) => {
        this.setState({ orgs: results });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDeleteOrg = (orgTalentUuid) => {
    this.setState({ loading: true });
    deleteUserOrg(this.props.userUuid, orgTalentUuid).then(
      (response) => {
        const orgs = this.state.orgs.filter(({ uuid }) => uuid !== orgTalentUuid);
        this.setState({ orgs, loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    return (
      <Card>
        <Card.Header title={`Clients`} />
        <Fragment>
          <div>
            {(this.state.orgs &&
              this.state.orgs.length &&
              this.state.orgs.map((org, index) => (
                <Item
                  data={org}
                  key={org.uuid}
                  userUuid={this.props.userUuid}
                  onDeleteOrg={this.handleDeleteOrg}
                />
              ))) ||
              ''}
            {this.state.orgs && this.state.orgs.length === 0 && (
              <Card.Item>
                <div className={Styles.empty}>{`No clients added`}</div>
              </Card.Item>
            )}
          </div>
        </Fragment>
      </Card>
    );
  }
}

export default OrgList;
