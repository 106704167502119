import Styles from './SelectMenu.module.scss';
import Select from '@hiredigital/ui/Input/Select';
import classNames from 'classnames';

const SelectMenu = ({ className, ...props }) => {
  return (
    <Select
      className={classNames(Styles.dropdown, className)}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ id }) => id}
      {...props}
    />
  );
};

export default SelectMenu;
