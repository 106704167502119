import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { toReadableMedDateTime } from '@hiredigital/lib/helpers/date';
import Select from '@hiredigital/ui/Input/Select';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';
import Button from '@hiredigital/ui/Button';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import AppLayout from '@components/Layout/AppLayout';
import TimesheetEntry from '@components/TimesheetEntry';
import { getEvents, deleteEvent } from '@apis/tracking';
import { onTimetrackerUsersLoadOptions, onAllOrgsLoadOptions } from '@apis/dropdown';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import EntryListItem from './components/EntryListItem';
import { useUser } from '@context/user';
import withParameters from '@hoc/withParameters';
// import withParamsResolver from '@hoc/withParamsResolver';
import withEmailLabel from '@hoc/withEmailLabel';

import Styles from '@styles/PageList.module.scss';

const UserSelect = withEmailLabel(Select);

const TimetrackerEventList = ({ location, history, initialProps, ...props }) => {
  const currentUser = useUser();
  const [search, setSearch] = useState(initialProps.search);
  const [org, setOrg] = useState('');
  const [user, setUser] = useState('');
  const [isShownEntry, setIsShownEntry] = useState(false);
  const [timeEntry, setTimeEntry] = useState();
  const [newRow, setNewRow] = useState();
  const [updatedRow, setUpdatedRow] = useState();
  const [isShownConfirm, setIsShownConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletedRow, setDeletedRow] = useState();

  let timeout = null;

  useEffect(() => {
    setPageTitle('Timetracker Events');
  }, []);
  const filterMemo = useMemo(
    () => ({
      organization: org?.uuid,
      user: user?.uuid,
    }),
    [org, user, newRow]
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleEdit = (v) => {
    setTimeEntry(v);
    setIsShownEntry(true);
  };

  const handleDelete = (v) => {
    setTimeEntry(v);
    setIsShownConfirm(true);
  };

  const handleCloseConfirm = () => {
    setIsShownConfirm(false);
  };

  const handleDeleteActivityCompletion = async () => {
    setIsDeleting(true);
    try {
      await deleteEvent(timeEntry?.uuid);
      setIsShownConfirm(false);
      setDeletedRow(timeEntry);
    } catch (err) {
      console.log(err);
    } finally {
      setIsDeleting(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Task',
        id: 'task__name',
        minWidth: 300,
        truncate: false,
        accessor: (v) => {
          return (
            <div className={Styles.taskName}>
              <Ellipsis className={!v.task?.name && Styles.blank}>
                {v.task?.name || 'No task name'}
              </Ellipsis>
              <SplitButton
                more={[
                  { text: 'Edit', onClick: () => handleEdit(v) },
                  { text: 'Delete', onClick: () => handleDelete(v) },
                ]}
                direction={'left_over'}
              />
            </div>
          );
        },
      },

      {
        Header: 'User',
        id: 'user__name',
        minWidth: 175,
        truncate: false,
        Cell: ({ row: { original: n } }) => {
          return n.user?.uuid ? (
            <Link className={Styles.profile} to={`/profiles/${n.user?.uuid}`}>
              <img className={Styles.picture} src={n.user?.picture} alt='' />
              <MultiLineEllipsis
                data-test-id='profile-table-item'
                className={classNames(Styles.name, !n.user?.name && Styles.empty)}>
                {n.user?.name || 'No Name'}
              </MultiLineEllipsis>
            </Link>
          ) : (
            ''
          );
        },
      },
      {
        Header: 'Organization',
        id: 'organization__name',
        minWidth: 175,
        truncate: false,
        Cell: ({ row: { original: n } }) => {
          return n.task?.timesheet?.assignment?.organization ? (
            <Link
              className={Styles.profile}
              to={`/clients/${n.task?.timesheet?.assignment?.organization?.uuid}`}>
              <img
                className={Styles.picture}
                src={n.task?.timesheet?.assignment?.organization?.picture}
                alt=''
              />
              <div>
                <MultiLineEllipsis data-test-id='client-table-item' className={Styles.name}>
                  {n.task?.timesheet?.assignment?.organization?.name}
                </MultiLineEllipsis>
              </div>
            </Link>
          ) : (
            ''
          );
        },
      },

      {
        Header: 'Timesheet',
        id: 'task__timesheet',
        minWidth: 300,
        accessor: (n) => <div>{n.task?.timesheet?.assignment?.title}</div>,
      },
      {
        Header: 'Start Time',
        canSort: true,
        id: 'start_time',
        minWidth: 100,
        accessor: (n) => <div>{toReadableMedDateTime(n.startTime)}</div>,
      },
      // {
      //   Header: 'End Time',
      //   canSort: true,
      //   id: 'end_time',
      //   minWidth: 100,
      //   accessor: (n) => <div>{toReadableMedDateTime(n.endTime)}</div>,
      // },
      {
        Header: 'Duration',
        canSort: true,
        id: 'duration',
        minWidth: 170,
        accessor: (n) => (
          <div className={Styles.labelContainer}>
            {n.duration?.split('.', 1)}
            {n.manual && <span className={Styles.labelInList}>{`Manual`}</span>}
            {n.locked && <span className={Styles.labelInList}>{`Paid`}</span>}
          </div>
        ),
      },

      // {
      //   Header: '',
      //   id: 'action',
      //   width: 25,
      //   minWidth: 25,
      //   accessor: (e) => <ActionColumn notification={e} onStatusUpdate={handleStatusUpdate} />,
      // },
      // {
      //   Header: 'Summary',
      //   id: 'summary',
      //   minWidth: 400,
      //   accessor: (n) => <div dangerouslySetInnerHTML={{ __html: n?.summary }} />,
      // },
      // {
      //   Header: 'Recipient',

      //   id: 'recipient',
      //   minWidth: 100,
      //   accessor: (n) => <div>{n?.recipient?.name || 'No Recipient'}</div>,
      // },
      // {
      //   Header: 'Created By',
      //   id: 'actor',
      //   minWidth: 100,
      //   accessor: (n) => n?.actor?.name,
      // },
      // {
      //   Header: 'Organization',
      //   id: 'organization',
      //   minWidth: 100,
      //   accessor: (n) => n?.organization?.name,
      // },

      // {
      //   Header: 'Status',
      //   canSort: true,
      //   id: 'read',
      //   minWidth: 100,
      //   accessor: (n) => <div>{n?.read === true ? `Read` : `Unread`}</div>,
      // },
      // {
      //   Header: 'Date',
      //   canSort: true,
      //   id: 'date',
      //   minWidth: 100,
      //   accessor: (n) => <div>{format(parseISO(n.created.toString()), 'dd MMM yyyy')}</div>,
      // },
    ],
    []
  );

  const handleAddActivity = () => {
    setIsShownEntry(true);
    setTimeEntry({ manual: true });
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Timetracker Events`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />
          <UserSelect
            id='userID'
            className={classNames(Styles.headerSelect, Styles.showBorder)}
            value={user}
            name='user'
            label='Talent'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={(user) => {
              setUser(user);
            }}
            isPaginated
            loadOptions={onTimetrackerUsersLoadOptions}
            isClearable
          />
          <Select
            id='organizationID'
            className={classNames(Styles.headerSelect, Styles.showBorder)}
            value={org}
            name='organization'
            label='Organization'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={(org) => {
              setOrg(org);
            }}
            isPaginated
            loadOptions={onAllOrgsLoadOptions}
            isClearable
          />
          <Button type={Button.Type.BLUE} className={Styles.btnAdd} onClick={handleAddActivity}>
            {`Add Activity`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getEvents}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        filters={filterMemo}
        updateRow={updatedRow}
        deleteRow={deletedRow}
        rowHeight={50}
      />

      {isShownEntry && (
        <TimesheetEntry
          isShown={true}
          onClose={() => setIsShownEntry(false)}
          data={timeEntry}
          onAdd={setNewRow}
          onUpdate={setUpdatedRow}
          OverlapRowComponent={EntryListItem}
        />
      )}

      <Dialog
        title={`Delete Activity`}
        description={
          <>
            <p>Are you sure you want to delete this activity?</p>
            <EntryListItem
              event={timeEntry}
              showActionItems={false}
              className={Styles.deleteDialogEntry}
              isDuration={false}
            />
          </>
        }
        minWidth='450px'
        isOpen={isShownConfirm}
        onClose={handleCloseConfirm}>
        <Button
          onClick={handleDeleteActivityCompletion}
          type={Button.Type.BLUE}
          isLoading={isDeleting}>
          {'Delete'}
        </Button>
        <Button onClick={handleCloseConfirm}>{'Cancel'}</Button>
      </Dialog>
    </AppLayout>
  );
};

export default withParameters(TimetrackerEventList);
