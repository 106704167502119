import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // for ES6 modules

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';

import Address from '@components/Address/Address';
import Quill from '@hiredigital/ui/Quill/Editor';
import ImageDrop from '@components/Form/File/ImageDrop';
import Contact from '@components/OrgContact/Contact';
import ProfileContact from '@components/ProfileContact/ProfileContact';
import AppLayout from '@components/Layout/AppLayout';
import SortableSelect from '@hiredigital/ui/Input/SortableSelect';

import AgreementList from '@components/Agreements/List';
import ServiceRateList from '@components/ServiceRate/ServiceRateList';
import TalentRateList from '@hiredigital/ui/TalentRateList';
import PortfolioList from '@components/PortfolioList/PortfolioList';
import Members from '@components/TeamMembers/Members';
import BankAccountList from '@hiredigital/ui/BankAccounts';
import { BankApiManager, BankValidator } from '@hiredigital/lib/apis/class/banks';
import { RatesApiManager } from '@hiredigital/lib/apis/class/rates';
import { authRequest, request } from '@apis/utils';

import { validateUrl } from '@helpers/forms';
import { isAdmin } from '@helpers/permissions';
import { onSkillsLoadOptions } from '@apis/dropdown';
import {
  getTeam,
  getTeamInvitations,
  acceptTeamChanges,
  getTeamBankAccounts,
  putTeam,
} from '@apis/teams';
import { createAdminSkill } from '@apis/common';
import { TeamStatus } from '@hiredigital/lib/helpers/enum';
import { withUser } from '@context/user';
import { setPageTitle, addHttpPrefix, socialPrefixes } from '@hiredigital/lib/helpers/utils';

import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

class TeamDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      teamUuid: this.props.match.params.teamUuid,
      isSaving: false,
      adminMin: false,
      isPublishing: false,
      name: '',
      picture: '',
      description: '',
      headline: '',
      social: {
        website: '',
        linkedin: '',
        twitter: '',
        facebook: '',
        instagram: '',
      },
      contact: {
        phoneCountry: '',
        phoneNumber: '',
        skype: '',
        zoom: '',
        timezone: '',
        preferred: '',
        type: '',
        user: {},
      },
      address: {
        address: '',
        postalCode: '',
        city: '',
        region: '',
        country: '',
      },
      invitations: [],
      services: [],

      // websiteValid: true,
      hasInitLoad: false,
      // submitted: false,
      currentTab: '',
      bankAccounts: [],
      errors: {},
    };

    this.contactInitialState = { ...this.state.contact };
    this.addressInitialState = { ...this.state.address };
    this.socialInitialState = { ...this.state.social };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextProps.currentUser !== this.props.currentUser) {
      return true;
    }
    if (
      nextState.social?.website !== this.state.social?.website ||
      nextState.social?.linkedin !== this.state.social?.linkedin ||
      nextState.social?.twitter !== this.state.social?.twitter ||
      nextState.social?.facebook !== this.state.social?.facebook ||
      nextState.social?.instagram !== this.state.social?.instagram ||
      nextState.orderedSkills !== this.state.orderedSkills ||
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.isPublishing !== this.state.isPublishing ||
      nextState.isSaving !== this.state.isSaving ||
      nextState.errors !== this.state.errors
    ) {
      return true;
    }
    return false;
  }

  componentDidMount = () => {
    const hash = this.props.location && this.props.location.hash;
    this.setState({ currentTab: (hash && hash.substr(1)) || 'profile' });

    Promise.all([this.loadTeam(), this.loadInvitations(), this.loadAdminFields()]).then(
      (values) => {
        this.setState({
          isLoaded: true,
        });
        setPageTitle(`Team Review | ${values?.[0]?.data?.name || 'New Team'}`);
        this.handleResponse(values[0]);
        this.setState(
          {
            invitations: values?.[1]?.data?.results || [],
            bankAccounts: values?.[2]?.data?.results || [],
          },
          () => {
            this.setState({ hasInitLoad: true });
          }
        );

        if (this.props.onLoad) {
          this.props.onLoad();
        }
      },
      (error) => {
        this.setState({ isLoaded: true });
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      }
    );
  };

  handleAdminBoolean = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
    });
    this.handleImmediateSave();
  };

  handleAdminChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.handleAutoSave();
  };

  loadAdminFields = () => {
    if (this.props.currentUser && isAdmin(this.props.currentUser)) {
      getTeamBankAccounts(this.state.teamUuid);
    }
  };

  loadTeam = async () => {
    return await getTeam(this.state.teamUuid);
  };

  loadInvitations = async () => {
    return await getTeamInvitations(this.state.teamUuid);
  };

  handleCreateSkill = async (value) => {
    const response = await createAdminSkill(value);

    this.setState((state) => {
      return {
        ...state,
        orderedSkills: state.orderedSkills.concat([response.data]),
      };
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.handleAutoSave();
  };

  handleSocialChange = (event, prefix, removeBaseUrl = true) => {
    const { name, value } = event.target;
    // const link =
    //   (removeBaseUrl
    //     ? value && value.replace(prefix, '')
    //     : value && addHttpPrefix(value, prefix)) || '';

    this.setState({ ...this.state, errors: {}, social: { ...this.state.social, [name]: value } });
    // this.setState((state) => ((state.social[name] = link), state));
    this.handleAutoSave();
  };

  handleContactChange = (event, setId) => {
    const { name, value } = event.target;
    this.setState((state) => ((state.contact[name] = setId ? value.id : value), state));
    this.handleAutoSave();
  };

  handleAddressChange = (event, setId) => {
    const { name, value } = event.target;
    this.setState((state) => ((state.address[name] = setId ? value.id : value), state));
    this.handleAutoSave();
  };

  handleResponse = (response) => {
    if (response) {
      this.setState({
        ...response.data,
      });

      if (!response.data.contact) {
        this.setState({ contact: { ...this.contactInitialState } });
      }

      if (!response.data.address) {
        this.setState({ address: { ...this.addressInitialState } });
      }

      if (!response.data.social) {
        this.setState({ social: { ...this.socialInitialState } });
      }
    }
  };

  handleAcceptChanges = async () => {
    this.setState({ isPublishing: true });
    try {
      await acceptTeamChanges(this.state.teamUuid);
      this.setState({ isPublishing: false });
    } catch (err) {
      this.setState({ isPublishing: false });
    }
  };

  handleImmediateSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 0);
  };

  handleSelectChange = (event, setId) => {
    const { name, value } = event.target;
    this.setState({
      [name]: setId ? value.id : value,
    });
    this.handleImmediateSave();
  };

  handleAddressSave = (data) => {
    this.setState({ ...data }, this.handleImmediateSave);
  };

  handleAutoSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 2000);
  };

  validate = () => {
    const { social } = this.state;
    const errors = {};

    if (social?.website && !validateUrl(social.website)) {
      errors.website = 'Enter a valid URL';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  handleSubmit = (storeResponse = true) => {
    this.setState({ errors: {} });

    const {
      name,
      slug,
      description,
      headline,
      social,
      contact,
      address,
      internalNotes,
      requestHidden,
      isHidden,
      talentReview,
      status,
      orderedSkills,
    } = this.state;

    if (!this.validate()) {
      return;
    }

    this.setState({ isSaving: true });

    const data = {
      name,
      slug,
      description,
      headline,
      social,
      address,
      internalNotes: internalNotes ? internalNotes : '',
      requestHidden: requestHidden ? requestHidden : false,
      isHidden: isHidden ? isHidden : false,
      talentReview: talentReview ? talentReview : false,
      status,
      orderedSkills: orderedSkills?.map((obj) => obj.id) || [],
      // owner: owner.uuid,
      contact: {
        ...contact,
        timezone: contact.timezone.id,
        preferred: contact.preferred ? contact.preferred : 1,
        type: contact.type ? contact.type : 1,
        phoneCountry: contact.phoneCountry ? contact.phoneCountry : null,
        user: contact && contact.user && contact.user.user ? contact.user.user.uuid : null,
      },
    };

    putTeam(this.state.teamUuid, data).then(
      (response) => {
        if (storeResponse) {
          this.handleResponse(response);
        }
        this.setState({ isSaving: false });
      },
      (error) => {
        this.setState({ isSaving: false });
      }
    );
  };

  navigateTabLocation = (tab) => {
    this.setState({ currentTab: tab }, () => {
      const { history, location } = this.props;
      history.push(`${location.pathname}#${tab}`);
    });
  };

  render() {
    const adminPermission = this.props.currentUser.adminPermission;

    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/teams'>
              {`Team Profile Review`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>{this.state.name}</div>
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <div
              className={classNames(
                PageStyles.formContainer,
                PageStyles.adminContainer,
                this.state.adminMin && Styles.adminMin
              )}>
              <div className={Styles.row}>
                <h3 className={PageStyles.headline}>{`Admin Settings`}</h3>

                <Button
                  style={{ marginLeft: 'auto' }}
                  type={Button.Type.BLUE}
                  onClick={this.handleAcceptChanges}
                  isLoading={this.state.isPublishing}>
                  {`Update Resume`}
                </Button>
                <Button
                  name='submit'
                  type={Button.Type.BLUE}
                  onClick={this.handleImmediateSave}
                  isLoading={this.state.isSaving}>
                  {`Save Profile`}
                </Button>
              </div>
              <div>
                <div
                  className={Styles.rowSpaceBetween}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <div className={Styles.adminToggle}>
                    <InputContainer
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: '20px',
                      }}>
                      <Toggle
                        id='request-private'
                        name='requestHidden'
                        defaultChecked={this.state.requestHidden}
                        onChange={this.handleAdminBoolean}
                        disabled
                      />
                      <label
                        htmlFor='request-private'
                        className={Styles.toggleLabel}>{`Team Requested Private`}</label>
                    </InputContainer>
                    <InputContainer
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: '20px',
                      }}>
                      <Toggle
                        id='set-private'
                        name='isHidden'
                        defaultChecked={this.state.isHidden}
                        onChange={this.handleAdminBoolean}
                      />
                      <label
                        htmlFor='set-private'
                        className={Styles.toggleLabel}>{`Set Profile to Private`}</label>
                    </InputContainer>
                    {this.props.currentUser && isAdmin(this.props.currentUser) && (
                      <InputContainer
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginRight: '20px',
                        }}>
                        <Toggle
                          id='talent-review'
                          name='talentReview'
                          defaultChecked={this.state.talentReview}
                          onChange={this.handleAdminBoolean}
                        />
                        <label
                          htmlFor='talent-review'
                          className={Styles.toggleLabel}>{`Enable Team Talent Review`}</label>
                      </InputContainer>
                    )}
                  </div>
                  <InputContainer style={{ width: '344px' }}>
                    <Select
                      className={PageStyles.select}
                      value={TeamStatus.getEnum(this.state.status)}
                      classNamePrefix='s-contact'
                      name='status'
                      label='Status'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      options={TeamStatus.values}
                      onChange={(e) => this.handleSelectChange(e, true)}
                    />
                  </InputContainer>
                </div>

                {!this.state.adminMin && (
                  <div className={Styles.notes}>
                    <Quill
                      name='internalNotes'
                      label='Notes'
                      placeholder='Review Notes'
                      defaultValue={this.state.internalNotes}
                      onChange={this.handleAdminChange}
                      legacyCompat
                    />
                  </div>
                )}
              </div>
            </div>
            <Tab.Container
              defaultActiveKey='profile'
              activeKey={this.state.currentTab}
              mountOnEnter={true}>
              <Nav variant='tabs' className={classNames(Styles.row, PageStyles.headlineRow)}>
                <Nav.Item>
                  <Nav.Link
                    eventKey='profile'
                    onClick={() => {
                      this.navigateTabLocation('profile');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Profile`}</h3>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='settings'
                    onClick={() => {
                      this.navigateTabLocation('settings');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Settings`}</h3>{' '}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className={PageStyles.tabContent}>
                <Tab.Pane eventKey='profile'>
                  <Card>
                    <Card.Header>
                      {`Profile Details`}
                      <div className={Styles.sideLinks}>
                        <a
                          href={`${process.env.MARKETING_ENDPOINT}/teams/${this.state.slug}`}
                          target='_blank'>
                          {`View ${this.state.name}'s Public Page`}
                        </a>
                      </div>
                    </Card.Header>
                    <Card.Item>
                      <div className={Styles.row}>
                        <ImageDrop
                          className={Styles.photo}
                          name='picture'
                          label='Picture'
                          server={`admin/teams/${this.state.teamUuid}/`}
                          idleLabel='Update Picture'
                          value={this.state.picture}
                          onResponse={this.handleResponse}
                        />
                        <div style={{ flexGrow: 1 }}>
                          <InputGroup>
                            <InputContainer>
                              <TextInput
                                name='name'
                                label='Name'
                                defaultValue={this.state.name}
                                onChange={this.handleChange}
                              />
                            </InputContainer>

                            <InputContainer>
                              <TextInput
                                name='slug'
                                label='Slug'
                                defaultValue={this.state.slug}
                                disabled
                              />
                            </InputContainer>
                          </InputGroup>

                          <InputContainer>
                            <TextInput
                              name='headline'
                              label='Headline'
                              defaultValue={this.state.headline}
                              onChange={this.handleChange}
                            />
                          </InputContainer>

                          {this.state.social && (
                            <InputContainer>
                              <TextInput
                                name='website'
                                label='Website'
                                error={this.state.errors?.website}
                                defaultValue={this.state.social.website}
                                onChange={(e) => this.handleSocialChange(e, 'http://', false)}
                              />
                            </InputContainer>
                          )}
                        </div>
                      </div>

                      <InputGroup>
                        <InputContainer>
                          <SortableSelect
                            className={PageStyles.select}
                            value={this.state.orderedSkills}
                            name='orderedSkills'
                            label='Skills'
                            isClearable={false}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={this.state.skills}
                            isMulti
                            onChange={this.handleChange}
                            isPaginated
                            isCreatable
                            onCreateOption={this.handleCreateSkill}
                            loadOptions={onSkillsLoadOptions}
                          />
                        </InputContainer>
                      </InputGroup>

                      {this.state.social && (
                        <Fragment>
                          <InputGroup>
                            <InputContainer>
                              <TextInput
                                name='linkedin'
                                label='Linkedin'
                                value={addHttpPrefix(
                                  this.state.social.linkedin,
                                  socialPrefixes.linkedin
                                )}
                                onChange={(e) =>
                                  this.handleSocialChange(e, socialPrefixes.linkedin)
                                }
                              />
                            </InputContainer>

                            <InputContainer>
                              <TextInput
                                name='twitter'
                                label='Twitter'
                                value={addHttpPrefix(
                                  this.state.social.twitter,
                                  socialPrefixes.twitter
                                )}
                                onChange={(e) => this.handleSocialChange(e, socialPrefixes.twitter)}
                              />
                            </InputContainer>
                          </InputGroup>
                          <InputGroup>
                            <InputContainer>
                              <TextInput
                                name='facebook'
                                label='Facebook'
                                value={addHttpPrefix(
                                  this.state.social.facebook,
                                  socialPrefixes.facebook
                                )}
                                onChange={(e) =>
                                  this.handleSocialChange(e, socialPrefixes.facebook)
                                }
                              />
                            </InputContainer>
                            <InputContainer>
                              <TextInput
                                name='instagram'
                                label='Instagram'
                                value={addHttpPrefix(
                                  this.state.social.instagram,
                                  socialPrefixes.instagram
                                )}
                                onChange={(e) =>
                                  this.handleSocialChange(e, socialPrefixes.instagram)
                                }
                              />
                            </InputContainer>
                          </InputGroup>
                        </Fragment>
                      )}

                      <InputContainer>
                        <Quill
                          name='description'
                          label='About'
                          placeholder='Description'
                          defaultValue={this.state.description}
                          onChange={this.handleChange}
                          legacyCompat
                        />
                      </InputContainer>
                    </Card.Item>
                    <Card.Footer right sticky>
                      <Button
                        name='submit'
                        type={Button.Type.BLUE}
                        className={Styles.saveButton}
                        onClick={this.handleImmediateSave}
                        isLoading={this.state.isSaving}>
                        {`Save Profile`}
                      </Button>
                    </Card.Footer>
                  </Card>

                  <Members
                    team={this.state}
                    members={this.state.users}
                    invitations={this.state.invitations}
                  />

                  <h3
                    className={classNames(
                      PageStyles.headline,
                      PageStyles.headlineRow
                    )}>{`Portfolio`}</h3>
                  <div>
                    <PortfolioList
                      type={PortfolioList.Type.TEAM}
                      isTeamView={true}
                      resourceUuid={this.state.teamUuid}
                      creator={this.state}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='settings'>
                  <ProfileContact userUuid={this.state.teamUuid} user={this.state} type='teams' />
                  <Contact orgUuid={this.state.teamUuid} data={this.state.contact} type='teams' />
                  <Address
                    title='Address'
                    address={this.state.address}
                    payment={this.state.payment}
                    onSave={this.handleAddressSave}
                  />

                  {this.props.currentUser &&
                    (isAdmin(this.props.currentUser) ||
                      adminPermission?.teamBilling ||
                      adminPermission?.teamClientRate ||
                      adminPermission?.teamTalentRate) && (
                      <>
                        {isNewRates
                          ? (isAdmin(this.props.currentUser) ||
                              adminPermission?.teamTalentRate) && (
                              <TalentRateList
                                type={TalentRateList.Type.ADMIN}
                                apiManager={new RatesApiManager(authRequest)}
                                resourceUuid={this.state.teamUuid}
                                resource={`teams`}
                                talentPermission={adminPermission?.teamTalentRate}
                                userSkills={this.state.orderedSkills}
                              />
                            )
                          : (isAdmin(this.props.currentUser) ||
                              adminPermission?.teamTalentRate ||
                              adminPermission?.teamClientRate) && (
                              <ServiceRateList
                                clientPermission={adminPermission?.teamClientRate}
                                talentPermission={adminPermission?.teamTalentRate}
                                resourceUuid={this.state.teamUuid}
                                resource={`teams`}
                                userSkills={this.state.orderedSkills}
                              />
                            )}
                        {this.state.hasInitLoad &&
                          (isAdmin(this.props.currentUser) || adminPermission.teamBilling) && (
                            <BankAccountList
                              apiManager={
                                new BankApiManager(authRequest, this.state.teamUuid, {
                                  resourcePrefix: 'admin',
                                  resource: 'teams',
                                })
                              }
                              oldApiManager={
                                new BankApiManager(authRequest, this.state.teamUuid, {
                                  resourcePrefix: 'admin',
                                  resource: 'teams',
                                  subResource: 'bank-accounts',
                                })
                              }
                              validator={new BankValidator(request)}
                              showWiseId
                            />
                          )}
                      </>
                    )}
                  <AgreementList
                    resource='teams'
                    resourceUuId={this.state.teamUuid}
                    currentUser={this.props.currentUser}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}
export default withUser(TeamDetail);
