import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import Quill from '@hiredigital/ui/Quill/Editor';
import DeadlineList from '@components/Deadline/List';
import TalentMemberList from './components/TalentMembers/List';

import AppLayout from '@components/Layout/AppLayout';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { getProject, saveProject } from '@apis/projects';
import { ProjectStatus } from '@hiredigital/lib/helpers/enum';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { onOrgsLoadOptions } from '@apis/dropdown';
import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

const ProjectDetail = ({ match, data, location, history }) => {
  const projectUuid = match.params.projectUuid;
  const [currentTab, setCurrentTab] = useState('project');
  const [hasInitLoad, setHasInitLoad] = useState(false);
  const [project, setProject] = useState({});
  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const hash = location?.hash;
    if (hash) setCurrentTab(hash.substr(1));
    getProject(projectUuid).then(({ data }) => {
      setPageTitle(`Projects | ${data.title || 'New Project'}`);
      populateFields(data);
    });
  }, []);

  const populateFields = (data, cb) => {
    setProject(data);
    setHasInitLoad(true);
    if (cb) {
      cb();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProject({ ...project, [name]: value });
    handleAutoSave();
  };

  const handleEnumChange = (event) => {
    const { name, value } = event.target;
    setProject({ ...project, [name]: value?.id });
    handleAutoSave();
  };

  const handleOrgChange = (event) => {
    const { name, value } = event.target;
    // This should be org and not org uuid because we serialize it in the api
    setProject({ ...project, organization: value });
    handleAutoSave();
  };

  const handleImmediateSave = () => {
    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(
      setTimeout(() => {
        handleSubmit(false);
      }, 0)
    );
  };

  const handleAutoSave = () => {
    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(
      setTimeout(() => {
        handleSubmit(false);
      }, 2000)
    );
  };

  const handleSubmit = (storeResponse = true) => {
    setIsSaving(true);

    const data = {
      title: project.title,
      requirements: project.requirements,
      status: project.status,
      organization: project.organization,
    };

    saveProject(projectUuid, data).then(
      (response) => {
        setIsSaving(false);
        if (storeResponse) {
          populateFields(response);
        }
        setAutoSaveTimeout(null);
      },
      (error) => {
        setIsSaving(false);
        setAutoSaveTimeout(null);
      }
    );
  };

  const navigateTabLocation = (tab) => {
    setCurrentTab(tab);
    history.push(`${location.pathname}#${tab}`);
  };

  return (
    <AppLayout
      location={location}
      header={
        <div className={PageStyles.header}>
          <Link className={PageStyles.headerParent} to='/projects'>
            {`Projects`}
          </Link>
          <p className={PageStyles.headerCaret}>&rsaquo;</p>
          {hasInitLoad && (
            <div className={PageStyles.headerActive}>{project.title || 'Untitled Project'}</div>
          )}
        </div>
      }>
      {hasInitLoad && (
        <div className={PageStyles.container}>
          <div className={classNames(PageStyles.formContainer, PageStyles.adminContainer)}>
            <div className={classNames(Styles.row, Styles.topItem)}>
              <h3 className={PageStyles.headline}>{`Admin Settings`}</h3>
              <Button
                element='a'
                type={Button.Type.BLUE_OUTLINE}
                className={Styles.saveButton}
                target='_blank'
                href={`${process.env.APP_ENDPOINT}/projects/${projectUuid}`}>
                {`Open in App`}
              </Button>

              <Button
                name='submit'
                className={Styles.saveButton}
                type={Button.Type.BLUE}
                onClick={handleImmediateSave}
                isLoading={isSaving}>
                {`Save Project`}
              </Button>
            </div>
            <InputGroup>
              <InputContainer style={{ width: '344px' }}>
                <Select
                  data-test-id='status'
                  className={PageStyles.select}
                  value={ProjectStatus.getEnum(project.status)}
                  classNamePrefix='s-contact'
                  name='status'
                  label='Status'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={ProjectStatus.values}
                  onChange={handleEnumChange}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  data-test-id='organization'
                  className={PageStyles.select}
                  defaultValue={project.organization}
                  classNamePrefix='s-contact'
                  name='organization'
                  label='Client'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ uuid }) => uuid}
                  onChange={handleOrgChange}
                  isPaginated
                  loadOptions={onOrgsLoadOptions}
                  isClearable
                />
              </InputContainer>
            </InputGroup>
          </div>

          <Tab.Container defaultActiveKey='project' activeKey={currentTab} mountOnEnter={true}>
            <Nav variant='tabs' className={classNames(Styles.row, PageStyles.headlineRow)}>
              <Nav.Item>
                <Nav.Link
                  eventKey='project'
                  onClick={() => {
                    navigateTabLocation('project');
                  }}>
                  <h3 className={PageStyles.headline}>{`Project`}</h3>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey='members'
                  onClick={() => {
                    navigateTabLocation('members');
                  }}>
                  <h3 className={classNames(PageStyles.headline)}>{`Members`}</h3>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className={PageStyles.tabContent}>
              <Tab.Pane eventKey='project'>
                <div>
                  <InputContainer>
                    <TextInput
                      name='title'
                      label='Title'
                      defaultValue={project.title}
                      placeholder='Title'
                      onChange={handleChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Quill
                      className={Styles.quillInput}
                      name='requirements'
                      label='Requirements'
                      placeholder='Add any specific requirements for your project. If you have an existing brief you can copy it here or include it as a reference below.'
                      defaultValue={project.requirements}
                      onChange={handleChange}
                      legacyCompat
                    />
                  </InputContainer>
                  <DeadlineList projectUuid={projectUuid} deadlines={project.deadlines} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey='content'></Tab.Pane>
              <Tab.Pane eventKey='members'>
                {hasInitLoad && (
                  <Fragment>
                    <TalentMemberList
                      projectUuid={projectUuid}
                      orgUuid={project.organization?.uuid}
                      members={project.members}
                    />
                  </Fragment>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
      {!hasInitLoad && (
        <div className={Loader.Styles.container}>
          <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
        </div>
      )}
    </AppLayout>
  );
};

export default ProjectDetail;
