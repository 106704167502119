import { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppLayout from '@components/Layout/AppLayout';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { useUser } from '@context/user';

import { TalentRegistrationStatus, ServiceRateType } from '@hiredigital/lib/helpers/enum';
import { toReadableShortDateTime } from '@hiredigital/lib/helpers/date';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from '@styles/PageList.module.scss';
import { getTalentRegistrations } from '@apis/talents';
import withParameters from '@hoc/withParameters';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

const TalentRegistrationList = ({ location, history, initialProps, ...props }) => {
  const currentUser = useUser();
  const [search, setSearch] = useState(initialProps.search);

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        canSort: true,
        minWidth: 200,
        Cell: ({ row: { original: user } }) => {
          return (
            <Link to={`/talent-registrations/${user?.uuid}`}>
              {`${user?.firstName} ${user?.lastName}`.trim() || 'Draft Registration'}
            </Link>
          );
        },
      },
      {
        Header: 'Email',
        id: 'email',
        canSort: true,
        accessor: (u) => u.email,
      },
      {
        Header: 'Engagement',
        id: 'engagement__title',
        canSort: true,
        accessor: (u) => u?.engagement?.title,
      },
      {
        Header: 'Date',
        id: 'created',
        canSort: true,
        width: 150,
        maxWidth: 150,
        accessor: (u) => toReadableShortDateTime(u?.created),
      },
      {
        Header: 'Status',
        id: 'status',
        canSort: true,
        width: 100,
        maxWidth: 150,
        accessor: (u) => TalentRegistrationStatus.getLabel(u?.status),
      },
      {
        Header: 'Rate',
        accessor: (u) => {
          if (isNewRates) {
            const v = u?.talentRates?.[0];
            return v?.maxRate && v?.currencyCode && v?.rateType
              ? `${v?.maxRate} ${v?.currencyCode} ${ServiceRateType.getLabel(v?.rateType)}`
              : null;
          } // else
          const v = u?.serviceRates?.[0];
          return v?.rate && v?.currency && v?.rateType && v?.equivalentTalentSgdPerHour
            ? `${v?.rate} ${v?.currency} ${ServiceRateType.getLabel(v?.rateType)} (S$${
                v?.equivalentTalentSgdPerHour
              }/hr)`
            : null;
        },
      },
      {
        Header: 'Resume',
        id: 'resume',
        width: 100,
        Cell: ({ row: { original: user } }) => {
          return user?.resume ? (
            <a href={user?.resume} target='_blank'>
              {`Download`}
            </a>
          ) : null;
        },
      },
      {
        Header: 'User',
        Cell: ({ row: { original: u } }) => {
          return <Link to={`/profiles/${u?.user?.uuid}`}>{u?.user?.name}</Link>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Talent Registrations');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Talent Registrations`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getTalentRegistrations}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
      />
    </AppLayout>
  );
};

TalentRegistrationList.propTypes = {
  location: PropTypes.object,
};
//export default withDataSource(TalentRegistrationList, 'user-registrations');
export default withParameters(TalentRegistrationList);
