import { useState, useEffect, memo } from 'react';
import convert from 'htmr';
import { getDateFromMonthYear, getDuration, getDates } from '@hiredigital/lib/helpers/date';

import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { topicList } from '@data/topicList';
// import DateInput from '@components/Form/Input/DateInput';
import DatePicker from '@hiredigital/ui/Input/Date/Date';

import Quill from '@hiredigital/ui/Quill/Editor';
import { putUserEmployment, postUserEmployment } from '@apis/users';
import Styles from '../Resume.module.scss';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';

const EmploymentItem = memo((props) => {
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [company, setCompany] = useState('');
  const [topic, setTopic] = useState('');
  const [startMonth, setStartMonth] = useState(null);
  const [startYear, setStartYear] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateInOrder, setDateInOrder] = useState(true);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState({});

  const formSetters = {
    jobTitle: setJobTitle,
    company: setCompany,
    topic: setTopic,
    startMonth: setStartMonth,
    startYear: setStartYear,
    startDate: setStartDate,
    endDate: setEndDate,
    description: setDescription,
  };

  useEffect(() => {
    handleData(props.employment);
    setHasMounted(true);

    if (!props.employment.id) {
      setIsEdit(true);
    }
  }, []);

  const handleData = (response) => {
    setId(response?.id || null);
    setJobTitle(response?.jobTitle || '');
    setCompany(response?.company || '');
    setTopic((response?.topics?.length && response?.topics[0]) || '');
    setStartMonth(response?.startMonth || null);
    setStartYear(response?.startYear || null);
    setEndMonth(response?.endMonth || null);
    setEndYear(response?.endYear || null);
    setDescription(response?.description || '');
    setStartDate(makeValidDate(response?.startMonth, response?.startYear));
    setEndDate(makeValidDate(response?.endMonth, response?.endYear));
  };

  const makeValidDate = (month, year) => {
    return month && year ? new Date(year, month - 1, 1) : null; // month is zero-based
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    if (isEdit) {
      setIsEdit(false);
      Object.keys(props.employment).forEach((k) => {
        formSetters[k] && formSetters[k](props.employment[k]);
      });
    }
    if (!id) {
      props.updateEmployment();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    formSetters[name](value);
  };

  const validateDates = (startMonth, startYear, endMonth, endYear) => {
    if (!endMonth && !endYear) {
      return true;
    }

    if (!startMonth && !startYear) {
      return true;
    }

    const start = getDateFromMonthYear(startMonth, startYear);
    const end = getDateFromMonthYear(endMonth, endYear);
    return start <= end;
  };

  // const handleStartDateChange = (event, data) => {
  //   const { year, month } = data;
  //   let { valid } = data;
  //   if (endDate) {
  //     const isValid = validateDates(month, year, endMonth, endYear);
  //     if (!isValid) {
  //       valid = false;
  //     }
  //   }
  //   setStartMonth(month);
  //   setStartYear(year);
  //   setIsStartDateValid(valid);
  // };

  // const handleEndDateChange = (event, data) => {
  //   const { year, month } = data;
  //   let { valid } = data;
  //   if (endDate) {
  //     const isValid = validateDates(startMonth, startYear, month, year);
  //     if (!isValid) {
  //       valid = false;
  //     }
  //   }
  //   setEndMonth(month);
  //   setEndYear(year);
  //   setIsEndDateValid(valid);
  // };

  const handleSubmit = () => {
    // const startValid = isDateValid(startMonth, startYear).isValid;
    // const endValid = isDateValid(endMonth, endYear).isValid;
    // const isValid = validateDates(startMonth, startYear, endMonth, endYear);

    // setHasError(false);
    // setDateInOrder(isValid);

    if (validateRequiredFields()) {
      const data = {
        jobTitle,
        company,
        topics: topic ? [topic.id] : [],
        startMonth,
        startYear,
        endMonth,
        endYear,
        description,
      };

      setLoading(true);
      if (props.employment.id) {
        putUserEmployment(props.user.uuid, props.employment.id, data).then(
          (response) => {
            handleData(response.data);
            props.updateEmploymentItem(response.data);
            setLoading(false);
            setIsEdit(false);
          },
          (error) => {
            setLoading(false);
          }
        );
      } else {
        postUserEmployment(props.user.uuid, data).then(
          (response) => {
            handleData(response.data);
            setLoading(false);
            setIsEdit(false);
            props.updateEmployment(response.data);
          },
          (error) => {
            setLoading(false);
          }
        );
      }
    } else {
      setHasError(true);
    }
  };

  const handleStartDateChange = (date) => {
    const startYear = date ? format(date, 'yyyy') : null;
    const startMonth = date ? format(date, 'M') : null;
    setStartDate(date);
    setStartYear(startYear);
    setStartMonth(startMonth);
    setErrors({});
  };

  const handleEndDateChange = (date) => {
    // makeDateBefore(startDate, date);
    const endYear = date ? format(date, 'yyyy') : null;
    const endMonth = date ? format(date, 'M') : null;
    setEndDate(date);
    setEndYear(endYear);
    setEndMonth(endMonth);
    setErrors({});
  };

  // const makeDateBefore = (start, end) => {
  //   const date = new Date();
  //   if (start && isAfter(start, end || date)) {
  //     setStartDate(end || date);
  //   }
  // };

  const validateRequiredFields = () => {
    let err = {};

    if (!jobTitle) {
      err = { ...err, jobTitle: 'Enter the job title.' };
    }

    if (!company) {
      err = { ...err, company: 'Enter the company name.' };
    }

    if (!topic?.id) {
      err = { ...err, topic: 'Select an industry.' };
    }

    if (!description) {
      err = { ...err, description: 'Enter the description.' };
    }

    if (!startDate) {
      err = { ...err, startDate: 'Enter the start date.' };
    }

    if (startDate && endDate && isAfter(new Date(startDate), new Date(endDate))) {
      err = { ...err, startDate: 'Start Date should not be later than End Date.' };
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }

    return true;
  };

  return (
    <Card.Item>
      {hasMounted && (
        <div>
          {isEdit && (
            <div>
              <div className={Styles.form}>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      name='jobTitle'
                      label='Job Title'
                      error={errors?.jobTitle}
                      defaultValue={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </InputContainer>

                  <InputContainer>
                    <TextInput
                      name='company'
                      label='Company'
                      error={errors?.company}
                      defaultValue={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </InputContainer>
                </InputGroup>
                <InputGroup>
                  <InputContainer>
                    <Select
                      data-test-id='industry'
                      className={Styles.select}
                      defaultValue={topic}
                      error={errors?.topic}
                      classNamePrefix='s-contact'
                      name='topic'
                      label='Industry'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      options={topicList}
                      onChange={(e) => setTopic(e.target.value)}
                    />
                  </InputContainer>
                  <InputGroup>
                    <InputContainer>
                      <DatePicker
                        id='workStartDate'
                        name='startDate'
                        label='From'
                        dateFormat='MM/yyyy'
                        error={errors?.startDate}
                        onChange={handleStartDateChange}
                        value={startDate}
                        showMonthYearPicker
                      />
                    </InputContainer>

                    <InputContainer>
                      <DatePicker
                        id='workEndDate'
                        name='endDate'
                        label='To'
                        dateFormat='MM/yyyy'
                        onChange={handleEndDateChange}
                        value={endDate}
                        // minDate={startDate ? new Date(startDate) : undefined}
                        showMonthYearPicker
                      />
                    </InputContainer>
                  </InputGroup>
                </InputGroup>
                <InputContainer>
                  <Quill
                    name='description'
                    label='Job Description'
                    placeholder='Job Description'
                    defaultValue={description}
                    onChange={(e) => setDescription(e.target.value)}
                    error={hasError && !description && 'Enter a job description'}
                    legacyCompat
                  />
                </InputContainer>
              </div>

              <div className={Styles.row}>
                <Button
                  name='delete'
                  type={Button.Type.WHITE}
                  onClick={(e) => props.deleteEmployment(props.employment)}>
                  {`Delete`}
                </Button>
                <div className={Styles.action}>
                  <Button
                    name='cancel'
                    type={Button.Type.WHITE_BLUE_OUTLINE}
                    onClick={handleCancel}>
                    {`Cancel`}
                  </Button>

                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={handleSubmit}
                    isLoading={loading}>
                    {`Save`}
                  </Button>
                </div>
              </div>
            </div>
          )}

          {!isEdit && (
            <div className={Styles.listPreview}>
              <div className={Styles.row}>
                <div>
                  <p className={Styles.title}>{jobTitle}</p>
                  <p className={Styles.institution}>{company}</p>
                  <p className={Styles.timeframe}>
                    {`${getDates(startMonth, startYear, endMonth, endYear)} ${getDuration(
                      startMonth,
                      startYear,
                      endMonth,
                      endYear
                    )}`}
                  </p>
                </div>
                <div className={Styles.action}>
                  <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={handleEdit}>
                    {`Edit`}
                  </Button>
                </div>
              </div>
              {description && <div className={Styles.description}>{convert(description)}</div>}
            </div>
          )}
        </div>
      )}
    </Card.Item>
  );
});

export default EmploymentItem;
