import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';

import DualInput from '@hiredigital/ui/Input/DualInput';
import { EngagementInvitationStatus, RateType, TimeRate } from '@hiredigital/lib/helpers/enum';
import withEmailLabel from '@hoc/withEmailLabel';
import { onInvitationTalentUsersLoadOptions, onTeamsLoadOptions } from '@apis/dropdown';
import { isSalesAdmin, isPlacementAdmin } from '@helpers/permissions';
import RateInputGroup from '@components/RateInputGroup';

import Styles from './UserInvitation.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);
const UserSelect = withEmailLabel(memo(Select));

const InvitationForm = ({
  data,
  currentUser,
  onFormChange,
  hideBgColorArea = false,
  talentEngagementRate,
  clientEngagementRate,
  resourceUuid,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onFormChange?.({ name, value: value?.id || value });
  };

  return (
    <div>
      {(isPlacementAdmin(currentUser) ||
        isSalesAdmin(currentUser) ||
        currentUser.adminPermission?.invitationTalentRate ||
        currentUser.adminPermission?.invitationClientRate) && (
        <div className={hideBgColorArea ? undefined : Styles.ratesContainer}>
          {/* visible for Brief Admin, Brief Placement Admin, Admin */}
          {(isPlacementAdmin(currentUser) || currentUser.adminPermission?.invitationTalentRate) &&
            !isNewRates && (
              <Fragment>
                <InputGroup>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentRate'
                          label='Talent Rate (SGD)'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentRate}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentRateType'
                          label='Rate Type'
                          style={{ flexBasis: '115px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={RateType.values}
                          defaultValue={RateType.getEnum(data?.talentRateType)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentMinHours'
                          label='Min Talent Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentMinHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentMinPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.talentMinPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentMaxHours'
                          label='Max Talent Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentMaxHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentMaxPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.talentMaxPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                </InputGroup>
              </Fragment>
            )}
          {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles that is Brief Placement Admin */}
          {(isSalesAdmin(currentUser) || currentUser.adminPermission?.invitationClientRate) &&
            !isNewRates && (
              <Fragment>
                <InputGroup>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandRate'
                          label='Client Rate (SGD)'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandRate}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandRateType'
                          label='Rate Type'
                          style={{ flexBasis: '115px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={RateType.values}
                          defaultValue={RateType.getEnum(data?.brandRateType)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandMinHours'
                          label='Min Client Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandMinHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandMinPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.brandMinPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandMaxHours'
                          label='Max Client Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandMaxHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandMaxPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.brandMaxPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                </InputGroup>
              </Fragment>
            )}

          {/* visible for Brief Admin, Brief Placement Admin, Admin */}
          {(isPlacementAdmin(currentUser) || currentUser.adminPermission?.invitationTalentRate) &&
            isNewRates && (
              <RateInputGroup
                rate={talentEngagementRate}
                resource={'invitation'}
                resourceUuid={resourceUuid}
                hideBgColorArea={hideBgColorArea}
              />
            )}
          {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles that is Brief Placement Admin */}
          {(isSalesAdmin(currentUser) || currentUser.adminPermission?.invitationClientRate) &&
            isNewRates && (
              <RateInputGroup
                isClient
                rate={clientEngagementRate}
                resource={'invitation'}
                resourceUuid={resourceUuid}
                hideBgColorArea={hideBgColorArea}
              />
            )}
        </div>
      )}
      <InputGroup>
        <InputContainer>
          <UserSelect
            id='userID'
            className={Styles.select}
            classNamePrefix='s-contact'
            name='user'
            label='User'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            isPaginated
            isClearable
            loadOptions={(search, loadedOptions, params) =>
              onInvitationTalentUsersLoadOptions(
                data?.engagement?.uuid,
                search,
                loadedOptions,
                params
              )
            }
            defaultValue={data?.user}
            onChange={handleChange}
          />
        </InputContainer>

        <InputContainer>
          <Select
            className={Styles.select}
            classNamePrefix='s-contact'
            data-test-id='team'
            name='team'
            label='Team'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            isPaginated
            isClearable
            loadOptions={onTeamsLoadOptions}
            defaultValue={data?.team}
            onChange={handleChange}
          />
        </InputContainer>

        <InputContainer>
          <Select
            className={Styles.select}
            classNamePrefix='s-contact'
            name='status'
            label='Status'
            getOptionLabel={({ label }) => label}
            getOptionValue={({ id }) => id}
            options={EngagementInvitationStatus.values}
            onChange={handleChange}
            defaultValue={EngagementInvitationStatus.getEnum(data?.status)}
          />
        </InputContainer>
      </InputGroup>
    </div>
  );
};

InvitationForm.propTypes = {
  currentUser: PropTypes.object,
  data: PropTypes.object,
  onFormChange: PropTypes.func,
};

export default InvitationForm;
