import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import classNames from 'classnames';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';

import Side from './Side/Side';
import Header from './Header/Header';
import Styles from './Layout.module.scss';
// import { configAxiosErrorInterceptor } from '@apis/utils';
import { useUserDispatch, useUser } from '@context/user';
import * as FullStory from '@fullstory/browser';
import { getMe } from '@apis/users';

const cookies = new Cookies();

const AppLayout = memo(
  ({
    location,
    children,
    bodyClass,
    header,
    headerClass,
    headerContainerClass,
    onLoad,
    currentUser,
    roles,
    permissions,
  }) => {
    const dispatchUser = useUserDispatch();
    const user = useUser();
    const [isLoaded, setIsLoaded] = useState(false);
    const [noAuth, setNoAuth] = useState(false);
    cookies.set('platform', 'admin');

    const setCurrentUser = async () => {
      if (cookies.get('admin_token') && !currentUser?.uuid) {
        setIsLoaded(false);

        try {
          const response = await getMe();
          setIsLoaded(true);
          dispatchUser({ type: 'UPDATE', payload: response.data });

          try {
            // This is an example script - don't forget to change it!
            FullStory.identify(response.data.uuid, {
              displayName: response.data.name,
              email: response.data.email,
            });
          } catch (err) {}

          // Deprecated
          currentUser.UpdateCurrentUser(response.data);
          if (onLoad) {
            onLoad();
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            setNoAuth(true);
            cookies.remove('admin_token', { path: '/' });
          } else {
            setNoAuth(false);
          }
          setIsLoaded(true);
          if (onLoad) {
            onLoad();
          }
        }
      } else if (!currentUser.uuid) {
        setIsLoaded(true);
        setNoAuth(true);
      } else {
        setIsLoaded(true);

        if (onLoad) {
          onLoad();
        }
      }
    };

    const checkPermissions = () => {
      const hasRole = Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
      const hasPermission = permissions || user.isSuperuser;

      if (hasRole || hasPermission) {
        // console.log('has permissions', hasRole, hasPermission);
        // console.log(
        //   'has permissions',
        //   user.roles?.filter((value) => roles.includes(value))
        // );

        return true;
      } else {
        toast.error(`You don't have access to this page`, { id: 'NoAccess' });

        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 1000);
        return false;
      }
    };

    useEffect(() => {
      setCurrentUser();
      // configAxiosErrorInterceptor((error) => {
      //   toast.error(error, { id: error });
      // });
    }, []);

    useEffect(() => {
      // console.log('change', roles, permissions, user);
      if (user?.roles && (roles || permissions)) {
        checkPermissions();
      }
    }, [user, roles, permissions]);

    return noAuth ? (
      <Redirect
        to={{
          pathname: `/login`,
          state: { from: location },
        }}
      />
    ) : (
      <div className={Styles.container}>
        <Side />
        <div className={Styles.body}>
          {/* <div style={{ position: 'sticky', top: '0', zIndex: 11 }}>
            <Header>{header}</Header>
          </div> */}
          <Header headerContainerClass={headerContainerClass} headerClass={headerClass}>
            {header}
          </Header>
          <div id='scrollContainer' className={classNames(Styles.children, bodyClass)}>
            {isLoaded && children}
          </div>
        </div>
      </div>
    );
  }
);

AppLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
  bodyClass: PropTypes.string,
  header: PropTypes.node,
  currentUser: PropTypes.object,
  onLoad: PropTypes.func,
};

export default AppLayout;
