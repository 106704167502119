import { authRequest } from './utils';

const serializePortfolioOrder = (data) => {
  return {
    ...data,
  };
};

const serializePortfolioItem = (data) => {
  if (data instanceof FormData) return data;
  return {
    ...data,
    skills: data?.skills?.map((obj) => obj.id),
    teams: data?.teams?.map((obj) => obj.uuid),
    publication: data?.publication?.id,
  };
};

export const apiItem = (type, requestType) => {
  // user item closure
  // TODO, review if this is the best solution
  const api = async (uuid, data = undefined, config = undefined) => {
    const url = `admin/${type}/${uuid}/items/`;
    return await authRequest[requestType](url, data, config);
  };
  return api;
};

export const fetchScreenshot = async (itemId, config = undefined) => {
  const url = `admin/portfolio-items/${itemId}/replace-screenshot/`;
  return await authRequest.put(url, config);
};

export const getUserPortfolioItemByUuid = async (itemId, config = undefined) => {
  const url = `admin/portfolio-items/${itemId}/`;
  return await authRequest['get'](url, config);
};

export const getUserPortfolioItems = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/items/`;
  return await authRequest['get'](url, config);
};

export const getTeamPortfolioItems = async (uuid, config = undefined) => {
  const url = `admin/teams/${uuid}/items/`;
  return await authRequest['get'](url, config);
};

export const getUserPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `admin/users/${uuid}/items/${itemId}/`;
  return await authRequest['get'](url, config);
};

export const getTeamPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `admin/teams/${uuid}/items/${itemId}/`;
  return await authRequest['get'](url, config);
};

export const postUserPortfolioItem = async (
  uuid,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `admin/users/${uuid}/items/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postTeamPortfolioItem = async (
  uuid,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `admin/teams/${uuid}/items/`;
  return await authRequest.post(url, serialize(data), config);
};

export const patchUserPortfolioItem = async (
  uuid,
  itemId,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `admin/users/${uuid}/items/${itemId}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const patchTeamPortfolioItem = async (
  uuid,
  itemId,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `admin/teams/${uuid}/items/${itemId}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const deleteUserPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `admin/users/${uuid}/items/${itemId}/`;
  return await authRequest.delete(url, config);
};

export const deleteTeamPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `admin/teams/${uuid}/items/${itemId}/`;
  return await authRequest.delete(url, config);
};

export const patchUserPortfolioCaseItem = async (
  uuid,
  itemId,
  caseId,
  data,
  config = undefined
) => {
  const url = `admin/users/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.patch(url, data, config);
};

export const patchTeamPortfolioCaseItem = async (
  uuid,
  itemId,
  caseId,
  data,
  config = undefined
) => {
  const url = `admin/teams/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.patch(url, data, config);
};

export const postUserPortfolioCaseItem = async (uuid, itemId, data, config = undefined) => {
  const url = `admin/users/${uuid}/items/${itemId}/case-items/`;
  return await authRequest.post(url, data, config);
};

export const postTeamPortfolioCaseItem = async (uuid, itemId, data, config = undefined) => {
  const url = `admin/teams/${uuid}/items/${itemId}/case-items/`;
  return await authRequest.post(url, data, config);
};

export const deleteUserPortfolioCaseItem = async (uuid, itemId, caseId, config = undefined) => {
  const url = `admin/users/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.delete(url, config);
};

export const deleteTeamPortfolioCaseItem = async (uuid, itemId, caseId, config = undefined) => {
  const url = `admin/teams/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.delete(url, config);
};

export const postUserPortfolioOrder = async (
  uuid,
  data,
  serialize = serializePortfolioOrder,
  config = undefined
) => {
  const url = `admin/users/${uuid}/items/move-all/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postUserPortfolioCaseOrder = async (
  uuid,
  itemId,
  data,
  serialize = serializePortfolioOrder,
  config = undefined
) => {
  const url = `admin/users/${uuid}/items/${itemId}/case-items/move-all/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postTeamPortfolioOrder = async (
  uuid,
  data,
  serialize = serializePortfolioOrder,
  config = undefined
) => {
  const url = `admin/teams/${uuid}/items/move-all/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postTeamPortfolioCaseOrder = async (
  uuid,
  itemId,
  data,
  serialize = serializePortfolioOrder,
  config = undefined
) => {
  const url = `admin/teams/${uuid}/items/${itemId}/case-items/move-all/`;
  return await authRequest.post(url, serialize(data), config);
};

export const getTalentPortfolioItemByUuid = async (uuid, config = undefined) => {
  const url = `portfolio-items/${uuid}/`;
  return await authRequest['get'](url, config);
};

export const getItems = async (config) => {
  const url = `admin/portfolio-items/`;
  return await authRequest['get'](url, config);
};

export const getItem = async (uuid) => {
  const url = `admin/portfolio-items/${uuid}/`;
  return await authRequest['get'](url);
};

export const saveItem = async (uuid, data) => {
  const url = `admin/portfolio-items/${uuid}/`;
  return await authRequest.put(url, data);
};

export const replaceScreenshot = async (uuid) => {
  const url = `admin/portfolio-items/${uuid}/replace-screenshot/`;
  return await authRequest.put(url);
};

export const getScreenshotItems = async (config) => {
  const url = `admin/portfolio-item-screenshots/`;
  return await authRequest['get'](url, config);
};

export const saveScreenshotItem = async (uuid, data) => {
  const url = `admin/portfolio-item-screenshots/${uuid}/`;
  return await authRequest.put(url, data);
};

export const replaceScreenshotItem = async (uuid) => {
  const url = `admin/portfolio-item-screenshots/${uuid}/replace-screenshot/`;
  return await authRequest.put(url);
};

export const refreshScreenshotItem = async (uuid) => {
  const url = `admin/portfolio-item-screenshots/${uuid}/restore-screenshot/`;
  return await authRequest.put(url);
};
