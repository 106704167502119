import { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroller';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import queryString from 'query-string';
import Select from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
import { CancelToken } from '@apis/utils';
import { postBrief, deleteBrief, getBriefs } from '@apis/briefs';
import { onOrgLoadOptions } from '@apis/dropdown';

import { AdminBriefStage, BriefStatus } from '@hiredigital/lib/helpers/enum';
import { isAdmin } from '@helpers/permissions';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { withUser } from '@context/user';
import AppLayout from '@components/Layout/AppLayout';
import Table from '@components/Table/Table';
import BriefListItem from './components/BriefListItem';
import Styles from '@styles/PageList.module.scss';

import withParamsResolver from '@hoc/withParamsResolver';

let cancel;

const ClientSelect = withParamsResolver(AsyncPaginate, 'admin/orgs');

class BriefList extends Component {
  static propTypes = {
    location: PropTypes.object,
    search: PropTypes.object,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const search = props.location.search ? queryString.parse(props.location.search) : null;
    this.state = {
      isLoaded: false,
      next: null,
      nextPage: 1,
      briefs: [],
      organization: (search && search.organization) || null,
      search: (search && search.search) || null,
      status: (search && search.status && parseInt(search.status)) || null,
      stage: (search && search.stage && parseInt(search.stage)) || null,
      sort: {
        title: null,
        organization: null,
        stage: null,
        status: null,
        created: null,
      },
      ordering: null,
      hasMore: true,
    };

    this.performDebouncedSearch = debounce(this.performSearch, 750);
  }

  handleSearchChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
        isLoaded: false,
        hasMore: true,
      },
      () => {
        this.performDebouncedSearch(event, name, value);
      }
    );
  };

  handleCreateBrief = () => {
    this.setState({ loading: true });
    postBrief({}).then((response) => {
      this.setState({ loading: false });
      this.props.history.push('/briefs/' + response.data.uuid);
    });
  };

  performSearch = (event, name, value) => {
    this.navigateUrl();
    if (cancel) {
      cancel();
    }

    let params = {
      search: value,
      page: 1,
    };

    if (this.state.status) {
      params.status = this.state.status;
    }

    if (!this.state.search || this.state.search == '') {
      delete params.search;
    }

    if (this.state.stage) {
      params.stage = this.state.stage;
    }
    if (this.state.organization) {
      params.organization = this.state.organization;
    }
    if (this.state.ordering) {
      params.ordering = this.state.ordering;
    }
    this.setState({
      isLoaded: false,
      hasMore: true,
    });
    this.getBriefs(params, true);
  };

  handleOrgFilterChange = (o) => {
    this.setState(
      {
        organization: o?.uuid,
      },
      () => {
        this.navigateUrl();

        let params = {
          search: this.state.search,
          page: 1,
        };

        if (this.state.status) {
          params.status = this.state.status;
        }
        if (this.state.stage) {
          params.stage = this.state.stage;
        }
        if (this.state.organization) {
          params.organization = this.state.organization;
        }
        if (this.state.ordering) {
          params.ordering = this.state.ordering;
        }

        this.getBriefs(params, true);
      }
    );
  };

  handleFilterChange = (name, value) => {
    this.setState(
      {
        [name]: value ? value.id : null,
      },
      () => {
        this.navigateUrl();

        let params = {
          search: this.state.search,
          page: 1,
        };

        if (this.state.status) {
          params.status = this.state.status;
        }

        if (this.state.stage) {
          params.stage = this.state.stage;
        }
        if (this.state.organization) {
          params.organization = this.state.organization;
        }
        if (this.state.ordering) {
          params.ordering = this.state.ordering;
        }

        this.getBriefs(params, true);
      }
    );
  };

  handleDeleteBrief = (briefUuid) => {
    deleteBrief(briefUuid).then(
      (response) => {
        // const inv = this.state.briefs.findIndex((a) => a.uuid === briefUuid);
        // this.setState((state) => (this.state.briefs.splice(inv, 1), state));
        this.setState({ briefs: this.state.briefs.filter((v) => v?.uuid !== briefUuid) });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  navigateUrl = () => {
    const params = {};
    if (this.state.search) {
      params.search = this.state.search;
    }
    if (this.state.status) {
      params.status = this.state.status;
    }
    if (this.state.organization) {
      params.organization = this.state.organization;
    }
    if (this.state.stage) {
      params.stage = this.state.stage;
    }

    const urlSearchParam = queryString.stringify(params);
    this.props.history.push(
      this.props.location.pathname + (urlSearchParam ? '?' + urlSearchParam : '')
    );
  };

  getBriefs = (params, clearResults = false) => {
    const data = {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };

    getBriefs(data).then(
      (response) => {
        let briefs;
        if (clearResults) {
          briefs = response.data.results;
        } else {
          briefs = this.state.briefs.concat(response.data.results);
        }
        this.setState({
          briefs,
          next: response.data.meta.next,
          nextPage: response.data.meta.nextPage,
          count: response.data.meta.count,
          isLoaded: true,
          ...(!response.data.meta.nextPage ? { hasMore: false } : {}),
        });
      },
      (error) => {
        console.log('Error occured during page request.');
        this.setState({ isLoaded: false, hasMore: false });
      }
    );
  };

  componentDidMount = () => {
    setPageTitle('Briefs');
    this.scrollParentRef = document.getElementById('scrollContainer');
    this.setState(
      {
        search: this.state.search ? this.state.search : '',
        status: this.state.status,
        stage: this.state.stage,
      },
      () => {
        this.navigateUrl();

        let params = {
          page: 1,
          search: this.state.search,
        };

        if (this.state.status) {
          params.status = this.state.status;
        }

        if (this.state.stage) {
          params.stage = this.state.stage;
        }
        if (this.state.organization) {
          params.organization = this.state.organization;
        }
        if (this.state.ordering) {
          params.ordering = this.state.ordering;
        }

        this.getBriefs(params, true);
      }
    );

    this.parentNode = document.getElementById('scrollContainer');
  };

  loadMoreBriefs = (page) => {
    let params = {
      search: this.state.search,
      page,
    };

    if (this.state.status) {
      params.status = this.state.status;
    }

    if (this.state.stage) {
      params.stage = this.state.stage;
    }
    if (this.state.organization) {
      params.organization = this.state.organization;
    }
    if (this.state.ordering) {
      params.ordering = this.state.ordering;
    }

    this.getBriefs(params, false);
  };

  sortBriefs = (type) => {
    let typeCopy = this.state.sort[type];

    this.setState(
      {
        sort: {},
        briefs: [],
        isLoaded: false,
        hasMore: true,
      },
      () => {
        this.setState(
          {
            sort: {
              ...(this.state.sort ? this.state.sort : {}),
              [type]: typeof typeCopy === 'undefined' ? true : !typeCopy,
            },
          },
          () => {
            let prefix = this.state.sort[type] === true ? '' : '-';

            this.setState({
              ordering: prefix + type,
            });

            let params = {
              search: this.state.search,
              page: 1,
              ordering: prefix + type,
            };

            if (this.state.status) {
              params.status = this.state.status;
            }

            if (this.state.stage) {
              params.stage = this.state.stage;
            }

            this.getBriefs(params, true);
          }
        );
      }
    );
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        bodyClass={Styles.wideBody}
        header={
          <div className={Styles.headerRow}>
            <div className={Styles.headerActive}>{`Briefs`}</div>
            <input
              className={Styles.headerSearch}
              name='search'
              type='text'
              placeholder='Search'
              value={this.state.search}
              onChange={this.handleSearchChange}
            />
            {this.props.currentUser && isAdmin(this.props.currentUser) && (
              <>
                <ClientSelect
                  id='organizationID'
                  className={Styles.headerSelect}
                  selectValue={this.state.organization}
                  defaultValue={this.state.organization}
                  name='organization'
                  placeholder='Client'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ uuid }) => uuid}
                  onChange={this.handleOrgFilterChange}
                  isPaginated
                  loadOptions={onOrgLoadOptions}
                  debounceTimeout={500}
                  isClearable
                  additional={{ page: 1 }}
                  SelectComponent={Select}
                  createOptionPosition={'first'}
                />
                <Select
                  id='status'
                  className={classNames(Styles.headerSelect, Styles.smallSelect)}
                  selectValue={this.state.status}
                  defaultValue={BriefStatus.getEnum(this.state.status)}
                  name='status'
                  placeholder='Status'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  onChange={(status) => this.handleFilterChange('status', status)}
                  options={BriefStatus.values}
                  isClearable
                />
                <Select
                  id='stage'
                  className={classNames(Styles.headerSelect, Styles.smallSelect)}
                  selectValue={this.state.stage}
                  defaultValue={AdminBriefStage.getEnum(this.state.stage)}
                  name='stage'
                  placeholder='Stage'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  onChange={(stage) => this.handleFilterChange('stage', stage)}
                  options={AdminBriefStage.values}
                  isClearable
                />
              </>
            )}
            <Button
              className={Styles.addButton}
              type={Button.Type.BLUE}
              onClick={this.handleCreateBrief}
              isLoading={this.state.loading}>
              {` Add New Brief`}
            </Button>
          </div>
        }>
        <div className={Styles.talentContainer} style={{ maxWidth: '1650px', overflow: 'scroll' }}>
          <div className={Styles.talentHeader}>
            <Table.Header
              width='300px'
              className={classNames(
                Styles.sort,
                this.state.sort.title === false
                  ? Styles.sortInactive
                  : this.state.sort.title
                  ? Styles.sortActive
                  : ''
              )}
              onClick={() => this.sortBriefs('title')}>{`Title`}</Table.Header>
            <Table.Header
              width='225px'
              className={classNames(
                Styles.sort,
                this.state.sort.organization__name === false
                  ? Styles.sortInactive
                  : this.state.sort.organization__name
                  ? Styles.sortActive
                  : ''
              )}
              onClick={() => this.sortBriefs('organization__name')}>{`Organization`}</Table.Header>
            <Table.Header width='300px'>{`Notes`}</Table.Header>
            <Table.Header
              width='180px'
              className={classNames(
                Styles.sort,
                this.state.sort.stage === false
                  ? Styles.sortInactive
                  : this.state.sort.stage
                  ? Styles.sortActive
                  : ''
              )}
              onClick={() => this.sortBriefs('stage')}>{`Stage`}</Table.Header>
            <Table.Header
              width='220px'
              className={classNames(
                Styles.sort,
                this.state.sort.status === false
                  ? Styles.sortInactive
                  : this.state.sort.status
                  ? Styles.sortActive
                  : ''
              )}
              onClick={() => this.sortBriefs('status')}>{`Status`}</Table.Header>
            <Table.Header
              width='130px'
              className={classNames(
                Styles.sort,
                this.state.sort.created === false
                  ? Styles.sortInactive
                  : this.state.sort.created
                  ? Styles.sortActive
                  : ''
              )}
              onClick={() => this.sortBriefs('created')}>{`Created`}</Table.Header>
            <Table.Header width='250px'>{`Action`}</Table.Header>
            <Table.Header width='43px' />
          </div>
          {this.state.isLoaded && this.state.briefs && this.state.briefs.length > 0 && (
            <InfiniteScroll
              initialLoad={true}
              pageStart={1}
              loadMore={this.loadMoreBriefs}
              hasMore={this.state.hasMore}
              threshold={500}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
              loader={
                <div key={0}>
                  <Loader />
                </div>
              }>
              <Fragment>
                {this.state.briefs.map((brief, index) => (
                  <BriefListItem
                    key={brief.uuid}
                    brief={brief}
                    onDelete={this.handleDeleteBrief}
                    currentUser={this.props.currentUser}
                    history={this.props.history}
                  />
                ))}
              </Fragment>
            </InfiniteScroll>
          )}
        </div>
        {!this.state.isLoaded && (
          <div className={Loader.Styles.container}>
            <Loader />
          </div>
        )}
        {this.state.isLoaded && (!this.state.briefs || this.state.briefs.length === 0) && (
          <div
            className={classNames(
              Styles.emptyState,
              Styles.talentContainer
            )}>{`No Briefs Found`}</div>
        )}
      </AppLayout>
    );
  }
}
export default withUser(BriefList);
