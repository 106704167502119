import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Tooltip, Position } from 'evergreen-ui';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Checkbox from '@hiredigital/ui/Checkbox';
import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';

import { patchEvent } from '@apis/tracking';
import { toReadableShortDateTime } from '@hiredigital/lib/helpers/date';

import ScreenshotList from './ScreenshotList';
import {
  convertToDuration,
  durationToHours,
  durationToTimestamps,
} from '@hiredigital/lib/helpers/date';

import ListStyles from '@styles/PageList.module.scss';
import Styles from '../list.module.scss';

const EntryListItem = ({
  className,
  onEdit,
  onDelete,
  event,
  isDuration,
  showActionItems = true,
}) => {
  const [locked, setLocked] = useState(event.locked);
  const [expand, setExpand] = useState(false);

  const handleEdit = () => onEdit?.(event);

  const handleDelete = () => onDelete?.(event);

  const handleViewScreenshots = () => setExpand(true);

  const handleLockedChange = (e) => {
    setLocked(e.target.checked);
    patchEvent(event.uuid, { ...event, locked: e.target.checked });
  };

  useEffect(() => {
    setLocked(event?.locked);
  }, [event?.locked]);

  return (
    <Fragment>
      <div
        className={classNames(
          className,
          Styles.list,
          locked && showActionItems ? Styles.listLocked : ''
        )}>
        <div className={Styles.row}>
          <div>
            <Ellipsis className={classNames(Styles.title, event.task?.name ? '' : Styles.blank)}>
              {event.task?.name || 'No task name'}
            </Ellipsis>
            <Ellipsis
              className={classNames(
                Styles.description,
                event.task?.timesheet?.talentTitle ? '' : Styles.blank
              )}>
              {event.task?.timesheet?.talentTitle || 'No assignment'}
            </Ellipsis>
          </div>
          <div className={Styles.action}>
            {event.manual && <span className={ListStyles.label}>{`Manual`}</span>}
            {showActionItems && (
              <>
                <div>
                  <Tooltip
                    position={Position.LEFT}
                    content={`Mark as ${locked ? 'Unpaid' : 'Paid'}`}>
                    <span>
                      <Checkbox name='paid' checked={locked} onChange={handleLockedChange} />
                    </span>
                  </Tooltip>
                </div>
                <div className={Styles.listSection}>
                  {(event.screenshotCount && (
                    <a
                      href='#'
                      onClick={expand ? () => setExpand((v) => !v) : handleViewScreenshots}>
                      <Tooltip
                        position={Position.TOP}
                        content={`${expand ? 'Hide' : 'View'} Screenshot${
                          event.screenshotCount > 1 ? 's' : ''
                        }`}>
                        <span>
                          {`${event.screenshotCount} Screenshot${
                            event.screenshotCount > 1 ? 's' : ''
                          }`}
                        </span>
                      </Tooltip>
                    </a>
                  )) ||
                    ''}
                </div>
              </>
            )}

            <div className={Styles.listSection}>
              {event.startTime && <span>{toReadableShortDateTime(event.startTime)}</span>}
            </div>
            <div className={Styles.listSectionSmall}>
              <span>
                {isDuration
                  ? durationToHours(convertToDuration(event.duration) || 0).toFixed(5)
                  : durationToTimestamps(convertToDuration(event.duration))}
              </span>
            </div>
            {showActionItems && (
              <div className={Styles.listOption}>
                <SplitButton
                  mainButtonContainerClass={Styles.splitButton}
                  more={[
                    { text: 'Edit', onClick: handleEdit },
                    { text: 'Delete', onClick: handleDelete },
                  ]}
                  direction={'left_over'}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {expand && <ScreenshotList eventUuid={event.uuid} user={event.user} />}
    </Fragment>
  );
};

export default EntryListItem;
