import Styles from './DialogMessage.module.scss';
import Button from '@hiredigital/ui/Button/Button';
import { SEGMENT_OPTIONS } from './SegmentViewOptions';
import IconExclamation from '@hiredigital/ui/Icon/icons/exclamation.inline.svg';

const DialogMessage = ({ total, hasDescription = true }) => (
  <>
    <div className={Styles.warning}>
      <p>
        <IconExclamation className={Styles.iconWarning} />
        {` Before opening resumes, you must be logged into JazzHR. `}
        {` Please log in first, then return here to continue. `}
      </p>
      <a href='https://app.jazz.co/app/v2/login' target='_blank'>
        <Button type={Button.Type.WHITE}>{`Log into JazzHR`}</Button>
      </a>
    </div>
    <p className={Styles.description}>
      {`You are about to view `}
      <b>{total}</b>
      {` resume${total > 1 ? 's' : ''} in ${total > 1 ? '' : 'a '}new browser tab${
        total > 1 ? 's' : ''
      }. `}
      {total > SEGMENT_OPTIONS[0] && (
        <span>
          <IconExclamation className={Styles.iconWarning} />
          {` Too many tabs may slow down your computer.`}
        </span>
      )}
    </p>
  </>
);

export default DialogMessage;
