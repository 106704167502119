import { authRequest } from './utils';

export const getOrgs = async (config) => {
  const url = `admin/orgs/`;
  return await authRequest.get(url, config);
};

export const getOrg = async (orgUuid, config) => {
  const url = `admin/orgs/${orgUuid}/`;
  return await authRequest.get(url, config);
};

export const postOrg = async (data, config) => {
  const url = `admin/orgs/`;
  return await authRequest.post(url, data, config);
};

export const patchOrg = async (orgUuid, data, config) => {
  const url = `admin/orgs/${orgUuid}/`;
  return await authRequest.patch(url, data, config);
};

export const putOrg = async (orgUuid, data, config) => {
  const url = `admin/orgs/${orgUuid}/`;
  return await authRequest.put(url, data, config);
};

export const deleteOrgInvitation = async (orgUuid, invitationId) => {
  const url = `admin/orgs/${orgUuid}/invitations/${invitationId}/`;
  return await authRequest.delete(url);
};

export const putOrgInvitation = async (orgUuid, invitationId, data) => {
  const url = `admin/orgs/${orgUuid}/invitations/${invitationId}/`;
  return await authRequest.put(url, data);
};

export const createOrgInvitation = async (orgUuid, data) => {
  const url = `admin/orgs/${orgUuid}/invitations/`;
  return await authRequest.post(url, data);
};

export const getOrgInvitations = async (orgUuid, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/invitations/`;
  return await authRequest.get(url, config);
};

export const getOrgUsers = async (orgUuid, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/users/`;
  return await authRequest.get(url, config);
};

export const getOrgUser = async (orgUuid, userUuid, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/users/${userUuid}/`;
  return await authRequest.get(url, config);
};

export const createOrgUser = async (orgUuid, data, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/users/`;
  return await authRequest.post(url, data, config);
};

export const deleteOrgUser = async (orgUuid, memberId, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/users/${memberId}/`;
  return await authRequest.delete(url, config);
};

export const putOrgUser = async (orgUuid, memberId, data, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/users/${memberId}/`;
  return await authRequest.put(url, data, config);
};

export const patchOrgUser = async (orgUuid, memberId, data, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/users/${memberId}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteOrgTalent = async (orgUuid, talentUuid) => {
  const url = `admin/orgs/${orgUuid}/talents/${talentUuid}/`;
  return await authRequest.delete(url);
};

export const getOrgTalents = async (orgUuid, config = undefined) => {
  const url = `admin/orgs/${orgUuid}/talents/`;
  return await authRequest.get(url, config);
};

export const putOrgTalent = async (orgUuid, talentUuid, data) => {
  const url = `admin/orgs/${orgUuid}/talents/${talentUuid}/`;
  return await authRequest.put(url, data);
};

export const getOrgRegistrations = async (config = undefined) => {
  const url = `admin/org-registrations/`;
  return await authRequest.get(url, config);
};

export const getOrgRegistration = async (orgUuid, config = undefined) => {
  const url = `admin/org-registrations/${orgUuid}/`;
  return await authRequest.get(url, config);
};

export const deleteOrgRegistration = async (orgUuid) => {
  const url = `admin/org-registrations/${orgUuid}/`;
  return await authRequest.delete(url);
};

export const putOrgRegistration = async (orgUuid, data, config = undefined) => {
  const url = `admin/org-registrations/${orgUuid}/`;
  return await authRequest.put(url, data, config);
};

export const convertClientRegistration = async (uuid) => {
  const url = `admin/org-registrations/${uuid}/convert-to-organization/`;
  return authRequest.post(url);
};

export const postOrgLink = async (uuid, data, config = undefined) => {
  const url = `admin/orgs/${uuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postOrgFile = async (uuid, data, config = undefined) => {
  const url = `admin/orgs/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteOrgLink = async (uuid, linkId, config = undefined) => {
  const url = `admin/orgs/${uuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteOrgFile = async (uuid, fileId, config = undefined) => {
  const url = `admin/orgs/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};

export const getOrgAssignments = async (orgUuid, config) => {
  const url = `admin/orgs/${orgUuid}/assignments/`;
  return await authRequest.get(url, config);
};

export const putOrgAssignments = async (orgUuid, assignmentuuid, data, config) => {
  const url = `admin/orgs/${orgUuid}/assignments/${assignmentuuid}/`;
  return await authRequest.put(url, data, config);
};

export const getOrgBriefs = async (orgUuid, config) => {
  const url = `admin/orgs/${orgUuid}/brief/`;
  return await authRequest.get(url, config);
};
