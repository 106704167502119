import { Component } from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

import { patchOrg } from '@apis/orgs';
import { countryList } from '@data/countryList';
import { timezoneList } from '@data/timezoneList';
import { PreferredPlatform, ConferencePlatform } from '@hiredigital/lib/helpers/enum';

class CallPreference extends Component {
  static propTypes = {
    orgUuid: PropTypes.string,
    data: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      ...props.data,
      conferencePlatform: props.data?.conferencePlatform || ConferencePlatform.GOOGLE.id,
    };
    this.saveTimeout = null;
  }

  handleChange = (event, valueKey) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: valueKey ? value[valueKey] : value,
      },
      () => {
        this.saveCallPreferenceWithDelay();
      }
    );
  };

  handlePhoneCountryChange = (value) => {
    this.setState(
      {
        phoneCountry: value,
      },
      () => {
        this.saveCallPreferenceWithDelay();
      }
    );
  };

  handlePhoneNumberChange = (value) => {
    this.setState(
      {
        phoneNumber: value,
      },
      () => {
        this.saveCallPreferenceWithDelay();
      }
    );
  };

  saveCallPreferenceWithDelay = (timeout = 2000) => {
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.saveCallPreference();
    }, timeout);
  };

  saveCallPreference = () => {
    const { preferred, skype, zoom, phoneCountry, phoneNumber, timezone, conferencePlatform } =
      this.state;
    const data = {
      contact: {
        preferred,
        skype,
        zoom,
        phoneCountry,
        phoneNumber,
        timezone: timezone?.id,
        conferencePlatform,
      },
    };

    this.setState({ isSaving: true });
    patchOrg(this.props.orgUuid, data).then((response) => {
      this.setState({ isSaving: false });
    });
  };

  render() {
    return (
      <Card>
        <Card.Header title={`Call Details`} />
        <Card.Item>
          <div className={Styles.form}>
            <InputGroup>
              {/* <div className={Styles.phoneCountry}>
                <Select
                  data-test-id='phoneCountry'
                  className={Styles.select}
                  value={this.state.phoneCountry}
                  classNamePrefix='s-contact'
                  name='phoneCountry'
                  label='Code'
                  getOptionLabel={({ dialCode }) => dialCode}
                  getOptionValue={({ id }) => id}
                  options={countryList}
                  onChange={this.handleChange}
                />
              </div> */}

              <InputContainer>
                <Select
                  data-test-id='timezone'
                  value={this.state.timezone}
                  classNamePrefix='s-contact'
                  name='timezone'
                  label='Timezone'
                  getOptionLabel={({ name, label, offset }) => `${name || label} (GMT${offset})`}
                  getOptionValue={({ id }) => id}
                  options={timezoneList}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <PhoneNumber
                  phoneCountry={this.state.phoneCountry}
                  phoneNumber={this.state.phoneNumber}
                  onPhoneCountryChange={this.handlePhoneCountryChange}
                  onPhoneNumberChange={this.handlePhoneNumberChange}
                  getCountries={async () => ({ data: countryList })}
                  shouldValidate={false}
                />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer style={{ marginRight: '5px' }}>
                <TextInput
                  name='skype'
                  label='Skype'
                  value={this.state.skype}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer style={{ marginLeft: '5px' }}>
                <TextInput
                  name='zoom'
                  label='Zoom'
                  value={this.state.zoom}
                  onChange={this.handleChange}
                />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer style={{ marginLeft: '5px' }}>
                <Select
                  data-test-id='preferred'
                  value={PreferredPlatform.getEnum(this.state.preferred)}
                  classNamePrefix='s-contact'
                  name='preferred'
                  label='Call Preference'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={PreferredPlatform.values}
                  onChange={(e) => this.handleChange(e, 'id')}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  defaultValue={ConferencePlatform.getEnum(this.state.conferencePlatform)}
                  classNamePrefix='s-contact'
                  name='conferencePlatform'
                  label='Conference Platform'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={ConferencePlatform.values}
                  onChange={(e) => this.handleChange(e, 'id')}
                />
              </InputContainer>
            </InputGroup>
          </div>
        </Card.Item>

        <Card.Footer right>
          <Button
            onClick={() => {
              this.saveCallPreferenceWithDelay(0);
            }}
            name='submit'
            className={Styles.updateButton}
            type={Button.Type.BLUE}
            isLoading={this.state.isSaving}>
            {`Update Call Details`}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default CallPreference;
