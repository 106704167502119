import classNames from 'classnames';
import PropTypes from 'prop-types';

import MultiLink from './Input/MultiLink';
import Password from './Password/Password';
import NewSingle from '@hiredigital/ui/Form/File/NewSingle';
const Form = (props) => {
  return <form />;
};

Form.SingleUpload = NewSingle;

Form.MultiLink = MultiLink;
Form.Password = Password;

Form.propTypes = { className: PropTypes.string };
export default Form;
