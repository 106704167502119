import { Component } from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';

import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

import { patchResource } from '@apis/common';
import { countryList } from '@data/countryList';
import { timezoneList } from '@data/timezoneList';
import { PreferredPlatform, Currency } from '@hiredigital/lib/helpers/enum';

class ProfileContact extends Component {
  static propTypes = {
    userUuid: PropTypes.string,
    user: PropTypes.object,
    type: PropTypes.string,
  };

  static defaultProps = {
    type: 'users', // Also used for teams
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      ...props.user.contact,
      ...props.user.profile,
      email: props.user?.email,
      username: props.user?.username,
    };
    this.saveTimeout = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isSaving !== this.state.isSaving) {
      return true;
    }

    return false;
  }

  handleChange = (event, valueKey) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: valueKey ? value[valueKey] : value,
      },
      () => {
        this.saveProfileContactWithDelay();
      }
    );
  };

  handlePhoneCountryChange = (value) => {
    this.setState(
      {
        phoneCountry: value,
      },
      () => {
        this.saveProfileContactWithDelay();
      }
    );
  };

  handlePhoneNumberChange = (value) => {
    this.setState(
      {
        phoneNumber: value,
      },
      () => {
        this.saveProfileContactWithDelay();
      }
    );
  };

  saveProfileContactWithDelay = (timeout = 3000) => {
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.saveProfileContact();
    }, timeout);
  };

  saveProfileContact = () => {
    //const url = `admin/${this.props.type}/${this.props.userUuid}/`;

    const { preferred, skype, zoom, phoneCountry, phoneNumber, timezone, currency, email } =
      this.state;

    const data = {
      contact: {
        preferred,
        skype,
        zoom,
        phoneCountry,
        phoneNumber,
      },
      profile: {
        currency,
        timezone: timezone.id,
      },
      email,
    };

    this.setState({ isSaving: true });
    patchResource(this.props.type, this.props.userUuid, data).then((response) => {
      this.setState({ isSaving: false });
      if (this.props.onUserUpdate) {
        this.props.onUserUpdate(response.data);
      }
    });
  };

  render() {
    return (
      <Card>
        <Card.Header title={`Contact Settings`} />
        <Card.Item>
          <div className={Styles.form} data-test-id='contact-settings-section'>
            {this.props.type === 'users' && (
              <InputGroup>
                <InputContainer>
                  <TextInput
                    name='username'
                    label='Username'
                    disabled
                    defaultValue={this.state.username}
                    onChange={this.handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <TextInput
                    name='email'
                    label='E-mail'
                    disabled
                    defaultValue={this.state.email}
                    onChange={this.handleChange}
                  />
                </InputContainer>
              </InputGroup>
            )}
            <InputGroup>
              <InputContainer>
                <Select
                  data-test-id='timezone'
                  className={Styles.select}
                  defaultValue={this.state.timezone}
                  classNamePrefix='s-contact'
                  name='timezone'
                  label='Timezone'
                  getOptionLabel={({ name, label, offset }) => {
                    return (name || label) + ' (GMT' + offset + ')';
                  }}
                  getOptionValue={({ id }) => id}
                  options={timezoneList}
                  onChange={this.handleChange}
                />
              </InputContainer>
              {this.props.type === 'users' && (
                <InputContainer>
                  <Select
                    data-test-id='currency'
                    className={Styles.select}
                    defaultValue={Currency.getEnum(this.state.currency)}
                    classNamePrefix='s-contact'
                    name='currency'
                    label='Currency Code'
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={Currency.values}
                    onChange={(e) => this.handleChange(e, 'id')}
                  />
                </InputContainer>
              )}
            </InputGroup>
            <InputGroup>
              {/* <div className={Styles.phoneCountry}>
                <div className={Styles.phone}>
                  <Select
                    data-test-id='phoneCountry'
                    className={Styles.select}
                    defaultValue={this.state.phoneCountry}
                    classNamePrefix='s-contact'
                    name='phoneCountry'
                    label='Code'
                    getOptionLabel={({ dialCode }) => dialCode}
                    getOptionValue={({ id }) => id}
                    options={countryList}
                    onChange={this.handleChange}
                  />
                </div> */}
              <InputContainer>
                <PhoneNumber
                  phoneCountry={this.state.phoneCountry}
                  phoneNumber={this.state.phoneNumber}
                  onPhoneCountryChange={this.handlePhoneCountryChange}
                  onPhoneNumberChange={this.handlePhoneNumberChange}
                  getCountries={async () => ({ data: countryList })}
                  shouldValidate={false}
                />
                {/* <TextInput
                    name='phoneNumber'
                    label='Phone Number'
                    defaultValue={this.state.phoneNumber}
                    onChange={this.handleChange}
                  /> */}
              </InputContainer>
              {/* </div> */}
              <InputContainer>
                <TextInput
                  name='skype'
                  label='Skype'
                  defaultValue={this.state.skype}
                  onChange={this.handleChange}
                />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <TextInput
                  name='zoom'
                  label='Zoom'
                  defaultValue={this.state.zoom}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  data-test-id='preferred'
                  className={Styles.select}
                  defaultValue={PreferredPlatform.getEnum(this.state.preferred)}
                  classNamePrefix='s-contact'
                  name='preferred'
                  label='Call Preference'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={PreferredPlatform.values}
                  onChange={(e) => this.handleChange(e, 'id')}
                />
              </InputContainer>
            </InputGroup>
          </div>
        </Card.Item>
        <Card.Footer right>
          <Button
            onClick={() => {
              this.saveProfileContactWithDelay(0);
            }}
            name='submit'
            type={Button.Type.BLUE}
            isLoading={this.state.isSaving}>
            {`Save Settings`}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default ProfileContact;
