import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { getServiceRates } from '@apis/serviceRates';
import ServiceRateItem from './ServiceRateItem';
import Styles from './Styles.module.scss';

const ServiceRateList = ({
  resourceUuid,
  resource,
  talentPermission,
  clientPermission,
  userSkills,
  showCopySkillsOption = true,
  hideClient,
  hideTalent,
}) => {
  const [list, setList] = useState([]);
  const [hasInitLoad, setHasInitLoad] = useState(false);

  useEffect(() => {
    const loadServiceRateList = () => {
      return getServiceRates(resource, resourceUuid);
    };

    const loadServices = async () => {
      const {
        data: { results },
      } = await loadServiceRateList();
      setList(results);
      setHasInitLoad(true);
    };

    loadServices();
  }, [resource, resourceUuid]);

  const handleCreateService = () => {
    setList([...list, {}]);
  };

  const updateServiceRate = (service) => {
    let updatedList = list;
    if (service) {
      updatedList = list.filter((el) => {
        return el && el.uuid;
      });
      updatedList.push(service);
    } else {
      updatedList.pop({});
    }
    setList(updatedList);
  };

  const handleDeleteServiceRate = (serviceUuid, serviceIndex) => {
    if (serviceUuid) {
      setList(list.filter(({ uuid }) => uuid !== serviceUuid));
    } else {
      setList(list.filter((e, index) => index !== serviceIndex));
    }
  };

  return (
    <Card>
      <Card.Header>
        <span>{`Rates`}</span>
        <div className={Styles.action}>
          <Button name='add new' type={Button.Type.BLUE} onClick={handleCreateService}>
            {`Add New Service Rate`}
          </Button>
        </div>
      </Card.Header>
      <Fragment>
        {list?.length === 0 && (
          <Card.Item>
            <p className={Styles.empty}>{`No rates have been added.`}</p>
          </Card.Item>
        )}
        <div>
          {hasInitLoad &&
            list?.map((service, index) => (
              <ServiceRateItem
                index={index}
                service={service}
                userSkills={userSkills}
                resourceUuid={resourceUuid}
                resource={resource}
                onUpdateServiceRate={updateServiceRate}
                onDeleteService={handleDeleteServiceRate}
                key={service.uuid || index}
                talentPermission={talentPermission}
                clientPermission={clientPermission}
                showCopySkillsOption={showCopySkillsOption}
                hideClient={hideClient}
                hideTalent={hideTalent}
              />
            ))}
        </div>
      </Fragment>
    </Card>
  );
};

ServiceRateList.propTypes = {
  resourceUuid: PropTypes.string,
  resource: PropTypes.string,
  talentPermission: PropTypes.bool,
  clientPermission: PropTypes.bool,
  userSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  showCopySkillsOption: PropTypes.bool,
  hideClient: PropTypes.bool,
  hideTalent: PropTypes.bool,
};

export default ServiceRateList;
