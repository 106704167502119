import { Fragment, useState } from 'react';
import { getUserEducation, deleteUserEducation } from '@apis/users';
import EducationItem from './EducationItem';
import { dateSort } from '@hiredigital/lib/helpers/utils';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import Styles from '../Resume.module.scss';

const Education = ({ user }) => {
  const [educationList, setEducationList] = useState(user.education || []);
  const [isEditing, setIsEditing] = useState(false);

  const getEducationList = () => {
    getUserEducation(user.uuid).then(({ data }) => {
      setEducationList(data.results);
    });
  };

  const handleNewEducation = () => {
    setEducationList([...educationList, {}]);
    setIsEditing(true);
  };

  const updateEducation = (education) => {
    const list = educationList.filter((el) => {
      return el && el.id;
    });

    if (education) {
      list.push(education);
    } else {
      // setState({ isEditing: false });
    }
    setEducationList(list);
    setIsEditing(false);
  };

  const deleteEducation = (education) => {
    if (education.id) {
      deleteUserEducation(user.uuid, education.id).then(
        (response) => {
          getEducationList();
        },
        (error) => {}
      );
    } else {
      updateEducation();
    }
  };

  return (
    <Card>
      <Card.Header>
        <span>{`Education`}</span>
        <div className={Styles.action} data-test-id='education-section'>
          <Button name='add new' type={Button.Type.BLUE} onClick={handleNewEducation}>
            {`Add New`}
          </Button>
        </div>
      </Card.Header>
      <Fragment>
        {educationList.length === 0 && (
          <Card.Item>
            <p className={Styles.empty}>{`No education history has been added.`}</p>
          </Card.Item>
        )}
        <div>
          {educationList &&
            educationList
              .sort(dateSort)
              .map((education, index) => (
                <EducationItem
                  user={user}
                  education={education}
                  updateEducation={updateEducation}
                  deleteEducation={deleteEducation}
                  key={education.id || index}
                />
              ))}
        </div>
      </Fragment>
    </Card>
  );
};

export default Education;
