import { useEffect, useState } from 'react';
import { authRequest } from '@apis/utils';
import Loader from '@hiredigital/ui/Loader';

const withParamsResolver = (BaseComponent, resource) => {
  const WrappedComponent = (props) => {
    const [value, setValue] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      const id = props?.defaultValue;

      if (!resource) {
        const options = props?.options || [];
        setValue(options.find((v) => [v?.id, v?.value].filter((v) => !!v).includes(id)));
        setIsLoaded(true);
        return;
      }

      if (!id) {
        setIsLoaded(true);
        return;
      }

      authRequest
        .get(`${resource}/${id}`)
        .then(({ data }) => {
          setValue(data);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.error(error);
          setIsLoaded(true);
        });
    }, [props?.defaultValue, props?.options]);

    return isLoaded ? (
      <BaseComponent {...props} defaultValue={value} />
    ) : (
      <div style={{ minWidth: '180px' }}>
        <Loader size={Loader.Size.SMALL} />
      </div>
    );
  };
  return WrappedComponent;
};

export default withParamsResolver;
