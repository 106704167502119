import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import { addHttpPrefix } from '@hiredigital/lib/helpers/utils';
import Toggle from 'react-toggle';
import { postPublication } from '@apis/publications';
import { PortfolioItemType } from '@hiredigital/lib/helpers/enum';
import { portfolioAcceptedFiles, getPortfolioThumbnail } from '@hiredigital/lib/helpers/utils';
import { authRequest } from '@apis/utils';

import { PortfolioType, PortfolioResource, postPortfolioCaseOrderRequests } from './apiMapper';
import { onTeamsLoadOptions, onSkillsLoadOptions, onPublicationsLoadOptions } from '@apis/dropdown';
import Form from '@components/Form/Form';

import EditHeader from './EditHeader';
import EditFooter from './EditFooter';
import PortfolioCaseItem from './PortfolioCaseItem';
import Styles from './PortfolioEdit.module.scss';

const PortfolioEdit = ({
  type,
  resourceUuid,
  item: initialItem,
  onSave,
  isLoading,
  onCancel,
  onDelete,
  onUrlSave,
  onCaseUpload,
  onCaseUpdate,
  onCaseDelete,
  onScreenshotUpload,
  onImageUpload,
  onFetchScreenshot,
  errors,
  showTagTeam = false,
}) => {
  const [item, setItem] = useState(initialItem);
  const [submitted, setSubmitted] = useState(false);

  const caseUploadElement = useRef();
  const urlRef = useRef();
  const caseImageElement = [];

  useEffect(() => {
    setItem(initialItem);
  }, [initialItem]);

  const addCustomPublication = (value) => {
    const data = { name: value };

    postPublication(data).then(({ data }) => {
      setItem({ ...item, publication: data });
    });
  };

  const setItemType = (type) => setItem({ ...item, type });

  const handleUrlChange = (event, prefix) => {
    const { name, value } = event.target;
    addPrefix(name, value, prefix);
  };

  const addPrefix = (name, value, prefix) => {
    const link = value ? addHttpPrefix(value, prefix) : '';
    if (urlRef && urlRef.current) {
      urlRef.current.value = link;
    }
    setItem({ ...item, [name]: link });
  };

  const handleUrlKeyPress = (event) => {
    // For passing up keypresses + autoselect
    // console.log('handleKeyPress');
    if (event.which === 13) {
      handleUrlSubmit(event);
    }
  };

  const handleUrlSubmit = (event) => {
    if (validateAddUrlForm()) {
      onUrlSave?.(item);
    }
  };

  const validateAddUrlForm = () => {
    let valid = true;
    if (!item.url) {
      setSubmitted(true);
      valid = false;
    }
    return valid;
  };

  const handleUploadError = (event) => {
    toast.error(
      'Select a valid file format. Only Images, PDFs, Documents and Videos formats allowed.'
    );
  };

  const handleUploadResponse = (data) => setItem(data);

  const handleChange = (event) => {
    const target = event.target;

    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setItem({ ...item, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleCaseChange = (caseItem) => {
    const newCases = item.caseItems;
    setItem({ ...item, caseItems: newCases });
  };

  const handleCaseUpdate = (file, caseItem) => {
    onCaseUpdate?.(file, item, caseItem);
  };

  const handleSave = () => {
    setSubmitted(true);
    if (item.type === PortfolioItemType.CASE_STUDY.id) {
      // Case
      let isValid = true;
      // Disable validation
      // const { cases } = item;
      // for (let x = 0; x < cases.length; x++) {
      //   if (!cases[x].title && !cases[x].description) {
      //     isValid = false;
      //   }
      // }

      if (isValid) {
        onSave?.(item);
        setSubmitted(false);
      } else {
        console.log('not valid');
      }
    } else {
      onSave?.(item);
      setSubmitted(false);
    }
  };

  const handleCaseDelete = (caseItem) => {
    setItem({ ...item, caseItems: item.caseItems.filter((v) => v.id !== caseItem.id) });
    onCaseDelete?.(item, caseItem);
  };

  const handleCancel = () => {
    setSubmitted(false);
    onCancel?.();
  };

  const handleCaseUpload = (event) => {
    const files = event.target.files;
    onCaseUpload?.(files, item);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    onImageUpload?.(file, item);
  };

  const handleScreenshotUpload = (event) => {
    const file = event.target.files[0];
    onScreenshotUpload?.(file, item);
  };

  const handleFetchScreenshot = (itemId) => {
    onFetchScreenshot?.(itemId);
  };

  // Temp
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleOrderChange = (updatedOrder) => {
    const data = { ids: updatedOrder };
    postPortfolioCaseOrderRequests[type](resourceUuid, item.id, data).then(
      (response) => {},
      (error) => {}
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(item.caseItems, result.source.index, result.destination.index);
    setItem({ ...item, caseItems: items });

    console.log(items);
    const updatedOrder = items.map(({ id }) => id);
    console.log(updatedOrder);
    // change order
    handleOrderChange(updatedOrder);
  };

  // const imageUrl = item?.image || item?.attachment || item?.pageScreenshot;
  const imageUrl = getPortfolioThumbnail(item);

  return (
    <div>
      <Dialog
        isOpen={true}
        padding={0}
        dialogProps={{ style: { overflow: 'auto' } }}
        hasClose={false}>
        <div className={Styles.modalContentArea}>
          <EditHeader
            item={item}
            type={type}
            onFetchScreenshot={handleFetchScreenshot}
            onMenuSelect={setItemType}
            onImageUpload={handleImageUpload}
            onScreenshotUpload={handleScreenshotUpload}
          />

          <div className={Styles.modalFormArea}>
            {item.id && item.type !== PortfolioItemType.CASE_STUDY.id && (
              <div className={Styles.thumbnail}>
                <img src={imageUrl} className={Styles.thumbnailImage} alt={item.title} />
              </div>
            )}

            <div className={`${Styles.content}`}>
              {!item.id && (
                <div>
                  {item.type === PortfolioItemType.URL.id && (
                    <div className={Styles.urlForm}>
                      <div className={Styles.urlInputContainer}>
                        <TextInput
                          ref={urlRef}
                          name='url'
                          label='URL'
                          defaultValue={item.url}
                          onKeyPress={handleUrlKeyPress}
                          onChange={(e) => handleUrlChange(e, 'http://')}
                          error={(submitted && !item.url && 'Add a URL') || errors.url}
                        />
                      </div>
                      <Button
                        className={Styles.urlButton}
                        name='add'
                        type={Button.Type.BLUE}
                        onClick={handleUrlSubmit}>
                        {`Add Url`}
                      </Button>
                    </div>
                  )}
                  {item.type === PortfolioItemType.UPLOAD.id && (
                    <div>
                      <Form.SingleUpload
                        name='attachment'
                        label='Attachment'
                        httpRequest={authRequest}
                        server={`${process.env.API_ENDPOINT}/admin/${PortfolioResource[type]}/${resourceUuid}/items/`}
                        idleLabel='Upload'
                        method='post'
                        accept={portfolioAcceptedFiles}
                        value={item.attachment}
                        onResponse={handleUploadResponse}
                        onDropRejected={handleUploadError}
                      />
                    </div>
                  )}
                </div>
              )}
              {item.id && item.type !== 3 && (
                <div>
                  <div className={Styles.editThumb}>
                    <div className={Styles.editCheckbox}>
                      <Toggle
                        id={`toggle_${item.id}`}
                        name='showThumbnail'
                        defaultChecked={item.showThumbnail}
                        onChange={handleChange}
                      />
                      <label className={Styles.editLabel} htmlFor={`toggle_${item.id}`}>
                        {`Show Thumbnail`}
                      </label>
                    </div>

                    <div className={Styles.editCheckbox}>
                      <Toggle
                        id={`toggle_pr_${item.id}`}
                        name='private'
                        defaultChecked={item.private}
                        onChange={handleChange}
                      />

                      <label className={Styles.editLabel} htmlFor={`toggle_pr_${item.id}`}>
                        {`Keep Private`}
                      </label>
                    </div>
                  </div>

                  {item.type === PortfolioItemType.UPLOAD.id && (
                    <InputContainer>
                      <Form.SingleUpload
                        name='attachment'
                        label='Attachment'
                        httpRequest={authRequest}
                        server={`${process.env.API_ENDPOINT}/admin/${PortfolioResource[type]}/${resourceUuid}/items/${item.id}/`}
                        method='patch'
                        idleLabel='Upload'
                        value={item?.attachment}
                        onResponse={handleUploadResponse}
                        onDropRejected={handleUploadError}
                        accept={portfolioAcceptedFiles}
                        hideRemove
                      />
                    </InputContainer>
                  )}

                  {item.type === PortfolioItemType.URL.id && (
                    <InputContainer>
                      <TextInput
                        name='url'
                        label='URL'
                        onChange={(e) => handleUrlChange(e, 'http://')}
                        error={(submitted && !item.url && 'Add a URL') || errors.url}
                        value={item.url}
                      />
                    </InputContainer>
                  )}
                  <InputContainer>
                    <TextInput
                      name='title'
                      label='Title'
                      defaultValue={item.title}
                      onChange={handleChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextArea
                      name='content'
                      label='Description'
                      defaultValue={item.content}
                      onChange={handleChange}
                    />
                  </InputContainer>

                  {item.type === PortfolioItemType.UPLOAD.id && (
                    <InputContainer>
                      <Select
                        className={Styles.select}
                        value={item.publication}
                        classNamePrefix='s-contact'
                        name='publication'
                        label='Publication'
                        getOptionLabel={(item) => {
                          return item.name || item.label;
                        }}
                        menuPlacement='top'
                        getOptionValue={({ id }) => id}
                        onChange={handleSelectChange}
                        onCreateOption={addCustomPublication}
                        isPaginated
                        isCreatable
                        loadOptions={onPublicationsLoadOptions}
                      />
                    </InputContainer>
                  )}

                  <InputContainer>
                    <Select
                      className={Styles.select}
                      isClearable={false}
                      value={item.skills}
                      classNamePrefix='s-contact'
                      name='skills'
                      label='Skills'
                      menuPlacement='top'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      isMulti
                      onChange={handleSelectChange}
                      isPaginated
                      loadOptions={onSkillsLoadOptions}
                    />
                  </InputContainer>
                  {(type === PortfolioType.TEAM ||
                    (showTagTeam &&
                      [PortfolioItemType.UPLOAD.id, PortfolioItemType.URL.id].includes(
                        item.type
                      ))) && (
                    <InputContainer>
                      <Select
                        className={Styles.select}
                        isClearable={true}
                        value={item.teams}
                        classNamePrefix='s-contact'
                        name='teams'
                        label='Tag to a team'
                        menuPlacement='top'
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        isMulti
                        onChange={handleSelectChange}
                        isPaginated
                        loadOptions={onTeamsLoadOptions}
                      />
                    </InputContainer>
                  )}
                </div>
              )}

              {item.type === PortfolioItemType.CASE_STUDY.id && (
                <div className={Styles.caseContainer}>
                  <div
                    className={Styles.caseUploader}
                    onClick={() => caseUploadElement.current.click()}>
                    <p>
                      Drag files here or <strong>Browse</strong> to upload
                    </p>
                    <input
                      className={Styles.hiddenInput}
                      type='file'
                      ref={caseUploadElement}
                      accept='image/*,application/pdf'
                      onChange={handleCaseUpload}
                      multiple
                    />
                  </div>
                  {item.id && (
                    <div className={Styles.casePrivate}>
                      <div className={Styles.editCheckbox}>
                        <Toggle
                          id={`toggle_pr_${item.id}`}
                          name='private'
                          defaultChecked={item.private}
                          onChange={handleChange}
                        />

                        <label className={Styles.editLabel} htmlFor={`toggle_pr_${item.id}`}>
                          {`Keep Private`}
                        </label>
                      </div>
                    </div>
                  )}

                  {item.caseItems && item.caseItems.length > 0 && (
                    <div className={Styles.caseList}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                          droppableId='droppable'
                          renderClone={(provided, snapshot, rubric) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}>
                              <PortfolioCaseItem
                                index={rubric.source.index}
                                caseItem={item.caseItems[rubric.source.index]}
                                caseImageElement={caseImageElement}
                                onCaseUpload={onCaseUpload}
                                onCaseUpdate={handleCaseUpdate}
                                onCaseDelete={handleCaseDelete}
                                onCaseChange={handleCaseChange}
                              />
                            </div>
                          )}>
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className={Styles.dropContainer}>
                              {item.caseItems.map((caseItem, index) => (
                                <Draggable
                                  draggableId={`${caseItem.id}`}
                                  index={index}
                                  key={caseItem.id}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={Styles.dropItem}>
                                      <PortfolioCaseItem
                                        index={index}
                                        caseItem={caseItem}
                                        caseImageElement={caseImageElement}
                                        onCaseUpload={onCaseUpload}
                                        onCaseUpdate={handleCaseUpdate}
                                        onCaseDelete={handleCaseDelete}
                                        onCaseChange={handleCaseChange}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {isLoading && (
            <div className={Styles.overlayLoader}>
              <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} />
            </div>
          )}
          <EditFooter item={item} onDelete={onDelete} onCancel={handleCancel} onSave={handleSave} />
        </div>
      </Dialog>
    </div>
  );
};

PortfolioEdit.propTypes = {
  resourceUuid: PropTypes.string,
  item: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onUrlSave: PropTypes.func,
  onCaseUpload: PropTypes.func,
  onCaseDelete: PropTypes.func,
  onScreenshotUpload: PropTypes.func,
  onImageUpload: PropTypes.func,
  errors: PropTypes.object,
};

export default PortfolioEdit;
