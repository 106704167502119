import { useState } from 'react';
import Toggle from 'react-toggle';
import PropTypes from 'prop-types';

import { onUsersLoadOptions } from '@apis/dropdown';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Action from '@hiredigital/ui/Action';

import withEmailLabel from '@hoc/withEmailLabel';
import { isAdmin } from '@helpers/permissions';
import { useUser } from '@context/user';
import { putOrgUser } from '@apis/orgs';

import Styles from './Styles.module.scss';

const UserSelect = withEmailLabel(Select);

const Item = ({ member: initialMember, deleteMember, orgUuid }) => {
  const currentUser = useUser();

  const [isEdit, setIsEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [member, setMember] = useState(initialMember);
  const [loading, setLoading] = useState(false);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setMember(initialMember);
  };

  const handleChange = (event, immediateSave) => {
    const { name, value, type, checked } = event.target;

    const data = {
      ...member,
      [name]: type === 'checkbox' ? checked : value,
    };
    setMember({
      ...member,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (immediateSave) {
      saveMember({ ...data, user: data?.user?.uuid });
    }
  };

  const saveMember = async (data) => {
    setSubmitted(true);

    if (member.user.uuid) {
      try {
        await putOrgUser(orgUuid, member.id, data);
        setIsEdit(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSubmit = () => {
    const data = {
      user: member.user.uuid,
      isManager: member.isManager,
      briefNotifications: member.briefNotifications,
    };

    saveMember(data);
  };

  return isEdit ? (
    <>
      <Card.Item>
        <div className={Styles.form}>
          <InputGroup>
            <InputContainer style={{ marginBottom: '0' }}>
              <UserSelect
                className={Styles.select}
                value={member.user}
                classNamePrefix='s-contact'
                name='user'
                label='User'
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                options={member.users}
                onChange={handleChange}
                isPaginated
                loadOptions={onUsersLoadOptions}
              />
            </InputContainer>
          </InputGroup>
        </div>
      </Card.Item>
      <Card.Footer>
        <Button name='delete' type={Button.Type.WHITE} onClick={(e) => deleteMember(member)}>
          {`Delete`}
        </Button>

        <Action>
          <Button name='cancel' type={Button.Type.WHITE_BLUE_OUTLINE} onClick={handleCancel}>
            {`Cancel`}
          </Button>

          <Button name='submit' type={Button.Type.BLUE} onClick={handleSubmit}>
            {`Save`}
          </Button>
        </Action>
      </Card.Footer>
    </>
  ) : (
    <Card.Item style={{ alignItems: 'center' }} flex>
      <img className={Styles.picture} src={member.user.picture} alt='' />
      <div style={{ overflow: 'hidden' }}>
        <p className={Styles.name}>{member.user.name}</p>
        {member.role && <p className={Styles.info}>{member.role}</p>}
        {isAdmin(currentUser) && <p className={Styles.info}>{member.user.email}</p>}
      </div>

      <Action>
        <div className={Styles.toggleManager}>
          <Toggle
            id={`brief-notifications-${member.id}`}
            name='briefNotifications'
            defaultChecked={member.briefNotifications}
            onChange={(e) => handleChange(e, true)}
          />
          <label htmlFor={`brief-notifications-${member.id}`} className={Styles.toggleLabel}>
            {`Brief Emails`}
          </label>
        </div>

        <div className={Styles.toggleManager}>
          <Toggle
            id={`is-manager-${member.id}`}
            name='isManager'
            defaultChecked={member.isManager}
            onChange={(e) => handleChange(e, true)}
          />
          <label htmlFor={`is-manager-${member.id}`} className={Styles.toggleLabel}>
            {`Admin (Full Access)`}
          </label>
        </div>

        <Button
          name='Edit'
          className={Styles.editButton}
          type={Button.Type.BLUE_OUTLINE}
          onClick={handleEdit}>
          {`Edit`}
        </Button>
      </Action>
    </Card.Item>
  );
};

Item.propTypes = {
  currentUser: PropTypes.object,
  member: PropTypes.object,
  deleteMember: PropTypes.func,
  orgUuid: PropTypes.string,
};

export default Item;
