import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Header.module.scss';

const Header = ({ children, headerClass, headerContainerClass }) => {
  return (
    <div className={classNames(Styles.container, headerContainerClass)}>
      <div className={classNames(Styles.headline, headerClass)}>{children}</div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  store: PropTypes.object,
};

export default Header;
