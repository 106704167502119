import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import SplitButton from '@hiredigital/ui/Button/SplitButton';

import Styles from './PortfolioEdit.module.scss';

const PortfolioCaseItem = ({
  index,
  caseItem,
  caseImageElement,
  onCaseUpload,
  onCaseUpdate,
  onCaseDelete,
  onCaseChange,
  onCaseItemMove,
}) => {
  const handleCaseUpdate = (event, caseItem) => {
    const file = event.target.files[0];
    onCaseUpdate?.(file, caseItem);
  };

  const handleCaseDelete = (caseItem) => {
    onCaseDelete?.(caseItem);
  };

  const handleCaseChange = (event, caseItem) => {
    const { name, value } = event.target;
    caseItem[name] = value;
    onCaseChange?.(caseItem);
  };

  return (
    <div className={Styles.case}>
      <div className={Styles.caseContent}>
        <div>
          <div className={Styles.caseUpload}>
            <img src={caseItem.image} alt={caseItem.title} />
            <input
              className={Styles.hiddenInput}
              type='file'
              ref={(input) => (caseImageElement[index] = input)}
              accept='image/*,application/pdf'
              onChange={(e) => handleCaseUpdate(e, caseItem)}
            />
          </div>
          <div>
            <SplitButton
              type={SplitButton.Type.LIGHT_GRAY}
              more={[
                { text: 'Download', onClick: () => caseImageElement[index].click() },
                { text: 'Delete', onClick: () => handleCaseDelete(caseItem) },
              ]}>{`Actions`}</SplitButton>
          </div>
        </div>
        <div className={Styles.caseDetail}>
          <InputContainer>
            <TextInput
              name='title'
              label='Title'
              value={caseItem.title}
              onChange={(e) => handleCaseChange(e, caseItem)}
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              name='description'
              label='Description'
              defaultValue={caseItem.description}
              onChange={(e) => handleCaseChange(e, caseItem)}
            />
          </InputContainer>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCaseItem;
