import { Component } from 'react';

import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Logo from '@hiredigital/ui/Logo';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Password from '@hiredigital/ui/Input/Password/Password';

import { Link, Redirect } from 'react-router-dom';

import { request } from '@apis/utils';
import Layout from '@components/Layout/Layout';

import Styles from './Login.module.scss';
import '@styles/global.scss';

const cookies = new Cookies();

const COOKIE_DOMAIN = {
  domain: process.env.cookieDomain ? process.env.cookieDomain : '',
  path: '/',
};

class LoginPage extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      togglePassword: false,
      usernameValid: true,
      passwordValid: true,
      passwordError: null,
      hasResponseError: false,
      loading: false,
      loadingWithToken: false,
    };
  }

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);

    if (
      !values.next &&
      this.props.location.state &&
      this.props.location.state.from.pathname !== '/'
    ) {
      this.props.history.push(
        '/login?next=' + encodeURIComponent(this.props.location.state.from.pathname)
      );
    }

    if (values.clear) {
      cookies.remove('admin_token', { path: '/' });
    }

    if (cookies.get('admin_token')) {
      this.setState({ loadingWithToken: true });
      return <Redirect to='/' />;
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      [name + 'Valid']: value.length >= 1,
    });
  };

  handleKeyPress = (event) => {
    if (event.which === 13) {
      this.handleSubmit();
    }
  };

  handleSubmit = (event) => {
    this.setState({ loading: true });
    const user = {
      account_type: 2,
      username: this.state.username,
      password: this.state.password,
    };
    request
      .post('accounts/login/', user)
      .then((response) => {
        let token = response.data.key;
        cookies.set('admin_token', token, COOKIE_DOMAIN);
        const values = queryString.parse(this.props.location.search);
        if (values.next) {
          window.location.href = decodeURIComponent(values.next);
        } else {
          window.location.href = '/';
          // window.location.href = this.props.location.state ? this.props.location.state.from.pathname : '/';
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        try {
          this.setState({
            passwordValid: false,
            passwordError: error.response.data.nonFieldErrors[0],
          });
        } catch (e) {}

        try {
          this.setState({
            passwordValid: false,
            passwordError: error.response.data.password[0],
          });
        } catch (e) {}
      });
  };

  togglePassword = () => {
    this.setState({ togglePassword: !this.state.togglePassword });
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <div className={Styles.container}>
          <div className={Styles.contentWrapper}>
            <Link className={Styles.logoContainer} to='/'>
              <Logo className={Styles.logo} type={Logo.Type.WHITE} site={'2'} />
            </Link>
            <div className={Styles.card}>
              <div className={Styles.header}>
                <div className={Styles.headline}>{`Admin Login`}</div>
              </div>
              <InputContainer>
                <TextInput
                  name='username'
                  label='Email Address'
                  error={!this.state.usernameValid && `Enter your email address.`}
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <Password
                  name='password'
                  value={this.state.password}
                  error={
                    !this.state.passwordValid &&
                    (this.state.passwordError || `Enter your password.`)
                  }
                  placeholder={`Password`}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <Button
                className=''
                name='submit'
                type={Button.Type.BLUE}
                size={Button.Size.FULLWIDTH}
                onClick={this.handleSubmit}
                isLoading={this.state.loading}>
                {`Log In`}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LoginPage;
