import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListStyles from '@styles/PageList.module.scss';
import Styles from '../list.module.scss';

const TreeLabel = ({ picture, name, manual }) => {
  return (
    <>
      {picture ? <img className={Styles.picture} src={picture} alt={name || 'No name'} /> : ''}
      <div>
        <p className={classNames(Styles.title, name ? '' : Styles.blank)}>
          {name || 'No name'}
          {manual && <span className={ListStyles.label}>{`Manual`}</span>}
        </p>
      </div>
    </>
  );
};

TreeLabel.propTypes = {
  picture: PropTypes.string,
  name: PropTypes.string,
  manual: PropTypes.bool,
};

export default TreeLabel;
