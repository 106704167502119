import { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';

import Select from '@hiredigital/ui/Input/Select';
import Loader from '@hiredigital/ui/Loader';

import debounce from 'lodash/debounce';
import isAfter from 'date-fns/isAfter';
import startOfMonth from 'date-fns/startOfMonth';

import AppLayout from '@components/Layout/AppLayout';
import EntryListItem from './components/EntryListItem';
import DatePicker from '@hiredigital/ui/Input/Date/Date';

import withEmailLabel from '@hoc/withEmailLabel';
import { CancelToken } from '@apis/utils';
import { getUser } from '@apis/users';
import { getTimesheets } from '@apis/timesheets';
import { onUsersLoadOptions, onAllOrgsLoadOptions } from '@apis/dropdown';
import { withUser } from '@context/user';
import { convertToHours, setPageTitle } from '@hiredigital/lib/helpers/utils';
import { RateType } from '@hiredigital/lib/helpers/enum';
import { isFinance, isAdmin } from '@helpers/permissions';
import { toISOExactDateTime } from '@hiredigital/lib/helpers/date';

import ListStyles from '@styles/PageList.module.scss';
import Styles from './list.module.scss';

import withParamsResolver from '@hoc/withParamsResolver';

const UserSelect = withParamsResolver(withEmailLabel(Select), 'admin/users');
const OrgSelect = withParamsResolver(Select, 'admin/orgs');

let cancel;

const getEntryAmount = ({ locked, duration, timesheet: { assignment } }) => {
  const secondsDuration = duration;
  const hourDuration = convertToHours(secondsDuration);

  if (!locked && assignment?.talentRateType === RateType.HOURLY.id) {
    return hourDuration * parseFloat(assignment?.talentRate);
  }

  return 0;
};

const getTimesheetTotalAmount = (timeEntries) => {
  try {
    return timeEntries.reduce((accumulator, o) => accumulator + getEntryAmount(o), 0);
  } catch (e) {
    return null;
  }
};

const TimeEntrySubTotals = ({ entries, currentUser }) => {
  const groupedEntries = entries.reduce((objectsByKeyValue, obj) => {
    const value = obj?.timesheet?.uuid;
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

  return Object.values(groupedEntries).map((group) => {
    const filteredGroup = group.filter((a) => !a.locked);

    return (
      filteredGroup?.[0]?.timesheet && (
        <tr className={classNames(Styles.list, Styles.listSubtotal)}>
          <div className={Styles.action}>
            <td colSpan='4' className={classNames(Styles.header)} style={{ fontSize: '14px' }}>
              {`${filteredGroup?.[0]?.timesheet?.assignment?.organization?.name || ''} - ${
                filteredGroup?.[0]?.timesheet?.assignment?.title || ''
              }`}
            </td>
            <td className={classNames(Styles.listSection, Styles.small)}>
              {(
                filteredGroup?.reduce((a, b) => {
                  return a + b.duration;
                }, 0) / 3600
              ).toFixed(3)}
            </td>
            <td className={classNames(Styles.listSection, Styles.medium)}>
              {(isFinance(currentUser) ||
                isAdmin(currentUser) ||
                currentUser.adminPermission?.timesheetTalentRate) &&
                `$${getTimesheetTotalAmount(filteredGroup)?.toFixed(3)}`}
            </td>
            <td className={Styles.listOption}></td>
          </div>
        </tr>
      )
    );
  });
};

class TimesheetList extends Component {
  static propTypes = {
    location: PropTypes.object,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const query = queryString.parse(props.location.search);
    this.state = {
      isLoaded: false,
      isLoadingMore: false,
      next: null,
      nextPage: 1,
      timesheets: [],
      hasMore: true,
      search: query?.search || '',
      start: query?.start ? new Date(query?.start) : startOfMonth(new Date()),
      end: query?.end ? new Date(query?.end) : new Date(),
      user: query?.user,
      organization: query?.organization,
    };
    this.performDebouncedSearch = debounce(this.searchTimesheets, 750);
  }

  componentDidMount = () => {
    setPageTitle('Timesheets');
    // let promises = [];
    // if (this.props.location.search && queryString.parse(this.props.location.search).user) {
    //   promises.push(this.loadFilteredUser(queryString.parse(this.props.location.search).user));
    // }

    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    // Promise.all(promises).then((values) => {
    //   this.getTimesheets({
    //     search: this.state.search,
    //     start,
    //     end,
    //     user: this.state.user ? this.state.user.uuid : null,
    //     page: 1,
    //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    //   });
    // });

    this.getTimesheets({
      search: this.state.search,
      start,
      end,
      user: this.state.user,
      page: 1,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  getTimesheets = (params, clearResults = false) => {
    if (cancel) {
      cancel();
    }

    //params.limit = 1;
    const data = {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };

    this.setState({ isLoadingMore: true });
    getTimesheets(data).then(
      (response) => {
        let timesheets;
        const responseData = response.data.results.filter((timesheet) => {
          return timesheet.timesheetEntries.length > 0;
        });
        if (clearResults) {
          timesheets = responseData;
        } else {
          timesheets = this.state.timesheets.concat(responseData);
        }

        this.setState({
          timesheets,
          next: response.data.meta.next,
          nextPage: response.data.meta.nextPage,
          count: response.data.meta.count,
          isLoaded: true,
          isLoadingMore: false,
          ...(!response.data.meta.nextPage && { hasMore: false }),
        });
      },
      (error) => {
        console.log('Error occured during page request.');
        this.setState({
          isLoaded: false,
          hasMore: false,
          isLoadingMore: false,
        });
        //this.setState({ isLoaded: true, isLoadingMore: false });
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value?.uuid || value,
        isLoaded: false,
        hasMore: true,
      },
      () => {
        this.performDebouncedSearch();
      }
    );
  };

  handleStartDate = (date) => {
    this.setState(
      {
        start: date,
        isLoaded: false,
        hasMore: true,
      },
      () => {
        this.performDebouncedSearch();
      }
    );
  };

  handleEndDate = (date) => {
    this.setState(
      {
        end: date,
        isLoaded: false,
        hasMore: true,
      },
      () => {
        this.adjustDateStart(date);
        this.performDebouncedSearch();
      }
    );
  };

  adjustDateStart = (endDate) => {
    if (!!this.state.start && isAfter(new Date(this.state.start), endDate)) {
      this.setState({ start: endDate });
    }
  };

  searchTimesheets = () => {
    let searchQuery = {};
    if (this.state.search) {
      searchQuery.search = this.state.search;
    }

    if (this.state.user) {
      searchQuery.user = this.state.user;
    }

    if (this.state.organization) {
      searchQuery.organization = this.state.organization;
    }

    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    if (this.state.start) {
      searchQuery.start = start;
      searchQuery.end = end;
    }

    this.getTimesheets(
      {
        search: this.state.search,
        start,
        end,
        user: this.state.user,
        organization: this.state.organization,
        page: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      true
    );

    this.props.history.push(
      '/timesheets' +
        (queryString.stringify(searchQuery) ? '?' + queryString.stringify(searchQuery) : '')
    );
  };

  loadMoreTimesheets = (page) => {
    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    this.getTimesheets({
      search: this.state.search,
      start,
      end,
      user: this.state.user,
      page,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  getTime = (duration) => {
    let hours = String(Math.floor(duration / 3600));
    let mins = String(Math.floor((duration % 3600) / 60));
    let secs = String(Math.floor((duration % 3600) % 60));

    hours = hours.length === 1 ? '0' + hours : hours;
    mins = mins.length === 1 ? '0' + mins : mins;
    secs = secs.length === 1 ? '0' + secs : secs;

    return hours + ':' + mins + ':' + secs;
  };

  refreshList = () => {
    const start = toISOExactDateTime(this.state.start, true);
    const end = toISOExactDateTime(this.state.end, false);

    this.getTimesheets(
      {
        search: this.state.search,
        start,
        end,
        user: this.state.user,
        page: 1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      true
    );
  };

  toggleDurationFormat = (timesheet, index) => {
    let timesheets = this.state.timesheets;
    timesheet.showTimestamp = !timesheet.showTimestamp;
    timesheets[index] = timesheet;
    this.setState({
      timesheets,
    });
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={ListStyles.headerRow}>
            <div className={ListStyles.headerActive}>{`Timesheets`}</div>
            <input
              className={ListStyles.headerSearch}
              name='search'
              type='text'
              placeholder='Search'
              value={this.state.search}
              onChange={this.handleChange}
            />
            <div className={Styles.filterContainer}>
              <span className={Styles.filterText}>{`Filter`}</span>
              <div className={Styles.filterFields}>
                {(isFinance(this.props.currentUser) ||
                  isAdmin(this.props.currentUser) ||
                  this.props.currentUser.adminPermission?.timesheet ||
                  this.props.currentUser.adminPermission?.finance) && (
                  <>
                    <UserSelect
                      id='userID'
                      className={Styles.select}
                      defaultValue={this.state.user}
                      classNamePrefix='s-contact'
                      name='user'
                      label='User'
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      // options={this.state.users}
                      onChange={this.handleChange}
                      isPaginated
                      loadOptions={onUsersLoadOptions}
                      isClearable
                    />

                    <OrgSelect
                      data-test-id='organization'
                      className={Styles.select}
                      defaultValue={this.state.organization}
                      classNamePrefix='s-contact'
                      name='organization'
                      label='Organization'
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      onChange={this.handleChange}
                      loadOptions={onAllOrgsLoadOptions}
                      isPaginated
                      isClearable
                    />

                    <div className={Styles.dateInput}>
                      <DatePicker
                        id='timesheetsStart'
                        name='start'
                        label='Start Date'
                        value={this.state.start}
                        onChange={this.handleStartDate}
                      />
                    </div>
                    <div className={Styles.dateInput}>
                      <DatePicker
                        id='timesheetsEnd'
                        name='end'
                        label='End Date'
                        value={this.state.end}
                        onChange={this.handleEndDate}
                        minDate={this.state.start ? new Date(this.state.start) : undefined}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        }>
        {!this.state.isLoaded && (
          <div className={classNames(ListStyles.emptyState)}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
        {this.state.isLoaded && this.state.timesheets && this.state.timesheets.length > 0 && (
          <InfiniteScroll
            initialLoad={true}
            className={Styles.container}
            pageStart={1}
            loadMore={this.loadMoreTimesheets}
            hasMore={this.state.hasMore && !this.state.isLoadingMore}
            threshold={500}
            useWindow={false}
            loader={
              <div className={classNames(Styles.loaderArea)} key={0}>
                {this.state.isLoadingMore && <Loader />}
              </div>
            }>
            {this.state.timesheets.map((timesheet, index) => (
              <table className={classNames(Styles.card)} key={index}>
                <tr className={Styles.list}>
                  <th style={{ display: 'flex' }}>
                    <img className={Styles.picture} src={timesheet.picture} alt={timesheet.name} />
                    <p className={ListStyles.name}>{timesheet.name}</p>
                  </th>
                  <th colSpan='1' />

                  <div className={Styles.action}>
                    <th className={classNames(Styles.listSection, Styles.header)}>{`Date`}</th>
                    <th className={classNames(Styles.listSection, Styles.header)}>
                      {`Talent Rate`}
                    </th>
                    <th
                      className={classNames(
                        Styles.listSection,
                        Styles.small,
                        Styles.header,
                        Styles.headerAction
                      )}
                      onClick={() => this.toggleDurationFormat(timesheet, index)}>
                      <span
                        data-tip={timesheet.showTimestamp ? 'Show As Hours' : 'Show As Timestamp'}>
                        <span>Duration</span>
                        <ReactTooltip place='top' type='dark' effect='solid' />
                      </span>
                    </th>
                    <th className={classNames(Styles.listSection, Styles.medium, Styles.header)}>
                      {`Total Amount`}
                    </th>
                    <div className={Styles.listOption}></div>
                  </div>
                </tr>

                {timesheet.timesheetEntries.map((entry, entryIndex) => (
                  <EntryListItem
                    key={entry.id}
                    entry={entry}
                    showTimestamp={timesheet.showTimestamp}
                    onEntryUpdate={this.refreshList}
                  />
                ))}
                <TimeEntrySubTotals
                  entries={timesheet?.timesheetEntries}
                  currentUser={this.props.currentUser}
                />
                <tr className={classNames(Styles.list, Styles.listTotal)}>
                  <td colSpan='3' />
                  <div className={Styles.action}>
                    <td className={classNames(Styles.listSection, Styles.header)}>Total</td>
                    <td className={classNames(Styles.listSection, Styles.small)}>
                      {timesheet.showTimestamp
                        ? this.getTime(timesheet.totalDuration)
                        : (timesheet.totalDuration / 3600).toFixed(3)}
                    </td>
                    <td className={classNames(Styles.listSection, Styles.medium)}>
                      {(isFinance(this.props.currentUser) ||
                        isAdmin(this.props.currentUser) ||
                        this.props.currentUser.adminPermission?.timesheetTalentRate) &&
                        `$${getTimesheetTotalAmount(timesheet.timesheetEntries)?.toFixed(3)}`}
                    </td>
                    <td className={Styles.listOption}></td>
                  </div>
                </tr>
              </table>
            ))}
          </InfiniteScroll>
        )}
        {this.state.isLoaded && (!this.state.timesheets || this.state.timesheets.length === 0) && (
          <div
            className={classNames(
              ListStyles.emptyState,
              ListStyles.container
            )}>{`No Timesheets Found`}</div>
        )}
      </AppLayout>
    );
  }
}

export default withUser(TimesheetList);
