import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withParameters from '@hoc/withParameters';
import AppLayout from '@components/Layout/AppLayout';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { getProspects } from '@apis/prospects';
import { ProspectStatus } from '@hiredigital/lib/helpers/enum';
import { isAdmin } from '@helpers/permissions';
import { useUser } from '@context/user';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from '@styles/PageList.module.scss';

import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

const ProspectList = ({ location, initialProps, ...props }) => {
  const currentUser = useUser();

  const [search, setSearch] = useState(initialProps.search);

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        width: 100,
        minWidth: 100,
        // canSort: true,
        accessor: (p) => p.name,
        Cell: ({ row: { original: prospect } }) => {
          return (
            <Link className={Styles.profile} to={`/prospects/${prospect?.uuid}`}>
              <MultiLineEllipsis
                data-test-id='profile-table-item'
                className={classNames(Styles.name, !prospect?.name && Styles.empty)}>
                {prospect?.name || 'No Name'}
              </MultiLineEllipsis>
            </Link>
          );
        },
      },
      {
        Header: 'Email',
        id: 'email',
        width: 150,
        minWidth: 150,
        // canSort: true,
        accessor: (p) => p?.email,
      },
      {
        Header: 'Phone Number',
        id: 'phone_number',
        width: 75,
        minWidth: 75,
        // canSort: true,
        accessor: (p) => p?.phoneNumber,
      },

      {
        Header: 'Status',
        id: 'status',
        width: 50,
        minWidth: 50,
        // canSort: true,
        accessor: (p) => ProspectStatus.getEnum(p?.status)?.label,
      },

      {
        Header: 'Created',
        id: 'created',
        width: 50,
        minWidth: 50,
        // canSort: true,
        accessor: (p) => toReadableDate(p?.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Prospect Review');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 1000) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Prospect Review`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            defaultValue={search}
            placeholder='Search'
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getProspects}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        excludeParams={isAdmin(currentUser) ? [] : ['role']}
      />
    </AppLayout>
  );
};

ProspectList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(ProspectList);
