import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { OrganizationStatus, OrganizationType, Role } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

import { getOrgs } from '@apis/orgs';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import withParameters from '@hoc/withParameters';
import { useUser } from '@context/user';

import AppLayout from '@components/Layout/AppLayout';
import Styles from '@styles/PageList.module.scss';

const OrganizationList = ({ location, history, initialProps, ...props }) => {
  const currentUser = useUser();

  const [search, setSearch] = useState(initialProps?.search);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        size: 'medium',
        canSort: true,
        truncate: false,
        accessor: (u) => u.name,
        Cell: ({ row: { original: organization } }) => {
          return (
            <Link className={Styles.profile} to={`/finance-clients/${organization.uuid}`}>
              <img className={Styles.picture} src={organization.picture} alt='' />
              <div>
                <MultiLineEllipsis data-test-id='client-table-item' className={Styles.name}>
                  {organization.name}
                </MultiLineEllipsis>
              </div>
            </Link>
          );
        },
      },
      {
        Header: 'Type',
        id: 'type',
        size: 'small',
        canSort: true,
        accessor: (u) => OrganizationType.getLabel(u.type),
      },
      {
        Header: 'Status',
        id: 'status',
        size: 'small',
        canSort: true,
        accessor: (u) => OrganizationStatus.getLabel(u.status),
      },
      {
        Header: 'Date Joined',
        id: 'created',
        size: 'small',
        canSort: true,
        accessor: (u) => toReadableDate(u.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Clients');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      roles={[Role.FINANCE.id, Role.ADMIN.id]}
      permissions={currentUser.adminPermission?.finance}
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Clients`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getOrgs}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
      />
    </AppLayout>
  );
};

OrganizationList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(OrganizationList);
