import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Loader from '@hiredigital/ui/Loader';
import DialogMessage from './DialogMessage';
import SegmentViewOptions from './SegmentViewOptions';
import Button from '@hiredigital/ui/Button';

const SegmentDialog = ({
  title,
  isOpen,
  onClose,
  isLoading,
  total,
  isSegmented = true,
  onOpenAll,
  onOpenSegment,
  onContinue,
}) => {
  return (
    <Dialog title={title} isOpen={isOpen} onClose={onClose} width={550}>
      {isLoading ? (
        <Loader size={Loader.Size.SMALL} type={Loader.Type.FULL} />
      ) : (
        <>
          <DialogMessage total={total} />
          {isSegmented ? (
            <SegmentViewOptions total={total} onOpenAll={onOpenAll} onOpenSegment={onOpenSegment} />
          ) : (
            <>
              <Button type={Button.Type.BLUE} onClick={onContinue}>
                {'Continue'}
              </Button>
              <Button type={Button.Type.LIGHT_GRAY} onClick={onClose}>
                {'Quit'}
              </Button>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default SegmentDialog;
