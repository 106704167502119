import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Styles from '@styles/PageList.module.scss';
import AppLayout from '@components/Layout/AppLayout';
import { Role } from '@hiredigital/lib/helpers/enum';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import { getAdmins } from '@apis/admins';
import withParameters from '@hoc/withParameters';

const SupportList = ({ location, history, initialProps, ...props }) => {
  const [search, setSearch] = useState(initialProps.search);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        id: 'first_name',
        canSort: true,
        Cell: ({ row: { original: user } }) => {
          return <Link to={`/support/${user?.uuid}`}>{user?.name || 'No Name'}</Link>;
        },
      },
      {
        Header: 'Username',
        id: 'username',
        canSort: true,
        accessor: (u) => u.username,
      },
      {
        Header: 'Email',
        id: 'email',
        canSort: true,
        accessor: (u) => u.email,
      },
      {
        Header: 'Super User',
        id: 'superuser',
        canSort: true,
        accessor: (u) => (u.isSuperuser ? 'Yes' : ''),
      },
      {
        Header: 'Staff User',
        id: 'staff',
        canSort: true,
        accessor: (u) => (u.isStaff ? 'Yes' : ''),
      },
      {
        Header: 'Role',
        field: 'roles',
        canSort: true,
        Cell: ({ row: { original: user } }) => {
          return (
            (user.roles?.length && (
              <p>
                {Role.getEnums(user.roles)
                  .map((r) => r.label)
                  .join(', ')}
              </p>
            )) ||
            ''
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Support');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Support Staff`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getAdmins}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        rowHeight={'auto'}
      />
    </AppLayout>
  );
};

SupportList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(SupportList);
