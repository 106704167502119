import { Component } from 'react';
import PropTypes from 'prop-types';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import { MemberInvitationStatus } from '@hiredigital/lib/helpers/enum';

import Styles from './Styles.module.scss';

import Card from '@hiredigital/ui/Card';

class Invitation extends Component {
  static propTypes = {
    invitation: PropTypes.object,
    orgUuid: PropTypes.string,
    onUpdateInvitation: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.invitation,
      isEdit: false,
      hasInitLoad: false,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        ...this.props.invitation,
      },
      () => {
        this.setState({ hasInitLoad: true });
      }
    );
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.invitation.status !== this.state.status) {
      this.setState({
        status: this.props.invitation.status,
      });
    }
  };

  handleEdit = () => {
    this.setState({ isEdit: true });
  };

  handleCancel = () => {
    this.setState({
      ...this.props.member,
      isEdit: false,
    });
  };

  updateInvitation = (invitation, status) => {
    if (this.props.onUpdateInvitation) {
      this.props.onUpdateInvitation(invitation, status);
    }
  };

  deleteInvitation = (invitation) => {
    if (this.props.onDeleteInvitation) {
      this.props.onDeleteInvitation(invitation);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <Card.Item style={{ padding: '10px 20px' }}>
        {this.state.hasInitLoad && (
          <div className={Styles.row}>
            <img className={Styles.picture} src={this.state.picture} alt={this.state.name} />
            <div style={{ overflow: 'hidden' }}>
              <p className={Styles.name}>{this.state.name}</p>
              <p className={Styles.info}>{this.state.email}</p>
            </div>

            <div className={Styles.action}>
              <p className={Styles.status}>{MemberInvitationStatus.getLabel(this.state.status)}</p>

              <SplitButton
                direction='left'
                more={[
                  {
                    ...(this.state.status !== MemberInvitationStatus.CANCELLED.id && {
                      text: 'Cancel Invite',
                      onClick: () =>
                        this.updateInvitation(this.state, MemberInvitationStatus.CANCELLED),
                    }),
                  },
                  {
                    text: 'Resend Invitation',
                    onClick: () => this.updateInvitation(this.state, MemberInvitationStatus.RESEND),
                  },
                  {
                    text: 'Delete Invitation',
                    onClick: () => this.deleteInvitation(this.state),
                  },
                ]}
              />
            </div>
          </div>
        )}
      </Card.Item>
    );
  }
}

export default Invitation;
