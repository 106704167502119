import { authRequest } from './utils';

export const getServiceRates = async (resource, resourceUuid, config = undefined) => {
  const url = `admin/${resource}/${resourceUuid}/service-rates/`;
  return await authRequest.get(url, config);
};

export const postServiceRate = async (resource, resourceUuid, data, config = undefined) => {
  const url = `admin/${resource}/${resourceUuid}/service-rates/`;
  return await authRequest.post(url, data, config);
};

export const putServiceRate = async (
  resource,
  resourceUuid,
  serviceRateUuid,
  data,
  config = undefined
) => {
  const url = `admin/${resource}/${resourceUuid}/service-rates/${serviceRateUuid}/`;
  return await authRequest.put(url, data, config);
};

export const deleteServiceRate = async (
  resource,
  resourceUuid,
  serviceRateUuid,
  config = undefined
) => {
  const url = `admin/${resource}/${resourceUuid}/service-rates/${serviceRateUuid}/`;
  return await authRequest.delete(url, config);
};
