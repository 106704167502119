import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Role } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import Button from '@hiredigital/ui/Button';

import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import AppLayout from '@components/Layout/AppLayout';

import { getWiseRecpients, generateWiseRecipientCsv } from '@apis/payments';
import withParameters from '@hoc/withParameters';
import { useUser } from '@context/user';

import Styles from '@styles/PageList.module.scss';

const WiseRecipientList = ({ location, history, initialProps, ...props }) => {
  const currentUser = useUser();

  const [search, setSearch] = useState(initialProps?.search);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Recipient ID',
        id: 'transferwise_id',
        size: 'medium',
        canSort: true,
        accessor: (u) => u.transferwiseId,
      },
      {
        Header: 'Name',
        id: 'account_holder_name',
        size: 'small',
        canSort: true,
        accessor: (u) => u.accountHolderName,
      },
      {
        Header: 'Currency',
        id: 'currency',
        size: 'small',
        canSort: true,
        accessor: (u) => u.currency,
      },
      {
        Header: 'Date Added',
        id: 'created',
        size: 'small',
        canSort: true,
        accessor: (u) => toReadableDate(u.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Wise Recipients');
  }, []);

  const handleDownload = async () => {
    await generateWiseRecipientCsv();
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      roles={[Role.FINANCE.id, Role.ADMIN.id]}
      permissions={currentUser.adminPermission?.finance}
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Wise Recipients`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />
          <Button className={Styles.addButton} type={Button.Type.BLUE} onClick={handleDownload}>
            {`Download CSV`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getWiseRecpients}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
      />
    </AppLayout>
  );
};

WiseRecipientList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(WiseRecipientList);
