import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import IconDashboard from '@hiredigital/ui/Icon/icons/dashboard.inline.svg';
import IconProfile from '@hiredigital/ui/Icon/icons/profile.inline.svg';
import IconSearch from '@hiredigital/ui/Icon/icons/search.inline.svg';
import IconBrief from '@hiredigital/ui/Icon/icons/brief.inline.svg';
import IconCompany from '@hiredigital/ui/Icon/icons/company.inline.svg';
import IconTimesheet from '@hiredigital/ui/Icon/icons/timesheet.inline.svg';
import IconEmail from '@hiredigital/ui/Icon/icons/email.inline.svg';
import IconLogout from '@hiredigital/ui/Icon/icons/logout.inline.svg';
import { Tooltip, Position } from 'evergreen-ui';

import Avatar from '@hiredigital/ui/Avatar';
import Menu from '@hiredigital/ui/Menu/Menu';

import Item from './Item';
import { useUser } from '@context/user';
import Styles from './Side.module.scss';

import {
  isTalentAdmin,
  isSearchAdmin,
  isAdmin,
  isAnyBriefAdmin,
  isClientAdmin,
  isFinance,
} from '@helpers/permissions';

const Side = memo(({ ...props }) => {
  const currentUser = useUser();

  return (
    <div className={Styles.container}>
      <div className={Styles.menu}>
        <Tooltip
          content={`Logged in as ${currentUser.name || currentUser.email}`}
          position={Position.RIGHT}>
          <div className={Styles.avatar}>
            <Avatar
              type={Avatar.Type.CIRCLE}
              hash={currentUser.uuid}
              name={currentUser.name}
              src={currentUser.picture}
              size={30}
            />
          </div>
        </Tooltip>

        {currentUser && (
          <>
            <Item to='/' icon={IconDashboard} label={`Dashboard`} />
            {isTalentAdmin(currentUser) && (
              <Item to='/profiles' icon={IconProfile} label={`Profile Review`} />
            )}
            {isSearchAdmin(currentUser) && (
              <Item to='/talents' icon={IconSearch} label={`Talent Search`} />
            )}
            {isAnyBriefAdmin(currentUser) && (
              <Item to='/briefs' icon={IconBrief} label={`Brief Search`} />
            )}
            {isClientAdmin(currentUser) && (
              <Item to='/clients' icon={IconCompany} label={`Client Search`} />
            )}
            {(isAdmin(currentUser) ||
              isFinance(currentUser) ||
              currentUser.adminPermission?.timesheet ||
              currentUser.adminPermission?.timetracker) && (
              <Menu>
                <Menu.Button as='div'>
                  <Tooltip content={'Timetracker'} position={Position.RIGHT}>
                    <div className={Styles.item}>
                      <IconTimesheet className={Styles.icon} />
                    </div>
                  </Tooltip>
                </Menu.Button>
                <Menu.Items
                  className={Styles.menuItems}
                  direction='right_bottom'
                  style={{ minWidth: 'max-content' }}>
                  <div className={Styles.menuGroupTitle}>{'Timetracker'}</div>
                  <Menu.Item as={Link} to={'/events'} className={Styles.menuItem}>
                    {'Timetracker Summary'}
                  </Menu.Item>
                  <Menu.Item as={Link} to={'/timetracker/events'} className={Styles.menuItem}>
                    {'Timetracker Events'}
                  </Menu.Item>
                  <Menu.Item as={Link} to={'/timetracker/tree'} className={Styles.menuItem}>
                    {'Timetracker Tree by Talent'}
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to={'/timetracker/tree?topLevelOrganization=true'}
                    className={Styles.menuItem}>
                    {'Timetracker Tree by Client'}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            )}
            {isClientAdmin(currentUser) && (
              <Item to='/emails' icon={IconEmail} label={`Email Search`} />
            )}
          </>
        )}
        <div className={Styles.menuSpacer} />
        <Item to='/logout' icon={IconLogout} label={`Logout`} />
      </div>
    </div>
  );
});

Side.propTypes = {
  currentUser: PropTypes.object,
};

export default Side;
