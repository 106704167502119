import Toggle from 'react-toggle';
import { Site } from '@hiredigital/lib/helpers/enum';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Select from '@hiredigital/ui/Input/Select';

import Styles from './UserSetting.module.scss';

const UserSetting = ({
  defaultSite,
  flagMessages,
  onFlagMessageChange,
  onDefaultSiteChange,
  onSaveSetting,
  isBusy,
  onTalentSettingChange,
  talentSettings: {
    activityTrackingRequired,
    screenshotTrackingRequired,
    agreementVerified,
    agreementSigned,
    profileUpdated,
    portfolioUpdated,
    availabilityUpdated,
  },
}) => {
  return (
    <Card>
      <Card.Header title={`User Settings`} />
      <Card.Item>
        <div className={Styles.row}>
          <div className={Styles.toggleItem}>
            <Toggle
              id='profile-updated'
              name='profileUpdated'
              defaultChecked={profileUpdated}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='profile-updated' className={Styles.toggleLabel}>
              {`Profile Updated`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            <Toggle
              id='portfolio-updated'
              name='portfolioUpdated'
              defaultChecked={portfolioUpdated}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='portfolio-updated' className={Styles.toggleLabel}>
              {`Portfolio Updated`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            <Toggle
              id='availability-updated'
              name='availabilityUpdated'
              defaultChecked={availabilityUpdated}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='availability-updated' className={Styles.toggleLabel}>
              {`Availability Updated`}
            </label>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.toggleItem}>
            <Toggle
              id='flag-message'
              name='flagMessages'
              defaultChecked={flagMessages}
              onChange={onFlagMessageChange}
            />
            <label htmlFor='flag-message' className={Styles.toggleLabel}>
              {`Flag Messages`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            <Toggle
              id='agreement-verified'
              name='agreementVerified'
              defaultChecked={agreementVerified}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='agreement-verified' className={Styles.toggleLabel}>
              {`Agreement Verified`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            <Toggle
              id='agreement-signed'
              name='agreementSigned'
              defaultChecked={agreementSigned}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='agreement-signed' className={Styles.toggleLabel}>
              {`Agreement Signed`}
            </label>
          </div>
          {/* <div className={Styles.toggleItem}>
            <Toggle
              id='activity-tracking'
              name='activityTrackingRequired'
              defaultChecked={activityTrackingRequired}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='activity-tracking' className={Styles.toggleLabel}>
              {`Require Activity Tracking`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            <Toggle
              id='screenshot-tracking'
              name='screenshotTrackingRequired'
              defaultChecked={screenshotTrackingRequired}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='screenshot-tracking' className={Styles.toggleLabel}>
              {`Require Screenshot Tracking`}
            </label>
          </div> */}
        </div>
        <div className={Styles.row}>
          <div className={Styles.siteField}>
            <Select
              //className={PageStyles.select}
              data-test-id='defaultSite'
              defaultValue={Site.getEnum(defaultSite)}
              classNamePrefix='s-contact'
              name='defaultSite'
              label='Default Site'
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
              options={Site.values}
              onChange={onDefaultSiteChange}
            />
          </div>
        </div>
      </Card.Item>
      <Card.Footer right>
        <Button name='cancel' type={Button.Type.BLUE} onClick={onSaveSetting} isLoading={isBusy}>
          {`Save Settings`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

UserSetting.propTypes = {};

export default UserSetting;
