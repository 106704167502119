import { useMemo, useRef, useEffect, useState, memo } from 'react';

import { useTable, useFlexLayout, useSortBy } from 'react-table';
import { useLocation } from 'react-router-dom';

import Table from './Table';

import { CancelToken } from '@apis/utils';
import queryString from 'query-string';

import qs from 'qs';

// This component will handle the retrieval of the data

const TableContainer = memo(
  ({
    columns,
    getListData,
    listDataCallback,
    searchParams,
    hiddenColumns = [],
    updateRow = undefined,
    deleteRow = undefined,
    rowAccessor = 'id',
    ...props
  }) => {
    const location = useLocation();
    let cancel;
    const [index, setIndex] = useState(0);
    const [cursor, setCursor] = useState(0);
    const [list, setList] = useState([]);
    const [meta, setMeta] = useState({});
    const [query, setQuery] = useState({});
    const [isLoading, setLoading] = useState(false);
    const listRef = useRef({});

    const defaultColumn = useMemo(() => ({ width: 150 }), []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { sortBy },
    } = useTable(
      {
        columns,
        data: list,
        defaultColumn,
        manualSortBy: true,
        autoResetPage: false,
        autoResetSortBy: true,
        initialState: {
          hiddenColumns,
        },
      },
      useFlexLayout,
      useSortBy
    );

    const memoSearchParams = useMemo(() => searchParams, [searchParams]);

    const updateUrlParams = (params) => {
      const urlSearchParam = queryString.stringify(params, {
        parseBooleans: true,
        arrayFormat: 'comma',
      });
      window.history.replaceState(
        null,
        '',
        location.pathname + (urlSearchParam ? '?' + urlSearchParam : '')
      );
    };

    const loadData = async (params, reset = false) => {
      if (reset) {
        setIndex(0);
        if (cancel) {
          cancel();
        }
      }
      setLoading(true);
      setQuery(params);

      const config = {
        params: { ...params, ...searchParams },
        cancelToken: new CancelToken((c) => {
          cancel = c;
          return;
        }),
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }), // allow query string with same key
      };

      const {
        data: { meta, results },
      } = await getListData(config);
      const resultList = reset ? results : [...list, ...results];
      setList(resultList);
      setMeta(meta);
      setLoading(false);
      listDataCallback?.(resultList, meta);
    };

    const loadMoreData = async (startIndex, stopIndex) => {
      setIndex(index > stopIndex ? index : stopIndex);
      setCursor(stopIndex);
      if (index + 20 >= list.length && meta.nextPage) {
        loadData({ ...query, page: meta.nextPage });
      }
    };

    useEffect(() => {
      if (index + 20 >= list.length && meta.nextPage) {
        loadData({ ...query, page: meta.nextPage });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta.nextPage]);

    useEffect(() => {
      const ordering = sortBy.map((sort) => `${sort.desc ? '-' : ''}${sort.id}`);
      const params = {
        ordering: sortBy.length ? ordering.join(',') : undefined,
        ...searchParams,
      };
      setList([]);
      setMeta({});
      setLoading(true);
      updateUrlParams({ ...params });
      loadData({ page: 1, ...params }, true);

      try {
        listRef.current._listRef._outerRef.scrollTop = 0;
      } catch (err) {}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, memoSearchParams]);

    useEffect(() => {
      if (updateRow) {
        const newList = list.map((origItem) =>
          origItem[rowAccessor] === updateRow[rowAccessor] ? updateRow : origItem
        );
        setList(newList);
      }
    }, [updateRow]);

    useEffect(() => {
      if (deleteRow) {
        let newList = [...list];
        const deletedMsgIndex = list.findIndex(
          (origItem) => origItem[rowAccessor] === deleteRow[rowAccessor]
        );
        newList.splice(deletedMsgIndex, 1);
        setList(newList);
      }
    }, [deleteRow]);

    return (
      <Table
        listRef={listRef}
        list={list}
        meta={meta}
        loadData={loadData}
        loadMoreData={loadMoreData}
        isLoading={isLoading}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        {...props}
      />
    );
  }
);

export default TableContainer;
