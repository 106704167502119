import applyConverters from 'axios-case-converter';
import axios from 'axios';
import Cookies from 'universal-cookie';
// import toast from 'react-hot-toast';

const cookies = new Cookies();
const token = cookies.get('admin_token');

export const CancelToken = axios.CancelToken;

export const request = applyConverters(
  axios.create({
    baseURL: process.env.API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const authRequest = applyConverters(
  axios.create({
    baseURL: process.env.API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
);

// export const authSaveRequest = applyConverters(
//   axios.create({
//     baseURL: process.env.API_ENDPOINT,
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       Authorization: `Token ${token}`,
//     },
//   })
// );

export const authReqNoIntercept = applyConverters(
  axios.create({
    baseURL: process.env.API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
);

export const baseAuthRequest = axios.create({
  baseURL: process.env.API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  },
});

// export const toCamelCase = (str) => {
//   return str.replace(/([:\-_]+(.))/g, (_, separator, letter, offset) => {
//     return offset ? letter.toUpperCase() : letter;
//   });
// };

// export const toCamelCaseDict = (dict) => {
//   const isNull = (n) => {
//     return n === null;
//   };

//   const isArray = (a) => {
//     return Array.isArray(a);
//   };

//   const isObject = (o) => {
//     return typeof o === 'object' && !isNull(o) && !isArray(o);
//   };

//   const ret = {};

//   for (const prop in dict) {
//     if (dict.hasOwnProperty(prop)) {
//       ret[toCamelCase(prop)] = dict[prop];
//       if (isObject(dict[prop])) {
//         ret[toCamelCase(prop)] = toCamelCaseDict(dict[prop]);
//       } else if (isArray(dict[prop])) {
//         ret[toCamelCase(prop)] = ret[toCamelCase(prop)].map((i) => {
//           return toCamelCaseDict(i);
//         });
//       }
//     }
//   }
//   return ret;
// };

// export const checkStatus = async (response) => {
//   const data = await response.clone().json();
//   if (!response.ok) {
//     return response
//       .clone()
//       .json()
//       .then((err, resp) => {
//         Promise.reject(toCamelCaseDict(err));
//       });
//   }
//   return toCamelCaseDict(data);
// };

// const interceptorErrorHandler = (error, requestCallback) => {
//   let errMessage;
//   if (error && error.nonFieldErrors && error.nonFieldErrors.length) {
//     errMessage = error.nonFieldErrors.join(' ');
//     requestCallback(errMessage);
//   } else {
//     const hasNestedProp = (o) =>
//       typeof o === 'object' && typeof o[Object.keys(o)?.[0]] === 'object';
//     const errorResponse = error?.response?.data || error?.response?.statusText || error || '';
//     const errValues =
//       typeof errorResponse === 'string'
//         ? false
//         : hasNestedProp(errorResponse)
//         ? Object.entries(errorResponse?.[Object.keys(errorResponse)?.[0]]) || []
//         : Object.entries(errorResponse) || [];
//     if (errValues && errValues.length) {
//       errMessage = errValues
//         .map((item) => {
//           return `${(item[0][0] || '').toUpperCase()}${item[0].slice(1)}: ${item[1]}`;
//         })
//         .join(' ');
//       requestCallback(errMessage);
//     } else {
//       requestCallback((errorResponse && errorResponse.toString()) || 'Something went wrong!');
//     }
//   }
// };

// const arrRequest = [
//   { request: request, hasInterceptor: false },
//   { request: authRequest, hasInterceptor: true },
//   { request: baseAuthRequest, hasInterceptor: false },
// ];

// function toSentenceCase(_string) {
//   // from Snake to Sentence
//   return (
//     _string.charAt(0).toUpperCase() +
//     _string
//       .slice(1)
//       .replace(/([:\-_]+(.))/g, function (_, separator, letter, offset) {
//         return offset ? ' ' + letter.toUpperCase() : letter;
//       })
//       .replace(/([A-Z])/g, ' $1')
//   );
// }

// function makeReadable(error) {
//   if (Array.isArray(error)) {
//     return error.join(' ');
//   }

//   if (typeof error === 'object') {
//     return Object.keys(error)
//       .reduce((prev, curKey) => {
//         prev.push(`${toSentenceCase(curKey)}: ${error[curKey]}`);
//         return prev;
//       }, [])
//       .join(' ');
//   }

//   return error;
// }

// function reduce(errors) {
//   return Object.keys(errors).reduce((prev, curKey) => {
//     const error = errors[curKey];

//     if (Array.isArray(error)) {
//       const name = toSentenceCase(curKey !== 'non_field_errors' ? `${curKey}` : '');
//       return error.map((v) => `${name}: ${v}`);
//     }

//     return [...prev, makeReadable(error)];
//   }, []);
// }

// authRequest.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error?.response?.data) {
//       const {
//         response: { data },
//       } = error;
//       if (typeof data.detail == 'string') {
//         toast.error(data.detail, { id: data.detail });
//       } else if (data.length === 1) {
//         toast.error(data[0], { id: data[0] });
//       } else {
//         // If there are field errors
//         const values = reduce(data);
//         if (values.length > 100) {
//           toast.error('There was an error with your request. Please try again.', {
//             id: 'There was an error with your request. Please try again.',
//           });
//           // Rather than up the entire space with errors
//         } else {
//           values.forEach(function (msg) {
//             toast.error(msg, { id: msg });
//           });
//         }
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// authSaveRequest.interceptors.response.use(
//   (response) => {
//     toast.success('Saved', { id: 'Saved' });
//     return response;
//   },
//   (error) => {
//     toast.error('There was a problem saving. Please try again.', {
//       id: 'There was a problem saving. Please try again.',
//     });

//     return Promise.reject(error);
//   }
// );

// export const configAxiosErrorInterceptor = (requestCallback) => {
//   arrRequest.forEach((item) => {
//     if (!item.hasInterceptor) {
//       item.request.interceptors.response.use(
//         (response) => {
//           return response;
//         },
//         (error) => {
//           if (!axios.isCancel(error)) {
//             interceptorErrorHandler(error, requestCallback);
//           }
//           return Promise.reject(error);
//         }
//       );
//       item.hasInterceptor = true;
//     }
//   });
// };
