import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import AsyncPaginate from 'react-select-async-paginate';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import {
  toReadableShortDateTime,
  toReadableDate,
  toDurationString,
} from '@hiredigital/lib/helpers/date';
import Card from '@hiredigital/ui/Card';

import DatePicker from '@hiredigital/ui/Input/Date/Date';

import Toggle from 'react-toggle';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';

import AppLayout from '@components/Layout/AppLayout';

import { getEvents, getEvent, putEvent, postEvent } from '@apis/tracking';
import { onUsersLoadOptions, onAllOrgsLoadOptions, onTimesheetsLoadOptions } from '@apis/dropdown';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import withEmailLabel from '@hoc/withEmailLabel';
import { addDurationToTime } from '@hiredigital/lib/helpers/date';

import PageStyles from '@styles/PageDetail.module.scss';
const UserSelect = withEmailLabel(Select);

const TimetrackerEventDetail = ({ ...props }) => {
  const { uuid } = useParams();

  const [startTime, setStartTime] = useState();
  const [duration, setDuration] = useState();
  const [endTime, setEndTime] = useState();
  const [user, setUser] = useState();
  const [locked, setLocked] = useState();
  const [task, setTask] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(false);

  useEffect(() => {
    setPageTitle('Timetracker Events');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getEvent(uuid);
      setStartTime(response.data?.startTime);
      setDuration(response.data?.duration);
      setEndTime(response.data?.endTime);
      setUser(response.data?.user);
      setLocked(response.data?.locked);
      setTask(response.data?.task);
      setIsInitialLoad(true);
      // setEvent(response.data);
    };
    if (uuid) {
      fetchData();
    }
  }, [uuid]);

  useEffect(() => {
    if (startTime && duration) handleChangeEndDate();
  }, [startTime, duration]);

  const handleChangeEndDate = () => {
    const endTime = addDurationToTime(duration, startTime);
    setEndTime(endTime);
  };

  const handleSave = async () => {
    try {
      await putEvent(uuid, {
        startTime,
        endTime,
        duration,
        task,
        user,
        locked,
      });

      toast.success('Event saved!', { id: 'Saved' });
    } catch (e) {
      console.log(e);
      toast.error('There was a problem trying to save.', { id: 'Saved' });
    }
  };

  const handleTask = (event) => {
    const { name, value } = event.target;
    setTask({ ...task, [name]: value });
  };

  const handleDuration = (event) => {
    const { value } = event.target;
    setDuration(value);
  };

  const handleUser = (event) => {
    const { value } = event.target;
    setUser(value);
  };

  const handleStartDateChange = (value) => {
    setStartTime(value);
  };

  return (
    <AppLayout
      bodyClass={PageStyles.tableBody}
      header={
        <div className={PageStyles.header}>
          <Link className={PageStyles.headerParent} to='/timetracker/events'>
            {`Timetracker Events`}
          </Link>
          <p className={PageStyles.headerCaret}>&rsaquo;</p>
          {isInitialLoad && (
            <div className={PageStyles.headerActive}>{task?.name || 'New Event'}</div>
          )}
        </div>
      }>
      <div className={PageStyles.container}>
        {isInitialLoad && (
          <Card>
            <Card.Header>{task?.name || 'New Event'}</Card.Header>
            <Card.Item>
              <div className={PageStyles.leftRightContainer}>
                <div className={PageStyles.leftFields}>
                  <InputContainer>
                    <TextInput
                      name='name'
                      label='Task'
                      defaultValue={task?.name}
                      onChange={handleTask}
                    />
                  </InputContainer>

                  <InputContainer>
                    <DatePicker
                      id='entityStartTime'
                      name='startTime'
                      label='Start Time'
                      dateFormat='MM/dd/yyyy hh:mm aa'
                      value={startTime}
                      onChange={handleStartDateChange}
                      showTimeInput
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      name='duration'
                      label='Duration'
                      placeholder='HH:MM:SS'
                      mask='99:99:99'
                      maskChar={'0'}
                      defaultValue={duration}
                      onChange={handleDuration}
                    />
                  </InputContainer>
                  {/* <InputContainer>
                    <DatePicker
                      id='entityEndTime'
                      name='endTime'
                      label='End Time'
                      dateFormat='MM/dd/yyyy hh:mm aa'
                      value={endTime}
                      showTimeInput
                      readOnly
                      disabled
                    />
                  </InputContainer>*/}
                </div>
                <div className={PageStyles.rightFields}>
                  <InputContainer>
                    <Select
                      id='timesheetSelect'
                      defaultValue={task?.timesheet}
                      name='timesheet'
                      label='Timesheet'
                      getOptionLabel={({ talentTitle }) => talentTitle}
                      getOptionValue={({ uuid }) => uuid}
                      onChange={handleTask}
                      isPaginated
                      loadOptions={(search, loadedOptions, params) =>
                        onTimesheetsLoadOptions(user?.uuid, search, loadedOptions, params)
                      }
                    />
                  </InputContainer>

                  <InputContainer>
                    <UserSelect
                      id='userSelect'
                      defaultValue={user}
                      name='user'
                      label='User'
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      onChange={handleUser}
                      isPaginated
                      loadOptions={onUsersLoadOptions}
                    />
                  </InputContainer>
                  <InputContainer>
                    <div className={PageStyles.toggleManager}>
                      <Toggle
                        id={`locked`}
                        name='locked'
                        defaultChecked={locked}
                        onChange={(e) => setLocked(e.target.checked)}
                      />
                      <label htmlFor={`locked`} className={PageStyles.toggleLabel}>
                        {`Paid & Locked`}
                      </label>
                    </div>
                  </InputContainer>
                </div>
              </div>
            </Card.Item>
            <Card.Footer right sticky>
              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={handleSave}
                isLoading={isSaving}>
                {`Save Event`}
              </Button>
            </Card.Footer>
          </Card>
        )}
      </div>
    </AppLayout>
  );
};

export default TimetrackerEventDetail;
