import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import Button from '@hiredigital/ui/Button';

import AppLayout from '@components/Layout/AppLayout';
import Styles from '@styles/PageList.module.scss';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { postEmailTemplate } from '@apis/emails';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import { getEmailTemplates } from '@apis/emails';
import withParameters from '@hoc/withParameters';
import { EmailTemplateBase } from '@hiredigital/lib/helpers/enum';

const TemplateList = ({ location, history, initialProps, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [archived, setArchived] = useState(initialProps?.archived || false);
  const [search, setSearch] = useState(initialProps.search);

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        canSort: true,
        Cell: ({ row: { original: template } }) => {
          return (
            <Link to={`/email-templates/${template.id}`}>{template?.title || 'No Title'}</Link>
          );
        },
      },
      {
        Header: 'Category',
        id: 'category',
        canSort: true,
        accessor: (t) => t.category || 'No Category',
      },
      {
        Header: 'Subject',
        id: 'subject',
        canSort: true,
        accessor: (t) => t.subject || 'No Subject',
      },
      {
        Header: 'Preview',
        id: 'preheader',
        canSort: true,
        accessor: (t) => t.preheader || 'No Preview',
      },
      {
        Header: 'Date Updated',
        id: 'modified',
        canSort: true,
        accessor: (t) => toReadableDate(t.modified),
      },
      {
        Header: 'Template',
        id: 'base',
        canSort: true,
        accessor: (t) => EmailTemplateBase.getLabel(t.base),
      },
      {
        Header: 'Archived',
        id: 'archived',
        canSort: true,
        accessor: (t) => (t.archived ? 'Archived' : ''),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Email Templates');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const toggleArchived = (e) => {
    const newArchived = e.target.checked;
    setArchived(newArchived);
  };

  const handleCreateNewTemplate = () => {
    setLoading(true);
    postEmailTemplate({}).then(({ data }) => {
      setLoading(false);
      history.push('/email-templates/' + data.id);
    });
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Email Templates`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />

          <div className={Styles.headerAction}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <label
                htmlFor='show-archived'
                className={Styles.toggleLabel}>{`View Archived`}</label>
              <Toggle
                id='show-archived'
                name='archived'
                defaultChecked={archived}
                onChange={toggleArchived}
              />
            </div>
          </div>

          <Button
            className={Styles.addButton}
            type={Button.Type.BLUE}
            onClick={handleCreateNewTemplate}
            isLoading={loading}>
            {`Create New Template`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getEmailTemplates}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        filters={{ archived }}
        rowHeight={'auto'}
      />
    </AppLayout>
  );
};

TemplateList.propTypes = {
  location: PropTypes.object,
  store: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withParameters(TemplateList);
