import { Component } from 'react';
import PropTypes from 'prop-types';
import convert from 'htmr';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { onSkillsLoadOptions } from '@apis/dropdown';
import { Currency, ServiceRateType, ServiceRateStatus } from '@hiredigital/lib/helpers/enum';

import Quill from '@hiredigital/ui/Quill/Editor';
import SortableSelect from '@hiredigital/ui/Input/SortableSelect';

import Styles from './Styles.module.scss';
import { postServiceRate, putServiceRate, deleteServiceRate } from '@apis/serviceRates';
import { postSkill } from '@apis/skills';

class ServiceRateItem extends Component {
  static propTypes = {
    index: PropTypes.number,
    service: PropTypes.object,
    userSkills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      })
    ),
    resourceUuid: PropTypes.string,
    resource: PropTypes.string,
    onUpdateServiceRate: PropTypes.func,
    onDeleteService: PropTypes.func,
    hideClient: PropTypes.bool,
    hideTalent: PropTypes.bool,
    talentPermission: PropTypes.bool,
    clientPermission: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      orderedSkills: [],
      description: '',
      internalNotes: '',
      isEdit: false,
      ...this.props.service,
      loading: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.service.uuid) {
      this.setState({ isEdit: true });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.isEdit !== this.state.isEdit ||
      nextState.orderedSkills !== this.state.orderedSkills ||
      nextState.loading !== this.state.loading
    ) {
      return true;
    }

    return false;
  }

  handleEdit = () => {
    this.setState({ isEdit: true }, () => {
      this.serviceBaseState = { ...this.state };
    });
  };

  handleCancel = () => {
    if (this.state.isEdit) {
      this.setState({ ...this.serviceBaseState, isEdit: false });
    }

    if (!this.state.uuid) {
      this.props.onUpdateServiceRate();
    }
  };

  handleChange = (event, valueKey) => {
    const { name, value } = event.target;
    this.setState({
      [name]: valueKey ? value[valueKey || 'id'] || value : value,
    });
  };

  handleSubmit = () => {
    const {
      name,
      description,
      rateType,
      currency,
      clientRate,
      equivalentTalentSgdPerHour,
      equivalentClientSgdPerHour,
      status,
      internalNotes,
      orderedSkills,
      rate,
    } = this.state;

    const data = {
      name,
      description,
      internalNotes,
      clientRate: clientRate || 0,
      rateType,
      status,
      currency,
      orderedSkills:
        (orderedSkills && !!orderedSkills.length && orderedSkills.map((skill) => skill.id)) || [],
      rate: rate || 0,
    };

    this.setState({ loading: true });
    if (this.props.service.uuid) {
      this.updateServiceRate(data)
        .then(({ data }) => {
          this.setState({ isEdit: false, loading: false, ...data });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } else {
      this.addServiceRate(data)
        .then(({ data }) => {
          this.setState({ isEdit: false, loading: false, ...data });
          if (this.props.onUpdateServiceRate) {
            this.props.onUpdateServiceRate(data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    }
  };

  handleDelete = () => {
    if (this.props.service.uuid) {
      this.deleteService().then((response) => {
        if (this.props.onDeleteService) {
          this.props.onDeleteService(this.props.service.uuid, this.props.index);
        }
      });
    } else {
      if (this.props.onDeleteService) {
        this.props.onDeleteService(this.props.service.uuid, this.props.index);
      }
    }
  };
  handleEnumChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value.id,
    });
  };

  addServiceRate = (data) => {
    const { resource, resourceUuid } = this.props;
    return postServiceRate(resource, resourceUuid, data);
  };

  updateServiceRate = (data) => {
    const { resource, resourceUuid, service } = this.props;
    return putServiceRate(resource, resourceUuid, service.uuid, data);
  };

  deleteService = () => {
    const { resource, resourceUuid, service } = this.props;
    return deleteServiceRate(resource, resourceUuid, service.uuid);
  };

  handleCopySkills = () => {
    if (this.props.userSkills?.length) {
      this.setState({
        orderedSkills: [...this.props.userSkills],
      });
    }
  };

  handleCreateSkill = async (value) => {
    const { data } = await postSkill({ label: value, adminGenerated: true });
    this.setState({
      orderedSkills: (this.state.orderedSkills || []).concat([data]),
    });
  };

  render() {
    return (
      <Card.Item>
        {this.state.isEdit && (
          <div className={Styles.form}>
            <div className={Styles.formFieldsRow}>
              <div className={Styles.fieldsLeft}>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      name='name'
                      label='Name'
                      type='text'
                      defaultValue={this.state.name}
                      onChange={this.handleChange}
                    />
                  </InputContainer>
                </InputGroup>
                <InputGroup>
                  <InputContainer style={{ flex: '100%' }}>
                    <Quill
                      name='description'
                      label='Description'
                      placeholder='Description'
                      defaultValue={this.state.description}
                      onChange={this.handleChange}
                      className={Styles.fieldDescription}
                      style={{ minHeight: '131px' }}
                      legacyCompat
                    />
                  </InputContainer>
                </InputGroup>

                <InputGroup>
                  <InputContainer>
                    <SortableSelect
                      className={Styles.select}
                      value={this.state.orderedSkills || []}
                      name='orderedSkills'
                      label='Expertise'
                      isClearable={false}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      isMulti
                      onChange={this.handleChange}
                      isPaginated
                      isCreatable
                      onCreateOption={this.handleCreateSkill}
                      loadOptions={onSkillsLoadOptions}
                    />
                  </InputContainer>
                </InputGroup>

                <InputGroup>
                  <InputContainer>
                    <Quill
                      name='internalNotes'
                      label='Internal Notes'
                      placeholder='Internal Notes'
                      defaultValue={this.state.internalNotes}
                      onChange={this.handleChange}
                      legacyCompat
                    />
                  </InputContainer>
                </InputGroup>
              </div>
              <div className={Styles.fieldsRight}>
                <InputGroup>
                  {!this.props.hideTalent && this.props.talentPermission && (
                    <InputContainer>
                      <TextInput
                        name='rate'
                        label='Talent Rate'
                        type='number'
                        step='.01'
                        defaultValue={this.state.rate}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                  )}
                  {!this.props.hideClient && this.props.clientPermission && (
                    <InputContainer>
                      <TextInput
                        name='clientRate'
                        label='Client Rate'
                        type='number'
                        step='.01'
                        defaultValue={this.state.clientRate}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                  )}
                </InputGroup>

                <InputGroup>
                  <InputContainer>
                    <Select
                      data-test-id='rateType'
                      className={Styles.select}
                      defaultValue={ServiceRateType.getEnum(this.state.rateType)}
                      classNamePrefix='s-contact'
                      name='rateType'
                      label='Rate Type'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      options={ServiceRateType.values}
                      onChange={this.handleEnumChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Select
                      data-test-id='currency'
                      className={Styles.select}
                      defaultValue={Currency.getEnumByKey(this.state.currency, 'code')}
                      classNamePrefix='s-contact'
                      name='currency'
                      label='Currency'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ code }) => code}
                      options={Currency.values}
                      onChange={(e) => this.handleChange(e, 'code')}
                    />
                  </InputContainer>
                </InputGroup>
                <InputGroup>
                  {(!this.props.hideTalent || this.props.talentPermission) && (
                    <InputContainer>
                      <TextInput
                        name='equivalentClientSgdPerHour'
                        label='SGD/Hr'
                        type='number'
                        step='.01'
                        defaultValue={this.state.equivalentTalentSgdPerHour}
                        disabled
                      />
                    </InputContainer>
                  )}
                  {(!this.props.hideClient || this.props.clientPermission) && (
                    <InputContainer>
                      <TextInput
                        name='equivalentClientSgdPerHour'
                        label='SGD/Hr'
                        type='number'
                        step='.01'
                        defaultValue={this.state.equivalentClientSgdPerHour}
                        disabled
                      />
                    </InputContainer>
                  )}
                </InputGroup>
                <InputContainer>
                  <Select
                    data-test-id='status'
                    className={Styles.select}
                    defaultValue={ServiceRateStatus.getEnum(this.state.status)}
                    classNamePrefix='s-contact'
                    name='status'
                    label='Status'
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={ServiceRateStatus.values}
                    onChange={this.handleEnumChange}
                  />
                </InputContainer>
              </div>
            </div>
            <div className={Styles.formFieldsRow}></div>
            <div className={Styles.row}>
              <Button name='delete' type={Button.Type.WHITE} onClick={this.handleDelete}>
                {`Delete`}
              </Button>
              <div className={Styles.action}>
                {this.props.showCopySkillsOption && (
                  <Button
                    name='Copy'
                    type={Button.Type.BLUE_OUTLINE}
                    onClick={this.handleCopySkills}>
                    {`Copy Skills to Expertise`}
                  </Button>
                )}

                <Button
                  name='cancel'
                  type={Button.Type.WHITE_BLUE_OUTLINE}
                  onClick={this.handleCancel}>
                  {`Cancel`}
                </Button>

                <Button
                  name='submit'
                  type={Button.Type.BLUE}
                  onClick={this.handleSubmit}
                  isLoading={this.state.loading}>
                  {`Save`}
                </Button>
              </div>
            </div>
          </div>
        )}
        {!this.state.isEdit && (
          <div className={Styles.listPreview}>
            <div className={Styles.row}>
              <div className={Styles.listItemText}>
                <p className={Styles.title}>
                  {this.state.name ? this.state.name : 'New Service Rate'}
                </p>
                {this.state.rate && (
                  <div className={Styles.rateType}>
                    {`${this.state.currency} ${this.state.rate} ${
                      (this.state.rateType && ServiceRateType.getEnum(this.state.rateType).label) ||
                      ''
                    }`}
                  </div>
                )}
              </div>
              {this.state.status && (
                <div className={Styles.listItemText}>
                  <p className={Styles.statusText}>
                    {(ServiceRateStatus.getEnum(this.state.status) || {}).label || ''}
                  </p>
                </div>
              )}
              <div className={Styles.action}>
                <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={this.handleEdit}>
                  {`Edit`}
                </Button>
              </div>
            </div>
            {this.state.description && (
              <div className={Styles.description}>{convert(this.state.description)}</div>
            )}
          </div>
        )}
      </Card.Item>
    );
  }
}

export default ServiceRateItem;
