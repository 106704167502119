import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { getAdminPermission, updateAdminPermission } from '@apis/admins';

import Toggle from 'react-toggle';
import Styles from './AdminPermissions.module.scss';
let autoSaveTimeout;

const ToggleClosure = (handlePermissionChange, permissions) => {
  const ToggleSection = ({ id, label }) => {
    return (
      <div className={Styles.toggleRow}>
        <Toggle
          id={id}
          name={id}
          defaultChecked={permissions[id]}
          onChange={handlePermissionChange}
        />
        <label htmlFor={id} className={Styles.toggleLabel}>
          {label}
        </label>
      </div>
    );
  };
  return ToggleSection;
};

const AdminPermissions = ({ userUuid }) => {
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    const getData = async () => {
      const { data } = await getAdminPermission(userUuid);
      setPermissions(data);
    };
    getData();
  }, []);

  const handlePermissionChange = (event) => {
    const { name, checked } = event.target;
    setPermissions({ ...permissions, [name]: checked });
    handleAutoSave({ ...permissions, [name]: checked });
  };

  const handleAutoSave = (data) => {
    clearTimeout(autoSaveTimeout);
    autoSaveTimeout = setTimeout(() => handleSubmit(data), 2000);
  };

  const handleSubmit = async (data) => {
    const serializedData = { ...data, user: data.user.uuid };
    await updateAdminPermission(userUuid, serializedData);
    toast.success('Permissions Saved', { id: 'Saved' });
  };

  const ToggleSection = ToggleClosure(handlePermissionChange, permissions);

  return permissions ? (
    <div className={Styles.container}>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Talents</h4>
        <ToggleSection id={`talent`} label={`Talents`} />
        <ToggleSection id={`talentScore`} label={`Talent Score`} />
        <ToggleSection id={`talentBilling`} label={`Talent Billing`} />
        <ToggleSection id={`talentClient`} label={`Talent Clients`} />
        <ToggleSection id={`talentTalentRate`} label={`Talent Talent Rates`} />
        <ToggleSection id={`talentClientRate`} label={`Talent Client Rates`} />
        <ToggleSection id={`talentLimited`} label={`Talent Limited`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Teams</h4>
        <ToggleSection id={`team`} label={`Teams`} />
        <ToggleSection id={`teamBilling`} label={`Team Billing`} />
        <ToggleSection id={`teamTalentRate`} label={`Team Talent Rates`} />
        <ToggleSection id={`teamClientRate`} label={`Team Client Rates`} />
        <ToggleSection id={`teamLimited`} label={`Team Limited`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Clients</h4>
        <ToggleSection id={`org`} label={`Clients`} />
        <ToggleSection id={`orgCredit`} label={`Client Payments`} />
        <ToggleSection id={`orgUser`} label={`Client Users`} />
        <ToggleSection id={`orgLimited`} label={`Client Limited`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Briefs</h4>
        <ToggleSection id={`brief`} label={`Briefs`} />
        <ToggleSection id={`briefClientRate`} label={`Brief Client Rates`} />
        <ToggleSection id={`briefLimited`} label={`Brief Limited`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Engagements</h4>
        <ToggleSection id={`engagement`} label={`Engagements`} />
        <ToggleSection id={`engagementTalentRate`} label={`Engagement Talent Rates`} />
        <ToggleSection id={`engagementClientRate`} label={`Engagement Client Rates`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Assignments</h4>
        <ToggleSection id={`assignment`} label={`Assignments`} />
        <ToggleSection id={`assignmentTalentRate`} label={`Assignment Talent Rates`} />
        <ToggleSection id={`assignmentClientRate`} label={`Assignment Client Rates`} />
        <ToggleSection id={`assignmentLimited`} label={`Assignment Limited`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Applications</h4>
        <ToggleSection id={`application`} label={`Applications`} />
        <ToggleSection id={`applicationTalentRate`} label={`Application Talent Rates`} />
        <ToggleSection id={`applicationClientRate`} label={`Application Client Rates`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Invitations</h4>
        <ToggleSection id={`invitation`} label={`Invitations`} />
        <ToggleSection id={`invitationTalentRate`} label={`Invitation Talent Rates`} />
        <ToggleSection id={`invitationClientRate`} label={`Invitation Client Rates`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Projects</h4>
        <ToggleSection id={`project`} label={`Projects`} />
        <ToggleSection id={`projectLimited`} label={`Project Limited`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Talent Registrations</h4>
        <ToggleSection id={`userRegistration`} label={`Talent Registrations`} />
        <ToggleSection
          id={`userRegistrationTalentRate`}
          label={`Talent Registration Talent Rates`}
        />
        <ToggleSection
          id={`userRegistrationClientRate`}
          label={`Talent Registration Client Rates`}
        />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Client Registrations</h4>
        <ToggleSection id={`clientRegistration`} label={`Client Registrations`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Brief Registrations</h4>
        <ToggleSection id={`briefRegistration`} label={`Brief Registrations`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Timesheets</h4>
        <ToggleSection id={`timetracker`} label={`Time Tracker`} />
        <ToggleSection id={`timesheet`} label={`Timesheets`} />
        <ToggleSection id={`timesheetTalentRate`} label={`Timesheet Talent Rates`} />
        {/*<ToggleSection id={`timesheetClientRate`} label={`Timesheet Client Rates`} />*/}
        <ToggleSection id={`timesheetTalentLimited`} label={`Timesheet Talent Limited`} />
        <ToggleSection id={`timesheetClientLimited`} label={`Timesheet Client Limited`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Discussions</h4>
        <ToggleSection id={`discussion`} label={`Discussions`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Emails</h4>
        <ToggleSection id={`email`} label={`Emails`} />
      </div>
      <div className={Styles.row}>
        <h4 className={Styles.title}>Finance</h4>
        <ToggleSection id={`finance`} label={`Finance`} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AdminPermissions;
