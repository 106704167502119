import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Toggle from 'react-toggle';
import toast from 'react-hot-toast';

import Button from '@hiredigital/ui/Button';
import Card from '@hiredigital/ui/Card';

import Loader from '@hiredigital/ui/Loader';
import Select from '@hiredigital/ui/Input/Select';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';

import { getStartDates } from '@apis/common';
import { postEngagement } from '@apis/engagements';
import {
  getBrief,
  putBrief,
  postBriefAcceptAdminReview,
  postBriefAcceptCallReview,
  deleteBrief,
  postBriefsFile,
  postBriefsLink,
  deleteBriefsFile,
  deleteBriefsLink,
} from '@apis/briefs';
import { setPageTitle, copyTextToClipboard } from '@hiredigital/lib/helpers/utils';
import {
  onOrgLoadOptions,
  onClientUsersLoadOptions,
  onHubspotDealsLoadOptions,
} from '@apis/dropdown';
import { AdminBriefStage, BriefStatus, Currency } from '@hiredigital/lib/helpers/enum';
import { isAdmin, isPlacementElevatedAdmin, isAnyBriefAdmin } from '@helpers/permissions';
import { useUser } from '@context/user';
import Quill from '@hiredigital/ui/Quill/Editor';
import CallSchedule from '@components/Call/CallSchedule';
import DualInput from '@hiredigital/ui/Input/DualInput';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import AppLayout from '@components/Layout/AppLayout';
import References from '@hiredigital/ui/Form/References';
import EngagementDetailList from './components/EngagementDetailList';
import BriefDetailSide from './components/BriefDetailSide';
import Styles from '@styles/BriefDetail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';
import isAfter from 'date-fns/isAfter';
let saveTimeout = undefined;

const BriefDetailPage = ({ location: pageLocation, match, history }) => {
  const briefUuid = match?.params.briefUuid;
  const currentUser = useUser();
  const [enableAutoSave, setEnableAutoSave] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [status, setStatus] = useState();
  const [stage, setStage] = useState();
  const [currencyCode, setCurrencyCode] = useState();
  const [estimatedStartDate, setEstimatedStartDate] = useState();
  const [estimatedEndDate, setEstimatedEndDate] = useState();
  const [internalNotes, setInternalNotes] = useState();
  const [requestTrial, setRequestTrial] = useState();
  const [disableNotifications, setDisableNotifications] = useState();
  const [location, setLocation] = useState();
  const [emailRecipients, setEmailRecipients] = useState();
  const [hubspotDeal, setHubspotDeal] = useState();
  const [estimatedBudget, setEstimatedBudget] = useState();
  const [attachments, setAttachments] = useState();
  const [links, setLinks] = useState();
  const [availableStages, setAvailableStages] = useState();
  const [organization, setOrganization] = useState();
  const [creator, setCreator] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [call, setCall] = useState();
  const [sgdBudget, setSgdBudget] = useState();
  const [engagements, setEngagements] = useState();

  const [startDates, setStartDates] = useState([]);
  const [isAddInProgress, setIsAddInProgress] = useState(false);
  const [hiddenEngagements, setHiddenEngagements] = useState([]);
  const [hasInitLoad, setHasInitLoad] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const updateBrief = (data) => {
    setTitle(data.title);
    setDescription(data.description);
    setStatus(data.status);
    setStage(data.stage);
    setCurrencyCode(data.currencyCode);
    setEstimatedStartDate(data.estimatedStartDate);
    setEstimatedEndDate(data.estimatedEndDate);
    setInternalNotes(data.internalNotes);
    setRequestTrial(data.requestTrial);
    setDisableNotifications(data.disableNotifications);
    setLocation(data.location);
    setEmailRecipients(data.emailRecipients);
    setHubspotDeal(data.hubspotDeal);
    setEstimatedBudget(data.estimatedBudget);
    setAttachments(data.attachments);
    setLinks(data.links);
    setAvailableStages(data.availableStages);
    setOrganization(data.organization);
    setCreator(data.creator);
    setSelectedStartDate(data.selectedStartDate);
    setCall(data.call);
    setEngagements(data.engagements);
    setSgdBudget(data.sgdBudget);
  };
  useEffect(() => {
    const loadBrief = async () => {
      const { data } = await getBrief(briefUuid);
      updateBrief(data);
      setPageTitle(`Briefs | ${title || 'Untitled Brief'}`);

      setTimeout(() => {
        setHasInitLoad(true);
      }, 1000);
    };

    const loadStartDates = async () => {
      const { data } = await getStartDates();
      setStartDates(data.results);
    };

    loadBrief();
    loadStartDates();

    const sectionId = pageLocation?.hash?.substr(1);
    if (sectionId) {
      navigateToElem(sectionId);
    }
    setEnableAutoSave(true);
  }, []);

  const navigateToElem = useCallback((elementId) => {
    if (!elementId) return;
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  // Brief functions /////////////////////////////////////////////////////////////////

  const handleSave = async (storeResponse = true) => {
    const isValid = validateFields();
    setIsSaving(true);

    if (isValid) {
      const body = {
        // ...brief,
        title: title,
        description: description,
        status: status,
        stage: stage,
        currencyCode: currencyCode,
        estimatedStartDate: estimatedStartDate,
        estimatedEndDate: estimatedEndDate,
        internalNotes: internalNotes,
        requestTrial: requestTrial,
        disableNotifications: disableNotifications,
        location: location,
        estimatedBudget: estimatedBudget || 0,
        sgdBudget: sgdBudget || 0,
        creator: creator?.uuid || null,
        organization: organization?.uuid || null,
        emailRecipients: emailRecipients?.map((obj) => obj.uuid) || [],
        selectedStartDate: selectedStartDate?.id || undefined,
        hubspotDeal: hubspotDeal?.id || null,
      };
      try {
        const { data } = await putBrief(briefUuid, body);
        if (storeResponse) {
          updateBrief(data);
        }
      } catch (e) {
        console.error(e);
      }
      setIsSaving(false);
    } else {
      setTimeout(() => setIsSaving(false), 1000);
    }
  };

  const handleAutoSave = (timeout = 2000) => {
    if (saveTimeout) clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      handleSave(false);
    }, timeout);
  };

  useEffect(() => {
    if (enableAutoSave && hasInitLoad) {
      handleAutoSave(2000);
    }
  }, [title, description, internalNotes, estimatedBudget, location]);

  useEffect(() => {
    // Immediate save fields
    if (enableAutoSave && hasInitLoad) {
      handleAutoSave(10);
    }
  }, [
    emailRecipients,
    disableNotifications,
    hubspotDeal,
    currencyCode,
    stage,
    status,
    organization,
    creator,
    selectedStartDate,
    estimatedStartDate,
    estimatedEndDate,
    engagements,
  ]);

  useEffect(() => {
    setErrors({});
  }, [estimatedStartDate, estimatedEndDate]);

  const validateFields = () => {
    let err = {};

    if (
      estimatedStartDate &&
      estimatedEndDate &&
      isAfter(new Date(estimatedStartDate), new Date(estimatedEndDate))
    ) {
      err = { ...errors, estimatedStartDate: 'Start Date should not be later than End Date.' };
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }

    return true;
  };

  const handleDelete = async () => {
    setIsSaving(true);
    await deleteBrief(briefUuid);
    history.push('/briefs');
    setIsSaving(false);
  };

  // Engagement Functions /////////////////////////////////////////////////////////////////

  const handleAddEngagement = async () => {
    setIsAddInProgress(true);
    try {
      const body = {
        brief: briefUuid,
        organization: organization?.uuid || '',
      };
      await postEngagement(body);
      const { data } = await getBrief(briefUuid);
      updateBrief(data);
    } catch (e) {
      console.error(e);
    }
    setIsAddInProgress(false);
    setIsSaving(false);
  };

  const handleDeleteEngagement = useCallback(
    (engagementUuid) => {
      setEngagements(engagements?.filter((v) => v?.uuid !== engagementUuid) || []);
    },
    [engagements]
  );

  // const handleEngagementSave = (data, engagementUuid) => {
  //   setEngagements(
  //     engagements.map((e) => {
  //       if (e.uuid === engagementUuid) {
  //         return { ...e, ...data };
  //       }
  //       return e;
  //     })
  //   );
  // };

  const updateEngagement = (data, engagementUuid, propKey) => {
    const updatedEngagements = engagements.map((e) => {
      if (e.uuid === engagementUuid) {
        e[propKey] = e[propKey].map((item) => {
          return (item.id || item.uuid) === (data.id || data.uuid) ? data : item;
        });
      }
      return e;
    });
    setEngagements(updatedEngagements);
  };

  // const handleEngagementPropertyAdd = (data, engagementUuid, propKey) => {
  //   const updatedEngagements = engagements.map((e) => {
  //     if (e.uuid === engagementUuid) {
  //       e[propKey].push(data);
  //     }
  //     return e;
  //   });
  //   setEngagements(updatedEngagements);
  // };

  // const handleEngagementPropertyDelete = (id, engagementUuid, propKey) => {
  // const updatedEngagements = engagements.map((e) => {
  //   if (e.uuid === engagementUuid) {
  //     e[propKey] =
  //       e[propKey].filter((item) => {
  //         return id !== (item.id || item.uuid);
  //       }) || [];
  //   }
  //   return e;
  // });
  // setEngagements(updatedEngagements);
  // };

  const handleEngagementChange = useCallback(
    (data, engagementUuid) => {
      setEngagements(
        engagements.map((e) => {
          if (e.uuid === engagementUuid) {
            return { ...e, ...data };
          }
          return e;
        })
      );
    },
    [engagements]
  );

  // const handleEngagementInvitationGenerate = (data, engagementUuid, propKey) => {
  //   // Generates multiple invitations
  //   const updatedEngagements = engagements.map((e) => {
  //     if (e.uuid === engagementUuid) {
  //       e[propKey] = [...e[propKey]].concat(data);
  //     }
  //     return e;
  //   });
  //   setEngagements(updatedEngagements);
  // };

  // const handleGeneratedInvitationClear = (data, engagementUuid, propKey) => {
  //   // TODO(tim): Why is this here while property delete exists? is this different?
  //   const updatedEngagements = engagements.map((e) => {
  //     if (e.uuid === engagementUuid) {
  //       e[propKey] = [...data];
  //     }
  //     return e;
  //   });
  //   setEngagements(updatedEngagements);
  // };

  // Other Actions /////////////////////////////////////////////////////////////////

  const acceptCallReview = async () => {
    setIsSaving(true);
    try {
      const { data } = await postBriefAcceptCallReview(briefUuid);
      setStage(data.stage);
    } catch (e) {
      console.error(e);
    }
    setIsSaving(false);
  };

  const acceptAdminReview = async () => {
    setIsSaving(true);
    try {
      const { data } = await postBriefAcceptAdminReview(briefUuid);
      setStage(data.stage);
    } catch (e) {
      console.error(e);
    }
    setIsSaving(false);
  };

  const handleToggleCollapse = useCallback(
    (engagementUuid) => {
      if (hiddenEngagements.includes(engagementUuid)) {
        setHiddenEngagements(hiddenEngagements.filter((uuid) => uuid !== engagementUuid) || []);
      } else {
        setHiddenEngagements([...hiddenEngagements, engagementUuid]);
      }
    },
    [hiddenEngagements]
  );

  const copyAgreementLink = () => {
    const link = `https://app.hiredigital.com/briefs/${briefUuid}/agreement`;
    copyTextToClipboard(link);
    toast.success('Copied to Clipboard', { id: 'Saved' });
  };

  const copyDepositLink = () => {
    const link = `https://app.hiredigital.com/briefs/${briefUuid}/deposit`;
    copyTextToClipboard(link);
    toast.success('Copied to Clipboard', { id: 'Saved' });
  };

  return (
    <AppLayout
      location={pageLocation}
      header={
        <div className={PageStyles.header}>
          <Link className={PageStyles.headerParent} to='/briefs'>
            {`Briefs`}
          </Link>
          <p className={PageStyles.headerCaret}>&rsaquo;</p>
          <div className={PageStyles.headerActive}>{title || 'Untitled Brief'}</div>
        </div>
      }>
      {hasInitLoad && (
        <div className={Styles.main}>
          <BriefDetailSide
            currentUser={currentUser}
            uuid={briefUuid}
            engagements={engagements}
            hiddenEngagements={hiddenEngagements}
            navigateToElem={navigateToElem}
            handleToggleCollapse={handleToggleCollapse}
          />
          <div className={PageStyles.container}>
            {((currentUser && isPlacementElevatedAdmin(currentUser)) ||
              currentUser?.adminPermission?.brief) && (
              <div
                className={classNames(PageStyles.formContainer, PageStyles.adminContainer)}
                style={{ marginTop: '0' }}>
                <div className={Styles.row} style={{ alignItems: 'center' }}>
                  <h3 className={PageStyles.headline} style={{ margin: '0' }}>
                    {`Admin Settings`}
                  </h3>
                  <SplitButton
                    type={Button.Type.BLUE}
                    onClick={() => handleSave(false)}
                    isLoading={isSaving}
                    more={[
                      {
                        text: 'Open on platform',
                        as: 'a',
                        target: '_blank',
                        href: `${process.env.APP_ENDPOINT}/briefs/${briefUuid}`,
                      },
                      { text: 'Accept call review', onClick: acceptCallReview },
                      { text: 'Accept admin review', onClick: acceptAdminReview },
                      {
                        ...(currentUser &&
                          isAdmin(currentUser) && { text: 'Delete Brief', onClick: handleDelete }),
                      },
                    ]}>
                    {'Save Brief'}
                  </SplitButton>
                  {/* <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    <Button
                      className={Styles.saveButton}
                      type={Button.Type.BLUE}
                      onClick={() => handleSave(false)}
                      isLoading={isSaving}>
                      {`Save Brief`}
                    </Button>
                    <Dropdown drop='down' size='sm' alignRight>
                      <Dropdown.Toggle className={Styles.deleteDropdown}>
                        <IconMoreVertical className={Styles.moreIcon} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.APP_ENDPOINT}/briefs/${briefUuid}`}>
                          {`Open on platform`}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={acceptCallReview}>
                          {`Accept call review`}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={acceptAdminReview}>
                          {`Accept admin review`}
                        </Dropdown.Item>

                        {currentUser && isAdmin(currentUser) ? (
                          <Dropdown.Item onClick={handleDelete}>{`Delete Brief`}</Dropdown.Item>
                        ) : (
                          ''
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                </div>

                <div style={{ width: '100%' }}>
                  <InputGroup>
                    <InputContainer>
                      <Select
                        data-test-id='emailRecipients'
                        className={PageStyles.select}
                        defaultValue={emailRecipients}
                        classNamePrefix='s-contact'
                        name='emailRecipients'
                        label='Email Recipients'
                        getOptionLabel={({ name, email }) => `${name} (${email})`}
                        getOptionValue={({ uuid }) => uuid}
                        options={emailRecipients}
                        onChange={({ target }) => setEmailRecipients(target.value)}
                        isPaginated
                        isMulti
                        loadOptions={onClientUsersLoadOptions}
                      />
                    </InputContainer>
                    <InputContainer style={{ flexBasis: '170px', flexGrow: '0' }}>
                      <div
                        style={{
                          padding: '3px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '50px',
                        }}>
                        <Toggle
                          id='disable-notifications'
                          name='disableNotifications'
                          defaultChecked={disableNotifications}
                          onChange={({ target }) => setDisableNotifications(target.checked)}
                        />
                        <label
                          htmlFor='disable-notifications'
                          className={Styles.toggleLabel}>{`Disable Emails`}</label>
                      </div>
                    </InputContainer>
                  </InputGroup>

                  <div className={classNames(Styles.leftRightContainer)}>
                    <div className={Styles.leftFields}>
                      <InputGroup>
                        <InputContainer>
                          <Quill
                            name='internalNotes'
                            label='Notes'
                            placeholder='Add internal notes here'
                            defaultValue={internalNotes}
                            onChange={({ target }) => setInternalNotes(target.value)}
                            className={Styles.adminNotes}
                            legacyCompat
                          />
                        </InputContainer>
                      </InputGroup>
                    </div>
                    {currentUser?.adminPermission?.briefClientRate && (
                      <div className={Styles.rightFields}>
                        <InputGroup>
                          <InputContainer>
                            <Select
                              data-test-id='hubspotDeal'
                              className={PageStyles.select}
                              defaultValue={hubspotDeal}
                              classNamePrefix='s-contact'
                              name='hubspotDeal'
                              label='Hubspot Deal'
                              getOptionLabel={(deal) => deal.dealname || deal.data.dealname}
                              getOptionValue={({ id }) => id}
                              onChange={({ target }) => setHubspotDeal(target.value)}
                              isPaginated
                              isClearable
                              loadOptions={onHubspotDealsLoadOptions}
                            />
                          </InputContainer>
                        </InputGroup>

                        <InputGroup>
                          <InputContainer>
                            <DualInput
                              input={(Input) => (
                                <Input
                                  name='estimatedBudget'
                                  label='Estimated Overall Budget'
                                  type='number'
                                  step='.01'
                                  defaultValue={estimatedBudget}
                                  onChange={({ target }) =>
                                    setEstimatedBudget(target.value)
                                  }></Input>
                              )}
                              select={(Select) => (
                                <Select
                                  id='currencyCodeID'
                                  name='currencyCode'
                                  label='Currency'
                                  style={{ flexBasis: '115px' }}
                                  defaultValue={Currency.getEnum(currencyCode)}
                                  getOptionLabel={({ code }) => code}
                                  getOptionValue={({ id }) => id}
                                  options={Currency.values}
                                  onChange={({ target }) =>
                                    setCurrencyCode(target?.value?.id)
                                  }></Select>
                              )}></DualInput>
                          </InputContainer>
                        </InputGroup>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className={PageStyles.formContainer} id={briefUuid}>
              <div className={Styles.sectionBody}>
                <Card>
                  <Card.Header>
                    {`Brief`}
                    <div className={Styles.sideLinks}>
                      <div style={{ display: 'flex' }}>
                        {currentUser &&
                          (isAnyBriefAdmin(currentUser) ||
                            currentUser.adminPermission?.engagement) && (
                            <Button
                              type={Button.Type.BLUE_OUTLINE}
                              onClick={handleAddEngagement}
                              isLoading={isAddInProgress}>
                              {`Add Engagement`}
                            </Button>
                          )}

                        {/* <Button
                          className={Styles.saveButton}
                          type={Button.Type.BLUE}
                          onClick={() => handleSave(false)}
                          isLoading={isSaving}>
                          {`Save Brief`}
                        </Button>

                        <Dropdown drop='down' size='sm' alignRight>
                          <Dropdown.Toggle className={Styles.deleteDropdown}>
                            <IconMoreVertical className={Styles.moreIcon} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {currentUser &&
                              (isAnyBriefAdmin(currentUser) ||
                                currentUser.adminPermission?.engagement) && (
                                <Dropdown.Item onClick={handleAddEngagement}>
                                  {`Add Engagement`}
                                </Dropdown.Item>
                              )}
                            {currentUser && isAnyBriefAdmin(currentUser) && (
                              <>
                                <Dropdown.Item onClick={copyAgreementLink}>
                                  {`Copy Agreement Link`}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={copyDepositLink}>
                                  {`Copy Deposit Link`}
                                </Dropdown.Item>
                              </>
                            )}
                          </Dropdown.Menu>
                        </Dropdown> */}
                        <SplitButton
                          type={Button.Type.BLUE}
                          onClick={() => handleSave(false)}
                          isLoading={isSaving}
                          style={{ marginLeft: '10px' }}
                          more={[
                            { text: 'Add Engagement', onClick: handleAddEngagement },
                            ...(currentUser &&
                              isAnyBriefAdmin(currentUser) && [
                                { text: 'Copy Agreement Link', onClick: copyAgreementLink },
                                { text: 'Copy Deposit Link', onClick: copyDepositLink },
                              ]),
                          ]}>{`Save Brief`}</SplitButton>
                      </div>
                    </div>
                  </Card.Header>

                  <Card.Item>
                    <div className={Styles.leftRightContainer}>
                      <div className={Styles.leftFields}>
                        <InputContainer>
                          <TextInput
                            name='title'
                            label='Title'
                            defaultValue={title}
                            onChange={({ target }) => setTitle(target.value)}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Quill
                            name='description'
                            label='Requirements'
                            placeholder='Requirements'
                            defaultValue={description}
                            onChange={({ target }) => setDescription(target.value)}
                            legacyCompat
                          />
                        </InputContainer>

                        <div>
                          <References
                            attachments={attachments}
                            links={links}
                            postLinkRequest={(payload, config) =>
                              postBriefsLink(briefUuid, payload, config)
                            }
                            postFileRequest={(payload, config) =>
                              postBriefsFile(briefUuid, payload, config)
                            }
                            deleteLinkRequest={(id) => deleteBriefsLink(briefUuid, id)}
                            deleteFileRequest={(id) => deleteBriefsFile(briefUuid, id)}
                          />
                        </div>
                      </div>
                      <div className={Styles.rightFields}>
                        <InputGroup>
                          <InputContainer>
                            <Select
                              data-test-id='stage'
                              className={PageStyles.select}
                              defaultValue={AdminBriefStage.getEnum(stage)}
                              name='stage'
                              label='Stage'
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={availableStages.map((id) => AdminBriefStage.getEnum(id))}
                              onChange={({ target }) => setStage(target?.value?.id)}
                            />
                          </InputContainer>
                          <InputContainer>
                            <Select
                              data-test-id='status'
                              className={PageStyles.select}
                              defaultValue={BriefStatus.getEnum(status)}
                              name='status'
                              label='Status'
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={BriefStatus.values}
                              onChange={({ target }) => setStatus(target?.value?.id)}
                            />
                          </InputContainer>
                        </InputGroup>
                        <InputContainer>
                          <Select
                            data-test-id='organization'
                            className={PageStyles.select}
                            value={organization}
                            name='organization'
                            label='Client'
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ uuid }) => uuid}
                            onChange={({ target }) => setOrganization(target.value)}
                            isPaginated
                            isClearable
                            loadOptions={onOrgLoadOptions}
                          />
                        </InputContainer>
                        <InputContainer>
                          <Select
                            data-test-id='creator'
                            className={PageStyles.select}
                            value={creator}
                            name='creator'
                            label='Client Contact'
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ uuid }) => uuid}
                            onChange={({ target }) => setCreator(target.value)}
                            isPaginated
                            isClearable
                            loadOptions={onClientUsersLoadOptions}
                          />
                        </InputContainer>

                        <InputContainer>
                          <TextInput
                            name='location'
                            label='Location (if on-site)'
                            defaultValue={location}
                            onChange={({ target }) => setLocation(target.value)}
                          />
                        </InputContainer>
                        <InputContainer>
                          <Select
                            data-test-id='selectedStartDate'
                            className={PageStyles.select}
                            value={selectedStartDate}
                            name='selectedStartDate'
                            label='Selected Start Date'
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={startDates}
                            onChange={({ target }) => setSelectedStartDate(target.value)}
                          />
                        </InputContainer>

                        <InputGroup>
                          <InputContainer>
                            <DatePicker
                              id='briefEstimatedStartDate'
                              name='estimatedStartDate'
                              label='Start Date'
                              value={estimatedStartDate}
                              onChange={setEstimatedStartDate}
                              error={errors?.estimatedStartDate}
                            />
                          </InputContainer>

                          <InputContainer>
                            <DatePicker
                              id='briefEstimatedEndDate'
                              name='estimatedEndDate'
                              label='End Date'
                              value={estimatedEndDate}
                              onChange={setEstimatedEndDate}
                              error={errors?.estimatedEndDate}
                            />
                          </InputContainer>
                        </InputGroup>
                      </div>
                    </div>
                  </Card.Item>
                </Card>
              </div>
            </div>
            <div name='phoneCountry'>
              <CallSchedule
                callResource={'briefs'}
                phoneResource={'orgs'}
                uuid={briefUuid}
                // briefUuid={briefUuid}
                orgUuid={organization?.uuid}
                data={{
                  ...call,
                  phoneCountry: organization?.contact?.phoneCountry,
                  phoneNumber: organization?.contact?.phoneNumber,
                }}
              />
            </div>
            {(currentUser &&
              engagements.map((engagement, key) => {
                if (hiddenEngagements.indexOf(engagement.uuid) > -1) {
                  return '';
                }

                return (
                  (isAnyBriefAdmin(currentUser) || currentUser.adminPermission?.engagement) && (
                    <EngagementDetailList
                      currentUser={currentUser}
                      engagement={engagement}
                      briefUuid={briefUuid}
                      key={engagement.uuid}
                      index={key}
                      startDates={startDates}
                      location={pageLocation}
                      history={history}
                      onEngagementDelete={handleDeleteEngagement}
                      onEngagementChange={handleEngagementChange}
                    />
                  )
                );
              })) || (
              <div style={{ margin: '50px 0' }}>
                <Loader
                  color={Loader.Color.BLUE}
                  size={Loader.Size.LARGE}
                  type={Loader.Type.FULL}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {!hasInitLoad && (
        <div className={Loader.Styles.container}>
          <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
        </div>
      )}
    </AppLayout>
  );
};

BriefDetailPage.propTypes = {
  location: PropTypes.object,
  currentUser: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default BriefDetailPage;
