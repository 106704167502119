import classNames from 'classnames';

const containerStyle = {
  display: 'flex',
  width: '100%',
};

const nameStyle = {
  flexGrow: '1',
  marginRight: '7px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '55%',
  flexShrink: '0',
};

const emailStyle = {
  marginLeft: 'auto',
  lineHeight: '24px',
  color: '#c3ced9',
  height: '24px',
  flexGrow: '1',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'right',
  marginRight: '15px',
  // maxWidth: '45%',
};

const handleOptionLabel = ({ name, email }) => {
  return (
    <div style={containerStyle}>
      <div style={nameStyle} data-test-id='name'>
        {name}
      </div>
      {email && (
        <div style={emailStyle} data-test-id='email'>
          {email}
        </div>
      )}
    </div>
  );
};

const styles = {
  menu: ({ width, ...css }) => ({ ...css, width: '400px' }),
  input: ({ ...css }) => ({ ...css, padding: 0, margin: 0 }),
};

const withEmailLabel = (BaseComponent) => {
  const WrappedComponent = ({ className, ...props }) => {
    return (
      <BaseComponent
        {...props}
        styles={styles}
        className={classNames(`select-email-label`, className)}
        getOptionLabel={handleOptionLabel}
      />
    );
  };
  return WrappedComponent;
};

export default withEmailLabel;
