import { useMemo, useState, useEffect, memo } from 'react';

import Select from 'react-select';

import 'react-datetime/css/react-datetime.css';
import { format, parseISO } from 'date-fns';

import SplitButton from '@hiredigital/ui/Button/SplitButton';

import { onOrgLoadOptions, onAllUsersLoadOptions } from '@apis/dropdown';
import { getNotifications, deleteNotification } from '@apis/notification';
import withParameters from '@hoc/withParameters';

import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import AsyncPaginate from 'react-select-async-paginate';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import AppLayout from '@components/Layout/AppLayout';

import Styles from '@styles/PageList.module.scss';

import withParamsResolver from '@hoc/withParamsResolver';

const OrgSelect = withParamsResolver(AsyncPaginate);
const UserSelect = withParamsResolver(AsyncPaginate);

const ActionColumn = ({ notification, onStatusUpdate }) => {
  const handleDeleteNotification = () => {
    onStatusUpdate(notification);
    deleteNotification(notification?.id)
      .then(({ data }) => {
        console.log('success');
      })
      .catch((error) => {});
  };

  return <SplitButton more={[{ text: 'Delete', onClick: handleDeleteNotification }]} />;
};

const NotificationList = memo(({ location, history, initialProps, ...props }) => {
  const [deleteRow, setDeleteRow] = useState(undefined);
  const [search, setSearch] = useState(initialProps?.search);
  const [org, setOrg] = useState('');
  const [user, setUser] = useState('');

  const filterMemo = useMemo(
    () => ({
      organization: org?.uuid,
      recipient: user?.uuid,
    }),
    [org, user]
  );

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Type',

        id: 'type',
        minWidth: 150,
        accessor: (n) => <div>{n?.type?.display}</div>,
      },
      {
        Header: '',
        id: 'action',
        width: 40,
        minWidth: 40,
        truncate: false,
        accessor: (e) => <ActionColumn notification={e} onStatusUpdate={handleStatusUpdate} />,
      },
      {
        Header: 'Summary',
        id: 'summary',
        minWidth: 400,
        accessor: (n) => <div dangerouslySetInnerHTML={{ __html: n?.summary }} />,
      },
      {
        Header: 'Recipient',

        id: 'recipient',
        minWidth: 100,
        accessor: (n) => <div>{n?.recipient?.name || 'No Recipient'}</div>,
      },
      {
        Header: 'Created By',
        id: 'actor',
        minWidth: 100,
        accessor: (n) => n?.actor?.name,
      },
      {
        Header: 'Organization',
        id: 'organization',
        minWidth: 100,
        accessor: (n) => n?.organization?.name,
      },

      {
        Header: 'Status',
        canSort: true,
        id: 'read',
        minWidth: 100,
        accessor: (n) => <div>{n?.read === true ? `Read` : `Unread`}</div>,
      },
      {
        Header: 'Date',
        canSort: true,
        id: 'date',
        minWidth: 100,
        accessor: (n) => <div>{format(parseISO(n.created.toString()), 'dd MMM yyyy')}</div>,
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Notifications');
  }, []);

  const handleStatusUpdate = (notification) => refreshList(notification);

  const refreshList = (notification) => {
    setDeleteRow(notification);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleOrgFilterChange = (org) => {
    // setParams((prev) => ({ ...prev, organization: org.uuid }));
    setOrg(org);
  };
  const handleUserFilterChange = (user) => {
    setUser(user);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Notifications`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />
          <OrgSelect
            id='organizationID'
            className={Styles.headerSelect}
            selectValue={org}
            defaultValue={org}
            name='organization'
            placeholder='organization'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={(org) => {
              handleOrgFilterChange(org);
            }}
            isPaginated
            loadOptions={onOrgLoadOptions}
            debounceTimeout={500}
            isClearable
            additional={{ page: 1 }}
            SelectComponent={Select}
            createOptionPosition={'first'}
          />
          <UserSelect
            id='userID'
            className={Styles.headerSelect}
            selectValue={user}
            defaultValue={user}
            name='user'
            placeholder='user'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={(user) => {
              handleUserFilterChange(user);
            }}
            isPaginated
            loadOptions={onAllUsersLoadOptions}
            debounceTimeout={500}
            isClearable
            additional={{ page: 1 }}
            SelectComponent={Select}
            createOptionPosition={'first'}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getNotifications}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        deleteRow={deleteRow}
        rowHeight={50}
        filters={filterMemo}
      />
    </AppLayout>
  );
});

// NotificationList.propTypes = {
//   location: PropTypes.object,
// };

export default withParameters(NotificationList);
