import { Role } from '@hiredigital/lib/helpers/enum';

export const isTalentAdmin = (user) => {
  // Talent Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.TALENT_REVIEW.id, Role.TALENT_REVIEW_LIMITED.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isTalentAdminNotLimited = (user) => {
  // Talent Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.TALENT_REVIEW.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isClientAdmin = (user) => {
  // Brief Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.CLIENT_ADMIN.id, Role.CLIENT_ADMIN_LIMITED.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isBriefAdmin = (user) => {
  // Brief Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.BRIEF_ADMIN.id, Role.ADMIN.id, Role.BRIEF_PLACEMENT.id, Role.BRIEF_SALES.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isAnyBriefAdmin = (user) => {
  // Brief Admin, Admin Roles, Sales Admin, Placement Admin,
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [
    Role.BRIEF_SALES.id,
    Role.BRIEF_SALES_LIMITED.id,
    Role.BRIEF_PLACEMENT.id,
    Role.BRIEF_PLACEMENT_LIMITED.id,
    Role.BRIEF_ADMIN.id,
    Role.ADMIN.id,
  ];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isAnyTalentAdmin = (user) => {
  // Search Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [
    Role.TALENT_SEARCH_ADMIN.id,
    Role.TALENT_SEARCH_BRAND_RATES.id,
    Role.TALENT_SEARCH_TALENT_RATES.id,
    Role.TALENT_REVIEW.id,
    Role.TALENT_REVIEW_LIMITED.id,
    Role.ADMIN.id,
  ];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isSearchAdmin = (user) => {
  // Search Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [
    Role.TALENT_SEARCH_ADMIN.id,
    Role.TALENT_SEARCH_BRAND_RATES.id,
    Role.TALENT_SEARCH_TALENT_RATES.id,
    Role.ADMIN.id,
  ];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isTalentSearchBrandRatesAdmin = (user) => {
  // Search Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.TALENT_SEARCH_ADMIN.id, Role.TALENT_SEARCH_BRAND_RATES.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isTalentSearchTalentRatesAdmin = (user) => {
  // Search Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.TALENT_SEARCH_ADMIN.id, Role.TALENT_SEARCH_TALENT_RATES.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isSearchElevatedAdmin = (user) => {
  // Search Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.TALENT_SEARCH_ADMIN.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isSearchLimitedAdmin = (user) => {
  // Search Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return false;
  const roles = [Role.TALENT_SEARCH_BRAND_RATES.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isSalesAdmin = (user) => {
  // Sales Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [
    Role.BRIEF_SALES.id,
    Role.BRIEF_SALES_LIMITED.id,
    Role.BRIEF_ADMIN.id,
    Role.ADMIN.id,
  ];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isPlacementAdmin = (user) => {
  // Placement Admin, Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [
    Role.BRIEF_PLACEMENT.id,
    Role.BRIEF_ADMIN.id,
    Role.BRIEF_PLACEMENT_LIMITED.id,
    Role.ADMIN.id,
  ];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isPlacementElevatedAdmin = (user) => {
  // Placement Admin, Admin Roles
  // Super User
  if (user.isSuperuser) return true;
  const roles = [Role.BRIEF_PLACEMENT.id, Role.BRIEF_ADMIN.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isAnyAssignmentAdmin = (user) => {
  if (user.isSuperuser) return true;
  const roles = [Role.ASSIGNMENT_ADMIN_LIMITED.id, Role.ASSIGNMENT_ADMIN.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isAssignmentAdmin = (user) => {
  if (user.isSuperuser) return true;
  const roles = [Role.ASSIGNMENT_ADMIN.id, Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isAccountManager = (user) => {
  if (user.isSuperuser) return true;
  const roles = [Role.ACCOUNT_MANAGER.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isAdmin = (user) => {
  // Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.ADMIN.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isFinance = (user) => {
  // Admin Roles
  // Super User, Staff User Settings
  const roles = [Role.FINANCE.id];
  return Boolean(user.roles?.filter((value) => roles.includes(value)).length > 0);
};
