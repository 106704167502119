import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@hiredigital/ui/Button';
import Checkbox from '@hiredigital/ui/Checkbox';
import Styles from '../list.module.scss';

import { useState } from 'react';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import { putEvent, postLockedUnlocked } from '@apis/tracking';
import { Tooltip, Position } from 'evergreen-ui';

const PaidCheckbox = (props) => {
  const [isMarking, setMarking] = useState(false);

  const data = props.data;

  const handleSubmit = () => {
    if (isMarking) return;
    setMarking(true);

    const locked = !props.locked;

    const putData = {
      task: {},
      locked,
    };

    putEvent(data.uuid, putData)
      .then((response) => {
        props.updateUnlocked?.((locked ? -1.0 : 1.0) * data.totalDuration, data.uuid);
        props.setLocked?.(locked);
      })
      .catch((error) => {
        console.error(`Error! Arguments: (${data.type}) ${data.uuid} (${locked})`);
        console.error(error);
      })
      .finally(() => setMarking(false));
  };

  const markAggregate = (undoToast) => {
    if (undoToast) toast.dismiss(undoToast);

    const uuid = data.type?.startsWith('unlinked') ? data.uuid?.substr(0, 36) : data.uuid;
    const locked = data.unlockedDuration > 0.0;

    const config = {
      params: {
        ...data.search,
        ...(data.type === 'unlinkedTasks' && { unlinked_tasks: true }),
        ...(data.type === 'unlinkedEvents' && { unlinked_events: true }),
      },
    };

    postLockedUnlocked(data.type, uuid, locked, config)
      .then((response) => {
        // to strictly avoid assumptions about the response, we check both.
        const rLocked = response?.data?.status === 'locked';
        const rUnlocked = response?.data?.status === 'unlocked';
        const rDuration = response?.data?.duration;
        if (rLocked || rUnlocked) {
          // update this level and lower levels
          props.resetLockedState?.(rLocked);
          // update higher levels
          props.updateUnlockedHigherLevel?.((rLocked ? -1.0 : 1.0) * rDuration, data.uuid);
        } else {
          toast.error('Something went wrong. Please check with @Tim.');
          console.error(`Unexpected response! Arguments: (${data.type}) ${uuid} (${locked})`);
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(`Error! Arguments: (${data.type}) ${uuid} (${locked})`);
        console.error(error);
      })
      .finally(() => setMarking(false));
  };

  const handleMarkingAggregate = () => {
    if (isMarking) return;
    setMarking(true);

    const debouncedMarking = debounce(markAggregate, 3000);

    const thisToast = toast.loading(
      <>
        <span style={{ margin: '14px 16px 14px 0' }}>
          <b>Marking {data.unlockedDuration > 0.0 ? 'paid' : 'unpaid'}:</b>{' '}
          {data.name || <i>No name</i>}
        </span>
        <Button
          type={Button.Type.BLUE}
          onClick={() => {
            debouncedMarking.cancel();
            toast.dismiss(thisToast);
            setMarking(false);
          }}>
          Undo
        </Button>
      </>,
      { id: `marking_${data.uuid}`, style: { maxWidth: '80%' } }
    );

    debouncedMarking(thisToast);
  };

  const handleMarking = (event) => {
    switch (data.type) {
      case 'event':
        handleSubmit();
        break;
      case 'task':
      case 'assignment':
      case 'relationship':
      case 'talent':
      case 'unlinkedTasks':
      case 'unlinkedEvents':
        handleMarkingAggregate();
        break;
      default:
        toast.error(
          'Marking paid/unpaid here is not yet supported. Please try lower levels or check with @Tim for support timeline.',
          { id: 'payment_level_unsupported' }
        );
    }
  };

  return (
    <div className={classNames(Styles.listSection, Styles.center, Styles.headerAction)}>
      <Tooltip
        position={Position.LEFT}
        content={`Mark as ${
          (props.isEvent ? props.locked : data.unlockedDuration <= 0.0) ? 'Unpaid' : 'Paid'
        }`}>
        <span>
          <Checkbox
            name='paid'
            checked={props.isEvent ? props.locked : data.unlockedDuration < data.totalDuration}
            value={
              props.isEvent
                ? 'on'
                : data.unlockedDuration > 0.0 && data.unlockedDuration < data.totalDuration
                ? 'partial'
                : 'on'
            }
            onChange={handleMarking}
          />
        </span>
      </Tooltip>
    </div>
  );
};

PaidCheckbox.propTypes = {
  data: PropTypes.object,
  isEvent: PropTypes.bool,
  locked: PropTypes.bool,
  setLocked: PropTypes.func,
  updateUnlocked: PropTypes.func,
  updateUnlockedHigherLevel: PropTypes.func,
  resetLockedState: PropTypes.func,
};

export default PaidCheckbox;
