import { Component } from 'react';
import PropTypes from 'prop-types';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { putUserRating, postUserRating, getUserAssignments } from '@apis/users';
import { RatingStatus, RatingType } from '@hiredigital/lib/helpers/enum';
import Quill from '@hiredigital/ui/Quill/Editor';
import Styles from './Rating.module.scss';

class RateItem extends Component {
  static propTypes = {
    user: PropTypes.object,
    rating: PropTypes.object,
    assignments: PropTypes.array,
    updateRating: PropTypes.func,
    deleteRating: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.rating,
      message: '',
      isEdit: false,
      loading: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.rating.uuid) {
      this.setState({ isEdit: true });
    }
  };

  handleEdit = () => {
    this.setState({ isEdit: true }, () => {
      this.ratingBaseState = { ...this.state }; // create a separate copy of base state to prevent it from being directly mutated
    });
  };

  handleCancel = () => {
    if (this.state.isEdit) {
      this.setState({ ...this.ratingBaseState, isEdit: false }); // restore the base state when editing is cancelled
    }

    if (!this.state.id) {
      this.props.updateRating();
    }
  };

  handleChange = (event, setId, max) => {
    const { name, value } = event.target;
    let val;
    if (max) {
      val = value < max ? value : parseInt(value.toString().substring(0, max));
    } else {
      val = setId ? value.id : value;
    }
    this.setState({
      [name]: val,
    });
  };

  handleKeyPress = (event) => {
    // added additional validation  for these characters since number fields seems to allow these characters
    const invalid = ['-', '+', 'e', '.'];
    if (invalid.includes(event.key.toLowerCase())) {
      event.preventDefault();
    }
  };

  handleSubmit = () => {
    const { type, status, overallRating, message, assignment, ratee } = this.state;

    const data = {
      type,
      status,
      overallRating: overallRating || null,
      message,
      assignment,
      ratee,
    };

    if (assignment) {
      data.assignment = assignment.uuid;
    }

    if (!overallRating || (overallRating > 0 && overallRating <= 5)) {
      this.setState({ submitted: true, loading: true });
      if (this.props.rating.uuid) {
        putUserRating(this.props.user.uuid, this.props.rating.uuid, data).then(
          (response) => {
            this.setState({ loading: false, isEdit: false });
          },
          (error) => {
            this.setState({ loading: false });
          }
        );
      } else {
        this.setState({ submitted: true, loading: true });
        postUserRating(this.props.user.uuid, data).then(
          (response) => {
            this.setState({ loading: false, isEdit: false });
            this.props.updateRating(response.data);
          },
          (error) => {
            this.setState({ loading: false });
          }
        );
      }
    }
  };

  onAssignmentLoadOptions = async (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const response = await getUserAssignments(this.props.user.uuid, data);
    return {
      options: response.data.results,
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  render() {
    const validateScore = () => {
      if (
        this.state.overallRating &&
        (parseInt(this.state.overallRating) > 5 ||
          parseInt(this.state.overallRating) <= 0 ||
          isNaN(this.state.overallRating))
      ) {
        return 'Enter valid score';
      }
    };

    return (
      <Card.Item>
        {this.state.isEdit && (
          <div className={Styles.form}>
            <InputGroup>
              <InputContainer>
                <Select
                  className={Styles.select}
                  value={RatingType.getEnum(this.state.type)}
                  classNamePrefix='s-contact'
                  name='type'
                  label='Type'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={RatingType.values}
                  onChange={(e) => this.handleChange(e, true)}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  className={Styles.select}
                  value={RatingStatus.getEnum(this.state.status)}
                  classNamePrefix='s-contact'
                  name='status'
                  label='Status'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={RatingStatus.values}
                  onChange={(e) => this.handleChange(e, true)}
                />
              </InputContainer>

              <InputContainer>
                <Select
                  className={Styles.select}
                  value={this.state.assignment}
                  classNamePrefix='s-contact'
                  name='assignment'
                  label='Assignments'
                  getOptionLabel={({ title, organization }) =>
                    title || (organization && organization.name)
                  }
                  getOptionValue={({ uuid }) => uuid}
                  options={this.state.assignments}
                  onChange={this.handleChange}
                  isPaginated
                  loadOptions={this.onAssignmentLoadOptions}
                />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <TextInput
                  name='overallRating'
                  label='Score (1-5)'
                  type='number'
                  error={validateScore()}
                  value={this.state.overallRating}
                  onChange={(e) => this.handleChange(e, false, 1)}
                  onKeyPress={(e) => this.handleKeyPress(e)}
                />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <Quill
                  name='message'
                  label='Review'
                  placeholder='Add review'
                  defaultValue={this.state.message}
                  onChange={this.handleChange}
                  legacyCompat
                />
              </InputContainer>
            </InputGroup>

            <div className={Styles.row}>
              <Button
                name='delete'
                type={Button.Type.WHITE}
                onClick={(e) => this.props.deleteRating(this.props.rating)}>
                {`Delete`}
              </Button>
              <div className={Styles.action}>
                <Button
                  name='cancel'
                  type={Button.Type.WHITE_BLUE_OUTLINE}
                  onClick={this.handleCancel}>
                  {`Cancel`}
                </Button>

                <Button
                  name='submit'
                  type={Button.Type.BLUE}
                  onClick={this.handleSubmit}
                  isLoading={this.state.loading}>
                  {`Save`}
                </Button>
              </div>
            </div>
          </div>
        )}

        {!this.state.isEdit && (
          <div className={Styles.listPreview}>
            <div className={Styles.row}>
              <div>
                <p className={Styles.title}>
                  {this.state.assignment
                    ? this.state.assignment.title ||
                      (this.state.assignment.organization &&
                        this.state.assignment.organization.name) ||
                      'Untitled Assignment'
                    : 'General Review'}
                </p>
                {this.state.message && (
                  <div
                    className={Styles.institution}
                    dangerouslySetInnerHTML={{
                      __html: this.state.message,
                    }}></div>
                )}
                {this.state.status && (
                  <p className={Styles.timeframe}>{RatingStatus.getLabel(this.state.status)}</p>
                )}
              </div>
              <div className={Styles.action}>
                <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={this.handleEdit}>
                  {`Edit`}
                </Button>
              </div>
            </div>
            {this.state.internalNotes && (
              <div
                className={Styles.description}
                dangerouslySetInnerHTML={{
                  __html: this.state.internalNotes,
                }}></div>
            )}
          </div>
        )}
      </Card.Item>
    );
  }
}

export default RateItem;
