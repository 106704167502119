import Styles from './ListViewSearch.module.scss';
import { useRef, useEffect } from 'react';

const ListViewSearch = ({ title, search, onSearch, children }) => {
  const searchRef = useRef();

  useEffect(() => {
    if (search === '') searchRef.current.value = '';
  }, [search]);

  return (
    <div className={Styles.container}>
      {title && <span className={Styles.title}>{title}</span>}
      <input
        ref={searchRef}
        className={Styles.searchInput}
        name='search'
        type='text'
        placeholder='Search'
        defaultValue={search}
        onChange={onSearch}
      />
      {children}
    </div>
  );
};

export default ListViewSearch;
