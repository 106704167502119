import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import DualInput from '@hiredigital/ui/Input/DualInput';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import { AssignmentStatus, RateType, TimeRate } from '@hiredigital/lib/helpers/enum';
import withEmailLabel from '@hoc/withEmailLabel';
import { onAssignmentTalentUsersLoadOptions, onTeamsLoadOptions } from '@apis/dropdown';
import { isSalesAdmin, isPlacementAdmin } from '@helpers/permissions';
import RateInputGroup from '@components/RateInputGroup';

import Styles from './UserAssignments.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);
const UserSelect = withEmailLabel(memo(Select));

const AssignmentForm = ({
  data,
  currentUser,
  onFormChange,
  errors = {},
  hideBgColorArea = false,
  talentEngagementRate,
  clientEngagementRate,
  resourceUuid,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onFormChange?.({ name, value: value?.id || value });
  };

  const handleStartDateChange = (v) => {
    onFormChange?.({ name: 'startDate', value: v });
  };

  const handleEndDateChange = (v) => {
    onFormChange?.({ name: 'endDate', value: v });
    // adjustDateStart(v);
  };

  return (
    <div>
      {(isPlacementAdmin(currentUser) ||
        isSalesAdmin(currentUser) ||
        currentUser.adminPermission?.assignmentTalentRate ||
        currentUser.adminPermission?.assignmentClientRate) && (
        <div className={hideBgColorArea ? undefined : Styles.ratesContainer}>
          {/* visible for Brief Admin, Brief Placement Admin, Admin */}
          {(isPlacementAdmin(currentUser) || currentUser.adminPermission?.assignmentTalentRate) &&
            !isNewRates && (
              <Fragment>
                <InputGroup>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentRate'
                          label='Talent Rate (SGD)'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentRate}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentRateType'
                          label='Rate Type'
                          style={{ flexBasis: '115px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={RateType.values}
                          defaultValue={RateType.getEnum(data?.talentRateType)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentMinHours'
                          label='Min Talent Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentMinHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentMinPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.talentMinPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentMaxHours'
                          label='Max Talent Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentMaxHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentMaxPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.talentMaxPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                </InputGroup>
              </Fragment>
            )}
          {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles that is Brief Placement Admin */}
          {(isSalesAdmin(currentUser) || currentUser.adminPermission?.assignmentClientRate) &&
            !isNewRates && (
              <Fragment>
                <InputGroup>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandRate'
                          label='Client Rate (SGD)'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandRate}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandRateType'
                          label='Rate Type'
                          style={{ flexBasis: '115px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={RateType.values}
                          defaultValue={RateType.getEnum(data?.brandRateType)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandMinHours'
                          label='Min Client Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandMinHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandMinPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.brandMinPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandMaxHours'
                          label='Max Client Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandMaxHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandMaxPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.brandMaxPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                </InputGroup>
              </Fragment>
            )}

          {/* visible for Brief Admin, Brief Placement Admin, Admin */}
          {(isPlacementAdmin(currentUser) || currentUser.adminPermission?.assignmentTalentRate) &&
            isNewRates && (
              <RateInputGroup
                rate={talentEngagementRate}
                resource={'assignment'}
                resourceUuid={resourceUuid}
                hideBgColorArea={hideBgColorArea}
              />
            )}
          {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles that is Brief Placement Admin */}
          {(isSalesAdmin(currentUser) || currentUser.adminPermission?.assignmentClientRate) &&
            isNewRates && (
              <RateInputGroup
                isClient
                rate={clientEngagementRate}
                resource={'assignment'}
                resourceUuid={resourceUuid}
                hideBgColorArea={hideBgColorArea}
              />
            )}
        </div>
      )}
      <div className={Styles.otherFieldsArea}>
        <div className={Styles.leftFields}>
          <InputContainer>
            <TextInput
              name='title'
              label='Title'
              defaultValue={data?.title}
              onChange={handleChange}
            />
          </InputContainer>

          <InputGroup>
            <InputContainer>
              <DatePicker
                id='assignStartDate'
                name='startDate'
                label='Start Date'
                value={data?.startDate}
                onChange={handleStartDateChange}
                error={errors?.startDate}
              />
            </InputContainer>

            <InputContainer>
              <DatePicker
                id='assignEndDate'
                name='endDate'
                label='End Date'
                value={data?.endDate}
                onChange={handleEndDateChange}
                // minDate={data?.startDate ? new Date(data?.startDate) : undefined}
                error={errors?.endDate}
              />
            </InputContainer>
          </InputGroup>
        </div>
        <div className={Styles.rightFields}>
          <InputContainer>
            <Select
              className={Styles.selectStatus}
              classNamePrefix='s-contact'
              name='status'
              label='Status'
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
              options={AssignmentStatus.values}
              onChange={handleChange}
              defaultValue={AssignmentStatus.getEnum(data?.status)}
              menuPlacement='top'
            />
          </InputContainer>

          <InputContainer>
            <Select
              className={Styles.selectTeam}
              classNamePrefix='s-contact'
              name='team'
              label='Team'
              getOptionLabel={({ name }) => name}
              getOptionValue={({ uuid }) => uuid}
              isPaginated
              isClearable
              loadOptions={onTeamsLoadOptions}
              defaultValue={data?.team}
              onChange={handleChange}
              menuPlacement='top'
            />
          </InputContainer>

          <InputContainer>
            <UserSelect
              id='userID'
              className={Styles.selectUser}
              classNamePrefix='s-contact'
              name='user'
              label='User'
              getOptionLabel={({ name }) => name}
              getOptionValue={({ uuid }) => uuid}
              isPaginated
              isClearable
              loadOptions={(search, loadedOptions, params) =>
                onAssignmentTalentUsersLoadOptions(
                  data?.engagement?.uuid,
                  search,
                  loadedOptions,
                  params
                )
              }
              defaultValue={data?.user}
              onChange={handleChange}
              menuPlacement='top'
            />
          </InputContainer>
        </div>
      </div>
    </div>
  );
};

AssignmentForm.propTypes = {
  currentUser: PropTypes.object,
  data: PropTypes.object,
  onFormChange: PropTypes.func,
};

export default AssignmentForm;
