import { useMemo, useState, useEffect, memo } from 'react';
import { Switch, Route } from 'react-router-dom';

import Button from '@hiredigital/ui/Button';

import AppLayout from '@components/Layout/AppLayout';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import BillModal from './edit';
import { formatter } from '@helpers/formats';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { getBills, getTransferwiseCsv, saveBill, createBill } from '@apis/payments';

import Styles from '@styles/PageList.module.scss';
import PageStyles from './list.module.scss';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import { Link } from 'react-router-dom';

import { InvoiceStatus } from '@hiredigital/lib/helpers/enum';
import Select from '@hiredigital/ui/SelectMenu';
import withParameters from '@hoc/withParameters';

const StatusColumn = memo(({ bill }) => {
  const [status, setStatus] = useState(bill?.status);

  const handleUpdateStatus = (value) => {
    saveBill(bill?.id, { status: value }).then(
      ({ data }) => {
        setStatus(data.status);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div className={PageStyles.selectStatus}>
      {/* <MemoSelectMenu
        title='Select Status'
        hasFilter={false}
        height={175}
        position={Position.BOTTOM_RIGHT}
        options={InvoiceStatus.values.map((a) => ({
          label: a.label,
          value: String(a.id),
        }))}
        selected={String(status)}
        isMultiSelect={false}
        closeOnSelect={true}
        onSelect={(item) => handleUpdateStatus(parseInt(item.value))}>
        <div className={Styles.toggleContainer}>
          {InvoiceStatus.getEnum(status).label}
          <div className={Styles.toggle} />
        </div>
      </MemoSelectMenu> */}
      <Select
        options={InvoiceStatus.values}
        defaultValue={InvoiceStatus.getEnum(status)}
        onChange={(value) => {
          const id = value?.id;
          setStatus(id);
          handleUpdateStatus(id);
        }}
      />
    </div>
  );
});

const BillList = ({ location, history, initialProps, ...props }) => {
  const [search, setSearch] = useState(initialProps?.search);
  const [status, setStatus] = useState(
    initialProps?.status ? parseInt(initialProps?.status) : undefined
  );
  const [isAdding, setIsAdding] = useState(false);
  const [updateRow, setUpdateRow] = useState();

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Reference',
        id: 'invoice_number',
        width: 200,
        canSort: true,
        accessor: (b) => <Link to={`/bills/${b?.id}`}>{b?.invoiceNumber || 'No Reference'}</Link>,
      },
      {
        Header: 'Payment Contact',
        id: 'name',
        size: 'medium',
        width: 200,
        accessor: (b) => b?.payment?.user?.name,
      },

      {
        Header: 'Date',
        id: 'date',
        size: 'small',
        width: 100,
        accessor: (b) => (b.date ? toReadableDate(b.date) : ''),
      },
      {
        Header: 'Amt Paid',
        id: 'amount_paid',
        width: 100,
        canSort: true,
        accessor: (b) => formatter.format(b.amountPaid),
      },
      {
        Header: 'Amt Due',
        id: 'amount_due',
        width: 100,
        canSort: true,
        accessor: (b) => formatter.format(b.amountDue),
      },
      {
        Header: 'Transferwise ID',
        id: 'transferwise_id',
        size: 'small',
        accessor: (b) =>
          b.payment?.transferwiseRecipients?.map((recipient) => recipient.transferwiseId),
      },
      {
        Header: 'Status',
        id: 'status',
        size: 'small',
        canSort: true,
        truncate: false,
        accessor: (b) => <StatusColumn bill={b} />,
      },
    ],
    []
  );

  const handleAddNewInvoice = () => {
    setIsAdding(true);
    createBill()
      .then(({ data }) => {
        setIsAdding(false);
        history.push(`/bills/${data.id}`);
      })
      .catch((error) => {
        setIsAdding(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setPageTitle('Bills');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const generateCsv = () => getTransferwiseCsv();

  const handleStatusChange = (v) => setStatus(v?.id);

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.wideBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Bills`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />

          <div className={PageStyles.filterContainer}>
            <Select
              name='status'
              classNamePrefix={`s-filter`}
              getOptionValue={(option) => option.id}
              options={InvoiceStatus.values}
              onChange={handleStatusChange}
              placeholder={`Status`}
              defaultValue={InvoiceStatus.getEnum(status)}
              isClearable
            />
          </div>

          <Button className={Styles.addButton} type={Button.Type.BLUE} onClick={generateCsv}>
            {` Generate CSV`}
          </Button>

          <Button
            className={Styles.addButton}
            type={Button.Type.BLUE}
            onClick={handleAddNewInvoice}
            isLoading={isAdding}>
            {` Add New Invoice`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getBills}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        updateRow={updateRow}
        search={search}
        filters={{ status }}
      />

      <Switch>
        <Route
          path={`/bills/:id`}
          render={(routeProps) => (
            <BillModal
              {...routeProps}
              billId={routeProps?.match?.params?.id}
              onInvoiceUpdate={setUpdateRow}
            />
          )}
        />
      </Switch>
    </AppLayout>
  );
};

export default withParameters(BillList);
