import { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getEmail, putEmail } from '@apis/emails';
import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import AppLayout from '@components/Layout/AppLayout';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { EmailStatus } from '@hiredigital/lib/helpers/enum';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-min-noconflict/ext-searchbox';

import Dialog from '@hiredigital/ui/Dialog/Dialog';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

class EmailDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      emailId: this.props.match.params.emailId,
      status: '',
      subject: '',
      to: '',
      fromEmail: '',
      cc: '',
      bcc: '',
      message: '',
      htmlMessage: '',
      context: '',
      headers: '',
      // Custom actions
      isModalOpen: false,
      hasInitLoad: false,
      autoSaveTimeout: null,
      isSaving: false,
      currentTab: '',
    };
    this.emailStatus = [...EmailStatus.values, { id: 3, label: 'Not Sent' }];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) {
      return true;
    }
    if (
      nextState.isModalOpen !== this.state.isModalOpen ||
      nextState.currentTab !== this.state.currentTab ||
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.isSaving !== this.state.isSaving
    ) {
      return true;
    }
    return false;
  }

  componentDidMount = () => {
    const hash = this.props.location && this.props.location.hash;
    this.setState({ currentTab: (hash && hash.substr(1)) || 'email' });

    this.loadEmail().then(({ data }) => {
      setPageTitle(`Emails | ${(data.to && data.to[0]) || 'New Email'}`);
      this.populateFields(data, () => {
        this.setState({ hasInitLoad: true });
      });
    });
  };

  loadEmail = () => getEmail(this.state.emailId);

  populateFields = (data, completionCallback) => {
    this.setState(
      {
        ...data,
        to: (data.to && !!data.to.length && data.to[0]) || '',
        cc: (data.cc && !!data.cc.length && data.cc[0]) || '',
        bcc: (data.bcc && !!data.bcc.length && data.bcc[0]) || '',
        status:
          EmailStatus.values.find(({ id }) => id === data.status) ||
          (!!this.emailStatus.length && this.emailStatus[3]) ||
          null,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.handleAutoSave();
  };

  handleSelectChange = (event, setId) => {
    const { name, value } = event.target;
    this.setState({
      [name]: setId ? value.id : value,
    });
    this.handleAutoSave();
  };

  handleNestedPropertyChange = (event, key, autoSave = true) => {
    const { name, value } = event.target;
    this.setState((state) => ((state[key][name] = value), state));
    if (autoSave) this.handleAutoSave();
  };

  handleImmediateSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 0),
    });
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleSubmit = (storeResponse = true) => {
    this.setState({ submitted: true });
    const {
      emailId,
      subject,
      headers,
      context,
      status,
      fromEmail,
      to,
      cc,
      bcc,
      message,
      htmlMessage,
    } = this.state;

    this.setState({ isSaving: true });
    const data = {
      subject,
      headers,
      context,
      fromEmail,
      status: status.id === 3 ? null : status.id,
      to: to ? [to] : [],
      cc: cc ? [cc] : [],
      bcc: bcc ? [bcc] : [],
      message,
      htmlMessage,
    };

    putEmail(emailId, data)
      .then(
        (response) => {
          this.setState({ isSaving: false });
          if (storeResponse) {
            this.populateFields(response);
          }
        },
        (error) => {
          this.setState({ isSaving: false, autoSaveTimeout: null });
        }
      )
      .catch((error) => {
        this.setState({ isSaving: false, autoSaveTimeout: null });
      });
  };

  handleSplitScreenView = () => {
    this.setState({ isModalOpen: true });
  };

  handleSplitScreenViewClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleEditorMessageChange = (propKey, value) => {
    this.setState(
      {
        [propKey]: value,
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  navigateTabLocation = (tab) => {
    this.setState({ currentTab: tab }, () => {
      const { history, location } = this.props;
      history.push(`${location.pathname}#${tab}`);
    });
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/emails'>
              {`Emails`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>
              {this.state.to && !!this.state.to.length && this.state.to}
            </div>
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <Tab.Container
              defaultActiveKey='email'
              activeKey={this.state.currentTab}
              mountOnEnter={true}>
              <Nav variant='tabs' className={classNames(Styles.row, PageStyles.headlineRow)}>
                <Nav.Item>
                  <Nav.Link
                    eventKey='email'
                    onClick={() => {
                      this.navigateTabLocation('email');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Email`}</h3>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='additional'
                    onClick={() => {
                      this.navigateTabLocation('additional');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Additional Details`}</h3>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className={PageStyles.tabContent}>
                <Tab.Pane eventKey='email'>
                  <InputGroup>
                    <InputContainer style={{ width: '344px' }}>
                      <TextInput
                        name='fromEmail'
                        label='From'
                        defaultValue={this.state.fromEmail}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                    <InputContainer style={{ width: '344px' }}>
                      <TextInput
                        name='to'
                        label='To'
                        defaultValue={this.state.to}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <TextInput
                        name='subject'
                        label='Subject'
                        defaultValue={this.state.subject}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                    <InputContainer style={{ marginBottom: '0' }}>
                      <Select
                        data-test-id='status'
                        className={PageStyles.select}
                        defaultValue={this.state.status}
                        classNamePrefix='s-contact'
                        name='status'
                        label='Status'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        onChange={this.handleSelectChange}
                        options={this.emailStatus}
                      />
                    </InputContainer>
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <label className={Styles.label}>{`Message`}</label>
                      <div className={Styles.codeEditorContainer} style={{ minHeight: '300px' }}>
                        <AceEditor
                          placeholder=''
                          mode='handlebars'
                          theme='github'
                          name='message'
                          wrapEnabled={true}
                          onChange={(value) => {
                            this.handleEditorMessageChange('message', value);
                          }}
                          fontSize={14}
                          showPrintMargin={true}
                          showGutter={true}
                          highlightActiveLine={true}
                          width='100%'
                          height='300px'
                          value={this.state.message}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                          }}
                        />
                      </div>
                    </InputContainer>
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <label className={Styles.label}>{`HTML Message`}</label>
                      <div className={Styles.codeEditorContainer}>
                        <iframe
                          src={`data:text/html, ${encodeURIComponent(this.state.htmlMessage)}`}
                          className={Styles.codePreviewContainer}
                          style={{ marginBottom: '-5px' }}
                        />
                      </div>
                    </InputContainer>
                  </InputGroup>

                  <div className={PageStyles.formContainer}>
                    <div className={Styles.saveRow}>
                      <Button
                        name='submit'
                        className={Styles.saveButton}
                        type={Button.Type.BLUE_OUTLINE}
                        onClick={this.handleSplitScreenView}>
                        {`Edit HTML Email`}
                      </Button>

                      <Button
                        name='submit'
                        className={Styles.saveButton}
                        type={Button.Type.BLUE}
                        onClick={this.handleImmediateSave}
                        isLoading={this.state.isSaving}>
                        {`Save Email`}
                      </Button>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey='additional'>
                  <InputGroup>
                    <InputContainer style={{ width: '344px' }}>
                      <TextInput
                        name='cc'
                        label='Cc'
                        defaultValue={this.state.cc}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                    <InputContainer style={{ width: '344px' }}>
                      <TextInput
                        name='bcc'
                        label='Bcc'
                        defaultValue={this.state.bcc}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <TextInput
                        name='headers'
                        label='Headers'
                        defaultValue={this.state.headers}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <TextArea
                        name='context'
                        label='Context'
                        defaultValue={this.state.context}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                  </InputGroup>

                  <div className={PageStyles.formContainer}>
                    <div className={Styles.saveRow}>
                      <Button
                        name='submit'
                        className={Styles.saveButton}
                        type={Button.Type.BLUE}
                        onClick={this.handleImmediateSave}
                        isLoading={this.state.isSaving}>
                        {`Save Email`}
                      </Button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}

        <Dialog
          title={'HTML Message'}
          isOpen={this.state.isModalOpen}
          bodyClass={Styles.dialogBody}
          onClose={this.handleSplitScreenViewClose}>
          {/* <div className={Styles.modalHeader}>
            <div className={Styles.modalTitle}>HTML Message</div>
            <div onClick={this.handleSplitScreenViewClose} className={Styles.btnIcon}>
              <IconClose />
            </div>
          </div> */}
          <div className={Styles.modalBody}>
            <div className={Styles.splitScreen} style={{ marginRight: '7.5px' }}>
              <AceEditor
                placeholder='HTML Message'
                mode='handlebars'
                theme='github'
                name='htmlMessage'
                wrapEnabled={true}
                onChange={(value) => {
                  this.handleEditorMessageChange('htmlMessage', value);
                }}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                width='100%'
                height='100%'
                value={this.state.htmlMessage}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </div>
            <div className={Styles.splitScreen} style={{ marginLeft: '7.5px' }}>
              <iframe
                src={`data:text/html, ${encodeURIComponent(this.state.htmlMessage)}`}
                className={Styles.codePreviewContainer}
              />
            </div>
          </div>
        </Dialog>
      </AppLayout>
    );
  }
}

export default EmailDetail;
