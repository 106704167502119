import { Link } from 'react-router-dom';
import Styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ links }) => {
  return (
    <p className={Styles.container}>
      {links.map((v, i) => {
        return v?.to ? (
          <Link to={v.to} key={i} className={Styles.item}>
            {v?.text || 'No link'}
          </Link>
        ) : (
          <span key={i} className={Styles.item}>
            {v?.text || 'No title'}
          </span>
        );
      })}
    </p>
  );
};

export default Breadcrumbs;
