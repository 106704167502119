import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import IconDrag from '@hiredigital/ui/Icon/icons/drag.inline.svg';
import { ReviewStatus } from '@hiredigital/lib/helpers/enum';
import { getPortfolioThumbnail, checkImageUrl } from '@hiredigital/lib/helpers/utils';

// import Icon from '@hiredigital/ui/Icon/Icon';

import Styles from './PortfolioItem.module.scss';
// import { checkImageUrl } from '@helpers/image';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const PortfolioItem = ({
  item,
  handleClass,
  onEdit,
  onPreview,
  viewOnly = false,
  showExcerpt = true,
}) => {
  // let itemImage;
  // let itemThumbnail;
  // if (
  //   item.image ||
  //   item.attachment ||
  //   (item.caseItems && item.caseItems[0] && item.caseItems[0].image)
  // ) {
  //   itemThumbnail = item.image || item.attachment || item.caseItems[0].image;
  //   itemImage = checkImageUrl(itemThumbnail);
  // }

  const itemThumbnail = getPortfolioThumbnail(item);
  const itemImage = checkImageUrl(itemThumbnail);

  const title =
    item.title || (item.caseItems && item.caseItems[0] && item.caseItems[0].title) || '';
  const description = (item.caseItems && item.caseItems[0] && item.caseItems[0].description) || '';

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit?.(item);
  };

  const handlePreview = (e) => {
    e.stopPropagation();
    onPreview?.(item);
  };

  return (
    <div className={Styles.container}>
      {!viewOnly && (
        <div className={classNames(Styles.header, handleClass)}>
          <button className={Styles.viewButton} onClick={handlePreview}>
            {`Preview`}
          </button>
          <button className={Styles.openButton} onClick={handleEdit}>
            {`Edit`}
          </button>
          <span className={Styles.status}>
            {item.reviewStatus ? `${ReviewStatus.getLabel(item.reviewStatus)}` : ''}
          </span>
          <IconDrag className={Styles.dragIcon} />
        </div>
      )}

      <div style={{ position: 'relative', lineHeight: 0 }} onClick={handlePreview}>
        {item.showThumbnail && itemImage ? (
          <img className={Styles.thumbnail} alt='' src={itemThumbnail} />
        ) : (
          <div className={Styles.thumbnailPlaceholder} />
        )}

        {!viewOnly && (
          <Fragment>
            {((!description && !item.content) || !title || item.private) && (
              <div className={Styles.notifications}>
                {!description && !item.content && (
                  <div className={classNames(Styles.notify, Styles.noContent)}>
                    {`No Description`}
                  </div>
                )}

                {!title && (
                  <div className={classNames(Styles.notify, Styles.noContent)}>{`No Title`}</div>
                )}

                {item.private && (
                  <div className={classNames(Styles.notify, Styles.isPrivate)}>{`Private`}</div>
                )}
              </div>
            )}
          </Fragment>
        )}

        <div className={Styles.details}>
          {item.publication && (item.publication.name || item.publication.url) && (
            <p className={Styles.label}>{item.publication.name || item.publication.url}</p>
          )}

          <ResponsiveEllipsis
            className={Styles.title}
            text={title || `Untitled Content`}
            maxLine='3'
            ellipsis='&hellip;'
            trimRight
            basedOn='letters'
            component='h3'
          />

          {showExcerpt && (
            <Fragment>
              {item.content && (
                <ResponsiveEllipsis
                  className={Styles.content}
                  text={item.content ? item.content : ''}
                  maxLine='6'
                  ellipsis='&hellip;'
                  trimRight
                  basedOn='letters'
                  component='p'
                />
              )}

              {description && (
                <ResponsiveEllipsis
                  className={Styles.content}
                  text={description}
                  maxLine='6'
                  ellipsis='&hellip;'
                  trimRight
                  basedOn='letters'
                  component='p'
                />
              )}
            </Fragment>
          )}

          {item?.skills?.length > 0 && (
            <div>
              <div className={Styles.keywords}>
                {item.skills.map((skill, index) => (
                  <div className={Styles.keyword} key={index}>
                    {skill.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PortfolioItem.propTypes = {
  item: PropTypes.object,
  handleClass: PropTypes.string,
  onEdit: PropTypes.func,
  onPreview: PropTypes.func,
};

export default PortfolioItem;
