import { useMemo, Fragment, useState, useEffect, memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Toggle from 'react-toggle';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';

import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import SplitButton from '@hiredigital/ui/Button/SplitButton';

import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { ContentType, MessageType } from '@hiredigital/lib/helpers/enum';
import { toReadableShortDateTime } from '@hiredigital/lib/helpers/date';

import { getMessages, putMessage, deleteMessage } from '@apis/messages';
import withParameters from '@hoc/withParameters';
import { onAllUsersLoadOptions } from '@apis/dropdown';

import Quill from '@hiredigital/ui/Quill/Editor';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import AppLayout from '@components/Layout/AppLayout';

import Styles from '@styles/PageList.module.scss';
import PageStyles from './Styles.module.scss';

const MessageList = ({ initialProps, location, history, ...props }) => {
  const [search, setSearch] = useState(initialProps?.search);
  const [updateRow, setUpdateRow] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  let timeout = null;

  const RowSubComponent = ({ row, row: { original } }, onSaveCallback, onDeleteCallback) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [flagged, setFlagged] = useState(false);
    const [errors, setErrors] = useState({});
    const [sender, setSender] = useState(original?.sender);
    const [title, setTitle] = useState(original?.title);
    const [type, setType] = useState(original?.type);
    const [content, setContent] = useState(original?.content);
    const [isSaving, setIsSaving] = useState(false);

    const handleToggleChange = (event, completionCallback) => {
      const { checked } = event.target;
      setFlagged(checked);
    };

    const handleDelete = () => {
      setIsDeleting(true);
      onDeleteCallback(original);
      toggleExpand();
      deleteMessage(original?.id)
        .then(() => {
          setIsDeleting(false);
        })
        .catch((error) => {
          console.log(error);
          setIsDeleting(false);
        });
    };

    const handleCancel = () => {
      setContent(original?.content);
      toggleExpand();
    };

    const toggleExpand = () => {
      row?.getToggleRowExpandedProps()?.onClick();
    };

    const handleSubmit = () => {
      setIsSaving(true);
      onSaveCallback({
        ...original,
        sender,
        content,
        flagged,
      });
      putMessage(original?.id, {
        ...original,
        sender: sender?.uuid || null,
        content,
        flagged,
      })
        .then(({ data }) => {
          setIsSaving(false);
          // toggleExpand();
        })
        .catch((error) => {
          console.log(error);
          setIsSaving(false);
        });
    };

    return (
      <Fragment>
        <div className={PageStyles.editForm}>
          <InputGroup>
            <InputContainer>
              <Select
                data-test-id='sender'
                defaultValue={sender}
                classNamePrefix='s-contact'
                name='sender'
                label='Sender'
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                onChange={(e) => setSender(e.target.value)}
                isPaginated
                loadOptions={onAllUsersLoadOptions}
                isClearable
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name='title'
                label='Discussion'
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
                disabled
              />
            </InputContainer>
          </InputGroup>
          {type !== MessageType.UPLOAD.id && type !== MessageType.CONTENT.id && (
            <InputGroup>
              <InputContainer>
                <Quill
                  name='content'
                  label='Message'
                  placeholder='Message'
                  defaultValue={content || ''}
                  onChange={(e) => setContent(e.target.value)}
                  error={errors?.content}
                  legacyCompat
                />
              </InputContainer>
            </InputGroup>
          )}

          <div className={PageStyles.actionContainer}>
            <Button
              type={Button.Type.WHITE_BLUE_OUTLINE}
              onClick={handleDelete}
              isLoading={isDeleting}>
              {`Delete`}
            </Button>
            <div className={PageStyles.toggleContainer} name='flagged'>
              <Toggle
                id={`flagged`}
                name='flagged'
                defaultChecked={flagged}
                onChange={handleToggleChange}
              />
              <label htmlFor={`flagged`} className={PageStyles.toggleLabel}>
                {`Flagged`}
              </label>
            </div>
            <Button type={Button.Type.WHITE_BLUE_OUTLINE} onClick={handleCancel}>
              {`Cancel`}
            </Button>
            <Button type={Button.Type.BLUE} onClick={handleSubmit} isLoading={isSaving}>
              {`Save`}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  const handleEdit = (e) => {
    e.preventDefault();
  };

  const handleDeleteMessage = (item) => {
    handleDeleteRow(item);
    deleteMessage(item.id);
  };

  const handleFlagChange = (event, message) => {
    const { checked } = event.target;
    const data = {
      ...message,
      flagged: checked,
    };
    putMessage(message.id, { flagged: checked });
  };

  const getContent = ({ type, metaObject, content, discussion, ...things }, row) => {
    switch (type) {
      case 2:
        return (
          <Fragment>
            {metaObject && (
              <div className={classNames(Styles.cellText, Styles.cellContent)}>
                <div className={Styles.senderLink}>
                  <a href={metaObject.attachment} target='_blank' rel='noopener'>
                    {metaObject.attachmentName}
                  </a>
                  {metaObject.preview && (
                    <a
                      className={Styles.attachmentPreview}
                      href={metaObject.attachment}
                      target='_blank'
                      rel='noopener'>
                      <img src={metaObject.preview} />
                    </a>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        );
      case 5:
        return (
          <Fragment>
            {metaObject && (
              <div>
                {metaObject.type === ContentType.ATTACHMENT.id && (
                  <div className={classNames(Styles.cellText, Styles.cellContent)}>
                    <div className={Styles.senderLink}>
                      <a href={metaObject.attachment} target='_blank' rel='noopener'>
                        {metaObject.attachmentName}
                      </a>
                      {metaObject.preview && (
                        <a
                          className={Styles.attachmentPreview}
                          href={metaObject.attachment}
                          target='_blank'
                          rel='noopener'>
                          <img src={metaObject.preview} />
                        </a>
                      )}
                    </div>
                  </div>
                )}

                {metaObject.type === ContentType.LINK.id && (
                  <div className={classNames(Styles.cellText, Styles.cellContent)}>
                    <div className={Styles.senderLink}>
                      <a href={metaObject.url} target='_blank' rel='noopener'>
                        {metaObject.url}
                      </a>
                      {metaObject.pageScreenshot && (
                        <a
                          className={Styles.attachmentPreview}
                          href={metaObject.pageScreenshot}
                          target='_blank'
                          rel='noopener'>
                          <img src={metaObject.pageScreenshot} />
                        </a>
                      )}
                    </div>
                  </div>
                )}

                {metaObject.type === ContentType.TEXT.id && (
                  <div className={classNames(Styles.cellText, Styles.cellContent)}>
                    <div className={Styles.senderLink}>
                      <Link to={`/projects/${discussion.related.id}`}>
                        {metaObject.title || 'New Text Document'}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Fragment>
        );
      default:
        return (
          <div
            className={PageStyles.senderLink}
            {...row.getToggleRowExpandedProps()}
            dangerouslySetInnerHTML={{ __html: content }}></div>
        );
    }
  };

  const getTitle = ({ related, title }) => {
    switch (related?.model) {
      case 'Project':
        return (
          <a
            className={PageStyles.titleLink}
            href={`${process.env.APP_ENDPOINT}/projects/${related.id}`}
            rel='nofollow noopener'
            target='_blank'>
            {`${related.model}: ${title}`}
            <IconExternal className={PageStyles.externalIcon} />
          </a>
        );
      case 'Application':
        return (
          <a
            className={PageStyles.titleLink}
            href={`${process.env.APP_ENDPOINT}/briefs/${related.brief}/engagements/${related.engagement}`}
            rel='nofollow noopener'
            target='_blank'>
            {`${related.model}: ${title}`}
            <IconExternal className={PageStyles.externalIcon} />
          </a>
        );
      case 'OrganizationTalent':
        return (
          <a
            className={PageStyles.titleLink}
            href={`${process.env.APP_ENDPOINT}/dashboard/projects/?discussion=${related.id}`}
            rel='nofollow noopener'
            target='_blank'>
            {`Direct: ${title}`}
            <IconExternal className={PageStyles.externalIcon} />
          </a>
        );
      default:
        return <p className={Styles.cellText}>{title}</p>;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Sender',
        canSort: true,
        id: 'sender',
        minWidth: 250,
        Cell: ({ row, row: { original: message } }) => {
          return (
            <div className={Styles.link} {...row.getToggleRowExpandedProps()}>
              {message?.sender?.name || 'System Message'}
            </div>
          );
        },
      },
      {
        Header: 'Message',
        minWidth: 350,
        canSort: true,
        truncate: false,
        id: 'content',
        Cell: ({ row, row: { original: item } }) => {
          return (
            <div style={{ display: 'flex', width: '100%' }}>
              {getContent(item, row)}
              <div style={{ marginLeft: 'auto' }}>
                <SplitButton
                  more={[
                    { text: 'Edit Message', ...row.getToggleRowExpandedProps() },
                    { text: 'Delete Message', onClick: () => handleDeleteMessage(item) },
                  ]}
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Flagged',
        minWidth: 75,
        maxWidth: 75,
        truncate: false,
        Cell: ({ row: { original: message } }) => {
          return (
            <span>
              <Toggle
                id={`flagged-${message?.id}`}
                name='flagged'
                defaultChecked={message.flagged}
                onChange={(e) => handleFlagChange(e, message)}
              />
            </span>
          );
        },
      },
      {
        Header: 'Discussion',
        minWidth: 250,
        canSort: true,
        truncate: false,
        id: 'discussion',
        accessor: (e) => getTitle(e.discussion),
      },
      {
        Header: 'Sent',
        canSort: true,
        id: 'created',
        accessor: (m) => toReadableShortDateTime(m.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Messages');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleSaveRow = (data) => {
    setUpdateRow(data);
  };
  const handleDeleteRow = (data) => {
    setDeleteRow(data);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.wideBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Messages`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getMessages}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        updateRow={updateRow}
        deleteRow={deleteRow}
        data={data}
        meta={meta}
        rowHeight={`auto`}
        renderRowSubComponent={RowSubComponent}
        onDeleteCallback={handleDeleteRow}
        onSaveCallback={handleSaveRow}
      />
    </AppLayout>
  );
};

MessageList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(MessageList);
