import { useState, useEffect, useMemo } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@hiredigital/ui/Button';
import Toggle from 'react-toggle';
import { PortfolioItemType, ReviewStatus } from '@hiredigital/lib/helpers/enum';
import ListImage from '@components/ListImage';
import Styles from '@styles/PageList.module.scss';
import AppLayout from '@components/Layout/AppLayout';

import {
  getScreenshotItems,
  saveScreenshotItem,
  replaceScreenshotItem,
  refreshScreenshotItem,
} from '@apis/portfolios';
import NewTable from '@components/Table/NewTable/Container';

import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import ItemStyles from './Styles.module.scss';

const PortfolioItemList = ({
  loadData,
  isLoaded,
  hasMore,
  sort,
  searchData,
  sortData,
  location,
  history,
  initialProps,
  ...props
}) => {
  const [search, setSearch] = useState(initialProps?.search);
  const [loading, setLoading] = useState(false);
  const [updateRow, setUpdateRow] = useState(undefined);

  let timeout = null;

  const handleReplace = async (item) => {
    const { data } = await replaceScreenshotItem(item.id);
    setUpdateRow(data);
  };

  const handleRefresh = async (item) => {
    const { data } = await refreshScreenshotItem(item.id);
    setUpdateRow(data);
  };
  const handleShowThumbnail = (e, item) => {
    const { checked } = e.target;
    const data = {
      ...item,
      showThumbnail: checked,
    };
    setUpdateRow(data);
    saveScreenshotItem(item.id, { showThumbnail: checked });
  };

  const handleShowLiveUrl = (e, item) => {
    const { checked } = e.target;
    const data = {
      ...item,
      showLiveUrl: checked,
    };
    setUpdateRow(data);
    saveScreenshotItem(item.id, { showLiveUrl: checked });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        size: 'medium',
        canSort: true,
        accessor: (u) => u.name,
        Cell: ({ row: { original: item } }) => {
          return (
            <Link className={Styles.profile} to={`/profiles/${item?.user?.uuid}`}>
              <ListImage user={item.user} />
              <div>
                <p data-test-id='client-table-item' className={Styles.name}>
                  {item.user?.name}
                </p>
              </div>
            </Link>
          );
        },
      },
      {
        Header: 'Type',
        id: 'type',
        size: 'small',
        canSort: true,
        accessor: (u) => PortfolioItemType.getLabel(u.type),
      },
      {
        Header: 'Status',
        id: 'reviewStatus',
        size: 'small',
        canSort: true,
        accessor: (u) => (
          <div>
            <div style={{ marginBottom: '10px' }}>{ReviewStatus.getLabel(u.reviewStatus)}</div>
            <label
              for={`thumbnail-${u?.id}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span style={{ marginRight: '10px' }}>Show Thumbnail</span>
              <Toggle
                id={`thumbnail-${u?.id}`}
                name='thumbnail'
                defaultChecked={u.showThumbnail}
                onChange={(e) => handleShowThumbnail(e, u)}
              />
            </label>

            <label
              for={`live-${u?.id}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span style={{ marginRight: '10px' }}>Show Live URL</span>
              <Toggle
                id={`live-${u?.id}`}
                name='live'
                defaultChecked={u.showLiveUrl}
                onChange={(e) => handleShowLiveUrl(e, u)}
              />
            </label>
            <Button type={Button.Type.BLUE} onClick={(e) => handleReplace(u)}>
              Replace Screenshot
            </Button>
            <Button type={Button.Type.BLUE} onClick={(e) => handleRefresh(u)}>
              Refresh Screenshot
            </Button>
          </div>
        ),
      },
      {
        Header: 'Image',
        id: 'image',
        size: 'small',
        canSort: true,
        accessor: (u) => <img className={ItemStyles.image} src={u.image} />,
      },
      {
        Header: 'Screenshot',
        id: 'pageScreenshot',
        size: 'small',
        canSort: true,
        accessor: (u) => (
          <a className={ItemStyles.pageScreenshot} href={u.url} target='_blank'>
            <img src={u.pageScreenshot} />{' '}
          </a>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('PortfolioItems');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const memoSearchParams = useMemo(() => {
    return { search };
  }, [search]);

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Portfolio Items`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />
        </div>
      }>
      <NewTable
        columns={columns}
        getListData={async (config) => await getScreenshotItems(config)}
        searchParams={memoSearchParams}
        updateRow={updateRow}
        rowHeight={200}
      />
    </AppLayout>
  );
};

PortfolioItemList.propTypes = {
  location: PropTypes.object,
};

export default PortfolioItemList;
