import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BaseCountry } from '@hiredigital/lib/helpers/enum';
import Button from '@hiredigital/ui/Button';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconXero from '@hiredigital/ui/Icon/icons/xero.inline.svg';

import withParameters from '@hoc/withParameters';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { getAllPrepayments, refreshAllPrepayments } from '@apis/payments';
import AppLayout from '@components/Layout/AppLayout';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from '@styles/PageList.module.scss';
import ListStyles from './list.module.scss';

const PrepaymentList = ({ location, history, initialProps, ...props }) => {
  const [search, setSearch] = useState(initialProps?.search);
  const [remainingCredit, setCredit] = useState(0);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Prepayment',
        id: 'reference',
        size: 'medium',
        canSort: true,
        Cell: ({ row: { original: prepayment } }) => {
          return (
            <span
              style={{
                display: 'flex',
                alignItems: 'space-between',
                width: '100%',
              }}>
              <a className={ListStyles.link} href={prepayment.attachment} target='_blank'>
                {`${prepayment.xeroIdentifier || prepayment.bankTransactionId}`}
              </a>
              <a
                className={ListStyles.xeroLink}
                href={`https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=${prepayment.prepaymentId}`}
                target='_blank'>
                <IconXero className={ListStyles.xeroIcon} />
              </a>
            </span>
          );
        },
      },
      {
        Header: 'Organization',
        id: 'payment__organization__name',
        size: 'small',
        canSort: true,
        accessor: (u) => u?.payment?.organization?.name,
      },
      {
        Header: 'Country',
        accessor: (d) => BaseCountry.getLabel(d.baseCountry),
      },
      {
        Header: 'Date',
        id: 'date',
        size: 'small',
        canSort: true,
        accessor: (u) => toReadableDate(u.date),
      },
      {
        Header: 'Remaining Credit',
        id: 'remaining_credit',
        size: 'small',
        canSort: true,
        accessor: (u) => formatter.format(u.remainingCredit),
      },
      {
        Header: 'Cur',
        width: 50,
        accessor: 'currencyCode',
      },
      {
        Header: 'Total Amount',
        id: 'sub_total',
        size: 'small',
        canSort: true,
        accessor: (u) => formatter.format(u.totalAmount),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Prepayments');
  }, []);

  const handleRefreshPrepayments = (e) => {
    refreshAllPrepayments().then(
      (response) => {
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      },
      (error) => {}
    );
  };

  const handleResponse = (d, m) => {
    setCredit(m.remainingCredit);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const formatter = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    minimumFractionDigits: 2,
  });

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Xero Prepayments`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />
          <div className={ListStyles.headerNote}>{`${formatter.format(
            remainingCredit
          )} Credits Outstanding`}</div>
          <Button
            className={Styles.addButton}
            type={Button.Type.BLUE}
            onClick={handleRefreshPrepayments}>
            {` Refresh Prepayments`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={async (config) => await getAllPrepayments(config)}
        listDataCallback={handleResponse}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
      />
    </AppLayout>
  );
};

PrepaymentList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(PrepaymentList);
