import { authRequest, request } from './utils';

export const getWiseRecpients = async (context) => {
  const url = `admin/wise-recipients/`;
  return await authRequest.get(url, context);
};

export const generateWiseRecipientCsv = async () => {
  const url = `${process.env.API_ENDPOINT}/admin/wise-recipients/generate-csv/`;
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
};

export const getStripePaymentLink = async (amount, currency) => {
  const url = `${process.env.MARKETING_ENDPOINT}/api/stripe?amount=${amount}&currency=${currency}`;
  return await request.get(url);
};
export const patchPayment = async (uuid, data) => {
  const url = `admin/payments/${uuid}/`;
  return await authRequest.patch(url, data);
};

export const syncWithXero = async (uuid) => {
  const url = `admin/payments/${uuid}/sync-with-xero/`;
  return await authRequest.post(url);
};

export const syncWithUsXero = async (uuid) => {
  const url = `admin/payments/${uuid}/sync-with-xero-us/`;
  return await authRequest.post(url);
};

export const getCreditNotes = async (orgUuid, data) => {
  const url = `admin/orgs/${orgUuid}/xero-creditnotes/`;
  return await authRequest.get(url, data);
};

export const refreshCreditNotes = async (orgUuid) => {
  const url = `admin/orgs/${orgUuid}/xero-creditnotes/refresh/`;
  return await authRequest.post(url);
};

export const getInvoices = async (parent, uuid, data) => {
  // Parent can be either users or orgs
  const url = `admin/${parent}/${uuid}/xero-invoices/`;
  return await authRequest.get(url, data);
};

export const refreshInvoices = async (parent, uuid) => {
  // Parent can be either users or orgs
  const url = `admin/${parent}/${uuid}/xero-invoices/refresh/`;
  return await authRequest.post(url);
};

export const postClearInvoices = async (parent, uuid) => {
  // Parent can be either users or orgs
  const url = `admin/${parent}/${uuid}/xero-invoices/clear-all/`;
  return await authRequest.post(url);
};

export const getPrepayments = async (orgUuid, config) => {
  const url = `admin/orgs/${orgUuid}/xero-prepayments/`;
  return await authRequest.get(url, config);
};

export const refreshPrepayments = async (orgUuid) => {
  const url = `admin/orgs/${orgUuid}/xero-prepayments/refresh/`;
  return await authRequest.post(url);
};

export const getAllPrepayments = async (config) => {
  const url = `admin/xero-prepayments/`;
  return await authRequest.get(url, config);
};

export const refreshAllPrepayments = async () => {
  const url = `admin/xero-prepayments/refresh/`;
  return await authRequest.post(url);
};

export const getQuotes = async (orgUuid, data) => {
  const url = `admin/orgs/${orgUuid}/xero-quotes/`;
  return await authRequest.get(url, data);
};

export const refreshQuotes = async (orgUuid) => {
  // Parent can be either users or orgs
  const url = `admin/orgs/${orgUuid}/xero-quotes/refresh/`;
  return await authRequest.post(url);
};

export const postClearQuotes = async (orgUuid) => {
  // Parent can be either users or orgs
  const url = `admin/orgs/${orgUuid}/xero-quotes/clear-all/`;
  return await authRequest.post(url);
};

export const postClearPrepayments = async (orgUuid) => {
  // Parent can be either users or orgs
  const url = `admin/orgs/${orgUuid}/xero-prepayments/clear-all/`;
  return await authRequest.post(url);
};

export const postClearCreditNotes = async (orgUuid) => {
  // Parent can be either users or orgs
  const url = `admin/orgs/${orgUuid}/xero-creditnotes/clear-all/`;
  return await authRequest.post(url);
};

export const getBills = async (data) => {
  const url = `admin/xero-bills/`;
  return await authRequest.get(url, data);
};

export const createBill = async (data) => {
  const url = `admin/xero-bills/`;
  return await authRequest.post(url, data);
};

export const getTransferwiseCsv = (data) => {
  // We meed the full url because we're not using axios, instead passing the link directlt
  const url = `${process.env.API_ENDPOINT}/admin/xero-bills/generate-csv/`;
  const a = document.createElement('a');
  a.href = url;
  a.click();
};

export const getBill = async (id) => {
  const url = `admin/xero-bills/${id}/`;
  return await authRequest.get(url);
};

export const saveBill = async (id, data) => {
  const url = `admin/xero-bills/${id}/`;
  return await authRequest.put(url, data);
};

export const getTaxRates = async () => {
  const url = `admin/xero-billing-taxrates/`;
  return await authRequest.get(url);
};

export const getBillingAccounts = async () => {
  const url = `admin/xero-billing-accounts/`;
  return await authRequest.get(url);
};

export const getTransferwiseTransfers = async (userUuid, data) => {
  const url = `admin/users/${userUuid}/transferwise-transfers`;
  return await authRequest.get(url, data);
};
