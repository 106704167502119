/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@hiredigital/ui/Card';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import { getUserPlatform } from '@apis/users';

const UserPlatform = ({ userUuid }) => {
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getUserPlatform(userUuid)
      .then((response) => {
        setData(response.data);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error(error);
        setIsLoaded(true);
      });
  }, []);

  return (
    <Card>
      <Card.Header title={`Platform`} />
      <Card.Item>
        {isLoaded && (
          <Fragment>
            <InputGroup>
              <InputContainer>
                <TextInput
                  name='platform'
                  label='Platform'
                  disabled
                  defaultValue={data?.platform}
                />
              </InputContainer>
              <InputContainer>
                <TextInput name='type' label='Type' disabled defaultValue={data?.osType} />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <TextInput name='release' label='Release' disabled defaultValue={data?.release} />
              </InputContainer>
              <InputContainer>
                <TextInput name='version' label='Version' disabled defaultValue={data?.version} />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <TextInput
                  name='architecture'
                  label='Architecture'
                  disabled
                  defaultValue={data?.architecture}
                />
              </InputContainer>
              <InputContainer>
                <TextInput name='device' label='Device' disabled defaultValue={data?.device} />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <TextInput name='ip' label='IP' disabled defaultValue={data?.ip} />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name='appVersion'
                  label='App Version'
                  disabled
                  defaultValue={data?.appVersion}
                />
              </InputContainer>
            </InputGroup>
          </Fragment>
        )}
      </Card.Item>
    </Card>
  );
};

UserPlatform.propTypes = {
  orgUuid: PropTypes.string,
  data: PropTypes.shape({
    contact: PropTypes.object,
    username: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default UserPlatform;
