import PropTypes from 'prop-types';
import classNames from 'classnames';
import { convertNumericToDuration } from '@hiredigital/lib/helpers/date';
import Styles from '../list.module.scss';

const Duration = ({ duration = 0.0, isDuration = false }) => {
  return (
    <div className={classNames(Styles.listSectionSmall, Styles.center)}>
      <span>
        {duration
          ? isDuration
            ? (duration / 3600).toFixed(5)
            : convertNumericToDuration(duration)
          : ''}
      </span>
    </div>
  );
};

Duration.propTypes = {
  duration: PropTypes.number,
  isDuration: PropTypes.bool,
};

export default Duration;
