import { Component } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import '@styles/global.scss';

import Styles from './Logout.module.scss';
import Layout from '@components/Layout/Layout';

import Logo from '@hiredigital/ui/Logo';

import PropTypes from 'prop-types';
const cookies = new Cookies();

const COOKIE_DOMAIN = {
  domain: process.env.cookieDomain ? process.env.cookieDomain : '',
  path: '/',
};

class LogoutPage extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    cookies.remove('admin_token', COOKIE_DOMAIN);
    setTimeout(this.logout, 2500);
  }

  logout = () => {
    window.location.href = '/login';
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <div className={Styles.container}>
          <Link className={Styles.logoContainer} to='/'>
            <Logo className={Styles.logo} type={Logo.Type.WHITE} site={'1'} />
          </Link>
          <div className={Styles.statusContainer}>
            <p className={Styles.progressText}>Logging you out...</p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LogoutPage;
