import { request } from './utils';
import { authRequest } from './utils';

export const postRates = async (
  data,
  primartResource,
  resource,
  resouceUuid,
  config = undefined
) => {
  const url = `admin/${primartResource}/${resource}/${resouceUuid}/`;
  return await authRequest.post(url, data);
};

export const putRates = async (uuid, data, config = undefined) => {
  const url = `engagement-rates/${uuid}/`;
  return await authRequest.put(url, data);
};

export const postCalculateRate = async (data, config = undefined) => {
  const url = `calculate-rate/`;
  return await request.post(url, data);
};
