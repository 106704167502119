import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withParameters from '@hoc/withParameters';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import AppLayout from '@components/Layout/AppLayout';
import ListImage from '@components/ListImage';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { getUsers } from '@apis/users';
import { useUser } from '@context/user';

import { Role, UserStatus } from '@hiredigital/lib/helpers/enum';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

import Styles from '@styles/PageList.module.scss';

const ProfileList = ({ location, initialProps, ...props }) => {
  const currentUser = useUser();

  const [search, setSearch] = useState(initialProps.search);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'first_name',
        size: 'medium',
        canSort: true,
        truncate: false,
        accessor: (u) => u.name,
        Cell: ({ row: { original: user } }) => {
          return (
            <Link className={Styles.profile} to={`/finance-talents/${user?.uuid}`}>
              <ListImage user={user} />
              <MultiLineEllipsis
                data-test-id='finance-table-item'
                className={classNames(Styles.name, !user?.name && Styles.empty)}>
                {user?.name || 'No Name'}
              </MultiLineEllipsis>
            </Link>
          );
        },
      },
      {
        Header: 'Email',
        id: 'email',
        size: 'medium',
        canSort: true,
        accessor: (u) => u.email,
      },
      {
        Header: 'Status',
        id: 'profile__status',
        size: 'small',
        canSort: true,
        accessor: (u) => u.profile && UserStatus.getLabel(u.profile?.status),
      },
      {
        Header: 'Last Active',
        id: 'date_joined',
        size: 'small',
        canSort: true,
        accessor: (u) => toReadableDate(u.lastLogin),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Talents');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 1000) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      roles={[Role.FINANCE.id, Role.ADMIN.id]}
      permissions={currentUser.adminPermission?.finance}
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Talents`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            defaultValue={search}
            placeholder='Search'
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getUsers}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        filters={{
          role: Role.TALENT.id,
          status: [
            UserStatus.ONBOARDING.id,
            UserStatus.ACTIVE.id,
            UserStatus.NETWORK_ACTIVE.id,
            UserStatus.LEGACY_ACTIVE.id,
            UserStatus.ADMIN_REMOVED.id,
          ],
        }}
        excludeParams={['role', 'status']}
      />
    </AppLayout>
  );
};

ProfileList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(ProfileList);
