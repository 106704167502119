import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import Select from 'react-select';

import Button from '@hiredigital/ui/Button';

import AsyncPaginate from 'react-select-async-paginate';
import AppLayout from '@components/Layout/AppLayout';
import withParameters from '@hoc/withParameters';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { getProjects, postProject } from '@apis/projects';

import { ProjectStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import withEmailLabel from '@hoc/withEmailLabel';
import { onOrgLoadOptions, onAssignmentUsersLoadOptions } from '@apis/dropdown';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import Styles from '@styles/PageList.module.scss';

import withParamsResolver from '@hoc/withParamsResolver';

const UserAsyncPaginate = withEmailLabel(AsyncPaginate);

const UserSelect = withParamsResolver(UserAsyncPaginate, 'admin/users');
const OrgSelect = withParamsResolver(UserAsyncPaginate, 'admin/orgs');

const ProjectList = ({ location, history, initialProps, ...props }) => {
  const [user, setUser] = useState(initialProps?.user);
  const [organization, setOrganization] = useState(initialProps?.organization);
  const [isAdding, setIsAdding] = useState(false);

  const [search, setSearch] = useState(initialProps?.search);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        width: 300,
        canSort: true,
        accessor: (u) => u.name,
        Cell: ({ row: { original: project } }) => {
          return (
            <Link className={Styles.title} to={`/projects/${project.uuid}`}>
              {project.title || 'Untitled Project'}
            </Link>
          );
        },
      },

      {
        Header: 'Team',
        id: 'team',
        size: 'small',
        canSort: true,
        truncate: false,
        accessor: (u) => u.name,
        Cell: ({ row: { original: project } }) => {
          return (
            <div style={{ display: 'flex' }}>
              {project?.members.slice(0, 3).map((member, index) => (
                <div key={index} data-tip={member.user.name}>
                  <img
                    className={Styles.picture}
                    style={{ marginRight: '10px' }}
                    src={member.user.picture}
                  />
                  <ReactTooltip place='top' type='dark' effect='solid' />
                </div>
              ))}
              {project?.members.length > 3 && (
                <span>
                  <div className={classNames(Styles.picture, Styles.more)}>
                    +{project?.members.length - 3}
                  </div>
                </span>
              )}
            </div>
          );
        },
      },

      {
        Header: 'Client',
        id: 'organization',
        size: 'small',
        canSort: true,
        accessor: (u) => u?.organization?.name,
      },
      {
        Header: 'Status',
        id: 'status',
        size: 'small',
        canSort: true,
        accessor: (u) => ProjectStatus.getLabel(u.status),
      },
      {
        Header: 'Date',
        id: 'created',
        size: 'small',
        canSort: true,
        accessor: (u) => toReadableDate(u.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Projects');
  }, []);

  const handleUserFilterChange = (u) => setUser(u?.uuid);

  const handleOrgFilterChange = (o) => setOrganization(o?.uuid);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const filterMemo = useMemo(
    () => ({
      user,
      organization,
    }),
    [user, organization]
  );

  const handleCreateNewProject = () => {
    setIsAdding(true);
    const data = { status: ProjectStatus.DRAFT.id };
    postProject(data)
      .then(({ data }) => {
        setIsAdding(false);
        history.push('/projects/' + data.uuid);
      })
      .catch((error) => {
        setIsAdding(false);
      });
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Projects`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />

          <UserSelect
            id='userID'
            className={Styles.headerSelect}
            selectValue={user}
            defaultValue={user}
            name='user'
            placeholder='User'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={handleUserFilterChange}
            isPaginated
            loadOptions={onAssignmentUsersLoadOptions}
            debounceTimeout={500}
            isClearable
            additional={{ page: 1 }}
            SelectComponent={Select}
            createOptionPosition={'first'}
          />

          <OrgSelect
            id='organizationID'
            className={Styles.headerSelect}
            selectValue={organization}
            defaultValue={organization}
            name='organization'
            placeholder='Client'
            getOptionLabel={({ name }) => name}
            getOptionValue={({ uuid }) => uuid}
            onChange={handleOrgFilterChange}
            isPaginated
            loadOptions={onOrgLoadOptions}
            debounceTimeout={500}
            isClearable
            additional={{ page: 1 }}
            SelectComponent={Select}
            createOptionPosition={'first'}
          />

          <Button
            className={Styles.addButton}
            type={Button.Type.BLUE}
            onClick={handleCreateNewProject}
            isLoading={isAdding}>
            {`New Project`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getProjects}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        filters={filterMemo}
      />
    </AppLayout>
  );
};

ProjectList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(ProjectList);
