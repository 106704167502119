import Select from 'react-select';
import { connectRefinementList } from 'react-instantsearch-dom';

const RefinementSelect = ({
  label,
  className,
  currentRefinement,
  items,
  refine,
  searchForItems,
}) => {
  const selected = items.filter(({ label: v }) => currentRefinement?.includes(v));
  return (
    <Select
      className={className}
      name={label}
      placeholder={label}
      value={selected}
      onInputChange={searchForItems}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value}
      onChange={(values) => refine(values.map(({ label }) => label))}
      options={items}
      isClearable
      isPaginated
      isMulti
    />
  );
};

export default connectRefinementList(RefinementSelect);
