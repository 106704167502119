import { useState } from 'react';
import PropTypes from 'prop-types';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import { putTeamInvitation, postTeamInvitation } from '@apis/teams';
import { MemberInvitationStatus } from '@hiredigital/lib/helpers/enum';
import { validateEmail } from '@helpers/forms';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';

import Styles from './Members.module.scss';

const MemberInvitationItem = ({
  team,
  invitation: isInitialInvitation,
  isEdit: isInitialEdit,
  deleteInvitation,
  onCancel,
  onCreate,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(isInitialEdit || false);
  const [invitation, setInvitation] = useState(isInitialInvitation || {});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailValid, setEmailValid] = useState(null);

  const checkEmail = (email) => {
    if (submitted) {
      if (!email) {
        return 'Your must include an e-mail address.';
      } else if (!emailValid) {
        return 'Enter a valid Email';
      }
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInvitation({ ...invitation, [name]: value });
  };

  const handleCancel = () => {
    setInvitation(isInitialInvitation || {});
    setIsEdit(false);
    if (!invitation.id) {
      deleteInvitation?.(invitation);
    }
    onCancel?.();
  };

  const updateInvitation = (invitation, status) => {
    const data = { status: status.id };
    putTeamInvitation(team.uuid, invitation.id, data).then((response) => {
      setInvitation(response.data);
    });
  };

  const submitInvitation = () => {
    setSubmitted(true);
    setErrors({});

    const { email, name, role } = invitation;
    const isEmailValid = validateEmail(email);
    if (email) {
      setEmailValid(isEmailValid);
    }
    if (email && isEmailValid) {
      setLoading(true);
      const data = {
        email,
        name,
        role,
      };

      postTeamInvitation(team.uuid, data).then(
        (response) => {
          setLoading(false);
          setIsEdit(false);
          setSubmitted(false);
          onCreate?.(response.data);
        },
        ({ response: { data } }) => {
          // Error
          Object.keys(data).forEach((key) => {
            if (data[key] && !!data[key].length) {
              setErrors({ [key]: data[key][0] });
            }
          });
          setLoading(false);
        }
      );
    }
  };

  return (
    <Card.Item>
      {isEdit && (
        <div className={Styles.form}>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='email'
                label='Email'
                error={checkEmail(invitation.email) || errors.email || ''}
                value={invitation.email}
                onChange={handleChange}
              />
            </InputContainer>

            <InputContainer>
              <TextInput name='name' label='Name' value={invitation.name} onChange={handleChange} />
            </InputContainer>

            <InputContainer>
              <TextInput name='role' label='Role' value={invitation.role} onChange={handleChange} />
            </InputContainer>
          </InputGroup>

          <div className={Styles.row}>
            <div className={Styles.action}>
              <Button name='cancel' type={Button.Type.WHITE_BLUE_OUTLINE} onClick={handleCancel}>
                {`Cancel`}
              </Button>
              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={submitInvitation}
                isLoading={loading}>
                {`Send Invitation`}
              </Button>
            </div>
          </div>
        </div>
      )}

      {!isEdit && (
        <div className={Styles.row}>
          <img className={Styles.picture} src={invitation?.picture} alt={invitation?.name} />
          <div style={{ overflow: 'hidden' }}>
            <p className={Styles.name}>{`${invitation?.name || ''} (${invitation?.email})`}</p>
            {(invitation?.role || '') && <p className={Styles.info}>{invitation?.role}</p>}
          </div>

          <div className={Styles.action}>
            <p className={Styles.status}>
              {MemberInvitationStatus.getEnum(invitation?.status)?.label}
            </p>
            {/* <Dropdown size='sm' drop='down' alignRight>
              <Dropdown.Toggle name='Invite ellipsis' className={Styles.moreInvitationDropdown}>
                <IconMoreVertical className={Styles.moreIcon} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {invitation?.status !== MemberInvitationStatus.CANCELLED.id && (
                  <Dropdown.Item
                    onClick={() => updateInvitation(invitation, MemberInvitationStatus.CANCELLED)}>
                    {`Cancel Invite`}
                  </Dropdown.Item>
                )}

                <Dropdown.Item
                  onClick={() => updateInvitation(invitation, MemberInvitationStatus.RESEND)}>
                  {`Resend Invitation`}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => deleteInvitation(invitation)}>
                  {`Delete Invitation`}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <SplitButton
              more={[
                {
                  ...(invitation?.status !== MemberInvitationStatus.CANCELLED.id && {
                    text: 'Cancel Invite',
                    onClick: () => updateInvitation(invitation, MemberInvitationStatus.CANCELLED),
                  }),
                },
                {
                  text: 'Resend Invitation',
                  onClick: () => updateInvitation(invitation, MemberInvitationStatus.RESEND),
                },
                {
                  text: 'Delete Invitation',
                  onClick: () => deleteInvitation(invitation),
                },
              ]}
            />
          </div>
        </div>
      )}
    </Card.Item>
  );
};

MemberInvitationItem.propTypes = {
  team: PropTypes.object,
  invitation: PropTypes.object,
};

export default MemberInvitationItem;
