import { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Select from '@hiredigital/ui/Input/Select';

import { onUsersLoadOptions } from '@apis/dropdown';
import withEmailLabel from '@hoc/withEmailLabel';
import { putOrgTalent } from '@apis/orgs';
import Styles from './Styles.module.scss';

const UserSelect = withEmailLabel(Select);

class Item extends Component {
  static propTypes = {
    talent: PropTypes.object,
    orgUuid: PropTypes.string,
    onDeleteTalent: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.talent,
      isEdit: false,
    };
  }

  handleEdit = () => {
    this.setState({ isEdit: true });
  };

  handleCancel = () => {
    this.setState({
      ...this.props.talent,
      isEdit: false,
    });
  };

  handleChange = (event, immediateSave) => {
    const { name, value, type, checked } = event.target;
    this.setState(
      {
        [name]: type === 'checkbox' ? checked : value,
      },
      () => {
        if (immediateSave) {
          this.handleSubmit();
        }
      }
    );
  };

  handleSubmit = () => {
    const {
      user: { uuid },
      isManager,
      isActive,
    } = this.state;

    const data = {
      user: uuid,
      isManager,
      isActive,
    };

    this.setState({ submitted: true });
    if (uuid) {
      putOrgTalent(this.props.orgUuid, this.state.uuid, data).then(
        (response) => {
          this.setState({ isEdit: false });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    }
  };

  render() {
    return (
      <Card.Item style={{ padding: '10px 20px' }}>
        {this.state.isEdit && (
          <div className={Styles.form}>
            <InputGroup style={{ marginBottom: '15px' }}>
              <InputContainer style={{ marginBottom: '0' }}>
                <UserSelect
                  className={Styles.select}
                  value={this.state.user}
                  classNamePrefix='s-contact'
                  name='user'
                  label='User'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ uuid }) => uuid}
                  onChange={this.handleChange}
                  isPaginated
                  loadOptions={onUsersLoadOptions}
                />
              </InputContainer>
            </InputGroup>

            <div className={Styles.row}>
              <Button
                name='delete'
                type={Button.Type.WHITE}
                onClick={() => this.props.onDeleteTalent(this.state.uuid)}>
                {`Delete`}
              </Button>

              <div className={Styles.action}>
                <Button
                  name='cancel'
                  type={Button.Type.WHITE_BLUE_OUTLINE}
                  onClick={this.handleCancel}>
                  {`Cancel`}
                </Button>

                <Button name='submit' type={Button.Type.BLUE} onClick={this.handleSubmit}>
                  {`Save`}
                </Button>
              </div>
            </div>
          </div>
        )}

        {!this.state.isEdit && (
          <div className={Styles.row}>
            <img className={Styles.picture} src={this.state.user.picture} alt='' />
            <div style={{ overflow: 'hidden' }}>
              <p className={Styles.name}>{this.state.user.name}</p>
            </div>

            <div className={Styles.action}>
              <div className={Styles.toggleManager}>
                <Toggle
                  id='is-active'
                  name='isActive'
                  defaultChecked={this.state.isActive}
                  onChange={(e) => this.handleChange(e, true)}
                />
                <label htmlFor='is-active' className={Styles.toggleLabel}>
                  {`Active`}
                </label>
              </div>

              <div className={Styles.toggleManager}>
                <Toggle
                  id='is-manager'
                  name='isManager'
                  defaultChecked={this.state.isManager}
                  onChange={(e) => this.handleChange(e, true)}
                />
                <label htmlFor='is-manager' className={Styles.toggleLabel}>
                  {`Manager`}
                </label>
              </div>

              <Button
                name='Edit'
                className={Styles.editButton}
                type={Button.Type.BLUE_OUTLINE}
                onClick={this.handleEdit}>
                {`Edit`}
              </Button>
            </div>
          </div>
        )}
      </Card.Item>
    );
  }
}

export default Item;
