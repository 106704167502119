import { Component } from 'react';
import Styles from './Styles.module.scss';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

import { patchUser } from '@apis/users';
import { countryList } from '@data/countryList';
import PropTypes from 'prop-types';

class PaymentDetails extends Component {
  static propTypes = {
    data: PropTypes.object,
    userUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...props.data,
      isSaving: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isSaving !== this.state.isSaving) {
      return true;
    }

    return false;
  }

  handleChange = (event, valueKey) => {
    const { name, value } = event.target;

    this.setState(
      {
        [name]: valueKey ? value[valueKey] : value,
      },
      () => {
        this.handleAutoSave();
      }
    );
  };
  handlePhoneCountryChange = (value) => {
    this.setState(
      {
        phoneCountry: value,
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  handlePhoneNumberChange = (value) => {
    this.setState(
      {
        phoneNumber: value,
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  handleAutoSave = (timeout = 3000) => {
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.handleSubmit();
    }, timeout);
  };

  handleSubmit = () => {
    const { name, phoneCountry, phoneNumber, city, address, region, country, postalCode } =
      this.state;

    const data = {
      contact: {
        name,
        phoneCountry,
        phoneNumber,
      },
      address: {
        city,
        address,
        region,
        country,
        postalCode,
      },
    };

    this.setState({ isSaving: true });
    patchUser(this.props.userUuid, data).then((response) => {
      this.setState({ isSaving: false });
      if (this.props.onUserUpdate) {
        this.props.onUserUpdate(response.data);
      }
    });
  };

  render() {
    return (
      <Card>
        <Card.Header title={`Payment Details`} />
        <Card.Item data-test-id='payment-details-section'>
          <InputContainer>
            <TextInput
              name='name'
              label='Full Name'
              defaultValue={this.state.name}
              onChange={this.handleChange}
            />
          </InputContainer>
          <InputGroup>
            {/* <div className={Styles.phone}>
              <Select
                data-test-id='phoneCountry'
                className={Styles.select}
                defaultValue={this.state.phoneCountry}
                classNamePrefix='s-contact'
                name='phoneCountry'
                label='Code'
                getOptionLabel={({ dialCode }) => dialCode}
                getOptionValue={({ id }) => id}
                options={countryList}
                onChange={this.handleChange}
              />
            </div> */}
            <InputContainer>
              <PhoneNumber
                phoneCountry={this.state.phoneCountry}
                phoneNumber={this.state.phoneNumber}
                onPhoneCountryChange={this.handlePhoneCountryChange}
                onPhoneNumberChange={this.handlePhoneNumberChange}
                getCountries={async () => ({ data: countryList })}
                shouldValidate={false}
              />
              {/* <TextInput
                name='phoneNumber'
                label='Phone Number'
                defaultValue={this.state.phoneNumber}
                onChange={this.handleChange}
              /> */}
            </InputContainer>
          </InputGroup>
          <InputContainer>
            <TextArea
              name='address'
              label='Address'
              placeholder='Address'
              defaultValue={this.state.address}
              onChange={this.handleChange}
            />
          </InputContainer>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='postalCode'
                label='Postal Code'
                defaultValue={this.state.postalCode}
                onChange={this.handleChange}
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name='city'
                label='City'
                defaultValue={this.state.city}
                onChange={this.handleChange}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='region'
                label='State/Region'
                defaultValue={this.state.region}
                onChange={this.handleChange}
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                name='country'
                label='Country'
                defaultValue={this.state.country}
                onChange={this.handleChange}
              />
            </InputContainer>
          </InputGroup>
        </Card.Item>
        <Card.Footer right>
          <Button
            onClick={() => this.handleAutoSave(0)}
            name='submit'
            type={Button.Type.BLUE}
            isLoading={this.state.isSaving}>
            {`Save Details`}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default PaymentDetails;
