import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@hiredigital/ui/Button';

import { createTeam } from '@apis/teams';

import { TeamStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import withParameters from '@hoc/withParameters';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import AppLayout from '@components/Layout/AppLayout';
import { getTeams } from '@apis/teams';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import MultilineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

import Styles from '@styles/PageList.module.scss';

const TalentList = ({ location, history, initialProps, ...props }) => {
  const [search, setSearch] = useState(initialProps?.search);
  const [loading, setLoading] = useState(false);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        size: 'medium',
        canSort: true,
        truncate: false,
        accessor: (u) => u.name,
        Cell: ({ row: { original: team } }) => {
          return (
            <Link className={Styles.profile} to={`/teams/${team.uuid}`}>
              {team.picture ? (
                <img className={Styles.picture} src={team.picture} alt={team.name} />
              ) : (
                <div className={classNames(Styles.picture, Styles.noPicture)}></div>
              )}
              <div style={{ overflow: 'hidden' }}>
                <MultilineEllipsis data-test-id='team-table-item' className={Styles.name}>
                  {team.name}
                </MultilineEllipsis>
              </div>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        id: 'status',
        size: 'small',
        canSort: true,
        accessor: (u) => TeamStatus.getLabel(u.status),
      },
      {
        Header: 'Date Joined',
        id: 'created',
        size: 'small',
        canSort: true,
        accessor: (u) => toReadableDate(u.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Team Review');
  }, []);

  const handleCreateTeam = () => {
    setLoading(true);
    createTeam({ name: '' }).then((response) => {
      setLoading(false);
      history.push('/teams/' + response.data.uuid);
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Team Review`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />

          <Button
            className={Styles.addButton}
            type={Button.Type.BLUE}
            onClick={handleCreateTeam}
            isLoading={loading}>
            {`Add New Team`}
          </Button>
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getTeams}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
      />
    </AppLayout>
  );
};
TalentList.propTypes = {
  location: PropTypes.object,
  store: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withParameters(TalentList);
