import { useState } from 'react';
import { usePagination } from '@hiredigital/lib/hooks/usePagination';
import classNames from 'classnames';
import BaseSelect from 'react-select';
import Styles from './Paginator.module.scss';

const PAGE_SIZE = [5, 25, 50, 100];

const Pagination = ({
  onPageChange,
  onPerPageChange,
  total,
  defaultCurrentPage = 1,
  defaultPerPage = PAGE_SIZE[1],
  nextText = 'Next',
  previousText = 'Back',
  className,
}) => {
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(defaultPerPage);

  const range = usePagination({
    currentPage,
    total,
    perPage,
  });

  if (!currentPage || !range) {
    return null;
  }

  const changePage = (page) => {
    setCurrentPage(page);
    onPageChange?.(page);
  };

  const lastPage = range[range.length - 1];
  const start = currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1;
  const end = currentPage * perPage;

  return (
    <div className={classNames(Styles.container, className)}>
      <ul className={Styles.list}>
        <li
          onClick={() => changePage(currentPage - 1)}
          className={classNames(
            Styles.item,
            Styles.large,
            (currentPage === 1 || !total) && Styles.disabled
          )}>
          <div>{previousText}</div>
        </li>
        {range.map((page, idx) => {
          return isNaN(page) ? (
            <li key={idx} className={classNames(Styles.item, Styles.disabled)}>
              {page}
            </li>
          ) : (
            <li
              key={idx}
              onClick={() => changePage(page)}
              className={classNames(Styles.item, page === currentPage && Styles.active)}>
              {page}
            </li>
          );
        })}
        <li
          onClick={() => changePage(currentPage + 1)}
          className={classNames(
            Styles.item,
            Styles.large,
            (currentPage === lastPage || !total) && Styles.disabled
          )}>
          <div>{nextText}</div>
        </li>
      </ul>
      {total > 0 && (
        <span className={Styles.description}>{`${start} - ${
          end > total ? total : end
        } of ${total} result${total > 1 ? 's' : ''}`}</span>
      )}
      <div className={Styles.goto}>
        <span className={Styles.shown}>{'Go to page: '}</span>
        <BaseSelect
          className={Styles.select}
          menuPlacement='top'
          placeholder=''
          value={{ label: currentPage }}
          options={Array.from({ length: lastPage }).map((_, i) => ({ label: i + 1 }))}
          getOptionValue={({ label }) => label}
          onChange={({ label: v }) => changePage(v)}
          disabled={!total}
        />
      </div>
      <div className={Styles.segment}>
        <span className={Styles.shown}>{'Show: '}</span>
        <BaseSelect
          className={Styles.select}
          menuPlacement='top'
          placeholder=''
          defaultValue={{ label: PAGE_SIZE[1] }}
          options={PAGE_SIZE.map((label) => ({ label }))}
          getOptionValue={({ label }) => label}
          onChange={({ label: v }) => {
            setPerPage(v);
            setCurrentPage(1);
            onPerPageChange?.(v);
          }}
          disabled={!total}
        />
      </div>
    </div>
  );
};

Pagination.PageSize = PAGE_SIZE;

export default Pagination;
