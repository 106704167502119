import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import qs from 'qs';
import toast from 'react-hot-toast';

import {
  getOrgUsers,
  getOrg,
  getOrgInvitations,
  putOrg,
  postOrgFile,
  postOrgLink,
  deleteOrgFile,
  deleteOrgLink,
} from '@apis/orgs';
import { onHubspotCompaniesLoadOptions } from '@apis/dropdown';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // for ES6 modules
import { languageList } from '@data/languageList';
import { countryList } from '@data/countryList';
import { topicList } from '@data/topicList';
import Quill from '@hiredigital/ui/Quill/Editor';
import References from '@hiredigital/ui/Form/References';
import ImageDrop from '@components/Form/File/ImageDrop';
import AppLayout from '@components/Layout/AppLayout';

import Card from '@hiredigital/ui/Card';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import { validateUrl } from '@helpers/forms';
import { isAdmin, isFinance, isAccountManager, isClientAdmin } from '@helpers/permissions';
import {
  OrganizationStatus,
  VisibilityOption,
  MemberInvitationStatus,
  StripeCurrency,
  OrganizationType,
} from '@hiredigital/lib/helpers/enum';
import {
  setPageTitle,
  addHttpPrefix,
  socialPrefixes,
  copyTextToClipboard,
  generateAddress,
} from '@hiredigital/lib/helpers/utils';
import { withUser } from '@context/user';
import TalentList from '@components/OrgTalents/List';
import MemberList from '@components/OrgMembers/List';
import InvoiceList from '@components/Invoices/List';
import QuoteList from '@components/Quotes/List';
import PrepaymentList from '@components/Prepayments/List';
import StripePaymentLinks from '@components/StripePaymentLinks/StripePaymentLinks';
// import PendingPrepaymentList from '@components/PendingPrepayments/List';
import CreditNoteList from '@components/CreditNotes/List';
import Address from '@components/Address/Address';
import Contact from '@components/OrgContact/Contact';
import AgreementList from '@components/Agreements/List';
import PaymentSettings from '@components/PaymentSettings/PaymentSettings';
import CallPreference from '@components/CallPreference/CallPreference';
import OrgAssignmentList from '@components/OrgAssignment/List';
import OrgBriefList from '@components/OrgBrief/List';

class OrganizationDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    currentUser: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      orgUuid: this.props.match.params.orgUuid,
      name: '',
      picture: '',
      description: '',
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        type: null,
      },
      social: {
        website: '',
        linkedin: '',
        twitter: '',
        facebook: '',
        instagram: '',
      },
      city: '',
      status: null,
      adminSettings: {
        projectReview: false,
        depositRequired: false,
        depositePaid: false,
        depositAmount: null,
        agreementSigned: false,
        agreementVerified: false,
        agreementRequired: false,
        viewEditor: false,
        viewTimesheets: false,
      },
      address: {
        city: '',
      },
      members: [],
      invitations: [],
      // websiteValid: true,
      hasInitLoad: false,
      submitted: false,
      currentTab: '',
      hubspotCompany: null,
      errors: {},
    };
  }

  componentDidMount = () => {
    const hash = this.props.location && this.props.location.hash;
    this.setState({ currentTab: (hash && hash.substr(1)) || 'profile' });

    this.loadOrganizations().then(
      ([
        { data: profile },
        {
          data: { results: members },
        },
        {
          data: { results: invitations },
        },
      ]) => {
        this.populateFields(profile, () => {
          setPageTitle(`Clients | ${profile.name || 'New Organization'}`);
          this.setState({ members, invitations }, () => {
            this.setState({ hasInitLoad: true });
          });
        });
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    // if (nextProps.engagement !== this.props.engagement) {
    //   return true;
    // }

    if (
      nextProps.location.hash !== this.props.location.hash ||
      nextProps.currentUser !== this.props.currentUser
    ) {
      return true;
    }

    if (
      nextState.isSaving !== this.state.isSaving ||
      nextState.hasInitLoad !== this.state.hasInitLoad
    ) {
      return true;
    }

    if (
      // nextState.name !== this.state.name ||
      // nextState.description !== this.state.description ||
      nextState.contact !== this.state.contact ||
      nextState.status !== this.state.status ||
      nextState.visibility !== this.state.visibility ||
      nextState.type !== this.state.type ||
      // nextState.social !== this.state.social ||

      nextState.social.website !== this.state.social.website ||
      nextState.social.linkedin !== this.state.social.linkedin ||
      nextState.social.twitter !== this.state.social.twitter ||
      nextState.social.facebook !== this.state.social.facebook ||
      nextState.social.instagram !== this.state.social.instagram ||
      // nextState.adminSettings !== this.state.adminSettings ||
      nextState.adminSettings.depositAmount !== this.state.adminSettings.depositAmount ||
      nextState.address !== this.state.address ||
      nextState.orgUuid !== this.state.orgUuid ||
      nextState.countries !== this.state.countries ||
      nextState.languages !== this.state.languages ||
      // nextState.confidentialDescription !== this.state.confidentialDescription ||
      // nextState.confidentialName !== this.state.confidentialName ||
      nextState.hubspotCompany !== this.state.hubspotCompany ||
      nextState.topics !== this.state.topics ||
      nextState.errors !== this.state.errors
    ) {
      return true;
    }
    return false;
  }

  loadOrganizations = () => {
    return Promise.all([
      this.loadOrganizationProfile(),
      this.loadOrganizationMembers(),
      this.loadOrganizationInvitations(),
    ]);
  };

  loadOrganizationProfile = () => getOrg(this.state.orgUuid);

  loadOrganizationMembers = () => getOrgUsers(this.state.orgUuid);

  loadOrganizationInvitations = () => {
    const params = {
      status: [
        MemberInvitationStatus.CREATED.id,
        MemberInvitationStatus.SENT.id,
        MemberInvitationStatus.CANCELLED.id,
        MemberInvitationStatus.RESEND.id,
        MemberInvitationStatus.REJECTED.id,
      ],
    };
    const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });
    return getOrgInvitations(this.state.orgUuid, { params, paramsSerializer });
  };

  populateFields = (data, completionCallback) => {
    this.setState(
      {
        ...data,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };

  hanldeImageUploadPayloadGenerate = (name, file) => {
    const formData = new FormData();
    formData.append(name, file, file.fileName || file.name);
    formData.append('name', this.state.name);
    return formData;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    // From https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
    this.setState({
      [name]: value,
    });
    // this.setState({ user: { ...this.state.user, [name]: value } });
    this.handleAutoSave();
  };

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.handleAutoSave();
  };

  handleEnumChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value.id,
    });
    this.handleImmediateSave();
  };

  handleSocialChange = (event, prefix, removeBaseUrl = true) => {
    const { name, value } = event.target;
    const link =
      (removeBaseUrl
        ? value && value.replace(prefix, '')
        : value && addHttpPrefix(value, prefix)) || '';
    // This only works with shouldUpdate
    this.setState((state) => {
      return {
        ...state,
        errors: {},
        social: {
          ...state.social,
          [name]: link,
        },
      };
    });

    // this.setState((state) => ((state.social[name] = link), state));
    this.handleAutoSave();
  };

  handleImmediateSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 0);
  };

  handleImmediateNestedSave = (o) => {
    this.setState(o, this.handleImmediateSave);
  };

  handleAutoSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 2000);
  };

  updateContactState = ({ contact }) => {
    this.setState({ contact: { ...this.state.contact, ...contact } });
  };

  validate = () => {
    const { social } = this.state;
    const errors = {};

    if (social?.website && !validateUrl(social.website)) {
      errors.website = 'Enter a valid URL';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  handleSubmit = (storeResponse = true) => {
    this.setState({ errors: {} });
    const {
      name,
      description,
      contact,
      status,
      visibility,
      type,
      social,
      adminSettings,
      address,
      orgUuid,
      countries,
      languages,
      topics,
      confidentialDescription,
      confidentialName,
      hubspotCompany,
      payment,
      guidelines,
    } = this.state;

    // if (social && social.website) {
    //   this.setState({ websiteValid: validateUrl(social.website) });
    // }
    if (contact && contact.user) {
      contact.user = contact.user.uuid;
    }

    if (contact && contact.timezone) {
      contact.timezone = contact.timezone.id;
    }

    if (!this.validate()) {
      return;
    }

    this.setState({ isSaving: true });

    const data = {
      countries: countries?.map((obj) => obj.id),
      languages: languages?.map((obj) => obj.id),
      topics: topics.map((obj) => obj.id),
      name,
      description,
      status: status,
      visibility: visibility,
      type: type,
      contact,
      adminSettings,
      social,
      address,
      confidentialDescription,
      confidentialName,
      payment,
      guidelines,
    };

    if (hubspotCompany) data.hubspotCompany = hubspotCompany.id;

    putOrg(orgUuid, data).then(
      (response) => {
        if (storeResponse) {
          this.populateFields(response);
        }
        this.setState({ isSaving: false });
      },
      (error) => {
        this.setState({ isSaving: false });
      }
    );
  };

  addSocialLink(name, value, prefix) {
    const link = value ? addHttpPrefix(value, prefix) : '';
    this.setState((state) => ((state.social[name] = link), state));
  }

  handleToggleChange = (event) => {
    const { name, checked } = event.target;
    this.setState((state) => ((state.adminSettings[name] = checked), state));
    this.handleImmediateSave();
  };
  generateAgreement = (e, pf) => {
    copyTextToClipboard(
      pf +
        `&effective_date=${toReadableDate(new Date())}` +
        `&entity_full_name_and_address=${encodeURIComponent(
          `${this.state.name}, ${generateAddress(this.state.address)}`
        )}` +
        `&signatory_name_and_title=${encodeURIComponent(
          `${this.state.contact.user?.name || this.state.contact?.name}, Signatory`
        )}` +
        `&Client_UserName=${encodeURIComponent(
          this.state.contact.user?.name || this.state.contact?.name
        )}` +
        `&Client_Email=${encodeURIComponent(
          this.state.contact.user?.email || this.state.contact?.email
        )}` +
        `&org_uuid=${this.state.orgUuid}`
    );
    toast.success('Copied to clipboard.', { id: 'Copied' });
  };

  handleStripeCurrencyChange = (event) => {
    const { value } = event.target;
    this.setState(
      {
        payment: { ...this.state.payment, stripeCurrency: value.id },
      },
      () => {
        this.handleAutoSave();
      }
    );
  };

  handleAdminChange = (event) => {
    const { name, value } = event.target;
    // Only this works with nested
    this.setState((state) => {
      return {
        ...state,
        adminSettings: {
          ...state.adminSettings,
          [name]: value,
        },
      };
    });

    // this.setState((state) => ((state.adminSettings[name] = value), state));
    this.handleAutoSave();
  };

  navigateTabLocation = (tab) => {
    this.setState({ currentTab: tab }, () => {
      const { history, location } = this.props;
      history.push(`${location.pathname}#${tab}`);
    });
  };

  render() {
    // const validateWebsite = () => {
    //   if (this.state.social && this.state.social.website && !this.state.websiteValid) {
    //     return 'Enter a valid URL';
    //   }
    // };

    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/clients'>
              {`Clients`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>{this.state.name}</div>
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <div className={classNames(PageStyles.adminContainer)}>
              <div className={classNames(Styles.row, Styles.topItem)}>
                <h3 className={PageStyles.headline}>{`Admin Settings`}</h3>

                <Button
                  name='submit'
                  style={{ marginBottom: '10px' }}
                  className={Styles.saveButton}
                  type={Button.Type.BLUE}
                  onClick={this.handleImmediateSave}
                  isLoading={this.state.isSaving}>
                  {`Save Profile`}
                </Button>
              </div>
              {this.state.adminSettings && (
                <div>
                  <InputGroup>
                    <InputContainer>
                      <Select
                        className={PageStyles.select}
                        value={OrganizationStatus.getEnum(this.state.status)}
                        classNamePrefix='s-contact'
                        name='status'
                        label='Status'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={OrganizationStatus.values}
                        onChange={this.handleEnumChange}
                      />
                    </InputContainer>

                    <InputContainer>
                      <Select
                        className={PageStyles.select}
                        value={VisibilityOption.getEnum(this.state.visibility)}
                        classNamePrefix='s-contact'
                        name='visibility'
                        label='Default Engagement Visibility'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={VisibilityOption.values}
                        onChange={this.handleEnumChange}
                      />
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Select
                        className={PageStyles.select}
                        value={OrganizationType.getEnum(this.state.type)}
                        classNamePrefix='s-contact'
                        name='type'
                        label='Type'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={OrganizationType.values}
                        onChange={this.handleEnumChange}
                      />
                    </InputContainer>

                    <InputContainer>
                      <Select
                        className={PageStyles.select}
                        value={this.state.hubspotCompany}
                        classNamePrefix='s-contact'
                        name='hubspotCompany'
                        label='Hubspot Company'
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        onChange={this.handleChange}
                        isPaginated
                        loadOptions={onHubspotCompaniesLoadOptions}
                      />
                    </InputContainer>
                  </InputGroup>
                </div>
              )}
            </div>

            <Tab.Container
              defaultActiveKey='profile'
              activeKey={this.state.currentTab}
              mountOnEnter={true}>
              <Nav variant='tabs' className={classNames(Styles.row, PageStyles.headlineRow)}>
                <Nav.Item>
                  <Nav.Link
                    eventKey='profile'
                    onClick={() => {
                      this.navigateTabLocation('profile');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Profile`}</h3>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='contact'
                    onClick={() => {
                      this.navigateTabLocation('contact');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Contact`}</h3>{' '}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='settings'
                    onClick={() => {
                      this.navigateTabLocation('settings');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Settings`}</h3>{' '}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='members'
                    onClick={() => {
                      this.navigateTabLocation('members');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Team`}</h3>{' '}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey='billing'
                    onClick={() => {
                      this.navigateTabLocation('billing');
                    }}>
                    <h3 className={classNames(PageStyles.headline)}>{`Billing`}</h3>{' '}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className={PageStyles.tabContent}>
                <Tab.Pane eventKey='profile'>
                  <div>
                    <Card>
                      <Card.Header>{`Profile`}</Card.Header>
                      <Card.Item>
                        <div className={Styles.row}>
                          <ImageDrop
                            className={Styles.photo}
                            name='picture'
                            label='Picture'
                            server={`admin/orgs/${this.state.orgUuid}/`}
                            onUploadRequestPayloadGenerate={(name, file) => {
                              return this.hanldeImageUploadPayloadGenerate(name, file);
                            }}
                            idleLabel='Update Picture'
                            value={this.state.picture}
                            onResponse={({ data }) => {
                              this.populateFields(data);
                            }}
                          />
                          <div style={{ flexGrow: 1 }}>
                            <InputGroup>
                              <InputContainer>
                                <TextInput
                                  name='name'
                                  label='Name'
                                  defaultValue={this.state.name}
                                  onChange={this.handleChange}
                                />
                              </InputContainer>
                              {this.state.social && (
                                <InputContainer>
                                  <TextInput
                                    name='website'
                                    label='Website'
                                    error={this.state.errors?.website}
                                    defaultValue={this.state.social.website}
                                    onChange={(e) => this.handleSocialChange(e, 'http://', false)}
                                  />
                                </InputContainer>
                              )}
                            </InputGroup>

                            <InputContainer>
                              <Quill
                                className={Styles.quillInput}
                                name='description'
                                label='Description'
                                placeholder='Description'
                                defaultValue={this.state.description}
                                onChange={this.handleChange}
                                legacyCompat
                              />
                            </InputContainer>
                          </div>
                        </div>

                        {this.state.social && (
                          <Fragment>
                            <InputGroup>
                              <InputContainer>
                                <TextInput
                                  name='linkedin'
                                  label='Linkedin'
                                  value={addHttpPrefix(
                                    this.state.social.linkedin,
                                    socialPrefixes.linkedin
                                  )}
                                  onChange={(e) =>
                                    this.handleSocialChange(e, socialPrefixes.linkedin)
                                  }
                                />
                              </InputContainer>

                              <InputContainer>
                                <TextInput
                                  name='twitter'
                                  label='Twitter'
                                  value={addHttpPrefix(
                                    this.state.social.twitter,
                                    socialPrefixes.twitter
                                  )}
                                  onChange={(e) =>
                                    this.handleSocialChange(e, socialPrefixes.twitter)
                                  }
                                />
                              </InputContainer>
                            </InputGroup>
                            <InputGroup>
                              <InputContainer>
                                <TextInput
                                  name='facebook'
                                  label='Facebook'
                                  value={addHttpPrefix(
                                    this.state.social.facebook,
                                    socialPrefixes.facebook
                                  )}
                                  onChange={(e) =>
                                    this.handleSocialChange(e, socialPrefixes.facebook)
                                  }
                                />
                              </InputContainer>
                              <InputContainer>
                                <TextInput
                                  name='instagram'
                                  label='Instagram'
                                  value={addHttpPrefix(
                                    this.state.social.instagram,
                                    socialPrefixes.instagram
                                  )}
                                  onChange={(e) =>
                                    this.handleSocialChange(e, socialPrefixes.instagram)
                                  }
                                />
                              </InputContainer>
                            </InputGroup>
                          </Fragment>
                        )}

                        <InputGroup>
                          <InputContainer>
                            <Select
                              data-test-id='countries'
                              className={PageStyles.select}
                              value={this.state.countries}
                              classNamePrefix='s-contact'
                              name='countries'
                              label='Markets'
                              isClearable={false}
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ id }) => id}
                              options={countryList}
                              isMulti
                              onChange={this.handleSelectChange}
                            />
                          </InputContainer>
                          <InputContainer>
                            <Select
                              data-test-id='language'
                              className={PageStyles.select}
                              value={this.state.languages}
                              classNamePrefix='s-contact'
                              name='languages'
                              label='Languages'
                              isClearable={false}
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ id }) => id}
                              options={languageList}
                              isMulti
                              onChange={this.handleSelectChange}
                            />
                          </InputContainer>
                        </InputGroup>

                        <InputGroup>
                          <InputContainer>
                            <Select
                              data-test-id='topics'
                              className={PageStyles.select}
                              value={this.state.topics}
                              classNamePrefix='s-contact'
                              name='topics'
                              label='Verticals'
                              isClearable={false}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={topicList}
                              isMulti
                              onChange={this.handleSelectChange}
                            />
                          </InputContainer>
                        </InputGroup>

                        <div></div>
                      </Card.Item>

                      <Card.Footer right sticky>
                        <Button
                          name='submit'
                          className={Styles.saveButton}
                          type={Button.Type.BLUE}
                          onClick={this.handleImmediateSave}
                          isLoading={this.state.isSaving}>
                          {`Save Profile`}
                        </Button>
                      </Card.Footer>
                    </Card>

                    <Card>
                      <Card.Header>{`Confidential Profile`}</Card.Header>
                      <Card.Item>
                        <InputContainer>
                          <TextInput
                            name='confidentialName'
                            label='Confidential Name'
                            defaultValue={this.state.confidentialName}
                            onChange={this.handleChange}
                          />
                        </InputContainer>

                        <InputContainer>
                          <Quill
                            className={Styles.quillInput}
                            name='confidentialDescription'
                            label='Confidential Description'
                            placeholder='Confidential Description'
                            value={this.state.confidentialDescription}
                            onChange={this.handleChange}
                            legacyCompat
                          />
                        </InputContainer>
                      </Card.Item>
                      <Card.Footer right sticky>
                        <Button
                          name='submit'
                          type={Button.Type.BLUE}
                          onClick={this.handleImmediateSave}
                          isLoading={this.state.isSaving}>
                          {`Save Confidential Profile`}
                        </Button>
                      </Card.Footer>
                    </Card>

                    <Card>
                      <Card.Header>{`Brand Guidelines`}</Card.Header>
                      <Card.Item>
                        <InputContainer>
                          <Quill
                            className={Styles.quillInput}
                            name='guidelines'
                            label='Guidelines'
                            placeholder='Guidelines'
                            value={this.state.guidelines}
                            onChange={this.handleChange}
                            legacyCompat
                          />
                        </InputContainer>
                        <div style={{ width: '100%', margin: '10px 0' }}>
                          <References
                            attachments={this.state?.attachments}
                            links={this.state?.links}
                            postLinkRequest={(payload, config) =>
                              postOrgLink(this.state.orgUuid, payload, config)
                            }
                            postFileRequest={(payload, config) =>
                              postOrgFile(this.state.orgUuid, payload, config)
                            }
                            deleteLinkRequest={(id) => deleteOrgLink(this.state.orgUuid, id)}
                            deleteFileRequest={(id) => deleteOrgFile(this.state.orgUuid, id)}
                            // server={`admin/orgs/${this.state.orgUuid}`}
                          />
                        </div>
                      </Card.Item>
                      <Card.Footer right sticky>
                        <Button
                          name='submit'
                          type={Button.Type.BLUE}
                          onClick={this.handleImmediateSave}
                          isLoading={this.state.isSaving}>
                          {`Save Guidelines`}
                        </Button>
                      </Card.Footer>
                    </Card>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey='contact'>
                  <Contact
                    orgUuid={this.state.orgUuid}
                    data={this.state.contact}
                    onContactChange={this.updateContactState}
                  />
                  <CallPreference orgUuid={this.state.orgUuid} data={this.state.contact} />
                  <Address
                    title='Company Address'
                    address={this.state.address}
                    payment={this.state.payment}
                    onSave={(o) => this.handleImmediateNestedSave(o)}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='settings'>
                  <Card>
                    <Card.Header>
                      <>Project Settings</>
                      <Card.Action>
                        <div className={Styles.agreementToggle}>
                          <Toggle
                            id='view-editor'
                            name='viewEditor'
                            defaultChecked={this.state.adminSettings.viewEditor}
                            onChange={this.handleToggleChange}
                          />

                          <label
                            htmlFor='view-editor'
                            style={{ marginRight: '10px' }}
                            className={Styles.toggleLabel}>{`View Editor`}</label>
                        </div>

                        <div className={Styles.agreementToggle}>
                          <Toggle
                            id='view-timesheets'
                            name='viewTimesheets'
                            defaultChecked={this.state.adminSettings.viewTimesheets}
                            onChange={this.handleToggleChange}
                          />
                          <label
                            htmlFor='view-timesheets'
                            style={{ marginRight: '10px' }}
                            className={Styles.toggleLabel}>{`View Timesheets`}</label>
                        </div>
                      </Card.Action>
                    </Card.Header>
                  </Card>
                  <Card>
                    <Card.Header>
                      <>Agreement Settings</>
                      <Card.Action>
                        <div className={Styles.agreementToggle}>
                          <Toggle
                            id='agreement-required'
                            name='agreementRequired'
                            defaultChecked={this.state.adminSettings.agreementRequired}
                            onChange={this.handleToggleChange}
                          />

                          <label
                            htmlFor='agreement-required'
                            style={{ marginRight: '10px' }}
                            className={Styles.toggleLabel}>
                            {`Required`}
                          </label>
                        </div>

                        <div className={Styles.agreementToggle}>
                          <Toggle
                            id='agreement-signed'
                            name='agreementSigned'
                            defaultChecked={this.state.adminSettings.agreementSigned}
                            onChange={this.handleToggleChange}
                          />
                          <label
                            htmlFor='agreement-signed'
                            className={Styles.toggleLabel}>{`Signed`}</label>
                        </div>

                        <div className={Styles.agreementToggle}>
                          <Toggle
                            id='agreement-verified'
                            name='agreementVerified'
                            defaultChecked={this.state.adminSettings.agreementVerified}
                            onChange={this.handleToggleChange}
                          />
                          <label
                            htmlFor='agreement-verified'
                            className={Styles.toggleLabel}>{`Verified`}</label>
                        </div>
                      </Card.Action>
                    </Card.Header>
                  </Card>

                  <AgreementList
                    note={`Ensure that the client's name, address, and client information are correct before generating the agreement. Company: ${
                      this.state.name
                    } | Address: ${generateAddress(this.state.address)} | Name: ${
                      this.state.contact.user?.name || this.state.contact?.name
                    } | Email: ${this.state.contact.user?.email || this.state.contact?.email}`}
                    action={
                      <>
                        <Button
                          type={Button.Type.BLUE_OUTLINE}
                          onClick={(e) => this.generateAgreement(e, process.env.DOCUSIGN_US_PF)}>
                          Copy US Agreement Link
                        </Button>
                        <Button
                          type={Button.Type.BLUE_OUTLINE}
                          onClick={(e) => this.generateAgreement(e, process.env.DOCUSIGN_SG_PF)}>
                          Copy SG Agreement Link
                        </Button>
                      </>
                    }
                    currentUser={this.props.currentUser}
                    resourceUuId={this.state.orgUuid}></AgreementList>
                </Tab.Pane>
                <Tab.Pane eventKey='members'>
                  {this.state.hasInitLoad && (
                    <Fragment>
                      {(isClientAdmin(this.props.currentUser) ||
                        this.props.currentUser.adminPermission?.orgUser) && (
                        <>
                          <MemberList
                            orgUuid={this.state.orgUuid}
                            members={this.state.members}
                            invitations={this.state.invitations}
                          />

                          <TalentList orgUuid={this.state.orgUuid} />
                          <OrgAssignmentList
                            orgUuid={this.state.orgUuid}
                            currentUser={this.props.currentUser}
                          />
                          <OrgBriefList
                            orgUuid={this.state.orgUuid}
                            currentUser={this.props.currentUser}
                          />
                        </>
                      )}
                    </Fragment>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey='billing'>
                  <StripePaymentLinks />

                  <Card>
                    <Card.Header title='Platform Deposit'></Card.Header>
                    <Card.Item flex>
                      <InputGroup style={{ marginRight: '10px', maxWidth: '200px' }}>
                        <Select
                          data-test-id='stripeCurrency'
                          className={Styles.select}
                          defaultValue={StripeCurrency.getEnum(this.state.payment.stripeCurrency)}
                          classNamePrefix='s-contact'
                          name='stripeCurrency'
                          label='Base Currency'
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={StripeCurrency.values}
                          onChange={this.handleStripeCurrencyChange}
                        />
                      </InputGroup>
                      <InputGroup style={{ marginRight: 'auto', maxWidth: '200px' }}>
                        <TextInput
                          style={{ marginLeft: '10px' }}
                          name='depositAmount'
                          label='Deposit Amount'
                          type='number'
                          step='1'
                          value={this.state.adminSettings.depositAmount}
                          onChange={this.handleAdminChange}
                        />
                      </InputGroup>

                      <div className={Styles.agreementToggle}>
                        <Toggle
                          id='require-deposit'
                          name='depositRequired'
                          defaultChecked={this.state.adminSettings.depositRequired}
                          onChange={this.handleToggleChange}
                        />
                        <label
                          htmlFor='require-deposit'
                          style={{ marginRight: '10px' }}
                          className={Styles.toggleLabel}>
                          {`Deposit Required`}
                        </label>
                      </div>
                      <div className={Styles.agreementToggle}>
                        <Toggle
                          id='paid-deposit'
                          name='depositPaid'
                          defaultChecked={this.state.adminSettings.depositPaid}
                          onChange={this.handleToggleChange}
                        />
                        <label
                          htmlFor='paid-deposit'
                          style={{ marginRight: '10px' }}
                          className={Styles.toggleLabel}>{`Deposit Paid`}</label>
                      </div>
                    </Card.Item>
                  </Card>

                  <PaymentSettings
                    orgUuid={this.state.orgUuid}
                    data={{
                      ...this.state.payment,
                    }}
                  />

                  {this.state.adminSettings.depositRequired &&
                    !this.state.adminSettings.depositPaid && (
                      <Alert variant={'warning'}>
                        Note that the client will not be able to see any prepayments, or credit
                        notes until their deposit has been paid, or the deposit requirement has been
                        removed.{' '}
                        <Alert.Link
                          onClick={() => {
                            this.navigateTabLocation('settings');
                          }}>
                          Click here
                        </Alert.Link>{' '}
                        to edit their settings.
                      </Alert>
                    )}
                  {this.props.currentUser &&
                    (isClientAdmin(this.props.currentUser) ||
                      isAccountManager(this.props.currentUser) ||
                      isFinance(this.props.currentUser) ||
                      this.props.currentUser.adminPermission?.orgCredit ||
                      this.props.currentUser.adminPermission?.finance) && (
                      <>
                        {/* <PendingPrepaymentList orgUuid={this.state.orgUuid} /> */}
                        <PrepaymentList orgUuid={this.state.orgUuid} />
                        <CreditNoteList orgUuid={this.state.orgUuid} />
                        <InvoiceList resourceUuid={this.state.orgUuid} />
                        <QuoteList orgUuid={this.state.orgUuid} />
                      </>
                    )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}
export default withUser(OrganizationDetail);
