import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import { withUser } from '@context/user';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import TextInput from '@hiredigital/ui/Input/TextInput';

import add from 'date-fns/add';

import { putTimeEntries } from '@apis/timesheets';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { isFinance, isAdmin } from '@helpers/permissions';

import { convertToSeconds, convertToHours } from '@hiredigital/lib/helpers/utils';
import { RateType } from '@hiredigital/lib/helpers/enum';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import ListStyles from '@styles/PageList.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';
import Styles from '../list.module.scss';

class EntryListItem extends Component {
  static propTypes = {
    entry: PropTypes.object,
    store: PropTypes.object,
    showTimestamp: PropTypes.bool,
    onEntryUpdate: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.entry,
      isEdit: false,
      durationValid: true,
      errors: {},
    };
  }

  componentDidMount = () => {
    this.setState({
      duration: this.getTime(this.state.duration),
    });
  };

  handleEdit = () => {
    this.setState({ isEdit: true }, () => {
      this.entryBaseState = { ...this.state };
    });
  };

  handleCancel = () => {
    if (this.state.isEdit) {
      this.setState({ ...this.entryBaseState, isEdit: false });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      durationValid: true,
      errors: {},
    });
  };

  handleStartDateChange = (value) => {
    this.setState({ start: value, errors: {} });
  };

  validateDuration = (duration) => {
    let ret = false;
    const stringVal = String(duration) || '';
    const stringArr = stringVal.split(':');

    const hour = parseInt(stringArr[0]) || 0;
    const min = parseInt(stringArr[1]) || 0;
    const sec = parseInt(stringArr[2]) || 0;

    if (
      ((hour || min || sec) && hour < 24 && min <= 59 && sec <= 59) ||
      (hour === 24 && min === 0 && sec === 0)
    ) {
      ret = true;
    }
    return ret;
  };

  validateRequiredFields = () => {
    let errors = {};
    const { start, description } = this.state;

    if (!start) {
      errors = { ...errors, start: 'Start Date is required!' };
    }

    if (!description) {
      errors = { ...errors, description: 'Enter your activity.' };
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    const { description, start } = this.state;

    const seconds = convertToSeconds(this.state.duration);
    const end = add(new Date(this.state.start), { seconds });
    const duration = convertToSeconds(this.state.duration);
    this.setState(
      {
        submitted: true,
        durationValid: this.validateDuration(this.state.duration),
      },
      () => {
        if (this.validateRequiredFields() && end && this.state.durationValid) {
          this.setState({ loading: true });
          const data = {
            description,
            start,
            end,
            duration,
          };

          putTimeEntries(this.state.id, data).then(
            (response) => {
              this.handleData(response.data);
              this.props.onEntryUpdate();
              this.setState({ loading: false, isEdit: false });
            },
            (error) => {
              this.setState({ loading: false });
            }
          );
        }
      }
    );
  };

  handleData = (response) => {
    if (response) {
      this.setState({
        ...response.data,
      });
    }
  };

  getTime = (duration) => {
    let hours = String(Math.floor(this.state.duration / 3600));
    let mins = String(Math.floor((this.state.duration % 3600) / 60));
    let secs = String(Math.floor((this.state.duration % 3600) % 60));

    hours = hours.length === 1 ? '0' + hours : hours;
    mins = mins.length === 1 ? '0' + mins : mins;
    secs = secs.length === 1 ? '0' + secs : secs;

    return hours + ':' + mins + ':' + secs;
  };

  // convertToSeconds = () => {
  //   const stringVal = String(this.state.duration) || '';
  //   const stringArr = stringVal.split(':');

  //   let hour = parseInt(stringArr[0]) || 0;
  //   let min = parseInt(stringArr[1]) || 0;
  //   let sec = parseInt(stringArr[2]) || 0;
  //   return hour * 60 * 60 + min * 60 + sec;
  // };

  handlePaidChange = () => {
    this.setState(
      {
        locked: !this.state.locked,
      },
      () => {
        const data = {
          locked: this.state.locked,
        };

        putTimeEntries(this.state.id, data).then(
          (response) => {
            this.handleData(response.data);
            this.setState({ loading: false, isEdit: false });
          },
          (error) => {
            this.setState({ loading: false });
          }
        );
      }
    );
  };

  render() {
    const assignment = this.state.timesheet?.assignment;

    const secondsDuration = convertToSeconds(this.state.duration);
    const hourDuration = convertToHours(secondsDuration);

    return (
      <Fragment>
        <tbody className={classNames(Styles.list, this.state.locked ? Styles.listLocked : '')}>
          {this.state.isEdit && (
            <td style={{ width: '100%' }}>
              <div className={PageStyles.form}>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      name='description'
                      label='Activity'
                      error={this.state.errors?.description}
                      defaultValue={this.state.description}
                      onChange={this.handleChange}
                    />
                  </InputContainer>
                </InputGroup>
                <InputGroup>
                  <InputContainer>
                    <DatePicker
                      id='sheetsStart'
                      name='start'
                      label='Start Date'
                      value={this.state.start}
                      error={this.state.errors?.start}
                      dateFormat='MM/dd/yyyy hh:mm aa'
                      showTimeInput
                      onChange={this.handleStartDateChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      name='duration'
                      label='Duration'
                      placeholder='HH:MM:SS'
                      mask='99:99:99'
                      maskChar={'0'}
                      error={
                        this.state.submitted &&
                        !this.state.durationValid &&
                        'Enter valid entry duration'
                      }
                      defaultValue={this.state.duration}
                      onChange={this.handleChange}
                    />
                  </InputContainer>
                </InputGroup>
              </div>

              <div className={Styles.row}>
                <div className={ListStyles.action}>
                  <Button
                    name='cancel'
                    type={Button.Type.WHITE_BLUE_OUTLINE}
                    onClick={this.handleCancel}>
                    {`Cancel`}
                  </Button>

                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={this.handleSubmit}
                    isLoading={this.state.loading}>
                    {`Save`}
                  </Button>
                </div>
              </div>
            </td>
          )}
          {!this.state.isEdit && (
            <tr className={Styles.row}>
              <td>
                <div>
                  <p className={Styles.title}>{this.state.description}</p>
                  <p className={Styles.description}>{`${(assignment?.organization || {}).name} - ${
                    (assignment || {}).title || 'Untitled Assignment'
                  }`}</p>
                </div>
              </td>

              <div className={Styles.action}>
                <td>
                  {this.state.locked && (
                    <span className={classNames(ListStyles.label, Styles.paidLabel)}>Paid</span>
                  )}
                </td>

                <td className={Styles.listSection}>
                  <span>{toReadableDate(this.state.start)}</span>
                </td>
                <td className={Styles.listSection}>
                  {(isFinance(this.props.currentUser) ||
                    isAdmin(this.props.currentUser) ||
                    this.props.currentUser.adminPermission.timesheetTalentRate) &&
                  (assignment || {}).talentRate &&
                  (assignment || {}).talentRateType ? (
                    <span>{`$${(assignment || {}).talentRate} ${
                      RateType.getEnum((assignment || {}).talentRateType).label
                    }`}</span>
                  ) : (
                    ''
                  )}
                </td>
                <td className={classNames(Styles.listSection, Styles.small)}>
                  <span>{this.props.showTimestamp ? this.state.duration : hourDuration}</span>
                </td>
                <td className={classNames(Styles.listSection, Styles.medium)}>
                  {(isFinance(this.props.currentUser) ||
                    isAdmin(this.props.currentUser) ||
                    this.props.currentUser.adminPermission.timesheetTalentRate) &&
                    !this.state.locked &&
                    assignment?.talentRateType === RateType.HOURLY.id && (
                      <span>
                        {`$${(hourDuration * parseFloat(assignment?.talentRate))?.toFixed(3)}`}
                      </span>
                    )}
                </td>
                <td className={Styles.listOption}>
                  {/* <Dropdown drop='left' size='sm'>
                    <Dropdown.Toggle className={ListStyles.moreDropdown}>
                      <IconMoreVertical className={ListStyles.moreIcon} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.handleEdit}>{`Edit`}</Dropdown.Item>
                      <Dropdown.Item onClick={this.handlePaidChange}>
                        {this.state.locked ? 'Mark as Unpaid' : 'Mark as Paid'}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <SplitButton
                    direction='left'
                    more={[
                      {
                        text: 'Edit',
                        onClick: this.handleEdit,
                      },
                      {
                        text: this.state.locked ? 'Mark as Unpaid' : 'Mark as Paid',
                        onClick: this.handlePaidChange,
                      },
                    ]}
                  />
                </td>
              </div>
            </tr>
          )}
        </tbody>
      </Fragment>
    );
  }
}

export default withUser(EntryListItem);
