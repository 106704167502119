import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as FullStory from '@fullstory/browser';

import '@styles/global.scss';
import 'react-toggle/style.css'; // for ES6 modules

console.log('Build Version: ', __VERISON__);
console.log('Env: ', __ACTIVE_ENV__);

if (__ACTIVE_ENV__ !== 'local' && __ACTIVE_ENV__ !== 'localDev') {
  Sentry.init({
    dsn: 'https://b9d3be0afdde47d78499b947380ab9aa@o32554.ingest.sentry.io/1505937',
    integrations: [new Integrations.BrowserTracing()],
    release: 'admin@' + process.env.npm_package_version,
    environment: process.env.SENTRY_ENV,
  });

  FullStory.init({ orgId: process.env.FULLSTORY_ID });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
