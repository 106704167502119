import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TimeRate } from '@hiredigital/lib/helpers/enum';
import Styles from '../list.module.scss';

const MaxAllocation = ({ maxAlloc }) => {
  return (
    <div className={classNames(Styles.listSection, Styles.center)}>
      {maxAlloc?.maxHours ? `${maxAlloc.maxHours} ${TimeRate.getEnum(maxAlloc.maxPer).label}` : ''}
    </div>
  );
};

MaxAllocation.propTypes = {
  maxAlloc: PropTypes.object,
};

export default MaxAllocation;
