import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUser, getUserBankAccounts, putUser } from '@apis/users';
import Loader from '@hiredigital/ui/Loader';
import AppLayout from '@components/Layout/AppLayout';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { validateUrl } from '@helpers/forms';
import { Role } from '@hiredigital/lib/helpers/enum';
import { withUser } from '@context/user';

import BankAccountList from '@hiredigital/ui/BankAccounts';
import { BankApiManager, BankValidator } from '@hiredigital/lib/apis/class/banks';
import { RatesApiManager } from '@hiredigital/lib/apis/class/rates';
import { authRequest, request } from '@apis/utils';

import ServiceRateList from '@components/ServiceRate/ServiceRateList';
import TalentRateList from '@hiredigital/ui/TalentRateList';
import PaymentDetails from '@components/PaymentDetails/PaymentDetails';
import PaymentSettings from '@components/PaymentSettings/PaymentSettings';
import UserAssignments from '@components/UserAssignments/List';
import UserApplications from '@components/UserApplications/List';

import InvoiceList from '@components/Invoices/List';

import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

class FinanceUserDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    store: PropTypes.object,
    currentUser: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      userUuid: this.props.match.params.userUuid,
      ratingsLoaded: false,
      bankAccountsLoaded: false,
      name: '',
      picture: '',
      firstName: '',
      lastName: '',
      username: '',
      language: '',
      profile: {
        headline: '',
        description: '',
        flagMessages: false,
      },
      social: {
        website: '',
        linkedin: '',
        twitter: '',
        facebook: '',
        instagram: '',
      },
      portfolio: {
        isHidden: false,
        primarySkill: '',
        location: '',
        currentLocation: null,
        orderedSkills: [],
        requestHidden: false,
        resume: '',
      },
      employment: [],
      status: null,
      skills: [],
      ratings: [],
      assignments: [],
      bankAccounts: [],
      websiteValid: true,
      hasInitLoad: false,
      weekHours: [],
      talentSettings: {},
    };
  }

  componentDidMount = () => {
    const hash = this.props.location && this.props.location.hash;

    Promise.all([this.loadProfile()]).then(
      (values) => {
        setPageTitle(`Talents |  ${(values[0].data && values[0].data.name) || 'New Talent'}`);
        this.handleResponse(values[0]);
        this.loadSuperUserFields();
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      },
      (error) => {
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextProps.currentUser !== this.props.currentUser) {
      return true;
    }

    if (
      nextState.profile?.description !== this.state.profile?.description ||
      nextState.portfolio?.resume !== this.state.portfolio?.resume ||
      nextState.portfolio?.orderedSkills !== this.state.portfolio?.orderedSkills ||
      nextState.bankAccountsLoaded !== this.state.bankAccountsLoaded ||
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.isSaving !== this.state.isSaving ||
      nextState.websiteValid !== this.state.websiteValid ||
      nextState.isAcceptingChanges !== this.state.isAcceptingChanges ||
      nextState.title !== this.state.title ||
      nextState.status !== this.state.status ||
      nextState.stage !== this.state.stage ||
      nextState.currencyCode !== this.state.currencyCode ||
      nextState.estimatedBudget !== this.state.estimatedBudget ||
      nextState.sgdBudget !== this.state.sgdBudget ||
      nextState.estimatedStartDate !== this.state.estimatedStartDate ||
      nextState.estimatedEndDate !== this.state.estimatedEndDate ||
      nextState.requestTrial !== this.state.requestTrial ||
      nextState.disableNotifications !== this.state.disableNotifications ||
      nextState.location !== this.state.location ||
      nextState.creator !== this.state.creator ||
      nextState.isSaving !== this.state.isSaving
    ) {
      return true;
    }
    return false;
  }

  loadSuperUserFields = () => {
    this.loadBankAccounts().then((response) => {
      this.setState({
        bankAccounts: response.data.results,
        bankAccountsLoaded: true,
      });
    });
  };

  loadProfile = () => getUser(this.state.userUuid);

  loadBankAccounts = () => getUserBankAccounts(this.state.userUuid);

  handleNestedChange = (user) => {
    this.setState((state) => {
      return {
        ...state,
        ...user,
      };
    });
  };

  handleResponse = (response) => {
    if (response) {
      this.setState(
        {
          ...response.data,
          language:
            (response.data.portfolio &&
              response.data.portfolio.languages &&
              !!response.data.portfolio.languages.length &&
              response.data.portfolio.languages[0]) ||
            null,
          ...(!response.data.portfolio && {
            portfolio: { orderedSkills: [] },
          }),
        },
        () => {
          this.setState({ hasInitLoad: true });
        }
      );
    }
  };

  handleUploadResponse = ({ data }) => {
    const portfolio = {
      ...this.state.portfolio,
      resume: data.portfolio.resume,
    };
    this.setState({ portfolio });
  };

  handleImmediateSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 0);
  };

  handleAutoSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 2000);
  };

  handleSubmit = (storeResponse = true) => {
    const {
      firstName,
      lastName,
      username,
      profile,
      social,
      language,
      notes,
      status,
      defaultSite,
      talentSettings,
    } = this.state;
    const portfolio = { ...this.state.portfolio };

    if (social && social.website) {
      this.setState({ websiteValid: validateUrl(social.website) });
    }

    if (this.state.websiteValid) {
      delete profile.timezone;
      delete portfolio.skills;
      delete portfolio.requestHidden;

      this.setState({ isSaving: true });

      let primarySkill;
      if (portfolio.primarySkill) {
        primarySkill = portfolio.primarySkill.id;
      }

      if (portfolio.resume) {
        delete portfolio.resume;
      }
      // We do this to ignore this skills for now, when we move all to portoflio read/write then we can leave
      const data = {
        firstName,
        lastName,
        username,
        profile,
        social,
        defaultSite,
        talentSettings,
        portfolio: {
          ...portfolio,
          primarySkill,
          currentLocation: portfolio.currentLocation ? portfolio.currentLocation.placeId : null,
          orderedSkills: portfolio.orderedSkills
            ? portfolio.orderedSkills.map((obj) => obj.id)
            : [],
        },
        notes,
        status,
      };

      if (language) {
        data.portfolio.languages = [language.id];
      }

      putUser(this.state.userUuid, data).then(
        (response) => {
          if (storeResponse) {
            this.handleResponse(response);
          }
          this.setState({ isSaving: false });
        },
        (error) => {
          this.setState({ isSaving: false });
        }
      );
    }
  };

  render() {
    return (
      <AppLayout
        roles={[Role.FINANCE.id, Role.ADMIN.id]}
        permissions={this.props.currentUser.adminPermission?.finance}
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/finance-talents'>
              {`Talents`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>{this.state.name}</div>
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <>
              <div className={Styles.row} style={{ marginBottom: '10px' }}>
                <img src={this.state.picture} className={Styles.photo} />
                <div>
                  <p className={Styles.name}>{this.state.name}</p>
                  <p>{this.state.email}</p>
                </div>
              </div>

              <PaymentSettings
                userUuid={this.state.userUuid}
                data={{
                  ...this.state.payment,
                }}
              />
              <PaymentDetails
                userUuid={this.state.userUuid}
                data={{
                  ...this.state.contact,
                  ...this.state.address,
                }}
              />
              {this.props.currentUser && (
                <UserApplications
                  talentPermission={true}
                  clientPermission={true}
                  userUuid={this.state.userUuid}
                  currentUser={this.props.currentUser}
                />
              )}
              {this.props.currentUser && (
                <UserAssignments
                  talentPermission={true}
                  clientPermission={true}
                  userUuid={this.state.userUuid}
                  currentUser={this.props.currentUser}
                />
              )}

              <Fragment>
                {isNewRates ? (
                  <TalentRateList
                    type={TalentRateList.Type.ADMIN}
                    apiManager={new RatesApiManager(authRequest)}
                    resourceUuid={this.state.userUuid}
                    resource={`users`}
                    talentPermission={true}
                    userSkills={this.state.portfolio && this.state.portfolio.orderedSkills}
                  />
                ) : (
                  <ServiceRateList
                    clientPermission={true}
                    talentPermission={true}
                    resourceUuid={this.state.userUuid}
                    userSkills={this.state.portfolio && this.state.portfolio.orderedSkills}
                    resource={`users`}
                  />
                )}

                {this.state.bankAccountsLoaded ? (
                  <BankAccountList
                    apiManager={
                      new BankApiManager(authRequest, this.state.userUuid, {
                        resourcePrefix: 'admin',
                      })
                    }
                    oldApiManager={
                      new BankApiManager(authRequest, this.state.userUuid, {
                        resourcePrefix: 'admin',
                        subResource: 'bank-accounts',
                      })
                    }
                    validator={new BankValidator(request)}
                    showWiseId
                  />
                ) : (
                  ''
                )}
              </Fragment>

              <InvoiceList resourceUuid={this.state.userUuid} resource={`users`} />
            </>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default withUser(FinanceUserDetail);
