import { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import Loader from '@hiredigital/ui/Loader';
import Select from '@hiredigital/ui/Input/Select';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import AppLayout from '@components/Layout/AppLayout';
import AdminPermissions from '@components/AdminPermissions/AdminPermissions';
import { Role } from '@hiredigital/lib/helpers/enum';
import {
  onAssignmentsLoadOptions,
  onProjectsLoadOptions,
  onOrgLoadOptions,
  onBriefLoadOptions,
} from '@apis/dropdown';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import {
  getAdmin,
  putAdmin,
  getAdminOrgList,
  getAdminBriefList,
  getAdminTeamList,
  getAdminUserList,
  getAdminEngagementAssignmentList,
  addAdminOrg,
  addAdminBrief,
  addAdminTeam,
  addAdminUser,
  addAdminEngagementAssignment,
  removeAdminOrg,
  removeAdminBrief,
  removeAdminTeam,
  removeAdminUser,
  removeAdminEngagementAssignment,
  getAdminProjectList,
  removeAdminProject,
  addAdminProject,
} from '@apis/admins';

import Styles from './SupportDetail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

class SupportDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      userUuid: this.props.match.params.userUuid,
      permissions: undefined,
      isSaved: false,
      hasInitLoad: false,
      submitted: false,
      currentTab: '',
    };
  }

  componentDidMount = () => {
    const hash = this.props.location && this.props.location.hash;
    this.setState(
      {
        currentTab: (hash && hash.substr(1)) || 'clients',
        hasInitLoad: true,
      },
      () => {
        this.handleNavigation(this.state.currentTab);
      }
    );

    this.loadUser().then((res) => {
      setPageTitle((res.data && res.data.name) || res.data.username);
      this.setState({ ...res.data });
    });
  };

  loadUser = () => getAdmin(this.state.userUuid);

  handleRoleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value.map((e) => e.id),
      },
      () => {
        this.handleImmediateSave();
      }
    );
  };

  handleImmediateSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(), 0);
  };

  handleSubmit = () => {
    const { roles } = this.state;

    this.setState({ isSaving: true });

    const data = { roles };

    putAdmin(this.state.userUuid, data).then(
      (response) => {
        this.setState({
          isSaving: false,
        });
      },
      (error) => {
        this.setState({
          isSaving: false,
        });
      }
    );
  };

  handleNavigation = (tab) => {
    this.setState({ currentTab: tab }, () => {
      const { history, location } = this.props;
      //let url;
      history.push(`${location.pathname}#${tab}`);

      const requests = {
        clients: getAdminOrgList,
        briefs: getAdminBriefList,
        teams: getAdminTeamList,
        users: getAdminUserList,
        projects: getAdminProjectList,
        assignments: getAdminEngagementAssignmentList,
      };

      return requests[tab](this.state.userUuid).then((res) =>
        this.setState({
          [tab]: res.data,
        })
      );
    });
  };

  handleRemove = (tab, uuid) => {
    const requests = {
      clients: removeAdminOrg,
      briefs: removeAdminBrief,
      teams: removeAdminTeam,
      users: removeAdminUser,
      projects: removeAdminProject,
      assignments: removeAdminEngagementAssignment,
    };

    return requests[tab](this.state.userUuid, uuid).then((e) => this.handleNavigation(tab));
  };

  handleAdd = (tab, uuid) => {
    const requests = {
      clients: addAdminOrg,
      briefs: addAdminBrief,
      teams: addAdminTeam,
      users: addAdminUser,
      projects: addAdminProject,
      assignments: addAdminEngagementAssignment,
    };
    return requests[tab](this.state.userUuid, uuid).then((e) => this.handleNavigation(tab));
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/support'>
              {`Support Staff`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>{this.state.name || this.state.username}</div>
          </div>
        }>
        {this.state.hasInitLoad && this.state.roles && (
          <div className={PageStyles.container}>
            <div className={classNames(PageStyles.formContainer, PageStyles.adminContainer)}>
              <div className={Styles.row}>
                <h3 className={PageStyles.headline}>{`Admin Settings`}</h3>
              </div>
              <div>
                <InputGroup>
                  <InputContainer>
                    <Select
                      data-test-id='roles'
                      className={PageStyles.select}
                      defaultValue={Role.getEnums(this.state.roles)}
                      name='roles'
                      label='Roles'
                      isClearable={false}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      options={Role.values}
                      isMulti
                      onChange={this.handleRoleChange}
                    />
                  </InputContainer>
                </InputGroup>
              </div>
            </div>
            <AdminPermissions userUuid={this.state.userUuid} />
            <br />
            <br />
            <Tab.Container
              defaultActiveKey='clients'
              activeKey={this.state.currentTab}
              mountOnEnter={true}>
              <Nav variant='tabs' className={classNames(Styles.row, PageStyles.headlineRow)}>
                <Nav.Item>
                  <Nav.Link eventKey='clients' onSelect={this.handleNavigation}>
                    <h3 className={classNames(PageStyles.headline)}>{`Clients`}</h3>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='briefs' onSelect={this.handleNavigation}>
                    <h3 className={classNames(PageStyles.headline)}>{`Briefs`}</h3>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey='assignments' onSelect={this.handleNavigation}>
                    <h3 className={classNames(PageStyles.headline)}>{`Assignments`}</h3>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey='projects' onSelect={this.handleNavigation}>
                    <h3 className={classNames(PageStyles.headline)}>{`Projects`}</h3>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className={PageStyles.tabContent}>
                <Tab.Pane eventKey='clients'>
                  <div className={Styles.listRow}>
                    <InputGroup>
                      <InputContainer style={{ marginBottom: '0' }}>
                        <Select
                          data-test-id='clients'
                          className={PageStyles.select}
                          value={this.state.newOrg}
                          classNamePrefix='s-contact'
                          name='clients'
                          label='Add Client'
                          getOptionLabel={({ name, uuid }) => `${name} (${uuid})`}
                          getOptionValue={({ uuid }) => uuid}
                          options={this.state.orgList}
                          onChange={(e) => this.handleAdd('clients', e.target.value.uuid)}
                          filterOption={({ value }) =>
                            this.state.clients.filter((c) => c.uuid == value).length === 0
                          }
                          isPaginated
                          loadOptions={onOrgLoadOptions}
                        />
                      </InputContainer>
                    </InputGroup>
                  </div>
                  {this.state.clients &&
                    this.state.clients.map((org, i) => {
                      return (
                        <div className={Styles.listRow} key={i}>
                          <div>
                            <div className={Styles.primary}>{org.title || 'Untitled Client'}</div>
                            <div className={Styles.secondary}>{org.uuid} </div>
                          </div>
                          <div
                            className={Styles.action}
                            onClick={(e) => this.handleRemove('clients', org.uuid)}>
                            Remove
                          </div>
                        </div>
                      );
                    })}
                  {/*<div className={classNames(PageStyles.formContainer, PageStyles.stickyRow)}>
                    <div className={Styles.saveRow}>
                      <Button
                        name='submit'
                        className={Styles.saveButton}
                        type={Button.Type.BLUE}
                        onClick={this.handleImmediateSave}
                        isLoading={this.state.isSaving}>
                        {`Save`}
                      </Button>
                    </div>
                  </div>*/}
                </Tab.Pane>
                <Tab.Pane eventKey='briefs'>
                  <div className={Styles.listRow}>
                    <InputGroup>
                      <InputContainer style={{ marginBottom: '0' }}>
                        <Select
                          data-test-id='roles'
                          className={PageStyles.select}
                          value={this.state.newBrief}
                          classNamePrefix='s-contact'
                          name='briefs'
                          label='Add Brief'
                          getOptionLabel={({ title, organization, uuid }) =>
                            `${title || 'Untitled Brief'} (${
                              (organization && organization.name) || uuid
                            })`
                          }
                          getOptionValue={({ uuid }) => uuid}
                          options={this.state.briefList}
                          onChange={(e) => this.handleAdd('briefs', e.target.value.uuid)}
                          filterOption={({ value }) =>
                            this.state.briefs.filter((c) => c.uuid == value).length === 0
                          }
                          isPaginated
                          loadOptions={onBriefLoadOptions}
                        />
                      </InputContainer>
                    </InputGroup>
                  </div>
                  {this.state.briefs &&
                    this.state.briefs.map((brief, i) => {
                      return (
                        <div className={Styles.listRow} key={i}>
                          <div>
                            <div className={Styles.primary}>{brief.title || 'Untitled Brief'}</div>
                            <div className={Styles.secondary}>{brief.uuid} </div>
                          </div>
                          <div
                            className={Styles.action}
                            onClick={(e) => this.handleRemove('briefs', brief.uuid)}>
                            Remove
                          </div>
                        </div>
                      );
                    })}
                </Tab.Pane>

                <Tab.Pane eventKey='assignments'>
                  <div className={Styles.listRow}>
                    <InputGroup>
                      <InputContainer style={{ marginBottom: '0' }}>
                        <Select
                          data-test-id='assignments'
                          className={PageStyles.select}
                          value={this.state.newAssignment}
                          classNamePrefix='s-contact'
                          name='assignments'
                          label='Add Assignment'
                          getOptionLabel={({ title, organization, uuid }) =>
                            `${title || 'Untitled Assignment'} (${
                              (organization && organization.name) || uuid
                            })`
                          }
                          getOptionValue={({ uuid }) => uuid}
                          options={this.state.assignmentList}
                          onChange={(e) => this.handleAdd('assignments', e.target.value.uuid)}
                          filterOption={({ value }) =>
                            this.state.assignments.filter((c) => c.uuid == value).length === 0
                          }
                          isPaginated
                          loadOptions={onAssignmentsLoadOptions}
                        />
                      </InputContainer>
                    </InputGroup>
                  </div>
                  {this.state.assignments &&
                    this.state.assignments.map((assignment, i) => {
                      return (
                        <div className={Styles.listRow} key={i}>
                          <div>
                            <div className={Styles.primary}>
                              {assignment.title || 'Untitled Assignment'}
                            </div>
                            <div className={Styles.secondary}>{assignment.uuid} </div>
                          </div>
                          <div
                            className={Styles.action}
                            onClick={(e) => this.handleRemove('assignments', assignment.uuid)}>
                            Remove
                          </div>
                        </div>
                      );
                    })}
                </Tab.Pane>

                <Tab.Pane eventKey='projects'>
                  <div className={Styles.listRow}>
                    <InputGroup>
                      <InputContainer style={{ marginBottom: '0' }}>
                        <Select
                          data-test-id='projects'
                          className={PageStyles.select}
                          value={this.state.newProject}
                          classNamePrefix='s-contact'
                          name='projects'
                          label='Add Project'
                          getOptionLabel={({ title, organization, uuid }) =>
                            `${title || 'Untitled Project'} (${
                              (organization && organization.name) || uuid
                            })`
                          }
                          getOptionValue={({ uuid }) => uuid}
                          options={this.state.projectList}
                          onChange={(e) => this.handleAdd('projects', e.target.value.uuid)}
                          filterOption={({ value }) =>
                            this.state.projects.filter((c) => c.uuid == value).length === 0
                          }
                          isPaginated
                          loadOptions={onProjectsLoadOptions}
                        />
                      </InputContainer>
                    </InputGroup>
                  </div>
                  {this.state.projects &&
                    this.state.projects.map((project, i) => {
                      return (
                        <div className={Styles.listRow} key={i}>
                          <div>
                            <div className={Styles.primary}>
                              {project.title || 'Untitled Project'}
                            </div>
                            <div className={Styles.secondary}>{project.uuid} </div>
                          </div>
                          <div
                            className={Styles.action}
                            onClick={(e) => this.handleRemove('projects', project.uuid)}>
                            Remove
                          </div>
                        </div>
                      );
                    })}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default SupportDetail;
