import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import SelectMenu from '@hiredigital/ui/SelectMenu';
import { putEngagementAssignment } from '@apis/engagements';

import { AssignmentStatus, RateType } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import {
  isBriefAdmin,
  isPlacementAdmin,
  isSalesAdmin,
  isAnyBriefAdmin,
  isAnyTalentAdmin,
} from '@helpers/permissions';

import Styles from '../list.module.scss';
import ListStyles from '@styles/PageList.module.scss';

import Table from '@components/Table/Table';
import ListImage from '@components/ListImage';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

class AssignmentListItem extends Component {
  static propTypes = {
    assignment: PropTypes.object,
    engagementUuid: PropTypes.string,
    briefUuid: PropTypes.string,
    onDelete: PropTypes.func,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.assignment,
    };
  }

  handleSelectChange = (value, name) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.saveAssignment();
      }
    );
  };

  saveAssignment = () => {
    this.setState({ submitted: true, loading: true });
    const data = {
      status: this.state.status,
    };

    putEngagementAssignment(this.props.engagementUuid, this.props.assignment.uuid, data).then(
      (response) => {
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    return (
      <div className={classNames(Styles.nestedRow, Styles.assignmentRow)}>
        <div className={Styles.nestedTree} />
        <Table.Cell width='250px' className={ListStyles.cell}>
          <Link
            className={ListStyles.title}
            to={`/briefs/${this.props.briefUuid}#ass-${this.state.uuid}`}>
            {`Assignment`}
          </Link>
        </Table.Cell>
        <Table.Cell width='225px'>
          {this.state.user || this.state.team ? (
            <Fragment>
              <a
                className={Styles.profileLink}
                href={
                  this.state.user
                    ? `/profiles/${this.state.user.uuid}`
                    : `/teams/${this.state.team.uuid}`
                }
                rel='nofollow noopener'
                target='_blank'>
                <ListImage user={this.state.user} team={this.state.team} />
                <span className={Styles.profileLinkText}>
                  {(this.state.user && this.state.user.name) ||
                    (this.state.team && this.state.team.name)}
                </span>
                <IconExternal style={{ marginLeft: 'auto' }} className={Styles.externalIcon} />
              </a>
            </Fragment>
          ) : (
            'No User Added'
          )}
        </Table.Cell>
        <Table.Cell width='480px' className={Styles.hoverParent}>
          <div className={Styles.hoverSection}>
            <div className={Styles.innerSection}>
              {this.state.startDate || this.state.endDate ? (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {`Dates: `}
                  </p>
                  <p className={Styles.content}>
                    {`${
                      this.state.startDate ? toReadableDate(this.state.startDate) : 'No Start Date'
                    } - ${this.state.endDate ? toReadableDate(this.state.endDate) : 'No End Date'}`}
                  </p>
                </div>
              ) : (
                ''
              )}

              {this.props.currentUser && isSalesAdmin(this.props.currentUser) && (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {isBriefAdmin(this.props.currentUser) ? `Client Rate: ` : `Rate: `}
                  </p>
                  <p className={Styles.content}>
                    {isNewRates
                      ? this.state.clientEngagementRate?.rateDisplayText || '-'
                      : `${this.state.brandRate} ${
                          this.state.brandRateType
                            ? RateType.getEnum(this.state.brandRateType).label
                            : ''
                        }`}
                  </p>
                </div>
              )}

              {this.props.currentUser && isPlacementAdmin(this.props.currentUser) && (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {isBriefAdmin(this.props.currentUser) ? `Talent Rate: ` : `Rate: `}
                  </p>
                  <p className={Styles.content}>
                    {isNewRates
                      ? this.state.talentEngagementRate?.rateDisplayText || '-'
                      : `${this.state.talentRate} ${
                          this.state.talentRateType
                            ? RateType.getEnum(this.state.talentRateType).label
                            : ''
                        }`}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell width='220px'>
          {/* <Dropdown size='sm'>
            <Dropdown.Toggle className={ListStyles.selectDropdown}>
              <p>{AssignmentStatus.getEnum(this.state.status).label}</p>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {AssignmentStatus.values.map((status, statusIndex) => (
                <Dropdown.Item
                  onClick={() => this.handleSelectChange(status.id, 'status')}
                  key={statusIndex}>
                  {status.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
          <SelectMenu
            options={AssignmentStatus.values}
            defaultValue={AssignmentStatus.getEnum(this.state.status)}
            onChange={(v) => this.handleSelectChange(v?.id, 'status')}
          />
        </Table.Cell>
        <Table.Cell width='380px' />
        <div className={Styles.floatingAction}>
          {/* <Dropdown drop='left' size='sm'>
            <Dropdown.Toggle className={ListStyles.moreDropdown}>
              <IconMoreVertical className={ListStyles.moreIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.user ? (
                <Fragment>
                  {this.props.currentUser && isAnyTalentAdmin(this.props.currentUser) && (
                    <Dropdown.Item target='_blank' href={`/profiles/${this.state.user.uuid}`}>
                      {`View Talent Profile`}
                    </Dropdown.Item>
                  )}

                  {this.props.currentUser && isAnyBriefAdmin(this.props.currentUser) && (
                    <Dropdown.Item
                      target='_blank'
                      href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.key}`}>
                      {`View Hidden Resume`}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    target='_blank'
                    href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.username}`}>
                    {`View Public Resume`}
                  </Dropdown.Item>
                </Fragment>
              ) : (
                ''
              )}

              {!this.state.user &&
                this.state.team &&
                this.props.currentUser &&
                isBriefAdmin(this.props.currentUser) && (
                  <Fragment>
                    <Dropdown.Item
                      target='_blank'
                      href={`${process.env.MARKETING_ENDPOINT}/teams/${this.state.team.slug}`}>
                      {`View Public Page`}
                    </Dropdown.Item>
                  </Fragment>
                )}

              <Dropdown.Item onClick={(e) => this.props.onDelete(e, this.state.uuid)}>
                {`Delete Assignment`}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <SplitButton
            more={[
              {
                ...(this.state.user &&
                this.props.currentUser &&
                isAnyTalentAdmin(this.props.currentUser)
                  ? {
                      text: 'View Talent Profile',
                      as: 'a',
                      target: '_blank',
                      href: `/profiles/${this.state.user.uuid}`,
                    }
                  : {}),
              },
              {
                ...(this.state.user &&
                this.props.currentUser &&
                isAnyBriefAdmin(this.props.currentUser)
                  ? {
                      text: 'View Hidden Resume',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.key}`,
                    }
                  : {}),
              },
              {
                ...(this.state.user
                  ? {
                      text: 'View Public Resume',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.username}`,
                    }
                  : {}),
              },
              {
                ...(!this.state.user &&
                this.state.team &&
                this.props.currentUser &&
                isBriefAdmin(this.props.currentUser)
                  ? {
                      text: 'View Public Page',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/teams/${this.state.team.slug}`,
                    }
                  : {}),
              },
              {
                text: 'Delete Assignment',
                onClick: (e) => this.props.onDelete(e, this.state.uuid),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default AssignmentListItem;
