import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { BaseCountry, Currency, StripeCurrency } from '@hiredigital/lib/helpers/enum';
import { patchPayment, syncWithXero, syncWithUsXero } from '@apis/payments';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import Styles from './Styles.module.scss';
let saveTimeout = undefined;

const PaymentSettings = memo(({ data, userUuid, orgUuid }) => {
  const [taxNumber, setTaxNumber] = useState(data.taxNumber);
  const [xeroContactId, setXeroContactId] = useState(data.xeroContactId);
  const [usXeroContactId, setUsXeroContactId] = useState(data.usXeroContactId);
  const [baseCountry, setBaseCountry] = useState(data.baseCountry);
  const [stripeCurrency, setStripeCurrency] = useState(data.stripeCurrency);
  const [currency, setCurrency] = useState(data.currency);
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const [enableAutoSave, setEnableAutoSave] = useState(false);

  useEffect(() => {
    setEnableAutoSave(true);
  }, []);

  useEffect(() => {
    if (enableAutoSave) {
      handleAutoSave();
    }
  }, [taxNumber, xeroContactId, usXeroContactId, baseCountry, stripeCurrency, currency]);

  const handleAutoSave = (timeout = 3000) => {
    if (saveTimeout) clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      handleSubmit();
    }, timeout);
  };

  const updateXero = async () => {
    setIsSyncing(true);
    const response = await syncWithXero(data.uuid);

    setXeroContactId(response?.data?.xeroContactId);
    setIsSyncing(false);
  };

  const updateUsXero = async () => {
    setIsSyncing(true);
    const response = await syncWithUsXero(data.uuid);
    setUsXeroContactId(response?.data?.usXeroContactId);
    setIsSyncing(false);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    await patchPayment(data.uuid, {
      baseCountry,
      taxNumber,
      currency,
      stripeCurrency,
      xeroContactId,
      usXeroContactId,
    });
    // await updateRequest(userUuid || orgUuid, data);
    setIsSaving(false);
  };

  return (
    <Card>
      <Card.Header>
        <div className={Styles.title}>{`Payment Settings`}</div>
      </Card.Header>
      <Card.Item data-test-id=''>
        <InputGroup>
          <InputContainer>
            <TextInput
              name='taxNumber'
              label='Tax Id Number'
              defaultValue={taxNumber}
              onChange={(e) => setTaxNumber(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <Select
              data-test-id='localCurrency'
              className={Styles.select}
              defaultValue={Currency.getEnumByKey(currency, 'id')}
              name='currency'
              label='Preferred Currency'
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
              options={Currency.values}
              onChange={(e) => setCurrency(e.target.value.id)}
            />
          </InputContainer>
        </InputGroup>

        <InputGroup>
          <InputContainer>
            <Select
              data-test-id='baseCountry'
              className={Styles.select}
              defaultValue={BaseCountry.getEnum(baseCountry)}
              name='baseCountry'
              label='Xero Default Country'
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
              options={BaseCountry.values}
              onChange={(e) => setBaseCountry(e.target.value.id)}
            />
          </InputContainer>

          <InputContainer>
            <Select
              data-test-id='baseCurrency'
              className={Styles.select}
              defaultValue={StripeCurrency.getEnum(stripeCurrency)}
              name='stripeCurrency'
              label='Xero Base Currency'
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
              options={StripeCurrency.values}
              onChange={(e) => setStripeCurrency(e.target.value.id)}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <div className={Styles.rowBreak}>
            <InputContainer>
              <TextInput
                name='xeroContactId'
                label='SG Xero Contact Id'
                defaultValue={xeroContactId}
                onChange={(e) => setXeroContactId(e.target.value)}
              />
            </InputContainer>
            <div className={Styles.buttonRow}>
              <Button
                onClick={updateXero}
                name='submit'
                type={Button.Type.BLUE_OUTLINE}
                isLoading={isSyncing}>
                {xeroContactId ? `Update Xero Contact (SG)` : `Create Xero Contact (SG)`}
              </Button>
              {xeroContactId ? (
                <Button
                  element='a'
                  href={`https://go.xero.com/Contacts/View/${xeroContactId}`}
                  name='submit'
                  target='_blank'
                  type={Button.Type.BLUE_OUTLINE}
                  isLoading={isSyncing}>
                  {`View in Xero (SG)`}
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </InputGroup>

        <InputGroup>
          <div className={Styles.rowBreak}>
            <InputContainer>
              <TextInput
                name='usXeroContactId'
                label='US Xero Contact Id'
                defaultValue={usXeroContactId}
                onChange={(e) => setUsXeroContactId(e.target.value)}
              />
            </InputContainer>
            <div className={Styles.buttonRow}>
              <Button
                onClick={updateUsXero}
                name='submit'
                type={Button.Type.BLUE_OUTLINE}
                isLoading={isSyncing}>
                {usXeroContactId ? `Update Xero Contact (US)` : `Create Xero Contact (US)`}
              </Button>
              {usXeroContactId ? (
                <Button
                  element='a'
                  href={`https://go.xero.com/Contacts/View/${usXeroContactId}`}
                  name='submit'
                  target='_blank'
                  type={Button.Type.BLUE_OUTLINE}
                  isLoading={isSyncing}>
                  {`View in Xero (US)`}
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </InputGroup>
      </Card.Item>
      <Card.Footer right>
        <Button
          onClick={() => handleAutoSave(0)}
          name='submit'
          className={Styles.updateButton}
          type={Button.Type.BLUE}
          isLoading={isSaving}>
          {`Save Payment Settings`}
        </Button>
      </Card.Footer>
    </Card>
  );
});
PaymentSettings.propTypes = {
  data: PropTypes.object,
  userUuid: PropTypes.string,
  orgUuid: PropTypes.string,
};

export default PaymentSettings;
