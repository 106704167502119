import { Fragment, useMemo, useCallback, useRef } from 'react';
import classNames from 'classnames';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconCaret from '@hiredigital/ui/Icon/icons/caret.inline.svg';
import Loader from '@hiredigital/ui/Loader';

import Styles from './Table.module.scss';
// This component will handle the rendering of the table only

const Table = ({
  rowClass,
  cellClass,
  tableClass,
  headerRowClass,
  headerCellClass,
  emptyState,
  rowHeight = 45,
  headerRowHeight = 40,
  list,
  meta,
  loadData,
  loadMoreData,
  isLoading,
  listRef,

  prepareRow,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,

  ...props
}) => {
  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = useMemo(
    () => ({
      width: 150,
    }),
    []
  );
  // const headerRowRef = useRef({});
  // const rowHeights = useRef({});

  const loadMoreItems = isLoading ? () => {} : loadMoreData;

  const isItemLoaded = (index) => {
    if (list?.[index]?.id || list?.[index]?.uuid) {
      return true;
    } else {
      return false;
    }
  };

  const Row = ({ index, style, row }) => {
    const rowRef = useRef({});

    return (
      <div style={style}>
        <div
          ref={rowRef}
          className={classNames(Styles.row, rowClass)}
          style={{ height: rowHeight }}>
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps()}
                className={classNames(Styles.cell, cellClass)}
                title={cell.render('Header')}>
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];

      if (row && isItemLoaded(index)) {
        prepareRow(row);
        return <Row index={index} style={style} row={row} />;
      } else {
        return (
          <div
            className={classNames(Styles.loadingRow, Styles.row, rowClass)}
            index={index}
            style={style}>
            <Loader size={Loader.Size.SMALL} style={{ margin: '10px' }} />
          </div>
        );
      }
    },
    [prepareRow, list, rows]
  );

  // useEffect(() => {
  //   if (headerRowRef.current) {
  //     setHeaderRowHeight(headerRowRef.current.clientHeight);
  //   }
  //   // eslint-disable-next-line
  // }, [headerRowRef]);

  return (
    <div className={Styles.container} data-test-id='scroll-container'>
      <div {...getTableProps()} className={classNames(Styles.table, tableClass)}>
        <Fragment>
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className={classNames(Styles.headerRow, headerRowClass)}
              height={headerRowHeight}>
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classNames(
                    Styles.headerCell,
                    headerCellClass,
                    column.isSorted && Styles.boldHeader
                  )}>
                  {column.render('Header')}

                  <span className={Styles.action}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <IconCaret className={classNames(Styles.toggle, Styles.down)} />
                      ) : (
                        <IconCaret className={classNames(Styles.toggle, Styles.up)} />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </Fragment>
        <div
          {...getTableBodyProps()}
          className={Styles.body}
          style={{ height: `calc(100% - ${headerRowHeight + 1}px)` }}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                ref={listRef}
                isItemLoaded={isItemLoaded}
                itemCount={meta.count}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={20}
                threshold={20}>
                {({ onItemsRendered, ref }) => (
                  <>
                    {!isLoading && list.length === 0 && (
                      <div className={Styles.emptyContainer}>{emptyState || 'No items found'}</div>
                    )}

                    <List
                      ref={ref}
                      className={Styles.list}
                      height={height}
                      itemCount={meta.count}
                      width={width}
                      onItemsRendered={onItemsRendered}
                      overscanCount={25}
                      itemSize={rowHeight}>
                      {RenderRow}
                    </List>
                  </>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
        {isLoading && (
          <div className={Styles.loadingRow}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
