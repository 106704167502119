import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  getUser,
  getUserRatings,
  getUserAssignments,
  getUserBankAccounts,
  putUser,
  patchUser,
  postUserAcceptChanges,
} from '@apis/users';
import { getCities, postLocations, getLogos } from '@apis/common';
import { postSkill } from '@apis/skills';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Toggle from 'react-toggle';
import { authRequest, request } from '@apis/utils';

import Card from '@hiredigital/ui/Card';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';

import { languageList } from '@data/languageList';
import Quill from '@hiredigital/ui/Quill/Editor';
import SortableSelect from '@hiredigital/ui/Input/SortableSelect';
import ImageDrop from '@components/Form/File/ImageDrop';
import SingleUpload from '@hiredigital/ui/Form/File/NewSingle';
import FileViewer from '@hiredigital/ui/FileViewer/FileViewer';
import AgreementList from '@components/Agreements/List';
import BoldAgreementList from '@components/BoldAgreements/List';

import AppLayout from '@components/Layout/AppLayout';
import { UserStatus, Role } from '@hiredigital/lib/helpers/enum';
import { setPageTitle, addHttpPrefix, socialPrefixes } from '@hiredigital/lib/helpers/utils';
import { validateUrl } from '@helpers/forms';
import { onSkillsLoadOptions } from '@apis/dropdown';
import { isAdmin, isTalentAdminNotLimited, isAnyTalentAdmin } from '@helpers/permissions';
import { withUser } from '@context/user';
import Employment from '@components/Resume/Employment/Employment';
import RatingList from '@components/Rating/RatingList';
import BankAccountList from '@hiredigital/ui/BankAccounts';
import { BankApiManager, BankValidator } from '@hiredigital/lib/apis/class/banks';
import { RatesApiManager } from '@hiredigital/lib/apis/class/rates';

import ScoreList from '@components/TalentScore/ScoreList';
import Education from '@components/Resume/Education/Education';
import PortfolioList from '@components/PortfolioList/PortfolioList';

import {
  getUserDailyBusySchedule,
  postUserDailyBusyScheduleSet,
  getUserMonthlyBusySchedule,
  postUserMonthlyBusyScheduleSet,
  processUserResume,
} from '@apis/users';
import MonthlyBusySchedule from '@hiredigital/ui/BusySchedule/Monthly';
import WeeklyBusySchedule from '@hiredigital/ui/DailyBusySchedule/Weekly';
// import { logoList } from '@hiredigital/lib/helpers/logos';

import ServiceRateList from '@components/ServiceRate/ServiceRateList';
import TalentRateList from '@hiredigital/ui/TalentRateList';
import ProfileContact from '@components/ProfileContact/ProfileContact';
import PaymentDetails from '@components/PaymentDetails/PaymentDetails';
import PaymentSettings from '@components/PaymentSettings/PaymentSettings';
import InvoiceList from '@components/Invoices/List';

import TransferList from '@components/Transfers/List';

import UserSettings from '@components/UserSettings/UserSetting';
import TimetrackerSettings from '@components/TimetrackerSettings';
import UserEmailAddresses from '@components/UserEmailAddresses/UserEmailAddresses';
import UserOrgList from '@components/UserOrgs/List';
import UserInvitations from '@components/UserInvitations/List';
import UserAssignments from '@components/UserAssignments/List';
import UserApplications from '@components/UserApplications/List';
import UserPlatform from '@components/UserPlatform/UserPlatform';
import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

class UserDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.object,
    store: PropTypes.object,
    currentUser: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showResumes: JSON.parse(localStorage.getItem('showResumes')) || false,
      userUuid: this.props.match.params.userUuid,
      adminMin: false,
      ratingsLoaded: false,
      // bankAccountsLoaded: false,
      name: '',
      picture: '',
      firstName: '',
      lastName: '',
      username: '',
      language: '',
      profile: {
        headline: '',
        description: '',
        flagMessages: false,
      },
      social: {
        website: '',
        linkedin: '',
        twitter: '',
        facebook: '',
        instagram: '',
      },
      portfolio: {
        isHidden: false,
        primarySkill: '',
        workedAtName: '',
        location: '',
        currentLocation: null,
        orderedSkills: [],
        requestHidden: false,
        resume: '',
      },
      employment: [],
      status: null,
      skills: [],
      ratings: [],
      assignments: [],
      bankAccounts: [],
      // websiteValid: true,
      hasInitLoad: false,
      currentTab: '',
      weekHours: [],
      talentSettings: {},
      errors: {},
      isProcessing: false,
      renderKey: 0,
    };
  }

  componentDidMount = () => {
    const hash = this.props.location && this.props.location.hash;
    this.setState({ currentTab: (hash && hash.substr(1)) || 'profile' });

    this.loadLogos();

    Promise.all([this.loadProfile()]).then(
      (values) => {
        setPageTitle(
          `Profile Review |  ${(values[0].data && values[0].data.name) || 'New Profile'}`
        );
        this.handleResponse(values[0]);
        this.loadSuperUserFields();
        this.loadAdminFields();
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      },
      (error) => {
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextProps.currentUser !== this.props.currentUser) {
      return true;
    }

    if (nextState.notes !== this.state.notes) {
      return false;
    }

    if (
      nextState.profile?.description !== this.state.profile?.description ||
      nextState.portfolio?.resume !== this.state.portfolio?.resume ||
      nextState.portfolio?.workedAtName !== this.state.portfolio?.workedAtName ||
      nextState.portfolio?.orderedSkills !== this.state.portfolio?.orderedSkills ||
      // nextState.bankAccountsLoaded !== this.state.bankAccountsLoaded ||
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.isSaving !== this.state.isSaving ||
      nextState.errors !== this.state.errors ||
      nextState.isAcceptingChanges !== this.state.isAcceptingChanges ||
      nextState.adminMin !== this.state.adminMin ||
      nextState.showResumes !== this.state.showResumes ||
      nextState.title !== this.state.title ||
      nextState.status !== this.state.status ||
      nextState.stage !== this.state.stage ||
      nextState.currencyCode !== this.state.currencyCode ||
      nextState.estimatedBudget !== this.state.estimatedBudget ||
      nextState.sgdBudget !== this.state.sgdBudget ||
      nextState.estimatedStartDate !== this.state.estimatedStartDate ||
      nextState.estimatedEndDate !== this.state.estimatedEndDate ||
      nextState.requestTrial !== this.state.requestTrial ||
      nextState.disableNotifications !== this.state.disableNotifications ||
      nextState.location !== this.state.location ||
      nextState.creator !== this.state.creator ||
      nextState.emailRecipients !== this.state.emailRecipients ||
      nextState.organization !== this.state.organization ||
      nextState.selectedStartDate !== this.state.selectedStartDate ||
      nextState.hubspotDeal !== this.state.hubspotDeal ||
      nextState.visibility !== this.state.visibility ||
      nextState.isAddInProgress !== this.state.isAddInProgress ||
      nextState.isSaving !== this.state.isSaving ||
      nextState.social?.website !== this.state.social?.website ||
      nextState.social?.linkedin !== this.state.social?.linkedin ||
      nextState.social?.twitter !== this.state.social?.twitter ||
      nextState.social?.facebook !== this.state.social?.facebook ||
      nextState.social?.instagram !== this.state.social?.instagram ||
      nextState.renderKey !== this.state.renderKey ||
      nextState.isProcessing !== this.state.isProcessing
    ) {
      return true;
    }
    return false;
  }

  loadAdminFields = () => {
    if (this.props.currentUser && isAdmin(this.props.currentUser)) {
      // this.loadBankAccounts().then((response) => {
      //   this.setState({
      //     bankAccounts: response.data.results,
      //     bankAccountsLoaded: true,
      //   });
      // });

      this.loadRatings().then((response) => {
        this.setState({
          ratings: response.data.results,
          ratingsLoaded: true,
        });
      });

      this.loadAssignments().then((response) => {
        this.setState({ assignments: response.data.results });
      });
    }
  };

  loadSuperUserFields = () => {
    if (this.props.currentUser?.isSuperuser) {
      // Nothing specific here
    }
  };

  loadLogos = async () => {
    const response = await getLogos();
    this.setState({ logoList: response.data || [] });
  };

  loadProfile = () => getUser(this.state.userUuid);

  loadRatings = () => getUserRatings(this.state.userUuid);

  loadAssignments = () => getUserAssignments(this.state.userUuid);

  loadBankAccounts = () => getUserBankAccounts(this.state.userUuid);

  handleAdminPortfolioBoolean = (event) => {
    const { name, checked } = event.target;
    this.setState((state) => {
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          [name]: checked,
        },
      };
    });
    this.handleImmediateSave();
  };

  handleAdminProfileBoolean = (event) => {
    const { name, checked } = event.target;
    this.setState((state) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          [name]: checked,
        },
      };
    });
    this.handleImmediateSave();
  };

  handleAdminChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    //this.handleImmediateSave();
    this.handleAutoSave();
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    // From https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
    this.setState({
      [name]: value,
    });
    // this.setState({ user: { ...this.state.user, [name]: value } });
    this.handleAutoSave();
  };

  handleNestedChange = (user) => {
    this.setState((state) => {
      return {
        ...state,
        ...user,
      };
    });
  };

  handleContactChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => {
      return {
        ...state,
        contact: {
          ...state.contact,
          [name]: value,
        },
      };
    });
    this.handleAutoSave();
  };

  handlePortfolioChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => {
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          [name]: value,
        },
      };
    });
    this.handleAutoSave();
  };

  handlePortfolioEnumChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => {
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          [name]: value.id,
        },
      };
    });
    this.handleAutoSave();
  };

  handleEnumSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value.id,
    });
    this.handleImmediateSave();
  };

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.handleAutoSave();
  };

  handleProfileChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          [name]: value,
        },
        websiteValid: true,
      };
    });
    this.handleAutoSave();
  };

  handleSocialChange = (event, prefix, removeBaseUrl = true) => {
    const { name, value } = event.target;
    // const link =
    //   (removeBaseUrl
    //     ? value && value.replace(prefix, '')
    //     : value && addHttpPrefix(value, prefix)) || '';

    this.setState({ ...this.state, errors: {}, social: { ...this.state.social, [name]: value } });
    // this.setState((state) => ((state.social[name] = link), state));
    this.handleAutoSave();
  };

  handleResponse = (response, completionCallback) => {
    if (response) {
      this.setState(
        {
          ...response.data,
          language: response.data?.portfolio?.languages?.[0] || null,
          ...(!response.data?.portfolio && {
            portfolio: { orderedSkills: [] },
          }),
        },
        () => {
          this.setState({ hasInitLoad: true });
          completionCallback?.();
        }
      );
    }
  };

  handleUploadResponse = (data) => {
    const portfolio = {
      ...this.state.portfolio,
      resume: data.portfolio.resume,
    };
    this.setState({ portfolio });
  };

  handleImmediateSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 0);
  };

  handleProcessResume = async () => {
    try {
      this.setState({ isProcessing: true });
      const response = await processUserResume(this.state.userUuid);
      this.handleResponse(response, () =>
        this.setState((prevState) => ({ renderKey: prevState.renderKey + 1 }))
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isProcessing: false });
    }
  };

  handleAutoSave = () => {
    clearTimeout(this.autoSaveTimeout);
    this.autoSaveTimeout = setTimeout(() => this.handleSubmit(false), 2000);
  };

  validate = () => {
    const { social } = this.state;
    const errors = {};

    if (social?.website && !validateUrl(social.website)) {
      errors.website = 'Enter a valid URL';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  handleSubmit = (storeResponse = true) => {
    const {
      firstName,
      lastName,
      username,
      profile,
      social,
      language,
      notes,
      status,
      defaultSite,
      talentSettings,
    } = this.state;
    const portfolio = { ...this.state.portfolio };

    // if (social && social.website) {
    //   this.setState({ websiteValid: validateUrl(social.website) });
    // }

    if (this.validate()) {
      delete profile.timezone;
      delete portfolio.skills;
      delete portfolio.requestHidden;

      this.setState({ isSaving: true });

      let primarySkill;
      if (portfolio.primarySkill) {
        primarySkill = portfolio.primarySkill.id;
      }

      if (portfolio.resume) {
        delete portfolio.resume;
      }
      // We do this to ignore this skills for now, when we move all to portoflio read/write then we can leave
      const data = {
        firstName,
        lastName,
        username,
        profile,
        social,
        defaultSite,
        talentSettings,
        portfolio: {
          ...portfolio,
          primarySkill,
          currentLocation: portfolio.currentLocation ? portfolio.currentLocation.placeId : null,
          orderedSkills: portfolio.orderedSkills
            ? portfolio.orderedSkills.map((obj) => obj.id)
            : [],
        },
        notes,
        status,
      };

      if (!(this.props.currentUser && isAdmin(this.props.currentUser))) {
        delete data.username;
      }

      if (language) {
        data.portfolio.languages = [language.id];
      }

      putUser(this.state.userUuid, data).then(
        (response) => {
          if (storeResponse) {
            this.handleResponse(response);
          }
          this.setState({ isSaving: false });
        },
        (error) => {
          this.setState({ isSaving: false });
        }
      );
    }
  };

  handleCreateSkill = (value) => {
    const data = { label: value, adminGenerated: true };
    postSkill(data).then((response) => {
      this.setState((state) => {
        return {
          ...state,
          portfolio: {
            ...state.portfolio,
            orderedSkills: state.portfolio.orderedSkills.concat([response.data]),
          },
        };
      });
    });
  };

  handleAcceptChanges = () => {
    this.setState({ isAcceptingChanges: true });
    postUserAcceptChanges(this.state.userUuid, {}).then(
      (response) => {
        this.setState({ isAcceptingChanges: false });
      },
      (error) => {
        this.setState({
          isAcceptingChanges: false,
        });
      }
    );
  };

  addSocialLink(name, value, prefix) {
    const link = value ? addHttpPrefix(value, prefix) : '';
    //this.setState((state) => ((state.social[name] = link), state));
    this.setState((state) => {
      return {
        ...state,
        social: {
          ...state.social,
          [name]: link,
        },
      };
    });
  }

  handleLocationChange = (option) => {
    const country = option.terms ? option.terms[option.terms.length - 1].value : null;
    const data = {
      place_id: option.placeId,
      description: option.description,
      country: `${country}`,
      data: option,
    };

    if (option.action === 'remove-value' || option.action === 'clear') {
      this.setState((state) => {
        return {
          ...state,
          portfolio: {
            ...state.portfolio,
            currentLocation: null,
          },
        };
      });
    } else if (option.action === 'select-option') {
      postLocations(data).then((response) => {
        this.setState((state) => {
          return {
            ...state,
            portfolio: {
              ...state.portfolio,
              currentLocation: option,
            },
          };
        });
        this.handleAutoSave();
      });
    }
  };

  onLocationLoadOptions = async (search, loadedOptions, { page }) => {
    const response = await getCities(search);
    return {
      options: response.data.predictions,
    };
  };

  onLogoLoadOptions = async (search, loadedOptions, { page }) => {
    const response = await getLogos();
    return {
      options: response.data.predictions,
    };
  };

  navigateTabLocation = (tab) => {
    this.setState({ currentTab: tab }, () => {
      const { history, location } = this.props;
      history.push(`${location.pathname}#${tab}`);
    });
  };

  handleResumeViewChange = (event) => {
    const { checked } = event.target;
    localStorage.setItem('showResumes', checked);
    this.setState({ showResumes: checked });
  };

  handleFlagMessages = (event) => {
    const { checked } = event.target;
    this.setState({
      profile: {
        ...this.state.profile,
        flagMessages: checked,
      },
    });
    this.handleImmediateSave();
  };

  handleTalentSettingChange = (event) => {
    const { checked, name } = event.target;
    this.setState({
      talentSettings: {
        ...this.state.profile,
        [name]: checked,
      },
    });
    this.handleImmediateSave();
  };

  render() {
    const adminPermission = this.props.currentUser.adminPermission;

    return (
      <AppLayout
        location={this.props.location}
        roles={[
          Role.ADMIN.id,
          Role.ACCOUNT_MANAGER.id,
          Role.TALENT_SEARCH_ADMIN.id,
          Role.TALENT_SEARCH_TALENT_RATES.id,
          Role.TALENT_SEARCH_BRAND_RATES.id,
          Role.TALENT_REVIEW.id,
          Role.TALENT_REVIEW_LIMITED.id,
          Role.ADMIN.id,
        ]}
        permissions={adminPermission?.talent}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/profiles'>
              {`Profile Review`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            <div className={PageStyles.headerActive}>{this.state.name}</div>

            {this.state.roles?.includes(Role.TALENT.id) && (
              <div className={PageStyles.headerAction}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Toggle
                    id='show-resumes'
                    name='showResumes'
                    defaultChecked={this.state.showResumes}
                    onChange={this.handleResumeViewChange}
                  />
                  <label
                    htmlFor='show-resumes'
                    className={Styles.toggleLabel}>{`Show Resume`}</label>
                </div>
              </div>
            )}
          </div>
        }>
        {this.state.hasInitLoad && (
          <Fragment>
            <div key={this.state.renderKey} style={{ display: 'flex' }}>
              {this.state.showResumes && this.state.portfolio && this.state.portfolio.resume ? (
                <div className={PageStyles.sideContainer}>
                  <FileViewer
                    className={Styles.resumeImage}
                    title={this.state.portfolio.resume}
                    url={this.state.portfolio.resume}
                  />
                </div>
              ) : (
                ''
              )}
              <div className={PageStyles.container}>
                <div
                  className={classNames(
                    PageStyles.adminContainer,
                    Styles.adminFormMobile,
                    this.state.adminMin && Styles.adminMin
                  )}
                  data-test-id='admin-settings-form'>
                  <div className={classNames(Styles.row, Styles.topItem)}>
                    <h3 className={PageStyles.headline}>{`Admin Settings`}</h3>

                    <Fragment>
                      <Button
                        data-test-id='update-resume-button'
                        type={Button.Type.BLUE}
                        onClick={this.handleAcceptChanges}
                        isLoading={this.state.isAcceptingChanges}>
                        {`Update Resume`}
                      </Button>
                    </Fragment>

                    <Button
                      data-test-id='save-profile-button'
                      name='submit'
                      type={Button.Type.BLUE}
                      onClick={this.handleImmediateSave}
                      isLoading={this.state.isSaving}>
                      {`Save Profile`}
                    </Button>
                    {/*<Button
                          className={Styles.btnHideNotes}
                          style={{ marginLeft: '10px', height: '38px' }}
                          type={Button.Type.BLUE}
                          onClick={() => {
                            this.setState({ adminMin: !this.state.adminMin });
                          }}>
                          {this.state.adminMin ? `Show Notes` : `Hide Notes`}
                        </Button>*/}
                  </div>
                  {this.state.portfolio && (
                    <div style={{ maxWidth: '100%' }}>
                      <div className={classNames(Styles.rowSpaceBetween)}>
                        <div className={Styles.adminToggle}>
                          <InputContainer
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginRight: '20px',
                            }}>
                            <Toggle
                              id='request-private'
                              name='requestHidden'
                              defaultChecked={this.state.portfolio.requestHidden}
                              disabled
                            />
                            <label
                              htmlFor='request-private'
                              className={Styles.toggleLabel}>{`User Requested Private`}</label>
                          </InputContainer>
                          {this.state.roles?.includes(Role.TALENT.id) && (
                            <InputContainer
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginRight: '20px',
                              }}>
                              <Toggle
                                id='set-private'
                                name='isHidden'
                                defaultChecked={this.state.portfolio.isHidden}
                                onChange={this.handleAdminPortfolioBoolean}
                              />
                              <label
                                htmlFor='set-private'
                                className={Styles.toggleLabel}>{`Set Resume to Private`}</label>
                            </InputContainer>
                          )}

                          {this.props.currentUser &&
                            isTalentAdminNotLimited(this.props.currentUser) && (
                              <InputContainer
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <Toggle
                                    id='talent-review'
                                    name='talentReview'
                                    defaultChecked={this.state.profile.talentReview}
                                    onChange={this.handleAdminProfileBoolean}
                                  />
                                  <label
                                    htmlFor='talent-review'
                                    className={Styles.toggleLabel}>{`Enable Talent Review`}</label>
                                </div>
                              </InputContainer>
                            )}
                        </div>
                        <InputContainer
                          className={classNames(Styles.adminStatus)}
                          style={{ width: '344px' }}>
                          <Select
                            className={PageStyles.select}
                            defaultValue={UserStatus.getEnum(this.state.status)}
                            classNamePrefix='s-contact'
                            name='status'
                            label='Status'
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                            options={UserStatus.values}
                            onChange={this.handleEnumSelectChange}
                          />
                        </InputContainer>
                      </div>

                      {!this.state.adminMin && (
                        <div className={Styles.notes}>
                          <Quill
                            name='notes'
                            label='Notes'
                            placeholder='Review Notes'
                            defaultValue={this.state.notes}
                            onChange={this.handleAdminChange}
                            legacyCompat
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <Tab.Container
                  defaultActiveKey='profile'
                  activeKey={this.state.currentTab}
                  mountOnEnter={true}>
                  <Nav variant='tabs' className={classNames(Styles.row, Styles.headlineRow)}>
                    <Nav.Item>
                      <Nav.Link
                        eventKey='profile'
                        onClick={() => {
                          this.navigateTabLocation('profile');
                        }}>
                        <h3 className={classNames(PageStyles.headline)}>{`Profile`}</h3>
                      </Nav.Link>
                    </Nav.Item>

                    {this.state.roles?.includes(Role.TALENT.id) && (
                      <Fragment>
                        <Nav.Item>
                          <Nav.Link
                            eventKey='settings'
                            onClick={() => {
                              this.navigateTabLocation('settings');
                            }}>
                            <h3
                              data-test-id='settings-menu'
                              className={classNames(PageStyles.headline)}>{`Settings`}</h3>{' '}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey='schedule'
                            onClick={() => {
                              this.navigateTabLocation('schedule');
                            }}>
                            <h3 className={classNames(PageStyles.headline)}>{`Schedule`}</h3>{' '}
                          </Nav.Link>
                        </Nav.Item>

                        {this.props.currentUser &&
                          (isAdmin(this.props.currentUser) ||
                            adminPermission?.talentBilling ||
                            adminPermission?.talentTalentRate ||
                            adminPermission?.talentClientRate) && (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey='payments'
                                  onClick={() => {
                                    this.navigateTabLocation('payments');
                                  }}>
                                  <h3 className={classNames(PageStyles.headline)}>{`Payments`}</h3>{' '}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey='clients'
                                  onClick={() => {
                                    this.navigateTabLocation('clients');
                                  }}>
                                  <h3 className={classNames(PageStyles.headline)}>{`Clients`}</h3>{' '}
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                      </Fragment>
                    )}
                    {this.state.roles?.includes(Role.TALENT.id) && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey='timetracker'
                          onClick={() => {
                            this.navigateTabLocation('timetracker');
                          }}>
                          <h3 className={classNames(PageStyles.headline)}>{`Timetracker`}</h3>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                  <Tab.Content className={PageStyles.tabContent}>
                    <Tab.Pane eventKey='profile' mountOnEnter unmountOnExit>
                      <div>
                        <Card>
                          <Card.Header>
                            {`Profile`}

                            {this.state.roles?.includes(Role.TALENT.id) && (
                              <div className={Styles.sideLinks}>
                                {this.state.portfolio && (
                                  <a
                                    className={Styles.sideLinkItem}
                                    href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.portfolio?.accessKey}`}
                                    style={{ marginRight: '10px' }}
                                    target='_blank'>
                                    {`Private Resume`}
                                  </a>
                                )}
                                {` | `}
                                {this.state.portfolio && (
                                  <a
                                    className={Styles.sideLinkItem}
                                    href={`${process.env.MARKETING_ENDPOINT}/talent/resumes/${this.state.portfolio?.accessKey}`}
                                    style={{ marginRight: '10px', marginLeft: '10px' }}
                                    target='_blank'>
                                    {`Obfuscated Resume`}
                                  </a>
                                )}
                                {` | `}
                                <a
                                  className={Styles.sideLinkItem}
                                  href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.username}`}
                                  style={{ marginRight: '10px', marginLeft: '10px' }}
                                  target='_blank'>
                                  {`Public Resume`}
                                </a>
                                {` | `}
                                {this.state.portfolio?.resume ? (
                                  <a
                                    className={Styles.sideLinkItem}
                                    href={this.state.portfolio.resume}
                                    style={{ marginLeft: '10px' }}
                                    target='_blank'>
                                    {`Download Resume`}
                                  </a>
                                ) : (
                                  <span
                                    className={Styles.sideLinkItem}
                                    style={{
                                      marginLeft: '10px',
                                    }}>{`No Resume on File`}</span>
                                )}
                              </div>
                            )}
                          </Card.Header>
                          <Card.Item>
                            <div className={Styles.row}>
                              <div className={Styles.photo}>
                                <ImageDrop
                                  className={Styles.photo}
                                  name='picture'
                                  label='Picture'
                                  server={`admin/users/${this.state.userUuid}/`}
                                  idleLabel='Update Picture'
                                  value={this.state.picture}
                                  onResponse={this.handleResponse}
                                />
                              </div>
                              <div style={{ flexGrow: 1 }}>
                                <InputGroup>
                                  {this.props.currentUser && (
                                    <InputContainer>
                                      <TextInput
                                        name='username'
                                        label='Username'
                                        disabled={!isTalentAdminNotLimited(this.props.currentUser)}
                                        defaultValue={this.state.username || ''}
                                        onChange={this.handleChange}
                                      />
                                    </InputContainer>
                                  )}
                                </InputGroup>
                                <InputGroup>
                                  <InputContainer>
                                    <TextInput
                                      name='firstName'
                                      label='First Name'
                                      defaultValue={this.state.firstName || ''}
                                      onChange={this.handleChange}
                                    />
                                  </InputContainer>
                                  <InputContainer>
                                    <TextInput
                                      name='lastName'
                                      label='Last Name'
                                      defaultValue={this.state.lastName || ''}
                                      onChange={this.handleChange}
                                    />
                                  </InputContainer>
                                </InputGroup>
                                {(isTalentAdminNotLimited(this.props.currentUser) ||
                                  this.props.currentUser.talent) && (
                                  <InputGroup>
                                    <InputContainer>
                                      <TextInput
                                        name='email'
                                        label='Email Address'
                                        defaultValue={this.state.email || ''}
                                        disabled
                                        onChange={this.handleChange}
                                      />
                                    </InputContainer>
                                    <InputContainer>
                                      <TextInput
                                        name='phone'
                                        label='Phone'
                                        defaultValue={this.state.contact.phoneNumber || ''}
                                        disabled
                                      />
                                    </InputContainer>
                                  </InputGroup>
                                )}
                              </div>
                            </div>

                            <InputGroup>
                              <InputContainer>
                                {this.state.profile && (
                                  <TextInput
                                    name='headline'
                                    label='Headline'
                                    defaultValue={this.state.profile.headline || ''}
                                    onChange={this.handleProfileChange}
                                  />
                                )}
                              </InputContainer>
                            </InputGroup>

                            {this.state.roles?.includes(Role.TALENT.id) && (
                              <Fragment>
                                <InputGroup>
                                  <InputContainer>
                                    {this.state.portfolio && (
                                      <Select
                                        data-test-id='primarySkills'
                                        className={PageStyles.select}
                                        defaultValue={this.state.portfolio.primarySkill}
                                        classNamePrefix='s-contact'
                                        name='primarySkill'
                                        label='Primary Skill'
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ id }) => id}
                                        options={this.state.skills}
                                        onChange={this.handlePortfolioChange}
                                        isPaginated
                                        loadOptions={onSkillsLoadOptions}
                                      />
                                    )}
                                  </InputContainer>
                                  {this.state.logoList && (
                                    <InputContainer>
                                      <Select
                                        data-test-id='workedAt'
                                        className={PageStyles.select}
                                        defaultValue={this.state.logoList?.find((e) => {
                                          return e.id === this.state.portfolio?.workedAtName;
                                        })}
                                        classNamePrefix='s-contact'
                                        name='workedAtName'
                                        label='Last Worked At'
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ id }) => id}
                                        options={this.state.logoList}
                                        onChange={this.handlePortfolioEnumChange}
                                      />
                                    </InputContainer>
                                  )}
                                </InputGroup>
                                <InputGroup>
                                  <InputContainer>
                                    <TextArea
                                      name='description'
                                      label='Description'
                                      placeholder='Add 2-3 sentences for why the user would be a good fit for their specialisation'
                                      defaultValue={this.state.profile?.description || ''}
                                      onChange={this.handleProfileChange}
                                    />
                                  </InputContainer>
                                </InputGroup>

                                <InputGroup>
                                  <InputContainer>
                                    <SortableSelect
                                      className={PageStyles.select}
                                      value={this.state.portfolio.orderedSkills}
                                      name='orderedSkills'
                                      label='Skills'
                                      isClearable={false}
                                      getOptionLabel={({ label }) => label}
                                      getOptionValue={({ id }) => id}
                                      options={this.state.skills}
                                      isMulti
                                      onChange={this.handlePortfolioChange}
                                      isPaginated
                                      isCreatable
                                      onCreateOption={this.handleCreateSkill}
                                      loadOptions={onSkillsLoadOptions}
                                    />
                                  </InputContainer>
                                </InputGroup>
                                <InputGroup>
                                  <InputContainer className={classNames(Styles.fileInputContainer)}>
                                    <SingleUpload
                                      name='portfolio.resume'
                                      label='Resume'
                                      fileUploadRequest={(data, conf) =>
                                        patchUser(this.state.userUuid, data, conf, true)
                                      }
                                      fileRemoveRequest={() => {
                                        const data = new FormData();
                                        data.append('portfolio.resume', '');
                                        return patchUser(this.state.userUuid, data, {}, true);
                                      }}
                                      // apiFileUpload={`admin/users/${this.state.userUuid}/`}
                                      // onFileRemovePayloadGenerate={(key) => {
                                      //   const formData = new FormData();
                                      //   formData.append(key, '');
                                      //   return formData;
                                      // }}
                                      idleLabel='Upload'
                                      // method='patch'
                                      value={this.state.portfolio?.resume}
                                      onResponse={this.handleUploadResponse}
                                      hideRemove={false}
                                    />
                                  </InputContainer>
                                </InputGroup>
                              </Fragment>
                            )}

                            <InputGroup>
                              <InputContainer>
                                <Select
                                  data-test-id='location'
                                  className={PageStyles.select}
                                  defaultValue={this.state.portfolio.currentLocation}
                                  name='currentLocation'
                                  label='Location'
                                  isClearable
                                  isSavingMessage={() => 'Searching...'}
                                  noOptionsMessage={() => 'Start typing to search for a location'}
                                  getOptionLabel={({ description }) => description}
                                  getOptionValue={({ placeId }) => placeId}
                                  options={this.state.locations}
                                  onChange={this.handleLocationChange}
                                  isPaginated
                                  loadOptions={this.onLocationLoadOptions}
                                />
                              </InputContainer>
                              <InputContainer>
                                <TextInput
                                  name='lastImpression'
                                  label='Last IP Location'
                                  defaultValue={`${
                                    this.state.lastImpression?.city
                                      ? this.state.lastImpression?.city + ', '
                                      : ''
                                  }${this.state.lastImpression?.country}`}
                                  disabled
                                />
                              </InputContainer>
                            </InputGroup>

                            {this.state.social && (
                              <Fragment>
                                <InputGroup>
                                  <InputContainer>
                                    <TextInput
                                      name='website'
                                      label='Website'
                                      error={this.state.errors?.website}
                                      defaultValue={this.state.social.website || ''}
                                      onChange={(e) => this.handleSocialChange(e, 'http://', false)}
                                    />
                                  </InputContainer>

                                  <InputContainer>
                                    <Select
                                      data-test-id='language'
                                      className={PageStyles.select}
                                      defaultValue={this.state.language}
                                      classNamePrefix='s-contact'
                                      name='language'
                                      label='Language'
                                      getOptionLabel={({ name }) => name}
                                      getOptionValue={({ id }) => id}
                                      options={languageList}
                                      onChange={this.handleSelectChange}
                                    />
                                  </InputContainer>
                                </InputGroup>

                                <InputGroup>
                                  <InputContainer>
                                    <TextInput
                                      name='linkedin'
                                      label='Linkedin'
                                      value={addHttpPrefix(
                                        this.state.social.linkedin,
                                        socialPrefixes.linkedin
                                      )}
                                      onChange={(e) =>
                                        this.handleSocialChange(e, socialPrefixes.linkedin)
                                      }
                                    />
                                  </InputContainer>

                                  <InputContainer>
                                    <TextInput
                                      name='twitter'
                                      label='Twitter'
                                      value={addHttpPrefix(
                                        this.state.social.twitter,
                                        socialPrefixes.twitter
                                      )}
                                      onChange={(e) =>
                                        this.handleSocialChange(e, socialPrefixes.twitter)
                                      }
                                    />
                                  </InputContainer>
                                </InputGroup>
                                <InputGroup>
                                  <InputContainer>
                                    <TextInput
                                      name='facebook'
                                      label='Facebook'
                                      value={addHttpPrefix(
                                        this.state.social.facebook,
                                        socialPrefixes.facebook
                                      )}
                                      onChange={(e) =>
                                        this.handleSocialChange(e, socialPrefixes.facebook)
                                      }
                                    />
                                  </InputContainer>
                                  <InputContainer>
                                    <TextInput
                                      name='instagram'
                                      label='Instagram'
                                      value={addHttpPrefix(
                                        this.state.social.instagram,
                                        socialPrefixes.instagram
                                      )}
                                      onChange={(e) =>
                                        this.handleSocialChange(e, socialPrefixes.instagram)
                                      }
                                    />
                                  </InputContainer>
                                </InputGroup>
                              </Fragment>
                            )}
                          </Card.Item>
                          <Card.Footer right sticky>
                            <Button
                              name='submit'
                              type={Button.Type.LIGHT_GRAY}
                              onClick={this.handleProcessResume}
                              isLoading={this.state.isProcessing}
                              disabled={!this.state.portfolio?.resume || this.state.isProcessing}>
                              {`Extract missing data from resume`}
                            </Button>
                            <Button
                              name='submit'
                              type={Button.Type.BLUE}
                              onClick={this.handleImmediateSave}
                              isLoading={this.state.isSaving}>
                              {`Save Profile`}
                            </Button>
                          </Card.Footer>
                        </Card>

                        {this.state.roles?.includes(Role.TALENT.id) && (
                          <Fragment>
                            <Employment user={this.state} />
                            <Education user={this.state} />
                            {/*<PortfolioList
                              parentUrl={`admin/users/${this.state.userUuid}`}
                              creator={this.state}
                            />*/}
                            <PortfolioList
                              parentUrl={`admin/users/${this.state.userUuid}`}
                              type={PortfolioList.Type.USER}
                              creator={this.state}
                              resourceUuid={this.state.userUuid}
                            />
                          </Fragment>
                        )}
                      </div>
                    </Tab.Pane>

                    {this.state.roles?.includes(Role.TALENT.id) && (
                      <Fragment>
                        <Tab.Pane eventKey='settings' mountOnEnter unmountOnExit>
                          {(isTalentAdminNotLimited(this.props.currentUser) ||
                            adminPermission?.talent) && (
                            <ProfileContact
                              userUuid={this.state.userUuid}
                              user={this.state}
                              onUserUpdate={this.handleNestedChange}
                            />
                          )}
                          {(isAnyTalentAdmin(this.props.currentUser) ||
                            adminPermission?.talentScore) && (
                            <ScoreList userUuid={this.state.userUuid} />
                          )}

                          {(isAdmin(this.props.currentUser) || adminPermission?.talent) && (
                            <UserEmailAddresses userUuid={this.state.userUuid} />
                          )}

                          {(isAdmin(this.props.currentUser) || adminPermission?.talent) && (
                            <UserSettings
                              flagMessages={this.state.profile.flagMessages}
                              defaultSite={this.state.defaultSite}
                              talentSettings={this.state.talentSettings}
                              onFlagMessageChange={this.handleFlagMessages}
                              onDefaultSiteChange={this.handleEnumSelectChange}
                              onTalentSettingChange={this.handleTalentSettingChange}
                              onSaveSetting={this.handleImmediateSave}
                              isBusy={this.state.isSaving}
                            />
                          )}
                          <BoldAgreementList
                            resource='users'
                            resourceUuId={this.state.userUuid}
                            currentUser={this.props.currentUser}
                          />
                          <AgreementList
                            resource='users'
                            resourceUuId={this.state.userUuid}
                            currentUser={this.props.currentUser}
                          />

                          {this.props.currentUser &&
                            (isAdmin(this.props.currentUser) || adminPermission?.talentScore) && (
                              <Fragment>
                                {this.state.ratingsLoaded ? (
                                  <RatingList
                                    user={this.state}
                                    ratings={this.state.ratings}
                                    assignments={this.state.assignments}
                                  />
                                ) : (
                                  ''
                                )}
                              </Fragment>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey='schedule' mountOnEnter unmountOnExit>
                          {this.state.hasInitLoad && (
                            <WeeklyBusySchedule
                              postUserDailyBusyScheduleSet={(p) =>
                                postUserDailyBusyScheduleSet(this.state.userUuid, p)
                              }
                              getUserDailyBusySchedule={() =>
                                getUserDailyBusySchedule(this.state.userUuid)
                              }
                              userUuid={this.state.userUuid}
                            />
                          )}
                          <MonthlyBusySchedule
                            user={this.state}
                            isAdmin={true}
                            postUserMonthlyBusyScheduleSet={postUserMonthlyBusyScheduleSet}
                            getUserMonthlyBusySchedule={getUserMonthlyBusySchedule}
                          />
                        </Tab.Pane>

                        {this.props.currentUser &&
                          (isAdmin(this.props.currentUser) ||
                            adminPermission?.talentBilling ||
                            adminPermission?.talentTalentRate ||
                            adminPermission?.talentClientRate) && (
                            <Tab.Pane eventKey='payments' mountOnEnter unmountOnExit>
                              {(isAdmin(this.props.currentUser) ||
                                adminPermission?.talentBilling) && (
                                <>
                                  <PaymentSettings
                                    userUuid={this.state.userUuid}
                                    data={{
                                      ...this.state.payment,
                                    }}
                                  />
                                  <PaymentDetails
                                    userUuid={this.state.userUuid}
                                    data={{
                                      ...this.state.contact,
                                      ...this.state.address,
                                    }}
                                    onUserUpdate={this.handleNestedChange}
                                  />
                                </>
                              )}
                              <Fragment>
                                {isNewRates
                                  ? (isAdmin(this.props.currentUser) ||
                                      adminPermission?.talentTalentRate) && (
                                      <TalentRateList
                                        type={TalentRateList.Type.ADMIN}
                                        apiManager={new RatesApiManager(authRequest)}
                                        resourceUuid={this.state.userUuid}
                                        resource={`users`}
                                        talentPermission={
                                          adminPermission?.talentTalentRate ||
                                          isAdmin(this.props.currentUser)
                                        }
                                        userSkills={
                                          this.state.portfolio && this.state.portfolio.orderedSkills
                                        }
                                      />
                                    )
                                  : (isAdmin(this.props.currentUser) ||
                                      adminPermission?.talentTalentRate ||
                                      adminPermission?.talentClientRate) && (
                                      <ServiceRateList
                                        clientPermission={
                                          adminPermission?.talentClientRate ||
                                          isAdmin(this.props.currentUser)
                                        }
                                        talentPermission={
                                          adminPermission?.talentTalentRate ||
                                          isAdmin(this.props.currentUser)
                                        }
                                        resourceUuid={this.state.userUuid}
                                        userSkills={
                                          this.state.portfolio && this.state.portfolio.orderedSkills
                                        }
                                        resource={`users`}
                                      />
                                    )}

                                {isAdmin(this.props.currentUser) ||
                                adminPermission?.talentBilling ? (
                                  <BankAccountList
                                    apiManager={
                                      new BankApiManager(authRequest, this.state.userUuid, {
                                        resourcePrefix: 'admin',
                                      })
                                    }
                                    oldApiManager={
                                      new BankApiManager(authRequest, this.state.userUuid, {
                                        resourcePrefix: 'admin',
                                        subResource: 'bank-accounts',
                                      })
                                    }
                                    validator={new BankValidator(request)}
                                    showWiseId
                                  />
                                ) : (
                                  ''
                                )}
                              </Fragment>

                              {this.props.currentUser &&
                                (isAdmin(this.props.currentUser) ||
                                  adminPermission?.talentTalentRate) && (
                                  <>
                                    <TransferList userUuid={this.state.userUuid} />
                                    <InvoiceList
                                      resourceUuid={this.state.userUuid}
                                      resource={`users`}
                                    />
                                  </>
                                )}
                            </Tab.Pane>
                          )}

                        {this.props.currentUser && (
                          <Tab.Pane eventKey='clients' mountOnEnter unmountOnExit>
                            <UserOrgList userUuid={this.state.userUuid} />
                            {(isAdmin(this.props.currentUser) ||
                              adminPermission?.talentTalentRate ||
                              adminPermission?.talentClientRate) && (
                              <>
                                <UserInvitations
                                  talentPermission={
                                    adminPermission?.talentTalentRate ||
                                    isAdmin(this.props.currentUser)
                                  }
                                  clientPermission={
                                    adminPermission?.talentClientRate ||
                                    isAdmin(this.props.currentUser)
                                  }
                                  userUuid={this.state.userUuid}
                                  currentUser={this.props.currentUser}
                                />
                                <UserApplications
                                  talentPermission={
                                    adminPermission?.talentTalentRate ||
                                    isAdmin(this.props.currentUser)
                                  }
                                  clientPermission={
                                    adminPermission?.talentClientRate ||
                                    isAdmin(this.props.currentUser)
                                  }
                                  userUuid={this.state.userUuid}
                                  currentUser={this.props.currentUser}
                                />
                                <UserAssignments
                                  talentPermission={
                                    adminPermission?.talentTalentRate ||
                                    isAdmin(this.props.currentUser)
                                  }
                                  clientPermission={
                                    adminPermission?.talentClientRate ||
                                    isAdmin(this.props.currentUser)
                                  }
                                  userUuid={this.state.userUuid}
                                  currentUser={this.props.currentUser}
                                />
                              </>
                            )}
                          </Tab.Pane>
                        )}
                      </Fragment>
                    )}

                    <Tab.Pane eventKey='timetracker' mountOnEnter unmountOnExit>
                      <TimetrackerSettings
                        talentSettings={this.state.talentSettings}
                        onTalentSettingChange={this.handleTalentSettingChange}
                        onSaveSetting={this.handleImmediateSave}
                        isBusy={this.state.isSaving}
                      />
                      {this.state.userUuid && <UserPlatform userUuid={this.state.userUuid} />}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </Fragment>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default withUser(UserDetail);
