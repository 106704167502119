import { authRequest } from './utils';

export const getAdmins = async (config = undefined) => {
  const url = `admin/admins/`;
  return await authRequest.get(url, config);
};

export const getAdminPermissions = async (config = undefined) => {
  const url = `admin/permissions/`;
  return await authRequest.get(url, config);
};
export const getAdminPermission = async (id, config = undefined) => {
  const url = `admin/permissions/${id}/`;
  return await authRequest.patch(url, config);
};

export const updateAdminPermission = async (id, config = undefined) => {
  const url = `admin/permissions/${id}/`;
  return await authRequest.patch(url, config);
};

export const getAdmin = async (uuid, config = undefined) => {
  const url = `admin/admins/${uuid}/`;
  return await authRequest.get(url, config);
};

export const patchAdmin = async (uuid, data, config) => {
  const url = `admin/admins/${uuid}/`;
  return await authRequest.patch(url, data, config);
};

export const putAdmin = async (uuid, data, config) => {
  const url = `admin/admins/${uuid}/`;
  return await authRequest.put(url, data, config);
};

export const getAdminOrgList = async (uuid, config = undefined) => {
  const url = `admin/admins/${uuid}/list/orgs/Organization/`;
  return await authRequest.get(url, config);
};

export const getAdminBriefList = async (uuid, config = undefined) => {
  const url = `admin/admins/${uuid}/list/engagements/Brief/`;
  return await authRequest.get(url, config);
};

export const getAdminTeamList = async (uuid, config = undefined) => {
  const url = `admin/admins/${uuid}/list/teams/Team/`;
  return await authRequest.get(url, config);
};

export const getAdminUserList = async (uuid, config = undefined) => {
  const url = `admin/admins/${uuid}/list/users/User/`;
  return await authRequest.get(url, config);
};

export const getAdminProjectList = async (uuid, config = undefined) => {
  const url = `admin/admins/${uuid}/list/engagements/Project/`;
  return await authRequest.get(url, config);
};

export const getAdminEngagementAssignmentList = async (uuid, config = undefined) => {
  const url = `admin/admins/${uuid}/list/engagements/Assignment/`;
  return await authRequest.get(url, config);
};

export const removeAdminOrg = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/remove/orgs/Organization/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const removeAdminBrief = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/remove/engagements/Brief/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const removeAdminTeam = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/remove/teams/Team/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const removeAdminUser = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/remove/users/User/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const removeAdminProject = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/remove/engagements/Project/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const removeAdminEngagementAssignment = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/remove/engagements/Assignment/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const addAdminOrg = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/add/orgs/Organization/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const addAdminBrief = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/add/engagements/Brief/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const addAdminTeam = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/add/teams/Team/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const addAdminUser = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/add/users/User/${itemUuid}/`;
  return await authRequest.put(url, config);
};

export const addAdminProject = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/add/engagements/Project/${itemUuid}/`;
  return await authRequest.put(url, config);
};
export const addAdminEngagementAssignment = async (uuid, itemUuid, config = undefined) => {
  const url = `admin/admins/${uuid}/add/engagements/Assignment/${itemUuid}/`;
  return await authRequest.put(url, config);
};
