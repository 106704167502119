import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import SelectMenu from '@hiredigital/ui/SelectMenu';
import {
  deleteEngagementApplication,
  deleteEngagementAssignment,
  deleteEngagementInvitation,
  getEngagement,
  postEngagementApplication,
  postEngagementInvitation,
  postEngagementInvitationGenerate,
  putEngagement,
} from '@apis/engagements';

import { EngagementStatus, RateType } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import {
  isBriefAdmin,
  isSalesAdmin,
  isPlacementAdmin,
  isAnyBriefAdmin,
} from '@helpers/permissions';

import Styles from '../list.module.scss';
import ListStyles from '@styles/PageList.module.scss';

import ApplicationListItem from './ApplicationListItem';
import InvitationListItem from './InvitationListItem';
import AssignmentListItem from './AssignmentListItem';

import Table from '@components/Table/Table';

import Button from '@hiredigital/ui/Button';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

class EngagementListItem extends Component {
  static propTypes = {
    engagement: PropTypes.object,
    briefUuid: PropTypes.string,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
      invitations: [],
      assignments: [],
      applications: [],
      ...this.props.engagement,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.engagement.uuid !== this.props.engagement.uuid ||
      nextProps.engagement.status !== this.props.engagement.status
    ) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  handleSelectChange = (value, name) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.saveEngagement();
      }
    );
  };

  handleGetEngagementDetails = () => {
    this.setState({ toggle: !this.state.toggle });
    getEngagement(this.props.engagement.uuid).then(
      (response) => {
        this.setState({ ...response.data, loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleAddInvitation = () => {
    postEngagementInvitation(this.props.engagement.uuid).then(
      (response) => {
        response.data.new = true;
        this.setState((state) => (state.invitations.push(response.data), state));
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleDeleteInvitation = (event, value) => {
    deleteEngagementInvitation(this.props.engagement.uuid, value).then(
      (response) => {
        // const inv = this.state.invitations.findIndex((inv) => inv.id === value);
        // this.setState((state) => (this.state.invitations.splice(inv, 1), state));
        const invitations = this.state.invitations?.filter((v) => v?.id !== value) || [];
        this.setState({ invitations });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleDeleteApplication = (event, value) => {
    deleteEngagementApplication(this.props.engagement.uuid, value).then(
      (response) => {
        // const inv = this.state.applications.findIndex((a) => a.id === value);
        // this.setState((state) => (this.state.applications.splice(inv, 1), state));
        const applications = this.state.applications?.filter((v) => v?.uuid !== value) || [];
        this.setState({ applications });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleDeleteAssignment = (event, value) => {
    deleteEngagementAssignment(this.props.engagement.uuid, value).then(
      (response) => {
        // const inv = this.state.assignments.findIndex((a) => a.id === value);
        // this.setState((state) => (this.state.assignments.splice(inv, 1), state));
        const assignments = this.state.assignments?.filter((v) => v?.uuid !== value) || [];
        this.setState({ assignments });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleAddRecommendedInvitations = () => {
    postEngagementInvitationGenerate(this.props.engagement.uuid).then(
      (response) => {
        this.setState((state) => (state.invitations.push(...response.data.results), state));
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleAddApplication = () => {
    postEngagementApplication(this.props.engagement.uuid).then(
      (response) => {
        response.data.new = true;
        this.setState((state) => (state.applications.push(response.data), state));
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  saveEngagement = () => {
    this.setState({ submitted: true, loading: true });
    const data = {
      status: this.state.status,
    };

    putEngagement(this.props.engagement.uuid, data).then(
      (response) => {
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    if (this.state.status === EngagementStatus.CANCELLED.id) {
      return null;
    }

    return (
      <div className={Styles.rowContainer}>
        <div className={classNames(Styles.engagementRow)}>
          <div className={Styles.engagementTree} />
          <Table.Cell width='250px' className={ListStyles.cellRow}>
            <Link
              className={ListStyles.title}
              to={`/briefs/${this.props.briefUuid}#eng-${this.state.uuid}`}>
              {`Engagement`}
            </Link>

            {((this.state.assignmentCount ||
              this.state.applicationCount ||
              this.state.invitationCount) && (
              <div className={Styles.newItemCount} onClick={this.handleGetEngagementDetails}>
                {(this.state.assignmentCount && (
                  <div>{`${this.state.assignmentCount} Assignments`}</div>
                )) ||
                  (this.state.applicationCount && (
                    <div>{`${this.state.applicationCount} Applications`}</div>
                  )) ||
                  (this.state.invitationCount && (
                    <div>{`${this.state.invitationCount} Invitations`}</div>
                  )) ||
                  ''}
              </div>
            )) ||
              ''}
          </Table.Cell>
          <Table.Cell width='225px' className={ListStyles.cell}>
            {this.state.talentType || this.state.otherTalentType ? (
              <div className={Styles.engagementTitle}>
                {(this.state.talentType && this.state.talentType.label) ||
                  this.state.otherTalentType}
              </div>
            ) : (
              (this.state.format || this.state.otherFormat) && (
                <div className={Styles.engagementTitle}>
                  {this.state.formatQty}{' '}
                  {(this.state.format && this.state.format.label) || this.state.otherFormat}
                </div>
              )
            )}
          </Table.Cell>
          <Table.Cell width='480px' className={Styles.hoverParent}>
            <div className={Styles.hoverSection}>
              <div className={Styles.innerSection}>
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {`Required Skills: `}
                  </p>
                  <p className={Styles.content}>
                    {this.state.skills &&
                      this.state.skills.map((skill, index) => {
                        return (
                          <span key={index}>
                            {skill.label}
                            {index === this.state.skills.length - 1 ? `` : `, `}
                          </span>
                        );
                      })}
                  </p>
                </div>
                {this.state.estimatedStateDate || this.state.estimatedEndDate ? (
                  <div style={{ display: 'flex' }}>
                    <p className={Styles.title} style={{ marginRight: '10px' }}>
                      {`Dates: `}
                    </p>
                    <p className={Styles.content}>
                      {`${
                        this.state.estimatedStateDate
                          ? toReadableDate(this.state.estimatedStateDate)
                          : 'No Start Date'
                      } - ${
                        this.state.estimatedEndDate
                          ? toReadableDate(this.state.estimatedEndDate)
                          : 'No End Date'
                      }`}
                    </p>
                  </div>
                ) : (
                  ''
                )}

                {this.props.currentUser && isSalesAdmin(this.props.currentUser) && (
                  <Fragment>
                    <div style={{ display: 'flex' }}>
                      <p className={Styles.title} style={{ marginRight: '10px' }}>
                        {`Budget: `}
                      </p>
                      <p className={Styles.content}>
                        {`${this.state.estimatedBudget || '0.00'} ${this.state.currencyCode}`}
                      </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p className={Styles.title} style={{ marginRight: '10px' }}>
                        {isBriefAdmin(this.props.currentUser) ? `Client Rate: ` : `Rate: `}
                      </p>
                      <p className={Styles.content}>
                        {isNewRates
                          ? this.state.clientEngagementRate?.rateDisplayText || '-'
                          : `${this.state.estimatedBrandRate || '0.00'} ${
                              this.state.estimatedBrandRateType
                                ? RateType.getEnum(this.state.estimatedBrandRateType).label
                                : ''
                            }`}
                      </p>
                    </div>
                  </Fragment>
                )}

                {this.props.currentUser && isPlacementAdmin(this.props.currentUser) && (
                  <div style={{ display: 'flex' }}>
                    <p className={Styles.title} style={{ marginRight: '10px' }}>
                      {isBriefAdmin(this.props.currentUser) ? `Talent Rate: ` : `Rate: `}
                    </p>
                    <p className={Styles.content}>
                      {isNewRates
                        ? this.state.talentEngagementRate?.rateDisplayText || '-'
                        : `${this.state.estimatedTalentRate || '0.00'} ${
                            this.state.estimatedTalentRateType
                              ? RateType.getEnum(this.state.estimatedTalentRateType).label
                              : ''
                          }`}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Table.Cell>
          <Table.Cell width='220px'>
            {/* <Dropdown size='sm'>
              <Dropdown.Toggle className={ListStyles.selectDropdown}>
                <p>{EngagementStatus.getEnum(this.state.status).label}</p>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {EngagementStatus.values.map((status, statusIndex) => (
                  <Dropdown.Item
                    onClick={() => this.handleSelectChange(status.id, 'status')}
                    key={statusIndex}>
                    {status.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <SelectMenu
              defaultValue={EngagementStatus.getEnum(this.state.status)}
              onChange={(v) => this.handleSelectChange(v?.id, 'status')}
              options={EngagementStatus.values}
            />
          </Table.Cell>
          <Table.Cell width='380px' className={ListStyles.cellRow}>
            {this.state.invitationCount ||
            this.state.applicationCount ||
            this.state.assignmentCount ? (
              <Button
                className={Styles.button}
                type={Button.Type.BLUE_OUTLINE}
                onClick={this.handleGetEngagementDetails}
                isLoading={this.state.loading}>
                {`Toggle`}
              </Button>
            ) : (
              ''
            )}
          </Table.Cell>
          <div className={Styles.floatingAction}>
            {/* <Dropdown drop='left' size='sm'>
              <Dropdown.Toggle className={ListStyles.moreDropdown}>
                <IconMoreVertical className={ListStyles.moreIcon} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.props.currentUser && isAnyBriefAdmin(this.props.currentUser) && (
                  <Fragment>
                    <Dropdown.Item onClick={this.handleAddRecommendedInvitations}>
                      {`Generate Invitations`}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleAddInvitation}>
                      {`Add Invitation`}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleAddApplication}>
                      {`Add Application`}
                    </Dropdown.Item>
                    {this.state.status >= EngagementStatus.MATCH.id ? (
                      <Dropdown.Item
                        target='_blank'
                        href={`${process.env.APP_ENDPOINT}/briefs/${this.props.briefUuid}/engagements/`}>
                        {`View Engagement`}
                      </Dropdown.Item>
                    ) : (
                      ''
                    )}
                  </Fragment>
                )}
              </Dropdown.Menu>
            </Dropdown> */}
            <SplitButton
              more={[
                ...(this.props.currentUser && isAnyBriefAdmin(this.props.currentUser)
                  ? [
                      {
                        text: 'Generate Invitations',
                        onClick: this.handleAddRecommendedInvitations,
                      },
                      { text: 'Add Invitation', onClick: this.handleAddInvitation },
                      { text: 'Add Application', onClick: this.handleAddApplication },
                      {
                        ...(this.state.status >= EngagementStatus.MATCH.id
                          ? {
                              text: 'View Engagement',
                              as: 'a',
                              target: '_blank',
                              href: `${process.env.APP_ENDPOINT}/briefs/${this.props.briefUuid}/engagements/`,
                            }
                          : {}),
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        </div>
        {this.state.assignments && this.state.toggle ? (
          <div className={Styles.assignmentsContainer}>
            {this.state.assignments.map((assignment, i) => (
              <AssignmentListItem
                assignment={assignment}
                key={assignment.uuid}
                engagementUuid={this.state.uuid}
                briefUuid={this.props.briefUuid}
                onDelete={this.handleDeleteAssignment}
                currentUser={this.props.currentUser}
              />
            ))}
          </div>
        ) : (
          ''
        )}
        {this.state.applications && this.state.toggle ? (
          <Fragment>
            <div className={Styles.applicationsContainer}>
              {this.state.applications.map((application, i) => {
                if (
                  this.state.assignments.find(
                    (ass) => ass.user && application.user && ass.user.uuid === application.user.uuid
                  )
                ) {
                  return null;
                }
                return (
                  <ApplicationListItem
                    application={application}
                    key={application.uuid}
                    engagementUuid={this.state.uuid}
                    briefUuid={this.props.briefUuid}
                    onDelete={this.handleDeleteApplication}
                    currentUser={this.props.currentUser}
                  />
                );
              })}
            </div>
            {this.state.invitations && this.state.toggle && (
              <div className={Styles.invitationsContainer}>
                {this.state.invitations.map((invitation, i) => {
                  if (
                    this.state.applications.find(
                      (app) => app.user && invitation.user && app.user.uuid === invitation.user.uuid
                    )
                  ) {
                    return null;
                  }
                  return (
                    <InvitationListItem
                      invitation={invitation}
                      key={invitation.id}
                      engagementUuid={this.state.uuid}
                      briefUuid={this.props.briefUuid}
                      onDelete={this.handleDeleteInvitation}
                      currentUser={this.props.currentUser}
                    />
                  );
                })}
              </div>
            )}
          </Fragment>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default EngagementListItem;
