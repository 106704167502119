import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getUserRatings, deleteUserRating } from '@apis/users';
import RatingItem from './RatingItem';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import Styles from './Rating.module.scss';

class RatingList extends Component {
  static propTypes = {
    user: PropTypes.object,
    ratings: PropTypes.array,
    assignments: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      ratingList: [],
      isEditing: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      ratingList: this.props.ratings,
    });
  };

  getRatingList = () => {
    this.setState({ isLoaded: false });
    getUserRatings(this.props.user.uuid).then((response) => {
      this.setState({ ratingList: response.data.results });
    });
  };

  handleNewRating = () => {
    const ratingList = this.state.ratingList;
    ratingList.push({});
    this.setState({
      ratingList,
      isEditing: true,
    });
  };

  updateRating = (rating) => {
    const ratingList = this.state.ratingList.filter((el) => {
      return el && el.uuid;
    });

    if (rating) {
      ratingList.push(rating);
    }

    this.setState({ ratingList, isEditing: false });
  };

  deleteRating = (rating) => {
    if (rating.uuid) {
      deleteUserRating(this.props.user.uuid, rating.uuid).then(
        (response) => {
          this.getRatingList();
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    } else {
      this.updateRating(rating);
    }
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <span>{`Reviews`}</span>
          <div className={Styles.action}>
            <Button name='add new' type={Button.Type.BLUE} onClick={this.handleNewRating}>
              {`Add New`}
            </Button>
          </div>
        </Card.Header>
        <Fragment>
          {this.state.ratingList.length === 0 && (
            <Card.Item>
              <p className={Styles.empty}>{`No reviews have been added.`}</p>
            </Card.Item>
          )}
          <div>
            {this.state.ratingList &&
              this.state.ratingList.map((rating, index) => (
                <RatingItem
                  user={this.props.user}
                  rating={rating}
                  assignments={this.props.assignments}
                  updateRating={this.updateRating}
                  deleteRating={this.deleteRating}
                  key={rating.uuid || index}
                />
              ))}
          </div>
        </Fragment>
      </Card>
    );
  }
}

export default RatingList;
