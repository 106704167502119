import { useState } from 'react';
import toast from 'react-hot-toast';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import { getStripePaymentLink } from '@apis/payments';
import { copyTextToClipboard } from '@hiredigital/lib/helpers/utils';

const StripePaymentLinks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleCopyLink = async ({ amount, currency }) => {
    try {
      setIsLoading(true);
      const response = await getStripePaymentLink(amount, currency);
      copyTextToClipboard(response.data.url);
      toast.success('Copied to clipboard.', { id: 'Copied' });
    } catch (e) {
      toast.error('Unable to generate Stripe Link.', { id: 'Copied' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        {`Stripe Payment Links`}
        {isLoading && (
          <Loader style={{ margin: '-3px', marginLeft: '10px' }} size={Loader.Size.SMALL} />
        )}
      </Card.Header>
      <Card.Note>{`These links are not tied to any particular clients. You will need to generate an Invoice on Xero manually. See the Finance Handbook for more information.`}</Card.Note>
      <Card.Item flex>
        <span style={{ marginRight: 'auto' }}>{`Hire Digital Corporation`}</span>
        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 500, currency: 'usd' })}>{`USD $500`}</Button>
        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 1000, currency: 'usd' })}>{`USD $1,000`}</Button>
        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 1500, currency: 'usd' })}>{`USD $1,500`}</Button>
        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 2000, currency: 'usd' })}>{`USD $2,000`}</Button>
      </Card.Item>
      <Card.Item flex>
        <span style={{ marginRight: 'auto' }}>{`Bolt Media Private Ltd`}</span>
        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 500, currency: 'sgd' })}>{`SGD $500`}</Button>

        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 1000, currency: 'sgd' })}>{`SGD $1,000`}</Button>
        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 1500, currency: 'sgd' })}>{`SGD $1,500`}</Button>
        <Button
          type={Button.Type.BLUE_OUTLINE}
          onClick={() => handleCopyLink({ amount: 2000, currency: 'sgd' })}>{`SGD $2,000`}</Button>
      </Card.Item>
    </Card>
  );
};

export default StripePaymentLinks;
