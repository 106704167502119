import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Tooltip, Position } from 'evergreen-ui';

import ListViewSearch from '@components/ListViewSearch';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import AppLayout from '@components/Layout/AppLayout';
import Select from '@hiredigital/ui/Input/Select';
import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { JobStatus, JobType } from '@hiredigital/lib/helpers/enum';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { getJazzHrJobs, getJazzHrJobResumes } from '@apis/jazzhr';
import SegmentDialog from './components/SegmentDialog';
import PageList from '@styles/PageList.module.scss';
import Styles from './list.module.scss';

const List = ({ location, initialProps }) => {
  const [search, setSearch] = useState(initialProps?.search);
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [isShown, setIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resumes, setResumes] = useState([]);

  const pipe = (v) => {
    return v ? `| ${v} ` : '';
  };

  useEffect(() => {
    setPageTitle(
      `JazzHR Jobs ${pipe(search) + pipe(type?.label) + pipe(status?.label)}| Hire Digital Admin`
    );
  }, [search, type?.label, status?.label]);

  const filters = useMemo(
    () => ({
      type: type?.label,
      status: status?.label,
    }),
    [type, status]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        canSort: true,
        minWidth: 200,
        truncate: false,
        Cell: ({
          row: {
            original: { id, title, noOfApplications },
          },
        }) => {
          return (
            <div className={Styles.titleColumn}>
              <Link to={`/jazzhr-jobs/${id}`} className={Styles.link}>
                {title}
              </Link>
              {noOfApplications > 0 && (
                <Tooltip
                  position={Position.LEFT}
                  content={`Open ${noOfApplications} resume${noOfApplications > 1 ? 's' : ''}`}>
                  <button onClick={() => handleViewResumes(id)} className={Styles.btnOpen}>
                    <IconExternal className={Styles.iconExternal} />
                  </button>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Date Created',
        id: 'original_open_date',
        canSort: true,
        width: 50,
        accessor: (u) => toReadableDate(u?.originalOpenDate, 'MMM d yyyy'),
      },

      {
        Header: 'Location',
        id: 'location',
        canSort: true,
        minWidth: 200,
        accessor: (u) => (
          <Link to={`/jazzhr-jobs/${u.id}`} className={Styles.link}>
            {u.location}
          </Link>
        ),
      },

      {
        Header: 'Type',
        id: 'type',
        width: 100,
        canSort: true,
        accessor: (u) => u.type,
      },
      {
        Header: 'Status',
        id: 'status',
        width: 70,
        canSort: true,
        accessor: (u) => u.status,
      },
      {
        Header: () => <div className={Styles.alignRight}>{'Applicants'}</div>,
        id: 'no_of_applications',
        width: 70,
        canSort: false,
        Cell: ({ row }) => (
          <div className={Styles.alignRight}>{row?.original?.noOfApplications}</div>
        ),
      },
    ],
    []
  );

  const handleSearch = debounce((e) => setSearch(e.target.value), 500);

  const handleViewResumes = async (jobId) => {
    setIsShown(true);
    setIsLoading(true);

    try {
      const { data } = await getJazzHrJobResumes(jobId);
      setResumes(data || []);
    } catch (err) {
      setIsShown(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenAll = () => {
    resumes.forEach((v) => window.open(v, '_blank'));
    setIsShown(false);
  };

  const handleOpenSegment = ({ start, end }) => {
    const resumeSegment = resumes.slice(start - 1, end);
    resumeSegment.forEach((v) => window.open(v, '_blank'));
  };

  const handleClearSearch = () => {
    setSearch('');
    setType(null);
    setStatus(null);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={PageList.tableBody}
      header={
        <ListViewSearch title={'JazzHR Jobs'} search={search} onSearch={handleSearch}>
          <Select
            name='type'
            label='Type'
            className={PageList.headerSelect}
            getOptionValue={({ label }) => label}
            options={JobType.values}
            onChange={(e) => setType(e.target.value)}
            value={type}
            isClearable
          />
          <Select
            name='status'
            label='Status'
            className={PageList.headerSelect}
            getOptionValue={({ label }) => label}
            options={JobStatus.values}
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            isClearable
          />
        </ListViewSearch>
      }>
      <SegmentDialog
        title={`Open Resume${resumes.length > 1 ? 's' : ''}`}
        isOpen={isShown}
        isLoading={isLoading}
        onClose={() => setIsShown(false)}
        total={resumes.length}
        onOpenAll={handleOpenAll}
        onOpenSegment={handleOpenSegment}
      />
      <PaginatedTable
        columns={columns}
        getListData={getJazzHrJobs}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        filters={filters}
        onClearSearch={handleClearSearch}
      />
    </AppLayout>
  );
};

export default List;
