import { authRequest } from './utils';

export const getAgreements = async (parent, uuid) => {
  const url = `admin/${parent}/${uuid}/agreements/`;
  return await authRequest.get(url);
};

export const getBoldAgreements = async (parent, uuid) => {
  const url = `admin/${parent}/${uuid}/bold-agreements/`;
  return await authRequest.get(url);
};

export const getBoldAgreement = async (parent, uuid, agreementId) => {
  const url = `admin/${parent}/${uuid}/bold-agreements/${agreementId}`;
  return await authRequest.get(url);
};

export const generateBoldAgreement = async (parent, uuid) => {
  const url = `admin/${parent}/${uuid}/bold-agreements/generate/`;
  return await authRequest.post(url);
};

export const getBoldAgreementLink = async (parent, uuid, agreementId) => {
  const url = `admin/${parent}/${uuid}/bold-agreements/${agreementId}/link`;
  return await authRequest.get(url);
};

export const getBoldDownloadLink = (parent, uuid, agreementId) => {
  return `${process.env.API_ENDPOINT}/admin/${parent}/${uuid}/bold-agreements/${agreementId}/download`;
};

export const downloadBoldAgreement = async (parent, uuid, agreementId) => {
  const url = `admin/${parent}/${uuid}/bold-agreements/${agreementId}/download`;
  return await authRequest.get(url);
};

export const generateAgreement = async (parent, uuid) => {
  const url = `admin/${parent}/${uuid}/agreements/generate-agreement/`;
  return await authRequest.post(url);
};

export const resetAgreement = async (parent, uuid) => {
  const url = `admin/${parent}/${uuid}/agreements/reset-agreements/`;
  return await authRequest.post(url);
};

export const deleteAgreement = async (parent, uuid, agreementId) => {
  const url = `admin/${parent}/${uuid}/agreements/${agreementId}/`;
  return await authRequest.delete(url);
};

export const resendAgreement = async (parent, uuid, agreementId) => {
  const url = `admin/${parent}/${uuid}/agreements/${agreementId}/resend-agreement-email/`;
  return await authRequest.post(url);
};

export const viewAgreement = async (parent, uuid, agreementId) => {
  const callback = window.location.href;
  const url = `admin/${parent}/${uuid}/agreements/${agreementId}/get-agreement-link/?callback=${callback}`;
  const response = await authRequest.get(url);
  const docusignUrl = response.data;
  const a = document.createElement('a');
  a.href = docusignUrl;
  a.target = '_blank';
  a.click();
};

export const getAgreementLink = async (parent, uuid, agreementId) => {
  const url = `${process.env.MARKETING_ENDPOINT}/api/docusign`;
  const data = {
    resource: parent,
    resourceUuid: uuid,
    agreementId: agreementId,
  };
  const response = await authRequest.post(url, data);
  return `${process.env.MARKETING_ENDPOINT}/api/docusign?agreement=${response.data}`;
};
