import { authRequest } from './utils';

const serializeBrief = (data) => {
  return {
    ...data,
    estimatedBudget: data.estimatedBudget || 0,
    sgdBudget: data.sgdBudget || 0,
    creator: data.creator?.uuid || data.creator || null,
    organization: data.organization?.uuid || data.organization || null,
    emailRecipients: data.emailRecipients?.map((obj) => obj?.uuid || obj) || [],
    selectedStartDate: data.selectedStartDate?.id || data.selectedStartDate || undefined,
    hubspotDeal: data.hubspotDeal?.id || data.hubspotDeal || null,
  };
};

export const getBriefs = async (config = undefined) => {
  const url = `admin/briefs/`;
  return await authRequest.get(url, config);
};

export const getBrief = async (uuid, config = undefined) => {
  const url = `admin/briefs/${uuid}/`;
  return await authRequest.get(url, config);
};

export const putBrief = async (uuid, data, serialize = serializeBrief, config = undefined) => {
  const url = `admin/briefs/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const patchBrief = async (uuid, data, serialize = serializeBrief, config = undefined) => {
  const url = `admin/briefs/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const postBrief = async (data, serialize = serializeBrief, config = undefined) => {
  const url = `admin/briefs/`;
  return authRequest.post(url, serialize(data), config);
};

export const deleteBrief = async (uuid, config = undefined) => {
  const url = `admin/briefs/${uuid}/`;
  return authRequest.delete(url, config);
};

export const getBriefRegistrations = async (config = undefined) => {
  const url = `admin/brief-registrations/`;
  return await authRequest.get(url, config);
};

export const getBriefRegistration = async (uuid) => {
  const url = `admin/brief-registrations/${uuid}/`;
  return authRequest.get(url);
};

export const putBriefRegistrationCall = async (briefUuid, callId, data) => {
  const url = `admin/brief-registrations/${briefUuid}/call/${callId}/`;
  return authRequest.put(url, data);
};

export const deleteBriefRegistration = async (uuid) => {
  const url = `admin/brief-registrations/${uuid}/`;
  return authRequest.delete(url);
};

export const putBriefRegistration = async (uuid, data) => {
  const url = `admin/brief-registrations/${uuid}/`;
  return authRequest.put(url, data);
};

export const convertBriefRegistration = async (uuid) => {
  const url = `admin/brief-registrations/${uuid}/convert-to-brief/`;
  return authRequest.post(url);
};

export const putBriefCall = async (uuid, callId, data, config = undefined) => {
  const url = `admin/briefs/${uuid}/call/${callId}/`;
  return await authRequest.put(url, data, config);
};

export const postBriefAcceptCallReview = async (uuid, data, config = undefined) => {
  const url = `admin/briefs/${uuid}/accept-call-review/`;
  return await authRequest.post(url, data, config);
};

export const postBriefAcceptAdminReview = async (uuid, data, config = undefined) => {
  const url = `admin/briefs/${uuid}/accept-admin-review/`;
  return await authRequest.post(url, data, config);
};

export const postBriefRegistrationLink = async (uuid, data, config = undefined) => {
  const url = `admin/brief-registrations/${uuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postBriefRegistrationFile = async (uuid, data, config = undefined) => {
  const url = `admin/brief-registrations/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteBriefRegistrationLink = async (uuid, linkId, config = undefined) => {
  const url = `admin/brief-registrations/${uuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteBriefRegistrationFile = async (uuid, fileId, config = undefined) => {
  const url = `admin/brief-registrations/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};

export const postBriefsLink = async (uuid, data, config = undefined) => {
  const url = `admin/briefs/${uuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postBriefsFile = async (uuid, data, config = undefined) => {
  const url = `admin/briefs/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteBriefsLink = async (uuid, linkId, config = undefined) => {
  const url = `admin/briefs/${uuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteBriefsFile = async (uuid, fileId, config = undefined) => {
  const url = `admin/briefs/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};
