import { useState, useEffect } from 'react';
import Button from '@hiredigital/ui/Button/Button';
import BaseSelect from 'react-select';
import IconCheck from '@hiredigital/ui/Icon/icons/check-normal.inline.svg';
import Styles from './SegmentViewOptions.module.scss';

export const SEGMENT_OPTIONS = [25, 50, 75, 100];

const SegmentViewOptions = ({ total, onOpenAll, onOpenSegment }) => {
  const [segment, setSegment] = useState({ label: SEGMENT_OPTIONS[0] });
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [segmentOptions, setSegmentOptions] = useState([]);

  useEffect(() => {
    const options = SEGMENT_OPTIONS.filter((v) => total > v);
    setSegmentOptions(options);
  }, [segment, total]);

  if (!total) return null;

  return (
    <>
      <div>
        <Button
          onClick={onOpenAll}
          type={Button.Type.LIGHT_GRAY}
          size={Button.Size.FULLWIDTH_LARGE}>
          {total > 1 ? `Open all ${total} resumes` : `Open ${total} resume`}
        </Button>
      </div>
      {segmentOptions.length > 0 && (
        <>
          <div className={Styles.selectContainer}>
            <span>{`Or open `}</span>
            <BaseSelect
              className={Styles.select}
              placeholder=''
              defaultValue={segment}
              options={segmentOptions.map((label) => ({ label }))}
              getOptionValue={({ label }) => label}
              onChange={(v) => {
                setSelectedIndexes([]);
                setSegment(v);
              }}
            />
            <span>{` resumes at a time by clicking the buttons: `}</span>
          </div>
          <div className={Styles.segmentButtons}>
            {Array.from({ length: Math.ceil(total / segment.label) }).map((_, i) => {
              const start = i * segment.label + 1;
              const end = (i + 1) * segment.label;
              return (
                <Button
                  onClick={() => {
                    if (!selectedIndexes.includes(i)) setSelectedIndexes((p) => [...p, i]);
                    onOpenSegment({ start, end });
                  }}
                  key={i}
                  type={Button.Type.LIGHT_GRAY}>
                  {selectedIndexes.includes(i) && <IconCheck className={Styles.iconSelected} />}
                  {`${['First '][i] || `${start} - `}${end > total ? total : end}`}
                </Button>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default SegmentViewOptions;
