import { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import {
  putBriefRegistration,
  getBriefRegistration,
  convertBriefRegistration,
  postBriefRegistrationLink,
  postBriefRegistrationFile,
  deleteBriefRegistrationLink,
  deleteBriefRegistrationFile,
} from '@apis/briefs';
import { onUsersLoadOptions } from '@apis/dropdown';
import withEmailLabel from '@hoc/withEmailLabel';

import AppLayout from '@components/Layout/AppLayout';

import { BriefRegistrationStatus, Site } from '@hiredigital/lib/helpers/enum';
import { addHttpPrefix } from '@hiredigital/lib/helpers/utils';
import { getNameParts } from '@helpers/forms';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import Quill from '@hiredigital/ui/Quill/Editor';

import CallSchedule from '@components/Call/CallSchedule';
import References from '@hiredigital/ui/Form/References';

import Styles from './detail.module.scss';
import PageStyles from '@styles/PageDetail.module.scss';

import sub from 'date-fns/sub';

const UserSelect = withEmailLabel(Select);

class BriefRegistrationDetail extends Component {
  static propTypes = {
    search: PropTypes.object,
    location: PropTypes.object,
    store: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      briefUuid: this.props.match.params.briefUuid,
      autoSaveTimeout: null,
      isSaving: false,
      hasInitLoad: false,
      organizationName: null,
      userEmail: null,
      name: null,
      website: null,
      budget: null,
      startDate: null,
      talentsRequired: null,
      requirements: null,
      status: null,
      duration: null,
      errors: {},
      notes: null,
      userTitle: null,
      phoneNumber: null,
      phoneCountry: null,
      attachments: null,
      links: null,
      call: {},
      startDateValid: true,
      submitted: false,
      user: null,
    };
  }

  componentDidMount = () => {
    getBriefRegistration(this.state.briefUuid).then(({ data }) => {
      setPageTitle(`Brief Registrations | ${data.organizationName || 'New Brief Registration'}`);
      this.populateFields(data, () => {
        this.setState({ hasInitLoad: true });
      });
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.hasInitLoad !== this.state.hasInitLoad ||
      nextState.isSaving !== this.state.isSaving
    ) {
      return true;
    }

    return false;
  }

  populateFields = (data, completionCallback) => {
    this.setState(
      {
        ...data,
        call: { ...this.state.call, ...data.call },
        name: data.userFirstName ? `${data.userFirstName} ${data.userLastName}` : '',
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
  };

  handleChange = (event, valueKey, completionCallback) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: valueKey ? value[valueKey] : value,
      },
      () => {
        if (completionCallback) {
          completionCallback();
        }
      }
    );
    this.handleAutoSave();
  };

  handleOrganizationChange = (event) => {
    this.handleChange(event, false, () => {
      const { organization, engagement } = this.state;
      if (organization && organization.name) {
        this.setState({
          engagement: {
            ...engagement,
            clientName: organization.name,
          },
        });
      }
    });
  };

  handleImmediateSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false, true);
      }, 0),
    });
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleSubmit = (storeResponse = true, isSubmit = false) => {
    if (isSubmit) {
      this.setState({ submitted: true });
    }

    const {
      name,
      call: { phoneCountry },
      organization,
      brief,
      user,
    } = this.state;

    const { firstName, lastName } = getNameParts(name);
    const data = {
      ...this.state,
      userFirstName: firstName,
      userLastName: lastName,
      phoneCountry: (phoneCountry && phoneCountry.id) || null,
      organization: organization?.uuid,
      user: user?.uuid,
      brief: brief?.uuid,
    };

    if (this.state.startDateValid) {
      this.setState({ errors: {}, isSaving: true });
      putBriefRegistration(this.state.briefUuid, data).then(
        (response) => {
          this.setState({ isSaving: false, autoSaveTimeout: null });
          if (storeResponse) {
            this.populateFields(response);
          }
        },
        (error) => {
          const errors = error.response.data;
          let stateErrors;
          Object.keys(errors).forEach((key) => {
            stateErrors = { ...stateErrors, [key]: errors[key][0] };
          });
          this.setState({
            isSaving: false,
            autoSaveTimeout: null,
            errors: stateErrors,
          });
        }
      );
    }
  };

  handleAutoSave = () => {
    clearTimeout(this.state.autoSaveTimeout);
    this.setState({
      autoSaveTimeout: setTimeout(() => {
        this.handleSubmit(false);
      }, 2000),
    });
  };

  handleSocialChange = (event, prefix, removeBaseUrl = true) => {
    const { name, value } = event.target;
    const link =
      (removeBaseUrl
        ? value && value.replace(prefix, '')
        : value && addHttpPrefix(value, prefix)) || '';
    this.setState({ [name]: link });
    this.handleAutoSave();
  };

  handleDateChange = (date, name) => {
    this.setState({
      [name]: date,
      startDateValid: true,
    });
    this.handleAutoSave();
  };

  handleScheduleChange = (call) => {
    this.setState({ call: { ...this.state.call, ...call }, errors: {} });
    this.handleAutoSave();
  };

  handleConvertBrief = () => {
    this.setState({ isConverting: true });
    convertBriefRegistration(this.state.briefUuid)
      .then(({ data }) => {
        if (data && data.brief && data.brief.uuid) {
          this.setState({ isConverting: false });
          setTimeout(() => {
            this.props.history.push('/briefs/' + data.brief.uuid);
          }, 500);
        }
      })
      .catch((error) => {
        this.setState({ isConverting: false });
      });
  };

  render() {
    return (
      <AppLayout
        location={this.props.location}
        header={
          <div className={PageStyles.header}>
            <Link className={PageStyles.headerParent} to='/brief-registrations'>
              {`Brief Registrations`}
            </Link>
            <p className={PageStyles.headerCaret}>&rsaquo;</p>
            {this.state.hasInitLoad && (
              <div className={PageStyles.headerActive}>{this.state.name || 'Untitled'}</div>
            )}
          </div>
        }>
        {this.state.hasInitLoad && (
          <div className={PageStyles.container}>
            <Card>
              <Card.Header>{`Brief Registration`}</Card.Header>
              <Card.Item>
                <div className={PageStyles.leftRightContainer}>
                  <div className={PageStyles.leftFields}>
                    <InputGroup>
                      <InputContainer>
                        <TextInput
                          name='name'
                          label='Name'
                          defaultValue={this.state.name}
                          placeholder='Name'
                          onChange={this.handleChange}
                          error={this.state.errors.name}
                        />
                      </InputContainer>
                      <InputContainer>
                        <UserSelect
                          id='userSelect'
                          className={Styles.select}
                          defaultValue={this.state.user}
                          classNamePrefix='s-contact'
                          name='user'
                          label='User'
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ uuid }) => uuid}
                          options={this.state.users}
                          onChange={this.handleChange}
                          isPaginated
                          loadOptions={onUsersLoadOptions}
                        />
                      </InputContainer>
                    </InputGroup>
                    <InputGroup>
                      <InputContainer>
                        <TextInput
                          name='organizationName'
                          label='Company Name'
                          defaultValue={this.state.organizationName}
                          placeholder='Company'
                          onChange={this.handleChange}
                          error={this.state.errors.organizationName}
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextInput
                          name='userEmail'
                          label='Email'
                          defaultValue={this.state.userEmail}
                          placeholder='Email'
                          onChange={this.handleChange}
                          error={this.state.errors.userEmail}
                        />
                      </InputContainer>
                    </InputGroup>
                    <InputGroup>
                      <InputContainer>
                        <TextInput
                          name='talentsRequired'
                          label='Title'
                          defaultValue={this.state.talentsRequired}
                          placeholder='Title'
                          onChange={this.handleChange}
                        />
                      </InputContainer>
                      <InputContainer>
                        <TextInput
                          name='userTitle'
                          label='Role'
                          defaultValue={this.state.userTitle}
                          placeholder='Role'
                          onChange={this.handleChange}
                          error={this.state.errors.userTitle}
                        />
                      </InputContainer>
                    </InputGroup>
                    <InputContainer>
                      <Quill
                        name='requirements'
                        label='Requirements'
                        placeholder='Requirements'
                        defaultValue={this.state.requirements}
                        onChange={this.handleChange}
                        style={{ minHeight: '126px' }}
                        legacyCompat
                      />
                    </InputContainer>

                    <References
                      attachments={this.state.attachments}
                      links={this.state.links}
                      postLinkRequest={(payload, config) =>
                        postBriefRegistrationLink(this.state.briefUuid, payload, config)
                      }
                      postFileRequest={(payload, config) =>
                        postBriefRegistrationFile(this.state.briefUuid, payload, config)
                      }
                      deleteLinkRequest={(id) =>
                        deleteBriefRegistrationLink(this.state.briefUuid, id)
                      }
                      deleteFileRequest={(id) =>
                        deleteBriefRegistrationFile(this.state.briefUuid, id)
                      }
                    />
                  </div>
                  <div className={PageStyles.rightFields}>
                    <InputContainer>
                      <Select
                        data-test-id='status'
                        className={PageStyles.select}
                        defaultValue={BriefRegistrationStatus.getEnum(this.state.status)}
                        classNamePrefix='s-contact'
                        name='status'
                        label='Status'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={BriefRegistrationStatus.values}
                        onChange={(e) => this.handleChange(e, 'id')}
                        error={this.state.errors.status}
                      />
                    </InputContainer>
                    <InputContainer
                      className={classNames(!this.state.startDateValid && Styles.dateError)}>
                      <DatePicker
                        id='regStartDate'
                        name='startDate'
                        label='Start Date'
                        value={this.state.startDate}
                        onChange={(date) => this.handleDateChange(date, 'startDate')}
                        minDate={sub(new Date(), { days: 30 })}
                      />
                    </InputContainer>
                    <InputContainer>
                      <TextInput
                        name='duration'
                        label='Duration'
                        defaultValue={this.state.duration}
                        placeholder='Duration'
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                    <InputContainer>
                      <TextInput
                        name='budget'
                        label='Budget'
                        defaultValue={this.state.budget}
                        placeholder='Budget'
                        onChange={this.handleChange}
                      />
                    </InputContainer>

                    <InputContainer>
                      <Select
                        data-test-id='site'
                        className={Styles.select}
                        defaultValue={Site.getEnum(this.state.site)}
                        classNamePrefix='s-contact'
                        name='site'
                        label='Site'
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ id }) => id}
                        options={Site.values}
                        onChange={(e) => this.handleChange(e, 'id')}
                      />
                    </InputContainer>
                  </div>
                </div>
              </Card.Item>
              <Card.Footer right sticky>
                <Button
                  name='convert'
                  className={Styles.convertButton}
                  type={Button.Type.BLUE_OUTLINE}
                  onClick={this.handleConvertBrief}
                  isLoading={this.state.isConverting}>
                  {`Convert to Brief`}
                </Button>
                <Button
                  name='submit'
                  className={Styles.saveButton}
                  type={Button.Type.BLUE}
                  onClick={this.handleImmediateSave}
                  isLoading={this.state.isSaving}>
                  {`Save Registration`}
                </Button>
              </Card.Footer>
            </Card>
            <CallSchedule
              callResource={'brief-registrations'}
              phoneResource={'brief-registrations'}
              uuid={this.state.uuid}
              // briefUuid={this.state.uuid}
              data={{
                ...this.state.call,
                phoneCountry: this.state.phoneCountry,
                phoneNumber: this.state.phoneNumber,
                organizationName: this.state.organizationName,
              }}
              // isBrief={false}
            />
          </div>
        )}
        {!this.state.hasInitLoad && (
          <div className={Loader.Styles.container}>
            <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
          </div>
        )}
      </AppLayout>
    );
  }
}

export default BriefRegistrationDetail;
