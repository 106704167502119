import { useRef } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { connectSearchBox } from 'react-instantsearch-dom';

import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import Loader from '@hiredigital/ui/Loader';

import Styles from './SearchBox.module.scss';

const SearchBox = ({
  currentRefinement,
  defaultRefinement,
  isSearchStalled,
  refine,
  className,
}) => {
  const textRef = useRef();

  const debounceRefine = debounce((v) => refine(v), 350);

  const handleClear = () => {
    debounceRefine.cancel();
    refine('');
    textRef.current.value = '';
  };

  return (
    <div className={Styles.container}>
      <input
        ref={textRef}
        type='search'
        className={classNames(Styles.search, className)}
        name='search'
        placeholder='Search'
        defaultValue={defaultRefinement}
        onChange={(e) => debounceRefine(e.target.value)}
      />
      {isSearchStalled ? (
        <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} type={Loader.Type.FULL} />
      ) : (
        currentRefinement && (
          <button className={Styles.btn} onClick={handleClear}>
            <IconClose className={Styles.icon} />
          </button>
        )
      )}
    </div>
  );
};

export default connectSearchBox(SearchBox);
