import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import SplitButton from '@hiredigital/ui/Button/SplitButton';

import Card from '@hiredigital/ui/Card';
import Loader from '@hiredigital/ui/Loader';
import { copyTextToClipboard } from '@hiredigital/lib/helpers/utils';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { AgreementStatus } from '@hiredigital/lib/helpers/enum';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from './Styles.module.scss';

import {
  getAgreements,
  generateAgreement,
  resetAgreement,
  deleteAgreement,
  viewAgreement,
  getAgreementLink,
  resendAgreement,
} from '@apis/agreements';

class AgreementList extends Component {
  static propTypes = {
    note: PropTypes.string,
    resource: PropTypes.string,
    resourceUuId: PropTypes.string,
    children: PropTypes.node,
    docusignLink: PropTypes.func,
    currentUser: PropTypes.object,
    action: PropTypes.node,
  };

  static defaultProps = {
    resource: `orgs`,
    currentUser: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      hasInitLoad: false,
      agreements: [],
      isLoading: false,
      isDataLoaded: false,
    };

    this.columns = [
      {
        Header: 'Agreement',
        id: 'name',
        minWidth: 300,
        Cell: ({ row }) => {
          return (
            <div className={Styles.row}>
              {row.original.attachment ? (
                <a className={Styles.link} href={row.original.attachment} target='_blank'>
                  {`${row.original.name}`}
                </a>
              ) : (
                row.original.name
              )}
            </div>
          );
        },
      },

      {
        Header: 'Date Received',
        accessor: (d) => {
          if (!d.dateReceived) {
            return '';
          }
          return toReadableDate(d.dateReceived);
        },
      },
      {
        Header: 'Date Signed',
        accessor: (d) => {
          if (!d.dateSigned) {
            return '';
          }
          return toReadableDate(d.dateSigned);
        },
      },
      {
        Header: 'Step',
        id: 'step',
        width: 75,
        Cell: ({ row }) => {
          return row.original.step;
        },
      },
      {
        Header: 'Status',
        id: 'status',
        width: 150,
        Cell: ({ row }) => {
          return AgreementStatus.getLabel(row.original.status);
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: 75,
        truncate: false,
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {/* <Dropdown
                style={{ marginLeft: 'auto' }}
                name='Agreement menu'
                drop='down'
                size='sm'
                alignRight>
                <Dropdown.Toggle className={Styles.moreDropdown}>
                  <IconMoreVertical className={Styles.moreIcon} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => this.handleResendAgreement(row.original.id)}>
                    {`Send Email Reminder`}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.handleCopyAgreementLink(row.original)}>
                    {`Get Agreement Link`}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.handleDeleteAgreement(row.original.id)}>
                    {`Delete Agreement`}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <SplitButton
                direction='left'
                more={[
                  {
                    text: 'Send Email Reminder',
                    onClick: () => this.handleResendAgreement(row.original.id),
                  },
                  {
                    text: 'Get Agreement Link',
                    onClick: () => this.handleCopyAgreementLink(row.original),
                  },
                  {
                    text: 'Delete Agreement',
                    onClick: () => this.handleDeleteAgreement(row.original.id),
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }

  componentDidMount = () => {
    getAgreements(this.props.resource, this.props.resourceUuId).then(({ data }) => {
      this.setState({ agreements: data.results, hasInitLoad: true, isDataLoaded: true });
    });
  };

  handleRefresh = () => {
    this.setState({ isLoading: true, isDataLoaded: false });

    getAgreements(this.props.resource, this.props.resourceUuId).then(({ data }) => {
      this.setState({
        agreements: data.results,
        hasInitLoad: true,
        isDataLoaded: true,
        isLoading: false,
      });
    });
  };

  handleGenerateAgreement = () => {
    this.setState({ isSaving: true });
    generateAgreement(this.props.resource, this.props.resourceUuId)
      .then(({ data }) => {
        this.setState(
          {
            agreements: [...this.state.agreements, data],
            isSaving: false,
            isDataLoaded: false,
          },
          () => {
            this.setState({ isDataLoaded: true });
          }
        );
      })
      .catch((error) => {
        this.setState({ isSaving: false }, () => {
          this.setState({ isDataLoaded: true });
        });
      });
  };

  handleResetAgreement = () => {
    resetAgreement(this.props.resource, this.props.resourceUuId).then(({ data }) => {
      this.setState({ agreements: [] });
    });
  };

  handleResendAgreement = async (agreementId) => {
    try {
      await resendAgreement(this.props.resource, this.props.resourceUuId, agreementId);
      toast.success('Email sent to pending signers.', { id: 'Resend' });
    } catch (e) {}
  };

  handleCopyAgreementLink = async (agreement) => {
    try {
      this.setState({ isLoading: true });
      const url =
        agreement?.attachment ||
        (await getAgreementLink(this.props.resource, this.props.resourceUuId, agreement.id));
      if (url) {
        copyTextToClipboard(url);
        toast.success('Copied to clipboard.', { id: 'Copied' });
      }
    } catch (e) {
      toast.error('Link not found.', { id: 'Copied' });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleDeleteAgreement = (agreementId) => {
    deleteAgreement(this.props.resource, this.props.resourceUuId, agreementId).then(({ data }) => {
      this.setState(
        {
          isDataLoaded: false,
          agreements: this.state.agreements.filter(({ id }) => id !== agreementId) || [],
        },
        () => {
          this.setState({ isDataLoaded: true });
        }
      );
    });
  };

  handleAgreementView = async (agreementId) => {
    await viewAgreement(this.props.resource, this.props.resourceUuId, agreementId);
  };

  render() {
    const hasAgreements = this.state.agreements && !!this.state.agreements.length;
    return (
      <Card>
        <Card.Header>
          <>{`Agreements`}</>
          {this.state.isLoading && (
            <Loader style={{ margin: '-3px', marginLeft: '10px' }} size={Loader.Size.SMALL} />
          )}
          <Card.Action>
            <>
              {this.props.action}
              {/* <Dropdown
                style={{ marginLeft: '10px' }}
                name='Agreement menu'
                drop='down'
                size='sm'
                alignRight>
                <Dropdown.Toggle className={Styles.moreDropdown}>
                  <IconMoreVertical className={Styles.moreIcon} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.handleRefresh}>{`Refresh List`}</Dropdown.Item>
                  <Dropdown.Item onClick={this.handleGenerateAgreement}>
                    {`Generate SG Agreement`}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.handleResetAgreement}>
                    {`Delete All Agreements`}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <SplitButton
                type={SplitButton.Type.WHITE}
                moreButtonClass={Styles.moreButton}
                more={[
                  { text: 'Refresh List', onClick: this.handleRefresh },
                  { text: 'Generate SG Agreement', onClick: this.handleGenerateAgreement },
                  { text: 'Delete All Agreements', onClick: this.handleResetAgreement },
                ]}
              />
            </>
          </Card.Action>
        </Card.Header>
        {this.props.note && <Card.Note>{this.props.note}</Card.Note>}
        {this.props.children}
        <Card.Item noPadding>
          {this.state.isDataLoaded && (
            <PaginatedTable
              columns={this.columns}
              rowAccessor='uuid'
              getListData={(config) =>
                getAgreements(this.props.resource, this.props.resourceUuId, config)
              }
              defaultLimit={10}
              defaultPaginationOptions={[5, 20, 50, 100]}
              showParams={false}
              emptyContainerClass={Styles.emptyContainer}
              paginationClass={Styles.paginationContainer}
            />
          )}
        </Card.Item>
      </Card>
    );
  }
}

export default AgreementList;
