import { useState, useEffect, useMemo, memo, useRef } from 'react';
import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';
import Breadcrumbs from '@components/Breadcrumbs';
import AppLayout from '@components/Layout/AppLayout';
import Tab from '@hiredigital/ui/Tab/Tab';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Badge from '@hiredigital/ui/Badge/Badge';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import IconCalendar from '@hiredigital/ui/Icon/icons/calendar.inline.svg';
import IconBrief from '@hiredigital/ui/Icon/icons/brief.inline.svg';
import IconSearch from '@hiredigital/ui/Icon/icons/search.inline.svg';
import IconPin from '@hiredigital/ui/Icon/icons/pin.inline.svg';
import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';
import Styles from './detail.module.scss';
import Checkbox from '@hiredigital/ui/Checkbox';
import { Tooltip, Position } from 'evergreen-ui';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { getJazzHrJobApplications, getJazzHrJobResumes, getJazzHrJob } from '@apis/jazzhr';
import debounce from 'lodash/debounce';
import Paginator from '@hiredigital/ui/Paginator';
import SegmentDialog from './components/SegmentDialog';

const BASE_RESUME_URL = 'https://app.jazz.co/files/download/resume?url=';

const Item = ({ content, icon: Icon }) => (
  <span className={Styles.description}>
    <Icon className={Styles.icon} />
    {content}
  </span>
);

const PageHeader = memo(({ data, onTabChange, applicantCount = 0, selectedIndex }) => {
  return (
    <div className={Styles.headerColumn}>
      <div className={Styles.headerRow}>
        <div className={Styles.headDetails}>
          <Breadcrumbs
            links={[{ text: 'Jobs', to: '/jazzhr-jobs' }, { text: data?.title || 'Untitled Job' }]}
          />
          <div>
            <Item
              icon={IconCalendar}
              content={
                data?.originalOpenDate
                  ? `Posted on ${toReadableDate(data?.originalOpenDate, 'MMM d yyyy')}`
                  : 'Unknown posting date'
              }
            />
            <Item icon={IconBrief} content={data?.type || 'No type'} />
            <Item icon={IconPin} content={data?.location || 'No location'} />
          </div>
        </div>
        {data?.status && (
          <div className={Styles.headSubDetails}>
            <p className={Styles.label}>{'STATUS'}</p>
            <Badge color='green' className={Styles.label}>
              {data?.status}{' '}
            </Badge>
          </div>
        )}
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange} manual>
        <Tab.List className={Styles.tabList}>
          <Tab className={Styles.tab}>{'Job Details'}</Tab>
          <Tab className={Styles.tab}>
            <span>{'Applications'}</span>
            {applicantCount > 0 && <Badge className={Styles.label}>{applicantCount}</Badge>}
          </Tab>
        </Tab.List>
      </Tab.Group>
    </div>
  );
});

const JobDetails = ({ match, location, initialProps }) => {
  const jobId = match.params.jobId;

  const [search, setSearch] = useState(initialProps?.search);
  const [isShown, setIsShown] = useState(false);
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [resume, setResume] = useState();
  const [total, setTotal] = useState();
  const [resumesLinkDict, setResumesLinkDict] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deselectedItems, setDeselectedItems] = useState([]);
  const [resumes, setResumes] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [job, setJob] = useState();
  const [continueClickedOnce, setContinueClickedOnce] = useState(false);

  useEffect(() => {
    setPageTitle(
      job?.title
        ? `${job.title} | ${job?.location || 'No location'} | JazzHR Jobs`
        : 'JazzHR Jobs | Hire Digital Admin'
    );
  }, [job?.title, job?.location]);

  const searchRef = useRef();

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className={Styles.alignCenter}>
            <Checkbox
              name='ignore'
              checked={isHeaderChecked}
              onChange={handleSelectAllApplications}
              disabled={!total}
            />
          </div>
        ),
        id: 'action',
        width: 25,
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <div className={Styles.alignCenter}>
            <Checkbox
              name='selected'
              checked={isRowSelected(id)}
              labelClass={Styles.checkbox}
              onChange={(e) => handleSelectApplication(e, id)}
            />
          </div>
        ),
      },
      {
        Header: 'Applicant',
        id: 'first_name',
        canSort: true,
        minWidth: 140,
        truncate: false,
        Cell: ({ row: { original } }) => {
          return (
            <div className={Styles.applicantCell}>
              <div>
                <Ellipsis
                  onClick={() => handleOpenResume(original?.resumeLink)}
                  className={Styles.btnLink}>{`${original?.firstName || ''} ${
                  original?.lastName || ''
                }`}</Ellipsis>
                <p className={Styles.email}>{original?.email || ''}</p>
              </div>
              <Tooltip content='Open Resume' position={Position.LEFT}>
                <button
                  onClick={() => handleOpenResume(original?.resumeLink)}
                  className={Styles.btnOpen}>
                  <IconExternal className={Styles.iconExternal} />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: 'Phone',
        id: 'phone',
        canSort: true,
        width: 70,
        accessor: (u) => u.phone || u.prospectPhone,
      },
      {
        Header: 'Address',
        id: 'address',
        canSort: true,
        accessor: (u) => u.address,
      },
      {
        Header: 'Date Applied',
        id: 'apply_date',
        width: 70,
        canSort: true,
        accessor: (u) => toReadableDate(u.applyDate),
      },
    ],
    [selectedItems, isHeaderChecked, deselectedItems, total, continueClickedOnce]
  );

  const handleSelectAllApplications = (e) => {
    if (!e.target.checked) setSelectedItems([]);
    setIsHeaderChecked(e.target.checked);
    setDeselectedItems([]);
  };

  const handleSelectApplication = ({ target: { checked } }, id) => {
    if (!isHeaderChecked) {
      setSelectedItems((p) => (checked ? [...p, id] : p.filter((v) => v !== id)));
      return;
    }

    setDeselectedItems((p) => (checked ? p.filter((v) => v !== id) : [...p, id]));
  };

  const isRowSelected = (id) => {
    if (!isHeaderChecked) return selectedItems.includes(id);
    if (deselectedItems.length) return !deselectedItems.includes(id);
    return true;
  };

  const handleSearch = debounce((e) => setSearch(e.target.value), 500);

  const handleOpenResume = (resumeLink) => {
    const link = `${BASE_RESUME_URL}${resumeLink}`;

    if (continueClickedOnce) {
      window.open(link, '_blank');
    } else {
      setIsShown(true);
      setIsMultiple(false);
      setResume(link);
    }
  };

  const handleOpenSelected = async () => {
    setIsShown(true);
    setIsMultiple(true);

    if (isHeaderChecked) {
      setIsLoading(true);
      try {
        const { data } = await getJazzHrJobResumes(jobId);
        setResumes(data || []);
      } catch (err) {
        setIsShown(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleContinueOpen = () => {
    if (isMultiple) {
      selectedItems.forEach((id) => window.open(resumesLinkDict[id], '_blank'));
    } else {
      window.open(resume, '_blank');
    }
    setContinueClickedOnce(true);
    setIsShown(false);
  };

  const handleClose = () => setIsShown(false);

  const getDeselectedResumeLinks = () => {
    if (!deselectedItems.length) return [];
    return Object.keys(resumesLinkDict).reduce(
      (p, applicationId) =>
        deselectedItems.includes(Number(applicationId))
          ? [...p, resumesLinkDict[applicationId]]
          : p,
      []
    );
  };

  const handleOpenAll = () => {
    setIsShown(false);

    if (!deselectedItems.length) {
      resumes.forEach((u) => window.open(u, '_blank'));
      return;
    }

    const ignoredLinks = getDeselectedResumeLinks();
    resumes.forEach((u) => {
      if (!ignoredLinks.includes(u)) window.open(u, '_blank');
    });
  };

  const handleOpenSegment = ({ start, end }) => {
    const ignoredLinks = getDeselectedResumeLinks();
    const linksSegment = (
      ignoredLinks.length ? resumes.filter((v) => !ignoredLinks.includes(v)) : resumes
    ).slice(start - 1, end);
    linksSegment.forEach((v) => window.open(v, '_blank'));
  };

  const handleListDataCallback = (items, meta) => {
    setTotal(meta?.count);
    const dict = items.reduce((p, { id, resumeLink }) => {
      p[id] = `${BASE_RESUME_URL}${resumeLink}`;
      return p;
    }, {});
    setResumesLinkDict((p) => ({ ...p, ...dict }));
  };

  useEffect(() => {
    getJazzHrJob(jobId)
      .then(({ data }) => setJob(data))
      .catch((err) => console.error('Error occured when fetching jobs.'));
  }, []);

  useEffect(() => {
    if (deselectedItems.length === total) {
      setIsHeaderChecked(false);
      setSelectedItems([]);
    }
  }, [deselectedItems]);

  useEffect(() => {
    if (selectedItems.length === total) {
      setIsHeaderChecked(true);
      setDeselectedItems([]);
    }
  }, [selectedItems]);

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.body}
      headerClass={Styles.header}
      headerContainerClass={Styles.headerContainer}
      header={
        <PageHeader
          applicantCount={total}
          onTabChange={setTabIndex}
          selectedIndex={tabIndex}
          data={job}
        />
      }>
      {/* will just hide the element or component using 'display: none' so that the component's logic doesn't run everytime you switch tabs */}
      <div className={Styles.tabContent} {...(tabIndex !== 0 && { style: { display: 'none' } })}>
        {job?.description && (
          <div
            className={Styles.contentBody}
            dangerouslySetInnerHTML={{ __html: job?.description }}></div>
        )}
      </div>
      <div className={Styles.tabContent} {...(tabIndex !== 1 && { style: { display: 'none' } })}>
        <div className={Styles.actionRow}>
          <Button
            onClick={handleOpenSelected}
            type={Button.Type.LIGHT_GRAY}
            disabled={!selectedItems.length && !isHeaderChecked}>{`Open selected${
            isHeaderChecked
              ? ` (${total - deselectedItems.length})`
              : selectedItems.length
              ? ` (${selectedItems.length})`
              : ''
          }`}</Button>
          <TextInput
            icon={IconSearch}
            label={'Search'}
            name='search'
            onChange={handleSearch}
            className={Styles.searchInput}
            ref={searchRef}
          />
        </div>
        <div className={Styles.tableContainer}>
          <PaginatedTable
            paginator={({ setPage, setPageSize, meta }) => (
              <Paginator
                onPageChange={setPage}
                onPerPageChange={setPageSize}
                total={meta?.count}
                className={Styles.paginator}
              />
            )}
            defaultLimit={Paginator.PageSize[1]}
            getListData={async (config) => getJazzHrJobApplications(jobId, config)}
            listDataCallback={handleListDataCallback}
            columns={columns}
            search={search}
            containerClass={Styles.tableContainer}
            tableClass={Styles.table}
            headerCellClass={Styles.tableHeaderCell}
            rowClass={Styles.row}
            headerRowClass={Styles.tableHeader}
            paginationClass={Styles.pagination}
            onClearSearch={() => {
              setSearch('');
              searchRef.current.value = '';
            }}
          />
        </div>
      </div>
      <SegmentDialog
        title={`Open Resume${isMultiple && selectedItems.length > 1 ? 's' : ''}`}
        isOpen={isShown}
        isLoading={isLoading}
        total={
          isMultiple ? (isHeaderChecked ? total - deselectedItems.length : selectedItems.length) : 1
        }
        isSegmented={isHeaderChecked && isMultiple}
        onOpenAll={handleOpenAll}
        onOpenSegment={handleOpenSegment}
        onContinue={handleContinueOpen}
        onClose={handleClose}
      />
    </AppLayout>
  );
};

export default JobDetails;
