import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Tab from '@hiredigital/ui/Tab/Tab';

import withEmailLabel from '@hoc/withEmailLabel';
import { PointContactType } from '@hiredigital/lib/helpers/enum';
import { getResourceDropdownUsers, patchResource } from '@apis/common';

import Styles from './Styles.module.scss';

const UserSelect = withEmailLabel(Select);

class OrgContact extends Component {
  static propTypes = {
    orgUuid: PropTypes.string,
    data: PropTypes.object,
    onContactChange: PropTypes.func,
    type: PropTypes.string,
  };

  static defaultProps = {
    type: 'orgs',
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      orgUuid: props.orgUuid,
      errors: {},
      ...props.data,
    };
    this.saveTimeout = null;
  }

  isCustomMode = () => {
    return this.state.type === PointContactType.CUSTOM.id;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
        errors: {},
      },
      () => {
        this.saveContactWithDelay();
      }
    );
  };

  saveContactWithDelay = (timeout = 2000) => {
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.saveContact();
    }, timeout);
  };

  saveContact = () => {
    const data = {};
    if (this.isCustomMode()) {
      data.contact = {
        name: `${this.state.firstName} ${this.state.lastName}`,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        type: PointContactType.CUSTOM.id,
      };
    } else {
      data.contact = {
        user: this.state.user?.uuid,
        type: PointContactType.EXISTING.id,
      };
    }
    this.setState({ isSaving: true, errors: {} });
    patchResource(this.props.type, this.state.orgUuid, data)
      .then((response) => {
        this.setState({ isSaving: false });
        this.props.onContactChange?.(data);
      })
      .catch((error) => {
        this.setState({ isSaving: false });
        if (error?.response?.data?.contact?.email) {
          this.setState({ errors: { email: error?.response?.data?.contact?.email?.[0] } });
        }
      });
  };

  onUsersLoadOptions = async (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const response = await getResourceDropdownUsers(this.props.type, this.state.orgUuid, data);
    return {
      options: response.data.results.map((item) => item.user),
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  render() {
    const options = [
      { label: 'Select a team member', value: PointContactType.EXISTING.id },
      { label: 'Use a custom contact', value: PointContactType.CUSTOM.id },
    ];

    return (
      <Card>
        <Card.Header title={`Point of Contact`} />
        <Card.Note>{`Choose a point of contact for billing and agreements.`}</Card.Note>
        <Card.Item>
          <div className={Styles.contactForm}>
            <InputContainer style={{ flexDirection: 'row' }}>
              <Tab.Group
                buttonStyle
                manual
                selectedIndex={options.findIndex((o) => this.state.type === o.value)}
                onChange={(i) => this.setState({ type: options[i].value })}>
                <Tab.List>
                  {options.map((option, index) => (
                    <Tab key={index}>{option.label}</Tab>
                  ))}
                </Tab.List>
              </Tab.Group>
            </InputContainer>

            {this.isCustomMode() ? (
              <Fragment>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      name='firstName'
                      label='First Name'
                      defaultValue={this.state.firstName}
                      onChange={this.handleChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      name='lastName'
                      label='Last Name'
                      defaultValue={this.state.lastName}
                      onChange={this.handleChange}
                    />
                  </InputContainer>
                </InputGroup>
                <InputContainer>
                  <TextInput
                    name='email'
                    label='Email'
                    defaultValue={this.state.email}
                    onChange={this.handleChange}
                    error={this.state.errors?.email}
                  />
                </InputContainer>
              </Fragment>
            ) : (
              <InputContainer>
                <UserSelect
                  className={Styles.select}
                  value={this.state.user}
                  classNamePrefix='s-contact'
                  name='user'
                  label='Team Member'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ uuid }) => uuid}
                  onChange={this.handleChange}
                  isPaginated
                  loadOptions={this.onUsersLoadOptions}
                />
              </InputContainer>
            )}
          </div>
        </Card.Item>
        <Card.Footer right>
          <Button
            onClick={() => {
              this.saveContactWithDelay(0);
            }}
            name='submit'
            type={Button.Type.BLUE}
            isLoading={this.state.isSaving}>
            {`Update Point of Contact`}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default OrgContact;
