import { memo, Fragment } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import {
  EngagementInvitationStatus,
  AdminApplicationStatus,
  AssignmentStatus,
} from '@hiredigital/lib/helpers/enum';
import { isAnyBriefAdmin } from '@helpers/permissions';

import Styles from '@styles/BriefDetail.module.scss';

const BriefDetailSide = memo(
  ({ uuid, engagements, hiddenEngagements, navigateToElem, handleToggleCollapse, currentUser }) => {
    return engagements.length ? (
      <div className={Styles.table}>
        <ul className={Styles.tableList}>
          <li>
            <Link
              className={Styles.tableBrief}
              to={`/briefs/${uuid}`}
              onClick={() => navigateToElem(`${uuid}`)}>
              <div className={classNames(Styles.label, Styles.briefLabel)}>{`B`}</div>
              {`Back to Top`}
            </Link>

            <ul className={Styles.tableList}>
              {(isAnyBriefAdmin(currentUser) || currentUser.adminPermission?.engagement) &&
                engagements.map((engagement, engagementKey) => {
                  return (
                    <li key={engagementKey}>
                      <div className={Styles.tableEngagement}>
                        <div className={Styles.sideListText}>
                          <Link
                            className={Styles.sideListText}
                            to={`/briefs/${uuid}#eng-${engagement.uuid}`}
                            onClick={() => navigateToElem(`eng-${engagement.uuid}`)}>
                            <div
                              className={classNames(
                                Styles.label,
                                Styles.engagementLabel
                              )}>{`E`}</div>
                            {engagement.title ||
                              engagement.talentType?.label ||
                              engagement.otherTalentType ||
                              (engagement.formatQty === 1
                                ? engagement.format?.label || engagement.otherFormat
                                : engagement.formatQty +
                                  ' ' +
                                  ((engagement.format && engagement.format.pluralLabel) ||
                                    engagement.otherFormat)) ||
                              'New Engagement'}
                          </Link>
                        </div>
                        {engagement.invitations?.length ||
                        engagement.applications?.length ||
                        engagement.assignments?.length ? (
                          <button
                            className={classNames(
                              Styles.toggleIcon,
                              hiddenEngagements.includes(engagement.uuid) ? '' : Styles.toggleUp
                            )}
                            onClick={() => handleToggleCollapse(engagement.uuid)}>
                            <IconDown />
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                      {!hiddenEngagements.includes(engagement.uuid) && (
                        <Fragment>
                          <ul className={Styles.tableList}>
                            {(isAnyBriefAdmin(currentUser) ||
                              currentUser.adminPermission?.invitation) &&
                              engagement.invitations.map((invitation, invitationIndex) => (
                                <li className={Styles.tableInvitation} key={invitationIndex}>
                                  <div className={Styles.invitationSideListItem}>
                                    <Link
                                      className={Styles.sideListText}
                                      to={`/briefs/${uuid}#inv-${invitation.id}`}
                                      onClick={() => navigateToElem(`inv-${invitation.id}`)}>
                                      <div
                                        className={classNames(
                                          Styles.label,
                                          Styles.invitationLabel,
                                          invitation.status ===
                                            EngagementInvitationStatus.SENT.id &&
                                            Styles.sentInvitationLabel,
                                          invitation.status ===
                                            EngagementInvitationStatus.ACCEPTED.id &&
                                            Styles.acceptedInvitationLabel
                                        )}>
                                        {`I`}
                                      </div>
                                      <div
                                        className={Styles.linkText}
                                        data-test-id='invitation-menu'>
                                        {invitation.user
                                          ? invitation.user.name
                                          : invitation.team
                                          ? invitation.team.name
                                          : 'New Invitation'}
                                      </div>
                                      <div className={Styles.tableStatus}>
                                        {EngagementInvitationStatus.getLabel(invitation.status)}
                                      </div>
                                    </Link>

                                    <div className={Styles.sideListAction}>
                                      {/* {invitation?.user?.uuid && (
                                        <Dropdown size='sm' drop='down' alignRight>
                                          <Dropdown.Toggle className={Styles.sideMoreDropdown}>
                                            <IconMoreVertical className={Styles.moreIcon} />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              href={`/profiles/${invitation?.user?.uuid}`}>
                                              {`View Profile`}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )} */}
                                      {invitation?.user?.uuid && (
                                        <SplitButton
                                          type={SplitButton.Type.LIGHT_GRAY}
                                          more={[
                                            {
                                              text: 'View Profile',
                                              as: 'a',
                                              href: `/profiles/${invitation?.user?.uuid || ''}`,
                                            },
                                          ]}
                                          moreIconClass={Styles.moreIcon}
                                          moreButtonClass={Styles.moreBtn}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>

                          <ul className={Styles.tableList}>
                            {(isAnyBriefAdmin(currentUser) ||
                              currentUser.adminPermission?.application) &&
                              engagement.applications.map((application, applicationIndex) => (
                                <li className={Styles.tableApplication} key={applicationIndex}>
                                  <div className={Styles.invitationSideListItem}>
                                    <Link
                                      className={Styles.sideListText}
                                      to={`/briefs/${uuid}#app-${application.uuid}`}
                                      onClick={() => navigateToElem(`app-${application.uuid}`)}>
                                      <div
                                        className={classNames(
                                          Styles.label,
                                          Styles.applicationLabel,
                                          application.status ==
                                            AdminApplicationStatus.SUBMITTED_REVIEWED.id &&
                                            Styles.submittedApplicationLabel,
                                          application.status ==
                                            AdminApplicationStatus.INTERVIEW.id &&
                                            Styles.submittedApplicationLabel,
                                          application.status ==
                                            AdminApplicationStatus.ACCEPTED.id &&
                                            Styles.submittedApplicationLabel,

                                          application.shortlist === true && Styles.isShortlist
                                        )}>
                                        {`A`}
                                      </div>
                                      <div
                                        className={Styles.linkText}
                                        data-test-id='application-menu'>
                                        {`${
                                          application.user
                                            ? application.user.name
                                            : application.team
                                            ? application.team.name
                                            : 'New Application'
                                        }`}
                                      </div>
                                      <div
                                        className={Styles.tableStatus}
                                        style={{ marginRight: '20px' }}>
                                        {AdminApplicationStatus.getLabel(application.status)}
                                      </div>
                                    </Link>
                                  </div>
                                </li>
                              ))}
                          </ul>

                          <ul className={Styles.tableList}>
                            {(isAnyBriefAdmin(currentUser) ||
                              currentUser.adminPermission?.assignment) &&
                              engagement.assignments.map((assignment, assignmentIndex) => (
                                <li className={Styles.tableAssignment} key={assignmentIndex}>
                                  <Link
                                    className={Styles.assignmentLink}
                                    to={`/briefs/${uuid}#ass-${assignment.uuid}`}
                                    onClick={() => navigateToElem(`ass-${assignment.uuid}`)}>
                                    <div
                                      className={classNames(Styles.label, Styles.assignmentLabel)}>
                                      {`A`}
                                    </div>
                                    <div className={Styles.linkText}>
                                      {`${
                                        assignment.user
                                          ? assignment.user.name
                                          : assignment.team
                                          ? assignment.team.name
                                          : 'New Assignment'
                                      }`}
                                    </div>
                                    <div
                                      className={Styles.tableStatus}
                                      style={{ marginRight: '20px' }}>
                                      {AssignmentStatus.getLabel(assignment.status)}
                                    </div>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </Fragment>
                      )}
                    </li>
                  );
                })}
            </ul>
          </li>
        </ul>
      </div>
    ) : (
      ''
    );
  }
);

export default BriefDetailSide;
