import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import { BaseCountry } from '@hiredigital/lib/helpers/enum';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';

import IconXero from '@hiredigital/ui/Icon/icons/xero.inline.svg';
import { QuoteStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import { formatter } from '@helpers/formats';
import { getQuotes, refreshQuotes, postClearQuotes } from '@apis/payments';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from './Styles.module.scss';

class QuoteList extends Component {
  static propTypes = {
    orgUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        Header: 'Quote Number',
        id: 'quoteNumber',
        Cell: ({ row }) => {
          return (
            <div className={Styles.row}>
              <a className={Styles.link} href={row.original.attachment} target='_blank'>
                {`${row.original.quoteNumber}`}
              </a>
              <a
                className={Styles.xeroLink}
                href={`https://go.xero.com/Accounts/Receivable/Quotes/View/${row.original.quoteId}`}
                target='_blank'>
                <IconXero className={Styles.xeroIcon} />
              </a>
            </div>
          );
        },
      },
      // TODO - format the dates
      {
        Header: 'Date',
        accessor: (d) => (d.date ? toReadableDate(d.date) : ''),
      },
      {
        Header: 'Country',
        accessor: (d) => BaseCountry.getLabel(d.baseCountry),
      },
      {
        Header: 'Expiry Date',
        accessor: (d) => (d.expiryDate ? toReadableDate(d.expiryDate) : ''),
      },
      {
        Header: 'Status',
        accessor: (d) => QuoteStatus.getLabel(d.status),
      },
      {
        Header: 'Cur',
        width: 50,
        accessor: 'currencyCode',
      },

      {
        Header: 'Total Amount',
        id: 'total',
        Cell: ({ row }) => {
          return <p className={Styles.currency}>{formatter.format(row.original.total)}</p>;
        },
      },
    ];

    this.state = {
      search: '',
    };
  }

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.search(value);
  };

  search = debounce((value) => {
    this.setState({
      search: value,
    });
  }, 250);

  refreshXero = () => {
    return refreshQuotes(this.props.orgUuid).then(() => {
      this.forceRefresh();
    });
  };

  forceRefresh = () => {
    this.setState({ search: ' ' }, () => this.setState({ search: '' }));
  };

  clearXero = () => {
    return postClearQuotes(this.props.orgUuid).then(() => {
      this.forceRefresh();
    });
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <span className={Styles.sectionTitle}>{`Quotes`}</span>
          <input
            className={Styles.searchFieldFrame}
            name='search'
            type='text'
            placeholder='Search Quotes'
            defaultValue={this.state.search}
            onChange={this.handleSearchChange}
          />

          {/* <Button
            className={Styles.leftButton}
            name='refresh'
            type={Button.Type.WHITE_BLUE_OUTLINE}
            onClick={this.refreshXero}>
            {`Refresh Quotes`}
          </Button>
          <Dropdown name='MoreMenu' drop='down' size='sm' alignRight>
            <Dropdown.Toggle className={Styles.rightButton}>
              <IconMoreVertical className={Styles.moreIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.clearXero}>{`Clear Quotes`}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <SplitButton
            name='refresh'
            type={Button.Type.BLUE_OUTLINE}
            onClick={this.refreshXero}
            more={[{ text: 'Clear Quotes', onClick: this.clearXero }]}>
            {`Refresh Quotes`}
          </SplitButton>
        </Card.Header>
        <Card.Item noPadding>
          <PaginatedTable
            columns={this.columns}
            search={this.state.search}
            rowAccessor='uuid'
            getListData={(config) => getQuotes(this.props.orgUuid, config)}
            defaultLimit={10}
            defaultPaginationOptions={[5, 20, 50, 100]}
            showParams={false}
            emptyContainerClass={Styles.emptyContainer}
            paginationClass={Styles.paginationContainer}
          />
        </Card.Item>
      </Card>
    );
  }
}

export default QuoteList;
