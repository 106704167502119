import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Table.module.scss';

const Header = (props) => {
  return (
    <div
      className={classNames(Styles.header, props.className, props.sticky && Styles.stickyHeader)}
      style={{ width: props.width }}
      onClick={props.onClick}>
      {props.children}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  sticky: PropTypes.bool,
};

export default Header;
