import { authRequest, baseAuthRequest } from './utils';

export const getMe = async () => {
  const url = 'admin/me/';
  return await authRequest.get(url);
};

export const getUsers = async (config = undefined) => {
  const url = `admin/users/`;
  return await authRequest.get(url, config);
};

export const getUser = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/`;
  return await authRequest.get(url, config);
};

export const putUser = async (uuid, data, config) => {
  const url = `admin/users/${uuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchUser = async (uuid, data, config, disableTransformer = false) => {
  const url = `admin/users/${uuid}/`;
  const req = disableTransformer ? baseAuthRequest : authRequest;
  return await req.patch(url, data, config);
};

export const getDropdownTalentUsers = async (config = undefined) => {
  const url = `admin/dropdown-talent-users/`;
  return await authRequest.get(url, config);
};

export const getUserEmailAddresses = async (uuid) => {
  const url = `admin/users/${uuid}/email-addresses/`;
  return await authRequest.get(url);
};

export const resetUserEmailAddress = async (uuid, id) => {
  const url = `admin/users/${uuid}/email-addresses/${id}/reset/`;
  return await authRequest.put(url);
};

export const createTalentScore = async (uuid, data) => {
  const url = `admin/users/${uuid}/talent-scores/`;
  return await authRequest.post(url, data);
};

export const updateTalentScore = async (uuid, scoreId, data) => {
  const url = `admin/users/${uuid}/talent-scores/${scoreId}/`;
  return await authRequest.put(url, data);
};

export const getUserOrgs = async (uuid) => {
  const url = `admin/users/${uuid}/clients/`;
  return await authRequest.get(url);
};

export const deleteUserOrg = async (uuid, orgTalentUuid) => {
  const url = `admin/users/${uuid}/clients/${orgTalentUuid}/`;
  return await authRequest.delete(url);
};

export const putUserOrg = async (uuid, orgTalentUuid, data) => {
  const url = `admin/users/${uuid}/clients/${orgTalentUuid}/`;
  return await authRequest.put(url, data);
};

export const getUserDailyBusySchedule = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/daily-busy-schedule/`;
  return await authRequest.get(url, config);
};

export const postUserDailyBusyScheduleSet = async (uuid, data, config = undefined) => {
  const url = `admin/users/${uuid}/daily-busy-schedule/set/`;
  return await authRequest.post(url, data, config);
};

export const getUserMonthlyBusySchedule = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/monthly-busy-schedule/`;
  return await authRequest.get(url, config);
};

export const postUserMonthlyBusyScheduleSet = async (
  uuid,
  year,
  month,
  data,
  config = undefined
) => {
  const url = `admin/users/${uuid}/monthly-busy-schedule/set/${year}/${month}/`;
  return await authRequest.post(url, data, config);
};

export const getUserRatings = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/ratings/`;
  return await authRequest.get(url, config);
};

export const postUserRating = async (uuid, data, config = undefined) => {
  const url = `admin/users/${uuid}/ratings/`;
  return await authRequest.post(url, data, config);
};

export const deleteUserRating = async (uuid, ratingUuid, config = undefined) => {
  const url = `admin/users/${uuid}/ratings/${ratingUuid}/`;
  return await authRequest.delete(url, config);
};

export const putUserRating = async (uuid, ratingUuid, data, config = undefined) => {
  const url = `admin/users/${uuid}/ratings/${ratingUuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchUserRating = async (uuid, ratingUuid, data, config = undefined) => {
  const url = `admin/users/${uuid}/ratings/${ratingUuid}/`;
  return await authRequest.patch(url, data, config);
};

export const getUserEducation = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/education/`;
  return await authRequest.get(url, config);
};

export const postUserEducation = async (uuid, data, config = undefined) => {
  const url = `admin/users/${uuid}/education/`;
  return await authRequest.post(url, data, config);
};

export const deleteUserEducation = async (uuid, educationId, config = undefined) => {
  const url = `admin/users/${uuid}/education/${educationId}/`;
  return await authRequest.delete(url, config);
};

export const putUserEducation = async (uuid, educationId, data, config = undefined) => {
  const url = `admin/users/${uuid}/education/${educationId}/`;
  return await authRequest.put(url, data, config);
};

export const patchUserEducation = async (uuid, educationId, data, config = undefined) => {
  const url = `admin/users/${uuid}/education/${educationId}/`;
  return await authRequest.patch(url, data, config);
};

export const getUserEmployment = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/employment/`;
  return await authRequest.get(url, config);
};

export const postUserEmployment = async (uuid, data, config = undefined) => {
  const url = `admin/users/${uuid}/employment/`;
  return await authRequest.post(url, data, config);
};

export const deleteUserEmployment = async (uuid, employmentId, config = undefined) => {
  const url = `admin/users/${uuid}/employment/${employmentId}/`;
  return await authRequest.delete(url, config);
};

export const putUserEmployment = async (uuid, employmentId, data, config = undefined) => {
  const url = `admin/users/${uuid}/employment/${employmentId}/`;
  return await authRequest.put(url, data, config);
};

export const patchUserEmployment = async (uuid, employmentId, data, config = undefined) => {
  const url = `admin/users/${uuid}/employment/${employmentId}/`;
  return await authRequest.patch(url, data, config);
};

export const getUserBankAccounts = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/bank-accounts/`;
  return await authRequest.get(url, config);
};

export const postUserAcceptChanges = async (uuid, data, config = undefined) => {
  const url = `admin/users/${uuid}/accept-changes/`;
  return await authRequest.post(url, data, config);
};

export const getUserInvitations = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/invitations/`;
  return await authRequest.get(url, config);
};

export const putUserInvitation = async (uuid, invitationId, data, config = undefined) => {
  const url = `admin/users/${uuid}/invitations/${invitationId}/`;
  return await authRequest.put(url, data, config);
};

export const getUserTimesheets = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/timesheets/`;
  return await authRequest.get(url, config);
};

export const getUserAssignments = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/assignments/`;
  return await authRequest.get(url, config);
};

export const putUserAssignment = async (uuid, assignmentId, data, config = undefined) => {
  const url = `admin/users/${uuid}/assignments/${assignmentId}/`;
  return await authRequest.put(url, data, config);
};

export const patchUserAssignment = async (uuid, assignmentId, data, config = undefined) => {
  const url = `admin/users/${uuid}/assignments/${assignmentId}/`;
  return await authRequest.patch(url, data, config);
};

export const getUserApplications = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/applications/`;
  return await authRequest.get(url, config);
};

export const putUserApplication = async (uuid, applicationId, data, config = undefined) => {
  const url = `admin/users/${uuid}/applications/${applicationId}/`;
  return await authRequest.put(url, data, config);
};

export const patchUserApplication = async (uuid, applicationId, data, config = undefined) => {
  const url = `admin/users/${uuid}/applications/${applicationId}/`;
  return await authRequest.patch(url, data, config);
};

export const postUserRegistrationLink = async (uuid, data, config = undefined) => {
  const url = `admin/user-registrations/${uuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postUserRegistrationFile = async (uuid, data, config = undefined) => {
  const url = `admin/user-registrations/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteUserRegistrationLink = async (uuid, linkId, config = undefined) => {
  const url = `admin/user-registrations/${uuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteUserRegistrationFile = async (uuid, fileId, config = undefined) => {
  const url = `admin/user-registrations/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};

export const getUserPlatform = async (uuid, config = undefined) => {
  const url = `admin/users/${uuid}/platforms/`;
  return await authRequest.get(url, config);
};

export const processUserResume = async (uuid, data, config) => {
  const url = `admin/users/${uuid}/process-resume/`;
  return await authRequest.post(url, data, config);
};
