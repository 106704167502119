import { authRequest } from './utils';

export const getNotifications = async (config = undefined) => {
  const url = `admin/notifications/`;
  return await authRequest.get(url, config);
};

export const deleteNotification = async (id, config = undefined) => {
  const url = `admin/notifications/${id}`;
  return await authRequest.delete(url, config);
};
