import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from '@hiredigital/ui/Loader';

import { toFormattedDateRange } from '@hiredigital/lib/helpers/date';

import Styles from '../list.module.scss';

import TreeCaret from './TreeCaret';
import TreeLabel from './TreeLabel';
import MaxAllocation from './MaxAllocation';
import Duration from './Duration';
import PaidCheckbox from './PaidCheckbox';

class EntryTreeItem extends Component {
  static propTypes = {
    data: PropTypes.object,
    isDuration: PropTypes.bool,
    updateUnlocked: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      locked: props.data.locked,
      expand: props.data.type === 'talent' || props.data.type === 'client',
    };
    this.childRefs = [];
    this.addChildRef = (childRef) => {
      this.childRefs.push(childRef);
    };
  }

  setLocked = (locked) => {
    this.setState({
      locked,
    });
  };

  setStateWithJSON = (stateJSON) => {
    this.setState(stateJSON);
  };

  resetLockedState = (locked) => {
    const data = { ...this.props.data, ...this.state.data };
    this.setState(
      (state) => {
        const newData = {
          data: {
            ...state.data,
            unlockedDuration: locked ? 0.0 : data.totalDuration,
          },
          locked,
        };
        if (data.type === 'unlinkedTasks') {
          newData.data.children = data.children?.map((v) => ({
            ...v,
            unlockedDuration: locked ? 0.0 : v?.totalDuration,
          }));
        }
        if (data.type === 'unlinkedEvents') {
          newData.data.children = data.children?.map((v) => ({ ...v, locked }));
        }
        return newData;
      },
      () => {
        this.childRefs.forEach((ref) => {
          ref?.resetLockedState?.(locked);
        });
      }
    );
  };

  updateUnlocked = (duration, childUuid) => {
    // use of function ensures concurrent updates are chained, not conflicting
    this.setState(
      (state, props) => {
        const data = { ...props.data, ...state.data };
        const newData = {
          data: {
            ...state.data,
            unlockedDuration: data.unlockedDuration + duration,
          },
        };
        if (data.type === 'unlinkedTasks') {
          newData.data.children = data.children?.map((v) => {
            if (v?.uuid !== childUuid) return v;
            return {
              ...v,
              unlockedDuration: 1 * v?.unlockedDuration + duration,
            };
          });
        }
        if (data.type === 'unlinkedEvents') {
          newData.data.children = data.children?.map((v) => {
            if (v?.uuid !== childUuid) return v;
            return {
              ...v,
              locked: !v?.locked,
            };
          });
        }
        return newData;
      },
      () => this.props.updateUnlocked?.(duration, this.props.data.uuid)
    );
  };

  getChildName = (child) => {
    if (!child) return '';
    return (
      child.name ||
      (this.props.data.topLevelOrganization ? child.userName : child.clientName) ||
      child.title ||
      toFormattedDateRange(child.startTime, child.endTime)
    );
  };

  getChildPicture = (child) => {
    return (
      child.picture ||
      (this.props.data.topLevelOrganization ? child.userPicture : child.clientPicture)
    );
  };

  getUnlockedDuration = (child, childIsEvent) => {
    return (
      1.0 * (child.unlockedDuration || (childIsEvent ? (child.locked ? 0.0 : child.duration) : 0.0))
    );
  };

  getTotalDuration = (child, childIsEvent) => {
    return 1.0 * (child.totalDuration || (childIsEvent ? child.duration : 0.0));
  };

  render() {
    const data = { ...this.props.data, ...this.state.data };
    const isEvent = data.type === 'event';
    const childIsEvent = data.childType === 'event';
    const children = data.children;
    // only on top level:
    const unlinkedTasks = data.unlinkedTasks;
    const unlinkedEvents = data.unlinkedEvents;
    return (
      <>
        <div
          className={classNames(
            Styles.list,
            Styles[data.type],
            isEvent && this.state.locked ? Styles.listLocked : ''
          )}>
          <div className={Styles.row}>
            <TreeCaret
              expand={this.state.expand}
              type={data.type}
              uuid={data.uuid}
              search={data.search}
              stateData={this.state.data}
              setParentState={this.setStateWithJSON}
            />
            <TreeLabel picture={data.picture} name={data.name} manual={data.manual} />
            <div className={Styles.action}>
              <MaxAllocation maxAlloc={data.maxAllocation} />
              <Duration duration={data.totalDuration} isDuration={this.props.isDuration} />
              <PaidCheckbox
                data={data}
                isEvent={isEvent}
                locked={this.state.locked}
                setLocked={this.setLocked}
                updateUnlocked={this.updateUnlocked}
                updateUnlockedHigherLevel={this.props.updateUnlocked}
                resetLockedState={this.resetLockedState}
              />
              <Duration duration={data.unlockedDuration} isDuration={this.props.isDuration} />
            </div>
          </div>
        </div>

        {this.state.expand &&
          (children?.length > 0 ? (
            children.map((child) => (
              <EntryTreeItem
                key={child.uuid}
                ref={this.addChildRef}
                data={{
                  search: data.search,
                  type: data.childType,
                  name: this.getChildName(child),
                  uuid: child.uuid,
                  picture: this.getChildPicture(child),
                  maxAllocation: child.maxAllocation,
                  unlockedDuration: this.getUnlockedDuration(child, childIsEvent),
                  totalDuration: this.getTotalDuration(child, childIsEvent),
                  // for events only:
                  locked: child.locked,
                  manual: child.manual,
                }}
                isDuration={this.props.isDuration}
                updateUnlocked={this.updateUnlocked}
              />
            ))
          ) : this.state.loading ? (
            <div className={classNames(Styles.placeholderText, Styles[data.childType])}>
              <Loader
                color={Loader.Color.BLUE}
                size={Loader.Size.LARGE}
                type={Loader.Type.INLINE}
              />
            </div>
          ) : (
            <div className={classNames(Styles.placeholderText, Styles[data.childType])}>
              {this.state.placeholderText}
            </div>
          ))}
        {
          // only on top level:
          this.state.expand && unlinkedTasks?.totalDuration > 0.0 && (
            <EntryTreeItem
              key={`${data.uuid}-unlinkedTasks`}
              ref={this.addChildRef}
              data={{
                search: data.search,
                type: 'unlinkedTasks',
                name: 'Unlinked Tasks',
                uuid: `${data.uuid}-unlinkedTasks`,
                maxAllocation: unlinkedTasks.maxAllocation,
                unlockedDuration: 1.0 * unlinkedTasks.unlockedDuration,
                totalDuration: 1.0 * unlinkedTasks.totalDuration,
                childType: 'task',
                children: unlinkedTasks.tasks?.filter((v) => 1.0 * v?.totalDuration > 0.0),
              }}
              isDuration={this.props.isDuration}
              updateUnlocked={this.updateUnlocked}
            />
          )
        }
        {
          // only on top level:
          this.state.expand && unlinkedEvents?.totalDuration > 0.0 && (
            <EntryTreeItem
              key={`${data.uuid}-unlinkedEvents`}
              ref={this.addChildRef}
              data={{
                search: data.search,
                type: 'unlinkedEvents',
                name: 'Unlinked Events',
                uuid: `${data.uuid}-unlinkedEvents`,
                maxAllocation: unlinkedEvents.maxAllocation,
                unlockedDuration: 1.0 * unlinkedEvents.unlockedDuration,
                totalDuration: 1.0 * unlinkedEvents.totalDuration,
                childType: 'event',
                children: unlinkedEvents.events,
              }}
              isDuration={this.props.isDuration}
              updateUnlocked={this.updateUnlocked}
            />
          )
        }
      </>
    );
  }
}

export default EntryTreeItem;
