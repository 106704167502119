import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { BaseCountry } from '@hiredigital/lib/helpers/enum';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import SplitButton from '@hiredigital/ui/Button/SplitButton';

import IconXero from '@hiredigital/ui/Icon/icons/xero.inline.svg';

import { formatter } from '@helpers/formats';
import { getPrepayments, refreshPrepayments, postClearPrepayments } from '@apis/payments';

import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import Styles from './Styles.module.scss';

class PrepaymentList extends Component {
  static propTypes = {
    orgUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        Header: 'Prepayment',
        id: 'xeroIdentifier',
        Cell: ({ row }) => {
          return (
            <div className={Styles.row}>
              <a className={Styles.link} href={row.original.attachment} target='_blank'>
                {`${row.original.xeroIdentifier || row.original.bankTransactionId}`}
              </a>
              <a
                className={Styles.xeroLink}
                href={`https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=${row.original.prepaymentId}`}
                target='_blank'>
                <IconXero className={Styles.xeroIcon} />
              </a>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Country',
        accessor: (d) => BaseCountry.getLabel(d.baseCountry),
      },

      // {
      //   Header: 'Expiration',
      //   accessor: (d) => {
      //     if (!d.expirationDate) {
      //       return '';
      //     }
      //     return toReadableDate(d.expirationDate);
      //   },
      // },

      {
        Header: 'Remaining Credit',
        id: 'remainingCredit',
        Cell: ({ row }) => {
          return (
            <p className={Styles.currency}>{formatter.format(row.original.remainingCredit)}</p>
          );
        },
      },
      {
        Header: 'Cur',
        width: 50,
        accessor: 'currencyCode',
      },
      {
        Header: 'Total Amount',
        id: 'totalAmount',
        Cell: ({ row }) => {
          return <p className={Styles.currency}>{formatter.format(row.original.totalAmount)}</p>;
        },
      },
    ];

    this.state = {
      search: '',
    };
  }

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.search(value);
  };

  search = debounce((value) => {
    this.setState({
      search: value,
    });
  }, 250);

  refreshXero = () => {
    return refreshPrepayments(this.props.orgUuid).then(() => {
      this.forceRefresh();
    });
  };

  forceRefresh = () => {
    this.setState({ search: ' ' }, () => this.setState({ search: '' }));
  };

  handleLoadData = (data, meta) => {
    this.setState({ remainingCredit: meta?.remainingCredit || 0 });
  };

  clearXero = () => {
    return postClearPrepayments(this.props.orgUuid).then(() => {
      this.forceRefresh();
    });
  };

  render() {
    const { remainingCredit } = this.state;

    return (
      <Card>
        <Card.Header>
          <div className={Styles.sectionTitle}>
            {`Xero Prepayments`}
            <div className={Styles.sectionCredit}>{`${formatter.format(
              remainingCredit
            )} Remaining`}</div>
          </div>

          <input
            className={Styles.searchFieldFrame}
            name='search'
            type='text'
            placeholder='Search Prepayments'
            defaultValue={this.state.search}
            onChange={this.handleSearchChange}
          />
          {/* <Button
            name='refresh'
            className={Styles.leftButton}
            type={Button.Type.WHITE_BLUE_OUTLINE}
            onClick={this.refreshXero}>
            {`Refresh Prepayments`}
          </Button>
          <Dropdown name='MoreMenu' drop='down' size='sm' alignRight>
            <Dropdown.Toggle className={Styles.rightButton}>
              <IconMoreVertical className={Styles.moreIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.clearXero}>{`Clear Prepayments`}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <SplitButton
            type={Button.Type.WHITE_BLUE_OUTLINE}
            onClick={this.refreshXero}
            more={[
              { text: 'Clear Prepayments', onClick: this.clearXero },
            ]}>{`Refresh Prepayments`}</SplitButton>
        </Card.Header>
        <Card.Item noPadding>
          <PaginatedTable
            columns={this.columns}
            search={this.state.search}
            rowAccessor='uuid'
            getListData={(config) => getPrepayments(this.props.orgUuid, config)}
            defaultLimit={10}
            defaultPaginationOptions={[5, 20, 50, 100]}
            showParams={false}
            listDataCallback={this.handleLoadData}
            emptyContainerClass={Styles.emptyContainer}
            paginationClass={Styles.paginationContainer}
          />
        </Card.Item>
      </Card>
    );
  }
}

export default PrepaymentList;
