import { Component } from 'react';
import { authRequest, CancelToken } from '@apis/utils';
import debounce from 'lodash/debounce';
import queryString from 'query-string';

const withDataSource = (BaseComponent, resource) => {
  return class extends Component {
    constructor(props) {
      super(props);
      const search = props.location.search ? queryString.parse(props.location.search) : null;

      this.state = {
        params: {
          search: search?.search,
        },
        list: [],
        sort: {},
        hasMore: true,
        isLoaded: false,
        ordering: '',
      };

      this.cancelToken = null;
      this.performDebouncedSearch = debounce(this.performSearch, 500);
    }

    performSearch = (params, completionCallback) => {
      const urlSearchParam = queryString.stringify(params);
      this.props.history.push(
        this.props.location.pathname + (urlSearchParam ? '?' + urlSearchParam : '')
      );

      this.loadData({ ...params, page: 1 }, true, completionCallback);
    };

    handleSearchChange = (params, completionCallback) => {
      const updatedParams = { ...this.state.params, ...params };
      this.setState(
        {
          params: updatedParams,
          isLoaded: false,
          hasMore: true,
        },
        () => {
          this.performDebouncedSearch(updatedParams, completionCallback);
        }
      );
    };

    handleFilterChange = (params, completionCallback) => {
      const updatedParams = { ...this.state.params, ...params };
      this.setState(
        {
          params: updatedParams,
          isLoaded: false,
          hasMore: true,
        },
        () => {
          this.loadData(updatedParams, true, completionCallback);
        }
      );
    };

    loadData = async (params, clearResults = false, completionCallback) => {
      const updatedParams = { ...this.state.params, ...params };
      this.cancelToken && this.cancelToken();
      const url = `admin/${resource}/`;

      // if (this.state.search) params.search = this.state.search;
      // if (this.state.ordering) params.ordering = this.state.ordering;

      if (updatedParams.ordering) {
        //this.setState({ ordering: params.ordering });
        updatedParams.ordering = updatedParams.ordering.replace(/([A-Z])/g, '_$1').toLowerCase();
      }

      const payload = {
        params: updatedParams,
        cancelToken: new CancelToken((c) => {
          this.cancelToken = c;
        }),
      };

      if (clearResults && this.state.isLoaded) {
        this.setState({ isLoaded: false });
      }

      if (!this.state.hasMore) {
        return;
      }

      try {
        const response = await authRequest.get(url, payload);
        const data = clearResults
          ? response.data.results
          : this.state.list.concat(response.data.results);
        completionCallback && completionCallback(data, response.data.meta);
        this.setState({
          list: data,
          isLoaded: true,
          ...(!response.data.meta.nextPage && { hasMore: false }),
        });
      } catch (e) {
        console.log('Error occured during page request.');
        this.setState({
          isLoaded: true,
          hasMore: false,
        });
      }
    };

    loadMore = (page, optParams, completionCallback) => {
      this.loadData(
        {
          ...this.state.params,
          page,
          ...optParams,
        },
        false,
        completionCallback
      );
    };

    sorter = (column, optParams, completionCallback) => {
      this.setState(
        {
          sort: {
            [column]: !this.state.sort[column],
          },
          // list: [],
          isLoaded: false,
          hasMore: true,
        },
        () => {
          const mode = this.state.sort[column] ? '' : '-';
          const ordering = `${mode}${column}`;
          const params = {
            ...this.state.params,
            page: 1,
            ordering,
            ...optParams,
          };
          this.setState({ ordering }, () => {
            this.loadData(params, true, completionCallback);
          });
        }
      );
    };

    render() {
      return (
        <BaseComponent
          loadData={this.loadData}
          sortData={this.sorter}
          searchData={this.handleSearchChange}
          filterData={this.handleFilterChange}
          search={this.state.params.search}
          sort={this.state.sort}
          hasMore={this.state.hasMore}
          isLoaded={this.state.isLoaded}
          {...this.props}
        />
      );
    }
  };
};

export default withDataSource;
