import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import SelectMenu from '@hiredigital/ui/SelectMenu';
import Toggle from 'react-toggle';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';

import Button from '@hiredigital/ui/Button';

import { putEngagementApplication } from '@apis/engagements';
import { AdminApplicationStatus, RateType } from '@hiredigital/lib/helpers/enum';
import {
  isBriefAdmin,
  isPlacementAdmin,
  isSalesAdmin,
  isAnyBriefAdmin,
  isAnyTalentAdmin,
} from '@helpers/permissions';
import Table from '@components/Table/Table';
import ListImage from '@components/ListImage';
import ListStyles from '@styles/PageList.module.scss';
import Styles from '../list.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

class ApplicationList extends Component {
  static propTypes = {
    application: PropTypes.object,
    engagementUuid: PropTypes.string,
    briefUuid: PropTypes.string,
    onDelete: PropTypes.func,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.application,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  handleSelectChange = (value, name) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.saveApplication();
      }
    );
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState(
      {
        [name]: type === 'checkbox' ? checked : value,
      },
      () => {
        this.saveApplication();
      }
    );
  };

  handleSendApplication = () => {
    this.setState({ status: AdminApplicationStatus.SUBMITTED_REVIEWED.id }, () => {
      this.saveApplication();
    });
  };

  saveApplication = () => {
    this.setState({ submitted: true, loading: true });
    const data = {
      shortlist: this.state.shortlist,
      status: this.state.status,
      reviewed: this.state.reviewed,
    };

    putEngagementApplication(this.props.engagementUuid, this.state.uuid, data).then(
      (response) => {
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    return (
      <div className={classNames(Styles.nestedRow, Styles.applicationRow)}>
        <div className={Styles.nestedTree} />
        <Table.Cell width='250px' className={ListStyles.cell}>
          <Link
            className={ListStyles.title}
            to={`/briefs/${this.props.briefUuid}#app-${this.state.uuid}`}>
            {`Application`}
          </Link>
        </Table.Cell>
        <Table.Cell width='225px'>
          {this.state.user || this.state.team ? (
            <Fragment>
              <a
                className={Styles.profileLink}
                href={
                  this.state.user
                    ? `/profiles/${this.state.user.uuid}`
                    : `/teams/${this.state.team.uuid}`
                }
                rel='nofollow noopener'
                target='_blank'>
                <ListImage user={this.state.user} team={this.state.team} />
                <span className={Styles.profileLinkText}>
                  {(this.state.user && this.state.user.name) ||
                    (this.state.team && this.state.team.name)}
                </span>
                <IconExternal style={{ marginLeft: 'auto' }} className={Styles.externalIcon} />
              </a>
            </Fragment>
          ) : (
            'No User Added'
          )}
        </Table.Cell>
        <Table.Cell width='480px' className={Styles.hoverParent}>
          <div className={Styles.hoverSection}>
            <div className={Styles.innerSection}>
              {this.state.user &&
              this.state.user.portfolio &&
              this.state.user.portfolio.primarySkill ? (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {`Primary Skill: `}
                  </p>
                  <p className={Styles.content}>{this.state.user.portfolio.primarySkill.label}</p>
                </div>
              ) : (
                ''
              )}
              {this.state.user &&
              this.state.user.portfolio &&
              this.state.user.portfolio.orderedSkills ? (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {`Skills: `}
                  </p>
                  <p className={Styles.content}>
                    {this.state.user.portfolio.orderedSkills &&
                      this.state.user.portfolio.orderedSkills.map((skill, index) => {
                        return (
                          <span key={index}>
                            {skill.label}
                            {index === this.state.user.portfolio.orderedSkills.length - 1
                              ? ``
                              : `, `}
                          </span>
                        );
                      })}
                  </p>
                </div>
              ) : (
                ''
              )}

              {this.props.currentUser && isSalesAdmin(this.props.currentUser) && (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {isBriefAdmin(this.props.currentUser) ? `Client Rate: ` : `Rate: `}
                  </p>
                  <p className={Styles.content}>
                    {isNewRates
                      ? this.state.clientEngagementRate?.rateDisplayText || '-'
                      : `${this.state.brandRate} ${
                          this.state.brandRateType
                            ? RateType.getEnum(this.state.brandRateType).label
                            : ''
                        }`}
                  </p>
                </div>
              )}

              {this.props.currentUser && isPlacementAdmin(this.props.currentUser) && (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {isBriefAdmin(this.props.currentUser) ? `Talent Rate: ` : `Rate: `}
                  </p>
                  <p className={Styles.content}>
                    {isNewRates
                      ? this.state.talentEngagementRate?.rateDisplayText || '-'
                      : `${this.state.talentRate} ${
                          this.state.talentRateType
                            ? RateType.getEnum(this.state.talentRateType).label
                            : ''
                        }`}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Table.Cell>

        <Table.Cell width='220px'>
          {/* <Dropdown size='sm'>
            <Dropdown.Toggle className={ListStyles.selectDropdown}>
              <p>{AdminApplicationStatus.getEnum(this.state.status).label}</p>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {AdminApplicationStatus.values.map((status, statusIndex) => (
                <Dropdown.Item
                  onClick={() => this.handleSelectChange(status.id, 'status')}
                  key={statusIndex}>
                  {status.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
          <SelectMenu
            defaultValue={AdminApplicationStatus.getEnum(this.state.status)}
            options={AdminApplicationStatus.values}
            onChange={(v) => this.handleSelectChange(v.id, 'status')}
          />
        </Table.Cell>
        <Table.Cell width='130px' />
        <Table.Cell width='250px' className={ListStyles.cellRow}>
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <label
              htmlFor={`shortlist-${this.state.uuid}`}
              className={Styles.toggleLabel}>{`Shortlist`}</label>
            <Toggle
              id={`shortlist-${this.state.uuid}`}
              name='shortlist'
              defaultChecked={this.state.shortlist}
              onChange={this.handleChange}
            />
          </div>

          {this.state.status === AdminApplicationStatus.SUBMITTED.id && (
            <Button
              className={Styles.button}
              type={Button.Type.BLUE}
              onClick={this.handleSendApplication}
              isLoading={this.state.loading}>
              {`Approve`}
            </Button>
          )}
        </Table.Cell>
        <div className={Styles.floatingAction}>
          {/* <Dropdown drop='left' size='sm'>
            <Dropdown.Toggle className={ListStyles.moreDropdown}>
              <IconMoreVertical className={ListStyles.moreIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.user ? (
                <Fragment>
                  {this.props.currentUser && isAnyTalentAdmin(this.props.currentUser) && (
                    <Dropdown.Item target='_blank' href={`/profiles/${this.state.user.uuid}`}>
                      {`View Admin Profile`}
                    </Dropdown.Item>
                  )}

                  {this.props.currentUser && isAnyBriefAdmin(this.props.currentUser) && (
                    <Dropdown.Item
                      target='_blank'
                      href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.key}`}>
                      {`View Private Resume`}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    target='_blank'
                    href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.username}`}>
                    {`View Public Resume`}
                  </Dropdown.Item>
                </Fragment>
              ) : (
                ''
              )}

              {!this.state.user &&
                this.state.team &&
                this.props.currentUser &&
                isBriefAdmin(this.props.currentUser) && (
                  <Fragment>
                    <Dropdown.Item
                      target='_blank'
                      href={`${process.env.MARKETING_ENDPOINT}/teams/${this.state.team.slug}`}>
                      {`View Public Profile`}
                    </Dropdown.Item>
                  </Fragment>
                )}

              <Dropdown.Item onClick={(e) => this.props.onDelete(e, this.state.uuid)}>
                {`Delete Application`}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <SplitButton
            more={[
              {
                ...(this.state.user &&
                this.props.currentUser &&
                isAnyTalentAdmin(this.props.currentUser)
                  ? {
                      text: 'View Admin Profile',
                      as: 'a',
                      target: '_blank',
                      href: `/profiles/${this.state.user?.uuid}`,
                    }
                  : {}),
              },
              {
                ...(this.state.user &&
                this.props.currentUser &&
                isAnyBriefAdmin(this.props.currentUser)
                  ? {
                      text: 'View Private Resume',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user?.key}`,
                    }
                  : {}),
              },
              {
                ...(this.state.user
                  ? {
                      text: 'View Public Resume',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user?.username}`,
                    }
                  : {}),
              },
              {
                ...(!this.state.user &&
                this.state.team &&
                this.props.currentUser &&
                isBriefAdmin(this.props.currentUser)
                  ? {
                      text: 'View Public Profile',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/teams/${this.state.team?.slug}`,
                    }
                  : {}),
              },
              {
                text: 'Delete Application',
                onClick: (e) => this.props.onDelete(e, this.state?.uuid),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default ApplicationList;
