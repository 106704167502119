import { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import convert from 'htmr';
import Toggle from 'react-toggle';

import {
  onRatingTypesLoadOptions,
  onStrongAttributesLoadOptions,
  onWeakAttributesLoadOptions,
} from '@apis/dropdown';
import { createTalentScore, updateTalentScore } from '@apis/users';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import Styles from './Score.module.scss';

const ScoreFieldItem = ({ label, content, small = false }) => (
  <div className={classNames(Styles.field, small && Styles.small)}>
    <span className={Styles.label}>{label}</span>
    <p className={Styles.content}>{content}</p>
  </div>
);

class ScoreItem extends Component {
  static propTypes = {
    score: PropTypes.object,
    index: PropTypes.number,
    userUuid: PropTypes.string,
    onScoreDelete: PropTypes.func,
    onScoreCreate: PropTypes.func,
    onScoreUpdate: PropTypes.func,
    onScoreCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...props.score,
      showForm: false,
      isSaving: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.showForm !== this.state.showForm || nextState.isSaving !== this.state.isSaving) {
      return true;
    }

    return false;
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.score !== prevProps.score) {
      this.setState({
        ...this.props.score,
      });
    }
  };

  componentDidMount = () => {
    if (!this.props.score.id) {
      this.setState({ showForm: true });
    }
  };

  handleEdit = () => {
    this.setState({ showForm: true }, () => {
      this.scoreBaseState = { ...this.state }; // create a separate copy of base state to prevent it from being directly mutated
    });
  };

  handleCancel = () => {
    if (this.state.showForm) {
      this.setState({ ...this.scoreBaseState, showForm: false }); // restore the base state when editing is cancelled
    }
    if (this.props.onScoreCancel) {
      this.props.onScoreCancel(this.state.id);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    const {
      ratingType,
      projectManagerNeeded,
      creator,
      skillsScore,
      availabilityScore,
      qualityOfWorkScore,
      timelinessScore,
      communicationScore,
      strongAttributes,
      weakAttributes,
      relatedLinks,
      remarks,
    } = this.state;

    const data = {
      ...(ratingType && { ratingType: ratingType.id }),
      projectManagerNeeded: projectManagerNeeded,
      ...(creator && { creator: creator.uuid }),
      skillsScore: skillsScore || null,
      availabilityScore: availabilityScore || null,
      qualityOfWorkScore: qualityOfWorkScore || null,
      timelinessScore: timelinessScore || null,
      communicationScore: communicationScore || null,
      strongAttributes: (strongAttributes && strongAttributes.map((v) => v.id)) || [],
      weakAttributes: (weakAttributes && weakAttributes.map((v) => v.id)) || [],
      relatedLinks,
      remarks,
    };

    if (this.state.id) {
      this.updateScore(data)
        .then((response) => {
          this.setState({ isSaving: false, showForm: false, ...response.data });
          if (this.props.onScoreUpdate) {
            this.props.onScoreUpdate(response.data);
          }
        })
        .catch((error) => {
          this.setState({ isSaving: false });
        });
    } else {
      this.createScore(data)
        .then((response) => {
          this.setState({ isSaving: false, showForm: false, ...response.data });
          if (this.props.onScoreCreate) {
            this.props.onScoreCreate(response.data);
          }
        })
        .catch((error) => {
          this.setState({ isSaving: false });
        });
    }
  };

  createScore = (data) => {
    return createTalentScore(this.props.userUuid, data);
  };

  updateScore = (data) => {
    return updateTalentScore(this.props.userUuid, this.state.id, data);
  };

  handleDeleteScore = () => {
    this.props.onScoreDelete(this.state.id);
    this.setState({ showForm: false });
  };

  handleToggleChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  render() {
    return (
      <Card.Item>
        {this.state.showForm && (
          <div className={Styles.form}>
            <InputGroup>
              <InputContainer>
                <Select
                  data-test-id='ratingType'
                  className={Styles.select}
                  value={this.state.ratingType}
                  classNamePrefix='s-contact'
                  name='ratingType'
                  label='Rating Type'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  onChange={this.handleChange}
                  isPaginated
                  loadOptions={onRatingTypesLoadOptions}
                />
              </InputContainer>

              <InputContainer style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Toggle
                  id='projectManagerNeeded'
                  name='projectManagerNeeded'
                  defaultChecked={this.state.projectManagerNeeded}
                  onChange={this.handleToggleChange}
                />
                <label htmlFor='projectManagerNeeded' className={Styles.toggleLabel}>
                  {`Project Manager Needed`}
                </label>
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <TextInput
                  name='skillsScore'
                  label='Skills Score'
                  type='number'
                  defaultValue={this.state.skillsScore}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name='availabilityScore'
                  label='Availability Score'
                  type='number'
                  defaultValue={this.state.availabilityScore}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name='qualityOfWorkScore'
                  label='Quality of Work Score'
                  type='number'
                  defaultValue={this.state.qualityOfWorkScore}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name='timelinessScore'
                  label='Timeliness Score'
                  type='number'
                  defaultValue={this.state.timelinessScore}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name='communicationScore'
                  label='Communication Score'
                  type='number'
                  defaultValue={this.state.communicationScore}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name='overallScore'
                  label='Overall Score'
                  type='number'
                  defaultValue={this.state.overallScore && this.state.overallScore.toFixed(2)}
                  disabled
                />
              </InputContainer>
            </InputGroup>

            <InputGroup>
              <InputContainer>
                <Select
                  data-test-id='strongAttributes'
                  className={Styles.select}
                  defaultValue={this.state.strongAttributes}
                  classNamePrefix='s-contact'
                  name='strongAttributes'
                  label='Strong Attributes'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  onChange={this.handleChange}
                  isPaginated
                  isMulti
                  loadOptions={onStrongAttributesLoadOptions}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  data-test-id='weakAttributes'
                  className={Styles.select}
                  defaultValue={this.state.weakAttributes}
                  classNamePrefix='s-contact'
                  name='weakAttributes'
                  label='Weak Attributes'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  onChange={this.handleChange}
                  isPaginated
                  isMulti
                  loadOptions={onWeakAttributesLoadOptions}
                />
              </InputContainer>
            </InputGroup>

            <InputGroup>
              <InputContainer>
                <TextArea
                  name='relatedLinks'
                  label='Related Links'
                  placeholder='Related Links'
                  defaultValue={this.state.relatedLinks}
                  onChange={this.handleChange}
                />
              </InputContainer>
              <InputContainer>
                <TextArea
                  name='remarks'
                  label='Remarks'
                  placeholder='Remarks'
                  defaultValue={this.state.remarks}
                  onChange={this.handleChange}
                />
              </InputContainer>
            </InputGroup>

            <div className={Styles.row}>
              <Button name='delete' type={Button.Type.WHITE} onClick={this.handleDeleteScore}>
                {`Delete`}
              </Button>
              <div className={Styles.action}>
                <Button
                  name='cancel'
                  type={Button.Type.WHITE_BLUE_OUTLINE}
                  onClick={this.handleCancel}>
                  {`Cancel`}
                </Button>

                <Button
                  name='submit'
                  type={Button.Type.BLUE}
                  onClick={this.handleSubmit}
                  isLoading={this.state.isSaving}>
                  {`Save`}
                </Button>
              </div>
            </div>
          </div>
        )}

        {!this.state.showForm && (
          <div className={Styles.listPreview}>
            <div className={Styles.row}>
              <div className={Styles.details}>
                <ScoreFieldItem
                  label={`Rating type`}
                  content={this.state.ratingType ? this.state.ratingType.label : 'No Type'}
                  small
                />
                <ScoreFieldItem
                  label={`PM Needed`}
                  content={this.state.projectManagerNeeded ? 'Yes' : 'No'}
                  small
                />

                {/*{this.state.creator && (
                  <ScoreFieldItem
                    label={`Reviewer`}
                    content={this.state.creator.name}
                  />
                )}*/}

                <ScoreFieldItem label={`Skills`} content={this.state.skillsScore || 'N/A'} small />

                <ScoreFieldItem
                  label={`Availability`}
                  content={this.state.availabilityScore || 'N/A'}
                  small
                />

                <ScoreFieldItem
                  label={`Quality of Work`}
                  content={this.state.qualityOfWorkScore || 'N/A'}
                  small
                />

                <ScoreFieldItem
                  label={`Timeliness`}
                  content={this.state.timelinessScore || 'N/A'}
                  small
                />

                <ScoreFieldItem
                  label={`Communication`}
                  content={this.state.communicationScore || 'N/A'}
                  small
                />

                <ScoreFieldItem
                  label={`Overall Score`}
                  content={this.state.overallScore && this.state.overallScore.toFixed(2)}
                  small
                />

                {this.state.strongAttributes && this.state.strongAttributes.length > 0 && (
                  <ScoreFieldItem
                    label={`Strong Attributes`}
                    content={(this.state.strongAttributes || [])
                      .map((item) => item.label)
                      .join(', ')}
                  />
                )}

                {this.state.weakAttributes && this.state.weakAttributes.length > 0 && (
                  <ScoreFieldItem
                    label={`Weak Attributes`}
                    content={(this.state.weakAttributes || []).map((item) => item.label).join(', ')}
                  />
                )}

                {this.state.relatedLinks && (
                  <ScoreFieldItem label={`Related Links`} content={this.state.relatedLinks} />
                )}

                {this.state.remarks && (
                  <ScoreFieldItem label={`Remarks`} content={this.state.remarks} />
                )}
              </div>
              <div className={Styles.action}>
                <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={this.handleEdit}>
                  {`Edit`}
                </Button>
              </div>
            </div>
            {this.state.internalNotes && (
              <div className={Styles.description}>{convert(this.state.internalNotes)}</div>
            )}
          </div>
        )}
      </Card.Item>
    );
  }
}

export default ScoreItem;
