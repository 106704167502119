import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { putUserEducation, postUserEducation } from '@apis/users';
// import DateInput from '@components/Form/Input/DateInput';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import Quill from '@hiredigital/ui/Quill/Editor';
import Styles from '../Resume.module.scss';
import { getDateFromMonthYear, getDuration, getDates } from '@hiredigital/lib/helpers/date';
import isAfter from 'date-fns/isAfter';
import format from 'date-fns/format';

class EducationItem extends PureComponent {
  static propTypes = {
    education: PropTypes.object,
    updateEducation: PropTypes.func,
    deleteEducation: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      jobTitle: '',
      institution: '',
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      description: '',
      major: '',
      isEdit: false,
      hasMounted: false,
      dateInOrder: true,
      startDate: null,
      endDate: null,
      errors: {},
    };
  }

  componentDidMount = () => {
    this.handleData(this.props.education);
    this.setState({ hasMounted: true });

    if (!this.props.education.id) {
      this.setState({ isEdit: true });
    }
  };

  handleData = (response) => {
    this.setState({
      id: response.id ? response.id : null,
      qualification: response.qualification ? response.qualification : '',
      institution: response.institution ? response.institution : '',
      startMonth: response.startMonth ? response.startMonth : null,
      startYear: response.startYear ? response.startYear : null,
      endMonth: response.endMonth ? response.endMonth : null,
      endYear: response.endYear ? response.endYear : null,
      major: response.major ? response.major : '',
      description: response.description ? response.description : '',
      startDate: this.makeValidDate(response?.startMonth, response?.startYear),
      endDate: this.makeValidDate(response?.endMonth, response?.endYear),
    });
  };

  makeValidDate = (month, year) => {
    return month && year ? new Date(year, month - 1, 1) : null; // month is zero-based
  };

  handleEdit = () => {
    this.setState({ isEdit: true }, () => {
      this.educationBaseState = { ...this.state };
    });
  };

  handleCancel = () => {
    if (this.state.isEdit) {
      this.setState({ ...this.educationBaseState, isEdit: false });
    }

    if (!this.state.id) {
      this.props.updateEducation();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      errors: {},
    });
  };

  validateDates = (startMonth, startYear, endMonth, endYear) => {
    if (!endMonth && !endYear) {
      return true;
    }

    if (!startMonth && !startYear) {
      return true;
    }

    const start = getDateFromMonthYear(startMonth, startYear);
    const end = getDateFromMonthYear(endMonth, endYear);
    return start <= end;
  };

  handleSubmit = () => {
    const {
      qualification,
      institution,
      major,
      startMonth,
      startYear,
      endMonth,
      endYear,
      description,
    } = this.state;
    // Check if dates are valid

    // const startValid = isDateValid(startMonth, startYear).isValid;
    // const endValid = isDateValid(endMonth, endYear).isValid;
    // const isValid = this.validateDates(startMonth, startYear, endMonth, endYear);
    // this.setState({ submitted: true, dateInOrder: isValid });

    const data = {
      qualification,
      institution,
      major,
      startMonth,
      startYear,
      endMonth,
      endYear,
      description,
    };

    if (this.validateRequiredFields()) {
      this.setState({ loading: true });
      if (this.props.education.id) {
        putUserEducation(this.props.user.uuid, this.props.education.id, data).then(
          (response) => {
            this.handleData(response.data);
            this.setState({ loading: false, isEdit: false });
          },
          (error) => {
            this.setState({ loading: false });
          }
        );
      } else {
        postUserEducation(this.props.user.uuid, data).then(
          (response) => {
            this.handleData(response.data);
            this.setState({ loading: false, isEdit: false });
            this.props.updateEducation(response.data);
          },
          (error) => {
            this.setState({ loading: false });
          }
        );
      }
    }
  };

  handleStartDateChange = (date) => {
    const startYear = date ? format(date, 'yyyy') : null;
    const startMonth = date ? format(date, 'M') : null;
    this.setState({ startDate: date, startYear, startMonth, errors: {} });
  };

  handleEndDateChange = (date) => {
    // this.makeDateBefore(this.state.startDate, date);
    const endYear = date ? format(date, 'yyyy') : null;
    const endMonth = date ? format(date, 'M') : null;
    this.setState({ endDate: date, endYear, endMonth, errors: {} });
  };

  // makeDateBefore = (start, end) => {
  //   const date = new Date();
  //   if (start && isAfter(start, end || date)) {
  //     this.setState({ startDate: end || date });
  //   }
  // };

  validateRequiredFields = () => {
    const { qualification, institution, major, startDate, endDate } = this.state;

    let errors = {};
    if (!qualification) {
      errors = { ...errors, qualification: 'Enter the name of the degree.' };
    }

    if (!institution) {
      errors = { ...errors, institution: 'Enter the name of the institution.' };
    }

    if (!major) {
      errors = { ...errors, major: 'Enter the name of major.' };
    }

    if (!startDate) {
      errors = { ...errors, startDate: 'Enter start date.' };
    }

    if (startDate && endDate && isAfter(new Date(startDate), new Date(endDate))) {
      errors = { ...errors, startDate: 'Start Date should not be later than End Date.' };
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  render() {
    const { startMonth, startYear, endMonth, endYear } = this.state;
    return (
      <Card.Item>
        {this.state.hasMounted && (
          <div>
            {this.state.isEdit && (
              <div>
                <div className={Styles.form} data-test-id='education-form'>
                  <InputGroup>
                    <InputContainer>
                      <TextInput
                        name='qualification'
                        label='Degree'
                        error={this.state.errors?.qualification}
                        defaultValue={this.state.qualification}
                        onChange={this.handleChange}
                      />
                    </InputContainer>

                    <InputContainer>
                      <TextInput
                        name='institution'
                        label='Institution'
                        error={this.state.errors?.institution}
                        defaultValue={this.state.institution}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <TextInput
                        name='major'
                        label='Major'
                        error={this.state.errors?.major}
                        defaultValue={this.state.major}
                        onChange={this.handleChange}
                      />
                    </InputContainer>
                    <InputGroup>
                      <InputContainer>
                        <DatePicker
                          id='educStartDate'
                          name='startDate'
                          label='From'
                          dateFormat='MM/yyyy'
                          error={this.state.errors.startDate}
                          onChange={this.handleStartDateChange}
                          value={this.state.startDate}
                          showMonthYearPicker
                        />
                      </InputContainer>

                      <InputContainer>
                        <DatePicker
                          id='educEndDate'
                          name='endDate'
                          label='To'
                          dateFormat='MM/yyyy'
                          onChange={this.handleEndDateChange}
                          // minDate={
                          //   this.state.startDate ? new Date(this.state.startDate) : undefined
                          // }
                          value={this.state.endDate}
                          showMonthYearPicker
                        />
                      </InputContainer>
                    </InputGroup>
                  </InputGroup>
                  <InputContainer>
                    <Quill
                      name='description'
                      label='Description'
                      placeholder='Description'
                      defaultValue={this.state.description}
                      error={this.state.errors.description}
                      onChange={this.handleChange}
                      legacyCompat
                    />
                  </InputContainer>
                </div>

                <div className={Styles.row} data-test-id='update-education-section'>
                  <Button
                    name='delete'
                    type={Button.Type.WHITE}
                    onClick={(e) => this.props.deleteEducation(this.props.education)}>
                    {`Delete`}
                  </Button>

                  <div className={Styles.action}>
                    <Button
                      name='cancel'
                      type={Button.Type.WHITE_BLUE_OUTLINE}
                      onClick={this.handleCancel}>
                      {`Cancel`}
                    </Button>

                    <Button
                      name='submit'
                      type={Button.Type.BLUE}
                      onClick={this.handleSubmit}
                      isLoading={this.state.loading}>
                      {`Save`}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {!this.state.isEdit && (
              <div className={Styles.listPreview}>
                <div className={Styles.row}>
                  <div>
                    <p className={Styles.title}>{`${this.state.qualification}${
                      this.state.major && `, ` + this.state.major
                    }`}</p>
                    <p className={Styles.institution}>{this.state.institution}</p>
                    <p className={Styles.timeframe}>
                      {`${getDates(startMonth, startYear, endMonth, endYear)} ${getDuration(
                        startMonth,
                        startYear,
                        endMonth,
                        endYear
                      )}`}
                    </p>
                  </div>
                  <div className={Styles.action} data-test-id='education-list'>
                    <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={this.handleEdit}>
                      {`Edit`}
                    </Button>
                  </div>
                </div>
                {this.state.description && (
                  <div
                    className={Styles.description}
                    dangerouslySetInnerHTML={{
                      __html: this.state.description,
                    }}></div>
                )}
              </div>
            )}
          </div>
        )}
      </Card.Item>
    );
  }
}

export default EducationItem;
