import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import Styles from './UserEmailAddresses.module.scss';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { getUserEmailAddresses, resetUserEmailAddress } from '@apis/users';

const UserEmailAddresses = ({ userUuid, ...props }) => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getUserEmailAddresses(userUuid);
        setAddresses(response.data.results);
      } catch (err) {
        console.error(err);
        setAddresses([]);
      }
    };

    loadData();
  }, []);

  const sendVerification = async (id) => {
    try {
      setLoading(true);
      const response = await resetUserEmailAddress(userUuid, id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  return (
    <Card>
      <Card.Header title={`Email Addresses`} />
      {addresses.map((address, index) => {
        return (
          <Card.Item key={`address-${address.uuid}`}>
            <div className={Styles.row}>
              <div className={Styles.primary}>{address.email}</div>
              {address.primary ? <div className={Styles.label}>{`Primary`}</div> : ''}
              {address.verified ? (
                <div className={classNames(Styles.label, Styles.action)}>{`Verified`}</div>
              ) : (
                <Fragment>
                  <div className={classNames(Styles.label, Styles.action)}>{`Not Verified`}</div>
                  <Button
                    data-test-id='resend-verification-button'
                    type={Button.Type.BLUE_OUTLINE}
                    isLoading={loading}
                    onClick={() => sendVerification(address.id)}>{`Resend Verification`}</Button>
                </Fragment>
              )}
            </div>
          </Card.Item>
        );
      })}
    </Card>
  );
};

UserEmailAddresses.propTypes = {};

export default UserEmailAddresses;
