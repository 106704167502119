import queryString from 'query-string';

const withParameters = (BaseComponent) => {
  const WrappedComponent = (props) => {
    const initialProps = queryString.parse(props?.location?.search, {
      parseBooleans: true,
      arrayFormat: 'comma',
    });
    return <BaseComponent initialProps={initialProps} {...props} />;
  };
  return WrappedComponent;
};

export default withParameters;
