import { authRequest } from './utils';

const serializerEvent = (data) => {
  const serializedData = {
    ...data,
    user: data?.user?.uuid,
    task: {
      ...data.task,
      timesheet: data?.task?.timesheet?.uuid || data?.task?.timesheet || undefined,
      user: data?.task?.user?.uuid,
    },
  };
  return serializedData;
};

export const getEventScreenshots = async (uuid, config = undefined) => {
  const url = `admin/tracking/events/${uuid}/screenshots/`;
  return await authRequest.get(url, config);
};

export const putEvent = async (uuid, data, serialize = serializerEvent, config = undefined) => {
  const url = `admin/tracking/events/${uuid}/`;
  return authRequest.put(url, serialize(data), config);
};

export const patchEvent = async (uuid, data, serialize = serializerEvent, config = undefined) => {
  const url = `admin/tracking/events/${uuid}/`;
  return authRequest.patch(url, serialize(data), config);
};

export const postEvent = async (data, serialize = serializerEvent, config = undefined) => {
  const url = `admin/tracking/events/`;
  return authRequest.post(url, serialize(data), config);
};

export const deleteEvent = async (uuid, config = undefined) => {
  const url = `admin/tracking/events/${uuid}/`;
  return authRequest.delete(url, config);
};

export const getUserEvents = async (config = undefined) => {
  const url = `admin/tracking/user-events/`;
  return await authRequest.get(url, config);
};

export const getEvents = async (config = undefined) => {
  const url = `admin/tracking/events/`;
  return await authRequest.get(url, config);
};

export const getEvent = async (uuid, config = undefined) => {
  const url = `admin/tracking/events/${uuid}/`;
  return authRequest.get(url, config);
};

export const getFinanceTopLevel = async (topLevelOrganization, config = undefined) => {
  const url = `admin/tracking/finance/${topLevelOrganization ? 'clients' : 'talents'}/`;
  return await authRequest.get(url, config);
};

const financeApi = {
  task: 'tasks',
  assignment: 'timesheets',
  relationship: 'relationships',
  talent: 'talents',
  unlinkedTasks: 'talents',
  unlinkedEvents: 'talents',
};

export const getFinanceOthers = async (type, uuid, config = undefined) => {
  const url = `admin/tracking/finance/${financeApi[type]}/${uuid}/`;
  return authRequest.get(url, config);
};

export const postLockedUnlocked = async (type, uuid, locked, config = undefined) => {
  const url = `admin/tracking/finance/${financeApi[type]}/${uuid}/set-${
    locked ? 'locked' : 'unlocked'
  }/`;
  return await authRequest.post(url, null, config);
};

export const getOverlappingEvents = async (data, config = undefined) => {
  const url = `admin/tracking/get-overlapping-events/`;
  return await authRequest.post(url, data, config);
};
