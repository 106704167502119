import { authRequest } from './utils';

export const createTeam = async (data) => {
  const url = `admin/teams/`;
  return await authRequest.post(url, data);
};

export const getTeams = async (config) => {
  const url = `admin/teams/`;
  return await authRequest.get(url, config);
};

export const getTeam = async (uuid) => {
  const url = `admin/teams/${uuid}/`;
  return await authRequest.get(url);
};

export const putTeam = async (uuid, data, config = undefined) => {
  const url = `admin/teams/${uuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchTeam = async (uuid, data, config = undefined) => {
  const url = `admin/teams/${uuid}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteTeam = async (uuid, config = undefined) => {
  const url = `admin/teams/${uuid}/`;
  return await authRequest.delete(url, config);
};

export const getTeamServices = async (uuid) => {
  const url = `admin/teams/${uuid}/services/`;
  return await authRequest.get(url);
};

export const getTeamInvitations = async (uuid) => {
  const url = `admin/teams/${uuid}/invitations/`;
  return await authRequest.get(url);
};

export const getTeamBankAccounts = async (uuid, config = undefined) => {
  const url = `admin/teams/${uuid}/bank-accounts/`;
  return await authRequest.get(url, config);
};

export const acceptTeamChanges = async (uuid) => {
  const url = `admin/teams/${uuid}/accept-changes/`;
  return await authRequest.post(url);
};

export const putTeamInvitation = async (uuid, invitationId, data, config = undefined) => {
  const url = `admin/teams/${uuid}/invitations/${invitationId}/`;
  return authRequest.put(url, data, config);
};

export const postTeamInvitation = async (uuid, data, config = undefined) => {
  const url = `admin/teams/${uuid}/invitations/`;
  return authRequest.post(url, data, config);
};

export const postTeamUser = async (uuid, data, config = undefined) => {
  const url = `admin/teams/${uuid}/users/`;
  return authRequest.post(url, data, config);
};

export const putTeamUser = async (uuid, userId, data, config = undefined) => {
  const url = `admin/teams/${uuid}/users/${userId}/`;
  return authRequest.put(url, data, config);
};

export const deleteTeamUser = async (uuid, userId, config = undefined) => {
  const url = `admin/teams/${uuid}/users/${userId}/`;
  return authRequest.delete(url, config);
};

export const deleteTeamInvitation = async (uuid, invitationId, config = undefined) => {
  const url = `admin/teams/${uuid}/invitations/${invitationId}/`;
  return authRequest.delete(url, config);
};

export const postTeamOwnerChange = async (uuid, data, config = undefined) => {
  const url = `admin/teams/${uuid}/change-owner/`;
  return authRequest.post(url, data, config);
};
