import { memo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconEdit from '@hiredigital/ui/Icon/icons/pencil.inline.svg';

import DualInput from '@hiredigital/ui/Input/DualInput';
import Quill from '@hiredigital/ui/Quill/Editor';
import References from '@hiredigital/ui/Form/References';
import { AdminApplicationStatus, RateType, TimeRate } from '@hiredigital/lib/helpers/enum';
import withEmailLabel from '@hoc/withEmailLabel';
import Styles from './UserApplications.module.scss';
import { onApplicationTalentUsersLoadOptions, onTeamsLoadOptions } from '@apis/dropdown';
import { isSalesAdmin, isPlacementAdmin } from '@helpers/permissions';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import PageStyles from '@styles/PageDetail.module.scss';
import BriefDetailStyles from '@styles/BriefDetail.module.scss';
import RateInputGroup from '@components/RateInputGroup';
import classNames from 'classnames';
import {
  deleteEngagementApplicationLink,
  postEngagementApplicationLink,
  postEngagementApplicationFile,
  deleteEngagementApplicationFile,
} from '@apis/engagements';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);
const UserSelect = withEmailLabel(memo(Select));

const ApplicationForm = ({
  data,
  currentUser,
  onFormChange,
  hideBgColorArea = false,
  isEdit: initialIsEdit = true,
  talentEngagementRate,
  clientEngagementRate,
  resourceUuid,
}) => {
  const [isEdit, setEdit] = useState(initialIsEdit);
  const [activeTabKey, setActiveTabKey] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    onFormChange?.({ name, value: value?.id || value });
  };

  return (
    <div>
      {(isPlacementAdmin(currentUser) ||
        isSalesAdmin(currentUser) ||
        currentUser.adminPermission?.applicationTalentRate ||
        currentUser.adminPermission?.applicationClientRate) && (
        <div className={hideBgColorArea ? undefined : Styles.ratesContainer}>
          {/* visible for Brief Admin, Brief Placement Admin, Admin */}
          {(isPlacementAdmin(currentUser) || currentUser.adminPermission?.applicationTalentRate) &&
            !isNewRates && (
              <Fragment>
                <InputGroup>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentRate'
                          label='Talent Rate (SGD)'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentRate}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentRateType'
                          label='Rate Type'
                          style={{ flexBasis: '115px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={RateType.values}
                          defaultValue={RateType.getEnum(data?.talentRateType)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentMinHours'
                          label='Min Talent Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentMinHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentMinPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.talentMinPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='talentMaxHours'
                          label='Max Talent Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.talentMaxHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='talentMaxPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.talentMaxPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                </InputGroup>
              </Fragment>
            )}
          {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles that is Brief Placement Admin */}
          {(isSalesAdmin(currentUser) || currentUser.adminPermission?.applicationClientRate) &&
            !isNewRates && (
              <Fragment>
                <InputGroup>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandRate'
                          label='Client Rate (SGD)'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandRate}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandRateType'
                          label='Rate Type'
                          style={{ flexBasis: '115px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={RateType.values}
                          defaultValue={RateType.getEnum(data?.brandRateType)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandMinHours'
                          label='Min Client Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandMinHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandMinPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.brandMinPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                  <InputContainer>
                    <DualInput
                      input={(Input) => (
                        <Input
                          name='brandMaxHours'
                          label='Max Client Hours'
                          type='number'
                          step='.01'
                          defaultValue={data?.brandMaxHours}
                          onChange={handleChange}
                        />
                      )}
                      select={(Select) => (
                        <Select
                          name='brandMaxPer'
                          label='Per'
                          style={{ flexBasis: '130px' }}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ id }) => id}
                          options={TimeRate.values}
                          defaultValue={TimeRate.getEnum(data?.brandMaxPer)}
                          onChange={handleChange}
                        />
                      )}></DualInput>
                  </InputContainer>
                </InputGroup>
              </Fragment>
            )}

          {/* visible for Brief Admin, Brief Placement Admin, Admin */}
          {(isPlacementAdmin(currentUser) || currentUser.adminPermission?.applicationTalentRate) &&
            isNewRates && (
              <RateInputGroup
                rate={talentEngagementRate}
                resource={'application'}
                resourceUuid={resourceUuid}
                hideBgColorArea={hideBgColorArea}
              />
            )}
          {/* not visible for Brief Placement Admin, visible to Admin, visible to other roles that is Brief Placement Admin */}
          {(isSalesAdmin(currentUser) || currentUser.adminPermission?.applicationClientRate) &&
            isNewRates && (
              <RateInputGroup
                isClient
                rate={clientEngagementRate}
                resource={'application'}
                resourceUuid={resourceUuid}
                hideBgColorArea={hideBgColorArea}
              />
            )}
        </div>
      )}
      <Tab.Container defaultActiveKey='application' activeKey={activeTabKey} mountOnEnter={true}>
        <Nav
          variant='tabs'
          className={classNames(BriefDetailStyles.row, BriefDetailStyles.headlineRow)}>
          <Nav.Item>
            <Nav.Link eventKey='application' onClick={() => setActiveTabKey('application')}>
              <h3 className={classNames(PageStyles.tabHeadline)}>{`Application`}</h3>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='terms' onClick={() => setActiveTabKey('terms')}>
              <h3 className={classNames(PageStyles.tabHeadline)}>{`Terms`}</h3>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className={PageStyles.tabContent}>
          <Tab.Pane eventKey='application'>
            <div className={Styles.otherFieldsArea}>
              <div className={Styles.leftFields}>
                {isEdit ? (
                  <InputContainer>
                    <Quill
                      name='content'
                      label='Talent Application'
                      placeholder='Proposal'
                      defaultValue={data?.content}
                      onChange={handleChange}
                      legacyCompat
                    />
                  </InputContainer>
                ) : (
                  <div>
                    <div
                      className={Styles.label}
                      style={{
                        display: 'flex',
                        fontSize: '16px',
                        fontWeight: '500',
                        margin: '0 0 5px 10px',
                      }}>
                      {`Talent Application`}
                      <div
                        className={Styles.detailEditButton}
                        style={{ marginLeft: '20px', fontSize: '14px' }}
                        onClick={() => setEdit(!isEdit)}>
                        <IconEdit style={{ height: '15px', width: '15px', marginRight: '10px' }} />
                        {`Edit `}
                      </div>
                    </div>
                    <div
                      className={Styles.readText}
                      dangerouslySetInnerHTML={{ __html: data?.content || 'No Application Added' }}
                    />
                  </div>
                )}

                <div>
                  <References
                    showActions={isEdit}
                    attachments={data?.attachments}
                    links={data?.links}
                    postLinkRequest={(payload, config) =>
                      postEngagementApplicationLink(
                        data?.engagement?.uuid,
                        data?.uuid,
                        payload,
                        config
                      )
                    }
                    postFileRequest={(payload, config) =>
                      postEngagementApplicationFile(
                        data?.engagement?.uuid,
                        data?.uuid,
                        payload,
                        config
                      )
                    }
                    deleteLinkRequest={(id) =>
                      deleteEngagementApplicationLink(data?.engagement?.uuid, data?.uuid, id)
                    }
                    deleteFileRequest={(id) =>
                      deleteEngagementApplicationFile(data?.engagement?.uuid, data?.uuid, id)
                    }
                    // server={`admin/engagements/${data?.engagement?.uuid}/applications/${data?.uuid}`}
                  />
                </div>
              </div>
              <div className={Styles.rightFields}>
                <InputContainer>
                  <Select
                    className={Styles.selectStatus}
                    classNamePrefix='s-contact'
                    name='status'
                    label='Status'
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={AdminApplicationStatus.values}
                    onChange={handleChange}
                    defaultValue={AdminApplicationStatus.getEnum(data?.status)}
                    menuPlacement='top'
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    className={Styles.selectTeam}
                    classNamePrefix='s-contact'
                    name='team'
                    label='Team'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    isPaginated
                    isClearable
                    loadOptions={onTeamsLoadOptions}
                    defaultValue={data?.team}
                    onChange={handleChange}
                    menuPlacement='top'
                  />
                </InputContainer>

                <InputContainer>
                  <UserSelect
                    id='userID'
                    className={Styles.select}
                    classNamePrefix='s-contact'
                    name='user'
                    label='User'
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ uuid }) => uuid}
                    isPaginated
                    isClearable
                    loadOptions={(search, loadedOptions, params) =>
                      onApplicationTalentUsersLoadOptions(
                        data?.engagement?.uuid,
                        search,
                        loadedOptions,
                        params
                      )
                    }
                    defaultValue={data?.user}
                    onChange={handleChange}
                    menuPlacement='top'
                  />
                </InputContainer>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey='terms'>
            {(isPlacementAdmin(currentUser) ||
              currentUser.adminPermission?.applicationTalentRate) && (
              <InputContainer>
                <Quill
                  name='talentTerms'
                  label='Talent Terms'
                  placeholder='Talent Terms'
                  defaultValue={data?.talentTerms}
                  onChange={handleChange}
                  legacyCompat
                />
              </InputContainer>
            )}
            {(isSalesAdmin(currentUser) || currentUser.adminPermission?.applicationClientRate) && (
              <InputContainer>
                <Quill
                  name='clientTerms'
                  label='Client Terms'
                  placeholder='Client Terms'
                  defaultValue={data?.clientTerms}
                  onChange={handleChange}
                  legacyCompat
                />
              </InputContainer>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

ApplicationForm.propTypes = {
  currentUser: PropTypes.object,
  data: PropTypes.object,
  onFormChange: PropTypes.func,
};

export default ApplicationForm;
