import { authRequest } from './utils';

const serializeApplication = (data) => {
  return {
    ...data,
    user: data.user?.uuid || data.user,
    team: data.team?.uuid || data.team,
  };
};

export const getEngagementApplications = async (uuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/applications/`;
  return authRequest.get(url, config);
};

export const getEngagementApplication = async (uuid, applicationUuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/`;
  return authRequest.get(url, config);
};

export const patchEngagementApplication = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/`;
  return authRequest.patch(url, data, config);
};

export const putEngagementApplication = async (
  uuid,
  applicationUuid,
  data,
  serialize = serializeApplication,
  config = undefined
) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/`;
  return authRequest.put(url, serialize(data), config);
};

export const postEngagementApplication = async (uuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/applications/`;
  return authRequest.post(url, data, config);
};

export const deleteEngagementApplication = async (uuid, applicationUuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/`;
  return authRequest.delete(url, config);
};

export const getEngagementDropdownUsers = async (uuid, type, config = undefined) => {
  const url = `admin/dropdown-talent-users/?type=${type}&engagement=${uuid}`;
  return authRequest.get(url, config);
};

export const getEngagementAssignments = async (uuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/assignments/`;
  return authRequest.get(url, config);
};

export const getEngagementAssignment = async (uuid, assignmentUuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/assignments/${assignmentUuid}/`;
  return authRequest.get(url, config);
};

export const putEngagementAssignment = async (uuid, assignmentUuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/assignments/${assignmentUuid}/`;
  return authRequest.put(url, data, config);
};

export const patchEngagementAssignment = async (uuid, assignmentUuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/assignments/${assignmentUuid}/`;
  return authRequest.patch(url, data, config);
};

export const postEngagementAssignment = async (uuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/assignments/`;
  return authRequest.post(url, data, config);
};

export const deleteEngagementAssignment = async (uuid, assignmentUuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/assignments/${assignmentUuid}/`;
  return authRequest.delete(url, config);
};

export const postEngagement = async (data, config = undefined) => {
  const url = `admin/engagements/`;
  return authRequest.post(url, data, config);
};

export const getEngagements = async (config = undefined) => {
  const url = `admin/engagements/`;
  return await authRequest.get(url, config);
};

export const getEngagement = async (uuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/`;
  return await authRequest.get(url, config);
};

export const putEngagement = async (uuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchEngagement = async (uuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteEngagement = async (uuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/`;
  return await authRequest.delete(url, config);
};

export const postEngagementInvitation = async (uuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/invitations/`;
  return authRequest.post(url, data, config);
};

export const getEngagementInviations = async (uuid, config = undefined) => {
  const url = `admin/engagements/${uuid}/invitations/`;
  return await authRequest.get(url, config);
};

export const getEngagementInvitation = async (uuid, invitationId, config = undefined) => {
  const url = `admin/engagements/${uuid}/invitations/${invitationId}/`;
  return await authRequest.get(url, config);
};

export const putEngagementInvitation = async (uuid, invitationId, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/invitations/${invitationId}/`;
  return await authRequest.put(url, data, config);
};

export const patchEngagementInvitation = async (uuid, invitationId, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/invitations/${invitationId}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteEngagementInvitation = async (uuid, invitationId, config = undefined) => {
  const url = `admin/engagements/${uuid}/invitations/${invitationId}/`;
  return await authRequest.delete(url, config);
};

export const postEngagementInvitationGenerate = async (uuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/generate-invitations/`;
  return authRequest.post(url, data, config);
};

export const postEngagementInvitationClear = async (uuid, data, config = undefined) => {
  const url = `admin/engagements/${uuid}/clear-generated-invitations/`;
  return authRequest.post(url, data, config);
};

export const postEngagementApplicationLink = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postEngagementApplicationFile = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteEngagementApplicationLink = async (
  uuid,
  applicationUuid,
  linkId,
  config = undefined
) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteEngagementApplicationFile = async (
  uuid,
  applicationUuid,
  fileId,
  config = undefined
) => {
  const url = `admin/engagements/${uuid}/applications/${applicationUuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};
