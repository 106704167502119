import { authRequest } from './utils';

const serializeProject = (data) => {
  const serializedData = {
    ...data,
    organization: data?.organization?.uuid || undefined,
  };
  return serializedData;
};

export const getProjects = async (config = undefined) => {
  const url = `admin/projects/`;
  return await authRequest.get(url, config);
};

export const getProject = async (uuid, config = undefined) => {
  const url = `admin/projects/${uuid}/`;
  return await authRequest.get(url, config);
};

export const saveProject = async (uuid, data, config = undefined) => {
  const url = `admin/projects/${uuid}/`;
  return await authRequest.put(url, serializeProject(data), config);
};

export const postProject = async (uuid, data, config = undefined) => {
  const url = `admin/projects/`;
  return await authRequest.post(url, serializeProject(data), config);
};

export const postProjectDeadline = async (uuid, data, config = undefined) => {
  const url = `admin/projects/${uuid}/deadlines/`;
  return await authRequest.post(url, data, config);
};

export const putProjectDeadline = async (uuid, deadlineId, data, config = undefined) => {
  const url = `admin/projects/${uuid}/deadlines/${deadlineId}/`;
  return await authRequest.put(url, data, config);
};

export const deleteProjectDeadline = async (uuid, deadlineId, config = undefined) => {
  const url = `admin/projects/${uuid}/deadlines/${deadlineId}/`;
  return await authRequest.delete(url, config);
};

export const postProjectDeadlineComplete = async (uuid, deadlineId, data, config = undefined) => {
  const url = `admin/projects/${uuid}/deadlines/${deadlineId}/complete/`;
  return await authRequest.post(url, data, config);
};

export const postProjectDeadlineActive = async (uuid, deadlineId, data, config = undefined) => {
  const url = `admin/projects/${uuid}/deadlines/${deadlineId}/active/`;
  return await authRequest.post(url, data, config);
};

export const getProjectMembers = async (uuid, config = undefined) => {
  const url = `admin/projects/${uuid}/members/`;
  return await authRequest.get(url, config);
};

export const postProjectMember = async (uuid, data, config = undefined) => {
  const url = `admin/projects/${uuid}/members/`;
  return await authRequest.post(url, data, config);
};

export const deleteProjectMember = async (uuid, memberid, config = undefined) => {
  const url = `admin/projects/${uuid}/members/${memberid}/`;
  return await authRequest.delete(url, config);
};

export const putProjectMember = async (uuid, memberid, data, config = undefined) => {
  const url = `admin/projects/${uuid}/members/${memberid}/`;
  return await authRequest.put(url, data, config);
};

export const postProjectSubmit = async (uuid, data, config = undefined) => {
  const url = `admin/projects/${uuid}/submit/`;
  return await authRequest.post(url, data, config);
};

export const postProjectSendBack = async (uuid, data, config = undefined) => {
  const url = `admin/projects/${uuid}/send-back/`;
  return await authRequest.post(url, data, config);
};

export const postProjectComplete = async (uuid, data, config = undefined) => {
  const url = `admin/projects/${uuid}/complete/`;
  return await authRequest.post(url, data, config);
};
