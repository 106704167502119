import { authRequest } from './utils';

export const getTalentRegistrations = async (config = undefined) => {
  const url = `admin/user-registrations/`;
  return await authRequest.get(url, config);
};
export const getTalentRegistration = async (uuid, config = undefined) => {
  const url = `admin/user-registrations/${uuid}/`;
  return await authRequest.get(url, config);
};

export const saveTalentRegistration = async (uuid, data, config = undefined) => {
  const url = `admin/user-registrations/${uuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchTalentRegistration = async (uuid, data, config = undefined) => {
  const url = `admin/user-registrations/${uuid}/`;
  return await authRequest.patch(url, data, config);
};

export const getTalentScores = async (userUuid, config = undefined) => {
  const url = `admin/users/${userUuid}/talent-scores/`;
  return await authRequest.get(url);
};

export const deleteTalentScore = async (userUuid, scoreId) => {
  const url = `admin/users/${userUuid}/talent-scores/${scoreId}/`;
  return await authRequest.delete(url);
};

export const convertTalentRegistration = async (uuid) => {
  const url = `admin/user-registrations/${uuid}/convert-to-user/`;
  return await authRequest.post(url);
};

export const messageTalentRegistration = async (uuid) => {
  const url = `admin/user-registrations/${uuid}/notify-to-join/`;
  return await authRequest.post(url);
};
