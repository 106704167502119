import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import SelectMenu from '@hiredigital/ui/SelectMenu';
import { putEngagementInvitation } from '@apis/engagements';
import { EngagementInvitationStatus, RateType } from '@hiredigital/lib/helpers/enum';
import {
  isBriefAdmin,
  isSalesAdmin,
  isPlacementAdmin,
  isAnyBriefAdmin,
  isAnyTalentAdmin,
} from '@helpers/permissions';
import Styles from '../list.module.scss';
import ListStyles from '@styles/PageList.module.scss';
import Table from '@components/Table/Table';

import Button from '@hiredigital/ui/Button';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';

import ListImage from '@components/ListImage';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

class InvitationListItem extends Component {
  static propTypes = {
    invitation: PropTypes.object,
    engagementUuid: PropTypes.string,
    briefUuid: PropTypes.string,
    onDelete: PropTypes.func,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.invitation,
    };
  }

  handleSelectChange = (value, name) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.saveInvitation();
      }
    );
  };

  handleSendInvitation = () => {
    this.setState({ status: EngagementInvitationStatus.SENT.id }, () => {
      this.saveInvitation();
    });
  };

  // handleChange = (event) => {
  //   const { name, value, type, checked } = event.target;

  //   this.setState({
  //       [name]: type === 'checkbox' ? checked : value
  //     },
  //     () => {
  //       if (
  //         name === 'reviewed' &&
  //         checked === true &&
  //         this.state.status === EngagementInvitationStatus.CREATED.id
  //       ) {
  //         // this is for convenience
  //         this.setState({ status: EngagementInvitationStatus.SENT.id });
  //       }
  //       this.saveInvitation();
  //     }
  //   );
  // };

  saveInvitation = () => {
    this.setState({ submitted: true, loading: true });
    const data = {
      status: this.state.status,
      reviewed: this.state.reviewed,
    };

    putEngagementInvitation(this.props.engagementUuid, this.state.id, data).then(
      (response) => {
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    return (
      <div className={classNames(Styles.nestedRow, Styles.invitationRow)}>
        <div className={Styles.nestedTree} />
        <Table.Cell width='250px' className={ListStyles.cell}>
          <Link
            className={ListStyles.title}
            to={`/briefs/${this.props.briefUuid}#inv-${this.state.id}`}>
            {`Invitation`}
          </Link>
        </Table.Cell>
        <Table.Cell width='225px'>
          {this.state.user || this.state.team ? (
            <Fragment>
              <a
                className={Styles.profileLink}
                href={
                  this.state.user
                    ? `/profiles/${this.state.user.uuid}`
                    : `/teams/${this.state.team.uuid}`
                }
                rel='nofollow noopener'
                target='_blank'>
                <ListImage user={this.state.user} team={this.state.team} />
                <span className={Styles.profileLinkText}>
                  {(this.state.user && this.state.user.name) ||
                    (this.state.team && this.state.team.name)}
                </span>
                <IconExternal style={{ marginLeft: 'auto' }} className={Styles.externalIcon} />
              </a>
            </Fragment>
          ) : (
            'No User Added'
          )}
        </Table.Cell>
        <Table.Cell width='480px' className={Styles.hoverParent}>
          <div className={Styles.hoverSection}>
            <div className={Styles.innerSection}>
              <div style={{ display: 'flex' }}>
                <p className={Styles.title} style={{ marginRight: '10px' }}>
                  {`Skills: `}
                </p>
                <p className={Styles.content}>
                  {this.state.user &&
                    this.state.user.portfolio &&
                    this.state.user.portfolio.orderedSkills &&
                    this.state.user.portfolio.orderedSkills.map((skill, index) => {
                      return (
                        <span key={index}>
                          {skill.label}
                          {index === this.state.user.portfolio.orderedSkills.length - 1 ? `` : `, `}
                        </span>
                      );
                    })}
                </p>
              </div>

              {this.props.currentUser && isSalesAdmin(this.props.currentUser) && (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {isBriefAdmin(this.props.currentUser) ? `Client Rate: ` : `Rate: `}
                  </p>
                  <p className={Styles.content}>
                    {isNewRates
                      ? this.state.clientEngagementRate?.rateDisplayText || '-'
                      : `${this.state.brandRate || '0.00'} ${
                          this.state.brandRateType
                            ? RateType.getEnum(this.state.brandRateType).label
                            : ''
                        }`}
                  </p>
                </div>
              )}

              {this.props.currentUser && isPlacementAdmin(this.props.currentUser) && (
                <div style={{ display: 'flex' }}>
                  <p className={Styles.title} style={{ marginRight: '10px' }}>
                    {isBriefAdmin(this.props.currentUser) ? `Talent Rate: ` : `Rate: `}
                  </p>
                  <p className={Styles.content}>
                    {isNewRates
                      ? this.state.talentEngagementRate?.rateDisplayText || '-'
                      : `${this.state.talentRate || '0.00'} ${
                          this.state.talentRateType
                            ? RateType.getEnum(this.state.talentRateType).label
                            : ''
                        }`}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell width='220px'>
          {/* <Dropdown size='sm'>
            <Dropdown.Toggle className={ListStyles.selectDropdown}>
              <p>{EngagementInvitationStatus.getEnum(this.state.status).label}</p>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {EngagementInvitationStatus.values.map((status, statusIndex) => (
                <Dropdown.Item
                  onClick={() => this.handleSelectChange(status.id, 'status')}
                  key={statusIndex}>
                  {status.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
          <SelectMenu
            onChange={(v) => this.handleSelectChange(v?.id, 'status')}
            defaultValue={EngagementInvitationStatus.getEnum(this.state.status)}
            options={EngagementInvitationStatus.values}
          />
        </Table.Cell>
        <Table.Cell width='130px' />
        <Table.Cell width='250px' className={ListStyles.cellRow}>
          {this.state.status === EngagementInvitationStatus.CREATED.id && (
            <Button
              className={Styles.button}
              type={Button.Type.BLUE}
              onClick={this.handleSendInvitation}
              isLoading={this.state.loading}>
              {`Approve`}
            </Button>
          )}
        </Table.Cell>
        <div className={Styles.floatingAction}>
          {/* <Dropdown drop='left' size='sm'>
            <Dropdown.Toggle className={ListStyles.moreDropdown}>
              <IconMoreVertical className={ListStyles.moreIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.user ? (
                <Fragment>
                  {this.props.currentUser && isAnyTalentAdmin(this.props.currentUser) && (
                    <Dropdown.Item target='_blank' href={`/profiles/${this.state.user.uuid}`}>
                      {`View Talent Profile`}
                    </Dropdown.Item>
                  )}
                  {this.props.currentUser && isAnyBriefAdmin(this.props.currentUser) && (
                    <Dropdown.Item
                      target='_blank'
                      href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.key}`}>
                      {`View Hidden Resume`}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    target='_blank'
                    href={`${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.username}`}>
                    {`View Public Resume`}
                  </Dropdown.Item>
                </Fragment>
              ) : (
                ''
              )}

              {!this.state.user &&
                this.state.team &&
                this.props.currentUser &&
                isBriefAdmin(this.props.currentUser) && (
                  <Fragment>
                    <Dropdown.Item
                      target='_blank'
                      href={`${process.env.MARKETING_ENDPOINT}/teams/${this.state.team.slug}`}>
                      {`View Public Page`}
                    </Dropdown.Item>
                  </Fragment>
                )}

              <Dropdown.Item onClick={(e) => this.props.onDelete(e, this.state.id)}>
                {`Delete Invitation`}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <SplitButton
            more={[
              {
                ...(this.state.user &&
                this.props.currentUser &&
                isAnyTalentAdmin(this.props.currentUser)
                  ? {
                      text: 'View Talent Profile',
                      as: 'a',
                      target: '_blank',
                      href: `/profiles/${this.state.user.uuid}`,
                    }
                  : {}),
              },
              {
                ...(this.state.user &&
                this.props.currentUser &&
                isAnyBriefAdmin(this.props.currentUser)
                  ? {
                      text: 'View Hidden Resume',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.key}`,
                    }
                  : {}),
              },
              {
                ...(this.state.user
                  ? {
                      text: 'View Public Resume',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/resumes/${this.state.user.username}`,
                    }
                  : {}),
              },
              {
                ...(!this.state.user &&
                this.state.team &&
                this.props.currentUser &&
                isBriefAdmin(this.props.currentUser)
                  ? {
                      text: 'View Public Page',
                      as: 'a',
                      target: '_blank',
                      href: `${process.env.MARKETING_ENDPOINT}/teams/${this.state.team.slug}`,
                    }
                  : {}),
              },
              {
                text: 'Delete Invitation',
                onClick: (e) => this.props.onDelete(e, this.state.id),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default InvitationListItem;
