import { useState, useEffect, useMemo, useRef } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PortfolioItemType } from '@hiredigital/lib/helpers/enum';
import { getItems, getItem, saveItem } from '@apis/portfolios';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import withParameters from '@hoc/withParameters';

import AppLayout from '@components/Layout/AppLayout';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import ListImage from '@components/ListImage';
import PortfolioViewer from '@hiredigital/ui/PortfolioViewer/PortfolioViewer';
import { authReqNoIntercept } from '@apis/utils';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

import Styles from '@styles/PageList.module.scss';

const PortfolioItemList = ({
  loadData,
  isLoaded,
  hasMore,
  sort,
  searchData,
  sortData,
  location,
  history,
  initialProps,
  ...props
}) => {
  const [search, setSearch] = useState(initialProps?.search);
  const [updateRow, setUpdateRow] = useState(undefined);
  const [data, setData] = useState([]);

  const getItemListData = async (...props) => {
    const d = await getItems(...props);
    setData(d.data.results);
    return d;
  };

  const itemRef = useRef();
  let timeout = null;

  const handleShowThumbnail = (e, item) => {
    const { checked } = e.target;
    const data = {
      ...item,
      showThumbnail: checked,
    };
    setUpdateRow(data);
    saveItem(item.id, { showThumbnail: checked });
  };

  const handleShowLiveUrl = (e, item) => {
    const { checked } = e.target;
    const data = {
      ...item,
      showLiveUrl: checked,
    };
    setUpdateRow(data);
    saveItem(item.id, { showLiveUrl: checked });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        size: 'medium',
        minWidth: 250,
        canSort: true,
        accessor: (u) => (
          <p className={Styles.title} onClick={() => itemRef.current.open(u)}>
            {u.title || u.caseItems?.[0]?.title || 'Untitled Portfolio Item'}
          </p>
        ),
      },

      {
        Header: 'Name',
        id: 'user__first_name',
        size: 'small',
        canSort: true,
        width: 130,
        truncate: false,
        accessor: (u) => u.name,
        Cell: ({ row: { original: item } }) => {
          return (
            <>
              {item?.user ? (
                <Link className={Styles.profile} to={`/profiles/${item?.user?.uuid}`}>
                  <ListImage user={item.user} />
                  <MultiLineEllipsis data-test-id='client-table-item' className={Styles.name}>
                    {item.user?.name}
                  </MultiLineEllipsis>
                </Link>
              ) : item?.teams?.[0] ? (
                <Link className={Styles.profile} to={`/teams/${item?.teams?.[0]?.uuid}`}>
                  <ListImage team={item?.teams?.[0]} />
                  <MultiLineEllipsis data-test-id='client-table-item' className={Styles.name}>
                    {item?.teams?.[0]?.name}
                  </MultiLineEllipsis>
                </Link>
              ) : (
                'No User'
              )}
            </>
          );
        },
      },

      {
        Header: 'Client',
        id: 'publication__name',
        size: 'small',
        canSort: true,
        width: 100,
        accessor: (u) => u?.publication?.name,
      },
      {
        Header: 'Keywords',
        id: 'skills',
        size: 'small',
        canSort: true,
        minWidth: 100,
        accessor: (u) => u?.skills?.map((s) => s.label)?.join(', '),
      },
      {
        Header: 'Type',
        id: 'type',
        size: 'small',
        canSort: true,
        width: 75,
        accessor: (u) => PortfolioItemType.getLabel(u.type),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Portfolio Items');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const memoSearchParams = useMemo(() => {
    return { search };
  }, [search]);

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Portfolio Items`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getItemListData}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        data={data}
        updateRow={updateRow}
      />

      <PortfolioViewer
        request={authReqNoIntercept}
        ref={itemRef}
        items={data}
        isAdmin={true}
        getItem={getItem}
      />
    </AppLayout>
  );
};

PortfolioItemList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(PortfolioItemList);
