export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const toReadableDuration = (duration) => {
  let ret = '';

  if (duration) {
    let [hours, minutes, seconds] = duration.split(':');
    seconds = Math.round(parseInt(seconds));
    ret = `${hours}:${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }
  return ret;
};
