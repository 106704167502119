import { Fragment, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

// import Icon from '@hiredigital/ui/Icon/Icon';
import { useQuery } from '@hiredigital/lib/hooks/useQuery';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import Loader from '@hiredigital/ui/Loader';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button';
import FileViewer from '@hiredigital/ui/FileViewer/NewFileViewer';

import { refetchResume } from '@apis/resumes';
import {
  isSearchElevatedAdmin,
  isTalentSearchBrandRatesAdmin,
  isTalentSearchTalentRatesAdmin,
} from '@helpers/permissions';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { useUser } from '@context/user';

import AppLayout from '@components/Layout/AppLayout';
import Table from '@components/Table/Table';

import Styles from '@styles/PageList.module.scss';
import TalentStyles from './talents.module.scss';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  connectSearchBox,
  connectInfiniteHits,
  connectHits,
  createInfiniteHitsSessionStorageCache,
  connectHighlight,
  DynamicWidgets,
  HierarchicalMenu,
  Menu,
  Panel,
  connectMenu,
  connectRefinementList,
  RefinementList,
  Pagination,
  Stats,
} from 'react-instantsearch-dom';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

// const searchClient = algoliasearch(`${process.env.ALGOLIA_APP}`, `${process.env.ALGOLIA_KEY}`);
const searchClient = instantMeiliSearch(`${process.env.MSEARCH_API}`, `${process.env.MSEARCH_KEY}`);

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });
  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <mark key={index}>{part.value}</mark>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  );
};

const CustomHighlight = connectHighlight(Highlight);

const Hits = ({
  hits,
  insights,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
  handleOpenDialog,
}) => {
  const currentUser = useUser();

  const handleFetchResume = async (uuid) => {
    return await refetchResume(uuid);
  };

  return (
    <>
      {hits.map((user, index) => {
        return (
          <div className={classNames(Styles.talentCard)} key={index}>
            <Table.Cell sticky={true} width='330px'>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <p
                    onClick={() => handleOpenDialog(user.resume_file)}
                    className={TalentStyles.name}>
                    <CustomHighlight hit={user} attribute='name' />
                  </p>

                  {/* <Dropdown drop='right' size='sm' style={{ margin: '-5px 0' }}>
                    <Dropdown.Toggle className={Styles.moreDropdown}>
                      <IconMoreVertical className={Styles.moreIcon} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleOpenDialog(user.resume_file)}>
                        {`View Resume`}
                      </Dropdown.Item>
                      <Dropdown.Item target='_blank' href={`${user.resume_file}`}>
                        {`Download Resume`}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <SplitButton
                    more={[
                      { text: 'View Resume', onClick: () => handleOpenDialog(user.resume_file) },
                      {
                        text: 'Download Resume',
                        as: 'a',
                        target: '_blank',
                        href: `${user.resume_file}`,
                      },
                    ]}
                  />
                </div>

                <p className={TalentStyles.headline}>
                  {(user?.occupation && <CustomHighlight hit={user} attribute='occupation' />) || (
                    <span className={Styles.inactive}>{'No Headline'}</span>
                  )}
                </p>
              </div>
            </Table.Cell>

            <Table.Cell width='250px'>
              <p className={TalentStyles.primarySkill}>
                {user?.companyName && <CustomHighlight hit={user} attribute='companyName' />}
              </p>
            </Table.Cell>

            <Table.Cell width='300px'>
              <div className={TalentStyles.primarySkill}>
                <CustomHighlight hit={user} attribute={`email`} />
              </div>
              <div className={TalentStyles.skill}>
                <CustomHighlight hit={user} attribute={`phone`} />
              </div>
            </Table.Cell>

            <Table.Cell width='400px'>
              {user.employment?.map((emp, i) => {
                return (
                  <div key={i}>
                    <p className={TalentStyles.title}>
                      <CustomHighlight hit={user} attribute={`employment[${i}].title`} />
                    </p>
                    <p className={TalentStyles.content}>
                      <CustomHighlight hit={user} attribute={`employment[${i}].companyName`} />
                    </p>
                  </div>
                );
              })}
            </Table.Cell>
            <Table.Cell width='400px'>
              {user.education?.map((emp, i) => {
                return (
                  <div key={i}>
                    <p className={TalentStyles.title}>
                      <CustomHighlight hit={user} attribute={`education[${i}].degree`} />
                      {emp.degree && emp.major && `, `}
                      <CustomHighlight hit={user} attribute={`education[${i}].major`} />
                    </p>
                    <p className={TalentStyles.content}>
                      <CustomHighlight hit={user} attribute={`education[${i}].school`} />
                    </p>
                  </div>
                );
              })}
            </Table.Cell>

            <Table.Cell width='150px'>
              <div className={TalentStyles.content}>
                {user.address?.city && (
                  <p>
                    <CustomHighlight hit={user} attribute={`address.city`} />
                  </p>
                )}
                {user.country?.label && (
                  <p>
                    <CustomHighlight hit={user} attribute={`country.label`} />
                  </p>
                )}
              </div>
            </Table.Cell>
          </div>
        );
      })}
    </>
  );
};

// 2. Connect the component using the connector
const CustomHits = connectHits(Hits);
const sessionStorageCache = createInfiniteHitsSessionStorageCache();

// 3. Use your connected widget

const SearchBox = ({ currentRefinement, isSearchStalled, refine, onChange: handleOnChange }) => {
  const onChange = (event) => {
    refine(event.currentTarget.value);
    handleOnChange?.(event);
  };
  return (
    <form noValidate action='' role='search' style={{ flexGrow: '1', display: 'flex' }}>
      <input
        type='search'
        className={Styles.headerSearch}
        name='search'
        type='text'
        placeholder='Search'
        value={currentRefinement}
        onChange={onChange}
      />
      {currentRefinement && (
        <span onClick={() => refine('')}>
          <IconClose className={Styles.clearButton} />
        </span>
      )}
    </form>
  );
};

const CustomSearchBox = connectSearchBox(SearchBox);

const SelMenu = ({ label, className, defaultValue, items, refine, searchForItems }) => {
  const onSearchChange = (value) => {
    searchForItems(value);
  };

  return (
    <Select
      className={classNames(Styles.headerSelect, className)}
      name={label}
      placeholder={label}
      defaultValue={defaultValue}
      onInputChange={onSearchChange}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value}
      onChange={(i) => {
        const a = i.map((e) => {
          return e.label;
        });
        refine(a);
      }}
      options={items}
      isClearable
      isPaginated
      isMulti
    />
  );
};

const CustomRefinementList = connectRefinementList(SelMenu);
const TalentList = ({ isLoaded, hasMore, sort, sortData, ...props }) => {
  let query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useUser();

  const [search, setSearch] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogUrl, setDialogUrl] = useState();
  const scrollParentRef = document.getElementById('scrollContainer');

  useEffect(() => {
    const s = query?.get('search');
    if (s) {
      setSearch(s);
    }
  }, []);

  const handleOpenDialog = (url) => {
    setDialogUrl(url);
    setIsDialogOpen(true);
  };

  const tableHeader = [
    {
      title: 'Name',
      field: 'name',
      width: '330px',
      sortable: false,
      isSticky: true,
    },
    {
      title: 'Company',
      field: 'company',
      width: '250px',
      sortable: false,
    },

    {
      title: 'Contact',
      field: 'email',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Work Experience',
      field: 'experience',
      width: '400px',
      sortable: false,
    },
    {
      title: 'Education',
      field: 'education',
      width: '400px',
      sortable: false,
    },
    {
      title: 'Location',
      field: 'country__label',
      width: '150px',
      sortable: false,
    },
  ];

  useEffect(() => {
    setPageTitle('Resumes');
  }, []);

  const onSearchChange = (event) => {
    const { name, value } = event?.currentTarget;

    setTimeout(() => {
      const params = new URLSearchParams({ search: value });
      history.replace({ pathname: location.pathname, search: params.toString() });
    }, 1);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={`${process.env.ALGOLIA_RESUMES_INDEX}`}>
      <Configure advancedSyntax={true} />
      <AppLayout
        location={location}
        bodyClass={Styles.wideBody}
        header={
          <div className={Styles.headerRow}>
            <div className={Styles.headerActive}>{`Resumes`}</div>
            <CustomSearchBox
              autoFocus
              showLoadingIndicator
              onChange={onSearchChange}
              defaultRefinement={search}
            />
            <DynamicWidgets fallbackWidget={RefinementList} className={Styles.widgets}>
              <CustomRefinementList label='City' attribute='address.city' searchable />
            </DynamicWidgets>
            <DynamicWidgets fallbackWidget={RefinementList} className={Styles.widgets}>
              <CustomRefinementList
                label='Country'
                className={Styles.extraSmallSelect}
                attribute='country.label'
                searchable
              />
            </DynamicWidgets>
          </div>
        }>
        <div className={Styles.talentContainer}>
          <div className={Styles.talentHeader}>
            {tableHeader &&
              tableHeader.length &&
              tableHeader.map((header, index) => {
                const column = header.field;
                return (
                  <Table.Header
                    width={header.width}
                    key={index}
                    sticky={header.isSticky}
                    className={classNames(
                      { [Styles.sort]: header.sortable },
                      header.sortable &&
                        (sort[column] === false
                          ? Styles.sortInactive
                          : sort[column]
                          ? Styles.sortActive
                          : '')
                    )}>
                    {header.title}
                  </Table.Header>
                );
              })}
          </div>

          <CustomHits cache={sessionStorageCache} handleOpenDialog={handleOpenDialog} />
        </div>

        <div className={TalentStyles.pagination}>
          <Pagination />
          <Stats />
        </div>
        <Dialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          minWidth={900}
          minHeight={900}
          maxWidth={'100vw'}
          maxHeight={`100vh`}>
          <FileViewer className={TalentStyles.fileViewer} url={dialogUrl} />
          <div style={{ marginTop: '20px' }}>
            <Button
              style={{ marginTop: 20 }}
              onClick={() => setIsDialogOpen(false)}
              type={Button.Type.LIGHT_GRAY}>
              {'Close'}
            </Button>
          </div>
        </Dialog>
      </AppLayout>
    </InstantSearch>
  );
};

TalentList.propTypes = {
  location: PropTypes.object,
  currentUser: PropTypes.object,
};

export default TalentList;
