import { authRequest } from './utils';

export const getTimesheets = async (config) => {
  const url = `admin/user-timesheets/`;
  return await authRequest.get(url, config);
};

export const putTimeEntries = async (id, data, config) => {
  const url = `admin/timeentries/${id}/`;
  return await authRequest.put(url, data, config);
};
