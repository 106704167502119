import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppLayout from '@components/Layout/AppLayout';
import { toReadableDateTime, toReadableDate } from '@hiredigital/lib/helpers/date';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { getBriefRegistrations } from '@apis/briefs';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import Styles from '@styles/PageList.module.scss';
import withParameters from '@hoc/withParameters';

const BriefRegistrationList = ({ location, history, initialProps, ...props }) => {
  const [search, setSearch] = useState(initialProps.search);
  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'talents_required ',
        canSort: true,
        minWidth: 250,
        accessor: (b) => (
          <Link to={`/brief-registrations/${b?.uuid}`}>
            {b?.talentsRequired || 'Draft Registration'}
          </Link>
        ),
      },
      {
        Header: 'Organization',
        id: 'organization__name',
        canSort: true,
        accessor: (b) => b.organizationName,
      },
      {
        Header: 'Email',
        id: 'email',
        canSort: true,
        accessor: (b) => b.userEmail,
      },
      {
        Header: 'Call Time',
        Cell: ({ row: { original: brief } }) => {
          return (
            <div>
              {brief?.call?.startTime ? toReadableDateTime(brief.call.startTime) : ''}
              {brief?.call?.conferenceUrl && (
                <div>
                  <a className={Styles.title} href={brief?.call.conferenceUrl} target='_blank'>
                    {brief?.call.conferenceUrl}
                  </a>
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Notes',
        accessor: (b) => b.notes,
      },
      {
        Header: 'Created',
        id: 'created',
        canSort: true,
        accessor: (b) => toReadableDate(b.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Brief Registrations');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Brief Registrations`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getBriefRegistrations}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        rowHeight={'auto'}
      />
    </AppLayout>
  );
};

BriefRegistrationList.propTypes = {
  location: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withParameters(BriefRegistrationList);
