import Button from '@hiredigital/ui/Button';
import AppLayout from '@components/Layout/AppLayout';
import { useHistory } from 'react-router-dom';
import Text from '@hiredigital/ui/Text/Text';

import Styles from '@styles/error.module.scss';

const Error = (props) => {
  const history = useHistory();
  const head = {
    title: `Page Not Found | Hire Digital`,
    meta: [
      {
        name: 'description',
        content: "The page you're looking for does not exist. ",
      },
    ],
  };

  return (
    <AppLayout pageHead={head} className={Styles.body}>
      <div className={Styles.container}>
        <div className={Styles.text}>
          <Text element='h1' className={Styles.headline} size={Text.Size.HEADLINE}>
            {'Page Not Found'}
          </Text>
          <Text className={Styles.subheadline} size={Text.Size.H3}>
            {"The page you're looking for doesn't exist."}
          </Text>
          <Button
            className={Styles.button}
            type={Button.Type.BLUE}
            onClick={() => history.push(`/`)}
            title='Back Home'>{`Back to Home`}</Button>
        </div>
      </div>
    </AppLayout>
  );
};

export default Error;
