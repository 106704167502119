import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withParameters from '@hoc/withParameters';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import AppLayout from '@components/Layout/AppLayout';
import ListImage from '@components/ListImage';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { getUsers } from '@apis/users';
import { Role } from '@hiredigital/lib/helpers/enum';
import { isAdmin } from '@helpers/permissions';
import { useUser } from '@context/user';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import { Tablist, Tab } from 'evergreen-ui/esm/tabs';
import MultilineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

import Styles from '@styles/PageList.module.scss';

const tabItems = [
  {
    value: Role.CLIENT.id,
    label: 'Clients',
  },
  {
    value: Role.TALENT.id,
    label: 'Talents',
  },
  {
    value: 3,
    label: 'All',
  },
];

const ProfileList = ({ location, initialProps, ...props }) => {
  const currentUser = useUser();

  const [search, setSearch] = useState(initialProps.search);
  const [role, setRole] = useState(null);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'first_name',
        size: 'medium',
        canSort: true,
        accessor: (u) => u.name,
        truncate: false,
        Cell: ({ row: { original: user } }) => {
          return (
            <Link className={Styles.profile} to={`/profiles/${user?.uuid}`}>
              <ListImage user={user} />
              <MultilineEllipsis
                data-test-id='profile-table-item'
                className={classNames(Styles.name, !user.name && Styles.empty)}>
                {user.name || 'No Name'}
              </MultilineEllipsis>
            </Link>
          );
        },
      },
      {
        Header: 'Headline',
        id: 'profile__headline',
        size: 'medium',
        canSort: true,
        accessor: (u) => u.profile?.headline,
      },
      {
        Header: 'Primary Skill',
        id: 'portfolio__primary_skill',
        size: 'small',
        canSort: true,
        accessor: (u) => u.portfolio?.primarySkill?.noun,
      },
      {
        Header: 'Date Joined',
        id: 'date_joined',
        size: 'small',
        canSort: true,
        accessor: (u) => toReadableDate(u.dateJoined),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Profile Review');
  }, []);

  useEffect(() => {
    setIsUserLoaded(Object.keys(currentUser).length > 0);
  }, [currentUser]);

  useEffect(() => {
    if (isUserLoaded) {
      const enumRole = Role.getEnum(
        isAdmin(currentUser) ? parseInt(initialProps?.role || Role.TALENT.id) : Role.TALENT.id
      );
      setRole({ label: enumRole.label, value: enumRole.id });
    }
  }, [isUserLoaded]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 1000) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleTabSelect = (item) => {
    const isAllSelected = item?.value === tabItems?.[2].value;
    setRole(isAllSelected ? {} : item);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Profile Review`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            defaultValue={search}
            placeholder='Search'
            onChange={handleSearchChange}
          />

          {isAdmin(currentUser) && (
            <div>
              <Tablist marginBottom={0} flexBasis={240} marginRight={0}>
                {tabItems.map((tab) => (
                  <Tab
                    key={tab?.value}
                    id={tab?.value}
                    onSelect={() => handleTabSelect(tab)}
                    isSelected={tab?.value === (role?.value || tabItems?.[2].value)}>
                    {tab?.label}
                  </Tab>
                ))}
              </Tablist>
              {/* <MemoSelectMenu
                title='Filter by Role'
                hasFilter={false}
                height={175}
                position={Position.BOTTOM_RIGHT}
                options={[
                  { label: 'Talents', value: String(Role.TALENT.id) },
                  { label: 'Clients', value: String(Role.CLIENT.id) },
                ]}
                selected={String(role?.value)}
                onSelect={handleRoleSelect}
                onDeselect={handleRoleDeSelect}
                isMultiSelect={true}>
                <button className={Styles.filter}>
                  {`Filter by Role`}
                  <div className={Styles.arrowDown} />
                </button>
              </MemoSelectMenu> */}
            </div>
          )}
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getUsers}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        filters={{ role: role?.value }}
        excludeParams={isAdmin(currentUser) ? [] : ['role']}
      />
    </AppLayout>
  );
};

ProfileList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(ProfileList);
