import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import classNames from 'classnames';
import PropTypes from 'prop-types';

import Styles from '@styles/pages/Dashboard.module.scss';

import AppLayout from '@components/Layout/AppLayout';
import {
  isTalentAdmin,
  isSearchAdmin,
  isAnyBriefAdmin,
  isClientAdmin,
  isAccountManager,
  isAnyTalentAdmin,
  isAnyAssignmentAdmin,
  isAdmin,
  isFinance,
} from '@helpers/permissions';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconProfile from '@hiredigital/ui/Icon/icons/profile.inline.svg';
import IconTalent from '@hiredigital/ui/Icon/icons/talent.inline.svg';
import IconSearch from '@hiredigital/ui/Icon/icons/search.inline.svg';
import IconBrief from '@hiredigital/ui/Icon/icons/brief.inline.svg';
import IconChatUsers from '@hiredigital/ui/Icon/icons/chat-users.inline.svg';
import IconProject from '@hiredigital/ui/Icon/icons/project.inline.svg';
import IconCompany from '@hiredigital/ui/Icon/icons/company.inline.svg';
import IconInvoice from '@hiredigital/ui/Icon/icons/invoice.inline.svg';
import IconTimesheet from '@hiredigital/ui/Icon/icons/timesheet.inline.svg';
import IconEmail from '@hiredigital/ui/Icon/icons/email.inline.svg';
import IconChat from '@hiredigital/ui/Icon/icons/chat.inline.svg';
import FileViewer from '@hiredigital/ui/FileViewer/NewFileViewer';

import { Role } from '@hiredigital/lib/helpers/enum';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { useUser } from '@context/user';

const Dashboard = ({ location }) => {
  const currentUser = useUser();
  const [roles, setRoles] = useState();

  useEffect(() => {
    setPageTitle('Admin Dashboard');
    let roles = Role.getEnums(currentUser?.roles)
      .map((r) => r.label)
      .join(', ');
    roles = roles + ' Roles';
    setRoles(roles);

    // Add any additional queries here
  }, []);

  return (
    <AppLayout location={location} header={`Dashboard`}>
      <div className={Styles.body}>
        <div className={Styles.listContainer}>
          {currentUser && (
            <Fragment>
              {(isAnyTalentAdmin(currentUser) || currentUser.adminPermission?.talent) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Talents`}</div>
                  {(isTalentAdmin(currentUser) || currentUser.adminPermission?.talent) && (
                    <Link to='profiles' className={Styles.sectionItem}>
                      <IconProfile className={Styles.icon} />
                      {`Profile Review`}
                    </Link>
                  )}
                  {/*{(isTalentAdmin(currentUser) || currentUser.adminPermission?.team) && (
                    <Link to='teams' className={Styles.sectionItem}>
                      <IconTalent className={Styles.icon} />
                      {`Team Profile Review`}
                    </Link>
                  )}*/}
                  {isSearchAdmin(currentUser) && (
                    <Link to='talents' className={Styles.sectionItem}>
                      <IconSearch className={Styles.icon} />
                      {`Talent Search`}
                    </Link>
                  )}
                  {isSearchAdmin(currentUser) && (
                    <Link to='talent-search' className={Styles.sectionItem}>
                      <IconSearch className={Styles.icon} />
                      {`Talent Search v2`}
                    </Link>
                  )}
                  {isSearchAdmin(currentUser) && (
                    <Link to='resume-search' className={Styles.sectionItem}>
                      <IconSearch className={Styles.icon} />
                      {`Resume Search`}
                    </Link>
                  )}
                  {(isSearchAdmin(currentUser) || currentUser.adminPermission?.talent) && (
                    <Link to='portfolio-items' className={Styles.sectionItem}>
                      <IconSearch className={Styles.icon} />
                      {`Portfolio Items Search`}
                    </Link>
                  )}
                </div>
              )}
              {(isAnyBriefAdmin(currentUser) ||
                isAnyAssignmentAdmin(currentUser) ||
                currentUser?.adminPermission?.brief ||
                currentUser?.adminPermission?.assignment ||
                currentUser?.adminPermission?.project) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Briefs`}</div>

                  {(isAnyBriefAdmin(currentUser) || currentUser?.adminPermission?.brief) && (
                    <Link to='briefs' className={Styles.sectionItem}>
                      <IconBrief className={Styles.icon} />
                      {`Briefs`}
                    </Link>
                  )}
                  {(isAnyAssignmentAdmin(currentUser) ||
                    currentUser?.adminPermission?.assignment) && (
                    <Link to='assignments' className={Styles.sectionItem}>
                      <IconChatUsers className={Styles.icon} />
                      {`Assignments`}
                    </Link>
                  )}

                  {(isAnyBriefAdmin(currentUser) || currentUser?.adminPermission?.project) && (
                    <Link to='projects' className={Styles.sectionItem}>
                      <IconProject className={Styles.icon} />
                      {`Projects`}
                    </Link>
                  )}

                  {(isAnyBriefAdmin(currentUser) || isSearchAdmin(currentUser)) && (
                    <Link to='jazzhr-jobs' className={Styles.sectionItem}>
                      <IconProject className={Styles.icon} />
                      {`JazzHR Jobs`}
                    </Link>
                  )}
                </div>
              )}
              {(isClientAdmin(currentUser) ||
                isAccountManager(currentUser) ||
                currentUser?.adminPermission?.org ||
                currentUser?.adminPermission?.clientRegistration) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Clients`}</div>
                  {(isClientAdmin(currentUser) ||
                    isAccountManager(currentUser) ||
                    currentUser?.adminPermission?.org) && (
                    <Link to='clients' className={Styles.sectionItem}>
                      <IconCompany className={Styles.icon} />
                      {`Clients`}
                    </Link>
                  )}
                  {(isAdmin(currentUser) || currentUser.adminPermission?.clientRegistration) && (
                    <Fragment>
                      <Link to='org-registrations' className={Styles.sectionItem}>
                        <IconCompany className={Styles.icon} />
                        {`Enterprise Leads`}
                      </Link>
                    </Fragment>
                  )}
                  {(isAdmin(currentUser) || currentUser?.adminPermission?.briefRegistration) && (
                    <Fragment>
                      <Link to='prospects' className={Styles.sectionItem}>
                        <IconTalent className={Styles.icon} />
                        {`Professional Leads`}
                      </Link>
                    </Fragment>
                  )}
                  {(isAdmin(currentUser) || currentUser?.adminPermission?.orgCredit) && (
                    <Link to='prepayments' className={Styles.sectionItem}>
                      <IconInvoice className={Styles.icon} />
                      {`Prepayments`}
                    </Link>
                  )}
                </div>
              )}
              {(isFinance(currentUser) || currentUser.adminPermission?.finance) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Finance`}</div>
                  <Link to='/finance-talents' className={Styles.sectionItem}>
                    <IconTalent className={Styles.icon} />
                    {`Talents`}
                  </Link>
                  <Link to='/finance-clients' className={Styles.sectionItem}>
                    <IconCompany className={Styles.icon} />
                    {`Clients`}
                  </Link>
                  <Link to='/wise-recipients' className={Styles.sectionItem}>
                    <IconCompany className={Styles.icon} />
                    {`Wise Recipients`}
                  </Link>
                </div>
              )}
              {(isAdmin(currentUser) ||
                isFinance(currentUser) ||
                currentUser.adminPermission?.timesheet ||
                currentUser.adminPermission?.timetracker) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Timetracker`}</div>
                  {/*<Link to='/timesheets' className={Styles.sectionItem}>
                    <IconTimesheet className={Styles.icon} />
                    {`Timesheet Search (old)`}
                  </Link>*/}

                  <Link to='/events' className={Styles.sectionItem}>
                    <IconSearch className={Styles.icon} />
                    {`Timetracker Summary`}
                  </Link>

                  <Link to='/timetracker/events' className={Styles.sectionItem}>
                    <IconSearch className={Styles.icon} />
                    {`Timetracker Events`}
                  </Link>

                  <Link to='/timetracker/tree' className={Styles.sectionItem}>
                    <IconTimesheet className={Styles.icon} />
                    {`Timetracker Tree by Talent`}
                  </Link>

                  <Link
                    to='/timetracker/tree?topLevelOrganization=true'
                    className={Styles.sectionItem}>
                    <IconTimesheet className={Styles.icon} />
                    {`Timetracker Tree by Client`}
                  </Link>
                </div>
              )}
              {(isClientAdmin(currentUser) || currentUser?.adminPermission?.email) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Emails`}</div>
                  <Link to='emails' className={Styles.sectionItem}>
                    <IconEmail className={Styles.icon} />
                    {`Email Search`}
                  </Link>
                  {isAdmin(currentUser) && (
                    <Link to='email-templates' className={Styles.sectionItem}>
                      <IconEmail className={Styles.icon} />
                      {`Email Templates`}
                    </Link>
                  )}
                </div>
              )}

              {(isAdmin(currentUser) ||
                isAnyTalentAdmin(currentUser) ||
                currentUser?.adminPermission?.briefRegistration ||
                currentUser?.adminPermission?.userRegistration ||
                currentUser?.adminPermission?.clientRegistration) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Registrations`}</div>
                  {/*{(isAdmin(currentUser) || currentUser.adminPermission?.briefRegistration) && (
                    <Fragment>
                      <Link to='brief-registrations' className={Styles.sectionItem}>
                        <IconBrief className={Styles.icon} />
                        {`Brief Registrations`}
                      </Link>
                    </Fragment>
                  )}*/}
                  {(isAnyTalentAdmin(currentUser) ||
                    currentUser.adminPermission?.userRegistration) && (
                    <Link to='talent-registrations' className={Styles.sectionItem}>
                      <IconProfile className={Styles.icon} />
                      {`Talent Registrations`}
                    </Link>
                  )}
                </div>
              )}
              {isAdmin(currentUser) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Notifications`}</div>
                  <Link to='notifications' className={Styles.sectionItem}>
                    <IconTalent className={Styles.icon} />
                    {`Notifications`}
                  </Link>
                </div>
              )}

              {(isAdmin(currentUser) || currentUser?.adminPermission?.discussion) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Discussions`}</div>
                  <Link to='messages' className={Styles.sectionItem}>
                    <IconChat className={Styles.icon} />
                    {`Message Review`}
                  </Link>
                </div>
              )}
              {isAdmin(currentUser) && (
                <div className={Styles.section}>
                  <div className={Styles.title}>{`Support Staff`}</div>
                  <Link to='support' className={Styles.sectionItem}>
                    <IconTalent className={Styles.icon} />
                    {`Support User Management`}
                  </Link>
                </div>
              )}

              {/*{isAdmin(currentUser) && (

                    <div className={Styles.section}>
                      <div className={Styles.title}>{`Bills`}</div>
                      <Link to="bills" className={Styles.sectionItem}>
                        <Icon.Invoice className={Styles.icon} />
                        {`Bills`}
                      </Link>
                    </div>
                    )}*/}
            </Fragment>
          )}
        </div>
      </div>
    </AppLayout>
  );
};
Dashboard.propTypes = {
  search: PropTypes.object,
  location: PropTypes.object,
};
export default Dashboard;
