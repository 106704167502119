import PropTypes from 'prop-types';
// import classNames from 'classnames';
// import Styles from './Table.module.scss';
import Cell from './Cell';
import Header from './Header';

const Table = (props) => {
  return <div />;
};

Table.propTypes = {
  className: PropTypes.string,
};

Table.Cell = Cell;
Table.Header = Header;

export default Table;
