import Toggle from 'react-toggle';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import Styles from './TimetrackerSettings.module.scss';

const TimetrackerSettings = ({
  onSaveSetting,
  isBusy,
  onTalentSettingChange,
  talentSettings: {
    activityTrackingRequired,
    screenshotTrackingRequired,
    allowManualTime,
    trackingDisabled,
  },
}) => {
  return (
    <Card>
      <Card.Header title={`Settings`} />
      <Card.Item>
        <div className={Styles.row}>
          <div className={Styles.toggleItem}>
            <Toggle
              id='activity-tracking'
              name='activityTrackingRequired'
              defaultChecked={activityTrackingRequired}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='activity-tracking' className={Styles.toggleLabel}>
              {`Require Activity Tracking`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            <Toggle
              id='screenshot-tracking'
              name='screenshotTrackingRequired'
              defaultChecked={screenshotTrackingRequired}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='screenshot-tracking' className={Styles.toggleLabel}>
              {`Require Screenshot Tracking`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            <Toggle
              id='allow-manual-time'
              name='allowManualTime'
              defaultChecked={allowManualTime}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='allow-manual-time' className={Styles.toggleLabel}>
              {`Allow Manual Time`}
            </label>
          </div>
          <div className={Styles.toggleItem}>
            {/* IMPROVEMENT: disable 'Require Activity Tracking' and 'Require Screenshot Tracking' once tracking is disabled */}
            <Toggle
              id='disable-tracking-time'
              name='trackingDisabled'
              defaultChecked={trackingDisabled}
              onChange={onTalentSettingChange}
            />
            <label htmlFor='disable-tracking-time' className={Styles.toggleLabel}>
              {`Disable Tracking`}
            </label>
          </div>
        </div>
      </Card.Item>
      <Card.Footer right>
        <Button name='cancel' type={Button.Type.BLUE} onClick={onSaveSetting} isLoading={isBusy}>
          {`Save Settings`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

TimetrackerSettings.propTypes = {};

export default TimetrackerSettings;
