/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import classNames from 'classnames';

import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Checkbox from '@hiredigital/ui/Checkbox';
import IconExclamation from '@hiredigital/ui/Icon/icons/exclamation.inline.svg';

import { postEvent, putEvent, getOverlappingEvents } from '@apis/tracking';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import { durationToSeconds } from '@hiredigital/lib/helpers/date';
import addSeconds from 'date-fns/addSeconds';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import Styles from './TimesheetEntry.module.scss';

import withEmailLabel from '@hoc/withEmailLabel';
import { getUserTimesheets } from '@apis/users';
import { onUsersLoadOptions } from '@apis/dropdown';
import Toggle from '@hiredigital/ui/Toggle';

const UserSelect = withEmailLabel(Select);

const Entry = ({
  isShown,
  data,
  onClose,
  onCloseComplete,
  onUpdate,
  OverlapRowComponent,
  onAdd,
  ...props
}) => {
  const isEditing = !!data?.uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState(data?.task?.name);
  const [duration, setDuration] = useState(data?.duration);
  const [locked, setLocked] = useState(data?.locked);
  const [timesheet, setTimesheet] = useState(data?.task?.timesheet);
  const [start, setStart] = useState(data?.startTime ? parseISO(data?.startTime) : new Date());
  const [isShownEntry, setIsShownEntry] = useState(isShown);
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState(data?.user);
  const [shouldIgnore, setShouldIgnore] = useState(false);
  const [overlapEvents, setOverlapEvents] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isForRecheck, setIsForRecheck] = useState(false);

  const handleSaveActivity = async () => {
    try {
      setIsLoading(true);

      if (overlapEvents?.length > 0) setIsForRecheck(false);

      if (!(await validate())) return;

      const seconds = durationToSeconds(duration);
      const startTime = formatISO(start);
      const endTime = formatISO(addSeconds(new Date(start), seconds));

      const payload = {
        manual: data?.manual,
        startTime,
        endTime,
        duration,
        user,
        locked,
        task: {
          name: description,
          timesheet,
        },
      };

      if (isEditing) {
        const response = await putEvent(data.uuid, payload);
        onUpdate?.(response?.data);
      } else {
        const response = await postEvent(payload);
        onAdd?.(response?.data);
      }

      setIsShownEntry(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onUserTimesheetsLoadOptions = async (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const response = await getUserTimesheets(user?.uuid, data);
    return {
      options: response.data.results,
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  const validate = async () => {
    let errors = {};

    if (!user || !Object.keys(user)?.length) {
      errors = { ...errors, user: 'User is required' };
    }

    if (!start) {
      errors = { ...errors, datetime: 'Date & Time is required' };
    }

    if (!duration) {
      errors = { ...errors, duration: 'Duration is required' };
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    }

    if (!shouldIgnore && (await hasOverlap())) {
      return false;
    }

    return true;
  };

  const hasOverlap = async () => {
    try {
      const payload = {
        startTime: formatISO(start),
        duration: duration,
        excludeEvent: data?.uuid,
        user: user?.uuid,
      };
      const {
        data: { results },
      } = await getOverlappingEvents(payload);
      const items = results || [];
      setOverlapEvents(items);
      return items.length > 0;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const handleClose = () => setIsShownEntry(false);

  const handleStartChange = (date) => {
    setErrors({});
    setStart(date);

    if (overlapEvents?.length > 0) setIsForRecheck(true);
  };

  const handleDurationChange = (e) => {
    setErrors({});
    setDuration(e.target.value);

    if (overlapEvents?.length > 0) setIsForRecheck(true);
  };

  const handleUserChange = (e) => {
    setOverlapEvents([]);
    setErrors({});
    setUser(e.target.value);
  };

  useEffect(() => {
    setIsShownEntry(isShown);
  }, [isShown]);

  useEffect(() => {
    if (!isShownEntry) onClose?.();
  }, [isShownEntry]);

  useEffect(() => {
    if (overlapEvents?.length > 0) {
      setIsSaveDisabled(!shouldIgnore);
    }
  }, [shouldIgnore, overlapEvents]);

  useEffect(() => {
    setIsSaveDisabled(!!overlapEvents?.length);
  }, [overlapEvents]);

  useEffect(() => {
    if (isForRecheck) {
      setIsSaveDisabled(false);
    }
  }, [isForRecheck]);

  return (
    <Dialog
      isOpen={isShownEntry}
      title={`${isEditing ? 'Edit' : 'Add'} Activity`}
      onClose={handleClose}
      onCloseComplete={onCloseComplete}
      className={Styles.dialogBody}
      {...props}>
      <InputContainer>
        <TextInput
          defaultValue={description}
          name='activity'
          label={`Activity`}
          onChange={(e) => {
            setErrors({});
            setDescription(e.target.value);
          }}
        />
      </InputContainer>
      <InputContainer>
        <UserSelect
          id='userSelect'
          defaultValue={user}
          name='user'
          label='User'
          getOptionLabel={({ name }) => name}
          getOptionValue={({ uuid }) => uuid}
          onChange={handleUserChange}
          isPaginated
          loadOptions={onUsersLoadOptions}
          error={errors?.user}
          disabled={isEditing}
        />
      </InputContainer>
      <InputContainer>
        <Select
          data-test-id='assignment'
          classNamePrefix='s-contact'
          name='timesheet'
          label='Assignment'
          menuPlacement='top'
          getOptionLabel={({ talentTitle }) => talentTitle}
          getOptionValue={({ uuid }) => uuid}
          onChange={(e) => {
            setErrors({});
            setTimesheet(e.target.value);
          }}
          isPaginated
          loadOptions={onUserTimesheetsLoadOptions}
          defaultValue={timesheet}
          error={errors?.timesheet}
          disabled={!user}
        />
      </InputContainer>
      <InputGroup>
        <InputContainer>
          <DatePicker
            dateFormat='MM/dd/yyyy hh:mm aa'
            onChange={handleStartChange}
            label={`Date & Time`}
            value={start}
            error={errors?.datetime}
            showTimeInput
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            name='duration'
            onChange={handleDurationChange}
            label={`Duration (HH:MM:SS)`}
            value={duration || ''}
            mask={`99:99:99`}
            error={errors?.duration}
          />
        </InputContainer>
      </InputGroup>
      <InputContainer>
        <Toggle
          id='locked'
          name='locked'
          label={`Paid`}
          onChange={(e) => setLocked(e.target.checked)}
          defaultChecked={locked}
        />
      </InputContainer>
      {!!overlapEvents?.length && (
        <>
          <div className={Styles.overlapContainer}>
            <div className={Styles.row}>
              <IconExclamation
                className={classNames(Styles.icon, {
                  [Styles.gray]: isForRecheck,
                })}
              />
              {isForRecheck ? (
                <p className={Styles.text}>
                  {`Click `}
                  <b>{`Save Activity`}</b>
                  {` to check again.`}
                </p>
              ) : (
                <p className={Styles.text}>{`We found ${overlapEvents.length} conflict${
                  overlapEvents.length > 1 ? 's' : ''
                }. Please resolve.`}</p>
              )}
            </div>
            <div className={Styles.overlapList}>
              {OverlapRowComponent &&
                overlapEvents?.map((data, index) => (
                  <OverlapRowComponent
                    key={index}
                    event={data}
                    showActionItems={false}
                    className={Styles.row}
                  />
                ))}
            </div>
          </div>
          {!isForRecheck && (
            <div className={Styles.checkContainer}>
              <Checkbox
                name='ignore'
                checked={shouldIgnore}
                labelClass={Styles.checkbox}
                label={'Ignore and save anyway'}
                onChange={(e) => setShouldIgnore(e.target.checked)}
              />
            </div>
          )}
        </>
      )}

      <div className={Styles.actionRow}>
        <Button
          type={Button.Type.BLUE}
          onClick={handleSaveActivity}
          isLoading={isLoading}
          disabled={isLoading || isSaveDisabled}>
          {'Save Activity'}
        </Button>
        <Button type={Button.Type.LIGHT_GRAY} onClick={handleClose} disabled={isLoading}>
          {'Cancel'}
        </Button>
      </div>
    </Dialog>
  );
};

Entry.propTypes = {};

export default Entry;
