import { Fragment, useState } from 'react';
import { getUserEmployment, deleteUserEmployment } from '@apis/users';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import { dateSort } from '@hiredigital/lib/helpers/utils';
import EmploymentItem from './EmploymentItem';
import Styles from '../Resume.module.scss';

const Employment = ({ user }) => {
  const [employmentList, setEmploymentList] = useState(user.employment || []);

  const getEmploymentList = () => {
    getUserEmployment(user.uuid).then((response) => {
      setEmploymentList(response.data.results);
    });
  };

  const handleNewEmployment = () => {
    setEmploymentList([...employmentList, {}]);
  };

  const updateEmployment = (employment) => {
    const list = employmentList.filter((el) => {
      return el && el.id;
    });

    if (employment) {
      list.push(employment);
    } else {
      // setState({ isEditing: false });
    }
    setEmploymentList(list);
    //setIsEditing(false);
  };

  const updateEmploymentItem = (employment) => {
    const item = employmentList.map((v) => (v.id === employment.id ? employment : v));
    setEmploymentList(item);
  };

  const deleteEmployment = (employment) => {
    if (employment.id) {
      deleteUserEmployment(user.uuid, employment.id).then(
        (response) => {
          getEmploymentList();
        },
        (error) => {
          //setLoading(false);
        }
      );
    } else {
      updateEmployment();
    }
  };

  return (
    <Card>
      <Card.Header>
        <span>{`Work Experience`}</span>
        <div className={Styles.action}>
          <Button name='add new' type={Button.Type.BLUE} onClick={handleNewEmployment}>
            {`Add New`}
          </Button>
        </div>
      </Card.Header>
      <Fragment>
        {employmentList.length === 0 && (
          <Card.Item>
            <p className={Styles.empty}>{`No employment history has been added.`}</p>
          </Card.Item>
        )}
        <div>
          {employmentList &&
            employmentList
              .sort(dateSort)
              .map((employment, index) => (
                <EmploymentItem
                  user={user}
                  employment={employment}
                  updateEmployment={updateEmployment}
                  updateEmploymentItem={updateEmploymentItem}
                  deleteEmployment={deleteEmployment}
                  key={employment.id || index}
                />
              ))}
        </div>
      </Fragment>
    </Card>
  );
};

export default Employment;
