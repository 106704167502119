import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppLayout from '@components/Layout/AppLayout';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { toReadableShortDateTime } from '@hiredigital/lib/helpers/date';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import { getOrgRegistrations } from '@apis/orgs';
import withParameters from '@hoc/withParameters';
import Styles from '@styles/PageList.module.scss';

const OrgRegistrationList = ({ location, history, initialProps, ...props }) => {
  const [search, setSearch] = useState(initialProps.search);

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        canSort: true,
        minWidth: 250,
        Cell: ({ row: { original: org } }) => {
          return (
            <Link to={`/org-registrations/${org?.uuid}`}>{org?.name || 'Draft Registration'}</Link>
          );
        },
      },
      {
        Header: 'Client',
        id: 'organization__name',
        canSort: true,
        minWidth: 250,
        Cell: ({ row: { original } }) => {
          return (
            <Link to={`/clients/${original?.organization?.uuid}`}>
              {original?.organization?.name}
            </Link>
          );
        },
      },
      {
        Header: 'User',
        id: 'user_first_name',
        canSort: true,
        accessor: (u) => `${u.userFirstName} ${u.userLastName}`,
      },
      {
        Header: 'Email',
        id: 'user_email',
        canSort: true,
        accessor: (u) => u.userEmail,
      },
      {
        Header: 'Website',
        id: 'website',
        canSort: true,
        accessor: (u) => u.website,
      },
      {
        Header: 'Created',
        id: 'created',
        canSort: true,
        accessor: (u) => toReadableShortDateTime(u.created),
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Client Registrations');
  }, []);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Client Registrations`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getOrgRegistrations}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
      />
    </AppLayout>
  );
};

OrgRegistrationList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(OrgRegistrationList);
