import { authRequest } from './utils';

export const getJazzHrJobs = async (config) => {
  const url = `/admin/jazzhr/jobs/`;
  return await authRequest.get(url, config);
};

export const getJazzHrJob = async (jobId, config) => {
  const url = `/admin/jazzhr/jobs/${jobId}/`;
  return await authRequest.get(url, config);
};

export const getJazzHrJobResumes = async (jobId, config) => {
  const url = `/admin/jazzhr/jobs/${jobId}/get-resumes/`;
  return await authRequest.get(url, config);
};

export const getJazzHrJobApplications = async (jobId, config) => {
  const url = `/admin/jazzhr/jobs/${jobId}/applications/`;
  return await authRequest.get(url, config);
};

export const getJazzHrJobApplication = async (jobId, applicationId, config) => {
  const url = `/admin/jazzhr/jobs/${jobId}/applications/${applicationId}/`;
  return await authRequest.get(url, config);
};
