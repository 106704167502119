import { useMemo, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import SelectMenu from '@hiredigital/ui/SelectMenu';
// import { SelectMenu, Position } from 'evergreen-ui';
import SplitButton from '@hiredigital/ui/Button/SplitButton';

import { getEmails, putEmail } from '@apis/emails';
import withParameters from '@hoc/withParameters';
import { EmailStatus } from '@hiredigital/lib/helpers/enum';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { Timer } from '@components/Timer/Timer';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import AppLayout from '@components/Layout/AppLayout';

import Styles from '@styles/PageList.module.scss';
import PageStyles from './list.module.scss';
import sub from 'date-fns/sub';

// const MemoSelectMenu = memo(SelectMenu);

const DateColumn = memo(({ email, onStatusUpdate }) => {
  const [scheduledTime, setScheduledTime] = useState(email.scheduledTime);

  const handleScheduleTimeChange = (date) => {
    setScheduledTime(date);
    const scheduledTime = new Date(date);

    putEmail(email?.id, { scheduledTime })
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Datetime
      value={scheduledTime}
      onChange={handleScheduleTimeChange}
      closeOnSelect={true}
      renderInput={(props, openCalendar, closeCalendar) => {
        return (
          <button className={PageStyles.timerRow} onClick={openCalendar} style={{ height: '30px' }}>
            <Timer deadline={scheduledTime} />
            <div className={Styles.toggle} />
          </button>
        );
      }}
    />
  );
});

const StatusColumn = memo(({ email, onStatusUpdate }) => {
  const [status, setStatus] = useState(email?.status);

  const handleUpdateStatus = (value) => {
    putEmail(email?.id, { status: value })
      .then(({ data }) => {
        setStatus(data?.status);
        onStatusUpdate(data);
      })
      .catch((error) => {});
  };

  return (
    <div className={PageStyles.selectStatus}>
      <SelectMenu
        onChange={(item) => handleUpdateStatus(item.id)}
        options={[...EmailStatus.values, { id: 3, label: 'Not Sent' }].map((a) => ({
          label: a.label,
          id: a.id,
        }))}
        defaultValue={email.status !== null ? EmailStatus.getEnum(email.status) : `Not Sent`}
      />
      {/* <MemoSelectMenu
        title='Select Status'
        hasFilter={false}
        height={175}
        position={Position.BOTTOM_RIGHT}
        options={[...EmailStatus.values, { id: null, label: 'Not Sent' }].map((a) => ({
          label: a.label,
          value: a.id,
        }))}
        selected={email.status}
        onSelect={(item) => handleUpdateStatus(item.value)}>
        <div className={Styles.toggleContainer} data-test-id='status'>
          {email.status !== null ? EmailStatus.getLabel(email.status) : `Not Sent`}

          <div className={Styles.toggle} />
        </div>
      </MemoSelectMenu> */}
    </div>
  );
});

const ActionColumn = memo(({ email, onStatusUpdate }) => {
  const [showActions, setShowActions] = useState(email?.status === EmailStatus.QUEUED.id);

  const handleSendNow = () => {
    const dateNow = new Date();
    const scheduledTime = sub(new Date(dateNow), { minutes: 1 });
    const newEmail = { ...email, scheduledTime, status: EmailStatus.SENT.id };
    onStatusUpdate(newEmail);
    setShowActions(false);

    putEmail(email?.id, { scheduledTime })
      .then(({ data }) => {
        // setShowActions(false);
        // onStatusUpdate(data);
      })
      .catch((error) => {});
  };

  const handleUpdateStatus = (value) => {
    setShowActions(value === EmailStatus.QUEUED.id);

    const newEmail = { ...email, status: value };
    onStatusUpdate(newEmail);
    setShowActions(false);

    putEmail(email?.id, { status: value })
      .then(({ data }) => {
        // setShowActions(false);
        // onStatusUpdate(data);
      })
      .catch((error) => {});
  };

  return showActions ? (
    <SplitButton
      more={[
        { text: 'Cancel', onClick: () => handleUpdateStatus(null) },
        { text: 'Send Now', onClick: handleSendNow },
      ]}
    />
  ) : null;
});

const EmailList = memo(({ location, history, initialProps, ...props }) => {
  const [updateRow, setUpdateRow] = useState(undefined);
  const [search, setSearch] = useState(initialProps?.search);

  let timeout = null;

  const columns = useMemo(
    () => [
      {
        Header: 'Recipient',
        canSort: true,
        id: 'to',
        minWidth: 200,
        accessor: (e) => <Link to={`/emails/${e?.id}`}>{e?.to || 'No Recipient'}</Link>,
      },
      {
        Header: 'Subject',
        canSort: true,
        id: 'subject',
        minWidth: 300,
        accessor: (e) => e.subject,
      },
      {
        Header: '',
        id: 'action',
        width: 25,
        minWidth: 25,
        truncate: false,
        accessor: (e) => <ActionColumn email={e} onStatusUpdate={handleStatusUpdate} />,
      },
      {
        Header: 'Status',
        canSort: true,
        id: 'status',
        width: 100,
        minWidth: 100,
        truncate: false,
        accessor: (e) => <StatusColumn email={e} onStatusUpdate={handleStatusUpdate} />,
      },
      {
        Header: 'Date',
        canSort: true,
        id: 'scheduled_time',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        truncate: false,
        accessor: (e) => <DateColumn email={e} onStatusUpdate={handleStatusUpdate} />,
      },
    ],
    []
  );

  useEffect(() => {
    setPageTitle('Emails');
  }, []);

  const handleStatusUpdate = (data) => refreshList(data);

  const refreshList = (data) => {
    setUpdateRow(data);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    debounceSearch(() => setSearch(value));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.tableBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Emails`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            onChange={handleSearchChange}
          />
        </div>
      }>
      <PaginatedTable
        columns={columns}
        getListData={getEmails}
        defaultPage={initialProps?.page}
        defaultLimit={initialProps?.limit}
        defaultOrdering={initialProps?.ordering}
        search={search}
        updateRow={updateRow}
        rowHeight={40}
      />
    </AppLayout>
  );
});

EmailList.propTypes = {
  location: PropTypes.object,
};

export default withParameters(EmailList);
