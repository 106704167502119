import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip, Position } from 'evergreen-ui';
import Styles from './Side.module.scss';

const Item = memo(({ icon: Icon, to, label }) => {
  return (
    <Tooltip content={label} position={Position.RIGHT}>
      <NavLink
        className={Styles.item}
        activeClassName={Styles.active}
        to={to}
        exact={false}
        strict={false}>
        <Icon className={Styles.icon} />
      </NavLink>
    </Tooltip>
  );
});

Item.propTypes = {
  icon: PropTypes.func,
  to: PropTypes.string,
  label: PropTypes.string,
};

export default Item;
