import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { getTalentScores, deleteTalentScore } from '@apis/talents';
import ScoreItem from './ScoreItem';

import Styles from './Score.module.scss';

class RatingList extends Component {
  static propTypes = {
    userUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      scores: [],
      isEditing: false,
    };
  }

  componentDidMount = () => {
    this.loadScoreList();
  };

  loadScoreList = () => {
    this.setState({ isLoaded: false });
    getTalentScores(this.props.userUuid).then(({ data }) => {
      this.setState({ scores: data.results });
    });
  };

  handleNewScore = () => {
    this.setState({
      scores: [...this.state.scores, {}],
      isEditing: true,
    });
  };

  handleDeleteScore = (scoreId) => {
    if (scoreId) {
      deleteTalentScore(this.props.userUuid, scoreId).then(
        (response) => {
          const scores = this.state.scores.filter(({ id }) => !!id && id !== scoreId);
          this.setState({ scores });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      const scores = this.state.scores.filter(({ id }) => !!id);
      this.setState({ scores });
    }
  };

  handleCreateScore = (score) => {
    // if (score) {
    //   this.setState({ scores: [...this.state.scores, score] });
    // }
  };

  handleUpdateScore = (score) => {
    if (score) {
      this.setState({
        scores: this.state.scores.map((item) => {
          return item.id === score.id ? score : item;
        }),
      });
    }
  };

  handleScoreCancel = (scoreId) => {
    if (!scoreId) {
      this.setState({
        scores: this.state.scores.filter(({ id }) => !!id),
      });
    }
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <span>{`Talent Scores`}</span>
          <div className={Styles.action}>
            <Button name='add new' type={Button.Type.BLUE} onClick={this.handleNewScore}>
              {`Add New`}
            </Button>
          </div>
        </Card.Header>
        <Fragment>
          {this.state.scores.length === 0 && (
            <Card.Item>
              <p className={Styles.empty}>{`No scores have been added.`}</p>
            </Card.Item>
          )}
          <div>
            {this.state.scores?.map((score, index) => (
              <ScoreItem
                key={score?.id || index}
                index={index}
                score={score}
                userUuid={this.props.userUuid}
                onScoreDelete={this.handleDeleteScore}
                onScoreCreate={this.handleCreateScore}
                onScoreUpdate={this.handleUpdateScore}
                onScoreCancel={this.handleScoreCancel}
              />
            ))}
          </div>
        </Fragment>
      </Card>
    );
  }
}

export default RatingList;
