import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';

import { deleteOrgTalent, getOrgTalents } from '@apis/orgs';
import Talent from './Talent';
import Team from './Team';
import Styles from './Styles.module.scss';

class TalentList extends Component {
  static propTypes = {
    orgUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      talents: [],
    };
  }

  componentDidMount = () => {
    this.loadOrganizationTalents().then(({ data: { results } }) => {
      this.setState({
        talents: results,
      });
    });
  };

  handleDeleteTalent = (talentUuid) => {
    this.setState({ loading: true });
    deleteOrgTalent(this.props.orgUuid, talentUuid).then(
      (response) => {
        const talents = this.state.talents.filter(({ uuid }) => uuid !== talentUuid);
        this.setState({ talents, loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  loadOrganizationTalents = () => getOrgTalents(this.props.orgUuid);

  render() {
    return (
      <Card>
        <Card.Header title={`Talents`} />
        <Fragment>
          <div>
            {(this.state.talents &&
              this.state.talents.length &&
              this.state.talents.map((talent, index) =>
                talent && talent.team ? (
                  <Team
                    team={talent}
                    key={talent.uuid}
                    orgUuid={this.props.orgUuid}
                    onDeleteTalent={this.handleDeleteTalent}
                  />
                ) : (
                  <Talent
                    talent={talent}
                    key={talent.uuid}
                    orgUuid={this.props.orgUuid}
                    onDeleteTalent={this.handleDeleteTalent}
                  />
                )
              )) ||
              ''}
            {this.state.talents && this.state.talents.length === 0 && (
              <Card.Item>
                <div className={Styles.empty}>{`No talents added`}</div>
              </Card.Item>
            )}
          </div>
        </Fragment>
      </Card>
    );
  }
}

export default TalentList;
