import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import ReactTooltip from 'react-tooltip';
import AsyncPaginate from 'react-select-async-paginate';
import InfiniteScroll from 'react-infinite-scroller';
import Select from 'react-select';

import Loader from '@hiredigital/ui/Loader';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';

import convert from 'htmr';

import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { getRegions } from '@apis/common';
import { UserStatus, ServiceRateType } from '@hiredigital/lib/helpers/enum';
import { isSearchElevatedAdmin } from '@helpers/permissions';
import withDataSource from '@hoc/withNewDataSource';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { useUser } from '@context/user';

import AppLayout from '@components/Layout/AppLayout';
import Table from '@components/Table/Table';

import Styles from '@styles/PageList.module.scss';
import TalentStyles from './talents.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

const TalentList = ({
  loadData,
  isLoaded,
  hasMore,
  search,
  sort,
  searchData,
  filterData,
  sortData,
  location,
  history,
  ...props
}) => {
  const currentUser = useUser();
  const [filterLocation, setFilterLocation] = useState(undefined);
  const [data, setData] = useState();
  const [meta, setMeta] = useState({ nextPage: 1 });

  const scrollParentRef = document.getElementById('scrollContainer');

  const tableHeader = [
    {
      title: 'Talent',
      field: 'first_name',
      width: '330px',
      sortable: true,
      isSticky: true,
    },

    {
      title: '',
      field: '',
      width: '32px',
      sortable: false,
    },
    {
      title: 'Rates',
      field: 'rates',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Skills',
      field: 'skills',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Work Experience',
      field: 'experience',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Reviews',
      field: 'reviews',
      width: '250px',
      sortable: false,
    },
    {
      title: 'Score',
      field: 'talent_scores',
      width: '250px',
      sortable: false,
    },
    {
      title: 'Assignments',
      field: 'assignments',
      width: '200px',
      sortable: false,
    },
    {
      title: 'Status',
      field: 'profile__status',
      width: '180px',
      sortable: true,
    },
    {
      title: 'Privacy',
      field: 'privacy',
      width: '100px',
      sortable: false,
    },
    {
      title: 'Agreement',
      field: 'agreement',
      width: '100px',
      sortable: false,
    },
    {
      title: 'Location',
      field: 'country',
      width: '150px',
      sortable: false,
    },
    {
      title: 'Notes',
      field: 'notes',
      width: '300px',
      sortable: false,
    },
    {
      title: 'Signup Date',
      field: 'date_joined',
      width: '150px',
      sortable: true,
    },
  ];

  useEffect(() => {
    setPageTitle('Talents');
    loadData({ page: 1, placeId: filterLocation }, true, handleResponse);
  }, []);

  const handleSort = (column) => {
    sortData(column, {}, handleResponse);
  };

  const handleSearch = (event) => {
    //const params = { ...(!!filterLocation && { placeId: filterLocation }) };
    //console.log(params)
    searchData(
      {
        page: 1,
        search: event.target.value,
        placeId: filterLocation,
      },
      handleResponse
    );
  };

  const handleLoadMore = () => {
    if (!isLoaded || !meta.nextPage || !hasMore) {
      return;
    } else {
      loadData({ page: meta.nextPage, placeId: filterLocation }, false, handleResponse);
    }
  };

  const handleResponse = (d, m) => {
    setData(d);
    setMeta(m);
  };

  const handleLocationFilterChange = (location) => {
    const placeId = location?.placeId;
    setFilterLocation(placeId || undefined);
    filterData({ page: 1, placeId }, handleResponse);
  };

  const onLocationLoadOptions = async (search, loadedOptions, { page }) => {
    if (!search) {
      return {
        options: [],
      };
    }
    try {
      const response = await getRegions(search);
      return {
        options: response?.data?.predictions,
      };
    } catch (err) {
      return {
        options: [],
      };
    }
  };

  const getRates = (user) => (isNewRates ? user?.talentRates : user?.serviceRates);

  return (
    <AppLayout
      location={location}
      bodyClass={Styles.wideBody}
      header={
        <div className={Styles.headerRow}>
          <div className={Styles.headerActive}>{`Talent Search`}</div>
          <input
            className={Styles.headerSearch}
            name='search'
            type='text'
            placeholder='Search'
            value={search}
            onChange={handleSearch}
          />
          <AsyncPaginate
            className={Styles.headerSelect}
            selectValue={filterLocation}
            defaultValue={filterLocation}
            name='location'
            placeholder='Location'
            getOptionLabel={({ description }) => description}
            getOptionValue={({ placeId }) => placeId}
            onChange={handleLocationFilterChange}
            loadOptions={onLocationLoadOptions}
            debounceTimeout={500}
            isClearable
            isPaginated
            additional={{ page: 1 }}
            SelectComponent={Select}
            loadOptionsOnMenuOpen={false}
            createOptionPosition={'first'}
          />
        </div>
      }>
      <div className={Styles.talentContainer}>
        <div className={Styles.talentHeader}>
          {tableHeader &&
            tableHeader.length &&
            tableHeader.map((header, index) => {
              const column = header.field;
              return (
                <Table.Header
                  width={header.width}
                  key={index}
                  sticky={header.isSticky}
                  className={classNames(
                    { [Styles.sort]: header.sortable },
                    header.sortable &&
                      (sort[column] === false
                        ? Styles.sortInactive
                        : sort[column]
                        ? Styles.sortActive
                        : '')
                  )}
                  {...(header.sortable && {
                    onClick: () => handleSort(column),
                  })}>
                  {header.title}
                </Table.Header>
              );
            })}
        </div>
        {isLoaded && data && data.length > 0 && (
          <InfiniteScroll
            initialLoad={true}
            pageStart={1}
            loadMore={handleLoadMore}
            hasMore={hasMore}
            threshold={500}
            useWindow={false}
            getScrollParent={() => scrollParentRef}
            loader={
              <div className={Styles.listLoader} key={0}>
                <Loader />
              </div>
            }>
            <Fragment>
              {data.map((user, index) => (
                <div className={classNames(Styles.talentCard)} key={index}>
                  <Table.Cell sticky={true} width='330px'>
                    <div style={{ display: 'flex' }}>
                      <img
                        className={classNames(Styles.picture, Styles.smallPicture)}
                        src={user.profile && user.profile.picture}
                        alt=''
                      />
                      <div>
                        <a href={`/profiles/${user.uuid}`}>
                          <p className={TalentStyles.name}>{user.name}</p>
                        </a>
                        <p className={TalentStyles.primarySkill}>
                          {(user.portfolio &&
                            user.portfolio.primarySkill &&
                            user.portfolio.primarySkill.noun) ||
                            'No Primary Skill'}
                        </p>

                        <p className={TalentStyles.headline}>
                          {(user.profile && user.profile.headline) || 'No Headline'}
                        </p>
                      </div>

                      <div className={TalentStyles.match}>
                        {(((user.rank && Number(user.rank)) ||
                          (user.similarity && Number(user.similarity) > 0.1)) && (
                          <div>
                            {Math.floor(Math.max(Number(user.rank), Number(user.similarity)) * 100)}

                            {`% Match`}
                          </div>
                        )) ||
                          ''}

                        {(user.profileRank && Number(user.profileRank) && (
                          <div>
                            {Math.floor(Number(user.profileRank) * 100)}
                            {`% Profile`}
                          </div>
                        )) ||
                          ''}

                        {(user.locationRank && Number(user.locationRank) && (
                          <div>
                            {Math.floor(Number(user.locationRank) * 100)}
                            {`% Location`}
                          </div>
                        )) ||
                          ''}
                        {(user.employmentRank && Number(user.employmentRank) && (
                          <div>
                            {Math.floor(Number(user.employmentRank) * 100)}
                            {`% Work`}
                          </div>
                        )) ||
                          ''}

                        {(user.educationRank && Number(user.educationRank) && (
                          <div>
                            {Math.floor(Number(user.educationRank) * 100)}
                            {`% Education`}
                          </div>
                        )) ||
                          ''}

                        {(user.portfolioWorkRank && Number(user.portfolioWorkRank) && (
                          <div>
                            {Math.floor(Number(user.portfolioWorkRank) * 100)}
                            {`% Portfolio`}
                          </div>
                        )) ||
                          ''}

                        {(user.resumeRank && Number(user.resumeRank) && (
                          <div>
                            {Math.floor(Math.max(Number(user.resumeRank)) * 100)}
                            {`% Resume`}
                          </div>
                        )) ||
                          ''}
                      </div>
                    </div>
                  </Table.Cell>

                  <Table.Cell width='32px' style={{ padding: '10px 3px' }}>
                    {/* <Dropdown drop='right' size='sm'>
                      <Dropdown.Toggle className={Styles.moreDropdown}>
                        <IconMoreVertical className={Styles.moreIcon} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/resumes/${
                            (user.portfolio && user.portfolio.accessKey) || ''
                          }`}>
                          {`View Resume`}
                        </Dropdown.Item>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/resumes/${user.username}`}>
                          {`View Public Resume`}
                        </Dropdown.Item>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/portfolios/${
                            (user.portfolio && user.portfolio.accessKey) || ''
                          }`}>
                          {`View Portfolio`}
                        </Dropdown.Item>
                        <Dropdown.Item
                          target='_blank'
                          href={`${process.env.MARKETING_ENDPOINT}/portfolios/${user.username}`}>
                          {`View Public Portfolio`}
                        </Dropdown.Item>
                        {currentUser && isSearchElevatedAdmin(currentUser) && (
                          <Dropdown.Item href={`/profiles/${user.uuid}`}>
                            {`Edit Profile`}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <SplitButton
                      more={[
                        {
                          text: 'View Resume',
                          as: 'a',
                          target: '_blank',
                          href: `${process.env.MARKETING_ENDPOINT}/resumes/${
                            (user.portfolio && user.portfolio.accessKey) || ''
                          }`,
                        },
                        {
                          text: 'View Public Resume',
                          as: 'a',
                          target: '_blank',
                          href: `${process.env.MARKETING_ENDPOINT}/resumes/${user.username}`,
                        },
                        {
                          text: 'View Portfolio',
                          as: 'a',
                          target: '_blank',
                          href: `${process.env.MARKETING_ENDPOINT}/portfolios/${
                            (user.portfolio && user.portfolio.accessKey) || ''
                          }`,
                        },
                        {
                          text: 'View Public Portfolio',
                          as: 'a',
                          target: '_blank',
                          href: `${process.env.MARKETING_ENDPOINT}/portfolios/${user.username}`,
                        },
                        {
                          ...(currentUser &&
                            isSearchElevatedAdmin(currentUser) && {
                              text: 'Edit Profile',
                              as: 'a',
                              href: `/profiles/${user.uuid}`,
                            }),
                        },
                      ]}
                    />
                  </Table.Cell>

                  <Table.Cell width='300px'>
                    {getRates(user) &&
                      getRates(user).map((rate, i) => {
                        const itemRate = isNewRates ? rate.maxRate : rate.rate;
                        const itemCurrency = isNewRates ? rate.currencyCode : rate.currency;

                        return (
                          <div key={i} className={Styles.subRow}>
                            {rate.clientRate && (
                              <div className={TalentStyles.content}>
                                <span className={TalentStyles.mainRate} data-tip='Client Rate'>
                                  {`${itemCurrency} ${rate.clientRate} ${ServiceRateType.getLabel(
                                    rate.rateType
                                  )}`}
                                  <ReactTooltip place='top' type='dark' effect='solid' />
                                </span>
                              </div>
                            )}

                            {!!itemRate && (
                              <div className={TalentStyles.content}>
                                <span className={TalentStyles.secondaryRate} data-tip='Talent Rate'>
                                  {`${itemCurrency} ${itemRate} ${ServiceRateType.getLabel(
                                    rate.rateType
                                  )}`}
                                  <ReactTooltip place='top' type='dark' effect='solid' />
                                </span>
                              </div>
                            )}

                            {currentUser && (rate.name || rate.description) && (
                              <Fragment>
                                <p className={TalentStyles.title}>{rate.name || ''}</p>
                                <div className={TalentStyles.content}>
                                  {rate.description?.replace(/<\/?[^>]+(>|$)/g, '') || ''}
                                </div>
                              </Fragment>
                            )}

                            {currentUser &&
                              isSearchElevatedAdmin(currentUser) &&
                              rate.internalNotes && (
                                <Fragment>
                                  <p className={TalentStyles.title}>{`Internal Notes:`}</p>
                                  <div className={TalentStyles.content}>
                                    {rate.internalNotes.replace(/<\/?[^>]+(>|$)/g, '')}
                                  </div>
                                </Fragment>
                              )}
                          </div>
                        );
                      })}
                  </Table.Cell>

                  <Table.Cell width='300px'>
                    {user.portfolio &&
                      !!user.portfolio.orderedSkills.length &&
                      user.portfolio.orderedSkills.map((skill, i) => {
                        return (
                          <div key={i} className={TalentStyles.skill}>
                            {skill.label}{' '}
                          </div>
                        );
                      })}
                  </Table.Cell>

                  <Table.Cell width='300px'>
                    {user.employment &&
                      user.employment.map((emp, i) => {
                        return (
                          <div key={i}>
                            <p className={TalentStyles.title}>{emp.jobTitle}</p>
                            <p className={TalentStyles.content}>{emp.company}</p>
                          </div>
                        );
                      })}
                  </Table.Cell>

                  <Table.Cell width='250px'>
                    {user.ratings &&
                      user.ratings.map((rating, idx) => {
                        return (
                          <div key={idx} className={Styles.subRow}>
                            <p className={TalentStyles.title}>
                              {`Review:`} {(rating.overallRating && rating.overallRating) || ''}
                            </p>
                            <div className={TalentStyles.content}>
                              {rating.message && convert(rating.message)}
                            </div>
                          </div>
                        );
                      })}
                  </Table.Cell>

                  <Table.Cell width='250px'>
                    {user.talentScores &&
                      user.talentScores.map((score, idx) => {
                        return (
                          <div key={idx} className={Styles.subRow}>
                            <div className={TalentStyles.scoreList}>
                              <div className={TalentStyles.scoreItem}>
                                <p className={TalentStyles.scoreTitle}>{`Skills`}</p>
                                <p className={TalentStyles.score}>
                                  {(score && score.skillsScore) || 'N/A'}
                                </p>
                              </div>
                              <div className={TalentStyles.scoreItem}>
                                <p className={TalentStyles.scoreTitle}>{`Availability`}</p>
                                <p className={TalentStyles.score}>
                                  {(score && score.availabilityScore) || 'N/A'}
                                </p>
                              </div>
                              <div className={TalentStyles.scoreItem}>
                                <p className={TalentStyles.scoreTitle}>{`Quality of Work`}</p>
                                <p className={TalentStyles.score}>
                                  {(score && score.qualityOfWorkScore) || 'N/A'}
                                </p>
                              </div>
                              <div className={TalentStyles.scoreItem}>
                                <p className={TalentStyles.scoreTitle}>{`Timeliness`}</p>
                                <p className={TalentStyles.score}>
                                  {(score && score.timelinessScore) || 'N/A'}
                                </p>
                              </div>
                              <div className={TalentStyles.scoreItem}>
                                <p className={TalentStyles.scoreTitle}>{`Communication`}</p>
                                <p className={TalentStyles.score}>
                                  {(score && score.communicationScore) || 'N/A'}
                                </p>
                              </div>
                              <div className={TalentStyles.scoreItem}>
                                <p className={TalentStyles.title}>{`Overall Score`}</p>
                                <p className={TalentStyles.title}>
                                  {(score && score.overallScore && score.overallScore.toFixed(2)) ||
                                    'N/A'}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Table.Cell>

                  <Table.Cell width='200px'>
                    {user.organizationTalents &&
                      user.organizationTalents.map((talent, i) => {
                        return (
                          <div
                            key={i}
                            className={classNames(
                              TalentStyles.title,
                              talent.isActive ? TalentStyles.active : TalentStyles.inactive
                            )}>
                            <div data-tip={talent.isActive ? 'Active' : 'Inactive'}>
                              {talent.organization.name}
                              <ReactTooltip place='top' type='dark' effect='solid' />
                            </div>
                          </div>
                        );
                      })}
                  </Table.Cell>
                  <Table.Cell width='180px'>
                    <p>{user.profile && UserStatus.getEnum(user.profile.status).label}</p>

                    {/*{user.profile &&<Dropdown size="sm">
                            <Dropdown.Toggle className={Styles.selectDropdown}>
                              <p>{UserStatus.getEnum(user.profile.status).label}</p>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {UserStatus.values.map((status, statusIndex) => (
                                <Dropdown.Item onClick={(e) =>handleStatusChange(e, user)}
                                  key={statusIndex}>
                                  {status.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>}*/}
                  </Table.Cell>
                  <Table.Cell width='100px'>
                    {user.portfolio && user.portfolio.isHidden ? (
                      `Private`
                    ) : user.portfolio &&
                      user.portfolio.requestHidden &&
                      !user.portfolio.isHidden ? (
                      <span
                        style={{
                          color: '#ec392f',
                        }}>{`Private Pending`}</span>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell width='100px'>{user.agreementSigned ? `Signed` : ''}</Table.Cell>
                  <Table.Cell width='150px'>
                    <div className={TalentStyles.content}>
                      {`${user.portfolio?.currentLocation?.description || ''}`}
                      {user.address?.country && user.portfolio?.currentLocation && <br />}
                      {`${user.address?.city} ${user.address?.region} ${user.address?.country}`}
                    </div>
                  </Table.Cell>
                  <Table.Cell width='300px'>
                    {user.priority ? (
                      <Fragment>
                        <p className={TalentStyles.title}>{`Priority:`}</p>
                        <div className={TalentStyles.content}>{user.priority}</div>
                      </Fragment>
                    ) : (
                      <div />
                    )}
                    {user.notes ? (
                      <Fragment>
                        <p className={TalentStyles.title}>{`Profile Review Notes:`}</p>
                        <div className={TalentStyles.content}> {convert(user.notes)}</div>
                      </Fragment>
                    ) : (
                      <div />
                    )}
                    {user.adminNotes ? (
                      <Fragment>
                        <p className={TalentStyles.title}>{`Admin Notes:`}</p>
                        <div className={TalentStyles.content}> {user.adminNotes}</div>
                      </Fragment>
                    ) : (
                      <div />
                    )}
                  </Table.Cell>

                  <Table.Cell width='150px'>
                    <p className={classNames(TalentStyles.label)}>
                      {toReadableDate(user.dateJoined)}
                    </p>
                  </Table.Cell>
                </div>
              ))}
            </Fragment>
          </InfiniteScroll>
        )}
      </div>
      {!isLoaded && (
        <div className={Loader.Styles.container}>
          <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
        </div>
      )}

      {isLoaded && (!data || data.length === 0) && (
        <div
          className={classNames(Styles.emptyState, Styles.talentContainer)}>{`No Users Found`}</div>
      )}
    </AppLayout>
  );
};

TalentList.propTypes = {
  location: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withDataSource(TalentList, 'talent-search');
