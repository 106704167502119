import PropTypes from 'prop-types';
import Styles from './ScreenshotViewer.module.scss';

const ScreenshotItem = ({ src, ...restProps }) => {
  return (
    <div className={Styles.screenshotItem}>
      <img className={Styles.screenshotImage} src={src} {...restProps} />
    </div>
  );
};

ScreenshotItem.propTypes = {
  src: PropTypes.string,
};

export default ScreenshotItem;
