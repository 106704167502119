import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'evergreen-ui';
import Styles from './Styles.module.scss';

const ListImage = memo(({ user, team }) => {
  const isUser = user ? true : false;

  return (
    <span className={Styles.parent}>
      <Tooltip
        content={
          (user?.issues === 2 && `Resume is Private`) ||
          (user?.issues === 1 && `User Requests Private`) ||
          undefined
        }
        isShown={user?.issues ? undefined : false}>
        <div>
          <img className={Styles.image} alt='' src={isUser ? user?.picture : team?.picture} />
          {(isUser && user?.issues > 0 && (
            <span
              className={classNames(
                Styles.issue,
                user?.issues === 2 && Styles.red,
                user?.issues === 1 && Styles.orange
              )}></span>
          )) ||
            ''}
        </div>
      </Tooltip>
    </span>
  );
});

ListImage.propTypes = {
  user: PropTypes.object,
  team: PropTypes.object,
};

ListImage.defaulProps = {
  user: null,
  team: null,
};

export default ListImage;
