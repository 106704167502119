import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@hiredigital/ui/Button';
import Caret from '@hiredigital/ui/Icon/icons/caret.inline.svg';
import Styles from '../list.module.scss';

import { getFinanceOthers } from '@apis/tracking';

const TreeCaret = ({ expand, type, uuid, search, stateData, setParentState }) => {
  const hasCaret = () => {
    switch (type) {
      case 'relationship':
      case 'assignment':
      case 'task':
      case 'unlinkedTasks':
      case 'unlinkedEvents':
        return true;
      default:
        return false;
    }
  };

  const toggleExpand = (event) => {
    if (expand) {
      if (type !== 'unlinkedTasks' && type !== 'unlinkedEvents') {
        setParentState({
          data: {
            ...stateData,
            childType: null,
            children: null,
          },
        });
      }
    } else {
      const childType = {
        task: 'event',
        assignment: 'task',
        relationship: 'assignment',
      }[type];

      const children = {
        task: 'events',
        assignment: 'tasks',
        relationship: 'timesheets',
      }[type];

      if (childType && children) {
        setParentState({
          loading: true,
          placeholderText: `No ${children} found.`,
          data: {
            ...stateData,
            childType,
          },
        });
        const config = { params: search };
        getFinanceOthers(type, uuid, config)
          .then(
            ({ data }) => {
              setParentState({
                data: {
                  ...stateData,
                  childType,
                  totalDuration: 1.0 * data.totalDuration,
                  unlockedDuration: 1.0 * data.unlockedDuration,
                  children: data[children]?.filter(
                    (v) => 1.0 * (v?.totalDuration || v?.duration) > 0.0
                  ),
                },
              });
            },
            (error) => {
              console.error(`Error occured fetching ${children}`);
            }
          )
          .finally(() => {
            setParentState({
              loading: false,
            });
          });
      }
    }
    setParentState({ expand: !expand });
  };

  return hasCaret() ? (
    <div className={classNames(Styles.caretOption, expand ? '' : Styles.caretCollapsed)}>
      <Button className={Styles.caretIcon} onClick={toggleExpand} type={Button.Type.WHITE}>
        <Caret />
      </Button>
    </div>
  ) : (
    ''
  );
};

TreeCaret.propTypes = {
  expand: PropTypes.bool,
  type: PropTypes.string,
  uuid: PropTypes.string,
  search: PropTypes.object,
  stateData: PropTypes.object,
  setParentState: PropTypes.func,
};

export default TreeCaret;
